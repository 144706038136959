import { FaFileInvoice } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { OverlayTrigger, Tooltip, Button, Col, Row, Image } from "react-bootstrap";
import { BiSolidBookmarks } from "react-icons/bi";
import { PAYMENT_LIST_API_ENDPOINT } from "../../Constants/api-routes.constant";
import { GetAuthToken } from "../../Utils/auth-verification.util";
import { GetFormattedCurrency } from "../../Utils/formating.util";

import Notify from "./../Status/Notify";
import Invoice from "../Billings/Invoice";
import { getInvoiceDetail } from '../../Utils/billing.util';
import downloadIcon from "../../assets/new_theme/img/download.png";
import nextImg from "../../assets/new_theme/img/next.png";
import prevImg from "../../assets/new_theme/img/prev.png";

const InvoiceCom = () => {


  const dispatch = useDispatch();

  document.title = "Invoices - Skipify.ai";
  const csvLink = useRef();

  const [invoiceData, setInvoiceData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [invoiceCsvData, setInvoiceCsvData] = useState([]);
  const [selectExport, setSelectExport] = useState(0)
  const [userEmail, setUserEmail] = useState('');
  const [invID, setInvID] = useState('');
  const [invObject, setInvObject] = useState({});
  const [showMessage, setShowMessage] = useState(false)
  // Pagination
  const itemsPerPage = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = invoiceData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  useEffect(() => {
    GetPaymentsList();
  }, []);


  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalCount;
    setItemOffset(newOffset);
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    GetPaymentsList(newOffset);
  };

  /**
   * Get Payment data
   * @returns
   */
  const GetPaymentsList = (newOffset = 0) => {
    setShowLoader(true);
    const token = GetAuthToken();
    if (!token) {
      return null;
    }
    fetch(`${PAYMENT_LIST_API_ENDPOINT}?skip=${newOffset}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response?.json())
      .then((response) => {
        if (response.statusCode == "200") {
          setInvoiceData(response.result?.payments);

          setTotalCount(response.result?.totalCount);
          setUserEmail(response.result?.email)

          if (response.result?.payments?.length === 0) {
            setShowMessage(true)
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setErrorMsg(error?.message);
        setShowLoader(false);
      });
  };

  /**
   * Handle checkbox selection
   * @param {*} e
   */
  const handleInvoiceCheckbox = async (e) => {
    const invoiceID = e.target.value;
    const invoice = invoiceData.find((prp) => prp._id == invoiceID);
    const newSet = selectedInvoices;
    const invoiceIndex = newSet.findIndex((p) => p?._id == invoice._id);

    if (e.target.checked) {
      if (invoiceIndex == -1) {
        newSet.push(invoice);
      }
    } else {
      if (invoiceIndex >= 0) {
        delete newSet.splice(invoiceIndex, 1);
      }
    }

    setSelectExport(newSet?.length)
    await setSelectedInvoices(newSet);
    // console.log(selectedInvoices);
  };

  const handleDownloadInvoicesButton = async () => {
    setShowLoader(true);

    let data = invoiceData;
    if (selectedInvoices.length) {
      data = selectedInvoices;
    }

    const csvData = [];
    let counter = 1;
    for (const invoice of data) {
      let transactionType = "Package Purchase";
      if(invoice?.hitPurchased > 0 || invoice?.noOfhitPurchased > 0) {
        transactionType = "Additional Hits Purchase";
      } else if(invoice?.flashSaleId) {
        transactionType = "Flash Sale Purchase";
      } else if(invoice.paymentType?.toLowerCase()?.includes('rotation')) {
        transactionType = invoice.paymentType;
      }
      const invoiceItem = {
        "Invoice #": `INV-${invoice?.paymentNumber || counter}`,
        "Date Created": moment(invoice.dateCreated).format("LLL"),
        "Transaction Type": transactionType,
        "Amount": GetFormattedCurrency(invoice.totalAmount),
        "Status": invoice.paymentStatus
      };
      csvData.push(invoiceItem)
      counter++;
    }
    //console.log(csvData)
    await setInvoiceCsvData(csvData);
    setTimeout(() => {
      csvLink.current.link.click();
      setShowLoader(false);
      setSelectedInvoices([]);
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
    }, 2000);
  };

  const handleUpperCase = (value) => {
    return value?.charAt(0).toUpperCase() + value?.slice(1) || 'Paid';
  }

  useEffect(() => {
    if (invID) {
      GetRecordOftheInvoice()
    }
  }, [invID]);


  const GetRecordOftheInvoice = async () => {
    const { result } = await getInvoiceDetail(invID);
    if (result?.statusCode === 200) {
      setInvObject(result?.result);
    } else {
      console.log("some Issue in Query")
    }
  }

  const pageTitle = "My Invoices";
  return (
    <>

      {invObject != '' && invID && <Invoice setInvID={setInvID} invObject={invObject || {}} />}


      {!invID && !showMessage ?
        <>
          <Row className="justify-content-between mt-3">
            <Col md={6}>
              <h2 className="mb-4 font-weight600 bill-gap">{pageTitle}</h2> 
              {/* <p>Find all of your invoices here.</p> */}
            </Col>
            {/* <Col md={3} className="d-flex justify-content-end">
                            <button
                                className="mb-2 cancelBtn"
                                onClick={handleDownloadInvoicesButton}
                                disabled={selectExport > 0 ? false : true}
                            >
                                <BiSolidBookmarks /> Export {selectExport} Statement
                            </button>
                            <CSVLink
                                data={invoiceCsvData}
                                filename="skipify_invoices_list.csv"
                                className="hidden"
                                ref={csvLink}
                                target="_blank"
                            />
                        </Col> */}
          </Row>
          {/* <!-- Page content--> */}
          <div className="table-responsive">


            <table className="table resp-table2 table-dim-skin">
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Invoice ID</th>
                  <th>Date</th>
                  <th>Email</th>
                  <th>Transaction Type</th>
                  <th>Amount</th>
                  <th className="text-center">Action</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {invoiceData.map((invoice, index) => (
                  <tr key={invoice._id}>
                    {/* <td>
                                                <span className="custom-cont">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        value={invoice._id}
                                                        onChange={handleInvoiceCheckbox}
                                                    />
                                                </span>
                                            </td> */}
                    <td>
                      INV-80{invoice?.paymentNumber ?? (index + 1)}
                    </td>
                    <td>{moment(invoice.dateCreated).format("MM/DD/YYYY")}</td>
                    <td>
                      {/* <MdEmail color="#00274e" />  */}
                        <a href={`mailto:${userEmail}`} style={{ color: "#00274e" }}>{userEmail ?? ''}</a>
                    </td>
                    <td>
{/* 
                    {invoice?.isAdditionalPurchase
                        ? "Additional Skips Purchase"
                        : invoice?.flashSaleId ? "Flash Sale Purchase" 
                        : invoice?.paymentType?.toLowerCase()?.includes('rotation') ? "List Rotation" :"Plan Purchase" } */}
                        {invoice.paymentType}
                    <br></br>
                    </td>

                    <td>
                      <span className="amount">
                        {GetFormattedCurrency(invoice.totalAmount)}
                      </span>
                    </td>

                    {/* <td>
                      <OverlayTrigger
                          placement="left"
                          overlay={
                              <Tooltip>{handleUpperCase(invoice?.paymentStatus)}</Tooltip>
                          }
                      >
                          <a href="#" > <Notify color={invoice?.paymentStatus == 'paid' ? 'blue' : 'gray'} /></a>
                      </OverlayTrigger>
                  </td> */}
                    <td className="text-center">
                      <Button 
                        className="download-table"
                        onClick={() => setInvID(invoice._id)}
                        >
                          <Image src={downloadIcon}></Image>
                          Download
                      </Button>
                      {/* <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip>Invoice Details</Tooltip>
                        }
                      >
                        <div className="icon_div" ><FaFileInvoice /></div>
                      </OverlayTrigger> */}

                    </td>

                  </tr>
                ))}

              </tbody>
            </table>


          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="pagination cst-pagination">
                <ReactPaginate
                  // breakLabel="..."
                  nextLabel={<><div>Next <Image src={nextImg} className="next-icon"></Image></div></>}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={<><div><Image src={prevImg} className="prev-icon"></Image> Previous</div></>}
                  renderOnZeroPageCount={null}
                  activeClassName="paginationActive"
                />
              </div>
            </div>
          </div>
        </>

        : invID == '' ?
          <p className="message_show mt-5">No Invoices Currently on File.  Please check back after you have made a purchase.</p>
          : ""
      }
    </>
  )
}

export default InvoiceCom;
