import { useEffect, useState } from "react";
import {
  DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT,
  DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT_JSON,
} from "../Constants/api-routes.constant";
import DashbaordLayout from "./DashbaordLayout";
import { useParams } from "react-router-dom";

export const DownloadFile = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const pageTitle = 'File Download';

  const {id} = useParams();

  const downloadEndpoint = DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT;

  useEffect(() => {
    window.location = downloadEndpoint + "/" + id;
    setTimeout(() => {
        window.location = '/my-lists'
    }, 3000);
  });

  return (
    <DashbaordLayout title={pageTitle}>
      <div id="page-content-wrapper">
        
        <h2 className="main-title-mobile">{pageTitle}</h2>
        {successMsg ? (
          <p className="alert alert-success">{successMsg}</p>
        ) : (
          ""
        )}
        {errorMsg ? (
          <p className="alert alert-danger" style={{ paddingLeft: "10px" }}>
            {errorMsg}
          </p>
        ) : (
          ""
        )}
      </div>
    </DashbaordLayout>
  );
};
