export const MiscellaneousOptionsConstant = [
{value:"0010", label: "General", category: "Miscellaneous"},
{value:"0011", label: "Pipeline or Right-of-Way", category: "Miscellaneous"},
{value:"0012", label: "Rail (Right-of-way & track)", category: "Miscellaneous"},
{value:"0013", label: "Road (Right-of-way)", category: "Miscellaneous"},
{value:"0014", label: "Utilities (Right-of-way ONLY)", category: "Miscellaneous"},
{value:"0015", label: "Sub-Surface Rights (mineral)", category: "Miscellaneous"},
{value:"0016", label: "Surface Rights (Grazing, timber, coal)", category: "Miscellaneous"},
{value:"0017", label: "Leasehold Rights", category: "Miscellaneous"},
{value:"0018", label: "Possessory Interest", category: "Miscellaneous"},
{value:"0019", label: "Petroleum & Gas Wells", category: "Miscellaneous"},
{value:"0020", label: "Water Rights", category: "Miscellaneous"},
{value:"0021", label: "Right-of-Way (not rail, road or utility)", category: "Miscellaneous"},
{value:"0022", label: "Easement", category: "Miscellaneous"},
{value:"0023", label: "Homestead", category: "Miscellaneous"},
{value:"0024", label: "Common Area", category: "Miscellaneous"},
{value:"0025", label: "Royalty Interest", category: "Miscellaneous"},
{value:"0026", label: "Working Interest", category: "Miscellaneous"},
{value:"0027", label: "Parcels with improvements, Use Not Specified", category: "Miscellaneous"}
]