import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Modal,
  Image,
  Card,
} from "react-bootstrap";
import { FaPlus, FaMinus, FaTrashAlt } from "react-icons/fa";
import { AiFillCreditCard } from "react-icons/ai";
import { AddBillingModal } from "./add-billing-modal";
import { IoMdTrash } from "react-icons/io";
// import { STRIPE_SK } from "../../Constants/api-credentials.constant";
import {
  createStripePaymentIntent,
  getActiveBilling,
  paymentFailed,
  activateListStorageSubscription,
  GetListStoragePriceDetails,
  GetActiveListStorageSubscription,
  RemoveListStorageSubscription,
} from "../../Utils/billing.util";
import {
  GetFormattedCurrency,
  formatCardEndFourDigits,
  getFormattedCurrencyInInteger,
} from "../../Utils/formating.util";
import { updateCurrentBilling } from "../../store/slices/features/payment-plan";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  GetCustomerListStorageLimit,
  GetCustomerListStorageCount,
} from "../../Utils/clients.util";
import {
  getCurrentBilling,
  getCurrentPlanData,
} from "../../store/selectors/features/payment-plan";
import { IoIosArrowRoundBack } from "react-icons/io";
import visaIcon from "./../../assets/new_theme/img/payment/visa.png";
import discoverIcon from "./../../assets/new_theme/img/payment/discover.png";
import mastercardIcon from "./../../assets/new_theme/img/payment/card.png";
import jbccardIcon from "./../../assets/new_theme/img/payment/jcb.png";
import linkIcon from "./../../assets/new_theme/img/payment/link.png";
import amexIcon from "./../../assets/new_theme/img/payment/amex.png";
import addIcon from "./../../assets/new_theme/img/add.png";

const AddListStorage = () => {
  document.title = "List Storage Subscription - Skipify.ai";
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const LIST_STORAGE_PRICE = 49;

  const currentPlanData = useSelector(getCurrentPlanData);
  const activeBilling = useSelector(getCurrentBilling);
  const stripePaymentId = activeBilling?.stripePaymentMethodId;
  const stripeCustomerId = activeBilling?.stripeCustomerId;
  const stripeClientId = activeBilling?.clientId;

  const [showLoader, setShowLoader] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [totalDueAmount, setTotalDueAmount] = useState(LIST_STORAGE_PRICE);

  const [listStorageRecords, setListStorageRecords] = useState(100000);

  const [customerListStorageDetails, setCustomerListStorageDetails] =
    useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showLRErrorModal, setShowLRErrorModal] = useState(false);
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] =
    useState(false);
  const [hasRemoved, setHasRemoved] = useState(false);

  const [totalRemainingListStorageLimit, setTotalRemainingListStorageLimit] =
    useState();
  const [totalListStorageUsage, setTotalListStorageUsage] = useState();

  const handleCloseConfirmModal = () => setShowConfirm(false);
  const handleShowConfirmModal = () => setShowConfirm(true);

  const handleCloseAddCardModal = () => setShowAddCard(false);
  const handleShowAddCardModal = () => setShowAddCard(true);

  const handleShowRemoveConfirmationModal = () =>
    setShowRemoveConfirmationModal(true);
  const handleCloaseRemoveConfirmationModal = () =>
    setShowRemoveConfirmationModal(false);

  useEffect(() => {
    getActiveBillingData();
    getListStoragePrice();
    getCustomerListStorage();
    getRemainingListStorage();
  }, []);

  const getActiveBillingData = async () => {
    setShowLoader(true);
    try {
      const response = await getActiveBilling();

      if (response?.statusCode == 200 && response?.result) {
        dispatch(updateCurrentBilling(response.result));
      }
      setShowLoader(false);
    } catch (error) {
      console.log("error", error);
      setShowLoader(false);
    }
  };

  const getRemainingListStorage = async () => {
    const listStorageLimit = await GetCustomerListStorageLimit();
    const listStorageUsage = await GetCustomerListStorageCount();
    setTotalListStorageUsage(listStorageUsage);
    if (listStorageLimit < listStorageUsage) {
      setTotalRemainingListStorageLimit(0);
    } else {
      setTotalRemainingListStorageLimit(listStorageLimit - listStorageUsage);
    }
  };

  /**
   * Get List Storage Price and Records count
   */
  const getListStoragePrice = async () => {
    setShowLoader(true);
    try {
      const response = await GetListStoragePriceDetails();

      if (
        response?.statusCode == 200 &&
        response?.result &&
        response.result?.listStoragePrice
      ) {
        setTotalDueAmount(response.result.listStoragePrice);
        setListStorageRecords(response.result.listStorageRecords);
      }
      setShowLoader(false);
    } catch (error) {
      console.log("error", error);
      setShowLoader(false);
    }
  };

  /**
   * Get List Stor
   */
  const getCustomerListStorage = async () => {
    setShowLoader(true);
    try {
      const response = await GetActiveListStorageSubscription();

      if (response?.statusCode == 200 && response?.result) {
        setCustomerListStorageDetails(response.result);
        setShowCheckout(false);
      } else {
        setShowCheckout(true);
      }
      setShowLoader(false);
    } catch (error) {
      console.log("error", error);
      setShowLoader(false);
    }
  };

  const plusClick = () => {
    let listStorageCount = listStorageRecords + 100000;
    if (listStorageCount > 100000000) return;

    setListStorageRecords(listStorageCount);
    setTotalDueAmount(totalDueAmount + LIST_STORAGE_PRICE);
  };

  const minusClick = () => {
    let listStorageCount = listStorageRecords - 100000;
    if (listStorageCount < 100000) return;

    setListStorageRecords(listStorageCount);
    setTotalDueAmount(totalDueAmount - LIST_STORAGE_PRICE);
  };

  const paymentFail = async (message) => {
    setStripeErrorMsg(message);
    setShowLoader(false);

    try {
      const result = await paymentFailed();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Call API to enable list storage  for the customer after successfull payment
   */
  const EnableListStorageSubscription = async (stripeResult) => {
    if (!stripeResult?.paymentIntent) {
      return;
    }
    const requestBody = {
      paymentIntentId: stripeResult.paymentIntent.id,
      paymentAmountInCent: stripeResult.paymentIntent.amount,
      paymentMethodId: stripeResult.paymentIntent.payment_method,
      paymentCreated: stripeResult.paymentIntent.created,
      listStorageCount: listStorageRecords,
    };

    console.log(requestBody);

    try {
      const response = await activateListStorageSubscription(requestBody);
      setShowLoader(false);

      if (response?.statusCode == 200 && response?.result) {
        setSuccessMsg(
          `Thank you for purchasing. List Storage Subscription has been activated.`
        );

        getCustomerListStorage();
        getRemainingListStorage();
        setTimeout(() => {
          window.location.href = "/billing";
        }, 3000);
      } else {
        setStripeErrorMsg(
          response?.message ?? "We are unable to verify your payment."
        );
      }
    } catch (error) {
      setShowLoader(false);
      setStripeErrorMsg(
        error?.message ?? "We are unable to verify your payment."
      );
    }
  };

  const handlePayNow = async (event) => {
    event.preventDefault();
    setShowConfirm(false);

    if (!activeBilling?._id) {
      setErrorMsg("Please add Payment Method to proceed");
      return;
    }

    if (totalDueAmount <= 0 || listStorageRecords < 100000) {
      setErrorMsg("Please Add List Storage Records");
      return;
    }

    setShowLoader(true);
    setStripeErrorMsg("");
    setErrorMsg("");

    try {
      const paymentIntentResult = await createStripePaymentIntent(
        totalDueAmount,
        "Skipify - List Storage Subscription"
      );

      if (!paymentIntentResult?.clientSecret) {
        if (
          paymentIntentResult?.message?.includes(
            "confirmCardPayment intent secret"
          )
        ) {
          setStripeErrorMsg(
            "Payment Failed due to an unexpected error. Please try later."
          );
        } else {
          setStripeErrorMsg(
            paymentIntentResult?.message ??
              "Error while creating a payment request."
          );
        }
        setShowLoader(false);
        return;
      }

      const paymentClientSecret = paymentIntentResult?.clientSecret;

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setShowLoader(false);
        setStripeErrorMsg(
          "Payment Failed. Please refresh the page and try again."
        );
        return;
      }

      let confirmPaymentOptions = {};

      if (!stripePaymentId) {
        confirmPaymentOptions = {
          payment_method: {
            card: elements.getElement(CardElement),
            metadata: {
              "customer ID": stripeClientId,
              "List Storage Subscription Amount": `$${totalDueAmount.toFixed(
                2
              )}`,
            },
          },
        };
      }

      const result = await stripe.confirmCardPayment(
        `${paymentClientSecret}`,
        confirmPaymentOptions
      );

      if (result.error) {
        await paymentFail(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          await EnableListStorageSubscription(result);
        } else {
          await paymentFail(
            "Payment confirmation failed. Make sure your card is valid."
          );
        }
      }
    } catch (error) {
      if (error?.message?.includes("confirmCardPayment intent secret")) {
        setStripeErrorMsg(
          "Payment Failed due to an unexpected error. Please try later."
        );
      } else {
        setStripeErrorMsg(error?.message);
      }
      setShowLoader(false);
    }
  };

  const handleRemoveListStorageSubscription = () => {
    setShowRemoveConfirmationModal(false);
    setShowLoader(true);
    RemoveListStorageSubscription()
      .then((response) => {
        if (response.statusCode == 200) {
          setSuccessMsg(
            "List Storage monthly subscription removed successfully."
          );
          setHasRemoved(true);
          setTimeout(() => (window.location.href = "/billing"), 3000);
          //
        } else {
          setErrorMsg(
            "We cannot remove List Storage subscription at the moment. Please try later."
          );
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const renderConfirmPaymentModal = () => {
    return (
      <Modal
        show={showConfirm}
        onHide={handleCloseConfirmModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="modal_notification">
            <h5>Proceed with your payment?</h5>

            <div>
              <Button variant="primary" onClick={handlePayNow}>
                Yes
              </Button>
              <Button variant="warning" onClick={handleCloseConfirmModal}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderConfirmRemoveModal = () => {
    return (
      <Modal
        show={showRemoveConfirmationModal}
        onHide={handleCloaseRemoveConfirmationModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header style={{ background: "#0b2548" }}>
          <h5 style={{ padding: "5px 5px 0px", color: "white" }}>
            Confirmation
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_notification">
            <p>
              Are you sure you want to cancel List Storage monthly subscription?
            </p>

            <div>
              <Button
                variant="primary"
                onClick={handleRemoveListStorageSubscription}
              >
                Yes
              </Button>
              <Button
                variant="warning"
                onClick={handleCloaseRemoveConfirmationModal}
              >
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderAddBillingModal = () => {
    return (
      <AddBillingModal
        showModal={showAddCard}
        closeModalHandler={handleCloseAddCardModal}
      />
    );
  };

  return (
    <>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {stripeErrorMsg ? (
        <div className="alert alert-danger">{stripeErrorMsg}</div>
      ) : (
        ""
      )}
      {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : ""}
      {successMsg ? (
        <div className="alert alert-success">{successMsg}</div>
      ) : (
        ""
      )}

      {customerListStorageDetails && !hasRemoved && !showCheckout ? (
        <div className="mt-3">
          <Row className="justify-content-between">
            <Col md={12}>
              {/* <h2 className="main-title-mobile">Additional List Storage</h2> */}
              <h2 className="sub_title_h4 mb-0">Additional List Storage</h2>
              <p>
                Find your additional list storage subscription details. Your
                current plan <strong>{currentPlanData.title}</strong> includes{" "}
                {currentPlanData.listStorageLimit.toLocaleString()} of list
                storage records.
              </p>
            </Col>
          </Row>
          <div className="table-responsive col-12 col-md-6">
            <table className="table resp-table2 table-dim-skin mt-4">
              <tbody>
                <tr>
                  <th>Start Date</th>
                  <td>
                    {moment(customerListStorageDetails.dateCreated).format(
                      "MM/DD/YYYY"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Additional Purchased</th>{" "}
                  <td>
                    {customerListStorageDetails.totalPurchasedStorageCount?.toLocaleString()}{" "}
                    records
                  </td>
                </tr>

                <tr>
                  <th>Included in the Plan</th>{" "}
                  <td>
                    {currentPlanData.listStorageLimit
                      ? currentPlanData.listStorageLimit?.toLocaleString()
                      : "Unlimited"}{" "}
                    records
                  </td>
                </tr>

                <tr>
                  <th>Total Usage</th>{" "}
                  <td>
                    {totalListStorageUsage?.toLocaleString() ?? 0} records
                  </td>
                </tr>
                <tr>
                  <th>Total Remaining</th>{" "}
                  <td>
                    {totalRemainingListStorageLimit > 1000000000
                      ? "Unlimited"
                      : totalRemainingListStorageLimit?.toLocaleString()}{" "}
                    records
                  </td>
                </tr>
                <tr>
                  <th>Amount per Month</th>
                  <td>
                    {GetFormattedCurrency(
                      customerListStorageDetails.amountPaid
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Next Payment Date</th>
                  <td>
                    {moment(customerListStorageDetails.nextRenewalDate).format(
                      "MM/DD/YYYY"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Status</th>{" "}
                  <td>
                    {customerListStorageDetails.isActive ? (
                      <span className="badge bg-success">Active</span>
                    ) : (
                      "Expired"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={handleShowRemoveConfirmationModal}
                      title="Cancel Subscription"
                    >
                      <IoMdTrash size={20} /> Cancel Subscription
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => setShowCheckout(true)}
                      title="Buy more Storage"
                    >
                      <AiFillCreditCard size={20} className="me-2" /> Buy more
                      Storage
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}

      {showCheckout ? (
        <div>
          
          {/* <Row>
            <Col className="text-center">
              <h4 className="sub_title_h4">List Storage Subscription</h4>
            </Col>
          </Row> */}
          
          <Row>
            <Col className="text-start skips-checkout">
              <h2 className="mb-4 bill-gap mb-sm-0">List Storage Subscription</h2>
              {/* <h3 className='sub_title'>Checkout</h3> */}
            </Col>
          </Row>

          <Row className="mt-3 skips-checkout justify-content-between">
            <Col xs={12} sm={12} md={12} xl={5} xxl={5}>
              <Row>
                <Col>
                  <h2 className="mb-4 font-lg bill-gap">Checkout</h2>
                  <hr className="mb-4 mt-2 hr-custom"></hr>
                </Col>

                <Col className="mb-3 mt-2" xl={12}>
                  <span className="step">1</span>
                  <h3>Select amount</h3>
                </Col>
                <Col className="mb-3" xl={12}>
                  <p>How much additional storage do you want to purchase?</p>
                </Col>
                <Col className="mb-3" xl={12}>
                  <Row>
                    <Col
                      xl={8}
                      lg={8}
                      md={10}
                      sm={8}
                      xs={8}
                      className="align-self-center flex-sm justify-content-center"
                    >
                      <button className="arthmetic" onClick={minusClick}>
                        <FaMinus />
                      </button>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          value={listStorageRecords?.toLocaleString()}
                        />
                      </InputGroup>
                      <button className="arthmetic" onClick={plusClick}>
                        <FaPlus />
                      </button>
                    </Col>
                    <Col
                      xl={4}
                      lg={4}
                      md={2}
                      sm={4}
                      xs={4}
                      className="align-self-center"
                    >
                      <h4 className="right">
                        ${getFormattedCurrencyInInteger(totalDueAmount)}/month
                      </h4>
                    </Col>
                  </Row>
                </Col>

                <Col xl={12}>
                  <hr className="mb-4 mt-3 hr-custom"></hr>
                </Col>

                <Col className="my-3" xl={12}>
                  <span className="step">2</span>
                  <h3 className="mb-4">Choose payment method</h3>
                </Col>

                {activeBilling._id && (
                  <Col xl={12}>
                    <Form.Check
                      type="checkbox"
                      className="form-check-cst mb-2"
                      label={
                        <Row className="payment-skin-struct addSkips-cst">
                          <Col
                            className="cst-wdth-skp align-self-center"
                            xl={2}
                            lg={2}
                            sm={2}
                            md={2}
                            xs={2}
                          >
                            {activeBilling.cardType == "visa" && (
                              <Image className="icon" src={visaIcon}></Image>
                            )}
                            {activeBilling.cardType == "discover" && (
                              <Image
                                className="icon"
                                src={discoverIcon}
                              ></Image>
                            )}
                            {activeBilling.cardType == "mastercard" && (
                              <Image
                                className="icon"
                                src={mastercardIcon}
                              ></Image>
                            )}
                            {activeBilling.cardType == "amex" && (
                              <Image className="icon" src={amexIcon}></Image>
                            )}
                            {activeBilling.cardType == "jcb" && (
                              <Image className="icon" src={jbccardIcon}></Image>
                            )}
                            {activeBilling.cardType == "link" && (
                              <Image className="icon" src={linkIcon}></Image>
                            )}
                          </Col>
                          <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                            <div className="container-content">
                              <Row>
                                <Col>
                                  <span className="name mb-1">
                                    {activeBilling?.cardOwnerName
                                      ? activeBilling?.cardOwnerName
                                      : ""}
                                  </span>
                                  {activeBilling.cardType != "link" ? (
                                    <span className="number">
                                      ****{" "}
                                      {formatCardEndFourDigits(
                                        activeBilling.cardLastFourDigits
                                      )}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <span className="expiry">
                                    Expires {activeBilling.cardExpiryMonth}/
                                    {activeBilling.cardExpiryYear}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      }
                      checked
                    />
                  </Col>
                )}

                <Col xl={8} md={6}>
                  <button
                    className="download-table wd-100 p-2 my-4 pl-all-4"
                    onClick={handleShowAddCardModal}
                  >
                    <Image src={addIcon}></Image>
                    Add new payment method
                  </button>
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={12} md={12} xl={6} xxl={6}>
              <div className="billing_detail_section">
                <h3 className="sub_title_h3">Order Summary</h3>
                <hr className="my-4 hr-custom"></hr>

                <Card className="addskip-card">
                  <Row className="mb-xl-3 mb-md-0">
                    <Col lg={12}>
                      <h6>Additional Storage</h6>
                    </Col>
                    <Col lg={7}>
                      <p>
                        {getFormattedCurrencyInInteger(listStorageRecords)}{" "}
                        records x ${LIST_STORAGE_PRICE} per 100k storage
                      </p>
                    </Col>
                    <Col lg={5}>
                      <span>{GetFormattedCurrency(totalDueAmount)}</span>
                    </Col>
                  </Row>

                  <hr className="my-3 hr-custom"></hr>

                  <Row className="mt-3">
                    <Col lg={6}>
                      <h6>Total amount due</h6>
                    </Col>
                    <Col lg={6}>
                      <span>{GetFormattedCurrency(totalDueAmount)}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        label={`I agree to all of Skipify.ai’s Terms of Service & Privacy Policy.`}
                        className="term_checkbox"
                        checked={true}
                      />
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col lg={6}>
                      {/* <Button variant='warning' onClick={handleShowConfirmModal}>SUBMIT PAYMENT</Button> */}
                      {successMsg ? (
                        ""
                      ) : (
                        <button
                          className="submit_Payment"
                          onClick={handlePayNow}
                        >
                          Submit payment
                        </button>
                      )}
                    </Col>
                    <Col lg={12}>
                      <div className="billing_detail_section_des">
                        <h4> Subscription Details: </h4>
                        <ul>
                          <li>
                            The subscription is set to auto-renew by default
                            every month.
                          </li>
                          <li>
                            If you close your account, you will no longer have
                            access to this service.
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>
          {renderConfirmPaymentModal()}
          {renderAddBillingModal()}
        </div>
      ) : (
        ""
      )}
      {renderConfirmRemoveModal()}
    </>
  );
};

export default AddListStorage;
