import { useState, useRef, useEffect } from "react";
import ReactPaginate from "react-paginate";
import {
  BsFillExclamationCircleFill,
  BsGeoAltFill,
  BsPlusLg,
} from "react-icons/bs";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPlan } from "../../store/slices/features/payment-plan";
import {
  getCurrentPlanRemainDownloads,
  getCurrentPlanRemainHits,
} from "../../store/selectors/features/payment-plan";
import {
  GetFormattedDate,
  getFormattedCurrencyInInteger
} from "../../Utils/formating.util";
import {
  SkiptraceBatchByRequestId,
  SkiptraceBatchSelectedItems,
  DownloadPropertiesByPropertiesId,
  GetProfile,
} from "../../Utils/common";
import { SummaryModal, MessageModal, GoCheckoutModal } from "../modal/modals";
import "../../assets/css/main.css";
import { GetFormattedNumber } from "../../Utils/formating.util";
import { Alert } from "react-bootstrap";
import {
  GetCustomerListStorageLimit,
  GetCustomerListStorageCount,
} from "../../Utils/clients.util";

const PropertyListing = (props) => {
  const dispatch = useDispatch();

  const currentPlanData = props.currentPlanData;
  // User can skip trace upto  records in one order
  const Skiptrace_Max_Limit =
    Number(process.env.REACT_APP_SKIPTRACE_LIMIT) ?? 30000;

  const notFoundText = localStorage.getItem("notFoundRecord");

  const remainingDownloads = useSelector(getCurrentPlanRemainDownloads);
  const remainingHits = useSelector(getCurrentPlanRemainHits);

  const [itemOffset, setItemOffset] = useState(0);
  const [skiptraceErrorMsg, setSkiptraceErrorMsg] = useState("");
  const [skiptraceSuccessMsg, setSkiptraceSuccessMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedPropertyRecords, setSelectedPropertyRecords] = useState([]);
  const [selectedPropertyCounter, setSelectedPropertyCounter] = useState(0);
  const [propertyCsvData, setPropertyCsvData] = useState("");
  const [listStorageLimit, setListStorageLimit] = useState(0);
  const [customerListStorageCount, setCustomerListStorageCount] = useState();

  const csvLink = useRef();

  // For Modal
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showPriceModule, setShowPriceModule] = useState(false);
  const [ageMin, setAgeMin] = useState(null);
  const [ageMax, setAgeMax] = useState(null);
  const [dncCheck, setDncCheck] = useState(false);
  const [relativeContacts, setRelativeContacts] = useState(false);
  const [likelyGender, setLikelyGender] = useState(null);

  const itemsPerPage = 500;
  const propertyData = props.propertyData;
  const totalPropertyCount = props.propertyCount;
  const requestId = props.requestId;

  const handleResetPreviousItems = async () => {
    const resetPreviousItems = props.resetPreviousItems;
    console.log(resetPreviousItems);
    if (resetPreviousItems && setSelectedPropertyRecords.length > 0) {
      await setSelectedPropertyCounter(0);
      await setSelectedPropertyRecords([]);
    }
  };

  const getCustomerListStorageLimit = async () => {
    const lsLimit = await GetCustomerListStorageLimit();
    setListStorageLimit(lsLimit);
  };

  const getCustomerListStorageCount = async () => {
    const customerLsCount = await  GetCustomerListStorageCount();
    setCustomerListStorageCount(customerLsCount);
  };

  useEffect(() => {
    handleResetPreviousItems();
    getCustomerListStorageLimit();
    getCustomerListStorageCount();
    setSkiptraceErrorMsg("");
    setSkiptraceSuccessMsg("");
  }, [propertyData]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = propertyData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalPropertyCount / itemsPerPage);

  const selectedDownloads = selectedPropertyRecords?.length
    ? selectedPropertyRecords?.length
    : totalPropertyCount;
  const remainingData =
    type === "Download" ? remainingDownloads : remainingHits;

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setSkiptraceErrorMsg("");
    setSkiptraceSuccessMsg("");
    const newOffset = (event.selected * itemsPerPage) % totalPropertyCount;
    setItemOffset(newOffset);
    props.setSkipValue(newOffset);
    document.getElementById("select_property_checkbox").checked = false;
  };

  /**
   * Handle property listing checkboxes
   * @param
   */
  const handlePropertyCheckbox = async (e) => {
    setSkiptraceErrorMsg("");
    setSkiptraceSuccessMsg("");

    const propertyId = e.target.value;
    const property = propertyData.find((prp) => prp._id == propertyId);
    const newSet = selectedPropertyRecords;
    const propertyIndex = newSet.findIndex((p) => p?._id == property._id);
    let propertyCounter = selectedPropertyCounter;

    if (e.target.checked) {
      if (propertyIndex == -1) {
        newSet.push(property);
        propertyCounter++;
      }
    } else {
      if (propertyIndex >= 0) {
        delete newSet.splice(propertyIndex, 1);
        propertyCounter--;
      }
    }
    setSelectedPropertyCounter(propertyCounter);
    setSelectedPropertyRecords(newSet);
    // console.log(selectedPropertyRecords);
  };

  const getProfile = async () => {
    GetProfile().then((response) => {
      if (response?.statusCode == "200" && response?.result) {
        dispatch(updateCurrentPlan(response.result.billing));
      }
    });
  };

  /**
   * Handle Skiptrace
   */
  const handleSkiptraceButtonClick = async () => {
    setShow(false);
    setSkiptraceErrorMsg("");
    setSkiptraceSuccessMsg("");

    if (!totalPropertyCount) return;

    setShowLoader(true);

    const skiptraceRequest = {
      propertyRequestId: requestId,
      propertyCount: totalPropertyCount,
      ageMin,
      ageMax,
      dncCheck,
      remainingHits: remainingHits,
      relativeContacts,
      likelyGender
    };

    const response = await SkiptraceBatchByRequestId(
      skiptraceRequest,
      fileName
    );
    getProfile();
    setShowLoader(false);

    if (response?.error) {
      setShow(false);
      setSkiptraceErrorMsg(response?.message ?? "");
      return;
    }

    if (response?.statusCode == 200) {
      setShow(false);
      setFileName("");
      const successMsg = (
        <>
          Your Skip-Trace order was successful. Order Number:{" "}
          {response?.result?.orderNumber}. Check your order status in{" "}
          <a href="/my-lists">My Data</a>.
        </>
      );
      setSkiptraceSuccessMsg(successMsg);
    }
  };

  /**
   * Handle Skiptrace Selected Property Records
   */
  const handleSkiptraceSelectedItems = async () => {
    setSkiptraceErrorMsg("");
    setSkiptraceSuccessMsg("");
    setShow(false);
    if (!selectedPropertyRecords.length) return;

    setShowLoader(true);

    const response = await SkiptraceBatchSelectedItems(
      selectedPropertyRecords,
      fileName,
      ageMin,
      ageMax,
      dncCheck,
      remainingHits,
      relativeContacts,
      likelyGender
    );
    getProfile();
    setShowLoader(false);

    if (response?.error) {
      setShow(false);
      setSkiptraceErrorMsg(response?.message ?? "");
      return;
    }

    if (response?.statusCode == 200) {
      setShow(false);
      setFileName("");

      const successMsg = (
        <>
          Your Skip-Trace order was successful. Order Number:{" "}
          {response?.result?.orderNumber}. Check your order status in{" "}
          <a href="/my-lists">My Data</a>.
        </>
      );
      setSkiptraceSuccessMsg(successMsg);
    }
  };

  /**
   * Handle download property button
   */
  const handleDownloadPropertiesButton = async () => {
    setSkiptraceErrorMsg("");
    setSkiptraceSuccessMsg("");
    setShow(false);
    setShowLoader(true);
    let propertiesData = [];

    if (selectedPropertyRecords?.length > 0) {
      // Download selected properties by IDs
      const propertyIds = selectedPropertyRecords.map((p) => p._id);
      const response = await DownloadPropertiesByPropertiesId(
        propertyIds,
        fileName
      );
      getProfile();

      if (response?.error) {
        setShow(false);
        setSkiptraceErrorMsg(response?.message ?? "");
        return;
      }
      setShow(false);
      propertiesData = response?.result ?? [];
    } else {
      // Download properties by requestId
      const response = await DownloadPropertiesByPropertiesId(
        requestId,
        fileName,
        "requestId"
      );

      if (response?.error) {
        setShow(false);
        setSkiptraceErrorMsg(response?.message);
        return;
      }
      setShow(false);
      setFileName("");
      propertiesData = response?.result ?? [];
    }

    const successMsg = (
      <>
        Your Property Download order was successful. Check your order status in{" "}
        <a href="/my-lists">My Data</a>.
      </>
    );
    setSkiptraceSuccessMsg(successMsg);
    setShowLoader(false);
    // setPropertyCsvData(propertiesData);

    // setTimeout(() => {
    //   csvLink.current.link.click();
    //   setShowLoader(false);
    //   const successMsg = (
    //     <>
    //       Your Property Download order was successful. Check your order status in{" "}
    //       <a href="/my-lists">My Data</a>.
    //     </>
    //   );
    //   setSkiptraceSuccessMsg(successMsg);
    // }, 2000);
  };

  const handleClick = (event) => {
    let toExpId = event.currentTarget.id;
    let expAddr = document.querySelector("#expandable-data");
    let expId = expAddr?.querySelector("#toExpand-" + toExpId);
    let expIdParentNode = expId?.parentNode;
    let child = event.currentTarget.childNodes[0];
    if (expId?.classList.contains("hide")) {
      expId?.classList.remove("hide");
      expId?.classList.add("show");
      child.classList.remove("fa-plus");
      child.classList.add("fa-minus");
      expIdParentNode?.classList.add("tr-expand");
    } else {
      expId?.classList.remove("show");
      expId?.classList.add("hide");
      child.classList.remove("fa-minus");
      child.classList.add("fa-plus");
      expIdParentNode?.classList.remove("tr-expand");
    }
  };

  const handleMapView = (propertiesItem) =>
    props.handleMapOfProperty(propertiesItem);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleModalShow = () => {
    return (
      <SummaryModal
        handleClose={handleClose}
        show={show}
        type={type}
        fileName={fileName}
        setFileName={setFileName}
        selected={selectedDownloads}
        remine={remainingData}
        setAgeMin={setAgeMin}
        setAgeMax={setAgeMax}
        setDncCheck={setDncCheck}
        setRelativeContacts={setRelativeContacts}
        setLikelyGender={setLikelyGender}
        currentPlanData={currentPlanData}
        submitFunction={
          type === "Download"
            ? handleDownloadPropertiesButton
            : selectedPropertyCounter > 0
            ? handleSkiptraceSelectedItems
            : handleSkiptraceButtonClick
        }
      />
    );
  };

  const handleCloseMessageModal = () => setShowMessageModal(false);

  const renderMessageModal = () => {
    return (
      <MessageModal
        show={showMessageModal}
        type="error"
        handleClose={handleCloseMessageModal}
        message="Please Select at least 10 records to proceed."
      />
    );
  };

  const renderGoCheckoutModal = () => {
    return (
      <GoCheckoutModal
        handleClose={handlePriceModalClose}
        show={showPriceModule}
        type={type}
      />
    );
  };

  const handleSkipTraceClick = (type) => {
    setType(type);

    if (type === "Download") {
      if (
        totalPropertyCount > remainingDownloads &&
        !selectedPropertyRecords.length
      ) {
        setShowPriceModule(true);
        return;
      }

      if (selectedPropertyRecords.length > remainingDownloads) {
        setShowPriceModule(true);
        return;
      }
    }

    if (type === "Skip Trace") {
      // If user skip trace all searched records
      if (
        (totalPropertyCount > remainingHits ||
          totalPropertyCount > remainingDownloads) &&
        !selectedPropertyRecords.length
      ) {
        setShowPriceModule(true);
        return;
      }

      // if user skip trace the selected records
      if (
        selectedPropertyRecords.length > remainingHits ||
        selectedPropertyRecords.length > remainingDownloads
      ) {
        setShowPriceModule(true);
        return;
      }

      // Check list storage limit for all properties
      if (totalPropertyCount && !selectedPropertyRecords.length && listStorageLimit > 0) {
        const newListCount = totalPropertyCount + customerListStorageCount;
        let remainingListCount = listStorageLimit - customerListStorageCount;
        if(remainingListCount < 0) remainingListCount = 0;
        if (newListCount > listStorageLimit) {
          setSkiptraceErrorMsg(
            <span>
            Your remaining skip-trace list storage limit is <strong>{remainingListCount}</strong>.
            To proceed with this order, please upgrade your plan or delete some previously skip-traced data from the <a href="/my-lists">My Data</a> section.
            </span>
          );
          return;
        }
      }

      // Check list storage limit for selected properties
      if (selectedPropertyRecords.length && listStorageLimit > 0) {
        const newListCount = selectedPropertyRecords.length + customerListStorageCount;
        let remainingListCount = listStorageLimit - customerListStorageCount;
        if(remainingListCount < 0) remainingListCount = 0;
        if (newListCount > listStorageLimit) {
          setSkiptraceErrorMsg(
            <span>
            Your remaining skip-trace list storage limit is <strong>{remainingListCount}</strong>.
            To proceed with this order, please upgrade your plan or delete some previously skip-traced data from the <a href="/my-lists">My Data</a> section.
            </span>
          );
          return;
        }
      }

      if (
        totalPropertyCount > Skiptrace_Max_Limit &&
        !selectedPropertyRecords.length
      ) {
        setSkiptraceErrorMsg(
          `We can not skip trace more than ${Skiptrace_Max_Limit} records.`
        );
        return;
      }

      if (selectedPropertyRecords?.length > Skiptrace_Max_Limit) {
        setSkiptraceErrorMsg(
          `We can not skip trace more than ${Skiptrace_Max_Limit} records.`
        );
        return;
      }
    }

    handleShow();
    return;
  };

  const handlePriceModalClose = () => setShowPriceModule(false);

  const handleSelectAll = (e) => {
    let newSet = selectedPropertyRecords;
    let propertyCounter = selectedPropertyCounter;
    if (e.target.checked) {
      document.querySelectorAll(".tableCheckBox").forEach((el) => {
        // console.log('select input', el)
        el.checked = true;
        let propertyId = el.value;
        let property = propertyData.find((prp) => prp._id == propertyId);
        const propertyIndex = newSet.findIndex((p) => p?._id == property?._id);
        if (property && propertyIndex == -1) {
          newSet.push(property);
          propertyCounter++;
        }
      });
    } else {
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));

      for (const property of propertyData) {
        const propertyIndex = newSet.findIndex((p) => p?._id == property?._id);
        if (propertyIndex >= 0) {
          delete newSet.splice(propertyIndex, 1);
          propertyCounter--;
        }
      }
    }
    setSelectedPropertyRecords(newSet);
    setSelectedPropertyCounter(propertyCounter);
  };

  // This will make sure that the selected properties of the last page are still check if someone back to a page
  const handleAlreadyCheckedProperty = (id) => {
    const propertyIndex = selectedPropertyRecords.findIndex(
      (p) => p?._id == id
    );
    if (selectedPropertyRecords.length && propertyIndex != -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {showLoader && (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      )}
      {skiptraceSuccessMsg && (
        <Alert
          variant="success"
          onClose={() => setSkiptraceSuccessMsg("")}
          dismissible
        >
          <span className="ms-2">{skiptraceSuccessMsg}</span>
        </Alert>
      )}

      {skiptraceErrorMsg && (
        <Alert
          variant="danger"
          onClose={() => setSkiptraceErrorMsg("")}
          dismissible
        >
          <BsFillExclamationCircleFill />{" "}
          <span className="ms-2">{skiptraceErrorMsg}</span>
        </Alert>
      )}

      <div className="row">
        <div className="col-12">
          {/* {totalPropertyCount == 0 ? "" :
            <button
              className="downloadRecords mb-3"
              onClick={() => handleSkipTraceClick('Skip Trace')}
            >
              Skip Trace <span>{totalPropertyCount}</span> Records
            </button>
          } */}

          {selectedPropertyCounter > 0 ? (
            <>
              <button
                className="downloadRecords mb-3"
                onClick={() => handleSkipTraceClick("Download")}
              >
                Download{" "}
                <span>{GetFormattedNumber(selectedPropertyCounter)}</span>{" "}
                Records
              </button>
            </>
          ) : (
            <>
              {totalPropertyCount !== 0 && (
                <button
                  className="downloadRecords mb-3"
                  onClick={() => handleSkipTraceClick("Download")}
                >
                  Download <span>{GetFormattedNumber(totalPropertyCount)}</span>{" "}
                  Records
                </button>
              )}
            </>
          )}

          {selectedPropertyCounter > 0 ? (
            <>
              <button
                className="downloadRecords mb-3"
                onClick={() => handleSkipTraceClick("Skip Trace")}
              >
                Skip Trace{" "}
                <span>{GetFormattedNumber(selectedPropertyCounter)}</span>{" "}
                Records
              </button>
            </>
          ) : (
            <>
              {totalPropertyCount !== 0 && (
                <button
                  className="downloadRecords mb-3"
                  onClick={() => handleSkipTraceClick("Skip Trace")}
                >
                  Skip Trace{" "}
                  <span>{GetFormattedNumber(totalPropertyCount)}</span> Records
                </button>
              )}
            </>
          )}

          <CSVLink
            data={propertyCsvData}
            filename={`${fileName}.csv`}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </div>
      </div>

      {/* <div className="table-data-main"> */}
      {propertyData?.length > 0 && (
        <div className="table-data-main property resp-table2">
          <table className="table" id="expandable-data">
            <thead>
              <tr role="row">
                <th role="columnheader"></th>
                <th role="columnheader">
                  <label className="containerCheckbox">
                    <input
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e)}
                      id="select_property_checkbox"
                    />
                    <span className="checkmark"></span>
                  </label>
                </th>
                <th role="columnheader" className="text-center "></th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">Property Address</span>
                  <span></span>
                </th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">City</span>
                  <span></span>
                </th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">State</span>
                  <span></span>
                </th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">Zip</span>
                  <span></span>
                </th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">Property Type</span>
                  <span></span>
                </th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">Owner Occupied</span>
                  <span></span>
                </th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">Assd Total Value</span>
                  <span></span>
                </th>
                <th role="columnheader" className="text-center ">
                  <span className="tabletitle">Market Total Value</span>
                  <span></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {propertyData?.length ? (
                propertyData.map((propertyItem) => (
                  <tr
                    className="text-center level-0"
                    role="row"
                    key={propertyItem._id}
                  >
                    <td role="cell">
                      <label>
                        <button
                          onClick={() => handleMapView(propertyItem)}
                          className="btn btn-sm btn-icon"
                        >
                          <BsGeoAltFill color="#fff" />
                        </button>
                      </label>
                    </td>
                    <td className="center" role="cell">
                      <label
                        className="containerCheckbox"
                        style={{ marginTop: "0.5rem" }}
                      >
                        <input
                          key={propertyItem._id}
                          type="checkbox"
                          value={propertyItem._id}
                          onChange={handlePropertyCheckbox}
                          className="tableCheckBox"
                          defaultChecked={handleAlreadyCheckedProperty(
                            propertyItem._id
                          )}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                    <td role="cell" className="btn-tool">
                      <div
                        type="button"
                        className="btn btn-sm btn-icon"
                        id={`${propertyItem._id}`}
                        onClick={handleClick}
                      >
                        <BsPlusLg />
                      </div>
                    </td>
                    <td role="cell">
                      <span>{propertyItem.SitusFullStreetAddress}</span>
                    </td>
                    <td role="cell">
                      <span>{propertyItem.SitusCity}</span>
                    </td>
                    <td role="cell">
                      <span>{propertyItem.SitusState}</span>
                    </td>
                    <td role="cell">
                      <span>{propertyItem.SitusZIP5}</span>
                    </td>
                    <td role="cell">
                      <span>
                        {propertyItem.PropertyTypeDetail}(
                        {propertyItem.PropertyType})
                      </span>
                    </td>
                    <td role="cell">
                      <span>
                        {propertyItem.OwnerOccupied == "Y" ? "Yes" : "No"}
                      </span>
                    </td>
                    <td role="cell">
                      <span>
                        ${getFormattedCurrencyInInteger(propertyItem.AssdTotalValue)}
                      </span>
                    </td>
                    <td role="cell">
                      <span>
                        ${getFormattedCurrencyInInteger(propertyItem.MarketTotalValue)}
                      </span>
                    </td>
                    <tr
                      className="level-1 hide"
                      id={`toExpand-${propertyItem._id}`}
                    >
                      <table>
                        <tr>
                          <td></td>
                          <td></td>
                          <td role="cell">
                            <label>Last Sale Date</label>
                            <span>
                              {propertyItem.CurrentSaleRecordingDate &&
                                GetFormattedDate(
                                  propertyItem.CurrentSaleRecordingDate
                                )}
                            </span>
                          </td>
                          <td role="cell">
                            <label>Last Sale Price</label>
                            <span>
                              {propertyItem.CurrentSalesPrice ?
                                '$'+getFormattedCurrencyInInteger(
                                  propertyItem.CurrentSalesPrice
                                ): ""}
                            </span>
                          </td>
                          <td role="cell">
                            <label>Total Sq.Ft</label>
                            <span>{propertyItem.SumBuildingSqFt}</span>
                          </td>
                          <td role="cell">
                            <label>Year Built</label>
                            <span>{propertyItem.YearBuilt}</span>
                          </td>
                          <td role="cell">
                            <label>Bedrooms</label>
                            <span>{propertyItem.Bedrooms}</span>
                          </td>
                          <td role="cell">
                            <label>Bathrooms</label>
                            <span>{propertyItem.BathFull}</span>
                          </td>
                        </tr>
                      </table>
                    </tr>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={18}>No Result</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {notFoundText === "true" ? (
        <p className="message_show">
          Sorry, we couldn't find any results for your search query. Please
          refine your search criteria and try again.
        </p>
      ) : (
        ""
      )}
      <div className="pagination mt-3">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeClassName="paginationActive"
        />
      </div>

      {renderMessageModal()}
      {handleModalShow()}
      {renderGoCheckoutModal()}

      {/* </div> */}
    </>
  );
};

export default PropertyListing;
