import React, { useEffect, useState, useRef } from "react";
import { PAYMENT_LIST_API_ENDPOINT } from "../Constants/api-routes.constant";
import { GetAuthToken } from "../Utils/auth-verification.util";
import { GetFormattedCurrency } from "../Utils/formating.util";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import {
  GetAllNotificationList,
  NotificationMarkAsRead,
  allNotificationsMarkAsRead,
} from "../Utils/notifications.util";
import DashbaordLayout from "./DashbaordLayout";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { BiBookmarkAlt } from "react-icons/bi";
import Notify from "./Status/Notify";

const NotificationList = (props) => {
 
  //const csvLink = useRef();

  const [notificationData, setNotificationData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);

  const [notificationUpdated, setNotificationUpdated] = useState(false);

  // Pagination
  const itemsPerPage = 20;
  const [totalCount, setTotalCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = notificationData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  useEffect(() => {
    document.title = "Notifications - Skipify.ai";
  }, []);
  

  useEffect(() => {
    GetNotificationList();
  }, [notificationUpdated]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalCount;
    setItemOffset(newOffset);
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    GetNotificationList(newOffset);
  };

  /**
   * Get Notification data
   * @returns
   */
  const GetNotificationList = (newOffset = 0) => {
    setShowLoader(true);

    GetAllNotificationList(newOffset)
      .then((response) => {
        if (response.statusCode == "200") {
          setNotificationData(response.result?.notifications);
          setTotalCount(response.result?.notificationCount);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setErrorMsg(error?.message);
        setShowLoader(false);
      });
  };

  /**
   * Handle checkbox selection
   * @param {*} e
   */
  const handleNotificationCheckbox = async (e) => {
    const notificationId = e.target.value;
    const invoice = notificationData.find((prp) => prp._id == notificationId);
    const newSet = selectedNotifications;
    const notifyIndex = newSet.findIndex((p) => p?._id == invoice._id);

    if (e.target.checked) {
      if (notifyIndex == -1) {
        newSet.push(invoice);
      }
    } else {
      if (notifyIndex >= 0) {
        delete newSet.splice(notifyIndex, 1);
      }
    }

    await setSelectedNotifications(newSet);
  };

  // Mark notifications as read
  const handleMarkAsReadBtn = async () => {

    if (!selectedNotifications.length) {
      const response = await allNotificationsMarkAsRead();
      setNotificationUpdated(!notificationUpdated);
      return;
    };

    const ids = selectedNotifications.map(
      (notificationItem) => notificationItem._id
    );

    NotificationMarkAsRead(ids).then((response) => {
      if (response.statusCode == 200) {
        console.log("thanksss")
        setSelectedNotifications([]);
        document
          .querySelectorAll("input[type=checkbox]")
          .forEach((el) => (el.checked = false));
        setNotificationUpdated(!notificationUpdated);
      }
    });
  };

  const pageTitle = "Notifications";
  

  return (
    <div>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex" id="wrapper">
        <DashbaordLayout title={pageTitle}>
          <div id="page-content-wrapper">
            {/* <!-- Page content--> */}
            <div className="table-data-main invoice_table">
              <div className="container">
                <Row className="justify-content-between">
                  <Col xs={12} md={9} lg={10}><h2 className="main-title-mobile">{pageTitle}</h2></Col>
                  <Col xs={12} md={3} lg={2}>
                    <Button
                      onClick={handleMarkAsReadBtn}
                      className="mb-3"
                    >
                      <BiBookmarkAlt /> Mark As Read
                    </Button>
                  </Col>
                </Row>
                <div className="resp-table2">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Sno</th>
                        <th width="150">Date</th>
                        <th>Title</th>
                        <th>Detail</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notificationData.length ? (
                        notificationData.map((item) => (
                          <tr key={item._id}>
                            <td>
                              <span className="custom-cont">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={item._id}
                                  onChange={handleNotificationCheckbox}
                                />
                              </span>
                            </td>
                            <td>
                              {item.notificationNumber}
                            </td>
                            <td>{moment(item.dateCreated).format("DD/MM/YYYY hh:mm")}</td>
                            <td>
                              {item.title}
                            </td>

                            <td>
                              <span className="amount">{item.details}</span>
                            </td>
                            <td>
                              <OverlayTrigger
                                placement="left"
                                overlay={
                                  <Tooltip>{item.isRead ? 'Read' : 'Unread'}</Tooltip>
                                }
                              >
                                <a href="#">  <Notify color={item.isRead == true ? 'gray' : 'blue'} /></a>
                              </OverlayTrigger>

                            </td>

                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5}>No record</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="table-data-main resp-table2">
                        <div className="pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            activeClassName="paginationActive"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DashbaordLayout>

      </div>
    </div>
  );
};

export default NotificationList;
