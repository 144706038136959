// PRE_DEFINED_FILTER
export const PRE_DEFINED_FILTER_CONSTANT = [
    {
        "label": "RealEstateMatt - Magic List",
        "value": 26
    },
    {
        "label": "RealEstateMatt - Recession List - Owner Occupied",
        "value": 18
    },
    {
        "label": "RealEstateMatt - Recession List - Non Owner Occ",
        "value": 19
    },
    {
        "label": "RealEstateMatt - Potential Distressed Builders",
        "value": 20
    },
    {
        "label": "RealEstateMatt - Mortgage Hack List",
        "value": 21
    },
    {
        "label": "RealEstateMatt - Pre-Foreclosure List",
        "value": 24
    },
    {
        "label": "RealEstateMatt - Propensity to Default List",
        "value": 25
    },
    {
        "label": "SFH to Quad - High Equity - Owner Occupied",
        "value": 1
    },
    {
        "label": "SFH to Quad - Hidden Equity - Owner Occupied",
        "value": 2
    },
    // {
    //     "label": "SFH to Quad - Missed High Equity",
    //     "value": 3
    // },
    {
        "label": "SFH to Quad - High Equity - Absentee",
        "value": 4
    },
    {
        "label": "SFH to Quad  - Tired Landlords - COVID",
        "value": 5
    },
    {
        "label": "SFR to Quad - Vacant",
        "value": 6
    },
    {
        "label": "SFR to Quad - Tired Landlords",
        "value": 7
    },
    {
        "label": "SFR to Quad - Cash Buyers - Last 1 yr",
        "value": 8
    },
    {
        "label": "SFR to Quad - Corporate Buyers - Last 1 yr",
        "value": 9
    },
    {
        "label": "SFR to Quad - Tax Delinquent",
        "value": 10
    },
    {
        "label": "SFR to Quad - Absentee   Vacant   Tax Delinq",
        "value": 11
    },
    {
        "label": "MFH - 10-30 Units Residential",
        "value": 12
    },
    {
        "label": "MFH - 30-100 Units Residential",
        "value": 13
    },
    {
        "label": "MFH - 100  Units Residential",
        "value": 14
    },
    {
        "label": "Lightning Leads",
        "value": 22
    },
    {
        "label": "Roofing Lightning Leads",
        "value": 23
    },
]