import { RiDownload2Fill } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import React from 'react'
import { Row, Col, Button, Image } from 'react-bootstrap'
import { IoIosArrowRoundBack, } from "react-icons/io";
import moment from "moment";
import { PROFILE_IMAGE_ENDPOINT } from "../../Constants/api-routes.constant";
import { GetFormattedCurrency, getFormattedCurrencyInInteger } from "../../Utils/formating.util";
import countryList from "react-select-country-list";

const Invoice = ({ setInvID, invObject }) => {

  const countries = countryList().getData().map(country => country);
  const CountryLabel = countries.find((cun) => cun.value === invObject?.country)
  const formatPhoneNumber = (phoneNumber) => {
    // Ensure the phoneNumber is a string
    phoneNumber = phoneNumber.toString();

    // Extract the different parts of the phone number
    // const countryCode = phoneNumber.substring(0, 1); // Assuming +1 as the country code
    const areaCode = phoneNumber.substring(0, 3);
    const firstPart = phoneNumber.substring(3, 6);
    const secondPart = phoneNumber.substring(6, 10);

    // Format the phone number
    const formattedPhoneNumber = ` (${areaCode}) ${firstPart}-${secondPart}`;

    return formattedPhoneNumber;
  }


  return (
    <div className="invoice-cst">
      <Row>
        <Col md={8} className='d-flex'>
          <div className="back_icon" onClick={() => setInvID('')}>
            <IoIosArrowRoundBack />
          </div>
          <h3 className='sub_title mx-2'>Invoice</h3>
          <h4 className='sub_title_h4 mt-2'>#INV-80{invObject?.paymentNumber}</h4>

        </Col>
        {/* <Col md={4} className='d-flex justify-content-end'>
                    <Button className="radius"><RiDownload2Fill className="mx-2" size={20} /> Download</Button>
                    <Button variant="muted" className="mx-3"><AiFillPrinter color="#00274e" size={25} /></Button>

                </Col> */}

      </Row>


      <Row className="d-flex justify-content-between my-3">
        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
          <p className="info_type">FROM</p>
          <div className="customer">
            <div className="image_div">
              <Image src={require('./../../assets/img/small-logo.jpg')} alt="logo" className="img-fluid" />
            </div>
            <div className="customer_info">
              <h4 className='sub_title_h4'>SKIPIFY.AI</h4>
              <a target="_blank" href="https://www.google.com/maps/place/Skipify.ai/@41.7966367,-88.0116752,15z/data=!4m6!3m5!1s0x880e4fdb7f0cecb9:0xd02e8a793c66b2ce!8m2!3d41.7966367!4d-88.0116752!16s%2Fg%2F11v3m7540d?entry=ttu">
                <p>4949 Forest Ave, First FL </p>
                <p>Downers Grove, IL 60515</p>
                <h5>United States</h5>
              </a>

              <ul>
                <li><a href="mailto:support@skipify.ai">support@skipify.ai</a></li>
                <li>Tel: <a href="tel:+1-414-751-0987">(414) 751-0987</a></li>
              </ul>

            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
          <p className="info_type">CLIENT</p>
          <div className="customer">
            <div className="image_div">
              {invObject?.profileImage ?
                <Image src={`${PROFILE_IMAGE_ENDPOINT}/${invObject?.profileImage}`} alt="logo" className="img-fluid" />
                :
                <Image src={require('./../../assets/img/avatar.png')} alt="logo" className="img-fluid" />
              }
            </div>
            <div className="customer_info">
              <h4 className='sub_title_h4'>{invObject?.clientName}</h4>

              <p>{invObject?.streetAddress !== undefined ? invObject?.streetAddress : ""} </p>
              <p>
                {invObject?.city != undefined ? invObject?.city + ',' : ""}
                {invObject?.state != undefined ? invObject?.state : ""}
                {invObject?.zipCode != undefined ? invObject?.zipCode : ""}
              </p>

              <h5> {invObject?.label !== undefined ? invObject?.label : ""}</h5>
              <ul>
                <li> {invObject?.clientEmail}</li>
                <li>Tel: {invObject?.phoneNumber && formatPhoneNumber(invObject?.phoneNumber)}</li>
                {/* <li>Tel: {formatPhoneNumber(invObject?.phoneNumber)}</li> */}
              </ul>

            </div>
          </div>
        </Col>
      </Row>

      <div className="divider"></div>

      <Row>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <p className="info_type">ITEM</p>
          <div className="date_section">
            <h3>{invObject?.ItemName}</h3>
            {!invObject?.flashSaleId ? (
              <div className="d-flex mt-3">
                <h5 className="mx-2">Time Period:</h5>
                <h6>{moment(invObject?.dateCreated).format(" MMMM Do YYYY")} </h6>
                {invObject?.dueDate && (<>
                  <h6 className="mx-2"> thru {moment(invObject?.dueDate).format(" MMMM Do YYYY")}</h6></>)
                }
              </div>
            ) : <h6>{moment(invObject?.dateCreated).format(" MMMM Do YYYY")} </h6>}
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>

          <div className="deital_head mt-4">
            <h6>ITEM DESCRIPTION</h6>
            <h6>QUANTITY</h6>
            <h6>AMOUNT</h6>
          </div>

          {invObject?.Items?.map((item, i) => (
            <div className="deital_head border_bottom">
              <h6>{item?.ItemDescripion}</h6>
              <h6>{item?.quantity != null ? getFormattedCurrencyInInteger(item?.quantity) : 1}</h6>
              <h6>{GetFormattedCurrency(item?.amount)}</h6>
            </div>

          ))}
        </Col>
      </Row>

      <div className="hr_devider" />

      <Row className="d-flex justify-content-end pt-2">
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          {/* <div className="deital_head ">
                        <h6>SUBTOTAL</h6>
                        <h6>$ 360.00</h6>
                    </div>

                    <div className="deital_head ">
                        <h6>TAX</h6>
                        <h6>2%</h6>
                    </div> */}

          <div className="deital_head ">
            <h6 className="sub_title_h4">TOTAL</h6>
            <h6 className="sub_title_h4">{GetFormattedCurrency(invObject?.TotalAmount)}</h6>
          </div>
        </Col>
      </Row>



    </div>
  )
}

export default Invoice