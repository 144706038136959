import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaMap, FaMapMarked, FaMapMarkerAlt } from "react-icons/fa";
import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { PropertyClassificationConstant } from "../../Constants/property/classification.constant";
import { SiteInfluenceConstant } from "../../Constants/site-influence.constant";
import {
  US_State_List
} from "../../Constants/us-states.constant";
import { React_Select_Style, React_Select_Style_State } from "../../Constants/react-select-style.constants";
import Select from "react-select";
import { US_County_List } from "../../Constants/us-county.constant";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { PropertyFilterUtil } from "../../Utils/property-filters.util";
import * as moment from "moment";
import { Col, Row, Form } from "react-bootstrap";
import { GetFormattedNumber } from "../../Utils/formating.util";
import { IoIosCloseCircle } from "react-icons/io";
import { GOOGLE_MAP_KEY } from "../../Constants/api-credentials.constant";
import Autocomplete from "react-google-autocomplete";
import "../../assets/css/main.css";
import { PreforeclosureStatusConstant } from "../../Constants/property/preforeclosure-status.constant";
import { GetCustomerCurrentPlanDetails } from "../../Utils/clients.util";
import { PRE_DEFINED_FILTER_CONSTANT } from "../../Constants/pre-defined-filter.constant";
import { WeatherEventDaysConstant, WeatherTypeConstant, WeatherTypeHailSizeConstant, WeatherTypeRainFallConstant, WeatherTypeTornadoConstant, WeatherTypeWindSpeedConstant } from "../../Constants/weather_filters.constant";
import { LikelyToDefaultConstant, UnLikelyToDefaultConstant, VeryLikelyToDefaultConstant } from "../../Constants/likely_to_default.constants";

const selectOccupancyOpt = [
  "No Preference",
  "Owner Occupuied",
  "Absentee Owner",
];
const OccupancyStatus = selectOccupancyOpt[0];

const selectVacancyOpt = ["No Preference", "Property Vacant", "Mailing Vacant"];
const VacancyStatus = selectOccupancyOpt[0];

class PropertySearchFilters extends React.Component {
  MAGIC_LIST_PRE_DEFINED_FILTER = 26;

  constructor(props) {
    super(props);

    this.state = {
      currentPlanData: props.currentPlanData,
      zipcodeCount: 0,
      countyDropdownList: US_County_List,
      placeholder: false,
      filters: "",
      propertyListing: [],
      searchZipCode: "",
      selectedState: "",
      stateShort: "",
      county: "",
      counties: [],
      city: "",
      street: "",
      addressPlaceHolder: "",
      // Default Filter
      defaultFilter: {
        label: "Select Pre-Defined Filter",
        value: 0,
      },
      selectedCounty: [],
      // Property Characteristics
      classification: [],
      selectedClassificationvalue: [],
      propertyType: [],
      selectTypesValue: [],
      propertyTypeOptions: [],
      siteInfluence: [],
      bedroomMin: "",
      bedroomMax: "",
      bathroomMin: "",
      bathroomMax: "",
      buildingAreaMin: "",
      buildingAreaMax: "",
      lotSizeMin: "",
      lotSizeMax: "",
      yearBuildMin: "",
      yearBuildMax: "",
      storyMin: "",
      storyMax: "",
      residentialUnitsMin: "",
      residentialUnitsMax: "",
      commercialUnitsMin: "",
      commercialUnitsMax: "",
      effectiveYearBuiltMin: "",
      effectiveYearBuiltMax: "",
      lotSizeAcresMin: "",
      lotSizeAcresMax: "",
      vacantFlag: "",

      // MLS
      MlsStatusOptions: [
        { value: "active", label: "Active" },
        { value: "pending", label: "Pending" },
        { value: "sold", label: "Sold" },
        { value: "cancelled", label: "Cancelled" },
      ],
      listingStatus: "",
      listingDate: "",
      listingDateMin: "",
      listingDateMax: "",
      daysOnMarketMin: "",
      daysOnMarketMax: "",

      // Ownership
      ownerType: "",
      yearsOfOwnershipMin: "",
      yearsOfOwnershipMax: "",
      lastSaleDateMin: "",
      lastSaleDateMax: "",
      lastSalePriceMin: "",
      lastSalePriceMax: "",
      ownerOccupied: "",
      coorporateOwned: "",
      isTaxDelinquent: "",
      taxDelinquentYearMin: "",
      taxDelinquentYearMax: "",
      mailingState: "",

      // Valuation and Equity
      estimatedValueMin: "",
      estimatedValueMax: "",
      equityPercentageMin: "",
      equityPercentageMax: "",
      assessedLandValueMin: "",
      assessedLandValueMax: "",
      assessedTotalValueMin: "",
      assessedTotalValueMax: "",
      marketLandValueMin: "",
      marketLandValueMax: "",
      marketTotalValueMin: "",
      marketTotalValueMax: "",

      // Mortgages
      mortgageInterestRateMin: "",
      mortgageInterestRateMax: "",
      mortgageAmountMin: "",
      mortgageAmountMax: "",
      mortgageRecordingDateMin: "",
      mortgageRecordingDateMax: "",

      // Preforeclosure
      preforeclosureStatus: [],
      preforeclosureStatusLabel: [],
      preforeclosureRecordingDateMin: "",
      preforeclosureRecordingDateMax: "",
      preforeclosureAuctionDateMin: "",
      preforeclosureAuctionDateMax: "",
      preforeclosureVeryLikeylyToDefault: "",
      preforeclosureLikeylyToDefault: "",
      preforeclosureUnLikeylyToDefault: "",
      preforeclosureExclude: "",
      preforeclosureActiveAuction: "",
      preforeclosureStatusSelectedValues: [],

      // Weather Filters
      weatherEventType: "",
      weatherEventTypeDataSelection: [],
      weatherEventTypeDataMin: "",
      weatherEventTypeDataMax: "",
      weatherEventLastDays: "",
      weatherEventMeasurementDefault: "",

      // Other
      showQuickFilter: "none",
      options: [
        {
          name: "Option",
          id: 1,
        },
      ],
      homeFeatures: [
        {
          name: "All",
          id: 1,
        },
        {
          name: "Residential",
          id: 2,
        },
        {
          name: "Commercial",
          id: 3,
        },
      ],
      tabIndex: 0
    };

    this.handleShowQuickFilter = this.handleShowQuickFilter.bind(this);
    this.onSelectClassification = this.onSelectClassification.bind(this);
    this.onSelectPropertyType = this.onSelectPropertyType.bind(this);
    this.onSelectSiteInfluence = this.onSelectSiteInfluence.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.onMlsListingDateChange = this.onMlsListingDateChange.bind(this);
    this.onMortgageRecordingDateChange =
      this.onMortgageRecordingDateChange.bind(this);
    this.onLastSaleDateChange = this.onLastSaleDateChange.bind(this);
    this.onSelectOwnerType = this.onSelectOwnerType.bind(this);
    this.handleAddressSearch = this.handleAddressSearch.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.onSelectPreforeclosureStatus = this.onSelectPreforeclosureStatus.bind(this);
    this.onRemovePreforeclosureStatus = this.onRemovePreforeclosureStatus.bind(this);
    this.onWeatherEventTypeSelection = this.onWeatherEventTypeSelection.bind(this);

    // refs for clear the checkbox after clear the state
    this.propertyCharacteristics = React.createRef();
    this.propertyType = React.createRef();
    this.siteInfluenc = React.createRef();
    this.ownerType = React.createRef();
    this.mailingState = React.createRef();
    this.preforeclosureStatusDropdown = React.createRef();
    this.weatherEventTypeDropdown = React.createRef();
    this.weatherEventMeasurementDropdown = React.createRef();
    this.weatherEventDaysDropdown = React.createRef();
  }

  // Property Characteristics handle
  handlePropertyCharacteristicsSelectedValues = () => {
    if (this.propertyCharacteristics.current) {
      this.propertyCharacteristics.current.resetSelectedValues();
    }
  };
  // Property Types  handle
  handlePropertyTypeSelectedValues = () => {
    if (this.propertyType.current) {
      this.propertyType.current.resetSelectedValues();
    }
  };

  // Site Influince handle
  handleSiteInfluenceSelectedValues = () => {
    if (this.siteInfluenc.current) {
      this.siteInfluenc.current.resetSelectedValues();
    }
  };

  // Onwer Type handle
  handleOwnerTypeSelectedValues = () => {
    if (this.ownerType.current) {
      this.ownerType.current.resetSelectedValues();
    }
  };
  
  handleClearPreforeclosureDropdown = () => {
    if (this.preforeclosureStatusDropdown.current) {
      this.preforeclosureStatusDropdown.current.resetSelectedValues();
    }
  }

  handleAddressSearch = (text) => {
    this.setState({
      selectedState: "",
      stateShort: "",
      county: "",
      city: "",
      street: "",
      addressPlaceHolder: "",
    });
  };

  handleSelectAddress = (place) => {
    let state = "";
    let stateShort = "";
    let county = "";
    const counties = [];
    let city = "";
    // Street Address components
    let streetNumber = "";
    let streetAddress = "";
    let streetSubPremise = "";
    let fullStreetAddress = "";
    let searchZipCode= "";
    let selectedCounty = []
       
    this.setState({ addressPlaceHolder: place });

    place.address_components.forEach((item) => {
      if (item.types.includes("administrative_area_level_1")) {
        state = item.long_name;
        stateShort = item.short_name;
      } else if (item.types.includes("administrative_area_level_2")) {
        county = item.short_name?.replace(/county/ig, "")?.trim();
        counties.push(county);
      } else if (item.types.includes("locality")) {
        city = item.long_name;
      }
      // Street Components
      if (item.types.includes("street_number")) {
        streetNumber = item.long_name;
      }
      if (item.types.includes("route")) {
        streetAddress = item.short_name;
      }
      if (item.types.includes("subpremise")) {
        streetSubPremise = item.short_name;
      }
      fullStreetAddress = `${streetNumber} ${streetAddress} ${streetSubPremise}`;
    });

    this.setState({
      selectedState: state,
      stateShort,
      county,
      city,
      street: fullStreetAddress?.trim(),
      counties,
      searchZipCode,
      selectedCounty
    }, this.handleSearchSubmit);
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
    }
  };
  /**
   * Handle counties selection
   * countyObj is Array: [{value:'Rock Island (IL)', label:'Rock Island (IL)'}]
   */
  handleCountyChange = async (countyObj) => {
    // Maximum 10 counties allowed to be selected
    if (countyObj.length > 10) {
      return;
    }
    this.setState({
      selectedState: "",
      stateShort: "",
      county: "",
      city: "",
      street: "",
      selectedCounty: [],
      searchZipCode: "",
    });

    // If empty array received then reset the dropdown to all US states counties
    if (!countyObj.length) {
      this.setState({
        countyDropdownList: US_County_List,
        counties: [],
      });
      return;
    }

    // Set the default value of the dropdown
    await this.setState({
      selectedCounty: countyObj,
    });

    // User can select multiple counties now
    const countiesList = [];
    for (const countyStateVal of countyObj) {
      const stateIndexStart = countyStateVal.value.indexOf("(");
      const stateIndexEnd = countyStateVal.value.indexOf(")");
      const county = countyStateVal.value.substring(0, stateIndexStart).trim();
      const state = countyStateVal.value
        .substring(stateIndexStart + 1, stateIndexEnd)
        .trim();

      if (!countiesList.includes(county)) countiesList.push(county);
      this.setState({
        county: county,
        counties: countiesList,
        stateShort: state,
      });

      // User can select counties from only ONE US state
      if (countiesList.length === 1 && state) {
        // Restrict the dropdown to show only ONE US state Counties
        const countyDropdown = this.state.countyDropdownList.filter(
          (dropdownOp) => dropdownOp.value.includes(`(${state.toUpperCase()})`)
        );
        await this.setState({
          countyDropdownList: countyDropdown,
        });

        this.setState({
          selectedState:
            US_State_List.find((st) => st.value == state)?.label || "",
        });
      }
    }
  };

  /**
   * Handle Zip Code entry
   * @param {*} zipCodeString
   */
  handleZipCodes = (e) => {
    const zipsArray = e.target.value.split(",");
    const zipcountarray = zipsArray.filter(item => item !== ''); // filter the empty values
    this.setState({ zipcodeCount: zipcountarray?.length })

    if (zipsArray?.length > 50) return;
    const zipCodes = zipsArray
      .map((zip) => zip?.trim() && zip?.trim()?.substring(0, 5))
      ?.join(",");
    this.setState({
      selectedState: "",
      stateShort: "",
      county: "",
      city: "",
      // selectedCounty: [],
      searchZipCode: "",
      street: "",
      counties: [],
    });

    this.setState({ searchZipCode: zipCodes });
    localStorage.setItem("zipcode", e.target.value.trim() ?? "");
  };

  onSelectClassification = async (selectedClassification) => {
    this.setState({ selectedClassificationvalue: selectedClassification });
    const classificationValue = selectedClassification?.map((clf) => clf.id);
    await this.setState({ classification: classificationValue });
    const propertyTypeFilters = await PropertyFilterUtil(
      this.state.classification,
      this.state.propertyTypeOptions,
      this.state.propertyType
    );
    this.setState({
      propertyTypeOptions: [... new Set(propertyTypeFilters.propertyTypeOptions)],
      propertyType: propertyTypeFilters.propertyType,
    });
  };

  onSelectPropertyType = async (selectTypes) => {
    this.setState({ selectTypesValue: selectTypes });
    const propertyTypeValues = selectTypes?.map((pType) => pType.value);
    await this.setState({ propertyType: selectTypes });
  };

  onSelectSiteInfluence = async (siteInf) => {
    const siteInfluencesVal = siteInf?.map((pType) => pType.value);
    await this.setState({ siteInfluence: siteInfluencesVal });
  };

  onSelectOwnerType = async (selectedOwnerType) => {
    const ownerType = selectedOwnerType?.map((pType) => pType.value);
    await this.setState({ ownerType: ownerType });
  };

  slideOffForMenu = () => {
    console.log("skude iff fir neby");
  };

  onSelectPreforeclosureStatus = (selectPreforeclosureStatus) => {
    const selectAll = selectPreforeclosureStatus.find((pfc) => pfc.id == "select_all");
    const pfcOptionSelected = selectAll ? PreforeclosureStatusConstant?.filter((pfc) => pfc.id != "select_all") : selectPreforeclosureStatus?.filter((pfc) => pfc.id != "select_all");
    const statusValue = pfcOptionSelected?.map((pfc) => pfc.id);
    const statusLabel = pfcOptionSelected?.map((pfc) => pfc.name);
    this.setState({ preforeclosureStatus: statusValue, preforeclosureStatusLabel: statusLabel});
    if(selectAll) {
      this.setState({ preforeclosureStatusSelectedValues: PreforeclosureStatusConstant  });
    } else {
      this.setState({ preforeclosureStatusSelectedValues: selectPreforeclosureStatus });
    }
    // Uncheck likely to default
    this.setState({ preforeclosureLikeylyToDefault: "", preforeclosureVeryLikeylyToDefault: "", preforeclosureUnLikeylyToDefault: ""})
  };
  onRemovePreforeclosureStatus = (selectedList, removeItem) => {
    if(removeItem.id == "select_all") {
     this.setState({ preforeclosureStatus: [], preforeclosureStatusLabel: [], preforeclosureStatusSelectedValues: []  });
    } else {
      const pfcAllOptions = selectedList?.filter((pfc) => pfc.id != "select_all");
      const statusValue = pfcAllOptions?.map((pfc) => pfc.id);
      const statusLabel = pfcAllOptions?.map((pfc) => pfc.name);
       this.setState({ preforeclosureStatus: statusValue, preforeclosureStatusLabel: statusLabel });
      if(selectedList.length  < PreforeclosureStatusConstant.length) {
        const pfcOptions = selectedList?.filter((pfc) => pfc.id != "select_all");
         this.setState({ preforeclosureStatusSelectedValues: pfcOptions });
      }
    }
  }

  onWeatherEventTypeSelection = (selected) => {
    this.setState({
      weatherEventType: selected?.value ?? "",
      weatherEventTypeDataSelection: [], 
      weatherEventMeasurementDefault: ""
    })
   if (this.weatherEventMeasurementDropdown.current) {
      this.weatherEventMeasurementDropdown.current.clearValue();
    }
    if(selected?.value == 'hail') {
      this.setState({weatherEventTypeDataSelection: WeatherTypeHailSizeConstant, 
        weatherEventTypeDataMin: 0, 
        weatherEventTypeDataMax: 1000
      })
      
    }
    if(selected?.value == 'rain') {
      this.setState({weatherEventTypeDataSelection: WeatherTypeRainFallConstant, 
        weatherEventTypeDataMin: 0, 
        weatherEventTypeDataMax: 1000
      })
    }
    if(selected?.value == 'tornado') {
      this.setState({weatherEventTypeDataSelection: WeatherTypeTornadoConstant})
    }
    if(selected?.value == 'wind') {
      this.setState({weatherEventTypeDataSelection: WeatherTypeWindSpeedConstant,
        weatherEventTypeDataMin: 60, 
        weatherEventTypeDataMax: 1000
      })
    }
  }

  // Show/Hide quick filters pane
  handleShowQuickFilter() {
    if (this.state.showQuickFilter === "none") {
      this.setState({ showQuickFilter: "" });
    } else {
      this.setState({ showQuickFilter: "none" });
    }
  }

  componentDidMount() {
    const slideBtn = document.querySelector(".iconRemove_icon_div_rotate");

    slideBtn.addEventListener("click", function () {
      let slideContainer = document.querySelector(".slideContainer");

      if (this.classList.contains("iconRemove_icon_div_rotate")) {
        slideContainer.classList.remove("slideShow");
        slideBtn.classList.remove("iconRemove_icon_div_rotate");
        slideBtn.classList.add("iconRemove_icon_div");
      } else {
        slideBtn.classList.remove("iconRemove_icon_div");
        slideBtn.classList.add("iconRemove_icon_div_rotate");
        slideContainer.classList.add("slideShow");
      }
    });

    GetCustomerCurrentPlanDetails().then((response) => {
      if(response.statusCode == 200) {
        this.setState({currentPlanData: response.result})
      }
    })

    localStorage.removeItem('predefined_filter_value');
  }

  // Mls Listing Date change
  onMlsListingDateChange(dates) {
    const [start, end] = dates;
    this.setState({
      listingDateMin: start,
      listingDateMax: end,
    });
  }
  onLastSaleDateChange(dates) {
    const [start, end] = dates;
    this.setState({
      lastSaleDateMin: start,
      lastSaleDateMax: end,
    });
  }
  onMortgageRecordingDateChange(dates) {
    const [start, end] = dates;
    this.setState({
      mortgageRecordingDateMin: start,
      mortgageRecordingDateMax: end,
    });
  }

  // Search submit action
  handleSearchSubmit = async () => {
    if(this.state.tabIndex === 0){
      this.setState({searchZipCode: "", selectedCounty: []})
    }else if(this.state.tabIndex === 1){
      this.setState({searchZipCode: "", addressPlaceHolder: ""})
    }else if(this.state.tabIndex === 2){
      this.setState({addressPlaceHolder: "", selectedCounty: []})
    }
    this.setState({ showQuickFilter: "none" });

    const zipStateCityCounty = {
      zipCode: this.state.searchZipCode,
      state: this.state.stateShort,
    };

    if (this.state.city) {
      zipStateCityCounty.city = this.state.city;
    } else if (this.state.county) {
      // zipStateCityCounty.county = this.state.county;
      zipStateCityCounty.counties = this.state.counties;
    }

    if (this.state.street) {
      zipStateCityCounty.street = this.state.street;
      // console.log("Got here...", zipStateCityCounty.street)
    }

    const propertyCharacteristics = {
      classification: this.state.classification,
      propertyTypes: this.state.propertyType,
      bedroomMin: this.state.bedroomMin,
      bedroomMax: this.state.bedroomMax,
      bathroomMin: this.state.bathroomMin,
      bathroomMax: this.state.bathroomMax,
      buildingAreaMin: this.state.buildingAreaMin,
      buildingAreaMax: this.state.buildingAreaMax,
      yearBuildMin: this.state.yearBuildMin,
      yearBuildMax: this.state.yearBuildMax,
      effectiveYearBuiltMin: this.state.effectiveYearBuiltMin,
      effectiveYearBuiltMax: this.state.effectiveYearBuiltMax,
      residentialUnitsMin: this.state.residentialUnitsMin,
      residentialUnitsMax: this.state.residentialUnitsMax,
      commercialUnitsMin: this.state.commercialUnitsMin,
      commercialUnitsMax: this.state.commercialUnitsMax,
      lotSizeMin: this.state.lotSizeMin,
      lotSizeMax: this.state.lotSizeMax,
      lotSizeAcresMin: this.state.lotSizeAcresMin,
      lotSizeAcresMax: this.state.lotSizeAcresMax,
      vacantFlag: this.state.vacantFlag,
      siteInfluence: this.state.siteInfluence,
    };

    const mls = {
      listingDateMin: this.state.listingDateMin,
      listingDateMax: this.state.listingDateMax,
      listingStatus: this.state.listingStatus,
      daysOnMarketMin: this.state.daysOnMarketMin,
      daysOnMarketMax: this.state.daysOnMarketMax,
    };

    const preforeclosure = {
      preforeclosureStatus: this.state.preforeclosureStatus,
      preforeclosureRecordingDateMin: this.state.preforeclosureRecordingDateMin,
      preforeclosureRecordingDateMax: this.state.preforeclosureRecordingDateMax,
      preforeclosureAuctionDateMin: this.state.preforeclosureAuctionDateMin,
      preforeclosureAuctionDateMax: this.state.preforeclosureAuctionDateMax,
      preforeclosureVeryLikeylyToDefault: this.state.preforeclosureVeryLikeylyToDefault,
      preforeclosureLikeylyToDefault: this.state.preforeclosureLikeylyToDefault,
      preforeclosureUnLikeylyToDefault: this.state.preforeclosureUnLikeylyToDefault,
      preforeclosureExclude: this.state.preforeclosureExclude,
      preforeclosureActiveAuction: this.state.preforeclosureActiveAuction
    };

    const owner = {
      ownerType: this.state.ownerType,
      yearsOfOwnershipMin: this.state.yearsOfOwnershipMin,
      yearsOfOwnershipMax: this.state.yearsOfOwnershipMax,
      lastSaleDateMin: this.state.lastSaleDateMin,
      lastSaleDateMax: this.state.lastSaleDateMax,
      lastSalePriceMin: this.state.lastSalePriceMin,
      lastSalePriceMax: this.state.lastSalePriceMax,
      ownerOccupied: this.state.ownerOccupied,
      // coorporateOwned: this.state.coorporateOwned,
      isTaxDelinquent: this.state.isTaxDelinquent,
      taxDelinquentYearMin: this.state.taxDelinquentYearMin,
      taxDelinquentYearMax: this.state.taxDelinquentYearMax,
      mailingState: this.state.mailingState
    };

    const valuation = {
      estimatedValueMin: this.state.estimatedValueMin,
      estimatedValueMax: this.state.estimatedValueMax,
      equityPercentageMin: this.state.equityPercentageMin,
      equityPercentageMax: this.state.equityPercentageMax,
      assessedLandValueMin: this.state.assessedLandValueMin,
      assessedLandValueMax: this.state.assessedLandValueMax,
      assessedTotalValueMin: this.state.assessedTotalValueMin,
      assessedTotalValueMax: this.state.assessedTotalValueMax,
      marketLandValueMin: this.state.marketLandValueMin,
      marketLandValueMax: this.state.marketLandValueMax,
      marketTotalValueMin: this.state.marketTotalValueMin,
      marketTotalValueMax: this.state.marketTotalValueMax,
    };

    const mortgage = {
      mortgageInterestRateMin: this.state.mortgageInterestRateMin,
      mortgageInterestRateMax: this.state.mortgageInterestRateMax,
      mortgageAmountMin: this.state.mortgageAmountMin,
      mortgageAmountMax: this.state.mortgageAmountMax,
      mortgageRecordingDateMin: this.state.mortgageRecordingDateMin,
      mortgageRecordingDateMax: this.state.mortgageRecordingDateMax,
    };

    const weather = {
      weatherEventType: this.state.weatherEventType,
      weatherEventTypeDataMin: this.state.weatherEventTypeDataMin,
      weatherEventTypeDataMax: this.state.weatherEventTypeDataMax,
      weatherEventLastDays: this.state.weatherEventLastDays
    }
    
    const filter = {
      ...zipStateCityCounty,
      propertyCharacteristics,
      mls,
      preforeclosure,
      owner,
      valuation,
      mortgage,
      weather
    };

    localStorage.setItem("queryFilter", JSON.stringify(filter));
    await this.props.apiCall(filter, 0, true);
  };

  // Clear All The Filter Of filter
  handleClearAllFilter = (type) => {
    // when  type = 2 its reset only default filter value other wise empty all state
    document.getElementById("main_search_form").reset();
    this.handlePropertyTypeSelectedValues();
    this.handleSiteInfluenceSelectedValues();
    this.handleOwnerTypeSelectedValues();
    this.handleClearPreforeclosureDropdown();
    if (this.mailingState.current) {
      this.mailingState.current.clearValue()
    }
    if (this.weatherEventDaysDropdown.current) {
      this.weatherEventDaysDropdown.current.clearValue()
    }
    if (this.weatherEventTypeDropdown.current) {
      this.weatherEventTypeDropdown.current.clearValue()
    }
    if (this.weatherEventMeasurementDropdown.current) {
      this.weatherEventMeasurementDropdown.current.clearValue()
    }
    localStorage.removeItem('predefined_filter_value');
    if (type != 2) {
      this.setState({
        searchZipCode: "",
        selectedState: "",
        stateShort: "",
        county: "",
        city: "",
        selectedCounty: [],
        street: "",
        addressPlaceHolder: "",
        counties: [],
        countyDropdownList: US_County_List,
        defaultFilter: {
          label: "Select Pre-Defined Filter",
          value: 0,
        },
      });
    }

    this.setState({
      filters: "",
      propertyListing: [],
      // Property Characteristics

      selectedClassificationvalue: [],
      classification: [],
      propertyType: [],
      propertyTypeOptions: [],
      siteInfluence: [],
      bedroomMin: "",
      bedroomMax: "",
      bathroomMin: "",
      bathroomMax: "",
      buildingAreaMin: "",
      buildingAreaMax: "",
      lotSizeMin: "",
      lotSizeMax: "",
      yearBuildMin: "",
      yearBuildMax: "",
      storyMin: "",
      storyMax: "",
      residentialUnitsMin: "",
      residentialUnitsMax: "",
      commercialUnitsMin: "",
      commercialUnitsMax: "",
      effectiveYearBuiltMin: "",
      effectiveYearBuiltMax: "",
      lotSizeAcresMin: "",
      lotSizeAcresMax: "",
      vacantFlag: "",

      // MLS
      MlsStatusOptions: [
        { value: "active", label: "Active" },
        { value: "pending", label: "Pending" },
        { value: "sold", label: "Sold" },
        { value: "cancelled", label: "Cancelled" },
      ],
      listingStatus: "",
      listingDate: "",
      listingDateMin: "",
      listingDateMax: "",
      daysOnMarketMin: "",
      daysOnMarketMax: "",

      // Preforeclosure
      preforeclosureStatus: [],
      preforeclosureStatusLabel: [],
      preforeclosureAuctionDateMin: "",
      preforeclosureAuctionDateMax: "",
      preforeclosureRecordingDateMin: "",
      preforeclosureRecordingDateMax: "",
      preforeclosureVeryLikeylyToDefault: "",
      preforeclosureLikeylyToDefault: "",
      preforeclosureUnLikeylyToDefault: "",
      preforeclosureExclude: "",
      preforeclosureActiveAuction: "",

      // Ownership
      ownerType: "",
      yearsOfOwnershipMin: "",
      yearsOfOwnershipMax: "",
      lastSaleDateMin: "",
      lastSaleDateMax: "",
      lastSalePriceMin: "",
      lastSalePriceMax: "",
      ownerOccupied: "",
      coorporateOwned: "",
      isTaxDelinquent: "",
      taxDelinquentYearMin: "",
      taxDelinquentYearMax: "",
      mailingState: "",

      // Valuation and Equity
      estimatedValueMin: "",
      estimatedValueMax: "",
      equityPercentageMin: "",
      equityPercentageMax: "",
      assessedLandValueMin: "",
      assessedLandValueMax: "",
      assessedTotalValueMin: "",
      assessedTotalValueMax: "",
      marketLandValueMin: "",
      marketLandValueMax: "",
      marketTotalValueMin: "",
      marketTotalValueMax: "",

      // Mortgages
      mortgageInterestRateMin: "",
      mortgageInterestRateMax: "",
      mortgageAmountMin: "",
      mortgageAmountMax: "",
      mortgageRecordingDateMin: "",
      mortgageRecordingDateMax: "",

      // Weather
      weatherEventType: "",
      weatherEventTypeDataMin: "",
      weatherEventTypeDataMax: "",
      weatherEventLastDays: ""
    });
  };

  // for changing to upper case the letter
  handleUpperCase = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  handleMinAndMax = (post = "", min, max, pre = "") => {
    if (min != "" && max == "") {
      // when min is not empty and max is empty
      return " >= " + post + GetFormattedNumber(min) + pre;
    } else if (min == "" && max != "") {
      // when max is not empty and min is empty
      return " <= " + post + GetFormattedNumber(max) + pre;
    } else if (min != "" && max != "") {
      // when both are not empty
      return (
        post +
        GetFormattedNumber(min) +
        pre +
        " - " +
        post +
        GetFormattedNumber(max) +
        pre
      );
    }
  };

  handleDateMinAndMax = (min, max) => {
    if (min != "" && max == "") {
      // when min is not empty and max is empty
      return " >= " + min;
    } else if (min == "" && max != "") {
      // when max is not empty and min is empty
      return " <= " + max;
    } else if (min != "" && max != "") {
      // when both are not empty
      return min + " - " + max;
    }
  };

  handleDefaultFilter = (obj) => {
    this.setState({ defaultFilter: obj });
    const { value } = obj;
    this.handleClearAllFilter(2);
    localStorage.setItem('predefined_filter_value', obj.value);
    
    const defaultClas = [{ name: "Residential", id: "residential" }];

    if (value === 1) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({
        ownerOccupied: "y",
        listingStatus: "n",
        yearsOfOwnershipMin: 15,
        mortgageRecordingDateMin: "",
        mortgageRecordingDateMax: '2012-01-01',
      });

    } else if (value === 2) {
      this.onSelectClassification(defaultClas);

      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);

      this.setState({
        ownerOccupied: "y", yearsOfOwnershipMin: 20, listingStatus: "n", mortgageRecordingDateMin: "",
        mortgageRecordingDateMax: '2008-01-01',
      });

    } else if (value === 3) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({
        ownerOccupied: "y", yearsOfOwnershipMin: 20, equityPercentageMax: 60, listingStatus: "n", mortgageRecordingDateMin: "",
        mortgageRecordingDateMax: '2008-01-01',
      });

    } else if (value === 4 || value === this.MAGIC_LIST_PRE_DEFINED_FILTER) {
      // Magic List filter is same as SFR High Equity Absentee
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({
        ownerOccupied: "n", yearsOfOwnershipMin: 10, listingStatus: "n", mortgageRecordingDateMin: "",
        mortgageRecordingDateMax: '2012-01-01',
      });

    } else if (value === 5) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({
        ownerOccupied: "n", listingStatus: "n", lastSaleDateMin: "2019-01-01",
        lastSaleDateMax: '2020-12-31'
      });

    } else if (value === 6) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({ vacantFlag: "y",ownerOccupied: "n", listingStatus: "n" });
    } else if (value === 7) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({ ownerOccupied: "n", listingStatus: "n", yearsOfOwnershipMin: 10 });

    } else if (value === 8) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({ ownerOccupied: "n", yearsOfOwnershipMax: 1, equityPercentageMin: 100 });

    } else if (value === 9) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({ ownerOccupied: "n", ownerType: ['company'], yearsOfOwnershipMax: 1 });
    } else if (value === 10) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({ isTaxDelinquent: "y", listingStatus: "n" });
    } else if (value === 11) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({ isTaxDelinquent: "y", ownerOccupied: "n", vacantFlag: "y", listingStatus: "n" });

    } else if (value === 12) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        {
          value: "1100",
          label: "Income General (Multi-Family)",
          category: "Residential",
        },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
        {
          value: "1105",
          label: "Apartment house (5+ units)",
          category: "Residential",
        },
        {
          value: "1106",
          label: "Garden Apt, Court Apt (5+ units)",
          category: "Residential",
        },
        {
          value: "1107",
          label: "Highrise Apartments",
          category: "Residential",
        },
        // {
        //   value: "1108",
        //   label:
        //     "Boarding House, Rooming House, Apt Hotel, Transient Lodgings, Hostel",
        //   category: "Residential",
        // },
        // {
        //   value: "1109",
        //   label: "Mobile Home or Trailer Park",
        //   category: "Residential",
        // },
        {
          value: "1110",
          label: "Multi-Family Dwellings (Generic, 2+)",
          category: "Residential",
        },
        // {
        //   value: "1111",
        //   label: "Fratenity or Sorority House",
        //   category: "Residential",
        // },
        {
          value: "1112",
          label: "Apartments (Generic)",
          category: "Residential",
        },
        // {
        //   value: "1113",
        //   label: "Dormitory, Group Quarters",
        //   category: "Residential",
        // },
        {
          value: "1114",
          label: "Condominium Development (Association Assessment)",
          category: "Residential",
        },
        // { value: "1902", label: "Storate Space", category: "Residential" },
        // {
        //   value: "1999",
        //   label: "Single Family Residential Assumed",
        //   category: "Residential",
        // },
        {
          value: "1124",
          label: "Condominium Building",
          category: "Residential",
        },
        // {
        //   value: "1125",
        //   label: "Corporative Building",
        //   category: "Residential",
        // },
      ]);
      this.setState({ residentialUnitsMin: "10", residentialUnitsMax: "30" });
    } else if (value === 13) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([ {
        value: "1100",
        label: "Income General (Multi-Family)",
        category: "Residential",
      },
      {
        value: "1101",
        label: "Duplex (2 units, any combination)",
        category: "Residential",
      },
      {
        value: "1102",
        label: "Triplex (3 units, any combination)",
        category: "Residential",
      },
      {
        value: "1104",
        label: "Quadruplex (4 units, any combination)",
        category: "Residential",
      },
      {
        value: "1105",
        label: "Apartment house (5+ units)",
        category: "Residential",
      },
      {
        value: "1106",
        label: "Garden Apt, Court Apt (5+ units)",
        category: "Residential",
      },
      {
        value: "1107",
        label: "Highrise Apartments",
        category: "Residential",
      },
      // {
      //   value: "1108",
      //   label:
      //     "Boarding House, Rooming House, Apt Hotel, Transient Lodgings, Hostel",
      //   category: "Residential",
      // },
      // {
      //   value: "1109",
      //   label: "Mobile Home or Trailer Park",
      //   category: "Residential",
      // },
      {
        value: "1110",
        label: "Multi-Family Dwellings (Generic, 2+)",
        category: "Residential",
      },
      // {
      //   value: "1111",
      //   label: "Fratenity or Sorority House",
      //   category: "Residential",
      // },
      {
        value: "1112",
        label: "Apartments (Generic)",
        category: "Residential",
      },
      // {
      //   value: "1113",
      //   label: "Dormitory, Group Quarters",
      //   category: "Residential",
      // },
      {
        value: "1114",
        label: "Condominium Development (Association Assessment)",
        category: "Residential",
      },
      // { value: "1902", label: "Storate Space", category: "Residential" },
      // {
      //   value: "1999",
      //   label: "Single Family Residential Assumed",
      //   category: "Residential",
      // },
      {
        value: "1124",
        label: "Condominium Building",
        category: "Residential",
      },
      // {
      //   value: "1125",
      //   label: "Corporative Building",
      //   category: "Residential",
      // },
      ]);
      this.setState({ residentialUnitsMin: "30", residentialUnitsMax: "100" });
    } else if (value === 14) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        {
          value: "1100",
          label: "Income General (Multi-Family)",
          category: "Residential",
        },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
        {
          value: "1105",
          label: "Apartment house (5+ units)",
          category: "Residential",
        },
        {
          value: "1106",
          label: "Garden Apt, Court Apt (5+ units)",
          category: "Residential",
        },
        {
          value: "1107",
          label: "Highrise Apartments",
          category: "Residential",
        },
        // {
        //   value: "1108",
        //   label:
        //     "Boarding House, Rooming House, Apt Hotel, Transient Lodgings, Hostel",
        //   category: "Residential",
        // },
        // {
        //   value: "1109",
        //   label: "Mobile Home or Trailer Park",
        //   category: "Residential",
        // },
        {
          value: "1110",
          label: "Multi-Family Dwellings (Generic, 2+)",
          category: "Residential",
        },
        // {
        //   value: "1111",
        //   label: "Fratenity or Sorority House",
        //   category: "Residential",
        // },
        {
          value: "1112",
          label: "Apartments (Generic)",
          category: "Residential",
        },
        // {
        //   value: "1113",
        //   label: "Dormitory, Group Quarters",
        //   category: "Residential",
        // },
        {
          value: "1114",
          label: "Condominium Development (Association Assessment)",
          category: "Residential",
        },
        // { value: "1902", label: "Storate Space", category: "Residential" },
        // {
        //   value: "1999",
        //   label: "Single Family Residential Assumed",
        //   category: "Residential",
        // },
        {
          value: "1124",
          label: "Condominium Building",
          category: "Residential",
        },
        // {
        //   value: "1125",
        //   label: "Corporative Building",
        //   category: "Residential",
        // },
      ]);
      this.setState({ residentialUnitsMin: "100" });
    } else if (value === 15) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
      ]);
      this.setState({
        yearBuildMin: "1949", ownerOccupied: "y", estimatedValueMax: 300000, mortgageRecordingDateMin: '2012-01-01',
        mortgageRecordingDateMax: new Date()
      });
    } else if (value === 16) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
      ]);
      this.setState({
        yearBuildMin: "1949", ownerOccupied: "y", mortgageRecordingDateMin: '2012-01-01',
        mortgageRecordingDateMax: new Date(), estimatedValueMax: 300000
      });

    } else if (value === 17) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
      ]);
      this.setState({ yearBuildMin: "1949", vacantFlag: "y", estimatedValueMax: 300000 });
    } else if (value === 18) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({
        ownerOccupied: "y",
        lastSaleDateMin: '2010-01-01',
        lastSaleDateMax: '2014-12-31',
        mortgageRecordingDateMin: "",
        mortgageRecordingDateMax: '2014-12-31',
        listingStatus: "n"
      });

    } else if (value === 19) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({
        ownerOccupied: "n",
        lastSaleDateMin: "2010-01-01",
        lastSaleDateMax: "2014-01-01",
        mortgageRecordingDateMin: "",
        mortgageRecordingDateMax: '2014-12-31',
        listingStatus: "n"
      });

    } else if (value === 20) {
      this.onSelectClassification(defaultClas);
      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);
      this.setState({
        ownerOccupied: "y",
        lastSaleDateMin: "2019-01-01",
        lastSaleDateMax: "2022-01-01",
        mortgageAmountMin: 1,
        listingStatus: "n"
      });


    } else if (value === 21) {
      this.onSelectClassification(defaultClas);

      this.onSelectPropertyType([
        { value: "1000", label: "General (Single)", category: "Residential" },
        { value: "1001", label: "Single Family", category: "Residential" },
        {
          value: "1101",
          label: "Duplex (2 units, any combination)",
          category: "Residential",
        },
        {
          value: "1102",
          label: "Triplex (3 units, any combination)",
          category: "Residential",
        },
        {
          value: "1104",
          label: "Quadruplex (4 units, any combination)",
          category: "Residential",
        },
      ]);

      this.setState({
        ownerOccupied: 'y',
        yearsOfOwnershipMin: 10,
        listingStatus: "n",
        mortgageRecordingDateMax: new Date(moment().subtract(10, 'years').format('MM/DD/YYYY'))
      })
    } else if( value === 22) {
      this.onSelectPropertyType([
        { value: "1001", label: "Single Family", category: "Residential" },
        { value: "1999", label: "Single Family Residential Assumed", category: "Residential" },
      ]);
      this.setState({
        ownerOccupied: 'y'
      })
    } else if(value === 23) {
      this.onSelectPropertyType([
        { value: "1001", label: "Single Family", category: "Residential" },
        { value: "1999", label: "Single Family Residential Assumed", category: "Residential" },
      ]);
      this.setState({
        ownerOccupied: 'y',
        yearBuildMax: 2008
      })
    } else if(value == 24 && !this.state.currentPlanData?.packageIsFree) { // MattRealEstate - Pre-Foreclosure List
      this.setState({
        preforeclosureStatusSelectedValues: PreforeclosureStatusConstant,
        preforeclosureStatus: PreforeclosureStatusConstant.filter((ps) => ps.id != "select_all").map((p) => p.id),
        preforeclosureStatusLabel: PreforeclosureStatusConstant.filter((ps) => ps.id != "select_all").map((p) => p.name),
        preforeclosureVeryLikeylyToDefault:"", 
        preforeclosureLikeylyToDefault:"", 
        preforeclosureUnlikelyLikeylyToDefault:"", 
        listingStatus: 'n'
      })
    } else if(value == 25 && !this.state.currentPlanData?.packageIsFree) {  // MattRealEstate - Propensity to Default List
      this.setState({
        preforeclosureLikeylyToDefault: LikelyToDefaultConstant,
        preforeclosureVeryLikeylyToDefault: VeryLikelyToDefaultConstant,
        preforeclosureStatusSelectedValues: [],
        preforeclosureStatus: [],
        preforeclosureStatusLabel: [],
        listingStatus: 'n'
      })
    }
  };

  handleMaxGreaterThanMin = (min, max) => {
    if (max >= min) {
      return max;
    } else {
      return 0;
    }
  };



  render() {
    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0">
            <div className="search-container searchAddress">
              <form id="main_search_form">
                <Tabs selectedIndex={this.state.tabIndex} onSelect={(index) => this.setState({tabIndex: index})}>
                  <div className="tabs_top_main">
                    <TabList>
                      <Tab>
                        {" "}
                        <FaMapMarkerAlt /> Address
                      </Tab>
                      <Tab>
                        <FaMap /> County
                      </Tab>
                      <Tab>
                        <FaMapMarked /> Zip Codes
                      </Tab>
                    </TabList>
                  </div>

                  <TabPanel className="search react-tabs__tab-panel">
                    <Autocomplete
                      apiKey={GOOGLE_MAP_KEY}
                      onPlaceSelected={(place) =>
                        this.handleSelectAddress(place)
                      }
                      options={{
                        types: ["geocode"],
                        componentRestrictions: { country: "us" },
                      }}
                      className="mb-4 searchAddressInput"
                      onChange={this.handleAddressSearch}
                      onKeyPress={this.handleKeyPress}
                      placeholder={
                        this.state.addressPlaceHolder
                          ? this.state.addressPlaceHolder?.formatted_address
                          : "Enter an Address"
                      }
                    />
                  </TabPanel>
                  <TabPanel className="search react-tabs__tab-panel">
                    <Select
                      value={this.state.selectedCounty}
                      onChange={this.handleCountyChange}
                      options={this.state.countyDropdownList}
                      styles={React_Select_Style}
                      name="county"
                      className=""
                      isMulti
                      placeholder={"Select County"}
                    />
                    <span style={{ fontSize: "10px" }} className="mb-2">
                      Maximum 10 counties.
                    </span>
                  </TabPanel>
                  <TabPanel className="search react-tabs__tab-panel">
                    <input
                      type="search"
                      placeholder="Enter a zip code"
                      name="zipCode"
                      onChange={this.handleZipCodes}
                      value={this.state?.searchZipCode}
                      onKeyDown={this.handleKeyPress}
                      className="searchAddressInput"
                    />
                    <span style={{ fontSize: "10px" }} className="mb-2">
                      Or enter mutiple zip codes by comma separated (e.g. 12345,
                      56789, 78901). Maximum {50 - this.state.zipcodeCount} zip codes.
                    </span>
                  </TabPanel>
                </Tabs>
              </form>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="p-0">
            <div className="tabs_top_main_address">
              <div className="filterNav">
                <button onClick={this.handleSearchSubmit}>
                  <FontAwesomeIcon
                    className="pe-2 iconRemove"
                    icon={faSearch}
                  />
                  Search
                </button>
                <button onClick={this.handleShowQuickFilter}>
                  <FontAwesomeIcon
                    className="pe-2 iconRemove"
                    icon={faFilter}
                  />
                  Quick-Filter
                </button>
                <button className="reset" onClick={this.handleClearAllFilter}>
                  Reset
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="filterArea">
          <Row
            className="my-3"
            style={{ display: `${this.state.showQuickFilter}` }}
          >
            <Col xs={12} sm={12} md={12} lg={8}>
              <div className="mian_filter_class">
                <div className="containerOfFilter">
                  <div className="head-filter">
                    <Row>
                      <Col xs={10} md={10}>
                        <h4 className="mt-2">Advance Search Options</h4>
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        className="text-center d-flex justify-content-center"
                      >
                        <div
                          className="iconRemove_icon_div_rotate mx-3"
                          
                        >
                          <FontAwesomeIcon
                            className="iconRemove_icon"
                            icon={faChevronUp}
                          />
                        </div>

                        <FontAwesomeIcon
                          className="iconRemove mt-2 ms-2"
                          icon={faClose}
                          onClick={this.handleShowQuickFilter}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="body-filter">
                    <Row className="quickFilter-row ">
                      <Col md={4}>
                        <h6>Occupancy Status</h6>
                        <Dropdown
                          options={selectOccupancyOpt}
                          value={OccupancyStatus}
                          className="select-search"
                          placeholder={"Select Occupancy"}
                        />
                      </Col>
                      <Col md={4}>
                        <h6>Vacancy Status</h6>
                        <Dropdown
                          options={selectVacancyOpt}
                          value={VacancyStatus}
                          className="select-search"
                          placeholder={"Select Vacancy"}
                        />
                      </Col>
                    </Row>

                    <Accordion allowZeroExpanded>
                      {/* pre Default Filter */}
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Pre-Defined Filter
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="filter_default">
                            {PRE_DEFINED_FILTER_CONSTANT.map((filt, f) => (
                              <li
                                key={f} className={this.state.defaultFilter?.value == filt?.value ? 'active' : ''}
                                onClick={() => this.handleDefaultFilter(filt)}
                              ><BsFillPatchCheckFill /> {filt?.label}</li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                      {/* Property Characteristics */}
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Property Characteristics
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Row>
                            <Col md={4}>
                              <Multiselect
                                className="form-control mb-2"
                                ref={this.propertyCharacteristics}
                                options={PropertyClassificationConstant}
                                onSelect={this.onSelectClassification}
                                onRemove={this.onSelectClassification}
                                displayValue="name"
                                selectedValues={
                                  this.state.selectedClassificationvalue
                                }
                                placeholder={
                                  "All Classifications " +
                                  (this.state.classification.length > 0
                                    ? `(${this.state.classification.length})`
                                    : "")
                                }
                                name="propertyType"
                                showCheckbox
                                showArrow
                                hideSelectedList
                              />
                            </Col>
                            <Col md={4}>
                              <Multiselect
                                className="form-control mb-2"
                                ref={this.propertyType}
                                options={this.state.propertyTypeOptions}
                                onSelect={this.onSelectPropertyType}
                                onRemove={this.onSelectPropertyType}
                                displayValue="label"
                                placeholder={
                                  "Property Types " +
                                  (this.state.propertyType.length > 0
                                    ? `(${this.state.propertyType.length})`
                                    : "")
                                }
                                selectedValues={this.state.propertyType}
                                name="propertyTypeDetailCode"
                                showCheckbox
                                showArrow
                                hideSelectedList
                                groupBy="category"
                              />
                            </Col>
                            <Col md={4}>
                              <Multiselect
                                className="form-control mb-2"
                                ref={this.siteInfluenc}
                                options={SiteInfluenceConstant}
                                onSelect={this.onSelectSiteInfluence}
                                onRemove={this.onSelectSiteInfluence}
                                displayValue="label"
                                placeholder={
                                  "Site Influence " +
                                  (this.state.siteInfluence.length > 0
                                    ? `(${this.state.siteInfluence.length})`
                                    : "")
                                }
                                name="siteInfluence"
                                showCheckbox
                                showArrow
                                hideSelectedList
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4} className="filter_item">
                              <label>Bedrooms</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Min"
                                    min="0"
                                    name="bedroomMin"
                                    value={
                                      this.state.bedroomMin > 0
                                        ? this.state.bedroomMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        bedroomMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0 "
                                    min={0}
                                    placeholder="Max"
                                    name="bedroomMax"
                                    value={
                                      this.state.bedroomMax > 0
                                        ? this.state.bedroomMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        bedroomMax: e.target.value > 0
                                          ? e.target.value
                                          : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col md={4} className="filter_item">
                              <label>Bathrooms</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="bathroomMin"
                                    value={
                                      this.state.bathroomMin > 0
                                        ? this.state.bathroomMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        bathroomMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    min="0"
                                    className="br-0 "
                                    name="bathroomMax"
                                    value={
                                      this.state.bathroomMax > 0
                                        ? this.state.bathroomMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        bathroomMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>Building Area SqFt</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="buildingAreaSqftMin"
                                    value={
                                      this.state.buildingAreaMin > 0
                                        ? this.state.buildingAreaMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        buildingAreaMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    className="br-0"
                                    min="0"
                                    name="buildingAreaSqftMax"
                                    value={
                                      this.state.buildingAreaMax > 0
                                        ? this.state.buildingAreaMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        buildingAreaMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} className="filter_item">
                              <label>Year Built</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="yearBuildMin"
                                    value={
                                      this.state.yearBuildMin > 0
                                        ? this.state.yearBuildMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        yearBuildMin:
                                          e.target.value.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    className="br-0"
                                    min="0"
                                    name="yearBuildMax"
                                    value={
                                      this.state.yearBuildMax > 0
                                        ? this.state.yearBuildMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        yearBuildMax:
                                          e.target.value.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>Effective Year Built</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="yearBuildMin"
                                    value={
                                      this.state.effectiveYearBuiltMin > 0
                                        ? this.state.effectiveYearBuiltMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        effectiveYearBuiltMin:
                                          e.target.value.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    className="br-0"
                                    min="0"
                                    name="yearBuildMax"
                                    value={
                                      this.state.effectiveYearBuiltMax > 0
                                        ? this.state.effectiveYearBuiltMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        effectiveYearBuiltMax:
                                          e.target.value.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>No. of Residential Units</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="numberOfUnitMix"
                                    value={
                                      this.state.residentialUnitsMin > 0
                                        ? this.state.residentialUnitsMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        residentialUnitsMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    className="br-0"
                                    min="0"
                                    name="numberOfUnitMax"
                                    value={
                                      this.state.residentialUnitsMax > 0
                                        ? this.state.residentialUnitsMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        residentialUnitsMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4} className="filter_item">
                              <label>No.s of Commercial Units</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="commercialUnits"
                                    value={
                                      this.state.commercialUnitsMin > 0
                                        ? this.state.commercialUnitsMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        commercialUnitsMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    min="0"
                                    className="br-0"
                                    name="commercialUnitsMax"
                                    value={
                                      this.state.commercialUnitsMax > 0
                                        ? this.state.commercialUnitsMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        commercialUnitsMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>Lot Size</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="lotSize"
                                    value={
                                      this.state.lotSizeMin > 0
                                        ? this.state.lotSizeMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        lotSizeMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    className="br-0"
                                    min="0"
                                    name="lotSize"
                                    value={
                                      this.state.lotSizeMax > 0
                                        ? this.state.lotSizeMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        lotSizeMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>Lot Size Acres</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Min"
                                    className="br-0"
                                    min="0"
                                    name="lotSize"
                                    value={
                                      this.state.lotSizeAcresMin > 0
                                        ? this.state.lotSizeAcresMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        lotSizeAcresMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    placeholder="Max"
                                    className="br-0"
                                    min="0"
                                    name="lotSize"
                                    value={
                                      this.state.lotSizeAcresMax > 0
                                        ? this.state.lotSizeAcresMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        lotSizeAcresMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <label className="label-check d-block">
                                Is Vacant
                              </label>
                              <Form.Check
                                inline
                                label={`Yes`}
                                type="radio"
                                name="vacantFlag"
                                checked={
                                  this.state.vacantFlag == "" ||
                                    this.state.vacantFlag != "y"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    vacantFlag: "y",
                                  })
                                }
                                value={"y"}
                              />

                              <Form.Check
                                inline
                                label={`No`}
                                type="radio"
                                name="vacantFlag"
                                value={"n"}
                                checked={
                                  this.state.vacantFlag == "" ||
                                    this.state.vacantFlag != "n"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    vacantFlag: "n",
                                  })
                                }
                              />

                              <Form.Check
                                inline
                                label={`Any`}
                                type="radio"
                                name="vacantFlag"
                                value={""}
                                onChange={(e) =>
                                  this.setState({
                                    vacantFlag: "",
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </AccordionItemPanel>
                      </AccordionItem>

                      {/* MLS Status */}
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>MLS Status</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Row>
                            <Col md={6} className="filter_item">
                              <label className="label-check d-block">
                                  Listing Date
                                </label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                      <Form.Control
                                        type="date"
                                        className="br-0"
                                        placeholder="Min"
                                        value={this.state?.listingDateMin}
                                        max={this.state.listingDateMax}
                                        onChange={(e) =>
                                          this.setState({ listingDateMin: e.target.value })
                                        }
                                      />
                                    </Col>
                                    <Col xs={6} sm={6} md={6}>
                                      <Form.Control
                                        type="date"
                                        className="br-0"
                                        value={this.state.listingDateMax}
                                        min={this.state?.listingDateMin}
                                        onChange={(e) =>
                                          this.setState({ listingDateMax: e.target.value })
                                        }
                                      />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                              <label className="label-check d-block mt-3">
                                On Market
                              </label>
                              <Form.Check
                                inline
                                label={`Yes`}
                                type="radio"
                                name="listingStatus"
                                checked={
                                  this.state.listingStatus == "" ||
                                    this.state.listingStatus != "y"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    listingStatus: "y",
                                  })
                                }
                                value={"y"}
                              />

                              <Form.Check
                                inline
                                label={`No`}
                                type="radio"
                                name="listingStatus"
                                value={"n"}
                                checked={
                                  this.state.listingStatus == "" ||
                                    this.state.listingStatus != "n"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    listingStatus: "n",
                                  })
                                }
                              />

                              <Form.Check
                                inline
                                label={`Any`}
                                type="radio"
                                name="listingStatus"
                                value={""}
                                onChange={(e) =>
                                  this.setState({
                                    listingStatus: "",
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </AccordionItemPanel>
                      </AccordionItem>

                        {/* Preforeclosure Filters */}
                        {this.state.currentPlanData?.packageIsFree || this.state.currentPlanData?.isFreePackage? "": (
                        <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Pre-Foreclosure/Propensity List</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                           <Row>
                           <Col md={4} sm={12} xm={12} className="filter_item">
                              <label className="label-check d-block">
                                  Pre-Foreclosure Status
                                </label>
                                <Multiselect
                                className="form-control mb-2"
                                options={PreforeclosureStatusConstant}
                                onSelect={this.onSelectPreforeclosureStatus}
                                onRemove={this.onRemovePreforeclosureStatus}
                                displayValue="name"
                                ref={this.preforeclosureStatusDropdown}
                                selectedValues={
                                  this.state.preforeclosureStatusSelectedValues
                                }
                                placeholder={
                                  "Status " + (this.state.preforeclosureStatus.length > 0
                                    ? `(${this.state.preforeclosureStatus.length})`
                                    : "")
                                }
                                name="preforeclosureStatus"
                                showCheckbox
                                showArrow
                                hideSelectedList
                              />
                              </Col>
                              <Col md={1}>
                              <label className="badge badge-bg-primary">OR</label>
                              </Col>
                              <Col md={7} sm={12} xs={12}>
                              <label className="label-check d-block mt-3">
                                Likely to Default (Pre/Pre-Foreclosure) / Propensity List
                              </label>
                              <Form.Check
                                id="very_likey_to_default"
                                inline
                                label={`Very Likely`}
                                type="checkbox"
                                name="preforeclosureLikeylyToDefault_very_likely"
                                value={"vy"}
                                checked={
                                  this.state.preforeclosureVeryLikeylyToDefault == VeryLikelyToDefaultConstant
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  this.setState({
                                    preforeclosureVeryLikeylyToDefault: this.state.preforeclosureVeryLikeylyToDefault ? "" : VeryLikelyToDefaultConstant,
                                    preforeclosureStatus: [],
                                    preforeclosureStatusLabel: [],
                                    preforeclosureStatusSelectedValues: []
                                  })
                                  this.handleClearPreforeclosureDropdown();
                                 }
                                }
                              />

                              <Form.Check
                                id="likey_to_default"
                                inline
                                label={`Likely`}
                                type="checkbox"
                                value={"y"}
                                name="preforeclosureLikeylyToDefault_likely"
                                checked={
                                    this.state.preforeclosureLikeylyToDefault == LikelyToDefaultConstant
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  this.setState({
                                    preforeclosureLikeylyToDefault: this.state.preforeclosureLikeylyToDefault ? "" : LikelyToDefaultConstant,
                                    preforeclosureStatus: [],
                                    preforeclosureStatusLabel: [],
                                    preforeclosureStatusSelectedValues: []
                                  }); this.handleClearPreforeclosureDropdown();
                                 }
                                }
                              />
                              
                              <Form.Check
                                id="unlikey_to_default"
                                inline
                                label={`Unlikely`}
                                type="checkbox"
                                value={"n"}
                                name="preforeclosureLikeylyToDefault_unlikely"
                                checked={
                                    this.state.preforeclosureUnLikeylyToDefault == UnLikelyToDefaultConstant
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  this.setState({
                                    preforeclosureUnLikeylyToDefault: this.state.preforeclosureUnLikeylyToDefault ? "" : UnLikelyToDefaultConstant,
                                    preforeclosureStatus: [],
                                    preforeclosureStatusLabel: [],
                                    preforeclosureStatusSelectedValues: []
                                  }); this.handleClearPreforeclosureDropdown();
                                 }
                                 }
                                
                              />
                           
                            </Col> 
                           </Row>
                          <Row>
                          <Col md={6} className="filter_item" sm={12} xs={12}>
                              <label className="label-check d-block">
                                  Recording Date
                                </label>
                              <Row>
                                <Col xs={12} sm={12} md={6}>
                                      <Form.Control
                                        type="date"
                                        className="br-0"
                                        placeholder="Min"
                                        value={this.state?.preforeclosureRecordingDateMin}
                                        max={this.state.preforeclosureRecordingDateMax}
                                        onChange={(e) =>
                                          this.setState({ preforeclosureRecordingDateMin: e.target.value })
                                        }
                                      />
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                      <Form.Control
                                        type="date"
                                        className="br-0"
                                        value={this.state.preforeclosureRecordingDateMax}
                                        min={this.state?.preforeclosureRecordingDateMin}
                                        onChange={(e) =>
                                          this.setState({ preforeclosureRecordingDateMax: e.target.value })
                                        }
                                      />
                                    </Col>
                                </Row>
                              </Col>
                            <Col md={6} className="filter_item" sm={12} xs={12}>
                              <label className="label-check d-block">
                                  Auction Date
                                </label>
                              <Row>
                                <Col xs={12} sm={12} md={6}>
                                      <Form.Control
                                        type="date"
                                        className="br-0"
                                        placeholder="Min"
                                        value={this.state?.preforeclosureAuctionDateMin}
                                        max={this.state.preforeclosureAuctionDateMax}
                                        onChange={(e) =>
                                          this.setState({ preforeclosureAuctionDateMin: e.target.value })
                                        }
                                      />
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                      <Form.Control
                                        type="date"
                                        className="br-0"
                                        value={this.state.preforeclosureAuctionDateMax}
                                        min={this.state?.preforeclosureAuctionDateMin}
                                        onChange={(e) =>
                                          this.setState({ preforeclosureAuctionDateMax: e.target.value })
                                        }
                                      />
                                    </Col>
                                </Row>
                            </Col>
                            
                          </Row>
                          <Row>
                         
                            {/* <Col md={3} sm={6} xs={12}>
                              <div className="mt-3" >
                              <Form.Check
                                id="active_auction_foreclosure_checkbox"
                                label={`Active Auction`}
                                type="checkbox"
                                name="preforeclosureActiveAuction"
                                onChange={(e) => {
                                  if(this.state.preforeclosureActiveAuction == "") {
                                    this.setState({
                                      preforeclosureActiveAuction: "y",
                                    })
                                  } else {
                                    this.setState({
                                      preforeclosureActiveAuction: "",
                                    })
                                  }
                                 }
                                }
                                value={"y"}
                                showArrow
                              />
                              </div>
                            </Col> */}
                            <Col md={3} sm={6} xs={12}>
                              <div className="mt-3">
                              <Form.Check
                                id="exclude_foreclosure_checkbox"
                                label={`Exclude Foreclosures`}
                                type="checkbox"
                                name="preforeclosureExclude"
                                onChange={(e) => {
                                  if(this.state.preforeclosureExclude == "") {
                                    this.setState({
                                      preforeclosureExclude: "y",
                                    })
                                  } else {
                                    this.setState({
                                      preforeclosureExclude: "",
                                    })
                                  }
                                 }
                                }
                                value={"y"}
                                showArrow
                              />
                              </div>
                            </Col>
                          </Row>
                        </AccordionItemPanel>
                      </AccordionItem>
                      )}
                      {/* Ownership Info */}
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Ownership Info
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Row>
                            <Col md={4} className="filter_item">
                              <label className="label-check d-block">
                                {" "}
                                Owner Type
                              </label>
                              <Multiselect
                                ref={this.ownerType}
                                options={[
                                  {
                                    value: "individual",
                                    label: "Individual",
                                  },
                                  {
                                    value: "company",
                                    label: "Company Owned",
                                  },
                                ]}
                                onSelect={this.onSelectOwnerType}
                                onRemove={this.onSelectOwnerType}
                                placeholder={
                                  "Owner Type " +
                                  (this.state.ownerType.length > 0
                                    ? `(${this.state.ownerType.length})`
                                    : "")
                                }
                                displayValue="label"
                                showArrow
                                showCheckbox
                                hideSelectedList
                                className="form-control"
                              />
                            </Col>
                            <Col md={6} className="filter_item">
                              <label className="label-check d-block">
                                Last Sale Date
                              </label>

                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="date"
                                    className="br-0"
                                    placeholder="Min"
                                    value={this.state?.lastSaleDateMin}
                                    max={this.state.lastSaleDateMax}
                                    onChange={(e) =>
                                      this.setState({ lastSaleDateMin: e.target.value })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="date"
                                    className="br-0"
                                    value={this.state.lastSaleDateMax}
                                    min={this.state?.lastSaleDateMin}
                                    onChange={(e) =>
                                      this.setState({ lastSaleDateMax: e.target.value })
                                    }
                                  />
                                </Col>
                              </Row>
                              {/* <DatePicker
                                selected={this.state.lastSaleDateMin}
                                startDate={this.state.lastSaleDateMin}
                                endDate={this.state.lastSaleDateMax}
                                onChange={this.onLastSaleDateChange}
                                selectsRange
                                peekNextMonth
                                value={this.state.lastSaleDateMin}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText="mm/dd/yyyy - mm/dd/yyyy"
                              /> */}
                            </Col>

                          </Row>
                          <Row>
                            <Col md={4} className="filter_item">
                              <label>Years of Ownership</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    max="4"
                                    placeholder="Min"
                                    value={
                                      this.state.yearsOfOwnershipMin > 0
                                        ? this.state.yearsOfOwnershipMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        yearsOfOwnershipMin:
                                          e.target?.value?.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Max"
                                    value={
                                      this.state.yearsOfOwnershipMax > 0
                                        ? this.state.yearsOfOwnershipMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        yearsOfOwnershipMax:
                                          e.target?.value?.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>Last Sale Price</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Min"
                                    min="0"
                                    value={
                                      this.state.lastSalePriceMin > 0
                                        ? this.state.lastSalePriceMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        lastSalePriceMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Max"
                                    min="0"
                                    value={
                                      this.state.lastSalePriceMax > 0
                                        ? this.state.lastSalePriceMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        lastSalePriceMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col md={4} className="filter_item">
                              <label>Tax Delinquent Year</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Min"
                                    min="0"
                                    value={
                                      this.state.taxDelinquentYearMin > 0
                                        ? this.state.taxDelinquentYearMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        taxDelinquentYearMin:
                                          e.target.value.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Max"
                                    min="0"
                                    value={
                                      this.state.taxDelinquentYearMax > 0
                                        ? this.state.taxDelinquentYearMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        taxDelinquentYearMax:
                                          e.target.value.slice(0, 4) > 0
                                            ? e.target.value.slice(0, 4)
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>

                          </Row>

                          <Row>
                            <Col md={4}>
                              <label className="label-check d-block mt-3">
                                Owner Occupied
                              </label>
                              <Form.Check
                                inline
                                label={`Yes`}
                                type="radio"
                                name="ownerOccupied"
                                checked={
                                  this.state.ownerOccupied == "" ||
                                    this.state.ownerOccupied != "y"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    ownerOccupied: "y",
                                  })
                                }
                                value={"y"}
                              />

                              <Form.Check
                                inline
                                label={`No`}
                                type="radio"
                                name="ownerOccupied"
                                value={"n"}
                                checked={
                                  this.state.ownerOccupied == "" ||
                                    this.state.ownerOccupied != "n"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    ownerOccupied: "n",
                                  })
                                }
                              />

                              <Form.Check
                                inline
                                label={`Any`}
                                type="radio"
                                name="ownerOccupied"
                                value={""}
                                onChange={(e) =>
                                  this.setState({
                                    ownerOccupied: "",
                                  })
                                }
                              />
                            </Col>
                            <Col md={4} className="mt-3">
                              <label className="label-check d-block">
                                Is Tax Delinquent
                              </label>
                              <Form.Check
                                inline
                                label={`Yes`}
                                type="radio"
                                name="isTaxDelinquent"
                                checked={
                                  this.state.isTaxDelinquent == "" ||
                                    this.state.isTaxDelinquent != "y"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    isTaxDelinquent: "y",
                                  })
                                }
                                value={"y"}
                              />

                              <Form.Check
                                inline
                                label={`No`}
                                type="radio"
                                name="isTaxDelinquent"
                                value={"n"}
                                checked={
                                  this.state.isTaxDelinquent == "" ||
                                    this.state.isTaxDelinquent != "n"
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  this.setState({
                                    isTaxDelinquent: "n",
                                  })
                                }
                              />

                              <Form.Check
                                inline
                                label={`Any`}
                                type="radio"
                                name="isTaxDelinquent"
                                value={""}
                                onChange={(e) =>
                                  this.setState({
                                    isTaxDelinquent: "",
                                  })
                                }
                              />
                            </Col>
                            <Col className="filter_item" lg={4}>
                              <label className="label-check d-block">
                                Mailing State (Not In)
                              </label>
                              <Select
                                defaultValue={this.state.mailingState}
                                onChange={(record) => this.setState({ mailingState: record?.value ?? '' })}
                                options={US_State_List}
                                styles={React_Select_Style_State}
                                name="mailingState"
                                placeholder={"Select Mailing State"}
                                isClearable
                                ref={this.mailingState}
                              />
                            </Col>
                          </Row>
                        </AccordionItemPanel>
                      </AccordionItem>

                      {/* VALUATION & EQUITY */}
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Valuation & Equity
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Row>
                            <Col md={4} className="filter_item">
                              <label>Estimated Value</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Min"
                                    value={
                                      this.state.estimatedValueMin > 0
                                        ? this.state.estimatedValueMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        estimatedValueMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Max"
                                    value={
                                      this.state.estimatedValueMax > 0
                                        ? this.state.estimatedValueMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        estimatedValueMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>Estimated Equity %</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Min"
                                    value={
                                      this.state.equityPercentageMin > 0
                                        ? this.state.equityPercentageMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        equityPercentageMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Max"
                                    value={
                                      this.state.equityPercentageMax > 0
                                        ? this.state.equityPercentageMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        equityPercentageMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4} className="filter_item">
                              <label>Assessed Total Value</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Min"
                                    value={
                                      this.state.assessedTotalValueMin > 0
                                        ? this.state.assessedTotalValueMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        assessedTotalValueMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Max"
                                    value={
                                      this.state.assessedTotalValueMax > 0
                                        ? this.state.assessedTotalValueMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        assessedTotalValueMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col md={4} className="filter_item">
                              <label>Assessed Land Value</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Min"
                                    value={
                                      this.state.assessedLandValueMin > 0
                                        ? this.state.assessedLandValueMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        assessedLandValueMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Max"
                                    value={
                                      this.state.assessedLandValueMax > 0
                                        ? this.state.assessedLandValueMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        assessedLandValueMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4} className="filter_item">
                              <label>Market Total Value</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Min"
                                    min="0"
                                    value={
                                      this.state.marketTotalValueMin > 0
                                        ? this.state.marketTotalValueMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        marketTotalValueMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Max"
                                    min="0"
                                    value={
                                      this.state.marketTotalValueMax > 0
                                        ? this.state.marketTotalValueMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        marketTotalValueMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col md={4} className="filter_item">
                              <label>Market Land Value</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Min"
                                    min="0"
                                    value={
                                      this.state.marketLandValueMin > 0
                                        ? this.state.marketLandValueMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        marketLandValueMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Max"
                                    min="0"
                                    value={
                                      this.state.marketLandValueMax > 0
                                        ? this.state.marketLandValueMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        marketLandValueMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </AccordionItemPanel>
                      </AccordionItem>

                      {/* MORTAGE INFO */}
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Mortgage Info
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Row>
                            <Col md={6} className="filter_item">
                              <label>Mortgage Recording Date</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="date"
                                    className="br-0"
                                    placeholder="Min"
                                    value={this.state?.mortgageRecordingDateMin}
                                    max={this.state.mortgageRecordingDateMax}
                                    onChange={(e) =>
                                      this.setState({ mortgageRecordingDateMin: e.target.value })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="date"
                                    className="br-0"
                                    value={this.state.mortgageRecordingDateMax}
                                    min={this.state?.mortgageRecordingDateMin}
                                    onChange={(e) =>
                                      this.setState({ mortgageRecordingDateMax: e.target.value })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col md={4} className="filter_item">
                              <label>Loan Interest Rate %</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Min"
                                    min="0"
                                    value={
                                      this.state.mortgageInterestRateMin > 0
                                        ? this.state.mortgageInterestRateMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        mortgageInterestRateMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Max"
                                    value={
                                      this.state.mortgageInterestRateMax > 0
                                        ? this.state.mortgageInterestRateMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        mortgageInterestRateMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col md={4} className="filter_item">
                              <label>Mortgage Remaining Balance</label>
                              <Row>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    placeholder="Min"
                                    min="0"
                                    value={
                                      this.state.mortgageAmountMin > 0
                                        ? this.state.mortgageAmountMin
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        mortgageAmountMin:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                  <Form.Control
                                    type="number"
                                    className="br-0"
                                    min="0"
                                    placeholder="Max"
                                    value={
                                      this.state.mortgageAmountMax > 0
                                        ? this.state.mortgageAmountMax
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        mortgageAmountMax:
                                          e.target.value > 0
                                            ? e.target.value
                                            : "",
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </AccordionItemPanel>
                      </AccordionItem>

                      { /** WEATHER INFO FILTER */}
                      {this.state.currentPlanData?.packageIsFree || this.state.currentPlanData?.isFreePackage? "": (
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Weather Events</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Row>
                          <Col md={4} className="filter_item">
                            <label className="label-check d-block">
                                  Duration in Days
                                </label>
                                <Select
                                onChange={(record) => this.setState({ weatherEventLastDays: record?.value})}
                                options={WeatherEventDaysConstant}
                                styles={React_Select_Style_State}
                                name="weatherTypeDataDays"
                                placeholder={"Choose the Duration"}
                                isClearable
                                defaultValue={this.state.weatherEventMeasurementDefault}
                                ref={this.weatherEventDaysDropdown}
                              />
                            </Col>
                            <Col md={4} className="filter_item">
                            <label className="label-check d-block">
                                  Weather Condition
                                </label>
                                <Select
                                //defaultValue={this.state.weatherEventType}
                                onChange={this.onWeatherEventTypeSelection}
                                options={WeatherTypeConstant}
                                styles={React_Select_Style_State}
                                name="weatherType"
                                placeholder={"Choose Weather Condition"}
                                isClearable
                                ref={this.weatherEventTypeDropdown}
                              />
                            </Col>
                            <Col md={4} className="filter_item">
                            { this.state.weatherEventType && this.state.weatherEventType != "tornado" ? ( <>
                              <label className="label-check d-block">
                                    Measurement
                              </label>
                              <Select
                                  onChange={(record) => this.setState({ weatherEventMeasurementDefault: record,
                                    weatherEventTypeDataMin: record?.min ?? 0,
                                    weatherEventTypeDataMax: record?.max ?? 1000
                                  })}
                                  options={this.state.weatherEventTypeDataSelection}
                                  styles={React_Select_Style_State}
                                  name="weatherTypeData"
                                  placeholder={"Choose the Measurement"}
                                  isClearable
                                  defaultValue={this.state.weatherEventMeasurementDefault}
                                  ref={this.weatherEventMeasurementDropdown}
                              />
                            </>) : ""}
                            </Col>
                         
                          </Row>
                          <Row>
                         
                            </Row>
                        </AccordionItemPanel>
                      </AccordionItem>
                      ) }
                    </Accordion>
                  </div>
                </div>
              </div>
            </Col>

            {/* side show */}
            <Col xs={12} sm={12} md={12} lg={4} className="p-0 overflow-hidden">
              <div className="slideContainer slideShow">
                <div className="slideBody">
                  <ul>
                    {/* For Tabs Locations */}
                    {this.state.searchZipCode != "" ||
                      this.state.selectedState !== "" ||
                      this.state.city !== "" ||
                      this.state?.county !== "" ? (
                      <li>
                        <span>
                          <div className="labelData">Location:</div>
                          <div className="taggedData">
                            {this.state.searchZipCode != ""
                              ? " Zip Code - " + this.state.searchZipCode
                              : ""}
                            {this.state.selectedState !== ""
                              ? `State - ${this.state?.selectedState} , `
                              : ""}
                            {this.state?.counties?.length
                              ? `Counties - ${this.state?.counties.join(
                                ", "
                              )} (${this.state.stateShort}) , `
                              : ""}
                            <br />
                            {this.state.city !== ""
                              ? `City - ${this.state?.city} (${this.state.stateShort})`
                              : ""}
                            <br />
                            {this.state.street !== ""
                              ? `Address - ${this.state?.street}`
                              : ""}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Property Characteristics */}
                    <span className="filter_divider">
                      Property Characteristics:
                    </span>

                    {/* classification */}
                    {this.state.classification?.length != 0 && this.state.defaultFilter.value != this.MAGIC_LIST_PRE_DEFINED_FILTER ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              classification: [],
                              propertyType: [],
                              selectedClassificationvalue: []
                            });
                            this.handlePropertyCharacteristicsSelectedValues();
                            this.handlePropertyTypeSelectedValues();
                          }}
                        />
                        <span>
                          <div className="labelData">All Classifications:</div>
                          <div className="taggedData">
                            {this.state.classification?.map((type, k) =>
                              k === 0
                                ? this.handleUpperCase(type)
                                : " , " + this.handleUpperCase(type)
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* propertyType */}
                    {this.state.propertyType?.length != 0 && this.state.defaultFilter.value != 26 ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ propertyType: [] });
                            this.handlePropertyTypeSelectedValues();
                          }}
                        />
                        <span>
                          <div className="labelData">Property Type:</div>
                          <div className="taggedData">
                            {this.state.propertyType?.map((ptype, k) => (
                              <span className="tag-span">
                                {this.handleUpperCase(ptype?.label)}
                              </span>
                            ))}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Site In fluence */}
                    {this.state.siteInfluence?.length != 0 ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ siteInfluence: [] });
                            this.handleSiteInfluenceSelectedValues();
                          }}
                        />
                        <span>
                          <div className="labelData">Site Influence:</div>
                          <div className="taggedData">
                            {this.state.siteInfluence?.map((type, k) => {
                              const sideValue = SiteInfluenceConstant.find(
                                (item) => item?.value == type
                              );
                              return k === 0
                                ? this.handleUpperCase(sideValue?.label)
                                : " , " +
                                this.handleUpperCase(sideValue?.label);
                            })}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min bedroom */}
                    {this.state.bedroomMin != "" ||
                      this.state.bedroomMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ bedroomMin: "", bedroomMax: "" });
                          }}
                        />
                        <span>
                          <div className="labelData">Bedrooms:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.bedroomMin,
                              this.state.bedroomMax,
                              ""
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Bathrooms */}
                    {this.state.bathroomMin != "" ||
                      this.state.bathroomMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ bathroomMin: "", bathroomMax: "" });
                          }}
                        />
                        <span>
                          <div className="labelData">Bathrooms:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.bathroomMin,
                              this.state.bathroomMax,
                              ""
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Building Area SqFt */}
                    {this.state.buildingAreaMin != "" ||
                      this.state.buildingAreaMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              buildingAreaMin: "",
                              buildingAreaMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Building Area SqFt:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.buildingAreaMin,
                              this.state.buildingAreaMax,
                              ""
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Year Built */}
                    {this.state.yearBuildMin != "" ||
                      this.state.yearBuildMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              yearBuildMin: "",
                              yearBuildMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Year Built:</div>
                          <div className="taggedData">
                            {this.handleDateMinAndMax(
                              this.state.yearBuildMin,
                              this.state.yearBuildMax
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Effective Year Built */}
                    {this.state.effectiveYearBuiltMin != "" ||
                      this.state.effectiveYearBuiltMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              effectiveYearBuiltMin: "",
                              effectiveYearBuiltMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Effective Year Built:</div>
                          <div className="taggedData">
                            {this.handleDateMinAndMax(
                              this.state.effectiveYearBuiltMin,
                              this.state.effectiveYearBuiltMax
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min No. of Residential Units */}
                    {this.state.residentialUnitsMin != "" ||
                      this.state.residentialUnitsMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              residentialUnitsMin: "",
                              residentialUnitsMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">
                            No. of Residential Units:
                          </div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.residentialUnitsMin,
                              this.state.residentialUnitsMax,
                              ""
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min No.s of Commercial Units */}
                    {this.state.commercialUnitsMin != "" ||
                      this.state.commercialUnitsMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              commercialUnitsMin: "",
                              commercialUnitsMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">
                            No.s of Commercial Units:
                          </div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.commercialUnitsMin,
                              this.state.commercialUnitsMax,
                              ""
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Lot Size */}
                    {this.state.lotSizeMin != "" ||
                      this.state.lotSizeMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ lotSizeMin: "", lotSizeMax: "" });
                          }}
                        />
                        <span>
                          <div className="labelData">Lot Size:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.lotSizeMin,
                              this.state.lotSizeMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Lot Size Acres */}
                    {this.state.lotSizeAcresMin != "" ||
                      this.state.lotSizeAcresMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              lotSizeAcresMin: "",
                              lotSizeAcresMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Lot Size Acres:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.lotSizeAcresMin,
                              this.state.lotSizeAcresMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/*   Is Vacant */}
                    {this.state.vacantFlag != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ vacantFlag: "" });
                          }}
                        />
                        <span>
                          <div className="labelData"> Is Vacant:</div>
                          <div className="taggedData">
                            {this.state.vacantFlag == "y" ? "Yes" : "No"}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Property Characteristics End */}

                    {/* MLS Status */}

                    <span className="filter_divider">MLS Status:</span>

                    {/*   Is Vacant */}
                    {this.state.listingStatus != "" && this.state.defaultFilter.value != this.MAGIC_LIST_PRE_DEFINED_FILTER ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ listingStatus: "" });
                          }}
                        />
                        <span>
                          <div className="labelData"> On Market:</div>
                          <div className="taggedData">
                            {this.state.listingStatus == "y" ? "Yes" : "No"}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Listing Date */}
                    {this.state.listingDateMin != "" ||
                      this.state.listingDateMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              listingDateMin: "",
                              listingDateMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Listing Date:</div>
                          <div className="taggedData">
                            {moment(this.state.listingDateMin).format(
                              "MM-DD-YYYY"
                            )}{" "}
                            -{" "}
                            {moment(this.state.listingDateMax).format(
                              "MM-DD-YYYY"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                  { this.state.currentPlanData?.packageIsFree || this.state.currentPlanData?.isFreePackage? "": (
                    <span className="filter_divider">Pre-Foreclosure/Propensity List:</span>
                  )}
                    {this.state.preforeclosureStatusLabel?.length? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ preforeclosureStatus: "", preforeclosureStatusLabel: [] });
                            this.handleClearPreforeclosureDropdown();
                          }}
                        />
                        <span>
                          <div className="labelData"> Pre-Foreclosure Status:</div>
                          <div className="taggedData">
                            {this.state.preforeclosureStatusLabel.join(", ")}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Recording Date */}
                    {this.state.preforeclosureRecordingDateMin != "" ||
                      this.state.preforeclosureRecordingDateMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              preforeclosureRecordingDateMax: "",
                              preforeclosureRecordingDateMin: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Recording Date:</div>
                          <div className="taggedData">
                            {this.state.preforeclosureRecordingDateMin ? (
                            moment(this.state.preforeclosureRecordingDateMin).format(
                              "MM-DD-YYYY"
                            )) : ""}
                            {" "}-{" "}
                            {this.state.preforeclosureRecordingDateMax ? (
                            moment(this.state.preforeclosureRecordingDateMax).format(
                              "MM-DD-YYYY"
                            )) : ""}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Auction Date* */}
                    {this.state.preforeclosureAuctionDateMin != "" ||
                      this.state.preforeclosureAuctionDateMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              preforeclosureAuctionDateMin: "",
                              preforeclosureAuctionDateMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Auction Date:</div>
                          <div className="taggedData">
                            {this.state.preforeclosureAuctionDateMin ? (
                            moment(this.state.preforeclosureAuctionDateMin).format(
                              "MM-DD-YYYY"
                            )) : ""} 
                            {" "}-{" "}
                            {this.state.preforeclosureAuctionDateMax ? (
                            moment(this.state.preforeclosureAuctionDateMax).format(
                              "MM-DD-YYYY"
                            )) : ""} 
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.preforeclosureVeryLikeylyToDefault || 
                     this.state.preforeclosureLikeylyToDefault || 
                     this.state.preforeclosureUnLikeylyToDefault?  (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ preforeclosureVeryLikeylyToDefault: "", preforeclosureLikeylyToDefault: "", preforeclosureUnLikeylyToDefault: ""})
                          }}
                        />
                        <span>
                          <div className="labelData">Likely to Default:</div>
                          {this.state.preforeclosureVeryLikeylyToDefault? <div className="taggedData">Very Likely, </div> : ""}
                          {this.state.preforeclosureLikeylyToDefault? <div className="taggedData">Likely, </div> : ""}
                          {this.state.preforeclosureUnLikeylyToDefault? <div className="taggedData">Unlikely </div> : ""}
                          
                        </span>
                      </li>
                     ) : ""}
                    
                      {/* {this.state.preforeclosureLikeylyToDefault ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ preforeclosureLikeylyToDefault: "" });
                          }}
                        />
                        <span>
                          <div className="labelData">Likely to Default:</div>
                          <div className="taggedData">
                            Likely
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )} */}
                      

                      {this.state.preforeclosureExclude != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ preforeclosureExclude: "" });
                          }}
                        />
                        <span>
                          <div className="labelData">Exclude Foreclosures:</div>
                          <div className="taggedData">
                            {this.state.preforeclosureExclude == "y" ? "Yes" : "No"}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                      {this.state.preforeclosureActiveAuction != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ preforeclosureActiveAuction: "" });
                          }}
                        />
                        <span>
                          <div className="labelData">Active Auction:</div>
                          <div className="taggedData">
                            {this.state.preforeclosureActiveAuction == "y" ? "Yes" : "No"}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}


                    {/* Ownership Info */}

                    <span className="filter_divider">Ownership Info:</span>

                    {/* max & min Owner Type */}
                    {this.state.ownerType?.length != 0 ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ ownerType: [] });
                            this.handleOwnerTypeSelectedValues();
                          }}
                        />
                        <span>
                          <div className="labelData">Owner Type:</div>
                          <div className="taggedData">
                            {this.state.ownerType?.map((type, k) =>
                              k === 0
                                ? this.handleUpperCase(type)
                                : " , " + this.handleUpperCase(type)
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* owner mailing state */}
                    {/* {this.state.mailingState ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ mailingState: '' });
                          }}
                        />
                        <span>
                          <div className="labelData">Owner Mailing State:</div>
                          <div className="taggedData">
                            {US_State_List.find((usState) => usState.value == this.state.mailingState)?.label}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )} */}


                    {/* owner mailing state */}
                    {this.state.mailingState ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ mailingState: '' });
                          }}
                        />
                        <span>
                          <div className="labelData">Not In (Mailing State):</div>
                          <div className="taggedData">
                            {US_State_List.find((usState) => usState.value == this.state.mailingState)?.label}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/*   Owner Occupied */}
                    {this.state.ownerOccupied != "" && this.state.defaultFilter.value != this.MAGIC_LIST_PRE_DEFINED_FILTER ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ ownerOccupied: "" });
                          }}
                        />
                        <span>
                          <div className="labelData"> Owner Occupied:</div>
                          <div className="taggedData">
                            {this.state.ownerOccupied == "y" ? "Yes" : "No"}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Years of Ownership */}
                    {(this.state.yearsOfOwnershipMin != "" ||
                      this.state.yearsOfOwnershipMax != "") && this.state.defaultFilter.value != this.MAGIC_LIST_PRE_DEFINED_FILTER  ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              yearsOfOwnershipMin: "",
                              yearsOfOwnershipMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Years of Ownership:</div>
                          <div className="taggedData">
                            {this.handleDateMinAndMax(
                              this.state.yearsOfOwnershipMin,
                              this.state.yearsOfOwnershipMax
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Last Sale Date */}
                    {this.state.lastSaleDateMin != "" ||
                      this.state.lastSaleDateMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              lastSaleDateMin: "",
                              lastSaleDateMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Last Sale Date:</div>
                          <div className="taggedData">
                            {this.state.lastSaleDateMin != "" && this.state.lastSaleDateMax == "" ? ">= " + moment(
                              this.state.lastSaleDateMin
                            ).format("MM-DD-YYYY")
                              : this.state.lastSaleDateMin == "" && this.state.lastSaleDateMax != "" ? "=< " +
                                moment(
                                  this.state.lastSaleDateMax
                                ).format("MM-DD-YYYY")
                                : moment(this.state.lastSaleDateMin).format("MM-DD-YYYY") + " - " + moment(this.state.lastSaleDateMax).format("MM-DD-YYYY")
                            }

                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Last Sale Price */}
                    {this.state.lastSalePriceMin != "" ||
                      this.state.lastSalePriceMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              lastSalePriceMin: "",
                              lastSalePriceMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Last Sale Price:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "$",
                              this.state.lastSalePriceMin,
                              this.state.lastSalePriceMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/*   Is Tax Delinquent */}
                    {this.state.isTaxDelinquent != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({ isTaxDelinquent: "" });
                          }}
                        />
                        <span>
                          <div className="labelData"> Is Tax Delinquent:</div>
                          <div className="taggedData">
                            {this.state.isTaxDelinquent == "y" ? "Yes" : "No"}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Tax Delinquent Year */}
                    {this.state.taxDelinquentYearMin != "" ||
                      this.state.taxDelinquentYearMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              taxDelinquentYearMin: "",
                              taxDelinquentYearMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Tax Delinquent Year:</div>
                          <div className="taggedData">
                            {this.handleDateMinAndMax(
                              this.state.taxDelinquentYearMin,
                              this.state.taxDelinquentYearMax
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}


                    {/* Valuation & Equity  */}

                    <span className="filter_divider">Valuation & Equity:</span>

                    {/* max & min Estimated Value */}
                    {this.state.estimatedValueMin != "" ||
                      this.state.estimatedValueMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              estimatedValueMin: "",
                              estimatedValueMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Estimated Value:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "$",
                              this.state.estimatedValueMin,
                              this.state.estimatedValueMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Estimated Equity % */}
                    {this.state.equityPercentageMin != "" ||
                      this.state.equityPercentageMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              equityPercentageMin: "",
                              equityPercentageMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Estimated Equity %:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.equityPercentageMin,
                              this.state.equityPercentageMax,
                              "%"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Assessed Total Value */}
                    {this.state.assessedTotalValueMin != "" ||
                      this.state.assessedTotalValueMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              assessedTotalValueMin: "",
                              assessedTotalValueMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Assessed Total Value:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "$",
                              this.state.assessedTotalValueMin,
                              this.state.assessedTotalValueMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Assessed Land Value */}
                    {this.state.assessedLandValueMin != "" ||
                      this.state.assessedLandValueMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              assessedLandValueMin: "",
                              assessedLandValueMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Assessed Land Value:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "$",
                              this.state.assessedLandValueMin,
                              this.state.assessedLandValueMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Market Total Value */}
                    {this.state.marketTotalValueMin != "" ||
                      this.state.marketTotalValueMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              marketTotalValueMin: "",
                              marketTotalValueMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Market Total Value:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "$",
                              this.state.marketTotalValueMin,
                              this.state.marketTotalValueMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Market Land Value */}
                    {this.state.marketLandValueMin != "" ||
                      this.state.marketLandValueMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              marketLandValueMin: "",
                              marketLandValueMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Market Land Value:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "$",
                              this.state.marketLandValueMin,
                              this.state.marketLandValueMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Mortgage Info */}

                    <span className="filter_divider">Mortgage Info :</span>

                    {/* max & min Mortgage Recording Date */}
                    {(this.state.mortgageRecordingDateMin != "" ||
                      this.state.mortgageRecordingDateMax != "")  && this.state.defaultFilter.value != this.MAGIC_LIST_PRE_DEFINED_FILTER ?  (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              mortgageRecordingDateMin: "",
                              mortgageRecordingDateMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">
                            Mortgage Recording Date:
                          </div>
                          <div className="taggedData">
                            {this.state.mortgageRecordingDateMin != "" && this.state.mortgageRecordingDateMax == "" ? ">= " + moment(
                              this.state.mortgageRecordingDateMin
                            ).format("MM-DD-YYYY")
                              : this.state.mortgageRecordingDateMin == "" && this.state.mortgageRecordingDateMax != "" ? "=< " +
                                moment(
                                  this.state.mortgageRecordingDateMax
                                ).format("MM-DD-YYYY")
                                : moment(this.state.mortgageRecordingDateMin).format("MM-DD-YYYY") + " - " + moment(this.state.mortgageRecordingDateMax).format("MM-DD-YYYY")
                            }
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* {this.state.mortgageRecordingDateMin != "" &&
                      this.state.mortgageRecordingDateMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              mortgageRecordingDateMin: "",
                              mortgageRecordingDateMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">
                            Mortgage Recording Date:
                          </div>
                          <div className="taggedData">
                            {moment(this.state.mortgageRecordingDateMin).format(
                              "MM-DD-YYYY"
                            )}{" "}
                            -{" "}
                            {moment(this.state.mortgageRecordingDateMax).format(
                              "MM-DD-YYYY"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )} */}

                    {/* max & min Loan Interest Rate % */}
                    {this.state.mortgageInterestRateMin != "" ||
                      this.state.mortgageInterestRateMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              mortgageInterestRateMin: "",
                              mortgageInterestRateMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">Loan Interest Rate %:</div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "",
                              this.state.mortgageInterestRateMin,
                              this.state.mortgageInterestRateMax,
                              "%"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* max & min Mortgage Remaining Balance */}
                    {this.state.mortgageAmountMin != "" ||
                      this.state.mortgageAmountMax != "" ? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              mortgageAmountMin: "",
                              mortgageAmountMax: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">
                            Mortgage Remaining Balance:
                          </div>
                          <div className="taggedData">
                            {this.handleMinAndMax(
                              "$",
                              this.state.mortgageAmountMin,
                              this.state.mortgageAmountMax,
                              ".0"
                            )}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  

                  {this.state.currentPlanData?.packageIsFree || this.state.currentPlanData?.isFreePackage? "": (
                  <span className="filter_divider">Weather Events :</span>
                  )}
                  {this.state.weatherEventType? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.weatherEventTypeDropdown.current.clearValue()
                            this.setState({
                              weatherEventType: "",
                            });
                          }}
                        />
                        <span>
                          <div className="labelData">
                            Weather Condition:
                          </div>
                          <div className="taggedData">
                            {this.state.weatherEventType?.includes("rain") ? 'Rain Fall' : 
                            this.state.weatherEventType ? this.handleUpperCase(this.state.weatherEventType) :
                             ''}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  {this.state.weatherEventMeasurementDefault? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              weatherEventMeasurementDefault: "",
                              weatherEventTypeDataMin: "",
                              weatherEventTypeDataMax: "",
                            });
                            this.weatherEventMeasurementDropdown.current.clearValue()
                          }}
                        />
                        <span>
                          <div className="labelData">
                            Weather Condition Measurement:
                          </div>
                          <div className="taggedData">
                           {this.state.weatherEventMeasurementDefault.label}
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                      {this.state.weatherEventLastDays? (
                      <li>
                        <IoIosCloseCircle
                          onClick={() => {
                            this.setState({
                              weatherEventLastDays: "",
                            });
                            this.weatherEventDaysDropdown.current.clearValue()
                          }}
                        />
                        <span>
                          <div className="labelData">
                            Weather Condition Duration:
                          </div>
                          <div className="taggedData">
                           Last {this.state.weatherEventLastDays} Days
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    </ul>
                </div>
                <div className="slideFooter">
                  <button onClick={this.handleSearchSubmit} className="apply">
                    <FontAwesomeIcon
                      className="pe-2 iconRemove"
                      icon={faSearch}
                    />
                    Search
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PropertySearchFilters;