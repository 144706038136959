import { AiFillCloseCircle } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Row, Col, Image } from "react-bootstrap";
import FlashSaleCard from "./FlashSaleCard";
import { GetFlashSalesByUser } from "../../Utils/flash-sale.util";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import FlashSaleCheckout from "./FlashSaleCheckout";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

export const FlashSalesModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [flashSales, setFlashSales] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    GetFlashSalesByUser().then((response) => {
      if (response?.statusCode == 200 && response.result?.length) {
        setFlashSales(response.result);
        // Timer here
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    });
  }, []);

  // const handlePurchaseItemClick = (itemSelected) => {
  //   setSelectedItem(itemSelected);
  //   setShowCheckout(true);
  // };

  return (
    <>
      {showCheckout ? (
        <Elements stripe={stripePromise}>
          <FlashSaleCheckout />
        </Elements>
      ) : (
        <Modal
          show={showModal}
          // onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="xl"
          className="glassModel glassModalSkips"
        >
          <Modal.Body className="py-4">
            <Row className="d-flex justify-content-between ">
              <Col xs={11} sm={11} md={11} lg={11} className="text-center">
                <Image src={require("./../../assets/img/logo.png")} alt="" />
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                <AiFillCloseCircle
                  size={30}
                  cursor={"pointer"}
                  onClick={() => setShowModal(false)}
                />
              </Col>
            </Row>

            <h4 className="skip_modal_title">
              24-Hour Flash Sale - <br /> Unskippable Savings on Skip Tracing!
            </h4>
            <h3 className="skip_offer_text">Up To 60% Off High-Quality Data</h3>

            <Row>
              <Col md={11} className="mx-auto">
                <Row className="my-5">
                  {flashSales?.map((item, s) => (
                    <Col md={3} key={s}>
                      <FlashSaleCard
                        item={item}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            <p className="skip_des_text">
              for the next 24 hours,seize the opportunity to access the
            </p>
            <p className="skip_des_text">
              {" "}
              heighest quality skip trace data at more than 60% off.
            </p>
            <p className="skip_des_text">
              These skips never expire and you can use them at anytime!
            </p>
            <span className="skip_span_text">
              For any question please email{" "}
              <a href="mailto:support@skipify.ai" className="mailto">
                support@skipify.ai
              </a>{" "}
            </span>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
