import { AiFillCloseCircle } from "react-icons/ai";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Row, Col, Image } from "react-bootstrap";
import { TiTimes } from "react-icons/ti";
import axios from "axios";
import { GetFormattedNumber } from "../../Utils/formating.util";
import PriceCardDash from "../Frontend/PriceCardDash";
import { PACKAGES_GET_LIST_ENDPOINT } from "../../Constants/api-routes.constant";
import { createActiveAddSkip } from "../../store/slices/features/app";
import SkipTraceCard from "../Frontend/SkipTraceCard";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { AGE_FILTER_SKIP_PER_HIT } from "../../Constants/common-limit.constant";
import { AgeFilterOptionConstant } from "../../Constants/age_filter_options.constant";

export const GoCheckoutModal = ({ type, handleClose, show }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToPlans = () => {
    dispatch(createActiveAddSkip(0));
    navigate("/billing");
  };

  const goToAddSkips = () => {
    dispatch(createActiveAddSkip(1));
    navigate("/billing");
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
      centered
      className="glassModel"
    >
      <Modal.Body>
        <Row className="d-flex justify-content-end mb-2">
          <Col md={1}>
            <TiTimes onClick={handleClose} cursor={"pointer"} />
          </Col>
        </Row>
        <p className="plan_des">
          {" "}
          You have insufficient Skip Trace Hits or Property Downloads. Please
          subscribe to a plan or add skips from Billing by clicking below
        </p>
        {/* <p className="plan_des">  </p> */}

        <div className="pre-plan">
          <button className="payment_widget_botton" onClick={goToPlans}>
            Select a Plan
          </button>
          <button className="payment_widget_botton add" onClick={goToAddSkips}>
            Add Skips
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// Message Modal
export const MessageModal = ({
  message,
  type,
  link = "",
  linkTitle = "",
  handleClose,
  show,
}) => {
  // message will text
  // type is error || success
  // link redirect to route
  // linkTitle Name of the link
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      // className='glassModel'
    >
      <Modal.Body>
        <Row className="d-flex justify-content-end mb-2">
          <Col md={1}>
            <AiFillCloseCircle onClick={handleClose} cursor={"pointer"} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="meesage_icon">
              <AiFillCloseCircle />
            </div>
          </Col>
          <Col md={12}>
            <p
              className={`text-center ${
                type === "error" ? "text-warning" : "text-success"
              }`}
            >
              {message}
              {/* if type is success then will redirect to some page or not */}
              {type != "error" && link != "" ? (
                <Link to={link}>{linkTitle}</Link>
              ) : (
                ""
              )}
            </p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

// Adding summary and file name
export const SummaryModal = ({
  selected,
  remine,
  type,
  handleClose,
  show,
  setFileName,
  fileName,
  setAgeMin,
  setAgeMax,
  currentPlanData,
  submitFunction,
  setDncCheck,
  setRelativeContacts,
  setLikelyGender
}) => {
  // type Download Or Skiptrace
  const [fileNameError, setFileNameError] = useState("");
  const [ageMinModel, setAgeMinModel] = useState(null);
  const [ageMaxModel, setAgeMaxModel] = useState(null);
  const [showAgeFilter, setShowAgeFilter] = useState(false);
  const [checkboxNumbers, setCheckboxNumbers] = useState([]);
  const checkbox1Ref = useRef(null)
  const checkbox2Ref = useRef(null)
  const checkbox3Ref = useRef(null)
  const checkbox4Ref = useRef(null)
  const checkbox5Ref = useRef(null)
  const checkbox6Ref = useRef(null)
 const [dncCheckModel, setDncCheckModel] = useState(false);
 const [relativesCheckModel, setRelativesCheckModel] = useState(false);
 const [likelyGenderModel, setLikelyGenderModel] = useState('')
 const maleCheckboxRef = useRef(null)
 const femaleCheckboxRef = useRef(null)

  const handleMessage = () => {
    if (fileName == "") {
      setFileNameError("Please enter file name");
    } else {
      setFileNameError("");
    }
  };

  const handleUpperCase = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleAgeFilterCheckbox = async (event, checkboxNumber) => {
    const selectedCheckBox = AgeFilterOptionConstant[checkboxNumber];
    let minAge = ageMinModel;
    let maxAge = ageMaxModel;

    if( !event.target.checked ) {

       if(maxAge) {
         
        if(selectedCheckBox.min >= minAge && selectedCheckBox.max < maxAge) {
          minAge = selectedCheckBox.max + 1
          setAgeMin(minAge)
          setAgeMinModel(minAge)
          //console.log(minAge, maxAge, 'uncheck1')
        }

        if(selectedCheckBox.min > minAge && selectedCheckBox.max >= maxAge) {
          maxAge = selectedCheckBox.min - 1
          setAgeMax(maxAge)
          setAgeMaxModel(maxAge)
          //console.log(minAge, maxAge, 'uncheck2')
        }

        if(selectedCheckBox.min == minAge && selectedCheckBox.max == maxAge) {
          maxAge = null
          minAge = null
          setAgeMax(maxAge); setAgeMin(minAge);
          setAgeMaxModel(maxAge); setAgeMinModel(minAge);
         // console.log(minAge, maxAge, 'uncheck3')
        }
       } 
    }
  

    if (checkboxNumber == 0 || ageMinModel == null) {
      setAgeMin(selectedCheckBox.min);
      setAgeMax(selectedCheckBox.max);
      setAgeMinModel(selectedCheckBox.min);
      setAgeMaxModel(selectedCheckBox.max);
      minAge = selectedCheckBox.min
      maxAge = selectedCheckBox.max
     // console.log(minAge, maxAge, "1")
    }

    // // Allow to select only consective checkboxes
    if (minAge > 1 && 
      ( Math.abs(ageMaxModel - selectedCheckBox.min) == 1 ||
      Math.abs(ageMinModel - selectedCheckBox.max) == 1 )
    ) {
      if (ageMinModel > selectedCheckBox.min) {
        setAgeMin(selectedCheckBox.min);
        setAgeMinModel(selectedCheckBox.min);
        minAge = selectedCheckBox.min
      }
      if (ageMaxModel < selectedCheckBox.max) {
        setAgeMax(selectedCheckBox.max);
        setAgeMaxModel(selectedCheckBox.max);
        maxAge = selectedCheckBox.max
      }
     // console.log(minAge, maxAge, "2")
    }

    handleChecked(minAge, maxAge);   
    
  };

  const handleChecked = (minAge, maxAge) => {
    if(AgeFilterOptionConstant[1].min < minAge || AgeFilterOptionConstant[1].max > maxAge ) {
      if (checkbox2Ref.current)  checkbox2Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[2].min < minAge || AgeFilterOptionConstant[2].max > maxAge ) {
      if (checkbox3Ref.current)  checkbox3Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[3].min < minAge || AgeFilterOptionConstant[3].max > maxAge ) {
      if (checkbox4Ref.current)  checkbox4Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[4].min < minAge || AgeFilterOptionConstant[4].max > maxAge ) {
      if (checkbox5Ref.current)  checkbox5Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[5].min < minAge || AgeFilterOptionConstant[5].max > maxAge ) {
      if (checkbox6Ref.current)  checkbox6Ref.current.checked = false;
    }
  }

  const handleGenderCheckbox = (e) => {
    setLikelyGender('');
    setLikelyGenderModel('');
    if(e.target.checked) {
      setLikelyGender(e.target.value);
      setLikelyGenderModel(e.target.value);
      if (maleCheckboxRef.current && e.target.value == 'female')  { maleCheckboxRef.current.checked = false; }
      if (femaleCheckboxRef.current && e.target.value == 'male')  { femaleCheckboxRef.current.checked = false; }
    }
  }

  const getPredefinedFilterValue = () => {
    const Magic_List_Filter_Value = 26;
    const preDefinedFilterValue = localStorage.getItem('predefined_filter_value');
    if(preDefinedFilterValue == Magic_List_Filter_Value) {
      setAgeMax(79)
      setAgeMin(65)
      setAgeMaxModel(79)
      setAgeMinModel(65)
     // localStorage.removeItem('predefined_filter_value')
    }
  }

  useEffect(() => {
    if (show) {
      setFileName("");
      setAgeMax(null)
      setAgeMin(null)
      setAgeMaxModel(null)
      setAgeMinModel(null)
      setDncCheck(false)
      setDncCheckModel(false)
      setRelativeContacts(false)
      setRelativesCheckModel(false)
      setLikelyGender('')
      setLikelyGenderModel('')
    }
    getPredefinedFilterValue();
    handleChecked(ageMinModel, ageMaxModel);  
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      className="glassModel"
      size="lg"
    >
      {/* <Modal.Header >
        <Modal.Title>Property {handleUpperCase(type)}</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <Row className="d-flex justify-content-end mb-2">
          <Col md={1}>
            <AiFillCloseCircle onClick={handleClose} cursor={"pointer"} />
          </Col>
        </Row>
        <div className="modal_notification_property">
          <h4>Property {handleUpperCase(type)}</h4>
          <p className="m-0">
            Records: <strong>{GetFormattedNumber(selected)}</strong>{" "}
          </p>
          <p>
            You have <strong>{GetFormattedNumber(remine)}</strong> {type}{" "}
            Credits
          </p>

          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  onChange={(e) => setFileName(e.target.value)}
                  placeholder="Enter file name"
                  value={fileName}
                  style={{ width: "100%" }}
                />
                {fileNameError && (
                  <span className="text-danger d-block">{fileNameError}</span>
                )}

                {/** Show to all */}
                {type != "Download" ?  (
                  <div className="mt-4">
                    <button
                      onClick={() => setShowAgeFilter(!showAgeFilter) }
                      className="btn btn-primary"
                    >
                      Demographic Filters{" "}
                      {showAgeFilter ? (
                        <FaChevronDown
                          className="ms-1"
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        <FaChevronUp
                          className="ms-1 small"
                          style={{ color: "#fff" }}
                        />
                      )}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                  <div
                    className={showAgeFilter && type != "Download" ? 'ms-3 d-block': 'ms-3 d-none'}                    
                  >
                    <p className="small mt-3 mb-3">
                      * Each demographic category requires additional 0.2 skip per match. The Magic List already includes the age filter.
                    </p>
                    <label className="d-block mt-3 mb-2">
                       <span className="fw-bolder fs-6">Age Range</span>
                    </label>
                  
                    <Form.Check
                      inline
                      label={<span className="ms-2">{AgeFilterOptionConstant[1].label}</span>}
                      type="checkbox"
                      id="ageRange2"
                      onChange={(e) => handleAgeFilterCheckbox(e, 1)}
                      ref={checkbox2Ref}
                     
                    />
                    <Form.Check
                      inline
                      label={<span className="ms-2">{AgeFilterOptionConstant[2].label}</span>}
                      type="checkbox"
                      id="ageRange3"
                      onChange={(e) => handleAgeFilterCheckbox(e, 2)}
                      ref={checkbox3Ref}
                      className="ms-2"
                    />
                    <Form.Check
                      inline
                      label={<span className="ms-2">{AgeFilterOptionConstant[3].label}</span>}
                      type="checkbox"
                      id="ageRange4"
                      onChange={(e) => handleAgeFilterCheckbox(e, 3)}
                      ref={checkbox4Ref}
                      className="ms-2"
                    />
                    <Form.Check
                      inline
                      label={<span className="ms-2">{AgeFilterOptionConstant[4].label}</span>}
                      type="checkbox"
                      id="ageRange5"
                      onChange={(e) => handleAgeFilterCheckbox(e, 4)}
                      ref={checkbox5Ref}
                      className="ms-2"
                    />
                    <Form.Check
                      inline
                      label={<span className="ms-2 mt-1">{AgeFilterOptionConstant[5].label}</span>}
                      type="checkbox"
                      id="ageRange6"
                      onChange={(e) => handleAgeFilterCheckbox(e, 5)}
                      ref={checkbox6Ref}
                      className="ms-2"
                    />
                     
                       <label className="d-block mt-4 mb-2">
                       <span className="fw-bolder fs-6">Most Likely Gender</span> (90% Accuracy. Non-Individuals Will Be Excluded From Search.)
                    </label>
                    <Form.Check
                      name="most_likely_gender_1"
                      inline
                      label={<span className="ms-2">Male</span>}
                      value={'male'}
                      type="checkbox"
                      id="most_likely_gender_male"
                      onChange={handleGenderCheckbox}
                      ref={maleCheckboxRef}
                    />
                    <Form.Check
                      name="most_likely_gender_2"
                      inline
                      label={<span className="ms-2">Female</span>}
                      value={'female'}
                      type="checkbox"
                      id="most_likely_gender_female"
                      onChange={handleGenderCheckbox} 
                      ref={femaleCheckboxRef}
                    />  

                    <label className="d-block mt-4 mb-2">
                       <span className="fw-bolder fs-6">Relatives' Contact Information</span>
                    </label>
                    <Form.Check
                      name="relative_contact_data"
                      inline
                      label={<span className="ms-2">Include relatives' contacts</span>}
                      type="checkbox"
                      id="relatives_data"
                      onChange={(e) => {setRelativeContacts(!relativesCheckModel); setRelativesCheckModel((previousValue) => !previousValue);  }}
                      checked={relativesCheckModel}
                    />

                 
                    <hr/>
                  </div>

                <div className={type != "Download" ? 'mt-4 ms-2 d-block': 'mt-4 ms-2 d-none'}>
                 <h4> DNC Scrub 
                 <p className="form-check-label mt-2">* DNC Scrub requires additional 0.1 skip per match.</p>
                 </h4>
                 
                  <Form.Check
                      name="dnc_checkbox"
                      label={<span className="ms-2">DNC Scrub</span>}
                      type="checkbox"
                      id="dncheckbox"
                      onChange={(e) => {setDncCheck(!dncCheckModel); setDncCheckModel((previousValue) => !previousValue);  }}
                      checked={dncCheckModel}
                    />
                </div>
                 
              </Form.Group>
              <div className="mt-4">
                <span className="text">
                  You will spend <strong>{GetFormattedNumber(selected)}</strong>{" "}
                  {type} Credits
                </span>
                <br />
                {type !== "Download" && (
                  <span className="text">
                    You will spend{" "}
                    <strong>{GetFormattedNumber(selected)}</strong> Download
                    Credits
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <div className="mt-3">
            {/* when input value is empty */}
            {fileName != "" ? (
              <Button
                variant="primary"
                onClick={submitFunction}
                onClickCapture={(e) => (e.currentTarget.disabled = true)}
              >
                {handleUpperCase(type)}
              </Button>
            ) : (
              <Button variant="primary" onClick={handleMessage}>
                {type}
              </Button>
            )}

            <Button variant="warning" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// Pricing Package Modal
export const PricePackageModal = ({ handleClose, showPriceModule }) => {
  const item = {
    title: "Primeium",
    price: "5499.00",
    hits: 200,
    downloads: 5000,
    pricePerHit: "0.50",
  };

  const [packageList, setPackageList] = useState([]);

  const getPackageList = async () => {
    // setShowLoader(true);

    try {
      const headers = {
        "Content-Type": "application/json",
      };

      const response = await axios.get(PACKAGES_GET_LIST_ENDPOINT, { headers });
      if (response.data.statusCode == 200 && response.data?.result) {
        setPackageList(response.data.result);
      }
      // setShowLoader(false);
    } catch (error) {
      // setErrorMsg(err?.message);
      // setShowLoader(false);
    }
  };

  useEffect(() => {
    getPackageList();
  }, []);

  return (
    <Modal
      show={showPriceModule}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title> Pricing Plan</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="py-4">
        <h4 className="pricing_head">
          Choose The Best Package For Your Property Search and Skip Tracing
          Needs
        </h4>
        <Row className="my-3">
          {packageList.map((item) => {
            return (
              <Col md={3}>
                <PriceCardDash pricePlan={item} />
              </Col>
            );
          })}
        </Row>
        {/* <Button variant="warning" onClick={handleClose}>Close</Button> */}
      </Modal.Body>
    </Modal>
  );
};

// Skip Trace Package Modal
export const SkipTraceModal = ({ show, handleClose }) => {
  const skips = [
    {
      prcent: 60,
      count: 3000,
      price: 240,
      cent: 8,
    },
    {
      prcent: 60,
      count: 10000,
      price: 700,
      cent: 7,
    },
    {
      prcent: 60,
      count: 30000,
      price: 1800,
      cent: 6,
    },
    {
      prcent: 60,
      count: 100000,
      price: 5000,
      cent: 5,
    },
  ];

  return (
    <Modal
      show={show}
      // onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
      className="glassModel glassModalSkips"
    >
      <Modal.Body className="py-4">
        <Row className="d-flex justify-content-between ">
          <Col xs={11} sm={11} md={11} lg={11} className="text-center">
            <Image src={require("./../../assets/img/logo.png")} alt="" />
          </Col>
          <Col xs={1} sm={1} md={1} lg={1}>
            <AiFillCloseCircle
              size={30}
              cursor={"pointer"}
              onClick={handleClose}
            />
          </Col>
        </Row>

        <h4 className="skip_modal_title">
          24-Hour Flash Sale - <br /> Unskippable Savings on Skip Tracing!
        </h4>
        <h3 className="skip_offer_text">Up To 60% Off High-Quality Data</h3>

        <Row>
          <Col md={11} className="mx-auto">
            <Row className="my-5">
              {skips?.map((skip, s) => (
                <Col md={3} key={s}>
                  <SkipTraceCard item={skip} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <p className="skip_des_text">
          for the next 24 hours,seize the opportunity to access the
        </p>
        <p className="skip_des_text">
          {" "}
          heighest quality skip trace data at more than 60% off.
        </p>
        <p className="skip_des_text">
          These skips never expire and you can use them at anytime!
        </p>
        <span className="skip_span_text">
          For any question please email{" "}
          <a href="mailto:support@skipify.ai" className="mailto">
            support@skipify.ai
          </a>{" "}
        </span>
      </Modal.Body>
    </Modal>
  );
};
