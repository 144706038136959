export const API_HOST = process.env.REACT_APP_BACKEND_URL;

//export const API_HOST = 'http://localhost:3500';

// Plego
//export const API_HOST = "https://api-skipify.plego.cloud/"

export const SET_TIME_OUT = 5000;


export const LOGIN_API_ENDPOINT = `${API_HOST}/auth/login`;
export const REGLOGIN_API_ENDPOINT = `${API_HOST}/auth/reg-login`;
export const REGISTER_API_ENDPOINT = `${API_HOST}/clients/create`;
export const REGISTER_API_PAYMENT_CLIENT_ENDPOINT = `${API_HOST}/clients/create-payment`;
export const REGISTER_API_PAYMENT_CLIENT_UPDATE_ENDPOINT = `${API_HOST}/clients/reg-payment`
export const ACCOUNT_ACTIVATION_API_ENDPOINT = `${API_HOST}/clients/activate`;
export const FORGET_PASSWORD_API_ENDPOINT = `${API_HOST}/auth/forget-password`;
export const VERIFY_RESET_CODE_API_ENDPOINT = `${API_HOST}/auth/verify-reset-code`;
export const NEW_PASSWORD_API_ENDPOINT = `${API_HOST}/auth/new-password`;
export const GET_PROFILE_ENDPOINT = `${API_HOST}/clients/profile`
export const PROFILE_IMAGE_ENDPOINT = `${API_HOST}/uploads/profile`
export const UPDATE_PROFILE_ENDPOINT = `${API_HOST}/clients/update`
export const UPDATE_PASSWORD_ENDPOINT = `${API_HOST}/clients/update-password`
export const CANCEL_ACCOUNT_ENDPOINT = `${API_HOST}/clients/cancel-account`;
export const RESEND_VERIFICATION = `${API_HOST}/clients/resend-verification`;
export const GET_LIST_STORAGE_LIMIT_ENDPOINT = `${API_HOST}/clients/get-storage-limit`;
export const GET_CUSTOMER_LIST_COUNT_ENDPOINT = `${API_HOST}/skiptrace/get-customer-skipped-records-count`;
export const NEW_EMAIL_VERIFICATION_API_ENDPOINT = `${API_HOST}/clients/verify-new-email`;


// Property 
export const PROPERTY_SEARCH_ENDPOINT = `${API_HOST}/property/search-property`

//skip trace
export const SKIPTRACE_BATCH_ENDPOINT = `${API_HOST}/skiptrace/batch`
export const SKIPTRACE_FILE_UPLOAD_ENDPOINT = `${API_HOST}/skiptrace/file-upload`
export const SKIPTRACE_PROPERTY_ORDER_ENDPOINT = `${API_HOST}/skiptrace/skiptrace-property-order`
export const SKIPTRACE_DELETE_ORDER_ENDPOINT = `${API_HOST}/skiptrace/delete-order`
// Place order
export const SKIPTRACE_PLACE_ORDER_API_ENDPOINT = `${API_HOST}/skiptrace/place-order`

// Get Properties by Property IDS
export const GET_PROPERTIES_BY_IDS_ENDPOINT = `${API_HOST}/property/get-properties`

export const GET_PROPERTIES_BY_REQUEST_ID_ENDPOINT = `${API_HOST}/files/download/properties`

// My Files
export const GET_MY_FILES_LIST_API_ENDPOINT = `${API_HOST}/files/list`

// Download BY downloadId/orderId
export const DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT = `${API_HOST}/files/download`
export const DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT_JSON = `${API_HOST}/files/download-json`
export const DOWNLOAD_PROPERTY_FILE_API_ENDPOINT = `${API_HOST}/files/download/propertylist`
export const DOWNLOAD_PROPERTY_FILE_API_ENDPOINT_JSON = `${API_HOST}/files/download/propertylist-json`

// Payment confirmation
export const PAYMENT_SUCCESS_API_ENDPOINT = `${API_HOST}/payment/create-payment`
export const PAYMENT_LIST_API_ENDPOINT = `${API_HOST}/payment/list`
export const GET_INVOICE_DETAIL = `${API_HOST}/payment/detail/`
export const CREATE_PAYMENT_INTENT_ENDPOINT = `${API_HOST}/payment/create-payment-intent`

// Notifications
export const NOTIFICATION_LIST_API_ENDPOINT = `${API_HOST}/notification/list`
export const NOTIFICATION_UNREAD_LIST_API_ENDPOINT = `${API_HOST}/notification/list-unread`
export const NOTIFICATION_MARK_AS_READ_API_ENDPOINT = `${API_HOST}/notification/mark-as-read`;
export const ALL_NOTIFICATIONS_MARK_AS_READ_API_ENDPOINT = `${API_HOST}/notification/read-all`;

// Dashboard
export const DASHBOARD_GET_SUMMARY_ENDPOINT = `${API_HOST}/dashboard/get-dashboard-summary`
export const DASHBOARD_GET_PROPERTY_CHART_DATA_ENDPOINT = `${API_HOST}/dashboard/get-property-chart-data`

// Billings
export const BILLING_GET_CUSTOMER_DETAILS_ENDPOINT = `${API_HOST}/billing/get-new-billing-details`
export const BILLING_UPDATE_PAYMENT_DETAILS_ENDPOINT = `${API_HOST}/billing/update-payment-method`
export const BILLING_GET_LIST_ENDPOINT = `${API_HOST}/billing/get-billings-list`
export const BILLING_GET_SINGLE_ACTIVE_ENDPOINT = `${API_HOST}/billing/get-single-active-billing`
export const BILLING_DISABLE_ENDPOINT = `${API_HOST}/billing/disable`
export const BILLING_ENABLE_ENDPOINT = `${API_HOST}/billing/enable`;
export const BILLING_DELETE_ENDPOINT = `${API_HOST}/billing/delete`;
export const BILLING_PAYMENT_FAILED = `${API_HOST}/billing/payment-failed`;

export const BILLING_AUTO_RENEW_SETTINGS_ENDPOINT = `${API_HOST}/billing/auto-renew-settings`
export const BILLING_GET_AUTO_RENEW_SETTINGS_ENDPOINT = `${API_HOST}/billing/get-auto-renew-settings`
export const BILLING_GET_CURRENT_PLAN_DETAILS_ENDPOINT = `${API_HOST}/billing/get-current-plan-details`
export const BILLING_NEW_PLAN_PURCHASED_ENDPOINT = `${API_HOST}/billing/new-plan-purchased`;
export const BILLING_ADDITIONAL_PURCHASED_ENDPOINT = `${API_HOST}/billing/additional-purchased`;
export const BILLING_DOWNGRADE_PURCHASED_ENDPOINT = `${API_HOST}/billing/downgrade-plan-purchased`;
export const BILLING_FREE_PURCHASED_ENDPOINT = `${API_HOST}/billing/convert-free-plan`;
export const BILLING_CRON_JOB = `${API_HOST}/billing/run-subscription-job`
export const LIST_ROTATION_ACTIVATE_ENDPOINT = `${API_HOST}/list-rotation/activate`
export const LIST_ROTATION_GET_DETAILS_ENDPOINT = `${API_HOST}/list-rotation/get-details`
export const LIST_ROTATION_GET_ACTIVATION_PRICE_ENDPOINT = `${API_HOST}/list-rotation/get-activation-price`;
// List Storage 
export const LIST_STORAGE_SUBSCRIPTION_PURCHASE_ENDPOINT = `${API_HOST}/list-storage/save-payment`;
export const LIST_STORAGE_SUBSCRIPTION_PRICE_ENDPOINT = `${API_HOST}/list-storage/get-price-details`;
export const LIST_STORAGE_GET_CUSTOMER_REMAINING_ENDPOINT = `${API_HOST}/list-storage/get-remaining`;
export const LIST_STORAGE_GET_CUSTOMER_ACTIVE_SUBSCRIPTION_ENDPOINT = `${API_HOST}/list-storage/get-active-subscription`;
export const LIST_STORAGE_REMOVE_CUSTOMER_ACTIVE_SUBSCRIPTION_ENDPOINT = `${API_HOST}/list-storage/remove-subscription`;

// Package list
export const PACKAGES_GET_LIST_ENDPOINT = `${API_HOST}/package/list`

// Contact us 
export const SUBMIT_CONTACTUS_FORM_ENDPOINT = `${API_HOST}/contact/add`;
export const CONTACT_SALES_ADD_ENDPOINT = `${API_HOST}/contact-sales/add`;
// personal indo
export const SUBMIT_PERSONAL_INFO_ADD = `${API_HOST}/home/personal-info/add`;
// Newsletter subscribe
export const SUBMIT_NEWSLETTER_SUBSCRIBE = `${API_HOST}/home/newsletter/subscribe`;

export const BOOK_DEMO_CREATE_ENDPOINT = `${API_HOST}/demo/add`;

// Flash Sale
export const FLASH_SALE_BY_USER_ENDPOINT = `${API_HOST}/flash-sale/get-flash-sales`;
export const FLASH_SALE_BY_ID_ENDPOINT = `${API_HOST}/flash-sale/get-sale-by-id`;
export const FLASH_SALE_PURCHASED_ENDPOINT = `${API_HOST}/flash-sale/flash-sales-purchased`;
export const FLASH_SALE_PAYMENT_FAILED_ENDPOINT = `${API_HOST}/billing/payment-failed`;

// Affiliate Program
export const AFFILIATE_GET_DETAILS_ENDPOINT = `${API_HOST}/affiliate/get-details`;
export const AFFILIATE_GET_GROSS_STATS_ENDPOINT = `${API_HOST}/affiliate/get-gross-stats`;
export const AFFILIATE_GET_PAYMENTS_HISTORY_ENDPOINT = `${API_HOST}/affiliate/get-payments-history`;
export const AFFILIATE_GET_REFERRAL_ACTIVITIES_ENDPOINT = `${API_HOST}/affiliate/get-referral-activities`;
export const AFFILIATE_GET_REFERRAL_LIST_ENDPOINT = `${API_HOST}/affiliate/get-referral-list`;
export const AFFILIATE_SAVE_PAY_EMAIL_ENDPOINT = `${API_HOST}/affiliate/save-pay-email`;
export const AFFILIATE_NEW_CREATE_REQUEST_ENDPOINT = `${API_HOST}/affiliate/create`;
export const AFFILIATE_GET_REVENUE_DATA_ENDPOINT = `${API_HOST}/affiliate/get-revenue-data`;
export const AFFILIATE_SAVE_PAYOUT_INFO_ENDPOINT = `${API_HOST}/affiliate/update-payout-info`;
export const AFFILIATE_GET_DUE_PAYMENTS_ENDPOINT = `${API_HOST}/affiliate/get-due-payments`;
