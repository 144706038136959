export const WeatherTypeConstant = [
  {
    value: "hail",
    label: "Hail",
  },
  {
    value: "rain",
    label: "Rain Fall",
  },
  {
    value: "tornado",
    label: "Tornado",
  },
  {
    value: "wind",
    label: "High Winds",
  },
];

export const WeatherTypeHailSizeConstant = [
  {
    value: "all",
    label: "All",
    min: 0,
    max: 100
  },
  // {
  //   value: "1",
  //   label: 'Up to 1"',
  //   min: 0,
  //   max: 1,
  // },
  // {
  //   value: "1.5",
  //   label: '1" - 1.5"',
  //   min: 1,
  //   max: 1.5,
  // },
  // {
  //   value: "2",
  //   label: '1.5" - 2"',
  //   min: 1.5,
  //   max: 2,
  // },
  {
    value: "3",
    label: '2" and greater',
    min: 2,
    max: 100
  },

];

export const WeatherTypeRainFallConstant = [
  {
    value: "all",
    label: "All",
    min: 0,
    max: 100
  },
  // {
  //   value: "2",
  //   label: 'Up to 2"',
  //   min: 0,
  //   max: 2,
  // },
  // {
  //   value: "4",
  //   label: '2" - 4"',
  //   min: 2,
  //   max: 4,
  // },
  {
    value: "5",
    label: '4" and greater',
    min: 4,
    max: 100
  },
 
];

export const WeatherTypeTornadoConstant = [
    {
      value: "show",
      label: "Show",
      min: 0,
      max: 1000
    },
  ];

export const WeatherTypeWindSpeedConstant = [
  //  {
  //   value: "all",
  //   label: "All",
  //   min: 0,
  //   max: 10000
  // },
  {
    value: "60",
    label: "60 MPH and greater",
    min: 60,
    max: 10000,
  },
  // {
  //   value: "70",
  //   label: "70 MPH - 80 MPH",
  //   min: 70,
  //   max: 80,
  // },
  {
    value: "80",
    label: "80 MPH and greater",
    min: 80,
    max: 10000,
  },
  {
    value: "100",
    label: "100 MPH and greater",
    min: 100,
    max: 10000
  },
 
];

export const WeatherEventDaysConstant = [
    {
        value: "7",
        label: "Last 7 Days"
    },
    {
        value: "30",
        label: "Last 30 Days"
    },
    {
        value: "90",
        label: "Last 90 Days"
    },
    {
        value: "180",
        label: "Last 180 Days"
    },
    {
        value: "365",
        label: "Last 365 Days"
    }
]
