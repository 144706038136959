import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import noImage from "../assets/new_theme/img/user.png";
import arrowDropdown from "../assets/new_theme/img/down-arrow.png";
import IconExpand from "../assets/new_theme/img/icon-expand.png";

import { GetProfile } from "../Utils/common";
import { PROFILE_IMAGE_ENDPOINT } from "../Constants/api-routes.constant";
import { IsLoggedIn } from "../Utils/auth-verification.util";
import HeaderNotification from "./HeaderNotification";
import { FiMenu, FiAlignLeft } from "react-icons/fi";
import { OverlayTrigger, Tooltip, Dropdown, Row, Col, Image } from "react-bootstrap";
import { BiSearch, BiSolidBell, BiSolidUser, BiCloudDownload, BiDownload } from "react-icons/bi";
import { IoLogOut } from "react-icons/io5";
import { GetFormattedNumber } from "../Utils/formating.util";
import { updateCurrentPlan } from "../store/slices/features/payment-plan";
import { createActiveAddSkip } from "../store/slices/features/app";
import { getCurrentPlanRemainDownloads, getCurrentPlanRemainHits } from "../store/selectors/features/payment-plan";
import {
  GetCustomerListStorageLimit,
  GetCustomerListStorageCount,
} from "../Utils/clients.util";

import { TbHomeSearch, TbDatabase  } from "react-icons/tb";
import { HiOutlineDownload , HiOutlineDatabase } from "react-icons/hi";
import { BsDatabaseFill } from "react-icons/bs";
import bellIcon from "./../assets/new_theme/img/bell.png"
import storageIcon from "./../assets/new_theme/img/storage.png"
import noImage2 from "./../assets/img/avatar.png";

const Header = ({ setActive, active, title, screen }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const remainingDownloads = useSelector(getCurrentPlanRemainDownloads);
  const remainingHits = useSelector(getCurrentPlanRemainHits);
  const [remainingListStorage, setRemainingListStorage] = useState();


  const [profileData, setProfileData] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  if (!IsLoggedIn()) {
    window.location = "/";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    GetProfile().then((response) => {
      if (response?.statusCode == "200" && response?.result) {
        dispatch(updateCurrentPlan(response.result.billing));
        setProfileData(response.result);
        if (response.result?.profileImage)
          setProfileImage(
            `${PROFILE_IMAGE_ENDPOINT}/${response.result?.profileImage}`
          );
      }
    });
    getRemainingListStorage();

  }, []);

  const getRemainingListStorage = async () => {
    const listStorageLimit = await GetCustomerListStorageLimit();
    const listStorageUsage = await GetCustomerListStorageCount();

    if(listStorageLimit < listStorageUsage) {
      setRemainingListStorage(0)
    } else if(listStorageLimit > 100000000) {
      setRemainingListStorage(-1)
    } else {
      setRemainingListStorage(listStorageLimit - listStorageUsage)
    }
  }

  const handleNotificationCount = (totalCount) => {
    setNotificationCount(totalCount);
  }

  const showDropDown = () => {
    try{
      let menuDp = document.querySelector(".menu-dropdown");
      menuDp.style.display = "block";

      document.addEventListener("mouseup", function (e) {
        if (!menuDp.contains(e.target)) {
          menuDp.style.display = "none";
        }
      });
    }catch(e){console.log(e)}
  };

  const showNotify = () => { setShowNotification(!showNotification) }

  const handleSkipRedirection = (index) => {
    dispatch(createActiveAddSkip(index));
    navigate('/billing');
  }

  document.addEventListener("click", function (event) {
    var navbar = document.getElementById("page-content-wrapper");
    var content = document.querySelector("conmain-headertent");

  });



  return (
    <header className="main-header mb-3">
      <Row>
        <Col xs={0} sm={0} md={2} lg={4} xl={4}>
          <div className="menu_toggle_icon">
            {/* {!screen && <>{!active ? <Image src={IconExpand} className="icon-nav-expand" onClick={() => setActive(!active)} /> : <Image src={IconExpand} className="icon-nav-expand" onClick={() => setActive(!active)} />}</>} */}
            <h2 className="ms-0 main-title-desktop">{title}</h2>
          </div>
        </Col>

        <Col xs={12} sm={12} md={12} lg={8} xl={8}>

          <div className="header-notification">
            <ul>

              <li className="remain_downloads" title="Downloads" onClick={() => handleSkipRedirection(1)}>
                <span><HiOutlineDownload /></span>
                <span>{GetFormattedNumber(remainingDownloads)}</span>
              </li>
              <li className="remain_downloads" title="Skip Trace" onClick={() => handleSkipRedirection(1)}>
                <span><TbHomeSearch /></span>
                <span>{GetFormattedNumber(remainingHits)}</span>
              </li>
              {remainingListStorage >= 0 ? 
              <li className="remain_downloads" title="List Storage" onClick={() => handleSkipRedirection(5)}>
                {/* <span><TbDatabase  /></span> */}
                <Image src={storageIcon} className="icon storage-icon"></Image>
                <span>{GetFormattedNumber(remainingListStorage)}</span>
              </li>
              : ""}

              <li className="remain_skiptrace upgrade-skiptrace" onClick={() => handleSkipRedirection(0)}>
                Upgrade
              </li>


              <li className="notification" onClick={showNotify}>
                {/* <BiSolidBell /> */}
                <Image src={bellIcon} className="bellIcon"></Image>
                <span>{notificationCount}</span>
              </li>
              <li className="avatar" onClick={showDropDown}>
                <Dropdown>
                  <Dropdown.Toggle>
                    {profileImage ? <img className="profileImg" src={!profileImage?.includes('no_image_set') ? profileImage  : noImage2} alt="" /> : <img className="profileImg" src={noImage2} />}
                    <span className="text-wrap">{profileData?.firstName?.slice(0, 10)} {profileData?.lastName?.slice(0, 10)}</span>
                    <img className="chevIcon" src={arrowDropdown} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link to="/settings" className="dropdown-item"> <BiSolidUser />Settings</Link>
                    <Link to="/logout" className="dropdown-item"> <IoLogOut />Logout</Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <HeaderNotification setNotificationCount={handleNotificationCount} showNotification={showNotification} />
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
