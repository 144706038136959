import { BiLockOpenAlt } from "react-icons/bi";
import React, { useEffect, useState, useRef } from "react";
import {
  NEW_PASSWORD_API_ENDPOINT,
  PROFILE_IMAGE_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
} from "../Constants/api-routes.constant";
import { NEW_PASSWORD_REGEX } from "../Constants/regex.constant";
import { GetAuthToken } from "../Utils/auth-verification.util"
import { Col, Row, Form } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


const SettingsNewPassword = (props) => {


  // Show/hide password on click
  const [showPass, setShowPass] = useState(false)
  const [showConPass, setShowConPass] = useState(false);
  const [currentPass, setCurrentPass] = useState(false)

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPasswordErr, setCurrentPasswordErr] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // Fields reference
  const currentPassRef = useRef();
  const pass1Ref = useRef();
  const pass2Ref = useRef();

  const handleResetPasswordSubmit = (e) => {
    e.preventDefault()
    setNewPasswordErr("");
    setCurrentPasswordErr("");
    setConfirmPasswordErr("");
    setErrorMsg("");
    setSuccessMsg("");

    if (!currentPassword?.trim()) {
      // Please enter current password
      setCurrentPasswordErr("Please enter your current password");
      return;
    }
    if (!newPassword?.trim()) {
      // Please enter New and confirm password
      setNewPasswordErr("Please enter new password");
      return;
    }

    if (!confirmPassword?.trim()) {
      // Please enter New and confirm password
      setNewPasswordErr("Please enter confirm new password");
      return;
    }

    if (!newPassword.match(NEW_PASSWORD_REGEX)) {
      setNewPasswordErr(
        "New password must contain uppercase letters, special characters, numeric digits and length should be at least 8 characters"
      );
      // The new password should meet the following criteria:

      // It must contain uppercase letters.
      // It must include special characters.
      // It should have numeric digits.
      // The length should be at least 8 characters.
      return;
    }

    if (newPassword?.trim() !== confirmPassword?.trim()) {
      setConfirmPasswordErr("New password value does not match with the Confirm New Password value");
      return;
    } else {
      setConfirmPasswordErr("");
    }



    const token = GetAuthToken();
    fetch(UPDATE_PASSWORD_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
      body: JSON.stringify({ newPassword, currentPassword }),
    })
      .then((response) => {
        return response?.json();
      })
      .then((response) => {
        if (response?.statusCode == 200) {
          setSuccessMsg("Your password has been updated successfully.");
          setNewPassword('');
          setConfirmPassword('');
          setCurrentPassword('');
          if(currentPassRef.current) currentPassRef.current.value = '';
          if(pass1Ref.current) pass1Ref.current.value = '';
          if(pass2Ref.current) pass2Ref.current.value = '';
        } else {
          const errMsg = response?.message ?? "Unable to update your password. Please try again.";
          setErrorMsg(errMsg);
        }
      });
  };
  return (
    <>
      {successMsg && <p className="alert alert-success">{successMsg}</p>}
      {errorMsg && <p className="alert alert-danger">{errorMsg}</p>}

      <h4 className="mt-5 title-sub">Reset Password</h4>

      <Row className="my-3 mt-4">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form onSubmit={handleResetPasswordSubmit} method="POST">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <Form.Group className="mb-3 password" >
                  <Form.Label>  Current Password * </Form.Label>
                  <Form.Control
                    type={currentPass ? 'text' : 'password'}
                    className="form-control custom-field"
                    onChange={(e) => { setCurrentPassword(e.target.value); setCurrentPasswordErr("") }}
                    name="currentPassword"
                    autoComplete="new-password"
                    value={currentPassword}
                    ref={currentPassRef}
                  />
                  {currentPass ? <AiOutlineEye className="show_password2" onClick={() => setCurrentPass(!currentPass)} /> : <AiOutlineEyeInvisible className="show_password2" onClick={() => setCurrentPass(!currentPass)} />}
                </Form.Group>
                {currentPasswordErr && <p className="error-message">{currentPasswordErr}</p>}
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <Form.Group className="mb-3 password" >
                  <Form.Label> New Password * </Form.Label>
                  <Form.Control
                    type={showPass ? 'text' : 'password'}
                    className="form-control custom-field"
                    onChange={(e) => { setNewPassword(e.target.value); setNewPasswordErr('') }}
                    name="newPassword"
                    ref={pass1Ref}
                  />
                  {showPass ? <AiOutlineEye className="show_password2" onClick={() => setShowPass(!showPass)} /> : <AiOutlineEyeInvisible className="show_password2" onClick={() => setShowPass(!showPass)} />}
                </Form.Group>
                {newPasswordErr && <p className="error-message">{newPasswordErr}</p>}

              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <Form.Group className="mb-3 password" >
                  <Form.Label>  Confirm New Password * </Form.Label>
                  <Form.Control
                    type={showConPass ? 'text' : 'password'}
                    className="form-control custom-field"
                    onChange={(e) => { setConfirmPassword(e.target.value); setNewPasswordErr('') }}
                    name="confirmPassword"
                    ref={pass2Ref}
                  />
                  {showConPass ? <AiOutlineEye className="show_password2" onClick={() => setShowConPass(!showConPass)} /> : <AiOutlineEyeInvisible className="show_password2" onClick={() => setShowConPass(!showConPass)} />}
                </Form.Group>
                {confirmPasswordErr && <p className="error-message">{confirmPasswordErr}</p>}
              </Col>
              {/* <Col xs={6} sm={6} md={4} lg={2}>
                <button
                  type="button"
                  className="cancelBtn"
                  onClick={() => window.location.reload(false)}
                >
                  Cancel
                </button>

              </Col> */}
            </Row>
            <Row>              
              <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                <button type="submit" className="cancelBtn mt-3">Save</button>
                <a href="/settings"  className="fw-normal ms-3">Cancel</a>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

    </>
  );
};

export default SettingsNewPassword;
