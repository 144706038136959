import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../../Constants/redux-slices';


const INITIAL_STATE = {
  seletedProperties: [],
  propertyRequestId: '',
  propertyCount: 0,
  fileName: '',
  isSkipTrace: false,
  searchPayload: {},
};

export const propertyListFeatureSlice = createSlice({
  name: SLICE_NAME.PROPERTY_LIST_FEATURE,
  initialState: INITIAL_STATE,
  reducers: {
    updateSeletedProperties: (state, action) => {
      state.seletedProperties = action.payload.selectedPropertyRecords;
      state.fileName = action.payload.fileName;
      state.isSkipTrace = action.payload.isSkipTrace;
    },
    updateAllPropertyRequest: (state, action) => {
      state.propertyRequestId = action.payload.requestId;
      state.propertyCount = action.payload.totalPropertyCount;
      state.fileName = action.payload.fileName;
      state.isSkipTrace = action.payload.isSkipTrace;
    },
    resetProprtyListData: (state) => {
      state.seletedPlan = [];
      state.propertyRequestId = '';
      state.propertyCount = 0;
      state.fileName = '';
      state.isSkipTrace = false;
    },
    addSearchPayload: (state, action) => {
      state.searchPayload = action.payload;
    }
  }
});

export const {
  updateSeletedProperties,
  updateAllPropertyRequest,
  resetProprtyListData,
  addSearchPayload,
} = propertyListFeatureSlice.actions;
export const propertyListFeatureReducer = propertyListFeatureSlice.reducer;
