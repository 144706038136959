import { CommercialOptionsConstant } from "../Constants/property/commercial-options.constant";
import { OfficeOptionsConstant } from "../Constants/property/office-options.constant";
import { RecreationalOptionsConstant } from "../Constants/property/recreational-options.constants";
import { IndustrialOptionsConstant } from "../Constants/property/industrial-options.constant";
import { AgriculturalOptionsConstant } from "../Constants/property/agricultural-options.constant";
import { VacantLandOptionsConstant } from "../Constants/property/vacant-land-options.constant";
import { ExemptOptionsConstant } from "../Constants/property/exempt-options.constant";
import { MiscellaneousOptionsConstant } from "../Constants/property/misc-options.constant";
import { ResidentialOptionsConstant } from "../Constants/property/residential-options.constant";

export const PropertyFilterUtil = async (
  classification,
  propertyTypeOptions,
  propertyType
) => {
  // Residentials
  if (classification.includes("residential")) {
    propertyTypeOptions = propertyTypeOptions.concat(
      ResidentialOptionsConstant
    );
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Residential"
    );
    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Residential");
  }
  // Commercial
  if (classification.includes("commercial")) {
    propertyTypeOptions = propertyTypeOptions.concat(CommercialOptionsConstant);
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Commercial"
    );

    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Commercial");
  }
  // office
  if (classification.includes("office")) {
    propertyTypeOptions = propertyTypeOptions.concat(OfficeOptionsConstant);
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Office"
    );

    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Office");
  }
  // Recreational
  if (classification.includes("recreational")) {
    propertyTypeOptions = propertyTypeOptions.concat(
      RecreationalOptionsConstant
    );
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Recreational"
    );

    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Recreational");
  }
  // Industrial
  if (classification.includes("industrial")) {
    propertyTypeOptions = propertyTypeOptions.concat(IndustrialOptionsConstant);
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Industrial"
    );

    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Industrial");
  }
  // Agricultural
  if (classification.includes("agricultural")) {
    propertyTypeOptions = propertyTypeOptions.concat(
      AgriculturalOptionsConstant
    );
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Agricultural"
    );
    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Agricultural");
  }
  // Vacant Land
  if (classification.includes("vacant")) {
    propertyTypeOptions = propertyTypeOptions.concat(VacantLandOptionsConstant);
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Vacant Land"
    );

    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Vacant Land");
  }
  // Exempt
  if (classification.includes("exempt")) {
    propertyTypeOptions = propertyTypeOptions.concat(ExemptOptionsConstant);
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Exempt"
    );
    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter((ppt) => ppt.category != "Exempt");
  }
  // miscellaneous
  if (classification.includes("miscellaneous")) {
    propertyTypeOptions = propertyTypeOptions.concat(
      MiscellaneousOptionsConstant
    );
  } else {
    const propertyTypes = propertyTypeOptions.filter(
      (pty) => pty.category != "Miscellaneous"
    );

    propertyTypeOptions = propertyTypes;
    propertyType = propertyType.filter(
      (ppt) => ppt.category != "Miscellaneous"
    );
  }

  return {
    propertyTypeOptions,
    propertyType,
  };
};
