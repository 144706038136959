import { createSelector } from 'reselect';

const propertyListFeatureSelector = (state) => state.features.propertyList;

export const getSelectedProperties = createSelector(
  propertyListFeatureSelector,
  (propertyList) => propertyList.seletedProperties,
);

export const getPropertyRequestId = createSelector(
  propertyListFeatureSelector,
  (propertyList) => propertyList.propertyRequestId,
);

export const getPropertyCount = createSelector(
  propertyListFeatureSelector,
  (propertyList) => propertyList.propertyCount,
);

export const getPropertyFileName = createSelector(
  propertyListFeatureSelector,
  (propertyList) => propertyList.fileName,
);

export const getIsPropertySkipTrace = createSelector(
  propertyListFeatureSelector,
  (propertyList) => propertyList.isSkipTrace,
);

export const getSearchPayload = createSelector(
  propertyListFeatureSelector,
  (propertyList) => propertyList.searchPayload,
);
