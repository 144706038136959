import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../../Constants/redux-slices';


const INITIAL_STATE = {
  seletedPlan: {},
  currentPlan: {},
  currentbilling: {},
  totalPackages: [],
};

export const paymentPlanFeatureSlice = createSlice({
  name: SLICE_NAME.PAY_PLAN_FEATURE,
  initialState: INITIAL_STATE,
  reducers: {
    changeSelectedPlan: (state, action) => {
      state.seletedPlan = action.payload;
    },
    resetSelectedPlan: (state) => {
      state.seletedPlan = {};
    },
    updateCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    updateCurrentBilling: (state, action) => {
      state.currentbilling = action.payload;
    },
    updatesPackagesData: (state, action) => {
      state.totalPackages = action.payload;
    }
  }
});

export const { 
  changeSelectedPlan,
  resetSelectedPlan,
  updateCurrentBilling,
  updateCurrentPlan,
  updatesPackagesData,
} = paymentPlanFeatureSlice.actions;
export const paymentPlanFeatureReducer = paymentPlanFeatureSlice.reducer;
