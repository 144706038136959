import { IoIosSave } from "react-icons/io";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Image, Col, Row, Form, Card, Button } from "react-bootstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import { GetProfile, UpdateProfile } from "../Utils/common";
import { US_State_List } from "../Constants/us-states.constant";
import { React_Select_Style } from "../Constants/react-select-style.constants";
import { USTimeZones } from "../Constants/timezones.constant";
import SettingsNewPassword from "./SettingsNewPassword";
import DashbaordLayout from "./DashbaordLayout";
import noImage from "../assets/img/avatar.png";
import { PROFILE_IMAGE_ENDPOINT } from "../Constants/api-routes.constant";
import uploadPhotoIcon from "../assets/new_theme/img/upload-photo.png";


const Settings = (props) => {

  const dispatch = useDispatch();
  const pageTitle = "Settings";

  const [profileData, setProfileData] = useState("");
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [showLoader, setShowLoader] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberWithMask, setPhoneNumberWithMask] = useState(""); //for masking values show
  const [stateProvince, setStateProvince] = useState("");
  const [city, setCity] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [timezone, setTimezone] = useState("");
  const [notificationOption1, setNotificationOption1] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  const [countryOptionValue, setCountryOptionValue] = useState(options[236]);
  const [stateOptionValue, setStateOptionValue] = useState("");
  const [timezonOptionValue, setTimezoneOptionValue] = useState("");
  const [notification1, setNotification1] = useState();
  const [imageUpload, setImageUpload] = useState(null);
  // Error message
  const [imageErrorMsg, setImageErrorMsg] = useState("");
  const [formSuccessMsg, setFormSuccessMsg] = useState("");
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [isUpdateSubmitted, setIsUpdateSubmitted] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Account Settings - Skipify.ai';
    setShowLoader(true);
    GetProfile().then((response) => {
      if (response?.statusCode == "200" && response?.result) {
        setProfileData(response?.result);
        setFirstName(response?.result?.firstName);
        setLastName(response?.result?.lastName);
        setEmail(response?.result?.email);
        setPhoneNumber(response?.result?.phoneNumber);
        NormalizePhoneNumber(response?.result?.phoneNumber)
        setBillingAddress(response?.result?.streetAddress == "undefined" ? '' : response?.result?.streetAddress);
        setCity(response?.result?.city == "undefined" ? '' : response?.result?.city);
        setStateProvince(response?.result?.state == "undefined" ? '' : response?.result?.state);
        setZipCode(response?.result?.zipCode == "undefined" ? '' : response?.result?.zipCode);
        setDateOfBirth(response?.result?.dateOfBirth ?? "");
        //setTimezone(response?.result?.timezone ?? "");
        setCountry(response?.result?.country);

        setShowLoader(false);
        if (response?.result?.profileImage) {
          setProfileImage(
            `${PROFILE_IMAGE_ENDPOINT}/${response?.result?.profileImage}`
          );
        }
        if (response.result?.notificationOption1 === "email") {
          setNotification1(true);
        } else {
          setNotification1(false);
        }

        // Country dropdown
        if (!response.result?.country
          || response.result?.country === undefined
          || response.result?.country === ''
          || response.result.country === 'undefined') {
          setCountryOptionValue(options[236]);
          setCountry('US');
        } else {
          const selectedCountry = options.find(
            (op) => op.value == response?.result?.country
          );
          setCountryOptionValue(selectedCountry);
          setCountry(selectedCountry.value);
        }


        // State dropdown
        const selectedState = US_State_List.find(
          (st) => st.value == response.result?.state
        );
        setStateOptionValue(selectedState);
        setStateProvince(selectedState.value);

        try{
          // Timezone
          const selectedTimezone = USTimeZones.find(
            (tz) => tz.value == response.result?.timezone
          );
          setTimezoneOptionValue(selectedTimezone);
          setTimezone(selectedTimezone.value);
        }catch(e){console.log(e)}
      }
      setShowLoader(false);
    });
  }, [isUpdateSubmitted]);

  const handleCountryChange = (countryVal) => {
    if (countryVal.value === 'US') {
      setCountryOptionValue(countryVal);
      setCountry(countryVal.value);
    } else {
      setCountryOptionValue(countryVal);
      setCountry(countryVal.value);
      setStateProvince('');
    }
  };

  const handleUsStateChange = (stateVal) => {
    setStateOptionValue(stateVal);
    setStateProvince(stateVal.value);
  };

  const handleUsTimeChange = (timezoneVal) => {
    setTimezoneOptionValue(timezoneVal);
    setTimezone(timezoneVal.value);
  };

  const handleCheckbox1 = (val) => {
    setNotification1(!notification1);
  };

  const handleChangeImage = (event) => {
    const imageFile = event.target.files[0];
    if (
      !["image/jpeg", "image/png", "image/giff", "image/jpg"].includes(
        imageFile.type
      )
    ) {
      // error
      setImageErrorMsg("Please upload an image of type JPEG or PNG");
      return;
    }
    if (imageFile.size > 3145728) {
      setImageErrorMsg("The image size must be equal or less than 3 MB");
      return;
    }
    setImageErrorMsg();
    setProfileImage(event.target.files[0]);
    setImageUpload(event.target.files[0]);
  };

  const handleProfileUpdateSubmit = () => {
    setShowLoader(true);
    setFormErrorMsg("");
    setFormSuccessMsg("");
    setFirstNameErr("");
    setLastNameErr("");
    setEmailErr("");
    setPhoneNumberErr("");

    if (!firstName?.trim() || !lastName?.trim() || !phoneNumber) {
      if (!firstName?.trim()) {
        setFirstNameErr("First Name should not be empty.");
      }

      if (!phoneNumber) {
        setPhoneNumberErr("Phone Number should not be empty.");
      }

      if (!lastName?.trim()) {
        setLastNameErr("Last Name should not be empty.");
      }
      setShowLoader(false);
      return;
    }

    const cityValue = city === 'undefined' ? '' : city;
    const billingAddressValue = billingAddress === 'undefined' ? '' : billingAddress;
    const zipCodeValue = zipCode === 'undefined' ? '' : zipCode;
    const countryValue = country === 'undefined' ? '' : country;
    const stateProvinceValue = stateProvince === 'undefined' ? '' : stateProvince;
    const timezoneValue = timezone === 'undefined' ? '' : timezone;

    const data = {
      email,
      firstName,
      lastName,
      phoneNumber: phoneNumber.toString(),
      dateOfBirth,
      country: countryValue,
      state: stateProvinceValue,
      city: cityValue,
      streetAddress: billingAddressValue,
      zipCode: zipCodeValue,
      timezone: timezoneValue,
      notificationOption1: notification1 ? "email" : "no",
      profileImage: imageUpload,
    };
    UpdateProfile(data).then((response) => {
      if (response?.statusCode == 200) {
        if(profileData.email?.trim()?.toLowerCase() != email?.trim()?.toLowerCase()) {
          setFormSuccessMsg(<>
          A verification email has been sent to your address: <strong>{email.toLowerCase()}</strong>. 
          Once you verify your email, it will become your primary email address.
          </>);
        } else {
           setFormSuccessMsg("Information updated successfully.");
        }
        setIsUpdateSubmitted(!isUpdateSubmitted);
        setShowLoader(false);
      } else {
        if (response?.message?.length) {
          const fnErr = response.message.find((msg) =>
            msg.includes("First Name")
          );
          setFirstNameErr(fnErr);
          const lnErr = response.message.find((msg) =>
            msg.includes("Last Name")
          );
          setLastNameErr(lnErr);
          const emErr = response.message?.find((msg) => msg.includes("Email"));
          setEmailErr(emErr);
          const phoneErr = response.message.find((msg) =>
            msg.includes("Phone Number")
          );
          setPhoneNumberErr(phoneErr);
        }
        setFormErrorMsg("Unable to update your profile information.");
        
      }
      setShowLoader(false);
    });
  };

  // Function to remove the Masking from phone Number
  const NormalizePhoneNumber = (phone) => {
    const numericOnly = phone.replace(/\D/g, '');
    let maskedNumber = '';
    if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
    }
    if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
    }
    if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
    }
    setPhoneNumber(numericOnly.slice(0, 10));
    setPhoneNumberWithMask(maskedNumber);
  }


  return (
    <div className="d-flex" id="wrapper">
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}

      <DashbaordLayout title={pageTitle}>
        <div id="page-content-wrapper">

          <h2 className="main-title-mobile">{pageTitle}</h2>
          {formSuccessMsg ? (
            <p className="alert alert-success">{formSuccessMsg}</p>
          ) : (
            ""
          )}
          {formErrorMsg ? (
            <p className="alert alert-danger" style={{ paddingLeft: "10px" }}>
              {formErrorMsg}
            </p>
          ) : (
            ""
          )}

          <Card className="setting_form">
            <Card.Body className="p-0">
              <Row >
                <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2 }} xl={{ span: 12, order: 1 }}>
                  <Row>
                    <Col xl={12}>
                      <h4 className="title-sub">Name and Profile</h4>
                    </Col>

                    <Col xl={12} className="my-4">
                      <Row>
                        <Col xs={12} sm={12} xl={12} lg={12} md={12}>
                          <div id="result" className="uploadPreview">
                            {imageUpload && imageUpload != 'no_image_set' ? (
                              <img src={URL.createObjectURL(imageUpload)} className="img-fluid" />
                            ) : (

                              <img src={(profileImage && !profileImage?.includes('no_image_set')) ? profileImage : noImage} className="img-fluid" />
                            )}

                          </div>
                          {imageErrorMsg && <p className="text-danger" style={{ fontSize: "12px" }}>{imageErrorMsg}</p>}
                          <div className="overlay_setting">
                            <label htmlFor="uploadImage"> <Image className="uploadIcon" src={uploadPhotoIcon} /> <span>Upload</span></label>
                            <input
                              type="file"
                              id="uploadImage"
                              name="profileImage"
                              className="file_input"
                              onChange={handleChangeImage}
                            />
                          </div>
                          <div className="settings_photo_remove">
                          <a href="#" onClick={() => { setProfileImage(noImage); setImageUpload('no_image_set'); }}>Remove</a>
                          </div>                 
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                {/* OLD AFTER THIS */}

                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                      <Form.Group className="mb-3" >
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control custom-field"
                          value={firstName}
                          name="firstName"
                          onChange={(e) => setFirstName(e.target?.value?.slice(0, 25))}
                        />
                        {firstNameErr && <p className="error-message">{firstNameErr}</p>}
                      </Form.Group>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                      <Form.Group className="mb-3" >
                        <Form.Label> Last Name *</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control custom-field"
                          value={lastName}
                          name="lastName"
                          onChange={(e) => setLastName(e.target?.value?.slice(0, 25))}
                        />
                        {lastNameErr && <p className="error-message">{lastNameErr}</p>}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                      <Form.Group className="mb-3" >
                        <Form.Label> Birthday </Form.Label>
                        <Form.Control
                          type="date"
                          className="form-control custom-field"
                          name="dateOfBirth"
                          value={dateOfBirth ? dateOfBirth : ""}
                          onChange={(e) => setDateOfBirth(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                    <button
                        type="submit"
                        className="cancelBtn mb-3"
                        onClick={handleProfileUpdateSubmit}
                      >
                        Save
                      </button>
                      <a href="/settings"  className="fw-normal ms-3">Cancel</a>
                    </Col>
                  </Row>

                    <hr></hr>

                    <Row className="my-3 mt-5">
                      <Col xl={12}>
                        <h4 className="title-sub">Contact Info and Address</h4>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Group className="mb-3" >
                          <Form.Label> Email Address *</Form.Label>
                          <Form.Control
                            type="email"
                            className="form-control custom-field"
                            value={email}
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailErr && <p className="error-message">{emailErr}</p>}
                        </Form.Group>
                      </Col>


                      <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Group className="mb-3" >
                          <Form.Label>  Phone Number *</Form.Label>
                          <Form.Control
                            type="text"
                            name="phoneNumber"
                            onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); setPhoneNumberErr("") }}
                            value={phoneNumberWithMask ? phoneNumberWithMask : phoneNumber}
                          />
                          {phoneNumberErr && <p className="error-message">{phoneNumberErr}</p>}
                        </Form.Group>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="mb-4 mt-3">
                          <Form.Check
                            type="checkbox"
                            label={`Send me notifications via email`}
                            checked={notification1}
                            onClick={handleCheckbox1}
                            id="email_notification_checkbox"
                          />
                        </div>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Group className="mb-3" >
                          <Form.Label> Country </Form.Label>
                          <Select
                            name="country"
                            options={options}
                            value={countryOptionValue}
                            onChange={handleCountryChange}
                            styles={React_Select_Style}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Group className="mb-3" >
                          <Form.Label>  Billing Address </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control custom-field"
                            value={billingAddress}
                            name="streetAddress"
                            onChange={(e) => setBillingAddress(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Group className="mb-3" >
                          <Form.Label>  City </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control custom-field"
                            value={city}
                            name="city"
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Group className="mb-3" >
                          <Form.Label>  State </Form.Label>
                          {country == "US" ? (
                            <Select
                              value={stateOptionValue}
                              onChange={handleUsStateChange}
                              options={US_State_List}
                              styles={React_Select_Style}
                              name="state"
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              name="state"
                              value={stateProvince}
                              className="form-control custom-field"
                              onChange={(e) => setStateProvince(e.target.value)}
                            />
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Form.Group className="mb-3" >
                          <Form.Label>  Zip </Form.Label>
                          <Form.Control
                            type="number"
                            className="form-control custom-field"
                            value={zipCode}
                            min="0"
                            name="zipCode"
                            onChange={(e) => setZipCode(e.target.value.slice(0, 5))}
                          />
                        </Form.Group>
                      </Col>

                    {/* 
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <Form.Group className="mb-5" >
                        <Form.Label>   TIME ZONE </Form.Label>
                        <Select
                          value={timezonOptionValue ? timezonOptionValue : ''}
                          onChange={handleUsTimeChange}
                          options={USTimeZones}
                          styles={React_Select_Style}
                          name="timezone"
                        />
                      </Form.Group>
                    </Col> */}

                  </Row>

                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={3} className="mt-3 mb-3">
                      {/* <Button
                        type="Button"
                        className="cancelBtn"
                        onClick={() => window.location.reload(false)}
                      >
                        Cancel
                      </Button> */}

                      <button
                        type="submit"
                        className="cancelBtn mb-3"
                        onClick={handleProfileUpdateSubmit}
                      >
                        Save
                      </button>
                       <a href="/settings"  className="fw-normal ms-3">Cancel</a>
                    </Col>
                    {/* <Col xs={6} sm={6} md={4} lg={2}>
                      <button
                        type="submit"
                        className="saveBtn"
                        onClick={handleProfileUpdateSubmit}
                      >
                        Save
                      </button>
                    </Col> */}
                  </Row>

                </Col>
                {/* <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 3, order: 1 }} lg={{ span: 4, order: 1 }} xl={{ span: 3, order: 2 }} className="mb-2">
                  <Form.Group className="ml-3" >
                    <Form.Label style={{ marginLeft: "38px" }}> profile picture</Form.Label>
                  </Form.Group>

                  <div id="result" className="uploadPreview">
                    {imageUpload ? (
                      <img src={URL.createObjectURL(imageUpload)} className="img-fluid" />
                    ) : (

                      <img src={profileImage || noImage} className="img-fluid" />
                    )}

                  </div>
                  <div className="overlay_setting">
                    <label htmlFor="uploadImage"> <i className="fa-solid fa-camera" ></i> <span>change Photo</span></label>
                    <input
                      type="file"
                      id="uploadImage"
                      name="profileImage"
                      className="file_input"
                      onChange={handleChangeImage}
                    />
                  </div>
                  {imageErrorMsg && <p className="text-danger text-center" style={{ fontSize: "10px" }}>{imageErrorMsg}</p>}
                </Col> */}
              </Row>
              <hr></hr>

              <div className="setting_form mt-3">
                <SettingsNewPassword />
              </div>

            </Card.Body>
          </Card>



          


        </div>
      </DashbaordLayout>

    </div>
  );
};

export default Settings;
