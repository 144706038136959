import React, { useState, useEffect } from "react";
import { Col, Row, ButtonGroup, Button } from "react-bootstrap";
import { MdShowChart } from "react-icons/md";
import { AffiliateGetGrossStats, AffiliateGetReferralActivity } from "../../Utils/affiliate.util";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { CiCalendarDate } from "react-icons/ci";

const AffiliateGrossCommissionWidgets = () => {
  const pageTitle = "Affiliate Program";
  const [filter, setFilter] = useState("today");
  const [grossStatsData, setGrossStatsData] = useState(null);
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [daysFromNow, setDaysFromNow] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [open, setOpen] = useState(false);

  const daysFilterOptions = [
    {value: 'today', label: 'Today'},
    {value: 'last_7', label: 'Last 7 Days'},
    {value: 'last_30', label: 'Last 30 Days'},
    {value: 'all', label: 'All Times'},
  ];

  const handleDateChangeFilter = (dates) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);

    if(end) setOpen(false);
  };

  useEffect(() => {
    setShowLoader(true);
    let startDate = "";
    let endDate = "";
    if (startDateFilter && endDateFilter) {
      startDate = moment(startDateFilter).format("MM/DD/YYYY");
      endDate = moment(endDateFilter).format("MM/DD/YYYY");
    }

    AffiliateGetGrossStats(filter, startDate, endDate).then((response) => {
      if (response.statusCode == 200) {
        setGrossStatsData(response?.result);
        // Every 1st of next month. Once in a month
        const nextPayDate = moment().add(1, 'month').startOf('month');
        setNextPaymentDate(nextPayDate.format('MMM Do'));
        setDaysFromNow(moment(nextPayDate.format('YYYY-MM-DD')).fromNow());
      }
      setShowLoader(false);
    });
  }, [filter, endDateFilter]);


  const firstDateOfMonth = moment().startOf('month').format('MM/DD/YYYY');
  const lastDateOfMonth = moment().endOf('month').format('MM/DD/YYYY');
  const placeholderText = `${firstDateOfMonth} - ${lastDateOfMonth}`;

  return (
    <>
      <div className="my-">
        <Col xs={12} md={12} lg={12} xl={4}>
          <h2 className="main-title-mobile">{pageTitle}</h2>
        </Col>
 
        <Row className="mb-4 justify-content-between">
          
          <Col md={12} className="mb-4">
            <h2>Overview</h2>
          </Col>
          <Col xs={12} md={12} lg={5} xl={5} className="text-center mb-3">
            <ButtonGroup aria-label="Basic example">
              {daysFilterOptions.map((obj, i) => (
                <Button
                  key={i}
                  className={`${filter == obj?.value ? "active" : ""}`}
                  onClick={() => {
                    setFilter(obj.value)
                    setStartDateFilter("");
                    setEndDateFilter("");
                  }}
                >
                  {obj.label}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
          <Col xs={12} md={12} lg={6} xl={5} className="text-center range-cst">
            <Row>
              <Col lg={4} md={3} className="align-self-center text-end pe-0">
                <label>Select Range</label>
              </Col>
              <Col >
                <div className="datePic ms-2"  >
                  <DatePicker
                    selected={startDateFilter}
                    onChange={handleDateChangeFilter}
                    startDate={startDateFilter}
                    endDate={endDateFilter}
                    selectsRange
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="MM/dd/yyyy"
                    dropdownMode="select"
                    className="form-control dateSelet text-start"
                    open={open}
                    onFocus={() => setOpen(true)}
                    placeholderText={placeholderText}
                  />
                  <CiCalendarDate size={35} onClick={() => setOpen(!open)} onBlur={() => setOpen(false)} />

                </div>
              </Col>
            </Row> 
          </Col>
          <Col xs={12}>
            <Row className="mt-4">

              <Col xxl={3} xl={6} md={6} className="mb-lg-3 mb-md-3">
                <div className="box-glow fade-grey bg-white pt-4 px-4">
                  <Row className="justify-content-between"> 
                    <Col sm={12} className="">            
                      <h6>Gross Sales</h6>
                    </Col>
                    <Col sm={12} className="self-align-center mt-3">
                      {showLoader ? (
                        <div className="loader-small"></div>
                      ) : (
                        <>
                          <div className="sub-data-box">
                            <div className="data-div">
                              <h1>
                                $
                                {grossStatsData
                                  ? Number(grossStatsData.grossSale)
                                    ?.toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : 0}
                              </h1>
                            </div>
                            {/* {grossStatsData &&
                              grossStatsData?.grossSaleChange >= 0 ? (
                              <div className="aff_icon">
                                <MdShowChart />
                                <span className="label_success">
                                  {grossStatsData.grossSaleChange}%
                                </span>
                              </div>
                            ) : (
                              <div className="aff_icon_danger">
                                <MdShowChart />
                                <span className="label_danger">
                                  {grossStatsData?.grossSaleChange || 0}%
                                </span>
                              </div>
                            )} */}
                          </div> 
                        </>
                      )} 
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xxl={3} xl={6} md={6} className="mb-lg-3 mb-md-3">
                <div className="box-glow fade-grey bg-white pt-4 px-4">
                  <Row className="justify-content-between"> 
                    <Col sm={12} className="">            
                      <h6>Commission ({grossStatsData
                                ? grossStatsData.commissionPercentage
                                : 0}
                              % Gross Sales)</h6>
                    </Col>
                    <Col sm={12} className="self-align-center mt-3">
                      {showLoader ? (
                        <div className="loader-small"></div>
                      ) : (
                        <>
                          <div className="sub-data-box">
                            <div className="data-div">
                              <h1>
                                $
                                {grossStatsData
                                  ? Number(grossStatsData.commissionAmount)
                                    ?.toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : 0}
                              </h1>
                            </div>
                            {/* {grossStatsData &&
                              grossStatsData?.commissionAmountChange >= 0 ? (
                              <div className="aff_icon">
                                <MdShowChart />
                                <span className="label_success">
                                  {grossStatsData.commissionAmountChange}%
                                </span>
                              </div>
                            ) : (
                              <div className="aff_icon_danger">
                                <MdShowChart />
                                <span className="label_danger">
                                  {grossStatsData?.commissionAmountChange || 0}%
                                </span>
                              </div>
                            )} */}
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xxl={3} xl={6} md={6} className="mb-lg-3 mb-md-3">
                <div className="box-glow fade-grey bg-white pt-4 px-4">
                  <Row className="justify-content-between"> 
                    <Col sm={12} className="">            
                      <h6>Referrals</h6>
                    </Col>
                    <Col sm={12} className="self-align-center mt-3">
                      {showLoader ? (
                        <div className="loader-small"></div>
                      ) : (
                        <>
                          <div className="sub-data-box">
                            <div className="data-div">
                              <h1>
                                {grossStatsData
                                  ? grossStatsData.referralCount.toLocaleString()
                                  : 0}
                              </h1>
                            </div>
                            {/* {grossStatsData &&
                              grossStatsData.referralCountChange >= 0 ? (
                              <div className="aff_icon">
                                <MdShowChart />
                                <span className="label_success">
                                  {grossStatsData.referralCountChange}%
                                </span>
                              </div>
                            ) : (
                              <div className="aff_icon_danger">
                                <MdShowChart />
                                <span className="label_danger">
                                  {grossStatsData?.referralCountChange || 0}%
                                </span>
                              </div>
                            )} */}
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xxl={3} xl={6} md={6} className="mb-lg-3 mb-md-3">
                <div className="box-glow fade-grey bg-white pt-4 px-4">
                  <Row className="justify-content-between"> 
                    <Col sm={12} className="">            
                      <h6>Pay Date</h6>
                    </Col>
                    <Col sm={12} className="self-align-center mt-4">
                      {showLoader ? (
                        <div className="loader-small"></div>
                      ) : (
                        <>
                          <div className="sub-data-box">
                            <div className="data-div">
                              <Row>
                                <Col className="align-self-center pe-0 sm-m-0">
                                  <h3>{nextPaymentDate ? nextPaymentDate : ""}</h3>
                                </Col>
                                <Col className="align-self-center ps-0">
                                  <p className="m-0">{daysFromNow ? daysFromNow : ""}</p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
    
            </Row> 
          </Col>
        </Row>
 
        
      </div>

      {/* <Row>
        <Col sm={12} ms={12} md={12} lg={6} xl={5}>
          <Row>
            <Col sm={12} md={6} lg={12} xl={6} className="mb-3">
              <div className="data-box affiliate">
                <h2>Gross Sales</h2>
                {showLoader ? (
                  <div className="loader-small"></div>
                ) : (
                  <>
                    <div className="sub-data-box">
                      <div className="data-div">
                        <h4>
                          $
                          {grossStatsData
                            ? Number(grossStatsData.grossSale)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : 0}
                        </h4>
                      </div> 
                    </div>

                    <p>Gross sales</p>
                  </>
                )}
              </div>
            </Col>
            <Col sm={12} md={6} lg={12} xl={6} className="mb-3">
              <div className="data-box affiliate">
                <h2>Commission %</h2>
                {showLoader ? (
                  <div className="loader-small"></div>
                ) : (
                  <>
                    <div className="sub-data-box">
                      <div className="data-div">
                        <h4>
                          {grossStatsData
                            ? grossStatsData.commissionPercentage
                            : 0}
                          %
                        </h4>
                      </div>
                    </div>
                    <p></p>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={12} ms={12} md={12} lg={6} xl={7} >
          <Row>
            <Col sm={12} md={6} lg={12} xl={4} className="mb-3">
              <div className="data-box affiliate">
                <h2>Commission</h2>
                {showLoader ? (
                  <div className="loader-small"></div>
                ) : (
                  <>
                    <div className="sub-data-box">
                      <div className="data-div">
                        <h4>
                          $
                          {grossStatsData
                            ? Number(grossStatsData.commissionAmount)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : 0}
                        </h4>
                      </div> 
                    </div>
                    <p>Total commission</p>
                  </>
                )}
              </div>
            </Col>
            <Col sm={12} md={6} lg={12} xl={4} className="mb-3">
              <div className="data-box affiliate">
                <h2>Referrals</h2>
                {showLoader ? (
                  <div className="loader-small"></div>
                ) : (
                  <>
                    <div className="sub-data-box">
                      <div className="data-div">
                        <h4>
                          {grossStatsData
                            ? grossStatsData.referralCount.toLocaleString()
                            : 0}
                        </h4>
                      </div> 
                    </div>
                    <p>Total referrals</p>
                  </>
                )}
              </div>
            </Col>
            <Col sm={12} md={6} lg={12} xl={4} className="mb-3">
              <div className="data-box affiliate">
                <h2>Pay Date </h2>
                {showLoader ? (
                  <div className="loader-small"></div>
                ) : (
                  <>
                    <div className="sub-data-box">
                      <div className="data-div">
                        <h4>{nextPaymentDate ? nextPaymentDate : ""}</h4>
                      </div>
                    </div>
                    <p>{daysFromNow ? daysFromNow : ""}</p>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </>
  );
};

export default AffiliateGrossCommissionWidgets;