export const React_Select_Style = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: "15px",
    fontSize: "14px",
    minHeight: "45px",
    // backgroundColor: "var(--lightsky)",
    borderRadius: "5px",
  }),
  option: (base, state) => ({
    ...base,
    fontSize: "12px",
    fontWeight: "normal",
    cursor: 'pointer',
    ':hover': {
      backgroundColor: "var(--blue)",
      color: "#fff"
     },
     backgroundColor: state.isSelected ? 'var(--blue)' : 'white', 
    //  color: state.isSelected ? 'white' : 'var(--blue)'
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'var(--darkblue)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    backgroundColor: 'var(--yellow)',
    ':hover': {
      backgroundColor: "var(--gold)",
    },
  }),
}; 


export const React_Select_Style_State = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: "5px",
    fontSize: 12,
    minHeight: "30px",
    // backgroundColor: "var(--lightsky)",
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 12,
    fontWeight: "normal",
    cursor: 'pointer',
      ':hover': {
      backgroundColor: "var(--blue)",
      color: "#fff"
     },
     backgroundColor: state.isSelected ? 'var(--blue)' : 'white', 
     color: state.isSelected ? 'white' : 'var(--blue)',
  }),
  singleValue: (baseStyle) => ({
    ...baseStyle,
    fontSize: 12
  }),
  placeholder: (baseStyle) => ({
    ...baseStyle,
    fontSize: 12,
    color: '#000'
  })
}; 