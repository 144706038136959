import { BsFillLightningChargeFill } from "react-icons/bs";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { getFormattedCurrencyInInteger } from '../../Utils/formating.util';
import {
  getCurrentPlanId,
  getCurrentPlanPrice,
  getIsCardExpired
} from '../../store/selectors/features/payment-plan';


const BillingCard = ({ type, data, selectPlan }) => {
  const plan = data.title.includes("Pro");
  const features = data?.features?.split('\n') || [];

  const currentPlanId = useSelector(getCurrentPlanId);
  const currentPlanPrice = useSelector(getCurrentPlanPrice);
  const isCardExpired = useSelector(getIsCardExpired);

  const [selectionText, setSelectionText] = useState('Select Plan');
  const [selectionTextClass, setSelectionTextClass] = useState('select_plan');
  const [selectionTextDisabled, setSelectionTextDisabled] = useState(false);
  const [isDowngrade, setIsDowngrade] = useState(false);


  useEffect(() => {
    if (data._id === currentPlanId) {
      // setSelectionText('Current Plan');
      setSelectionText('Upgrade');
      setSelectionTextClass('disabled_select_plan');
      setSelectionTextDisabled(true);
      return;
    }

    setSelectionTextClass('select_plan');
    setSelectionTextDisabled(false);

    if (data.price > currentPlanPrice) {
      // setSelectionText('Upgrade Plan');
      setSelectionText('Upgrade');
      setIsDowngrade(false);
      return;
    }

    if (data.price < currentPlanPrice) {
      setSelectionText('Downgrade Plan');
      setIsDowngrade(true);
      return;
    }
  }, [currentPlanId, currentPlanPrice, data]);

  const selectPlanHandler = () => {
    selectPlan(data, isDowngrade);
  }

  return (
    // most
    <div className={`pricing_item  ${!plan ? 'basic ' : 'pro'} billing ${selectionTextDisabled ? 'current_plan_item' : ''}`}>

      {/* <div className="most_overlay">
        <BsFillLightningChargeFill />
        <h4>Most Popular</h4>
        <BsFillLightningChargeFill />
      </div> */}
      <div className="pricing_item_header">
        <h4>{data.title} </h4>
        {!plan ? <></> : <><span className="current-badge most-popular">Most Popular</span></>}
      </div>
      <div className="current-plan-container">
        {selectionTextDisabled ? <><span className="current-badge">Current Plan</span></> : <></>}
      </div>
      <div className={`pricing_item_price`}>
        <div className="pricing mb-0">
          <span className="dollar">$</span>
          <span className="amount">{getFormattedCurrencyInInteger(data.price)}</span>
        </div>
        <div className="pricing mt-0">        
          <span className="type mt-0">/{type === 'year' ? 'year' : 'monthly'}</span>
        </div>
        <button
          // className={isDowngrade ? 'downgrad_plan_btn' : selectionTextClass}
          className={isDowngrade ? selectionTextClass : selectionTextClass}
          onClick={selectPlanHandler}
          disabled={isCardExpired || selectionTextDisabled}
        >
          {selectionText}
        </button>
        <div className="details">
          <h4>Plan Features:</h4>
          <ul>
            {features.map((item, index) => {
              return (
                <li key={index}>{item}</li>
              )
            })}
          </ul>
        </div>
      </div>

    </div>
  )
}

export default BillingCard