export const FirstNameRegex = /(firstname|first name|first_name|first)/ig
export const LastNameRegex = /(lastname|last name|last_name|last|surname|sur name|sur_name)/ig
export const PropertyAddressRegex = /(propertyaddress|property address|property_address|street address|street)/ig
export const MailingAddressRegex = /(mailingaddress|mailing address|mailing_address|mailing street|mailing_street|mailingstreet)/ig
export const PropertyCityRegex = /(propertycity|property city|property_city)/ig
export const MailingCityRegex = /(mailingcity|mailing city|mailing_city)/ig
export const PropertyStateRegex = /(propertystate|property state|property_state)/ig
export const MailingStateRegex = /(mailingstate|mailing state|mailing_state)/ig
export const PropertyZipRegex = /(property zip|property zipcode|property_zip|property_zipcode|postal code|postal_code)/ig
export const MailingZipRegex = /(mailing zip|mailing zipcode|mailing_zip|mailing_zipcode|mail zip|mail zip_code|mail zipcode|postal code|postal_code)/ig
export const ApnRegex = /(apn|assessor parcel number|parcel number|parcel_number)/ig