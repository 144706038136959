import React, { useState } from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";

import {
  Col,
  Row,
  ButtonGroup,
  Button,
  Card,
  Form,
  InputGroup,
  Accordion,
  Dropdown,
} from "react-bootstrap";
const AffiliateFaqs = () => {
  const faqs = [
    {
      head: "How do I become a Skipify.ai affiliate?",
      text: `To join our Affiliate Program, simply apply through our website. Once approved, you'll gain access to all the tools needed to start earning commissions.`,
    },
    {
      head: "What commission structure does Skipify.ai offer its affiliates?",
      text: `Our affiliates enjoy a competitive commission structure, with recurring payments for every client who signs up using their unique referral link.`,
    },
    {
      head: "Are there any qualifications needed to become an affiliate?",
      text: `Affiliates should have a relevant audience interested in real estate, a commitment to promoting quality services, and the ability to create engaging content.`,
    },
    {
      head: "How can I track my earnings and referrals?",
      text: `Our comprehensive affiliate dashboard provides real-time tracking of your referrals, earnings, and performance metrics.`,
    },
    {
      head: "What resources will I have access to as an affiliate?",
      text: `Skipify.ai provides affiliates with promotional materials, content examples, and strategic marketing guidance to help maximize referral conversions.`,
    },
    {
      head: "Is there a limit to how much I can earn as a Skipify.ai affiliate?",
      text: `There is no cap on earnings; the more clients you refer, the more you can earn.`,
    },
    {
      head: "How often will I get paid for my referrals?",
      text: "Payments are made on a monthly basis, provided you meet the minimum threshold set in our affiliate terms.",
    },
    {
      head: "Can I promote Skipify.ai internationally?",
      text: `Yes, as long as the leads you generate are from markets that Skipify.ai serves, you can promote internationally.`,
    },
    {
      head: "What support does Skipify.ai offer to help me succeed as an affiliate?",
      text: `Skipify.ai offers dedicated support to our affiliates, including customer service assistance and marketing strategy consultations.`,
    },
    {
      head: "How long does the affiliate tracking cookie last?",
      text: "Our tracking cookies have a standard duration, which ensures that you receive credit for referrals made within a set period after the initial click.",
    },
  ];

  return (
    <Row>
      <Col md={12} className="mb-4">
        <Card className="affiliate_card">
          <Card.Body>
            <h2>Affiliate FAQ’s</h2>

            <Accordion defaultActiveKey="0">
              {faqs.map((faq, f) => (
                <Accordion.Item eventKey={f} key={f}>
                  <Accordion.Header>
                    {" "}
                    <div className="icon_faq">
                      <BsFillQuestionCircleFill />
                    </div>{" "}
                    {faq?.head}
                  </Accordion.Header>
                  <Accordion.Body>{faq?.text}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AffiliateFaqs;
