export const CancellationReasonConstant = [
    {
      value: "Too expensive",
      label: "Too expensive",
    },
    {
      value: "Not getting desired results",
      label: "Not getting desired results",
    },
    {
      value: "Unsatisfactory customer support",
      label: "Unsatisfactory customer support",
    },
    {
      value: "Not using the service enough",
      label: "Not using the service enough",
    },
    {
      value: "Technical problems or bugs",
      label: "Technical problems or bugs",
    },
    {
      value: "Switching to a competitor",
      label: "Switching to a competitor",
    },
    {
      value: "Pausing business activities temporarily",
      label: "Pausing business activities temporarily",
    },
    {
      value: "other",
      label: "Other",
    },
  ];