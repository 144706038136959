import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { TopNav } from "../../Essentials/Front/TopNav";
import { FooterFront } from "../../Essentials/Front/FooterFront";
import { BOOK_DEMO_CREATE_ENDPOINT } from '../../Constants/api-routes.constant';


const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const BookADemo = () => {

  document.title = "Book A Demo - Skipify.ai";

  const [showLoader, setShowLoader] = useState(false);
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    businessName: "",
    phoneNumber: "",
    phoneNumberWithMaking: ""
  });

  // errors
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [formSuccessMsg, setFormSuccessMsg] = useState('');
  const [queryErr, setqueryErr] = useState('');

  // handle change of input
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    setFormSuccessMsg('');
    setShowLoader(true);

    let errorFlag = false;

    if (!input?.firstName) {
      setFirstName("First Name is required.")
      setShowLoader(false)
      errorFlag = true;
    } else {
      setFirstName("");
    }

    if (!input?.lastName) {
      setLastName("Last Name is required.")
      setShowLoader(false)
      errorFlag = true;
    } else {
      setLastName("");
    }

    if (!input?.phoneNumber) {
      setPhoneNumber("Phone Number is required.")
      setShowLoader(false)
      errorFlag = true;
    } else {
      setPhoneNumber("");
    }

    if (input.phoneNumber && input.phoneNumber.toString().length != 10) {
      setPhoneNumber("Phone Number should be 10 digits long.");
      errorFlag = true;
    }
    if (!input?.emailAddress) {
      setEmail("Email should be a valid email address.")
      setShowLoader(false)
      errorFlag = true;
    } else if (!EmailRegex.test(input?.emailAddress)) {
      setEmail("Email should be a valid email address.")
      setShowLoader(false)
      errorFlag = true;
    } else {
      setEmail("");
    }

    if (!errorFlag) {

      try {
        const headers = {
          'Content-Type': 'application/json'
        };

        const { phoneNumberWithMaking, phoneNumber, ...requestPayload } = input;

        requestPayload.phoneNumber = Number(phoneNumber);
        const response = await axios.post(BOOK_DEMO_CREATE_ENDPOINT, requestPayload, { headers });

        if (response.data && response.data?.statusCode === 200) {
          setShowLoader(false);
          setFormSuccessMsg('Information Submitted Successfully');
          setInput({
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
            phoneNumberWithMaking: "",
            businessName: ""
          });
          setTimeout(() => {
            window.location = '/';
            return;
          }, 2000);
        }
      } catch (error) {
        setShowLoader(false);
        setFormSuccessMsg('');
        setqueryErr("Please provide valid information");
      }
    }
  };

  // Function to remove the Masking from phone Number
  const NormalizePhoneNumber = (phone) => {

    const numericOnly = phone.replace(/\D/g, '');
    let maskedNumber = '';
    if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
    }
    if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
    }
    if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
    }
    setInput({
      ...input,
      phoneNumber: numericOnly.slice(0, 10),
      phoneNumberWithMaking: maskedNumber,
    })

  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TopNav />
      <div className="register_section">
        <div className="register_section_overlay">
          <Row>
            <Col md={6} className="mx-auto">
              <div className="main-form">
                <h2>Book A Demo</h2>
                <p>Fill out the from and one of our experts will get in touch with you.</p>
                {formSuccessMsg ? (
                  <div className="alert alert-success mt-2">
                    <h6 style={{ color: "#0f5132 !important", fontSize: "0.8rem" }}>{formSuccessMsg}</h6>
                  </div>
                ) : (
                  ""
                )}
                <Form method="post" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label>First NAME *</Form.Label>
                        <Form.Control
                          onChange={(e) => handleChange(e)}
                          type="text"
                          name="firstName"
                          value={input?.firstName}
                        />
                        {firstName && <span className='text-danger'>{firstName}</span>}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label>last NAME *</Form.Label>
                        <Form.Control
                          onChange={(e) => handleChange(e)}
                          type="text"
                          name="lastName"
                          value={input?.lastName}
                        />
                        {lastName && <span className='text-danger'>{lastName}</span>}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control
                          onChange={(e) => handleChange(e)}
                          type="email"
                          name="emailAddress"
                          value={input?.emailAddress}
                        />
                        {email && <span className='text-danger'>{email}</span>}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Label>Phone Number *</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); setPhoneNumber("") }}
                        value={input?.phoneNumberWithMaking}
                      />
                      {phoneNumber && <span className='text-danger'>{phoneNumber}</span>}
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" >
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          onChange={(e) => handleChange(e)}
                          type="text"
                          name="businessName"
                          value={input?.businessName}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mx-auto">
                      <div className="tab-container">
                        {showLoader ?
                          <button type="button" className="submit"> <Spinner animation="border" size="sm" /></button>
                          : <button type="submit" className="submit" >Book Now</button>}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <FooterFront />
    </>
  )
};

export default BookADemo;

