import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../../Constants/redux-slices';
import { API_HOST } from '../../../Constants/api-routes.constant';
/**
 * An example of creating feature slices, reducers and INITIAL_STATE.
 */

const INITIAL_STATE = {
  language: 'en',
  baseUrl: API_HOST,
  location: {},
  addSkips: 0 // for go to add skip on billing page
};

export const appFeatureSlice = createSlice({
  name: SLICE_NAME.APP,
  initialState: INITIAL_STATE,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    createActiveAddSkip: (state, action) => {
      state.addSkips = action.payload;
    }
  }
});

export const { changeLanguage, updateLocation, createActiveAddSkip } = appFeatureSlice.actions;
export const appFeatureReducer = appFeatureSlice.reducer;
