import React, { useEffect, useState, useCallback } from "react";
import "react-tabs/style/react-tabs.css";
import "react-dropdown/style.css";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import "react-datepicker/dist/react-datepicker.css";
import PropertySearchFilters from "./PropertySearchSub/Property-Search-Filters";
import PropertyListing from "./PropertySearchSub/Property-Listing";
import { SearchPropertyApi } from "../Utils/search-property.util";
import Map, {
  Marker,
  Popup,
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  ScaleControl,
  useControl,
} from "react-map-gl";
import { MapBox_API_TOKEN } from "../Constants/api-credentials.constant";
import { US_LOCATION_COORDINATES } from "../Constants/us-coordinates.constant";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  GetFormattedCurrency,
  GetFormattedCurrencyWithSuffixK,
  getFormattedCurrencyInInteger
} from "../Utils/formating.util";
import DashbaordLayout from "./DashbaordLayout";
import { Col, Row } from "react-bootstrap";
import GetCurrentLocation from "./location";
import { useDispatch, useSelector } from "react-redux";
import { getSearchPayload } from "../store/selectors/features/property-list";
import { addSearchPayload } from "../store/slices/features/property-list";
import MarkerContent from "./MarkerContent";
import { getCurrentPlanData } from "../store/selectors/features/payment-plan";
import { GetCustomerCurrentPlanDetails } from "../Utils/clients.util";

// Control Draw On map
export const DrawControl = (props) => {
  useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
    },
    ({ map }) => {
      map.off("draw.create", props.onCreate);
      map.off("draw.update", props.onUpdate);
      map.off("draw.delete", props.onDelete);
    },
    {
      position: props.position,
    }
  );

  return null;
};

const PropertySearch = () => {
  const dispatch = useDispatch();
  document.title = "Property Search - Skipify.ai";

  // Customer Current Plan
  const currentPlanDataProps = useSelector(getCurrentPlanData);
  const [currentPlanData, setCurrentPlanData] = useState(currentPlanDataProps);
  const currentLocation = GetCurrentLocation();

  const [propertyListing, setPropertyListing] = useState([]);
  const [propertyCount, setPropertyCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [requestId, setRequestId] = useState("");
  const [searchCriteriaErrorMsg, setSearchCriteriaErrorMsg] = useState("");
  const [initialLong, setInitialLong] = useState("");
  const [initialLat, setInitialLat] = useState("");
  const [showPopupPropertyId, setShowPopupPropertyId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState({ label: "", value: "" });
  const [zoom, setZoom] = useState(15);
  const [viewState, setViewState] = useState({});
  const [resetPreviousItems, setResetPreviousItems] = useState(false);
  const [searchAllFilters, setSearchAllFilters] = useState({});

  const searchFilter = useSelector(getSearchPayload);
  const setSkipValue = (skip) => {
    setSkip(skip);
    handleSearchApiCall(searchFilter, skip);
  };

  // Call Property Search API
  const handleSearchApiCall = async (filter, skipVal = skip, reset = false) => {
    try {
      setPropertyListing([]);
      setResetPreviousItems(reset);
      setSearchAllFilters(filter);

      localStorage.setItem("notFoundRecord", "");
      setSearchCriteriaErrorMsg("");
      setShowLoader(true);
      const propertyResult = await SearchPropertyApi(filter, skipVal);
      dispatch(addSearchPayload(filter));

      if (propertyResult?.result?.properties?.length) {
        setPropertyListing(propertyResult.result.properties);
        setPropertyCount(propertyResult.result.meta?.totalFound);
        setRequestId(propertyResult.result.meta?.requestId);
        for (const singleProperty of propertyResult?.result?.properties) {
          if (
            singleProperty?.SitusLongitude != "0" &&
            singleProperty?.SitusLatitude != "0"
          ) {
            setInitialLong(singleProperty.SitusLongitude);
            setInitialLat(singleProperty.SitusLatitude);
            break;
          }
        }
        localStorage.setItem("queryFilter", ""); // remove queryFilter when get result
        localStorage.setItem("notFoundRecord", "false");
      } else {
        setPropertyListing([]);
        setPropertyCount(0);
        setRequestId("");
        localStorage.setItem("queryFilter", ""); // remove queryFilter when get result
        localStorage.setItem("notFoundRecord", "true"); // when records is 0 then is show the the message (not found)

        if (propertyResult?.statusCode != 200 && propertyResult?.message) {
          setSearchCriteriaErrorMsg(propertyResult.message);
        }
      }
      setShowLoader(false);
      setViewState({ zoom: 12 });
      setShowLoader(false);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleMapOfProperty = (propertyItem) => {
    setViewState({
      longitude: propertyItem.SitusLongitude,
      latitude: propertyItem.SitusLatitude,
      zoom: 18,
    });
  };

  const pageTitle = "Property Search";

  // Map draw Polygon
  const [features, setFeatures] = useState({});
  const [mapCoordinates, setMapCoordinates] = useState([]);

  const onDrawUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
        if (f.geometry?.coordinates?.length) {
          setMapCoordinates(f.geometry.coordinates);
        }
      }
      return newFeatures;
    });
  }, []);

  const onDrawDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      setMapCoordinates([]);
      return newFeatures;
    });
  }, []);

  // Call the Api for change in coordinates
  useEffect(() => {
    if (mapCoordinates?.length) {
      let filter = {};
      if (searchFilter.coordinates) {
        filter = { ...searchFilter };
        filter.coordinates = mapCoordinates;
      } else {
        filter = Object.assign({ coordinates: mapCoordinates }, searchFilter);
      }
      handleSearchApiCall(filter, 0, true);
    }
    GetCustomerCurrentPlanDetails().then((response) => {
      if(response.statusCode == 200) {
        setCurrentPlanData(response.result)
      }
    })
  }, [mapCoordinates]);

  return (
    <div className="d-flex" id="wrapper">
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}

      <DashbaordLayout title={pageTitle}>
        <div id="page-content-wrapper">
          <div className="sub_div_property">
            <h2 className="main-title-mobile">{pageTitle}</h2>
            {/* <!-- Page content--> */}
            <PropertySearchFilters apiCall={handleSearchApiCall} currentPlanData={currentPlanData}/>
            {searchCriteriaErrorMsg ? (
              <div className="error-message mb-3">{searchCriteriaErrorMsg}</div>
            ) : (
              ""
            )}
            <Row>
              <Col md={12}>
                <div className="mapArea">
                  {propertyListing.length ? (
                    <Map
                      {...viewState}
                      onMove={(evt) => setViewState(evt.viewState)}
                      initialViewState={{
                        longitude: initialLong,
                        latitude: initialLat,
                        zoom: zoom,
                      }}
                      cooperative={false}
                      style={{ width: "100%", height: "600px" }}
                      mapStyle="mapbox://styles/mapbox/streets-v12"
                      mapboxAccessToken={MapBox_API_TOKEN}
                    >
                      <GeolocateControl position="top-right" />
                      <FullscreenControl position="top-right" />
                      <NavigationControl position="top-right" />
                      <ScaleControl />
                      {propertyListing.map((propertyItem) => (
                        <div key={propertyItem._id}>
                          <Marker
                            key={propertyItem._id}
                            longitude={propertyItem.SitusLongitude}
                            latitude={propertyItem.SitusLatitude}
                            anchor="bottom"
                            color="#0B2548"
                            style={{ cursor: "pointer" }}
                            onClick={(evt) => {
                              evt.originalEvent.stopPropagation();
                              setShowPopupPropertyId(propertyItem._id);
                              setShowPopup(true);
                            }}
                          >
                            <div className="marker_div">
                              {Number(propertyItem?.MarketTotalValue) ? '$'+ getFormattedCurrencyInInteger(propertyItem?.MarketTotalValue) : '$'+getFormattedCurrencyInInteger(propertyItem?.AssdTotalValue)}
                            </div>
                          </Marker>

                          {showPopup &&
                            showPopupPropertyId == propertyItem._id && (
                              <Popup
                                key={propertyItem.PropertyID}
                                longitude={propertyItem.SitusLongitude}
                                latitude={propertyItem.SitusLatitude}
                                anchor="bottom"
                                display={false}
                                className="mapbox-popup"
                              >
                                <MarkerContent propertyItem={propertyItem} />

                              </Popup>
                            )}
                        </div>
                      ))}

                      {/* For Draw Polygon */}
                      <DrawControl
                        position="top-right"
                        displayControlsDefault={false}
                        controls={{
                          polygon: true,
                          trash: true,
                        }}
                        onCreate={onDrawUpdate}
                        onUpdate={onDrawUpdate}
                        onDelete={onDrawDelete}
                      />
                    </Map>
                  ) : currentLocation.loaded ? (
                    <Map
                      key={"Asfasdss"}
                      initialViewState={{
                        latitude: currentLocation.lat,
                        longitude: currentLocation.lng,
                        zoom: 9,
                      }}
                      style={{ width: "100%", height: "600px" }}
                      mapStyle="mapbox://styles/mapbox/streets-v12"
                      mapboxAccessToken={MapBox_API_TOKEN}
                    >
                      {/* For Draw Polygon */}
                      <DrawControl
                        position="top-right"
                        displayControlsDefault={false}
                        controls={{
                          polygon: true,
                          trash: true,
                        }}
                        onCreate={onDrawUpdate}
                        onUpdate={onDrawUpdate}
                        onDelete={onDrawDelete}
                      />
                    </Map>
                  ) : (
                    <Map
                      key={"Asfasdf"}
                      initialViewState={{
                        latitude: US_LOCATION_COORDINATES.LATITUDE,
                        longitude: US_LOCATION_COORDINATES.LONGITUDE,
                        zoom: 9,
                      }}
                      style={{ width: "100%", height: 500 }}
                      mapStyle="mapbox://styles/mapbox/streets-v12"
                      mapboxAccessToken={MapBox_API_TOKEN}
                    >
                      {/* For Draw Polygon */}
                      <DrawControl
                        position="top-right"
                        displayControlsDefault={false}
                        controls={{
                          polygon: true,
                          trash: true,
                        }}
                        onCreate={onDrawUpdate}
                        onUpdate={onDrawUpdate}
                        onDelete={onDrawDelete}
                      />
                    </Map>
                  )}
                  {/* <img src={mapImg}></img> */}
                </div>
              </Col>
            </Row>

            <PropertyListing
              propertyData={propertyListing}
              propertyCount={propertyCount}
              requestId={requestId}
              setSkipValue={setSkipValue}
              handleMapOfProperty={handleMapOfProperty}
              resetPreviousItems={resetPreviousItems}
              currentPlanData={currentPlanData}
            />
          </div>
        </div>
      </DashbaordLayout>
    </div>
  );
};

export default PropertySearch;
