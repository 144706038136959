import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { BiDownload, BiCloudDownload, BiAnalyse, BiXCircle, BiX } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { DeleteOrderById } from "../Utils/files.util";
import uploadImageIcon from "../assets/new_theme/img/upload.png";
import moreIcon from "../assets/new_theme/img/more.png";
import downloadIcon from "../assets/new_theme/img/download.png";
import skiptraceIcon from "../assets/new_theme/img/skiptrace.png";
import deleteIcon from "../assets/new_theme/img/delete.png";


import {
  OverlayTrigger,
  Tooltip,
  Button,
  Row,
  Col,
  Image,
  ProgressBar,
  Dropdown
} from "react-bootstrap";
import {
  DOWNLOAD_PROPERTY_FILE_API_ENDPOINT,
  DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT,
  GET_MY_FILES_LIST_API_ENDPOINT,
  DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT_JSON,
  DOWNLOAD_PROPERTY_FILE_API_ENDPOINT_JSON,
  SKIPTRACE_PROPERTY_ORDER_ENDPOINT,
} from "../Constants/api-routes.constant";
import { GetAuthToken } from "../Utils/auth-verification.util";
import { GetProfile } from "../Utils/common";
import DashbaordLayout from "./DashbaordLayout";
import {
  GetCustomerListStorageLimit,
  GetCustomerListStorageCount,
} from "../Utils/clients.util";
import nextImg from "../assets/new_theme/img/next.png";
import prevImg from "../assets/new_theme/img/prev.png";

const MyLists = (props) => {
  const dispatch = useDispatch();
  const SkiptraceMaxRecordsLimit = 30000;
  const itemsPerPage = 20;

  const [itemOffset, setItemOffset] = useState(0);
  const [filesData, setFilesData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filesData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const [showSkiptraceModal, setShowSkiptraceModal] = useState(false);
  const [showDeleteOrderModel, setShowDeleteOrderModel] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState('');

  // SkipTrace Property Download Order
  const [orderId, setOrderId] = useState("");
  const [propertyRequestId, setPropertyRequestId] = useState("");
  const [skiptraceCount, setSkiptraceCount] = useState("");
  const [disabledSkiptraceBtn, setDisabledSkiptraceBtn] = useState(false);

  const [loading, setLoding] = useState(false);
  const [dataMsg, setDataMsg] = useState("");
  // List Storage const
  const [listStorageLimit, setListStorageLimit] = useState(0);
  const [customerListStorageCount, setCustomerListStorageCount] = useState();

  const getCustomerListStorageLimit = async () => {
    const lsLimit = await GetCustomerListStorageLimit();
    setListStorageLimit(lsLimit);
  };

  const getCustomerListStorageCount = async () => {
    const customerLsCount = await GetCustomerListStorageCount();
    setCustomerListStorageCount(customerLsCount);
  };

  useEffect(() => {
    GetFilesData();
    GetProfile().then((respnose) => {
      setProfileData(respnose.result);
    });
    document.title = "My Data - Skipify.ai";
    getCustomerListStorageLimit();
    getCustomerListStorageCount();
  }, []);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalCount;
    setItemOffset(newOffset);
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    GetFilesData(newOffset);
  };

  // Get Files List
  const GetFilesData = (offset = 0) => {
    setShowLoader(true);
    const token = GetAuthToken();
    if (!token) {
      return null;
    }

    fetch(
      `${GET_MY_FILES_LIST_API_ENDPOINT}?skip=${offset}&take=${itemsPerPage}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((rawResp) => rawResp.json())
      .then((response) => {
        if (response.statusCode == "200") {
          setFilesData(response?.result?.orders);
          setTotalCount(response?.result?.totalCount);
          if (response?.result?.orders?.length === 0) {
            setShowMessage(true);
          }
        } else {
          setErrorMsg(response?.message);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        setErrorMsg(err?.message);
      });
  };

  const handleDownload = async (downloadType, downloadId) => {
    setDataMsg("");
    setSuccessMsg("");

    // If list storage limit has consumed then don't allow the customer to download previous skipped data
    if (customerListStorageCount > listStorageLimit) {
      setErrorMsg(<span>
        Your remaining skip-trace list storage limit is <strong>0</strong>.
        To proceed with this download, please upgrade your plan or delete some previously skip-traced data.
      </span>);
      return;
    }

    setShowLoader(true);
    let jsonEndpoint;
    let downloadEndpoint = DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT;
    if (downloadType === "skiptrace") {
      jsonEndpoint = DOWNLOAD_SKIPTRACE_FILE_API_ENDPOINT_JSON;
    } else {
      jsonEndpoint = DOWNLOAD_PROPERTY_FILE_API_ENDPOINT_JSON;
      downloadEndpoint = DOWNLOAD_PROPERTY_FILE_API_ENDPOINT;
    }

    // window.location = downloadEndpoint + "/" + downloadId;
    const downloadUrl = downloadEndpoint + "/" + downloadId;
    await fetch(downloadUrl, {
      method: "get",
    })
      .then((res) => {
        if (!res.ok) {
          setErrorMsg("No data found to download.");
          return;
        }
        setSuccessMsg("Preparing your download. Please wait..");
        window.location = downloadUrl;
      })
      .catch((err) => setErrorMsg(err?.message));
    setTimeout(() => {
      setShowLoader(false);
      setErrorMsg("");
      setSuccessMsg("");
    }, 4000);

  };

  const handleSkiptraceModalClose = () => setShowSkiptraceModal(false);
  const handleSkiptraceModalShow = () => setShowSkiptraceModal(true);
  const handleDeleteOrderModalClose = () => setShowDeleteOrderModel(false);
  const handleDeleteOrderModalShow = () => setShowDeleteOrderModel(true);


  /**
   * SkipTrace the property download order
   * @param {*} orderId
   * @param {*} propertyRequestId
   * @returns
   */
  const handleSkiptracePropertyOrder = async () => {
    setDisabledSkiptraceBtn(true);
    setShowSkiptraceModal(false);
    setShowLoader(true);

    // List Storage check
    if (customerListStorageCount >= listStorageLimit) {
      setErrorMsg(<span>
        Your remaining skip-trace list storage limit is <strong>0</strong>.
        To proceed with this download, please upgrade your plan or delete some previously skip-traced data.
      </span>);
      setShowLoader(false);
      return;
    }

    // State variables
    if (!orderId || !propertyRequestId) {
      setErrorMsg("Order not found to Skip Trace.");
      return;
    }

    const token = GetAuthToken();
    if (!token) {
      setErrorMsg("Please login again to refresh Auth token.");
      return null;
    }

    await fetch(SKIPTRACE_PROPERTY_ORDER_ENDPOINT, {
      method: "post",
      body: JSON.stringify({
        orderId,
        propertyRequestId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.statusCode == 200) {
          setSuccessMsg(
            "Skip Trace process has been started. Reload the page to check the progress."
          );
          setOrderId("");
          setPropertyRequestId("");
        } else {
          if (response?.message) setErrorMsg(response.message);
          else setErrorMsg("Failed to place skip tracing order.");
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setErrorMsg(err?.message);
        setShowLoader(false);
        setDisabledSkiptraceBtn(false);
      });
  };

  // Delete ordre by ID
  const handleDeleteOrder = () => {
    setShowDeleteOrderModel(false);
    setErrorMsg('');
    setSuccessMsg('');
    setShowLoader(true);

    if (!deleteOrderId) {
      setErrorMsg('List ID is missing');
      setShowLoader(false);
      return;
    }

    DeleteOrderById(deleteOrderId).then((response) => {
      if (response?.statusCode == 200) {
        setSuccessMsg('The list has been deleted successfully.')
      } else {
        setErrorMsg('Sorry, the list cannot be deleted at this time. Please try later.');
      }
      GetFilesData();
      setShowLoader(false);
    }).catch((err) => {
      setShowLoader(false);
    })
  }

  const showDropDown = () => {
    try{
      let menuDp = document.querySelector(".menu-dropdown");
      let clickAction = document.querySelector(".clickAction");
      menuDp.style.display = "block";
    
      document.addEventListener("mouseup", function (e) {
        if (!menuDp.contains(e.target)) {
          menuDp.style.display = "none";
        }
      });

      clickAction.addEventListener("closeDropDown", function (e) { 
          menuDp.style.display = "none"; 
      }); 
    }catch(e){console.log(e)}
  };

  const pageTitle = "My Data";

  return (
    <div>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}

      {/* Modal: Skiptrace Property Download Order */}
      <Modal

        className=" "
        show={showSkiptraceModal}
        onHide={handleSkiptraceModalClose}
      >
        <Modal.Header style={{ background: "#0b2548" }}>
          <h5 style={{ padding: '10px 5px 0px', color: "white" }}>Skip Trace Confirmation</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="newsletter_message_modal">
            <p>
              Skip Tracing this order will require {skiptraceCount} Skip Tracing
              hits. This amount will be deducted from your available Skip Traces
              balance.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default2" onClick={handleSkiptraceModalClose}>
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSkiptracePropertyOrder}
          >
            Yes, please
          </button>
        </Modal.Footer>
      </Modal>
      <Modal

        className=" "
        show={showDeleteOrderModel}
        onHide={handleDeleteOrderModalClose}
      >
        <Modal.Header style={{ background: "#0b2548" }}>
          <h5 style={{ padding: '10px 5px 0px', color: "white" }}>Delete Confirmation</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="newsletter_message_modal">
            <p>
              Deleting this list will remove all the skip-traced data it contains. Are you sure you want to proceed?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default2" onClick={handleDeleteOrderModalClose}>
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={handleDeleteOrder}
          >
            Yes, please
          </button>
        </Modal.Footer>
      </Modal>


      <DashbaordLayout title={pageTitle}>
        <div className="d-flex" id="wrapper">
          <div id="page-content-wrapper">
            <h2 className="main-title-mobile mob-h2-cst mylist-mob-sm">{pageTitle}</h2>
            {/* <!-- Page content--> */}
            <div className="mylists-page">
              <div className="fileupload-container">
                <div className="row">
                  <div className="col-xl-12">
                    <Row className="justify-content-end">
                      <Col xl={2}>
                        <div className="filestyle">
                          <button
                            className="btn btn-primary upload-btn-list sm-wd-100"
                            onClick={() => (window.location.href = "/file-upload")}
                          >
                            <Image className="uploadIcon" src={uploadImageIcon} /> Upload
                            File
                          </button>

                          {/* <p>
                            You have{" "}
                            <span>
                              {GetFormattedNumber(
                                profileData?.billing?.remainingHits
                              ) || 0}
                            </span>{" "}
                            Skip Trace Hits and{" "}
                            <span>
                              {GetFormattedNumber(
                                profileData?.billing?.remainingDownloads
                              ) || 0}
                            </span>{" "}
                            Property Downloads Credits available.
                              </p> */}
                        </div>
                      </Col>
                    </Row> 
                    {errorMsg ? (
                      <div className="alert alert-danger">
                        {errorMsg}
                      </div>
                    ) : (
                      ""
                    )}
                    {successMsg ? (
                      <div className="alert alert-success">
                        {successMsg}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {!showMessage ? (
                <div className="table-data-main resp-table2">
                  {dataMsg && <p className="text-danger">{dataMsg}</p>}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th className="text-center" width="120">Downloads</th>
                        <th className="text-center" width="100">Searches</th>
                        <th className="text-center" width="80">Hits</th>
                        <th className="text-center">Hit Rate</th>
                        <th className="text-center">Non-Chargeable</th>
                        <th className="text-center">Charged</th>
                        {/* <th>Status</th> */}
                        <th className="text-center" width="130"> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filesData.map((data) => (
                        <tr key={data._id}>
                          <td>{data.orderNumber}</td>
                          <td>
                            {moment(data.dateCreated).format(
                              "MM/DD/YYYY HH:mm:ss"
                            )}
                          </td>
                          <td>
                            {data.fileName?.substring(0, 25) ?? "Skip Trace"}
                          </td>
                          <td className="text-center">{data.propertyCount}</td>
                          <td className="text-center">{data.skiptraceCount}</td>
                          <td className="text-center">{data.skiptraceHits}</td>
                          <td className="text-center">{data.hitRate && !data.additionalDemographicFilterCharges  ? data.hitRate + "%" : "N/A"}</td>
                          <td className="text-center">{data.totalNonChargableCount}</td>
                          <td className="text-center">{data.skiptraceHits > 0 ? data.totalChargedSkips?.toLocaleString() : ''}</td>

                          <td className="text-center">
                            {/* New Design Dropdown items are Starting From here */}
                            
                            {data?.downloadStatus == "in_progress" && data?.propertyCount? (
                                    <ProgressBar
                                        animated
                                        now={Number(data?.orderProgress?.replace("%", ""))}
                                        label={data?.orderProgress}
                                    />
                              ) : (
                                                       
                            <ul className="list-table-dropdown mylist-data">
                              <li className="avatar" onClick={showDropDown}>
                                <Dropdown>
                                  <Dropdown.Toggle>
                                    <Image src={moreIcon} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="list-items-micro">
                                    
                                    {data.downloadStatus == "completed" ? (
                                      <Dropdown.Item onClick={() =>
                                        handleDownload("property", data.downloadId)
                                      }>
                                        <Image src={downloadIcon}></Image><span>Download Property</span>
                                      </Dropdown.Item>
                                    ) : (
                                      ""
                                    )}

                                    {data.downloadStatus == "completed" &&
                                      data.downloadType === "skiptrace" ? (
                                      <>
                                        <Dropdown.Item onClick={() =>
                                          handleDownload("skiptrace", data.downloadId)
                                        }>
                                        
                                        <Image src={downloadIcon}></Image><span>Download Skip Trace</span>
                                        </Dropdown.Item>
                                       
                                      </>
                                    ) : (
                                      <>
                                        {data.downloadType === "skiptrace" ? (
                                          <>
                                            {!data.propertyCount || data.propertyCount<= 0 ? <p className="px-3"><strong>Expired</strong></p> : ''}
                                          </>
                                        ) : (

                                          <Dropdown.Item disabled={disabledSkiptraceBtn || data.propertyCount > SkiptraceMaxRecordsLimit}
                                            onClick={() => {
                                              setOrderId(data._id?.toString());
                                              setPropertyRequestId(
                                                data.propertyRequestId
                                              );
                                              setSkiptraceCount(data.propertyCount);
                                              handleSkiptraceModalShow();
                                            }}>
                                            <Image src={skiptraceIcon}></Image><span>Skip Trace Now</span>
                                          </Dropdown.Item>
                                        )}
                                      </>
                                    )}
                                    
                                    <Dropdown.Item onClick={() => {
                                          setDeleteOrderId(data._id)
                                          setShowDeleteOrderModel(true)
                                        }}>
                                          <Image src={deleteIcon}></Image><span className="text-danger">Delete Order</span>
                                        </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </li>
                            </ul>
                             )}
                            {/* New Design Dropdown items are Ending here */} 
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="pagination cst-pagination">
                    <ReactPaginate
                      // breakLabel="..."
                      previousLabel={<><div><Image src={prevImg} className="prev-icon"></Image> Previous</div></>}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      nextLabel={<><div>Next <Image src={nextImg} className="next-icon"></Image></div></>}
                      renderOnZeroPageCount={null}
                      activeClassName="paginationActive"
                    />
                  </div>
                </div>
              ) : (
                <Row>
                  <Col>
                    <p className="message_show mt-5 wd-100">
                      No data is currently available. Please check back here after
                      you have completed an order for Skip Tracing or Property
                      Downloads. Your requested data will be available for download
                      here.
                    </p>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </DashbaordLayout>
    </div>
  );
};

export default MyLists;
