import { useState } from "react";
import { GetAuthToken } from "../../Utils/auth-verification.util";
import {
  BILLING_AUTO_RENEW_SETTINGS_ENDPOINT,
  BILLING_GET_AUTO_RENEW_SETTINGS_ENDPOINT,
  BILLING_GET_LIST_ENDPOINT,
} from "../../Constants/api-routes.constant";
import Select from "react-select";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export const AutoRenewSettings = () => {
  const [billingList, setBillingList] = useState([]);
  const [billingId, setBillingId] = useState("");
  const [autoRenew, setAutoRenew] = useState(false);
  const [autoRenewMinHits, setAutoRenewMinHits] = useState(500);
  const [showLoader, setShowLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const autoRenewHitsDropdown = [
    { value: 100, label: 100 },
    { value: 500, label: 500 },
    { value: 1000, label: 1000 },
  ];

  useEffect(() => {
    GetAutoRenewSettings();
    GetBillingList();
  }, []);

  const token = GetAuthToken();
  if (!token) return [];

  const GetAutoRenewSettings = () => {
    return fetch(BILLING_GET_AUTO_RENEW_SETTINGS_ENDPOINT, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((rawRes) => rawRes.json())
      .then((response) => {
        if (response?.statusCode == 200) {
          setBillingId(response?.result?.billingId);
          setAutoRenew(response?.result?.autoRenew);
          setAutoRenewMinHits(response.result?.autoRenewMinHits);
        }
      })
      .catch((err) => setErrorMsg(err?.message));
  };

  const GetBillingList = () => {
    setShowLoader(true);
    return fetch(BILLING_GET_LIST_ENDPOINT, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.result) {
          const billings = response.result;
          if (billings?.length) {
            const activeBillings = [];
            for (const singleBilling of billings) {
              if (singleBilling.isActive) {
                const billing = {
                  value: singleBilling._id,
                  label: `${singleBilling.cardType?.toUpperCase()} - ${singleBilling.cardLastFourDigits
                    }`,
                };
                activeBillings.push(billing);
              }
            }
            setBillingList(activeBillings);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        return err;
      });
  };

  const handleSettingsFormSubmit = (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");

    if (autoRenew == "true" && !billingId) {
      setErrorMsg("Please select a payment method.");
      return;
    }
    if (autoRenew == "true" && (!autoRenewMinHits || autoRenewMinHits < 0)) {
      setErrorMsg("Minimum hits should not be less than 0.");
      return;
    }

    return fetch(BILLING_AUTO_RENEW_SETTINGS_ENDPOINT, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
      body: JSON.stringify({
        billingId,
        autoRenew: autoRenew == "true" ? true : false,
        autoRenewMinHits: Number(autoRenewMinHits),
      }),
    })
      .then((rawRes) => rawRes.json())
      .then((response) => {
        if (response?.statusCode == 200) {
          setSuccessMsg("Settings has been updated successfully");
        } else {
          setErrorMsg(
            "We are unable to update these settings. Please try later."
          );
        }
      })
      .catch((err) => setErrorMsg(err?.message));
  };

  return (
    <div>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex" id="wrapper">
        {/* <NavBarMenu page="billing"></NavBarMenu> */}
        <div id="page-content-wrapper">
          {/* <Header /> */}
          {/* <!-- Page content--> */}
          <div className="auto_renewal">
            <div className="container p-1">
              {errorMsg ? (
                <div className="alert alert-danger">{errorMsg}</div>
              ) : (
                ""
              )}
              {successMsg ? (
                <div className="alert alert-success">{successMsg}</div>
              ) : (
                ""
              )}


              <Row >
                <Col xs={12} sm={12} md={12} xl={8} className="mx-auto stripeForm">
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <h3>Auto Renew Settings</h3>
                    </div>
                  </div>
                  <form onSubmit={handleSettingsFormSubmit}>
                    <fieldset className="row">
                      <legend className="col-form-label col-sm-4 pt-0">
                        Auto Renew
                      </legend>
                      <div className="col-sm-8">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="autoRenew"
                            id="radio1"
                            value={true}
                            onChange={(e) => setAutoRenew(e.target.value)}
                            checked={autoRenew == true || autoRenew == "true"}
                          />
                          <label className="form-check-label" for="radio1">
                            On
                          </label>
                        </div>
                        <div className="form-check disabled">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="autoRenew"
                            id="radio2"
                            value={false}
                            onChange={(e) => setAutoRenew(e.target.value)}
                            checked={autoRenew == false || autoRenew == "false"}
                          />
                          <label className="form-check-label" for="radio2">
                            Off
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <Row className="mt-4">
                      <Col xs={12} sm={12} md={6} xl={6}>
                        <label for="inputPaymentMethod" className="form-label">
                          Select Payment Method
                        </label>
                        <Select
                          id="inputPaymentMethod"
                          name="billingId"
                          options={billingList}
                          placeholder="Select"
                          onChange={(selected) => setBillingId(selected.value)}
                          value={billingList.find((b) => b.value == billingId)}
                        />
                      </Col>

                      <Col xs={12} sm={12} md={6} xl={6}>
                        <label for="inputAutoHits" className="form-label">
                          If Hits are below
                        </label>
                        <Select
                          id="inputAutoHits"
                          name="autoRenewMinHits"
                          options={autoRenewHitsDropdown}
                          placeholder="Select"
                          onChange={(selected) =>
                            setAutoRenewMinHits(selected.value)
                          }
                          value={autoRenewHitsDropdown.find(
                            (b) => b.value == autoRenewMinHits
                          )}
                        />

                      </Col>
                      <Col xs={12} sm={12} md={12} xl={12} className="my-3">
                        <button type="submit" className="saveBtn">Save</button>
                      </Col>
                    </Row>


                  </form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
