export const US_County_List = [
  { value: "Autauga (AL)", label: "Autauga (AL)" },
  { value: "Baldwin (AL)", label: "Baldwin (AL)" },
  { value: "Barbour (AL)", label: "Barbour (AL)" },
  { value: "Bibb (AL)", label: "Bibb (AL)" },
  { value: "Blount (AL)", label: "Blount (AL)" },
  { value: "Bullock (AL)", label: "Bullock (AL)" },
  { value: "Butler (AL)", label: "Butler (AL)" },
  { value: "Calhoun (AL)", label: "Calhoun (AL)" },
  { value: "Chambers (AL)", label: "Chambers (AL)" },
  { value: "Cherokee (AL)", label: "Cherokee (AL)" },
  { value: "Chilton (AL)", label: "Chilton (AL)" },
  { value: "Choctaw (AL)", label: "Choctaw (AL)" },
  { value: "Clarke (AL)", label: "Clarke (AL)" },
  { value: "Clay (AL)", label: "Clay (AL)" },
  { value: "Cleburne (AL)", label: "Cleburne (AL)" },
  { value: "Coffee (AL)", label: "Coffee (AL)" },
  { value: "Colbert (AL)", label: "Colbert (AL)" },
  { value: "Conecuh (AL)", label: "Conecuh (AL)" },
  { value: "Coosa (AL)", label: "Coosa (AL)" },
  { value: "Covington (AL)", label: "Covington (AL)" },
  { value: "Crenshaw (AL)", label: "Crenshaw (AL)" },
  { value: "Cullman (AL)", label: "Cullman (AL)" },
  { value: "Dale (AL)", label: "Dale (AL)" },
  { value: "Dallas (AL)", label: "Dallas (AL)" },
  { value: "DeKalb (AL)", label: "DeKalb (AL)" },
  { value: "Elmore (AL)", label: "Elmore (AL)" },
  { value: "Escambia (AL)", label: "Escambia (AL)" },
  { value: "Etowah (AL)", label: "Etowah (AL)" },
  { value: "Fayette (AL)", label: "Fayette (AL)" },
  { value: "Franklin (AL)", label: "Franklin (AL)" },
  { value: "Geneva (AL)", label: "Geneva (AL)" },
  { value: "Greene (AL)", label: "Greene (AL)" },
  { value: "Hale (AL)", label: "Hale (AL)" },
  { value: "Henry (AL)", label: "Henry (AL)" },
  { value: "Houston (AL)", label: "Houston (AL)" },
  { value: "Jackson (AL)", label: "Jackson (AL)" },
  { value: "Jefferson (AL)", label: "Jefferson (AL)" },
  { value: "Lamar (AL)", label: "Lamar (AL)" },
  { value: "Lauderdale (AL)", label: "Lauderdale (AL)" },
  { value: "Lawrence (AL)", label: "Lawrence (AL)" },
  { value: "Lee (AL)", label: "Lee (AL)" },
  { value: "Limestone (AL)", label: "Limestone (AL)" },
  { value: "Lowndes (AL)", label: "Lowndes (AL)" },
  { value: "Macon (AL)", label: "Macon (AL)" },
  { value: "Madison (AL)", label: "Madison (AL)" },
  { value: "Marengo (AL)", label: "Marengo (AL)" },
  { value: "Marion (AL)", label: "Marion (AL)" },
  { value: "Marshall (AL)", label: "Marshall (AL)" },
  { value: "Mobile (AL)", label: "Mobile (AL)" },
  { value: "Monroe (AL)", label: "Monroe (AL)" },
  { value: "Montgomery (AL)", label: "Montgomery (AL)" },
  { value: "Morgan (AL)", label: "Morgan (AL)" },
  { value: "Perry (AL)", label: "Perry (AL)" },
  { value: "Pickens (AL)", label: "Pickens (AL)" },
  { value: "Pike (AL)", label: "Pike (AL)" },
  { value: "Randolph (AL)", label: "Randolph (AL)" },
  { value: "Russell (AL)", label: "Russell (AL)" },
  { value: "St. Clair (AL)", label: "St. Clair (AL)" },
  { value: "Shelby (AL)", label: "Shelby (AL)" },
  { value: "Sumter (AL)", label: "Sumter (AL)" },
  { value: "Talladega (AL)", label: "Talladega (AL)" },
  { value: "Tallapoosa (AL)", label: "Tallapoosa (AL)" },
  { value: "Tuscaloosa (AL)", label: "Tuscaloosa (AL)" },
  { value: "Walker (AL)", label: "Walker (AL)" },
  { value: "Washington (AL)", label: "Washington (AL)" },
  { value: "Wilcox (AL)", label: "Wilcox (AL)" },
  { value: "Winston (AL)", label: "Winston (AL)" },
  {
    value: "Aleutians East Borough (AK)",
    label: "Aleutians East Borough (AK)",
  },
  {
    value: "Aleutians West Census Area (AK)",
    label: "Aleutians West Census Area (AK)",
  },
  {
    value: "Anchorage Municipality (AK)",
    label: "Anchorage Municipality (AK)",
  },
  { value: "Bethel Census Area (AK)", label: "Bethel Census Area (AK)" },
  { value: "Bristol Bay Borough (AK)", label: "Bristol Bay Borough (AK)" },
  { value: "Denali Borough (AK)", label: "Denali Borough (AK)" },
  {
    value: "Dillingham Census Area (AK)",
    label: "Dillingham Census Area (AK)",
  },
  {
    value: "Fairbanks North Star Borough (AK)",
    label: "Fairbanks North Star Borough (AK)",
  },
  { value: "Haines Borough (AK)", label: "Haines Borough (AK)" },
  {
    value: "Hoonah-Angoon Census Area (AK)",
    label: "Hoonah-Angoon Census Area (AK)",
  },
  {
    value: "Juneau City and Borough (AK)",
    label: "Juneau City and Borough (AK)",
  },
  {
    value: "Kenai Peninsula Borough (AK)",
    label: "Kenai Peninsula Borough (AK)",
  },
  {
    value: "Ketchikan Gateway Borough (AK)",
    label: "Ketchikan Gateway Borough (AK)",
  },
  { value: "Kodiak Island Borough (AK)", label: "Kodiak Island Borough (AK)" },
  {
    value: "Lake and Peninsula Borough (AK)",
    label: "Lake and Peninsula Borough (AK)",
  },
  {
    value: "Matanuska-Susitna Borough (AK)",
    label: "Matanuska-Susitna Borough (AK)",
  },
  { value: "Nome Census Area (AK)", label: "Nome Census Area (AK)" },
  { value: "North Slope Borough (AK)", label: "North Slope Borough (AK)" },
  {
    value: "Northwest Arctic Borough (AK)",
    label: "Northwest Arctic Borough (AK)",
  },
  {
    value: "Petersburg Census Area (AK)",
    label: "Petersburg Census Area (AK)",
  },
  {
    value: "Prince of Wales-Hyder Census Area (AK)",
    label: "Prince of Wales-Hyder Census Area (AK)",
  },
  {
    value: "Sitka City and Borough (AK)",
    label: "Sitka City and Borough (AK)",
  },
  { value: "Skagway Municipality (AK)", label: "Skagway Municipality (AK)" },
  {
    value: "Southeast Fairbanks Census Area (AK)",
    label: "Southeast Fairbanks Census Area (AK)",
  },
  {
    value: "Valdez-Cordova Census Area (AK)",
    label: "Valdez-Cordova Census Area (AK)",
  },
  {
    value: "Wade Hampton Census Area (AK)",
    label: "Wade Hampton Census Area (AK)",
  },
  {
    value: "Wrangell City and Borough (AK)",
    label: "Wrangell City and Borough (AK)",
  },
  {
    value: "Yakutat City and Borough (AK)",
    label: "Yakutat City and Borough (AK)",
  },
  {
    value: "Yukon-Koyukuk Census Area (AK)",
    label: "Yukon-Koyukuk Census Area (AK)",
  },
  { value: "Apache (AZ)", label: "Apache (AZ)" },
  { value: "Cochise (AZ)", label: "Cochise (AZ)" },
  { value: "Coconino (AZ)", label: "Coconino (AZ)" },
  { value: "Gila (AZ)", label: "Gila (AZ)" },
  { value: "Graham (AZ)", label: "Graham (AZ)" },
  { value: "Greenlee (AZ)", label: "Greenlee (AZ)" },
  { value: "La Paz (AZ)", label: "La Paz (AZ)" },
  { value: "Maricopa (AZ)", label: "Maricopa (AZ)" },
  { value: "Mohave (AZ)", label: "Mohave (AZ)" },
  { value: "Navajo (AZ)", label: "Navajo (AZ)" },
  { value: "Pima (AZ)", label: "Pima (AZ)" },
  { value: "Pinal (AZ)", label: "Pinal (AZ)" },
  { value: "Santa Cruz (AZ)", label: "Santa Cruz (AZ)" },
  { value: "Yavapai (AZ)", label: "Yavapai (AZ)" },
  { value: "Yuma (AZ)", label: "Yuma (AZ)" },
  { value: "Arkansas (AR)", label: "Arkansas (AR)" },
  { value: "Ashley (AR)", label: "Ashley (AR)" },
  { value: "Baxter (AR)", label: "Baxter (AR)" },
  { value: "Benton (AR)", label: "Benton (AR)" },
  { value: "Boone (AR)", label: "Boone (AR)" },
  { value: "Bradley (AR)", label: "Bradley (AR)" },
  { value: "Calhoun (AR)", label: "Calhoun (AR)" },
  { value: "Carroll (AR)", label: "Carroll (AR)" },
  { value: "Chicot (AR)", label: "Chicot (AR)" },
  { value: "Clark (AR)", label: "Clark (AR)" },
  { value: "Clay (AR)", label: "Clay (AR)" },
  { value: "Cleburne (AR)", label: "Cleburne (AR)" },
  { value: "Cleveland (AR)", label: "Cleveland (AR)" },
  { value: "Columbia (AR)", label: "Columbia (AR)" },
  { value: "Conway (AR)", label: "Conway (AR)" },
  { value: "Craighead (AR)", label: "Craighead (AR)" },
  { value: "Crawford (AR)", label: "Crawford (AR)" },
  { value: "Crittenden (AR)", label: "Crittenden (AR)" },
  { value: "Cross (AR)", label: "Cross (AR)" },
  { value: "Dallas (AR)", label: "Dallas (AR)" },
  { value: "Desha (AR)", label: "Desha (AR)" },
  { value: "Drew (AR)", label: "Drew (AR)" },
  { value: "Faulkner (AR)", label: "Faulkner (AR)" },
  { value: "Franklin (AR)", label: "Franklin (AR)" },
  { value: "Fulton (AR)", label: "Fulton (AR)" },
  { value: "Garland (AR)", label: "Garland (AR)" },
  { value: "Grant (AR)", label: "Grant (AR)" },
  { value: "Greene (AR)", label: "Greene (AR)" },
  { value: "Hempstead (AR)", label: "Hempstead (AR)" },
  { value: "Hot Spring (AR)", label: "Hot Spring (AR)" },
  { value: "Howard (AR)", label: "Howard (AR)" },
  { value: "Independence (AR)", label: "Independence (AR)" },
  { value: "Izard (AR)", label: "Izard (AR)" },
  { value: "Jackson (AR)", label: "Jackson (AR)" },
  { value: "Jefferson (AR)", label: "Jefferson (AR)" },
  { value: "Johnson (AR)", label: "Johnson (AR)" },
  { value: "Lafayette (AR)", label: "Lafayette (AR)" },
  { value: "Lawrence (AR)", label: "Lawrence (AR)" },
  { value: "Lee (AR)", label: "Lee (AR)" },
  { value: "Lincoln (AR)", label: "Lincoln (AR)" },
  { value: "Little River (AR)", label: "Little River (AR)" },
  { value: "Logan (AR)", label: "Logan (AR)" },
  { value: "Lonoke (AR)", label: "Lonoke (AR)" },
  { value: "Madison (AR)", label: "Madison (AR)" },
  { value: "Marion (AR)", label: "Marion (AR)" },
  { value: "Miller (AR)", label: "Miller (AR)" },
  { value: "Mississippi (AR)", label: "Mississippi (AR)" },
  { value: "Monroe (AR)", label: "Monroe (AR)" },
  { value: "Montgomery (AR)", label: "Montgomery (AR)" },
  { value: "Nevada (AR)", label: "Nevada (AR)" },
  { value: "Newton (AR)", label: "Newton (AR)" },
  { value: "Ouachita (AR)", label: "Ouachita (AR)" },
  { value: "Perry (AR)", label: "Perry (AR)" },
  { value: "Phillips (AR)", label: "Phillips (AR)" },
  { value: "Pike (AR)", label: "Pike (AR)" },
  { value: "Poinsett (AR)", label: "Poinsett (AR)" },
  { value: "Polk (AR)", label: "Polk (AR)" },
  { value: "Pope (AR)", label: "Pope (AR)" },
  { value: "Prairie (AR)", label: "Prairie (AR)" },
  { value: "Pulaski (AR)", label: "Pulaski (AR)" },
  { value: "Randolph (AR)", label: "Randolph (AR)" },
  { value: "St. Francis (AR)", label: "St. Francis (AR)" },
  { value: "Saline (AR)", label: "Saline (AR)" },
  { value: "Scott (AR)", label: "Scott (AR)" },
  { value: "Searcy (AR)", label: "Searcy (AR)" },
  { value: "Sebastian (AR)", label: "Sebastian (AR)" },
  { value: "Sevier (AR)", label: "Sevier (AR)" },
  { value: "Sharp (AR)", label: "Sharp (AR)" },
  { value: "Stone (AR)", label: "Stone (AR)" },
  { value: "Union (AR)", label: "Union (AR)" },
  { value: "Van Buren (AR)", label: "Van Buren (AR)" },
  { value: "Washington (AR)", label: "Washington (AR)" },
  { value: "White (AR)", label: "White (AR)" },
  { value: "Woodruff (AR)", label: "Woodruff (AR)" },
  { value: "Yell (AR)", label: "Yell (AR)" },
  { value: "Alameda (CA)", label: "Alameda (CA)" },
  { value: "Alpine (CA)", label: "Alpine (CA)" },
  { value: "Amador (CA)", label: "Amador (CA)" },
  { value: "Butte (CA)", label: "Butte (CA)" },
  { value: "Calaveras (CA)", label: "Calaveras (CA)" },
  { value: "Colusa (CA)", label: "Colusa (CA)" },
  { value: "Contra Costa (CA)", label: "Contra Costa (CA)" },
  { value: "Del Norte (CA)", label: "Del Norte (CA)" },
  { value: "El Dorado (CA)", label: "El Dorado (CA)" },
  { value: "Fresno (CA)", label: "Fresno (CA)" },
  { value: "Glenn (CA)", label: "Glenn (CA)" },
  { value: "Humboldt (CA)", label: "Humboldt (CA)" },
  { value: "Imperial (CA)", label: "Imperial (CA)" },
  { value: "Inyo (CA)", label: "Inyo (CA)" },
  { value: "Kern (CA)", label: "Kern (CA)" },
  { value: "Kings (CA)", label: "Kings (CA)" },
  { value: "Lake (CA)", label: "Lake (CA)" },
  { value: "Lassen (CA)", label: "Lassen (CA)" },
  { value: "Los Angeles (CA)", label: "Los Angeles (CA)" },
  { value: "Madera (CA)", label: "Madera (CA)" },
  { value: "Marin (CA)", label: "Marin (CA)" },
  { value: "Mariposa (CA)", label: "Mariposa (CA)" },
  { value: "Mendocino (CA)", label: "Mendocino (CA)" },
  { value: "Merced (CA)", label: "Merced (CA)" },
  { value: "Modoc (CA)", label: "Modoc (CA)" },
  { value: "Mono (CA)", label: "Mono (CA)" },
  { value: "Monterey (CA)", label: "Monterey (CA)" },
  { value: "Napa (CA)", label: "Napa (CA)" },
  { value: "Nevada (CA)", label: "Nevada (CA)" },
  { value: "Orange (CA)", label: "Orange (CA)" },
  { value: "Placer (CA)", label: "Placer (CA)" },
  { value: "Plumas (CA)", label: "Plumas (CA)" },
  { value: "Riverside (CA)", label: "Riverside (CA)" },
  { value: "Sacramento (CA)", label: "Sacramento (CA)" },
  { value: "San Benito (CA)", label: "San Benito (CA)" },
  { value: "San Bernardino (CA)", label: "San Bernardino (CA)" },
  { value: "San Diego (CA)", label: "San Diego (CA)" },
  { value: "San Francisco (CA)", label: "San Francisco (CA)" },
  { value: "San Joaquin (CA)", label: "San Joaquin (CA)" },
  { value: "San Luis Obispo (CA)", label: "San Luis Obispo (CA)" },
  { value: "San Mateo (CA)", label: "San Mateo (CA)" },
  { value: "Santa Barbara (CA)", label: "Santa Barbara (CA)" },
  { value: "Santa Clara (CA)", label: "Santa Clara (CA)" },
  { value: "Santa Cruz (CA)", label: "Santa Cruz (CA)" },
  { value: "Shasta (CA)", label: "Shasta (CA)" },
  { value: "Sierra (CA)", label: "Sierra (CA)" },
  { value: "Siskiyou (CA)", label: "Siskiyou (CA)" },
  { value: "Solano (CA)", label: "Solano (CA)" },
  { value: "Sonoma (CA)", label: "Sonoma (CA)" },
  { value: "Stanislaus (CA)", label: "Stanislaus (CA)" },
  { value: "Sutter (CA)", label: "Sutter (CA)" },
  { value: "Tehama (CA)", label: "Tehama (CA)" },
  { value: "Trinity (CA)", label: "Trinity (CA)" },
  { value: "Tulare (CA)", label: "Tulare (CA)" },
  { value: "Tuolumne (CA)", label: "Tuolumne (CA)" },
  { value: "Ventura (CA)", label: "Ventura (CA)" },
  { value: "Yolo (CA)", label: "Yolo (CA)" },
  { value: "Yuba (CA)", label: "Yuba (CA)" },
  { value: "Adams (CO)", label: "Adams (CO)" },
  { value: "Alamosa (CO)", label: "Alamosa (CO)" },
  { value: "Arapahoe (CO)", label: "Arapahoe (CO)" },
  { value: "Archuleta (CO)", label: "Archuleta (CO)" },
  { value: "Baca (CO)", label: "Baca (CO)" },
  { value: "Bent (CO)", label: "Bent (CO)" },
  { value: "Boulder (CO)", label: "Boulder (CO)" },
  { value: "Broomfield (CO)", label: "Broomfield (CO)" },
  { value: "Chaffee (CO)", label: "Chaffee (CO)" },
  { value: "Cheyenne (CO)", label: "Cheyenne (CO)" },
  { value: "Clear Creek (CO)", label: "Clear Creek (CO)" },
  { value: "Conejos (CO)", label: "Conejos (CO)" },
  { value: "Costilla (CO)", label: "Costilla (CO)" },
  { value: "Crowley (CO)", label: "Crowley (CO)" },
  { value: "Custer (CO)", label: "Custer (CO)" },
  { value: "Delta (CO)", label: "Delta (CO)" },
  { value: "Denver (CO)", label: "Denver (CO)" },
  { value: "Dolores (CO)", label: "Dolores (CO)" },
  { value: "Douglas (CO)", label: "Douglas (CO)" },
  { value: "Eagle (CO)", label: "Eagle (CO)" },
  { value: "Elbert (CO)", label: "Elbert (CO)" },
  { value: "El Paso (CO)", label: "El Paso (CO)" },
  { value: "Fremont (CO)", label: "Fremont (CO)" },
  { value: "Garfield (CO)", label: "Garfield (CO)" },
  { value: "Gilpin (CO)", label: "Gilpin (CO)" },
  { value: "Grand (CO)", label: "Grand (CO)" },
  { value: "Gunnison (CO)", label: "Gunnison (CO)" },
  { value: "Hinsdale (CO)", label: "Hinsdale (CO)" },
  { value: "Huerfano (CO)", label: "Huerfano (CO)" },
  { value: "Jackson (CO)", label: "Jackson (CO)" },
  { value: "Jefferson (CO)", label: "Jefferson (CO)" },
  { value: "Kiowa (CO)", label: "Kiowa (CO)" },
  { value: "Kit Carson (CO)", label: "Kit Carson (CO)" },
  { value: "Lake (CO)", label: "Lake (CO)" },
  { value: "La Plata (CO)", label: "La Plata (CO)" },
  { value: "Larimer (CO)", label: "Larimer (CO)" },
  { value: "Las Animas (CO)", label: "Las Animas (CO)" },
  { value: "Lincoln (CO)", label: "Lincoln (CO)" },
  { value: "Logan (CO)", label: "Logan (CO)" },
  { value: "Mesa (CO)", label: "Mesa (CO)" },
  { value: "Mineral (CO)", label: "Mineral (CO)" },
  { value: "Moffat (CO)", label: "Moffat (CO)" },
  { value: "Montezuma (CO)", label: "Montezuma (CO)" },
  { value: "Montrose (CO)", label: "Montrose (CO)" },
  { value: "Morgan (CO)", label: "Morgan (CO)" },
  { value: "Otero (CO)", label: "Otero (CO)" },
  { value: "Ouray (CO)", label: "Ouray (CO)" },
  { value: "Park (CO)", label: "Park (CO)" },
  { value: "Phillips (CO)", label: "Phillips (CO)" },
  { value: "Pitkin (CO)", label: "Pitkin (CO)" },
  { value: "Prowers (CO)", label: "Prowers (CO)" },
  { value: "Pueblo (CO)", label: "Pueblo (CO)" },
  { value: "Rio Blanco (CO)", label: "Rio Blanco (CO)" },
  { value: "Rio Grande (CO)", label: "Rio Grande (CO)" },
  { value: "Routt (CO)", label: "Routt (CO)" },
  { value: "Saguache (CO)", label: "Saguache (CO)" },
  { value: "San Juan (CO)", label: "San Juan (CO)" },
  { value: "San Miguel (CO)", label: "San Miguel (CO)" },
  { value: "Sedgwick (CO)", label: "Sedgwick (CO)" },
  { value: "Summit (CO)", label: "Summit (CO)" },
  { value: "Teller (CO)", label: "Teller (CO)" },
  { value: "Washington (CO)", label: "Washington (CO)" },
  { value: "Weld (CO)", label: "Weld (CO)" },
  { value: "Yuma (CO)", label: "Yuma (CO)" },
  { value: "Fairfield (CT)", label: "Fairfield (CT)" },
  { value: "Hartford (CT)", label: "Hartford (CT)" },
  { value: "Litchfield (CT)", label: "Litchfield (CT)" },
  { value: "Middlesex (CT)", label: "Middlesex (CT)" },
  { value: "New Haven (CT)", label: "New Haven (CT)" },
  { value: "New London (CT)", label: "New London (CT)" },
  { value: "Tolland (CT)", label: "Tolland (CT)" },
  { value: "Windham (CT)", label: "Windham (CT)" },
  { value: "Kent (DE)", label: "Kent (DE)" },
  { value: "New Castle (DE)", label: "New Castle (DE)" },
  { value: "Sussex (DE)", label: "Sussex (DE)" },
  { value: "District of Columbia (DC)", label: "District of Columbia (DC)" },
  { value: "Alachua (FL)", label: "Alachua (FL)" },
  { value: "Baker (FL)", label: "Baker (FL)" },
  { value: "Bay (FL)", label: "Bay (FL)" },
  { value: "Bradford (FL)", label: "Bradford (FL)" },
  { value: "Brevard (FL)", label: "Brevard (FL)" },
  { value: "Broward (FL)", label: "Broward (FL)" },
  { value: "Calhoun (FL)", label: "Calhoun (FL)" },
  { value: "Charlotte (FL)", label: "Charlotte (FL)" },
  { value: "Citrus (FL)", label: "Citrus (FL)" },
  { value: "Clay (FL)", label: "Clay (FL)" },
  { value: "Collier (FL)", label: "Collier (FL)" },
  { value: "Columbia (FL)", label: "Columbia (FL)" },
  { value: "DeSoto (FL)", label: "DeSoto (FL)" },
  { value: "Dixie (FL)", label: "Dixie (FL)" },
  { value: "Duval (FL)", label: "Duval (FL)" },
  { value: "Escambia (FL)", label: "Escambia (FL)" },
  { value: "Flagler (FL)", label: "Flagler (FL)" },
  { value: "Franklin (FL)", label: "Franklin (FL)" },
  { value: "Gadsden (FL)", label: "Gadsden (FL)" },
  { value: "Gilchrist (FL)", label: "Gilchrist (FL)" },
  { value: "Glades (FL)", label: "Glades (FL)" },
  { value: "Gulf (FL)", label: "Gulf (FL)" },
  { value: "Hamilton (FL)", label: "Hamilton (FL)" },
  { value: "Hardee (FL)", label: "Hardee (FL)" },
  { value: "Hendry (FL)", label: "Hendry (FL)" },
  { value: "Hernando (FL)", label: "Hernando (FL)" },
  { value: "Highlands (FL)", label: "Highlands (FL)" },
  { value: "Hillsborough (FL)", label: "Hillsborough (FL)" },
  { value: "Holmes (FL)", label: "Holmes (FL)" },
  { value: "Indian River (FL)", label: "Indian River (FL)" },
  { value: "Jackson (FL)", label: "Jackson (FL)" },
  { value: "Jefferson (FL)", label: "Jefferson (FL)" },
  { value: "Lafayette (FL)", label: "Lafayette (FL)" },
  { value: "Lake (FL)", label: "Lake (FL)" },
  { value: "Lee (FL)", label: "Lee (FL)" },
  { value: "Leon (FL)", label: "Leon (FL)" },
  { value: "Levy (FL)", label: "Levy (FL)" },
  { value: "Liberty (FL)", label: "Liberty (FL)" },
  { value: "Madison (FL)", label: "Madison (FL)" },
  { value: "Manatee (FL)", label: "Manatee (FL)" },
  { value: "Marion (FL)", label: "Marion (FL)" },
  { value: "Martin (FL)", label: "Martin (FL)" },
  { value: "Miami-Dade (FL)", label: "Miami-Dade (FL)" },
  { value: "Monroe (FL)", label: "Monroe (FL)" },
  { value: "Nassau (FL)", label: "Nassau (FL)" },
  { value: "Okaloosa (FL)", label: "Okaloosa (FL)" },
  { value: "Okeechobee (FL)", label: "Okeechobee (FL)" },
  { value: "Orange (FL)", label: "Orange (FL)" },
  { value: "Osceola (FL)", label: "Osceola (FL)" },
  { value: "Palm Beach (FL)", label: "Palm Beach (FL)" },
  { value: "Pasco (FL)", label: "Pasco (FL)" },
  { value: "Pinellas (FL)", label: "Pinellas (FL)" },
  { value: "Polk (FL)", label: "Polk (FL)" },
  { value: "Putnam (FL)", label: "Putnam (FL)" },
  { value: "St. Johns (FL)", label: "St. Johns (FL)" },
  { value: "St. Lucie (FL)", label: "St. Lucie (FL)" },
  { value: "Santa Rosa (FL)", label: "Santa Rosa (FL)" },
  { value: "Sarasota (FL)", label: "Sarasota (FL)" },
  { value: "Seminole (FL)", label: "Seminole (FL)" },
  { value: "Sumter (FL)", label: "Sumter (FL)" },
  { value: "Suwannee (FL)", label: "Suwannee (FL)" },
  { value: "Taylor (FL)", label: "Taylor (FL)" },
  { value: "Union (FL)", label: "Union (FL)" },
  { value: "Volusia (FL)", label: "Volusia (FL)" },
  { value: "Wakulla (FL)", label: "Wakulla (FL)" },
  { value: "Walton (FL)", label: "Walton (FL)" },
  { value: "Washington (FL)", label: "Washington (FL)" },
  { value: "Appling (GA)", label: "Appling (GA)" },
  { value: "Atkinson (GA)", label: "Atkinson (GA)" },
  { value: "Bacon (GA)", label: "Bacon (GA)" },
  { value: "Baker (GA)", label: "Baker (GA)" },
  { value: "Baldwin (GA)", label: "Baldwin (GA)" },
  { value: "Banks (GA)", label: "Banks (GA)" },
  { value: "Barrow (GA)", label: "Barrow (GA)" },
  { value: "Bartow (GA)", label: "Bartow (GA)" },
  { value: "Ben Hill (GA)", label: "Ben Hill (GA)" },
  { value: "Berrien (GA)", label: "Berrien (GA)" },
  { value: "Bibb (GA)", label: "Bibb (GA)" },
  { value: "Bleckley (GA)", label: "Bleckley (GA)" },
  { value: "Brantley (GA)", label: "Brantley (GA)" },
  { value: "Brooks (GA)", label: "Brooks (GA)" },
  { value: "Bryan (GA)", label: "Bryan (GA)" },
  { value: "Bulloch (GA)", label: "Bulloch (GA)" },
  { value: "Burke (GA)", label: "Burke (GA)" },
  { value: "Butts (GA)", label: "Butts (GA)" },
  { value: "Calhoun (GA)", label: "Calhoun (GA)" },
  { value: "Camden (GA)", label: "Camden (GA)" },
  { value: "Candler (GA)", label: "Candler (GA)" },
  { value: "Carroll (GA)", label: "Carroll (GA)" },
  { value: "Catoosa (GA)", label: "Catoosa (GA)" },
  { value: "Charlton (GA)", label: "Charlton (GA)" },
  { value: "Chatham (GA)", label: "Chatham (GA)" },
  { value: "Chattahoochee (GA)", label: "Chattahoochee (GA)" },
  { value: "Chattooga (GA)", label: "Chattooga (GA)" },
  { value: "Cherokee (GA)", label: "Cherokee (GA)" },
  { value: "Clarke (GA)", label: "Clarke (GA)" },
  { value: "Clay (GA)", label: "Clay (GA)" },
  { value: "Clayton (GA)", label: "Clayton (GA)" },
  { value: "Clinch (GA)", label: "Clinch (GA)" },
  { value: "Cobb (GA)", label: "Cobb (GA)" },
  { value: "Coffee (GA)", label: "Coffee (GA)" },
  { value: "Colquitt (GA)", label: "Colquitt (GA)" },
  { value: "Columbia (GA)", label: "Columbia (GA)" },
  { value: "Cook (GA)", label: "Cook (GA)" },
  { value: "Coweta (GA)", label: "Coweta (GA)" },
  { value: "Crawford (GA)", label: "Crawford (GA)" },
  { value: "Crisp (GA)", label: "Crisp (GA)" },
  { value: "Dade (GA)", label: "Dade (GA)" },
  { value: "Dawson (GA)", label: "Dawson (GA)" },
  { value: "Decatur (GA)", label: "Decatur (GA)" },
  { value: "DeKalb (GA)", label: "DeKalb (GA)" },
  { value: "Dodge (GA)", label: "Dodge (GA)" },
  { value: "Dooly (GA)", label: "Dooly (GA)" },
  { value: "Dougherty (GA)", label: "Dougherty (GA)" },
  { value: "Douglas (GA)", label: "Douglas (GA)" },
  { value: "Early (GA)", label: "Early (GA)" },
  { value: "Echols (GA)", label: "Echols (GA)" },
  { value: "Effingham (GA)", label: "Effingham (GA)" },
  { value: "Elbert (GA)", label: "Elbert (GA)" },
  { value: "Emanuel (GA)", label: "Emanuel (GA)" },
  { value: "Evans (GA)", label: "Evans (GA)" },
  { value: "Fannin (GA)", label: "Fannin (GA)" },
  { value: "Fayette (GA)", label: "Fayette (GA)" },
  { value: "Floyd (GA)", label: "Floyd (GA)" },
  { value: "Forsyth (GA)", label: "Forsyth (GA)" },
  { value: "Franklin (GA)", label: "Franklin (GA)" },
  { value: "Fulton (GA)", label: "Fulton (GA)" },
  { value: "Gilmer (GA)", label: "Gilmer (GA)" },
  { value: "Glascock (GA)", label: "Glascock (GA)" },
  { value: "Glynn (GA)", label: "Glynn (GA)" },
  { value: "Gordon (GA)", label: "Gordon (GA)" },
  { value: "Grady (GA)", label: "Grady (GA)" },
  { value: "Greene (GA)", label: "Greene (GA)" },
  { value: "Gwinnett (GA)", label: "Gwinnett (GA)" },
  { value: "Habersham (GA)", label: "Habersham (GA)" },
  { value: "Hall (GA)", label: "Hall (GA)" },
  { value: "Hancock (GA)", label: "Hancock (GA)" },
  { value: "Haralson (GA)", label: "Haralson (GA)" },
  { value: "Harris (GA)", label: "Harris (GA)" },
  { value: "Hart (GA)", label: "Hart (GA)" },
  { value: "Heard (GA)", label: "Heard (GA)" },
  { value: "Henry (GA)", label: "Henry (GA)" },
  { value: "Houston (GA)", label: "Houston (GA)" },
  { value: "Irwin (GA)", label: "Irwin (GA)" },
  { value: "Jackson (GA)", label: "Jackson (GA)" },
  { value: "Jasper (GA)", label: "Jasper (GA)" },
  { value: "Jeff Davis (GA)", label: "Jeff Davis (GA)" },
  { value: "Jefferson (GA)", label: "Jefferson (GA)" },
  { value: "Jenkins (GA)", label: "Jenkins (GA)" },
  { value: "Johnson (GA)", label: "Johnson (GA)" },
  { value: "Jones (GA)", label: "Jones (GA)" },
  { value: "Lamar (GA)", label: "Lamar (GA)" },
  { value: "Lanier (GA)", label: "Lanier (GA)" },
  { value: "Laurens (GA)", label: "Laurens (GA)" },
  { value: "Lee (GA)", label: "Lee (GA)" },
  { value: "Liberty (GA)", label: "Liberty (GA)" },
  { value: "Lincoln (GA)", label: "Lincoln (GA)" },
  { value: "Long (GA)", label: "Long (GA)" },
  { value: "Lowndes (GA)", label: "Lowndes (GA)" },
  { value: "Lumpkin (GA)", label: "Lumpkin (GA)" },
  { value: "McDuffie (GA)", label: "McDuffie (GA)" },
  { value: "McIntosh (GA)", label: "McIntosh (GA)" },
  { value: "Macon (GA)", label: "Macon (GA)" },
  { value: "Madison (GA)", label: "Madison (GA)" },
  { value: "Marion (GA)", label: "Marion (GA)" },
  { value: "Meriwether (GA)", label: "Meriwether (GA)" },
  { value: "Miller (GA)", label: "Miller (GA)" },
  { value: "Mitchell (GA)", label: "Mitchell (GA)" },
  { value: "Monroe (GA)", label: "Monroe (GA)" },
  { value: "Montgomery (GA)", label: "Montgomery (GA)" },
  { value: "Morgan (GA)", label: "Morgan (GA)" },
  { value: "Murray (GA)", label: "Murray (GA)" },
  { value: "Muscogee (GA)", label: "Muscogee (GA)" },
  { value: "Newton (GA)", label: "Newton (GA)" },
  { value: "Oconee (GA)", label: "Oconee (GA)" },
  { value: "Oglethorpe (GA)", label: "Oglethorpe (GA)" },
  { value: "Paulding (GA)", label: "Paulding (GA)" },
  { value: "Peach (GA)", label: "Peach (GA)" },
  { value: "Pickens (GA)", label: "Pickens (GA)" },
  { value: "Pierce (GA)", label: "Pierce (GA)" },
  { value: "Pike (GA)", label: "Pike (GA)" },
  { value: "Polk (GA)", label: "Polk (GA)" },
  { value: "Pulaski (GA)", label: "Pulaski (GA)" },
  { value: "Putnam (GA)", label: "Putnam (GA)" },
  { value: "Quitman (GA)", label: "Quitman (GA)" },
  { value: "Rabun (GA)", label: "Rabun (GA)" },
  { value: "Randolph (GA)", label: "Randolph (GA)" },
  { value: "Richmond (GA)", label: "Richmond (GA)" },
  { value: "Rockdale (GA)", label: "Rockdale (GA)" },
  { value: "Schley (GA)", label: "Schley (GA)" },
  { value: "Screven (GA)", label: "Screven (GA)" },
  { value: "Seminole (GA)", label: "Seminole (GA)" },
  { value: "Spalding (GA)", label: "Spalding (GA)" },
  { value: "Stephens (GA)", label: "Stephens (GA)" },
  { value: "Stewart (GA)", label: "Stewart (GA)" },
  { value: "Sumter (GA)", label: "Sumter (GA)" },
  { value: "Talbot (GA)", label: "Talbot (GA)" },
  { value: "Taliaferro (GA)", label: "Taliaferro (GA)" },
  { value: "Tattnall (GA)", label: "Tattnall (GA)" },
  { value: "Taylor (GA)", label: "Taylor (GA)" },
  { value: "Telfair (GA)", label: "Telfair (GA)" },
  { value: "Terrell (GA)", label: "Terrell (GA)" },
  { value: "Thomas (GA)", label: "Thomas (GA)" },
  { value: "Tift (GA)", label: "Tift (GA)" },
  { value: "Toombs (GA)", label: "Toombs (GA)" },
  { value: "Towns (GA)", label: "Towns (GA)" },
  { value: "Treutlen (GA)", label: "Treutlen (GA)" },
  { value: "Troup (GA)", label: "Troup (GA)" },
  { value: "Turner (GA)", label: "Turner (GA)" },
  { value: "Twiggs (GA)", label: "Twiggs (GA)" },
  { value: "Union (GA)", label: "Union (GA)" },
  { value: "Upson (GA)", label: "Upson (GA)" },
  { value: "Walker (GA)", label: "Walker (GA)" },
  { value: "Walton (GA)", label: "Walton (GA)" },
  { value: "Ware (GA)", label: "Ware (GA)" },
  { value: "Warren (GA)", label: "Warren (GA)" },
  { value: "Washington (GA)", label: "Washington (GA)" },
  { value: "Wayne (GA)", label: "Wayne (GA)" },
  { value: "Webster (GA)", label: "Webster (GA)" },
  { value: "Wheeler (GA)", label: "Wheeler (GA)" },
  { value: "White (GA)", label: "White (GA)" },
  { value: "Whitfield (GA)", label: "Whitfield (GA)" },
  { value: "Wilcox (GA)", label: "Wilcox (GA)" },
  { value: "Wilkes (GA)", label: "Wilkes (GA)" },
  { value: "Wilkinson (GA)", label: "Wilkinson (GA)" },
  { value: "Worth (GA)", label: "Worth (GA)" },
  { value: "Hawaii (HI)", label: "Hawaii (HI)" },
  { value: "Honolulu (HI)", label: "Honolulu (HI)" },
  { value: "Kalawao (HI)", label: "Kalawao (HI)" },
  { value: "Kauai (HI)", label: "Kauai (HI)" },
  { value: "Maui (HI)", label: "Maui (HI)" },
  { value: "Ada (ID)", label: "Ada (ID)" },
  { value: "Adams (ID)", label: "Adams (ID)" },
  { value: "Bannock (ID)", label: "Bannock (ID)" },
  { value: "Bear Lake (ID)", label: "Bear Lake (ID)" },
  { value: "Benewah (ID)", label: "Benewah (ID)" },
  { value: "Bingham (ID)", label: "Bingham (ID)" },
  { value: "Blaine (ID)", label: "Blaine (ID)" },
  { value: "Boise (ID)", label: "Boise (ID)" },
  { value: "Bonner (ID)", label: "Bonner (ID)" },
  { value: "Bonneville (ID)", label: "Bonneville (ID)" },
  { value: "Boundary (ID)", label: "Boundary (ID)" },
  { value: "Butte (ID)", label: "Butte (ID)" },
  { value: "Camas (ID)", label: "Camas (ID)" },
  { value: "Canyon (ID)", label: "Canyon (ID)" },
  { value: "Caribou (ID)", label: "Caribou (ID)" },
  { value: "Cassia (ID)", label: "Cassia (ID)" },
  { value: "Clark (ID)", label: "Clark (ID)" },
  { value: "Clearwater (ID)", label: "Clearwater (ID)" },
  { value: "Custer (ID)", label: "Custer (ID)" },
  { value: "Elmore (ID)", label: "Elmore (ID)" },
  { value: "Franklin (ID)", label: "Franklin (ID)" },
  { value: "Fremont (ID)", label: "Fremont (ID)" },
  { value: "Gem (ID)", label: "Gem (ID)" },
  { value: "Gooding (ID)", label: "Gooding (ID)" },
  { value: "Idaho (ID)", label: "Idaho (ID)" },
  { value: "Jefferson (ID)", label: "Jefferson (ID)" },
  { value: "Jerome (ID)", label: "Jerome (ID)" },
  { value: "Kootenai (ID)", label: "Kootenai (ID)" },
  { value: "Latah (ID)", label: "Latah (ID)" },
  { value: "Lemhi (ID)", label: "Lemhi (ID)" },
  { value: "Lewis (ID)", label: "Lewis (ID)" },
  { value: "Lincoln (ID)", label: "Lincoln (ID)" },
  { value: "Madison (ID)", label: "Madison (ID)" },
  { value: "Minidoka (ID)", label: "Minidoka (ID)" },
  { value: "Nez Perce (ID)", label: "Nez Perce (ID)" },
  { value: "Oneida (ID)", label: "Oneida (ID)" },
  { value: "Owyhee (ID)", label: "Owyhee (ID)" },
  { value: "Payette (ID)", label: "Payette (ID)" },
  { value: "Power (ID)", label: "Power (ID)" },
  { value: "Shoshone (ID)", label: "Shoshone (ID)" },
  { value: "Teton (ID)", label: "Teton (ID)" },
  { value: "Twin Falls (ID)", label: "Twin Falls (ID)" },
  { value: "Valley (ID)", label: "Valley (ID)" },
  { value: "Washington (ID)", label: "Washington (ID)" },
  { value: "Adams (IL)", label: "Adams (IL)" },
  { value: "Alexander (IL)", label: "Alexander (IL)" },
  { value: "Bond (IL)", label: "Bond (IL)" },
  { value: "Boone (IL)", label: "Boone (IL)" },
  { value: "Brown (IL)", label: "Brown (IL)" },
  { value: "Bureau (IL)", label: "Bureau (IL)" },
  { value: "Calhoun (IL)", label: "Calhoun (IL)" },
  { value: "Carroll (IL)", label: "Carroll (IL)" },
  { value: "Cass (IL)", label: "Cass (IL)" },
  { value: "Champaign (IL)", label: "Champaign (IL)" },
  { value: "Christian (IL)", label: "Christian (IL)" },
  { value: "Clark (IL)", label: "Clark (IL)" },
  { value: "Clay (IL)", label: "Clay (IL)" },
  { value: "Clinton (IL)", label: "Clinton (IL)" },
  { value: "Coles (IL)", label: "Coles (IL)" },
  { value: "Cook (IL)", label: "Cook (IL)" },
  { value: "Crawford (IL)", label: "Crawford (IL)" },
  { value: "Cumberland (IL)", label: "Cumberland (IL)" },
  { value: "DeKalb (IL)", label: "DeKalb (IL)" },
  { value: "De Witt (IL)", label: "De Witt (IL)" },
  { value: "Douglas (IL)", label: "Douglas (IL)" },
  { value: "DuPage (IL)", label: "DuPage (IL)" },
  { value: "Edgar (IL)", label: "Edgar (IL)" },
  { value: "Edwards (IL)", label: "Edwards (IL)" },
  { value: "Effingham (IL)", label: "Effingham (IL)" },
  { value: "Fayette (IL)", label: "Fayette (IL)" },
  { value: "Ford (IL)", label: "Ford (IL)" },
  { value: "Franklin (IL)", label: "Franklin (IL)" },
  { value: "Fulton (IL)", label: "Fulton (IL)" },
  { value: "Gallatin (IL)", label: "Gallatin (IL)" },
  { value: "Greene (IL)", label: "Greene (IL)" },
  { value: "Grundy (IL)", label: "Grundy (IL)" },
  { value: "Hamilton (IL)", label: "Hamilton (IL)" },
  { value: "Hancock (IL)", label: "Hancock (IL)" },
  { value: "Hardin (IL)", label: "Hardin (IL)" },
  { value: "Henderson (IL)", label: "Henderson (IL)" },
  { value: "Henry (IL)", label: "Henry (IL)" },
  { value: "Iroquois (IL)", label: "Iroquois (IL)" },
  { value: "Jackson (IL)", label: "Jackson (IL)" },
  { value: "Jasper (IL)", label: "Jasper (IL)" },
  { value: "Jefferson (IL)", label: "Jefferson (IL)" },
  { value: "Jersey (IL)", label: "Jersey (IL)" },
  { value: "Jo Daviess (IL)", label: "Jo Daviess (IL)" },
  { value: "Johnson (IL)", label: "Johnson (IL)" },
  { value: "Kane (IL)", label: "Kane (IL)" },
  { value: "Kankakee (IL)", label: "Kankakee (IL)" },
  { value: "Kendall (IL)", label: "Kendall (IL)" },
  { value: "Knox (IL)", label: "Knox (IL)" },
  { value: "Lake (IL)", label: "Lake (IL)" },
  { value: "LaSalle (IL)", label: "LaSalle (IL)" },
  { value: "Lawrence (IL)", label: "Lawrence (IL)" },
  { value: "Lee (IL)", label: "Lee (IL)" },
  { value: "Livingston (IL)", label: "Livingston (IL)" },
  { value: "Logan (IL)", label: "Logan (IL)" },
  { value: "McDonough (IL)", label: "McDonough (IL)" },
  { value: "McHenry (IL)", label: "McHenry (IL)" },
  { value: "McLean (IL)", label: "McLean (IL)" },
  { value: "Macon (IL)", label: "Macon (IL)" },
  { value: "Macoupin (IL)", label: "Macoupin (IL)" },
  { value: "Madison (IL)", label: "Madison (IL)" },
  { value: "Marion (IL)", label: "Marion (IL)" },
  { value: "Marshall (IL)", label: "Marshall (IL)" },
  { value: "Mason (IL)", label: "Mason (IL)" },
  { value: "Massac (IL)", label: "Massac (IL)" },
  { value: "Menard (IL)", label: "Menard (IL)" },
  { value: "Mercer (IL)", label: "Mercer (IL)" },
  { value: "Monroe (IL)", label: "Monroe (IL)" },
  { value: "Montgomery (IL)", label: "Montgomery (IL)" },
  { value: "Morgan (IL)", label: "Morgan (IL)" },
  { value: "Moultrie (IL)", label: "Moultrie (IL)" },
  { value: "Ogle (IL)", label: "Ogle (IL)" },
  { value: "Peoria (IL)", label: "Peoria (IL)" },
  { value: "Perry (IL)", label: "Perry (IL)" },
  { value: "Piatt (IL)", label: "Piatt (IL)" },
  { value: "Pike (IL)", label: "Pike (IL)" },
  { value: "Pope (IL)", label: "Pope (IL)" },
  { value: "Pulaski (IL)", label: "Pulaski (IL)" },
  { value: "Putnam (IL)", label: "Putnam (IL)" },
  { value: "Randolph (IL)", label: "Randolph (IL)" },
  { value: "Richland (IL)", label: "Richland (IL)" },
  { value: "Rock Island (IL)", label: "Rock Island (IL)" },
  { value: "St. Clair (IL)", label: "St. Clair (IL)" },
  { value: "Saline (IL)", label: "Saline (IL)" },
  { value: "Sangamon (IL)", label: "Sangamon (IL)" },
  { value: "Schuyler (IL)", label: "Schuyler (IL)" },
  { value: "Scott (IL)", label: "Scott (IL)" },
  { value: "Shelby (IL)", label: "Shelby (IL)" },
  { value: "Stark (IL)", label: "Stark (IL)" },
  { value: "Stephenson (IL)", label: "Stephenson (IL)" },
  { value: "Tazewell (IL)", label: "Tazewell (IL)" },
  { value: "Union (IL)", label: "Union (IL)" },
  { value: "Vermilion (IL)", label: "Vermilion (IL)" },
  { value: "Wabash (IL)", label: "Wabash (IL)" },
  { value: "Warren (IL)", label: "Warren (IL)" },
  { value: "Washington (IL)", label: "Washington (IL)" },
  { value: "Wayne (IL)", label: "Wayne (IL)" },
  { value: "White (IL)", label: "White (IL)" },
  { value: "Whiteside (IL)", label: "Whiteside (IL)" },
  { value: "Will (IL)", label: "Will (IL)" },
  { value: "Williamson (IL)", label: "Williamson (IL)" },
  { value: "Winnebago (IL)", label: "Winnebago (IL)" },
  { value: "Woodford (IL)", label: "Woodford (IL)" },
  { value: "Adams (IN)", label: "Adams (IN)" },
  { value: "Allen (IN)", label: "Allen (IN)" },
  { value: "Bartholomew (IN)", label: "Bartholomew (IN)" },
  { value: "Benton (IN)", label: "Benton (IN)" },
  { value: "Blackford (IN)", label: "Blackford (IN)" },
  { value: "Boone (IN)", label: "Boone (IN)" },
  { value: "Brown (IN)", label: "Brown (IN)" },
  { value: "Carroll (IN)", label: "Carroll (IN)" },
  { value: "Cass (IN)", label: "Cass (IN)" },
  { value: "Clark (IN)", label: "Clark (IN)" },
  { value: "Clay (IN)", label: "Clay (IN)" },
  { value: "Clinton (IN)", label: "Clinton (IN)" },
  { value: "Crawford (IN)", label: "Crawford (IN)" },
  { value: "Daviess (IN)", label: "Daviess (IN)" },
  { value: "Dearborn (IN)", label: "Dearborn (IN)" },
  { value: "Decatur (IN)", label: "Decatur (IN)" },
  { value: "DeKalb (IN)", label: "DeKalb (IN)" },
  { value: "Delaware (IN)", label: "Delaware (IN)" },
  { value: "Dubois (IN)", label: "Dubois (IN)" },
  { value: "Elkhart (IN)", label: "Elkhart (IN)" },
  { value: "Fayette (IN)", label: "Fayette (IN)" },
  { value: "Floyd (IN)", label: "Floyd (IN)" },
  { value: "Fountain (IN)", label: "Fountain (IN)" },
  { value: "Franklin (IN)", label: "Franklin (IN)" },
  { value: "Fulton (IN)", label: "Fulton (IN)" },
  { value: "Gibson (IN)", label: "Gibson (IN)" },
  { value: "Grant (IN)", label: "Grant (IN)" },
  { value: "Greene (IN)", label: "Greene (IN)" },
  { value: "Hamilton (IN)", label: "Hamilton (IN)" },
  { value: "Hancock (IN)", label: "Hancock (IN)" },
  { value: "Harrison (IN)", label: "Harrison (IN)" },
  { value: "Hendricks (IN)", label: "Hendricks (IN)" },
  { value: "Henry (IN)", label: "Henry (IN)" },
  { value: "Howard (IN)", label: "Howard (IN)" },
  { value: "Huntington (IN)", label: "Huntington (IN)" },
  { value: "Jackson (IN)", label: "Jackson (IN)" },
  { value: "Jasper (IN)", label: "Jasper (IN)" },
  { value: "Jay (IN)", label: "Jay (IN)" },
  { value: "Jefferson (IN)", label: "Jefferson (IN)" },
  { value: "Jennings (IN)", label: "Jennings (IN)" },
  { value: "Johnson (IN)", label: "Johnson (IN)" },
  { value: "Knox (IN)", label: "Knox (IN)" },
  { value: "Kosciusko (IN)", label: "Kosciusko (IN)" },
  { value: "LaGrange (IN)", label: "LaGrange (IN)" },
  { value: "Lake (IN)", label: "Lake (IN)" },
  { value: "LaPorte (IN)", label: "LaPorte (IN)" },
  { value: "Lawrence (IN)", label: "Lawrence (IN)" },
  { value: "Madison (IN)", label: "Madison (IN)" },
  { value: "Marion (IN)", label: "Marion (IN)" },
  { value: "Marshall (IN)", label: "Marshall (IN)" },
  { value: "Martin (IN)", label: "Martin (IN)" },
  { value: "Miami (IN)", label: "Miami (IN)" },
  { value: "Monroe (IN)", label: "Monroe (IN)" },
  { value: "Montgomery (IN)", label: "Montgomery (IN)" },
  { value: "Morgan (IN)", label: "Morgan (IN)" },
  { value: "Newton (IN)", label: "Newton (IN)" },
  { value: "Noble (IN)", label: "Noble (IN)" },
  { value: "Ohio (IN)", label: "Ohio (IN)" },
  { value: "Orange (IN)", label: "Orange (IN)" },
  { value: "Owen (IN)", label: "Owen (IN)" },
  { value: "Parke (IN)", label: "Parke (IN)" },
  { value: "Perry (IN)", label: "Perry (IN)" },
  { value: "Pike (IN)", label: "Pike (IN)" },
  { value: "Porter (IN)", label: "Porter (IN)" },
  { value: "Posey (IN)", label: "Posey (IN)" },
  { value: "Pulaski (IN)", label: "Pulaski (IN)" },
  { value: "Putnam (IN)", label: "Putnam (IN)" },
  { value: "Randolph (IN)", label: "Randolph (IN)" },
  { value: "Ripley (IN)", label: "Ripley (IN)" },
  { value: "Rush (IN)", label: "Rush (IN)" },
  { value: "St. Joseph (IN)", label: "St. Joseph (IN)" },
  { value: "Scott (IN)", label: "Scott (IN)" },
  { value: "Shelby (IN)", label: "Shelby (IN)" },
  { value: "Spencer (IN)", label: "Spencer (IN)" },
  { value: "Starke (IN)", label: "Starke (IN)" },
  { value: "Steuben (IN)", label: "Steuben (IN)" },
  { value: "Sullivan (IN)", label: "Sullivan (IN)" },
  { value: "Switzerland (IN)", label: "Switzerland (IN)" },
  { value: "Tippecanoe (IN)", label: "Tippecanoe (IN)" },
  { value: "Tipton (IN)", label: "Tipton (IN)" },
  { value: "Union (IN)", label: "Union (IN)" },
  { value: "Vanderburgh (IN)", label: "Vanderburgh (IN)" },
  { value: "Vermillion (IN)", label: "Vermillion (IN)" },
  { value: "Vigo (IN)", label: "Vigo (IN)" },
  { value: "Wabash (IN)", label: "Wabash (IN)" },
  { value: "Warren (IN)", label: "Warren (IN)" },
  { value: "Warrick (IN)", label: "Warrick (IN)" },
  { value: "Washington (IN)", label: "Washington (IN)" },
  { value: "Wayne (IN)", label: "Wayne (IN)" },
  { value: "Wells (IN)", label: "Wells (IN)" },
  { value: "White (IN)", label: "White (IN)" },
  { value: "Whitley (IN)", label: "Whitley (IN)" },
  { value: "Adair (IA)", label: "Adair (IA)" },
  { value: "Adams (IA)", label: "Adams (IA)" },
  { value: "Allamakee (IA)", label: "Allamakee (IA)" },
  { value: "Appanoose (IA)", label: "Appanoose (IA)" },
  { value: "Audubon (IA)", label: "Audubon (IA)" },
  { value: "Benton (IA)", label: "Benton (IA)" },
  { value: "Black Hawk (IA)", label: "Black Hawk (IA)" },
  { value: "Boone (IA)", label: "Boone (IA)" },
  { value: "Bremer (IA)", label: "Bremer (IA)" },
  { value: "Buchanan (IA)", label: "Buchanan (IA)" },
  { value: "Buena Vista (IA)", label: "Buena Vista (IA)" },
  { value: "Butler (IA)", label: "Butler (IA)" },
  { value: "Calhoun (IA)", label: "Calhoun (IA)" },
  { value: "Carroll (IA)", label: "Carroll (IA)" },
  { value: "Cass (IA)", label: "Cass (IA)" },
  { value: "Cedar (IA)", label: "Cedar (IA)" },
  { value: "Cerro Gordo (IA)", label: "Cerro Gordo (IA)" },
  { value: "Cherokee (IA)", label: "Cherokee (IA)" },
  { value: "Chickasaw (IA)", label: "Chickasaw (IA)" },
  { value: "Clarke (IA)", label: "Clarke (IA)" },
  { value: "Clay (IA)", label: "Clay (IA)" },
  { value: "Clayton (IA)", label: "Clayton (IA)" },
  { value: "Clinton (IA)", label: "Clinton (IA)" },
  { value: "Crawford (IA)", label: "Crawford (IA)" },
  { value: "Dallas (IA)", label: "Dallas (IA)" },
  { value: "Davis (IA)", label: "Davis (IA)" },
  { value: "Decatur (IA)", label: "Decatur (IA)" },
  { value: "Delaware (IA)", label: "Delaware (IA)" },
  { value: "Des Moines (IA)", label: "Des Moines (IA)" },
  { value: "Dickinson (IA)", label: "Dickinson (IA)" },
  { value: "Dubuque (IA)", label: "Dubuque (IA)" },
  { value: "Emmet (IA)", label: "Emmet (IA)" },
  { value: "Fayette (IA)", label: "Fayette (IA)" },
  { value: "Floyd (IA)", label: "Floyd (IA)" },
  { value: "Franklin (IA)", label: "Franklin (IA)" },
  { value: "Fremont (IA)", label: "Fremont (IA)" },
  { value: "Greene (IA)", label: "Greene (IA)" },
  { value: "Grundy (IA)", label: "Grundy (IA)" },
  { value: "Guthrie (IA)", label: "Guthrie (IA)" },
  { value: "Hamilton (IA)", label: "Hamilton (IA)" },
  { value: "Hancock (IA)", label: "Hancock (IA)" },
  { value: "Hardin (IA)", label: "Hardin (IA)" },
  { value: "Harrison (IA)", label: "Harrison (IA)" },
  { value: "Henry (IA)", label: "Henry (IA)" },
  { value: "Howard (IA)", label: "Howard (IA)" },
  { value: "Humboldt (IA)", label: "Humboldt (IA)" },
  { value: "Ida (IA)", label: "Ida (IA)" },
  { value: "Iowa (IA)", label: "Iowa (IA)" },
  { value: "Jackson (IA)", label: "Jackson (IA)" },
  { value: "Jasper (IA)", label: "Jasper (IA)" },
  { value: "Jefferson (IA)", label: "Jefferson (IA)" },
  { value: "Johnson (IA)", label: "Johnson (IA)" },
  { value: "Jones (IA)", label: "Jones (IA)" },
  { value: "Keokuk (IA)", label: "Keokuk (IA)" },
  { value: "Kossuth (IA)", label: "Kossuth (IA)" },
  { value: "Lee (IA)", label: "Lee (IA)" },
  { value: "Linn (IA)", label: "Linn (IA)" },
  { value: "Louisa (IA)", label: "Louisa (IA)" },
  { value: "Lucas (IA)", label: "Lucas (IA)" },
  { value: "Lyon (IA)", label: "Lyon (IA)" },
  { value: "Madison (IA)", label: "Madison (IA)" },
  { value: "Mahaska (IA)", label: "Mahaska (IA)" },
  { value: "Marion (IA)", label: "Marion (IA)" },
  { value: "Marshall (IA)", label: "Marshall (IA)" },
  { value: "Mills (IA)", label: "Mills (IA)" },
  { value: "Mitchell (IA)", label: "Mitchell (IA)" },
  { value: "Monona (IA)", label: "Monona (IA)" },
  { value: "Monroe (IA)", label: "Monroe (IA)" },
  { value: "Montgomery (IA)", label: "Montgomery (IA)" },
  { value: "Muscatine (IA)", label: "Muscatine (IA)" },
  { value: "O'brien (ia)", label: "O'Brien (IA)" },
  { value: "Osceola (IA)", label: "Osceola (IA)" },
  { value: "Page (IA)", label: "Page (IA)" },
  { value: "Palo Alto (IA)", label: "Palo Alto (IA)" },
  { value: "Plymouth (IA)", label: "Plymouth (IA)" },
  { value: "Pocahontas (IA)", label: "Pocahontas (IA)" },
  { value: "Polk (IA)", label: "Polk (IA)" },
  { value: "Pottawattamie (IA)", label: "Pottawattamie (IA)" },
  { value: "Poweshiek (IA)", label: "Poweshiek (IA)" },
  { value: "Ringgold (IA)", label: "Ringgold (IA)" },
  { value: "Sac (IA)", label: "Sac (IA)" },
  { value: "Scott (IA)", label: "Scott (IA)" },
  { value: "Shelby (IA)", label: "Shelby (IA)" },
  { value: "Sioux (IA)", label: "Sioux (IA)" },
  { value: "Story (IA)", label: "Story (IA)" },
  { value: "Tama (IA)", label: "Tama (IA)" },
  { value: "Taylor (IA)", label: "Taylor (IA)" },
  { value: "Union (IA)", label: "Union (IA)" },
  { value: "Van Buren (IA)", label: "Van Buren (IA)" },
  { value: "Wapello (IA)", label: "Wapello (IA)" },
  { value: "Warren (IA)", label: "Warren (IA)" },
  { value: "Washington (IA)", label: "Washington (IA)" },
  { value: "Wayne (IA)", label: "Wayne (IA)" },
  { value: "Webster (IA)", label: "Webster (IA)" },
  { value: "Winnebago (IA)", label: "Winnebago (IA)" },
  { value: "Winneshiek (IA)", label: "Winneshiek (IA)" },
  { value: "Woodbury (IA)", label: "Woodbury (IA)" },
  { value: "Worth (IA)", label: "Worth (IA)" },
  { value: "Wright (IA)", label: "Wright (IA)" },
  { value: "Allen (KS)", label: "Allen (KS)" },
  { value: "Anderson (KS)", label: "Anderson (KS)" },
  { value: "Atchison (KS)", label: "Atchison (KS)" },
  { value: "Barber (KS)", label: "Barber (KS)" },
  { value: "Barton (KS)", label: "Barton (KS)" },
  { value: "Bourbon (KS)", label: "Bourbon (KS)" },
  { value: "Brown (KS)", label: "Brown (KS)" },
  { value: "Butler (KS)", label: "Butler (KS)" },
  { value: "Chase (KS)", label: "Chase (KS)" },
  { value: "Chautauqua (KS)", label: "Chautauqua (KS)" },
  { value: "Cherokee (KS)", label: "Cherokee (KS)" },
  { value: "Cheyenne (KS)", label: "Cheyenne (KS)" },
  { value: "Clark (KS)", label: "Clark (KS)" },
  { value: "Clay (KS)", label: "Clay (KS)" },
  { value: "Cloud (KS)", label: "Cloud (KS)" },
  { value: "Coffey (KS)", label: "Coffey (KS)" },
  { value: "Comanche (KS)", label: "Comanche (KS)" },
  { value: "Cowley (KS)", label: "Cowley (KS)" },
  { value: "Crawford (KS)", label: "Crawford (KS)" },
  { value: "Decatur (KS)", label: "Decatur (KS)" },
  { value: "Dickinson (KS)", label: "Dickinson (KS)" },
  { value: "Doniphan (KS)", label: "Doniphan (KS)" },
  { value: "Douglas (KS)", label: "Douglas (KS)" },
  { value: "Edwards (KS)", label: "Edwards (KS)" },
  { value: "Elk (KS)", label: "Elk (KS)" },
  { value: "Ellis (KS)", label: "Ellis (KS)" },
  { value: "Ellsworth (KS)", label: "Ellsworth (KS)" },
  { value: "Finney (KS)", label: "Finney (KS)" },
  { value: "Ford (KS)", label: "Ford (KS)" },
  { value: "Franklin (KS)", label: "Franklin (KS)" },
  { value: "Geary (KS)", label: "Geary (KS)" },
  { value: "Gove (KS)", label: "Gove (KS)" },
  { value: "Graham (KS)", label: "Graham (KS)" },
  { value: "Grant (KS)", label: "Grant (KS)" },
  { value: "Gray (KS)", label: "Gray (KS)" },
  { value: "Greeley (KS)", label: "Greeley (KS)" },
  { value: "Greenwood (KS)", label: "Greenwood (KS)" },
  { value: "Hamilton (KS)", label: "Hamilton (KS)" },
  { value: "Harper (KS)", label: "Harper (KS)" },
  { value: "Harvey (KS)", label: "Harvey (KS)" },
  { value: "Haskell (KS)", label: "Haskell (KS)" },
  { value: "Hodgeman (KS)", label: "Hodgeman (KS)" },
  { value: "Jackson (KS)", label: "Jackson (KS)" },
  { value: "Jefferson (KS)", label: "Jefferson (KS)" },
  { value: "Jewell (KS)", label: "Jewell (KS)" },
  { value: "Johnson (KS)", label: "Johnson (KS)" },
  { value: "Kearny (KS)", label: "Kearny (KS)" },
  { value: "Kingman (KS)", label: "Kingman (KS)" },
  { value: "Kiowa (KS)", label: "Kiowa (KS)" },
  { value: "Labette (KS)", label: "Labette (KS)" },
  { value: "Lane (KS)", label: "Lane (KS)" },
  { value: "Leavenworth (KS)", label: "Leavenworth (KS)" },
  { value: "Lincoln (KS)", label: "Lincoln (KS)" },
  { value: "Linn (KS)", label: "Linn (KS)" },
  { value: "Logan (KS)", label: "Logan (KS)" },
  { value: "Lyon (KS)", label: "Lyon (KS)" },
  { value: "McPherson (KS)", label: "McPherson (KS)" },
  { value: "Marion (KS)", label: "Marion (KS)" },
  { value: "Marshall (KS)", label: "Marshall (KS)" },
  { value: "Meade (KS)", label: "Meade (KS)" },
  { value: "Miami (KS)", label: "Miami (KS)" },
  { value: "Mitchell (KS)", label: "Mitchell (KS)" },
  { value: "Montgomery (KS)", label: "Montgomery (KS)" },
  { value: "Morris (KS)", label: "Morris (KS)" },
  { value: "Morton (KS)", label: "Morton (KS)" },
  { value: "Nemaha (KS)", label: "Nemaha (KS)" },
  { value: "Neosho (KS)", label: "Neosho (KS)" },
  { value: "Ness (KS)", label: "Ness (KS)" },
  { value: "Norton (KS)", label: "Norton (KS)" },
  { value: "Osage (KS)", label: "Osage (KS)" },
  { value: "Osborne (KS)", label: "Osborne (KS)" },
  { value: "Ottawa (KS)", label: "Ottawa (KS)" },
  { value: "Pawnee (KS)", label: "Pawnee (KS)" },
  { value: "Phillips (KS)", label: "Phillips (KS)" },
  { value: "Pottawatomie (KS)", label: "Pottawatomie (KS)" },
  { value: "Pratt (KS)", label: "Pratt (KS)" },
  { value: "Rawlins (KS)", label: "Rawlins (KS)" },
  { value: "Reno (KS)", label: "Reno (KS)" },
  { value: "Republic (KS)", label: "Republic (KS)" },
  { value: "Rice (KS)", label: "Rice (KS)" },
  { value: "Riley (KS)", label: "Riley (KS)" },
  { value: "Rooks (KS)", label: "Rooks (KS)" },
  { value: "Rush (KS)", label: "Rush (KS)" },
  { value: "Russell (KS)", label: "Russell (KS)" },
  { value: "Saline (KS)", label: "Saline (KS)" },
  { value: "Scott (KS)", label: "Scott (KS)" },
  { value: "Sedgwick (KS)", label: "Sedgwick (KS)" },
  { value: "Seward (KS)", label: "Seward (KS)" },
  { value: "Shawnee (KS)", label: "Shawnee (KS)" },
  { value: "Sheridan (KS)", label: "Sheridan (KS)" },
  { value: "Sherman (KS)", label: "Sherman (KS)" },
  { value: "Smith (KS)", label: "Smith (KS)" },
  { value: "Stafford (KS)", label: "Stafford (KS)" },
  { value: "Stanton (KS)", label: "Stanton (KS)" },
  { value: "Stevens (KS)", label: "Stevens (KS)" },
  { value: "Sumner (KS)", label: "Sumner (KS)" },
  { value: "Thomas (KS)", label: "Thomas (KS)" },
  { value: "Trego (KS)", label: "Trego (KS)" },
  { value: "Wabaunsee (KS)", label: "Wabaunsee (KS)" },
  { value: "Wallace (KS)", label: "Wallace (KS)" },
  { value: "Washington (KS)", label: "Washington (KS)" },
  { value: "Wichita (KS)", label: "Wichita (KS)" },
  { value: "Wilson (KS)", label: "Wilson (KS)" },
  { value: "Woodson (KS)", label: "Woodson (KS)" },
  { value: "Wyandotte (KS)", label: "Wyandotte (KS)" },
  { value: "Adair (KY)", label: "Adair (KY)" },
  { value: "Allen (KY)", label: "Allen (KY)" },
  { value: "Anderson (KY)", label: "Anderson (KY)" },
  { value: "Ballard (KY)", label: "Ballard (KY)" },
  { value: "Barren (KY)", label: "Barren (KY)" },
  { value: "Bath (KY)", label: "Bath (KY)" },
  { value: "Bell (KY)", label: "Bell (KY)" },
  { value: "Boone (KY)", label: "Boone (KY)" },
  { value: "Bourbon (KY)", label: "Bourbon (KY)" },
  { value: "Boyd (KY)", label: "Boyd (KY)" },
  { value: "Boyle (KY)", label: "Boyle (KY)" },
  { value: "Bracken (KY)", label: "Bracken (KY)" },
  { value: "Breathitt (KY)", label: "Breathitt (KY)" },
  { value: "Breckinridge (KY)", label: "Breckinridge (KY)" },
  { value: "Bullitt (KY)", label: "Bullitt (KY)" },
  { value: "Butler (KY)", label: "Butler (KY)" },
  { value: "Caldwell (KY)", label: "Caldwell (KY)" },
  { value: "Calloway (KY)", label: "Calloway (KY)" },
  { value: "Campbell (KY)", label: "Campbell (KY)" },
  { value: "Carlisle (KY)", label: "Carlisle (KY)" },
  { value: "Carroll (KY)", label: "Carroll (KY)" },
  { value: "Carter (KY)", label: "Carter (KY)" },
  { value: "Casey (KY)", label: "Casey (KY)" },
  { value: "Christian (KY)", label: "Christian (KY)" },
  { value: "Clark (KY)", label: "Clark (KY)" },
  { value: "Clay (KY)", label: "Clay (KY)" },
  { value: "Clinton (KY)", label: "Clinton (KY)" },
  { value: "Crittenden (KY)", label: "Crittenden (KY)" },
  { value: "Cumberland (KY)", label: "Cumberland (KY)" },
  { value: "Daviess (KY)", label: "Daviess (KY)" },
  { value: "Edmonson (KY)", label: "Edmonson (KY)" },
  { value: "Elliott (KY)", label: "Elliott (KY)" },
  { value: "Estill (KY)", label: "Estill (KY)" },
  { value: "Fayette (KY)", label: "Fayette (KY)" },
  { value: "Fleming (KY)", label: "Fleming (KY)" },
  { value: "Floyd (KY)", label: "Floyd (KY)" },
  { value: "Franklin (KY)", label: "Franklin (KY)" },
  { value: "Fulton (KY)", label: "Fulton (KY)" },
  { value: "Gallatin (KY)", label: "Gallatin (KY)" },
  { value: "Garrard (KY)", label: "Garrard (KY)" },
  { value: "Grant (KY)", label: "Grant (KY)" },
  { value: "Graves (KY)", label: "Graves (KY)" },
  { value: "Grayson (KY)", label: "Grayson (KY)" },
  { value: "Green (KY)", label: "Green (KY)" },
  { value: "Greenup (KY)", label: "Greenup (KY)" },
  { value: "Hancock (KY)", label: "Hancock (KY)" },
  { value: "Hardin (KY)", label: "Hardin (KY)" },
  { value: "Harlan (KY)", label: "Harlan (KY)" },
  { value: "Harrison (KY)", label: "Harrison (KY)" },
  { value: "Hart (KY)", label: "Hart (KY)" },
  { value: "Henderson (KY)", label: "Henderson (KY)" },
  { value: "Henry (KY)", label: "Henry (KY)" },
  { value: "Hickman (KY)", label: "Hickman (KY)" },
  { value: "Hopkins (KY)", label: "Hopkins (KY)" },
  { value: "Jackson (KY)", label: "Jackson (KY)" },
  { value: "Jefferson (KY)", label: "Jefferson (KY)" },
  { value: "Jessamine (KY)", label: "Jessamine (KY)" },
  { value: "Johnson (KY)", label: "Johnson (KY)" },
  { value: "Kenton (KY)", label: "Kenton (KY)" },
  { value: "Knott (KY)", label: "Knott (KY)" },
  { value: "Knox (KY)", label: "Knox (KY)" },
  { value: "Larue (KY)", label: "Larue (KY)" },
  { value: "Laurel (KY)", label: "Laurel (KY)" },
  { value: "Lawrence (KY)", label: "Lawrence (KY)" },
  { value: "Lee (KY)", label: "Lee (KY)" },
  { value: "Leslie (KY)", label: "Leslie (KY)" },
  { value: "Letcher (KY)", label: "Letcher (KY)" },
  { value: "Lewis (KY)", label: "Lewis (KY)" },
  { value: "Lincoln (KY)", label: "Lincoln (KY)" },
  { value: "Livingston (KY)", label: "Livingston (KY)" },
  { value: "Logan (KY)", label: "Logan (KY)" },
  { value: "Lyon (KY)", label: "Lyon (KY)" },
  { value: "McCracken (KY)", label: "McCracken (KY)" },
  { value: "McCreary (KY)", label: "McCreary (KY)" },
  { value: "McLean (KY)", label: "McLean (KY)" },
  { value: "Madison (KY)", label: "Madison (KY)" },
  { value: "Magoffin (KY)", label: "Magoffin (KY)" },
  { value: "Marion (KY)", label: "Marion (KY)" },
  { value: "Marshall (KY)", label: "Marshall (KY)" },
  { value: "Martin (KY)", label: "Martin (KY)" },
  { value: "Mason (KY)", label: "Mason (KY)" },
  { value: "Meade (KY)", label: "Meade (KY)" },
  { value: "Menifee (KY)", label: "Menifee (KY)" },
  { value: "Mercer (KY)", label: "Mercer (KY)" },
  { value: "Metcalfe (KY)", label: "Metcalfe (KY)" },
  { value: "Monroe (KY)", label: "Monroe (KY)" },
  { value: "Montgomery (KY)", label: "Montgomery (KY)" },
  { value: "Morgan (KY)", label: "Morgan (KY)" },
  { value: "Muhlenberg (KY)", label: "Muhlenberg (KY)" },
  { value: "Nelson (KY)", label: "Nelson (KY)" },
  { value: "Nicholas (KY)", label: "Nicholas (KY)" },
  { value: "Ohio (KY)", label: "Ohio (KY)" },
  { value: "Oldham (KY)", label: "Oldham (KY)" },
  { value: "Owen (KY)", label: "Owen (KY)" },
  { value: "Owsley (KY)", label: "Owsley (KY)" },
  { value: "Pendleton (KY)", label: "Pendleton (KY)" },
  { value: "Perry (KY)", label: "Perry (KY)" },
  { value: "Pike (KY)", label: "Pike (KY)" },
  { value: "Powell (KY)", label: "Powell (KY)" },
  { value: "Pulaski (KY)", label: "Pulaski (KY)" },
  { value: "Robertson (KY)", label: "Robertson (KY)" },
  { value: "Rockcastle (KY)", label: "Rockcastle (KY)" },
  { value: "Rowan (KY)", label: "Rowan (KY)" },
  { value: "Russell (KY)", label: "Russell (KY)" },
  { value: "Scott (KY)", label: "Scott (KY)" },
  { value: "Shelby (KY)", label: "Shelby (KY)" },
  { value: "Simpson (KY)", label: "Simpson (KY)" },
  { value: "Spencer (KY)", label: "Spencer (KY)" },
  { value: "Taylor (KY)", label: "Taylor (KY)" },
  { value: "Todd (KY)", label: "Todd (KY)" },
  { value: "Trigg (KY)", label: "Trigg (KY)" },
  { value: "Trimble (KY)", label: "Trimble (KY)" },
  { value: "Union (KY)", label: "Union (KY)" },
  { value: "Warren (KY)", label: "Warren (KY)" },
  { value: "Washington (KY)", label: "Washington (KY)" },
  { value: "Wayne (KY)", label: "Wayne (KY)" },
  { value: "Webster (KY)", label: "Webster (KY)" },
  { value: "Whitley (KY)", label: "Whitley (KY)" },
  { value: "Wolfe (KY)", label: "Wolfe (KY)" },
  { value: "Woodford (KY)", label: "Woodford (KY)" },
  { value: "Acadia Parish (LA)", label: "Acadia Parish (LA)" },
  { value: "Allen Parish (LA)", label: "Allen Parish (LA)" },
  { value: "Ascension Parish (LA)", label: "Ascension Parish (LA)" },
  { value: "Assumption Parish (LA)", label: "Assumption Parish (LA)" },
  { value: "Avoyelles Parish (LA)", label: "Avoyelles Parish (LA)" },
  { value: "Beauregard Parish (LA)", label: "Beauregard Parish (LA)" },
  { value: "Bienville Parish (LA)", label: "Bienville Parish (LA)" },
  { value: "Bossier Parish (LA)", label: "Bossier Parish (LA)" },
  { value: "Caddo Parish (LA)", label: "Caddo Parish (LA)" },
  { value: "Calcasieu Parish (LA)", label: "Calcasieu Parish (LA)" },
  { value: "Caldwell Parish (LA)", label: "Caldwell Parish (LA)" },
  { value: "Cameron Parish (LA)", label: "Cameron Parish (LA)" },
  { value: "Catahoula Parish (LA)", label: "Catahoula Parish (LA)" },
  { value: "Claiborne Parish (LA)", label: "Claiborne Parish (LA)" },
  { value: "Concordia Parish (LA)", label: "Concordia Parish (LA)" },
  { value: "De Soto Parish (LA)", label: "De Soto Parish (LA)" },
  {
    value: "East Baton Rouge Parish (LA)",
    label: "East Baton Rouge Parish (LA)",
  },
  { value: "East Carroll Parish (LA)", label: "East Carroll Parish (LA)" },
  { value: "East Feliciana Parish (LA)", label: "East Feliciana Parish (LA)" },
  { value: "Evangeline Parish (LA)", label: "Evangeline Parish (LA)" },
  { value: "Franklin Parish (LA)", label: "Franklin Parish (LA)" },
  { value: "Grant Parish (LA)", label: "Grant Parish (LA)" },
  { value: "Iberia Parish (LA)", label: "Iberia Parish (LA)" },
  { value: "Iberville Parish (LA)", label: "Iberville Parish (LA)" },
  { value: "Jackson Parish (LA)", label: "Jackson Parish (LA)" },
  { value: "Jefferson Parish (LA)", label: "Jefferson Parish (LA)" },
  {
    value: "Jefferson Davis Parish (LA)",
    label: "Jefferson Davis Parish (LA)",
  },
  { value: "Lafayette Parish (LA)", label: "Lafayette Parish (LA)" },
  { value: "Lafourche Parish (LA)", label: "Lafourche Parish (LA)" },
  { value: "La Salle Parish (LA)", label: "La Salle Parish (LA)" },
  { value: "Lincoln Parish (LA)", label: "Lincoln Parish (LA)" },
  { value: "Livingston Parish (LA)", label: "Livingston Parish (LA)" },
  { value: "Madison Parish (LA)", label: "Madison Parish (LA)" },
  { value: "Morehouse Parish (LA)", label: "Morehouse Parish (LA)" },
  { value: "Natchitoches Parish (LA)", label: "Natchitoches Parish (LA)" },
  { value: "Orleans Parish (LA)", label: "Orleans Parish (LA)" },
  { value: "Ouachita Parish (LA)", label: "Ouachita Parish (LA)" },
  { value: "Plaquemines Parish (LA)", label: "Plaquemines Parish (LA)" },
  { value: "Pointe Coupee Parish (LA)", label: "Pointe Coupee Parish (LA)" },
  { value: "Rapides Parish (LA)", label: "Rapides Parish (LA)" },
  { value: "Red River Parish (LA)", label: "Red River Parish (LA)" },
  { value: "Richland Parish (LA)", label: "Richland Parish (LA)" },
  { value: "Sabine Parish (LA)", label: "Sabine Parish (LA)" },
  { value: "St. Bernard Parish (LA)", label: "St. Bernard Parish (LA)" },
  { value: "St. Charles Parish (LA)", label: "St. Charles Parish (LA)" },
  { value: "St. Helena Parish (LA)", label: "St. Helena Parish (LA)" },
  { value: "St. James Parish (LA)", label: "St. James Parish (LA)" },
  {
    value: "St. John the Baptist Parish (LA)",
    label: "St. John the Baptist Parish (LA)",
  },
  { value: "St. Landry Parish (LA)", label: "St. Landry Parish (LA)" },
  { value: "St. Martin Parish (LA)", label: "St. Martin Parish (LA)" },
  { value: "St. Mary Parish (LA)", label: "St. Mary Parish (LA)" },
  { value: "St. Tammany Parish (LA)", label: "St. Tammany Parish (LA)" },
  { value: "Tangipahoa Parish (LA)", label: "Tangipahoa Parish (LA)" },
  { value: "Tensas Parish (LA)", label: "Tensas Parish (LA)" },
  { value: "Terrebonne Parish (LA)", label: "Terrebonne Parish (LA)" },
  { value: "Union Parish (LA)", label: "Union Parish (LA)" },
  { value: "Vermilion Parish (LA)", label: "Vermilion Parish (LA)" },
  { value: "Vernon Parish (LA)", label: "Vernon Parish (LA)" },
  { value: "Washington Parish (LA)", label: "Washington Parish (LA)" },
  { value: "Webster Parish (LA)", label: "Webster Parish (LA)" },
  {
    value: "West Baton Rouge Parish (LA)",
    label: "West Baton Rouge Parish (LA)",
  },
  { value: "West Carroll Parish (LA)", label: "West Carroll Parish (LA)" },
  { value: "West Feliciana Parish (LA)", label: "West Feliciana Parish (LA)" },
  { value: "Winn Parish (LA)", label: "Winn Parish (LA)" },
  { value: "Androscoggin (ME)", label: "Androscoggin (ME)" },
  { value: "Aroostook (ME)", label: "Aroostook (ME)" },
  { value: "Cumberland (ME)", label: "Cumberland (ME)" },
  { value: "Franklin (ME)", label: "Franklin (ME)" },
  { value: "Hancock (ME)", label: "Hancock (ME)" },
  { value: "Kennebec (ME)", label: "Kennebec (ME)" },
  { value: "Knox (ME)", label: "Knox (ME)" },
  { value: "Lincoln (ME)", label: "Lincoln (ME)" },
  { value: "Oxford (ME)", label: "Oxford (ME)" },
  { value: "Penobscot (ME)", label: "Penobscot (ME)" },
  { value: "Piscataquis (ME)", label: "Piscataquis (ME)" },
  { value: "Sagadahoc (ME)", label: "Sagadahoc (ME)" },
  { value: "Somerset (ME)", label: "Somerset (ME)" },
  { value: "Waldo (ME)", label: "Waldo (ME)" },
  { value: "Washington (ME)", label: "Washington (ME)" },
  { value: "York (ME)", label: "York (ME)" },
  { value: "Allegany (MD)", label: "Allegany (MD)" },
  { value: "Anne Arundel (MD)", label: "Anne Arundel (MD)" },
  { value: "Baltimore (MD)", label: "Baltimore (MD)" },
  { value: "Calvert (MD)", label: "Calvert (MD)" },
  { value: "Caroline (MD)", label: "Caroline (MD)" },
  { value: "Carroll (MD)", label: "Carroll (MD)" },
  { value: "Cecil (MD)", label: "Cecil (MD)" },
  { value: "Charles (MD)", label: "Charles (MD)" },
  { value: "Dorchester (MD)", label: "Dorchester (MD)" },
  { value: "Frederick (MD)", label: "Frederick (MD)" },
  { value: "Garrett (MD)", label: "Garrett (MD)" },
  { value: "Harford (MD)", label: "Harford (MD)" },
  { value: "Howard (MD)", label: "Howard (MD)" },
  { value: "Kent (MD)", label: "Kent (MD)" },
  { value: "Montgomery (MD)", label: "Montgomery (MD)" },
  { value: "Prince George's(md)", label: "Prince George's (MD)" },
  { value: "Queen Anne's (md)", label: "Queen Anne's (MD)" },
  { value: "St. Mary (md)", label: "St. Mary's (MD)" },
  { value: "Somerset (MD)", label: "Somerset (MD)" },
  { value: "Talbot (MD)", label: "Talbot (MD)" },
  { value: "Washington (MD)", label: "Washington (MD)" },
  { value: "Wicomico (MD)", label: "Wicomico (MD)" },
  { value: "Worcester (MD)", label: "Worcester (MD)" },
  { value: "Baltimore city (MD)", label: "Baltimore city (MD)" },
  { value: "Barnstable (MA)", label: "Barnstable (MA)" },
  { value: "Berkshire (MA)", label: "Berkshire (MA)" },
  { value: "Bristol (MA)", label: "Bristol (MA)" },
  { value: "Dukes (MA)", label: "Dukes (MA)" },
  { value: "Essex (MA)", label: "Essex (MA)" },
  { value: "Franklin (MA)", label: "Franklin (MA)" },
  { value: "Hampden (MA)", label: "Hampden (MA)" },
  { value: "Hampshire (MA)", label: "Hampshire (MA)" },
  { value: "Middlesex (MA)", label: "Middlesex (MA)" },
  { value: "Nantucket (MA)", label: "Nantucket (MA)" },
  { value: "Norfolk (MA)", label: "Norfolk (MA)" },
  { value: "Plymouth (MA)", label: "Plymouth (MA)" },
  { value: "Suffolk (MA)", label: "Suffolk (MA)" },
  { value: "Worcester (MA)", label: "Worcester (MA)" },
  { value: "Alcona (MI)", label: "Alcona (MI)" },
  { value: "Alger (MI)", label: "Alger (MI)" },
  { value: "Allegan (MI)", label: "Allegan (MI)" },
  { value: "Alpena (MI)", label: "Alpena (MI)" },
  { value: "Antrim (MI)", label: "Antrim (MI)" },
  { value: "Arenac (MI)", label: "Arenac (MI)" },
  { value: "Baraga (MI)", label: "Baraga (MI)" },
  { value: "Barry (MI)", label: "Barry (MI)" },
  { value: "Bay (MI)", label: "Bay (MI)" },
  { value: "Benzie (MI)", label: "Benzie (MI)" },
  { value: "Berrien (MI)", label: "Berrien (MI)" },
  { value: "Branch (MI)", label: "Branch (MI)" },
  { value: "Calhoun (MI)", label: "Calhoun (MI)" },
  { value: "Cass (MI)", label: "Cass (MI)" },
  { value: "Charlevoix (MI)", label: "Charlevoix (MI)" },
  { value: "Cheboygan (MI)", label: "Cheboygan (MI)" },
  { value: "Chippewa (MI)", label: "Chippewa (MI)" },
  { value: "Clare (MI)", label: "Clare (MI)" },
  { value: "Clinton (MI)", label: "Clinton (MI)" },
  { value: "Crawford (MI)", label: "Crawford (MI)" },
  { value: "Delta (MI)", label: "Delta (MI)" },
  { value: "Dickinson (MI)", label: "Dickinson (MI)" },
  { value: "Eaton (MI)", label: "Eaton (MI)" },
  { value: "Emmet (MI)", label: "Emmet (MI)" },
  { value: "Genesee (MI)", label: "Genesee (MI)" },
  { value: "Gladwin (MI)", label: "Gladwin (MI)" },
  { value: "Gogebic (MI)", label: "Gogebic (MI)" },
  { value: "Grand Traverse (MI)", label: "Grand Traverse (MI)" },
  { value: "Gratiot (MI)", label: "Gratiot (MI)" },
  { value: "Hillsdale (MI)", label: "Hillsdale (MI)" },
  { value: "Houghton (MI)", label: "Houghton (MI)" },
  { value: "Huron (MI)", label: "Huron (MI)" },
  { value: "Ingham (MI)", label: "Ingham (MI)" },
  { value: "Ionia (MI)", label: "Ionia (MI)" },
  { value: "Iosco (MI)", label: "Iosco (MI)" },
  { value: "Iron (MI)", label: "Iron (MI)" },
  { value: "Isabella (MI)", label: "Isabella (MI)" },
  { value: "Jackson (MI)", label: "Jackson (MI)" },
  { value: "Kalamazoo (MI)", label: "Kalamazoo (MI)" },
  { value: "Kalkaska (MI)", label: "Kalkaska (MI)" },
  { value: "Kent (MI)", label: "Kent (MI)" },
  { value: "Keweenaw (MI)", label: "Keweenaw (MI)" },
  { value: "Lake (MI)", label: "Lake (MI)" },
  { value: "Lapeer (MI)", label: "Lapeer (MI)" },
  { value: "Leelanau (MI)", label: "Leelanau (MI)" },
  { value: "Lenawee (MI)", label: "Lenawee (MI)" },
  { value: "Livingston (MI)", label: "Livingston (MI)" },
  { value: "Luce (MI)", label: "Luce (MI)" },
  { value: "Mackinac (MI)", label: "Mackinac (MI)" },
  { value: "Macomb (MI)", label: "Macomb (MI)" },
  { value: "Manistee (MI)", label: "Manistee (MI)" },
  { value: "Marquette (MI)", label: "Marquette (MI)" },
  { value: "Mason (MI)", label: "Mason (MI)" },
  { value: "Mecosta (MI)", label: "Mecosta (MI)" },
  { value: "Menominee (MI)", label: "Menominee (MI)" },
  { value: "Midland (MI)", label: "Midland (MI)" },
  { value: "Missaukee (MI)", label: "Missaukee (MI)" },
  { value: "Monroe (MI)", label: "Monroe (MI)" },
  { value: "Montcalm (MI)", label: "Montcalm (MI)" },
  { value: "Montmorency (MI)", label: "Montmorency (MI)" },
  { value: "Muskegon (MI)", label: "Muskegon (MI)" },
  { value: "Newaygo (MI)", label: "Newaygo (MI)" },
  { value: "Oakland (MI)", label: "Oakland (MI)" },
  { value: "Oceana (MI)", label: "Oceana (MI)" },
  { value: "Ogemaw (MI)", label: "Ogemaw (MI)" },
  { value: "Ontonagon (MI)", label: "Ontonagon (MI)" },
  { value: "Osceola (MI)", label: "Osceola (MI)" },
  { value: "Oscoda (MI)", label: "Oscoda (MI)" },
  { value: "Otsego (MI)", label: "Otsego (MI)" },
  { value: "Ottawa (MI)", label: "Ottawa (MI)" },
  { value: "Presque Isle (MI)", label: "Presque Isle (MI)" },
  { value: "Roscommon (MI)", label: "Roscommon (MI)" },
  { value: "Saginaw (MI)", label: "Saginaw (MI)" },
  { value: "St. Clair (MI)", label: "St. Clair (MI)" },
  { value: "St. Joseph (MI)", label: "St. Joseph (MI)" },
  { value: "Sanilac (MI)", label: "Sanilac (MI)" },
  { value: "Schoolcraft (MI)", label: "Schoolcraft (MI)" },
  { value: "Shiawassee (MI)", label: "Shiawassee (MI)" },
  { value: "Tuscola (MI)", label: "Tuscola (MI)" },
  { value: "Van Buren (MI)", label: "Van Buren (MI)" },
  { value: "Washtenaw (MI)", label: "Washtenaw (MI)" },
  { value: "Wayne (MI)", label: "Wayne (MI)" },
  { value: "Wexford (MI)", label: "Wexford (MI)" },
  { value: "Aitkin (MN)", label: "Aitkin (MN)" },
  { value: "Anoka (MN)", label: "Anoka (MN)" },
  { value: "Becker (MN)", label: "Becker (MN)" },
  { value: "Beltrami (MN)", label: "Beltrami (MN)" },
  { value: "Benton (MN)", label: "Benton (MN)" },
  { value: "Big Stone (MN)", label: "Big Stone (MN)" },
  { value: "Blue Earth (MN)", label: "Blue Earth (MN)" },
  { value: "Brown (MN)", label: "Brown (MN)" },
  { value: "Carlton (MN)", label: "Carlton (MN)" },
  { value: "Carver (MN)", label: "Carver (MN)" },
  { value: "Cass (MN)", label: "Cass (MN)" },
  { value: "Chippewa (MN)", label: "Chippewa (MN)" },
  { value: "Chisago (MN)", label: "Chisago (MN)" },
  { value: "Clay (MN)", label: "Clay (MN)" },
  { value: "Clearwater (MN)", label: "Clearwater (MN)" },
  { value: "Cook (MN)", label: "Cook (MN)" },
  { value: "Cottonwood (MN)", label: "Cottonwood (MN)" },
  { value: "Crow Wing (MN)", label: "Crow Wing (MN)" },
  { value: "Dakota (MN)", label: "Dakota (MN)" },
  { value: "Dodge (MN)", label: "Dodge (MN)" },
  { value: "Douglas (MN)", label: "Douglas (MN)" },
  { value: "Faribault (MN)", label: "Faribault (MN)" },
  { value: "Fillmore (MN)", label: "Fillmore (MN)" },
  { value: "Freeborn (MN)", label: "Freeborn (MN)" },
  { value: "Goodhue (MN)", label: "Goodhue (MN)" },
  { value: "Grant (MN)", label: "Grant (MN)" },
  { value: "Hennepin (MN)", label: "Hennepin (MN)" },
  { value: "Houston (MN)", label: "Houston (MN)" },
  { value: "Hubbard (MN)", label: "Hubbard (MN)" },
  { value: "Isanti (MN)", label: "Isanti (MN)" },
  { value: "Itasca (MN)", label: "Itasca (MN)" },
  { value: "Jackson (MN)", label: "Jackson (MN)" },
  { value: "Kanabec (MN)", label: "Kanabec (MN)" },
  { value: "Kandiyohi (MN)", label: "Kandiyohi (MN)" },
  { value: "Kittson (MN)", label: "Kittson (MN)" },
  { value: "Koochiching (MN)", label: "Koochiching (MN)" },
  { value: "Lac qui Parle (MN)", label: "Lac qui Parle (MN)" },
  { value: "Lake (MN)", label: "Lake (MN)" },
  { value: "Lake of the Woods (MN)", label: "Lake of the Woods (MN)" },
  { value: "Le Sueur (MN)", label: "Le Sueur (MN)" },
  { value: "Lincoln (MN)", label: "Lincoln (MN)" },
  { value: "Lyon (MN)", label: "Lyon (MN)" },
  { value: "McLeod (MN)", label: "McLeod (MN)" },
  { value: "Mahnomen (MN)", label: "Mahnomen (MN)" },
  { value: "Marshall (MN)", label: "Marshall (MN)" },
  { value: "Martin (MN)", label: "Martin (MN)" },
  { value: "Meeker (MN)", label: "Meeker (MN)" },
  { value: "Mille Lacs (MN)", label: "Mille Lacs (MN)" },
  { value: "Morrison (MN)", label: "Morrison (MN)" },
  { value: "Mower (MN)", label: "Mower (MN)" },
  { value: "Murray (MN)", label: "Murray (MN)" },
  { value: "Nicollet (MN)", label: "Nicollet (MN)" },
  { value: "Nobles (MN)", label: "Nobles (MN)" },
  { value: "Norman (MN)", label: "Norman (MN)" },
  { value: "Olmsted (MN)", label: "Olmsted (MN)" },
  { value: "Otter Tail (MN)", label: "Otter Tail (MN)" },
  { value: "Pennington (MN)", label: "Pennington (MN)" },
  { value: "Pine (MN)", label: "Pine (MN)" },
  { value: "Pipestone (MN)", label: "Pipestone (MN)" },
  { value: "Polk (MN)", label: "Polk (MN)" },
  { value: "Pope (MN)", label: "Pope (MN)" },
  { value: "Ramsey (MN)", label: "Ramsey (MN)" },
  { value: "Red Lake (MN)", label: "Red Lake (MN)" },
  { value: "Redwood (MN)", label: "Redwood (MN)" },
  { value: "Renville (MN)", label: "Renville (MN)" },
  { value: "Rice (MN)", label: "Rice (MN)" },
  { value: "Rock (MN)", label: "Rock (MN)" },
  { value: "Roseau (MN)", label: "Roseau (MN)" },
  { value: "St. Louis (MN)", label: "St. Louis (MN)" },
  { value: "Scott (MN)", label: "Scott (MN)" },
  { value: "Sherburne (MN)", label: "Sherburne (MN)" },
  { value: "Sibley (MN)", label: "Sibley (MN)" },
  { value: "Stearns (MN)", label: "Stearns (MN)" },
  { value: "Steele (MN)", label: "Steele (MN)" },
  { value: "Stevens (MN)", label: "Stevens (MN)" },
  { value: "Swift (MN)", label: "Swift (MN)" },
  { value: "Todd (MN)", label: "Todd (MN)" },
  { value: "Traverse (MN)", label: "Traverse (MN)" },
  { value: "Wabasha (MN)", label: "Wabasha (MN)" },
  { value: "Wadena (MN)", label: "Wadena (MN)" },
  { value: "Waseca (MN)", label: "Waseca (MN)" },
  { value: "Washington (MN)", label: "Washington (MN)" },
  { value: "Watonwan (MN)", label: "Watonwan (MN)" },
  { value: "Wilkin (MN)", label: "Wilkin (MN)" },
  { value: "Winona (MN)", label: "Winona (MN)" },
  { value: "Wright (MN)", label: "Wright (MN)" },
  { value: "Yellow Medicine (MN)", label: "Yellow Medicine (MN)" },
  { value: "Adams (MS)", label: "Adams (MS)" },
  { value: "Alcorn (MS)", label: "Alcorn (MS)" },
  { value: "Amite (MS)", label: "Amite (MS)" },
  { value: "Attala (MS)", label: "Attala (MS)" },
  { value: "Benton (MS)", label: "Benton (MS)" },
  { value: "Bolivar (MS)", label: "Bolivar (MS)" },
  { value: "Calhoun (MS)", label: "Calhoun (MS)" },
  { value: "Carroll (MS)", label: "Carroll (MS)" },
  { value: "Chickasaw (MS)", label: "Chickasaw (MS)" },
  { value: "Choctaw (MS)", label: "Choctaw (MS)" },
  { value: "Claiborne (MS)", label: "Claiborne (MS)" },
  { value: "Clarke (MS)", label: "Clarke (MS)" },
  { value: "Clay (MS)", label: "Clay (MS)" },
  { value: "Coahoma (MS)", label: "Coahoma (MS)" },
  { value: "Copiah (MS)", label: "Copiah (MS)" },
  { value: "Covington (MS)", label: "Covington (MS)" },
  { value: "DeSoto (MS)", label: "DeSoto (MS)" },
  { value: "Forrest (MS)", label: "Forrest (MS)" },
  { value: "Franklin (MS)", label: "Franklin (MS)" },
  { value: "George (MS)", label: "George (MS)" },
  { value: "Greene (MS)", label: "Greene (MS)" },
  { value: "Grenada (MS)", label: "Grenada (MS)" },
  { value: "Hancock (MS)", label: "Hancock (MS)" },
  { value: "Harrison (MS)", label: "Harrison (MS)" },
  { value: "Hinds (MS)", label: "Hinds (MS)" },
  { value: "Holmes (MS)", label: "Holmes (MS)" },
  { value: "Humphreys (MS)", label: "Humphreys (MS)" },
  { value: "Issaquena (MS)", label: "Issaquena (MS)" },
  { value: "Itawamba (MS)", label: "Itawamba (MS)" },
  { value: "Jackson (MS)", label: "Jackson (MS)" },
  { value: "Jasper (MS)", label: "Jasper (MS)" },
  { value: "Jefferson (MS)", label: "Jefferson (MS)" },
  { value: "Jefferson Davis (MS)", label: "Jefferson Davis (MS)" },
  { value: "Jones (MS)", label: "Jones (MS)" },
  { value: "Kemper (MS)", label: "Kemper (MS)" },
  { value: "Lafayette (MS)", label: "Lafayette (MS)" },
  { value: "Lamar (MS)", label: "Lamar (MS)" },
  { value: "Lauderdale (MS)", label: "Lauderdale (MS)" },
  { value: "Lawrence (MS)", label: "Lawrence (MS)" },
  { value: "Leake (MS)", label: "Leake (MS)" },
  { value: "Lee (MS)", label: "Lee (MS)" },
  { value: "Leflore (MS)", label: "Leflore (MS)" },
  { value: "Lincoln (MS)", label: "Lincoln (MS)" },
  { value: "Lowndes (MS)", label: "Lowndes (MS)" },
  { value: "Madison (MS)", label: "Madison (MS)" },
  { value: "Marion (MS)", label: "Marion (MS)" },
  { value: "Marshall (MS)", label: "Marshall (MS)" },
  { value: "Monroe (MS)", label: "Monroe (MS)" },
  { value: "Montgomery (MS)", label: "Montgomery (MS)" },
  { value: "Neshoba (MS)", label: "Neshoba (MS)" },
  { value: "Newton (MS)", label: "Newton (MS)" },
  { value: "Noxubee (MS)", label: "Noxubee (MS)" },
  { value: "Oktibbeha (MS)", label: "Oktibbeha (MS)" },
  { value: "Panola (MS)", label: "Panola (MS)" },
  { value: "Pearl River (MS)", label: "Pearl River (MS)" },
  { value: "Perry (MS)", label: "Perry (MS)" },
  { value: "Pike (MS)", label: "Pike (MS)" },
  { value: "Pontotoc (MS)", label: "Pontotoc (MS)" },
  { value: "Prentiss (MS)", label: "Prentiss (MS)" },
  { value: "Quitman (MS)", label: "Quitman (MS)" },
  { value: "Rankin (MS)", label: "Rankin (MS)" },
  { value: "Scott (MS)", label: "Scott (MS)" },
  { value: "Sharkey (MS)", label: "Sharkey (MS)" },
  { value: "Simpson (MS)", label: "Simpson (MS)" },
  { value: "Smith (MS)", label: "Smith (MS)" },
  { value: "Stone (MS)", label: "Stone (MS)" },
  { value: "Sunflower (MS)", label: "Sunflower (MS)" },
  { value: "Tallahatchie (MS)", label: "Tallahatchie (MS)" },
  { value: "Tate (MS)", label: "Tate (MS)" },
  { value: "Tippah (MS)", label: "Tippah (MS)" },
  { value: "Tishomingo (MS)", label: "Tishomingo (MS)" },
  { value: "Tunica (MS)", label: "Tunica (MS)" },
  { value: "Union (MS)", label: "Union (MS)" },
  { value: "Walthall (MS)", label: "Walthall (MS)" },
  { value: "Warren (MS)", label: "Warren (MS)" },
  { value: "Washington (MS)", label: "Washington (MS)" },
  { value: "Wayne (MS)", label: "Wayne (MS)" },
  { value: "Webster (MS)", label: "Webster (MS)" },
  { value: "Wilkinson (MS)", label: "Wilkinson (MS)" },
  { value: "Winston (MS)", label: "Winston (MS)" },
  { value: "Yalobusha (MS)", label: "Yalobusha (MS)" },
  { value: "Yazoo (MS)", label: "Yazoo (MS)" },
  { value: "Adair (MO)", label: "Adair (MO)" },
  { value: "Andrew (MO)", label: "Andrew (MO)" },
  { value: "Atchison (MO)", label: "Atchison (MO)" },
  { value: "Audrain (MO)", label: "Audrain (MO)" },
  { value: "Barry (MO)", label: "Barry (MO)" },
  { value: "Barton (MO)", label: "Barton (MO)" },
  { value: "Bates (MO)", label: "Bates (MO)" },
  { value: "Benton (MO)", label: "Benton (MO)" },
  { value: "Bollinger (MO)", label: "Bollinger (MO)" },
  { value: "Boone (MO)", label: "Boone (MO)" },
  { value: "Buchanan (MO)", label: "Buchanan (MO)" },
  { value: "Butler (MO)", label: "Butler (MO)" },
  { value: "Caldwell (MO)", label: "Caldwell (MO)" },
  { value: "Callaway (MO)", label: "Callaway (MO)" },
  { value: "Camden (MO)", label: "Camden (MO)" },
  { value: "Cape Girardeau (MO)", label: "Cape Girardeau (MO)" },
  { value: "Carroll (MO)", label: "Carroll (MO)" },
  { value: "Carter (MO)", label: "Carter (MO)" },
  { value: "Cass (MO)", label: "Cass (MO)" },
  { value: "Cedar (MO)", label: "Cedar (MO)" },
  { value: "Chariton (MO)", label: "Chariton (MO)" },
  { value: "Christian (MO)", label: "Christian (MO)" },
  { value: "Clark (MO)", label: "Clark (MO)" },
  { value: "Clay (MO)", label: "Clay (MO)" },
  { value: "Clinton (MO)", label: "Clinton (MO)" },
  { value: "Cole (MO)", label: "Cole (MO)" },
  { value: "Cooper (MO)", label: "Cooper (MO)" },
  { value: "Crawford (MO)", label: "Crawford (MO)" },
  { value: "Dade (MO)", label: "Dade (MO)" },
  { value: "Dallas (MO)", label: "Dallas (MO)" },
  { value: "Daviess (MO)", label: "Daviess (MO)" },
  { value: "DeKalb (MO)", label: "DeKalb (MO)" },
  { value: "Dent (MO)", label: "Dent (MO)" },
  { value: "Douglas (MO)", label: "Douglas (MO)" },
  { value: "Dunklin (MO)", label: "Dunklin (MO)" },
  { value: "Franklin (MO)", label: "Franklin (MO)" },
  { value: "Gasconade (MO)", label: "Gasconade (MO)" },
  { value: "Gentry (MO)", label: "Gentry (MO)" },
  { value: "Greene (MO)", label: "Greene (MO)" },
  { value: "Grundy (MO)", label: "Grundy (MO)" },
  { value: "Harrison (MO)", label: "Harrison (MO)" },
  { value: "Henry (MO)", label: "Henry (MO)" },
  { value: "Hickory (MO)", label: "Hickory (MO)" },
  { value: "Holt (MO)", label: "Holt (MO)" },
  { value: "Howard (MO)", label: "Howard (MO)" },
  { value: "Howell (MO)", label: "Howell (MO)" },
  { value: "Iron (MO)", label: "Iron (MO)" },
  { value: "Jackson (MO)", label: "Jackson (MO)" },
  { value: "Jasper (MO)", label: "Jasper (MO)" },
  { value: "Jefferson (MO)", label: "Jefferson (MO)" },
  { value: "Johnson (MO)", label: "Johnson (MO)" },
  { value: "Knox (MO)", label: "Knox (MO)" },
  { value: "Laclede (MO)", label: "Laclede (MO)" },
  { value: "Lafayette (MO)", label: "Lafayette (MO)" },
  { value: "Lawrence (MO)", label: "Lawrence (MO)" },
  { value: "Lewis (MO)", label: "Lewis (MO)" },
  { value: "Lincoln (MO)", label: "Lincoln (MO)" },
  { value: "Linn (MO)", label: "Linn (MO)" },
  { value: "Livingston (MO)", label: "Livingston (MO)" },
  { value: "McDonald (MO)", label: "McDonald (MO)" },
  { value: "Macon (MO)", label: "Macon (MO)" },
  { value: "Madison (MO)", label: "Madison (MO)" },
  { value: "Maries (MO)", label: "Maries (MO)" },
  { value: "Marion (MO)", label: "Marion (MO)" },
  { value: "Mercer (MO)", label: "Mercer (MO)" },
  { value: "Miller (MO)", label: "Miller (MO)" },
  { value: "Mississippi (MO)", label: "Mississippi (MO)" },
  { value: "Moniteau (MO)", label: "Moniteau (MO)" },
  { value: "Monroe (MO)", label: "Monroe (MO)" },
  { value: "Montgomery (MO)", label: "Montgomery (MO)" },
  { value: "Morgan (MO)", label: "Morgan (MO)" },
  { value: "New Madrid (MO)", label: "New Madrid (MO)" },
  { value: "Newton (MO)", label: "Newton (MO)" },
  { value: "Nodaway (MO)", label: "Nodaway (MO)" },
  { value: "Oregon (MO)", label: "Oregon (MO)" },
  { value: "Osage (MO)", label: "Osage (MO)" },
  { value: "Ozark (MO)", label: "Ozark (MO)" },
  { value: "Pemiscot (MO)", label: "Pemiscot (MO)" },
  { value: "Perry (MO)", label: "Perry (MO)" },
  { value: "Pettis (MO)", label: "Pettis (MO)" },
  { value: "Phelps (MO)", label: "Phelps (MO)" },
  { value: "Pike (MO)", label: "Pike (MO)" },
  { value: "Platte (MO)", label: "Platte (MO)" },
  { value: "Polk (MO)", label: "Polk (MO)" },
  { value: "Pulaski (MO)", label: "Pulaski (MO)" },
  { value: "Putnam (MO)", label: "Putnam (MO)" },
  { value: "Ralls (MO)", label: "Ralls (MO)" },
  { value: "Randolph (MO)", label: "Randolph (MO)" },
  { value: "Ray (MO)", label: "Ray (MO)" },
  { value: "Reynolds (MO)", label: "Reynolds (MO)" },
  { value: "Ripley (MO)", label: "Ripley (MO)" },
  { value: "St. Charles (MO)", label: "St. Charles (MO)" },
  { value: "St. Clair (MO)", label: "St. Clair (MO)" },
  { value: "Ste. Genevieve (MO)", label: "Ste. Genevieve (MO)" },
  { value: "St. Francois (MO)", label: "St. Francois (MO)" },
  { value: "St. Louis (MO)", label: "St. Louis (MO)" },
  { value: "Saline (MO)", label: "Saline (MO)" },
  { value: "Schuyler (MO)", label: "Schuyler (MO)" },
  { value: "Scotland (MO)", label: "Scotland (MO)" },
  { value: "Scott (MO)", label: "Scott (MO)" },
  { value: "Shannon (MO)", label: "Shannon (MO)" },
  { value: "Shelby (MO)", label: "Shelby (MO)" },
  { value: "Stoddard (MO)", label: "Stoddard (MO)" },
  { value: "Stone (MO)", label: "Stone (MO)" },
  { value: "Sullivan (MO)", label: "Sullivan (MO)" },
  { value: "Taney (MO)", label: "Taney (MO)" },
  { value: "Texas (MO)", label: "Texas (MO)" },
  { value: "Vernon (MO)", label: "Vernon (MO)" },
  { value: "Warren (MO)", label: "Warren (MO)" },
  { value: "Washington (MO)", label: "Washington (MO)" },
  { value: "Wayne (MO)", label: "Wayne (MO)" },
  { value: "Webster (MO)", label: "Webster (MO)" },
  { value: "Worth (MO)", label: "Worth (MO)" },
  { value: "Wright (MO)", label: "Wright (MO)" },
  { value: "St. Louis city (MO)", label: "St. Louis city (MO)" },
  { value: "Beaverhead (MT)", label: "Beaverhead (MT)" },
  { value: "Big Horn (MT)", label: "Big Horn (MT)" },
  { value: "Blaine (MT)", label: "Blaine (MT)" },
  { value: "Broadwater (MT)", label: "Broadwater (MT)" },
  { value: "Carbon (MT)", label: "Carbon (MT)" },
  { value: "Carter (MT)", label: "Carter (MT)" },
  { value: "Cascade (MT)", label: "Cascade (MT)" },
  { value: "Chouteau (MT)", label: "Chouteau (MT)" },
  { value: "Custer (MT)", label: "Custer (MT)" },
  { value: "Daniels (MT)", label: "Daniels (MT)" },
  { value: "Dawson (MT)", label: "Dawson (MT)" },
  { value: "Deer Lodge (MT)", label: "Deer Lodge (MT)" },
  { value: "Fallon (MT)", label: "Fallon (MT)" },
  { value: "Fergus (MT)", label: "Fergus (MT)" },
  { value: "Flathead (MT)", label: "Flathead (MT)" },
  { value: "Gallatin (MT)", label: "Gallatin (MT)" },
  { value: "Garfield (MT)", label: "Garfield (MT)" },
  { value: "Glacier (MT)", label: "Glacier (MT)" },
  { value: "Golden Valley (MT)", label: "Golden Valley (MT)" },
  { value: "Granite (MT)", label: "Granite (MT)" },
  { value: "Hill (MT)", label: "Hill (MT)" },
  { value: "Jefferson (MT)", label: "Jefferson (MT)" },
  { value: "Judith Basin (MT)", label: "Judith Basin (MT)" },
  { value: "Lake (MT)", label: "Lake (MT)" },
  { value: "Lewis and Clark (MT)", label: "Lewis and Clark (MT)" },
  { value: "Liberty (MT)", label: "Liberty (MT)" },
  { value: "Lincoln (MT)", label: "Lincoln (MT)" },
  { value: "McCone (MT)", label: "McCone (MT)" },
  { value: "Madison (MT)", label: "Madison (MT)" },
  { value: "Meagher (MT)", label: "Meagher (MT)" },
  { value: "Mineral (MT)", label: "Mineral (MT)" },
  { value: "Missoula (MT)", label: "Missoula (MT)" },
  { value: "Musselshell (MT)", label: "Musselshell (MT)" },
  { value: "Park (MT)", label: "Park (MT)" },
  { value: "Petroleum (MT)", label: "Petroleum (MT)" },
  { value: "Phillips (MT)", label: "Phillips (MT)" },
  { value: "Pondera (MT)", label: "Pondera (MT)" },
  { value: "Powder River (MT)", label: "Powder River (MT)" },
  { value: "Powell (MT)", label: "Powell (MT)" },
  { value: "Prairie (MT)", label: "Prairie (MT)" },
  { value: "Ravalli (MT)", label: "Ravalli (MT)" },
  { value: "Richland (MT)", label: "Richland (MT)" },
  { value: "Roosevelt (MT)", label: "Roosevelt (MT)" },
  { value: "Rosebud (MT)", label: "Rosebud (MT)" },
  { value: "Sanders (MT)", label: "Sanders (MT)" },
  { value: "Sheridan (MT)", label: "Sheridan (MT)" },
  { value: "Silver Bow (MT)", label: "Silver Bow (MT)" },
  { value: "Stillwater (MT)", label: "Stillwater (MT)" },
  { value: "Sweet Grass (MT)", label: "Sweet Grass (MT)" },
  { value: "Teton (MT)", label: "Teton (MT)" },
  { value: "Toole (MT)", label: "Toole (MT)" },
  { value: "Treasure (MT)", label: "Treasure (MT)" },
  { value: "Valley (MT)", label: "Valley (MT)" },
  { value: "Wheatland (MT)", label: "Wheatland (MT)" },
  { value: "Wibaux (MT)", label: "Wibaux (MT)" },
  { value: "Yellowstone (MT)", label: "Yellowstone (MT)" },
  { value: "Adams (NE)", label: "Adams (NE)" },
  { value: "Antelope (NE)", label: "Antelope (NE)" },
  { value: "Arthur (NE)", label: "Arthur (NE)" },
  { value: "Banner (NE)", label: "Banner (NE)" },
  { value: "Blaine (NE)", label: "Blaine (NE)" },
  { value: "Boone (NE)", label: "Boone (NE)" },
  { value: "Box Butte (NE)", label: "Box Butte (NE)" },
  { value: "Boyd (NE)", label: "Boyd (NE)" },
  { value: "Brown (NE)", label: "Brown (NE)" },
  { value: "Buffalo (NE)", label: "Buffalo (NE)" },
  { value: "Burt (NE)", label: "Burt (NE)" },
  { value: "Butler (NE)", label: "Butler (NE)" },
  { value: "Cass (NE)", label: "Cass (NE)" },
  { value: "Cedar (NE)", label: "Cedar (NE)" },
  { value: "Chase (NE)", label: "Chase (NE)" },
  { value: "Cherry (NE)", label: "Cherry (NE)" },
  { value: "Cheyenne (NE)", label: "Cheyenne (NE)" },
  { value: "Clay (NE)", label: "Clay (NE)" },
  { value: "Colfax (NE)", label: "Colfax (NE)" },
  { value: "Cuming (NE)", label: "Cuming (NE)" },
  { value: "Custer (NE)", label: "Custer (NE)" },
  { value: "Dakota (NE)", label: "Dakota (NE)" },
  { value: "Dawes (NE)", label: "Dawes (NE)" },
  { value: "Dawson (NE)", label: "Dawson (NE)" },
  { value: "Deuel (NE)", label: "Deuel (NE)" },
  { value: "Dixon (NE)", label: "Dixon (NE)" },
  { value: "Dodge (NE)", label: "Dodge (NE)" },
  { value: "Douglas (NE)", label: "Douglas (NE)" },
  { value: "Dundy (NE)", label: "Dundy (NE)" },
  { value: "Fillmore (NE)", label: "Fillmore (NE)" },
  { value: "Franklin (NE)", label: "Franklin (NE)" },
  { value: "Frontier (NE)", label: "Frontier (NE)" },
  { value: "Furnas (NE)", label: "Furnas (NE)" },
  { value: "Gage (NE)", label: "Gage (NE)" },
  { value: "Garden (NE)", label: "Garden (NE)" },
  { value: "Garfield (NE)", label: "Garfield (NE)" },
  { value: "Gosper (NE)", label: "Gosper (NE)" },
  { value: "Grant (NE)", label: "Grant (NE)" },
  { value: "Greeley (NE)", label: "Greeley (NE)" },
  { value: "Hall (NE)", label: "Hall (NE)" },
  { value: "Hamilton (NE)", label: "Hamilton (NE)" },
  { value: "Harlan (NE)", label: "Harlan (NE)" },
  { value: "Hayes (NE)", label: "Hayes (NE)" },
  { value: "Hitchcock (NE)", label: "Hitchcock (NE)" },
  { value: "Holt (NE)", label: "Holt (NE)" },
  { value: "Hooker (NE)", label: "Hooker (NE)" },
  { value: "Howard (NE)", label: "Howard (NE)" },
  { value: "Jefferson (NE)", label: "Jefferson (NE)" },
  { value: "Johnson (NE)", label: "Johnson (NE)" },
  { value: "Kearney (NE)", label: "Kearney (NE)" },
  { value: "Keith (NE)", label: "Keith (NE)" },
  { value: "Keya Paha (NE)", label: "Keya Paha (NE)" },
  { value: "Kimball (NE)", label: "Kimball (NE)" },
  { value: "Knox (NE)", label: "Knox (NE)" },
  { value: "Lancaster (NE)", label: "Lancaster (NE)" },
  { value: "Lincoln (NE)", label: "Lincoln (NE)" },
  { value: "Logan (NE)", label: "Logan (NE)" },
  { value: "Loup (NE)", label: "Loup (NE)" },
  { value: "McPherson (NE)", label: "McPherson (NE)" },
  { value: "Madison (NE)", label: "Madison (NE)" },
  { value: "Merrick (NE)", label: "Merrick (NE)" },
  { value: "Morrill (NE)", label: "Morrill (NE)" },
  { value: "Nance (NE)", label: "Nance (NE)" },
  { value: "Nemaha (NE)", label: "Nemaha (NE)" },
  { value: "Nuckolls (NE)", label: "Nuckolls (NE)" },
  { value: "Otoe (NE)", label: "Otoe (NE)" },
  { value: "Pawnee (NE)", label: "Pawnee (NE)" },
  { value: "Perkins (NE)", label: "Perkins (NE)" },
  { value: "Phelps (NE)", label: "Phelps (NE)" },
  { value: "Pierce (NE)", label: "Pierce (NE)" },
  { value: "Platte (NE)", label: "Platte (NE)" },
  { value: "Polk (NE)", label: "Polk (NE)" },
  { value: "Red Willow (NE)", label: "Red Willow (NE)" },
  { value: "Richardson (NE)", label: "Richardson (NE)" },
  { value: "Rock (NE)", label: "Rock (NE)" },
  { value: "Saline (NE)", label: "Saline (NE)" },
  { value: "Sarpy (NE)", label: "Sarpy (NE)" },
  { value: "Saunders (NE)", label: "Saunders (NE)" },
  { value: "Scotts Bluff (NE)", label: "Scotts Bluff (NE)" },
  { value: "Seward (NE)", label: "Seward (NE)" },
  { value: "Sheridan (NE)", label: "Sheridan (NE)" },
  { value: "Sherman (NE)", label: "Sherman (NE)" },
  { value: "Sioux (NE)", label: "Sioux (NE)" },
  { value: "Stanton (NE)", label: "Stanton (NE)" },
  { value: "Thayer (NE)", label: "Thayer (NE)" },
  { value: "Thomas (NE)", label: "Thomas (NE)" },
  { value: "Thurston (NE)", label: "Thurston (NE)" },
  { value: "Valley (NE)", label: "Valley (NE)" },
  { value: "Washington (NE)", label: "Washington (NE)" },
  { value: "Wayne (NE)", label: "Wayne (NE)" },
  { value: "Webster (NE)", label: "Webster (NE)" },
  { value: "Wheeler (NE)", label: "Wheeler (NE)" },
  { value: "York (NE)", label: "York (NE)" },
  { value: "Churchill (NV)", label: "Churchill (NV)" },
  { value: "Clark (NV)", label: "Clark (NV)" },
  { value: "Douglas (NV)", label: "Douglas (NV)" },
  { value: "Elko (NV)", label: "Elko (NV)" },
  { value: "Esmeralda (NV)", label: "Esmeralda (NV)" },
  { value: "Eureka (NV)", label: "Eureka (NV)" },
  { value: "Humboldt (NV)", label: "Humboldt (NV)" },
  { value: "Lander (NV)", label: "Lander (NV)" },
  { value: "Lincoln (NV)", label: "Lincoln (NV)" },
  { value: "Lyon (NV)", label: "Lyon (NV)" },
  { value: "Mineral (NV)", label: "Mineral (NV)" },
  { value: "Nye (NV)", label: "Nye (NV)" },
  { value: "Pershing (NV)", label: "Pershing (NV)" },
  { value: "Storey (NV)", label: "Storey (NV)" },
  { value: "Washoe (NV)", label: "Washoe (NV)" },
  { value: "White Pine (NV)", label: "White Pine (NV)" },
  { value: "Carson City (NV)", label: "Carson City (NV)" },
  { value: "Belknap (NH)", label: "Belknap (NH)" },
  { value: "Carroll (NH)", label: "Carroll (NH)" },
  { value: "Cheshire (NH)", label: "Cheshire (NH)" },
  { value: "Coos (NH)", label: "Coos (NH)" },
  { value: "Grafton (NH)", label: "Grafton (NH)" },
  { value: "Hillsborough (NH)", label: "Hillsborough (NH)" },
  { value: "Merrimack (NH)", label: "Merrimack (NH)" },
  { value: "Rockingham (NH)", label: "Rockingham (NH)" },
  { value: "Strafford (NH)", label: "Strafford (NH)" },
  { value: "Sullivan (NH)", label: "Sullivan (NH)" },
  { value: "Atlantic (NJ)", label: "Atlantic (NJ)" },
  { value: "Bergen (NJ)", label: "Bergen (NJ)" },
  { value: "Burlington (NJ)", label: "Burlington (NJ)" },
  { value: "Camden (NJ)", label: "Camden (NJ)" },
  { value: "Cape May (NJ)", label: "Cape May (NJ)" },
  { value: "Cumberland (NJ)", label: "Cumberland (NJ)" },
  { value: "Essex (NJ)", label: "Essex (NJ)" },
  { value: "Gloucester (NJ)", label: "Gloucester (NJ)" },
  { value: "Hudson (NJ)", label: "Hudson (NJ)" },
  { value: "Hunterdon (NJ)", label: "Hunterdon (NJ)" },
  { value: "Mercer (NJ)", label: "Mercer (NJ)" },
  { value: "Middlesex (NJ)", label: "Middlesex (NJ)" },
  { value: "Monmouth (NJ)", label: "Monmouth (NJ)" },
  { value: "Morris (NJ)", label: "Morris (NJ)" },
  { value: "Ocean (NJ)", label: "Ocean (NJ)" },
  { value: "Passaic (NJ)", label: "Passaic (NJ)" },
  { value: "Salem (NJ)", label: "Salem (NJ)" },
  { value: "Somerset (NJ)", label: "Somerset (NJ)" },
  { value: "Sussex (NJ)", label: "Sussex (NJ)" },
  { value: "Union (NJ)", label: "Union (NJ)" },
  { value: "Warren (NJ)", label: "Warren (NJ)" },
  { value: "Bernalillo (NM)", label: "Bernalillo (NM)" },
  { value: "Catron (NM)", label: "Catron (NM)" },
  { value: "Chaves (NM)", label: "Chaves (NM)" },
  { value: "Cibola (NM)", label: "Cibola (NM)" },
  { value: "Colfax (NM)", label: "Colfax (NM)" },
  { value: "Curry (NM)", label: "Curry (NM)" },
  { value: "De Baca (NM)", label: "De Baca (NM)" },
  { value: "Dona Ana (NM)", label: "Dona Ana (NM)" },
  { value: "Eddy (NM)", label: "Eddy (NM)" },
  { value: "Grant (NM)", label: "Grant (NM)" },
  { value: "Guadalupe (NM)", label: "Guadalupe (NM)" },
  { value: "Harding (NM)", label: "Harding (NM)" },
  { value: "Hidalgo (NM)", label: "Hidalgo (NM)" },
  { value: "Lea (NM)", label: "Lea (NM)" },
  { value: "Lincoln (NM)", label: "Lincoln (NM)" },
  { value: "Los Alamos (NM)", label: "Los Alamos (NM)" },
  { value: "Luna (NM)", label: "Luna (NM)" },
  { value: "McKinley (NM)", label: "McKinley (NM)" },
  { value: "Mora (NM)", label: "Mora (NM)" },
  { value: "Otero (NM)", label: "Otero (NM)" },
  { value: "Quay (NM)", label: "Quay (NM)" },
  { value: "Rio Arriba (NM)", label: "Rio Arriba (NM)" },
  { value: "Roosevelt (NM)", label: "Roosevelt (NM)" },
  { value: "Sandoval (NM)", label: "Sandoval (NM)" },
  { value: "San Juan (NM)", label: "San Juan (NM)" },
  { value: "San Miguel (NM)", label: "San Miguel (NM)" },
  { value: "Santa Fe (NM)", label: "Santa Fe (NM)" },
  { value: "Sierra (NM)", label: "Sierra (NM)" },
  { value: "Socorro (NM)", label: "Socorro (NM)" },
  { value: "Taos (NM)", label: "Taos (NM)" },
  { value: "Torrance (NM)", label: "Torrance (NM)" },
  { value: "Union (NM)", label: "Union (NM)" },
  { value: "Valencia (NM)", label: "Valencia (NM)" },
  { value: "Albany (NY)", label: "Albany (NY)" },
  { value: "Allegany (NY)", label: "Allegany (NY)" },
  { value: "Bronx (NY)", label: "Bronx (NY)" },
  { value: "Broome (NY)", label: "Broome (NY)" },
  { value: "Cattaraugus (NY)", label: "Cattaraugus (NY)" },
  { value: "Cayuga (NY)", label: "Cayuga (NY)" },
  { value: "Chautauqua (NY)", label: "Chautauqua (NY)" },
  { value: "Chemung (NY)", label: "Chemung (NY)" },
  { value: "Chenango (NY)", label: "Chenango (NY)" },
  { value: "Clinton (NY)", label: "Clinton (NY)" },
  { value: "Columbia (NY)", label: "Columbia (NY)" },
  { value: "Cortland (NY)", label: "Cortland (NY)" },
  { value: "Delaware (NY)", label: "Delaware (NY)" },
  { value: "Dutchess (NY)", label: "Dutchess (NY)" },
  { value: "Erie (NY)", label: "Erie (NY)" },
  { value: "Essex (NY)", label: "Essex (NY)" },
  { value: "Franklin (NY)", label: "Franklin (NY)" },
  { value: "Fulton (NY)", label: "Fulton (NY)" },
  { value: "Genesee (NY)", label: "Genesee (NY)" },
  { value: "Greene (NY)", label: "Greene (NY)" },
  { value: "Hamilton (NY)", label: "Hamilton (NY)" },
  { value: "Herkimer (NY)", label: "Herkimer (NY)" },
  { value: "Jefferson (NY)", label: "Jefferson (NY)" },
  { value: "Kings (NY)", label: "Kings (NY)" },
  { value: "Lewis (NY)", label: "Lewis (NY)" },
  { value: "Livingston (NY)", label: "Livingston (NY)" },
  { value: "Madison (NY)", label: "Madison (NY)" },
  { value: "Monroe (NY)", label: "Monroe (NY)" },
  { value: "Montgomery (NY)", label: "Montgomery (NY)" },
  { value: "Nassau (NY)", label: "Nassau (NY)" },
  { value: "New York (NY)", label: "New York (NY)" },
  { value: "Niagara (NY)", label: "Niagara (NY)" },
  { value: "Oneida (NY)", label: "Oneida (NY)" },
  { value: "Onondaga (NY)", label: "Onondaga (NY)" },
  { value: "Ontario (NY)", label: "Ontario (NY)" },
  { value: "Orange (NY)", label: "Orange (NY)" },
  { value: "Orleans (NY)", label: "Orleans (NY)" },
  { value: "Oswego (NY)", label: "Oswego (NY)" },
  { value: "Otsego (NY)", label: "Otsego (NY)" },
  { value: "Putnam (NY)", label: "Putnam (NY)" },
  { value: "Queens (NY)", label: "Queens (NY)" },
  { value: "Rensselaer (NY)", label: "Rensselaer (NY)" },
  { value: "Richmond (NY)", label: "Richmond (NY)" },
  { value: "Rockland (NY)", label: "Rockland (NY)" },
  { value: "St. Lawrence (NY)", label: "St. Lawrence (NY)" },
  { value: "Saratoga (NY)", label: "Saratoga (NY)" },
  { value: "Schenectady (NY)", label: "Schenectady (NY)" },
  { value: "Schoharie (NY)", label: "Schoharie (NY)" },
  { value: "Schuyler (NY)", label: "Schuyler (NY)" },
  { value: "Seneca (NY)", label: "Seneca (NY)" },
  { value: "Steuben (NY)", label: "Steuben (NY)" },
  { value: "Suffolk (NY)", label: "Suffolk (NY)" },
  { value: "Sullivan (NY)", label: "Sullivan (NY)" },
  { value: "Tioga (NY)", label: "Tioga (NY)" },
  { value: "Tompkins (NY)", label: "Tompkins (NY)" },
  { value: "Ulster (NY)", label: "Ulster (NY)" },
  { value: "Warren (NY)", label: "Warren (NY)" },
  { value: "Washington (NY)", label: "Washington (NY)" },
  { value: "Wayne (NY)", label: "Wayne (NY)" },
  { value: "Westchester (NY)", label: "Westchester (NY)" },
  { value: "Wyoming (NY)", label: "Wyoming (NY)" },
  { value: "Yates (NY)", label: "Yates (NY)" },
  { value: "Alamance (NC)", label: "Alamance (NC)" },
  { value: "Alexander (NC)", label: "Alexander (NC)" },
  { value: "Alleghany (NC)", label: "Alleghany (NC)" },
  { value: "Anson (NC)", label: "Anson (NC)" },
  { value: "Ashe (NC)", label: "Ashe (NC)" },
  { value: "Avery (NC)", label: "Avery (NC)" },
  { value: "Beaufort (NC)", label: "Beaufort (NC)" },
  { value: "Bertie (NC)", label: "Bertie (NC)" },
  { value: "Bladen (NC)", label: "Bladen (NC)" },
  { value: "Brunswick (NC)", label: "Brunswick (NC)" },
  { value: "Buncombe (NC)", label: "Buncombe (NC)" },
  { value: "Burke (NC)", label: "Burke (NC)" },
  { value: "Cabarrus (NC)", label: "Cabarrus (NC)" },
  { value: "Caldwell (NC)", label: "Caldwell (NC)" },
  { value: "Camden (NC)", label: "Camden (NC)" },
  { value: "Carteret (NC)", label: "Carteret (NC)" },
  { value: "Caswell (NC)", label: "Caswell (NC)" },
  { value: "Catawba (NC)", label: "Catawba (NC)" },
  { value: "Chatham (NC)", label: "Chatham (NC)" },
  { value: "Cherokee (NC)", label: "Cherokee (NC)" },
  { value: "Chowan (NC)", label: "Chowan (NC)" },
  { value: "Clay (NC)", label: "Clay (NC)" },
  { value: "Cleveland (NC)", label: "Cleveland (NC)" },
  { value: "Columbus (NC)", label: "Columbus (NC)" },
  { value: "Craven (NC)", label: "Craven (NC)" },
  { value: "Cumberland (NC)", label: "Cumberland (NC)" },
  { value: "Currituck (NC)", label: "Currituck (NC)" },
  { value: "Dare (NC)", label: "Dare (NC)" },
  { value: "Davidson (NC)", label: "Davidson (NC)" },
  { value: "Davie (NC)", label: "Davie (NC)" },
  { value: "Duplin (NC)", label: "Duplin (NC)" },
  { value: "Durham (NC)", label: "Durham (NC)" },
  { value: "Edgecombe (NC)", label: "Edgecombe (NC)" },
  { value: "Forsyth (NC)", label: "Forsyth (NC)" },
  { value: "Franklin (NC)", label: "Franklin (NC)" },
  { value: "Gaston (NC)", label: "Gaston (NC)" },
  { value: "Gates (NC)", label: "Gates (NC)" },
  { value: "Graham (NC)", label: "Graham (NC)" },
  { value: "Granville (NC)", label: "Granville (NC)" },
  { value: "Greene (NC)", label: "Greene (NC)" },
  { value: "Guilford (NC)", label: "Guilford (NC)" },
  { value: "Halifax (NC)", label: "Halifax (NC)" },
  { value: "Harnett (NC)", label: "Harnett (NC)" },
  { value: "Haywood (NC)", label: "Haywood (NC)" },
  { value: "Henderson (NC)", label: "Henderson (NC)" },
  { value: "Hertford (NC)", label: "Hertford (NC)" },
  { value: "Hoke (NC)", label: "Hoke (NC)" },
  { value: "Hyde (NC)", label: "Hyde (NC)" },
  { value: "Iredell (NC)", label: "Iredell (NC)" },
  { value: "Jackson (NC)", label: "Jackson (NC)" },
  { value: "Johnston (NC)", label: "Johnston (NC)" },
  { value: "Jones (NC)", label: "Jones (NC)" },
  { value: "Lee (NC)", label: "Lee (NC)" },
  { value: "Lenoir (NC)", label: "Lenoir (NC)" },
  { value: "Lincoln (NC)", label: "Lincoln (NC)" },
  { value: "McDowell (NC)", label: "McDowell (NC)" },
  { value: "Macon (NC)", label: "Macon (NC)" },
  { value: "Madison (NC)", label: "Madison (NC)" },
  { value: "Martin (NC)", label: "Martin (NC)" },
  { value: "Mecklenburg (NC)", label: "Mecklenburg (NC)" },
  { value: "Mitchell (NC)", label: "Mitchell (NC)" },
  { value: "Montgomery (NC)", label: "Montgomery (NC)" },
  { value: "Moore (NC)", label: "Moore (NC)" },
  { value: "Nash (NC)", label: "Nash (NC)" },
  { value: "New Hanover (NC)", label: "New Hanover (NC)" },
  { value: "Northampton (NC)", label: "Northampton (NC)" },
  { value: "Onslow (NC)", label: "Onslow (NC)" },
  { value: "Orange (NC)", label: "Orange (NC)" },
  { value: "Pamlico (NC)", label: "Pamlico (NC)" },
  { value: "Pasquotank (NC)", label: "Pasquotank (NC)" },
  { value: "Pender (NC)", label: "Pender (NC)" },
  { value: "Perquimans (NC)", label: "Perquimans (NC)" },
  { value: "Person (NC)", label: "Person (NC)" },
  { value: "Pitt (NC)", label: "Pitt (NC)" },
  { value: "Polk (NC)", label: "Polk (NC)" },
  { value: "Randolph (NC)", label: "Randolph (NC)" },
  { value: "Richmond (NC)", label: "Richmond (NC)" },
  { value: "Robeson (NC)", label: "Robeson (NC)" },
  { value: "Rockingham (NC)", label: "Rockingham (NC)" },
  { value: "Rowan (NC)", label: "Rowan (NC)" },
  { value: "Rutherford (NC)", label: "Rutherford (NC)" },
  { value: "Sampson (NC)", label: "Sampson (NC)" },
  { value: "Scotland (NC)", label: "Scotland (NC)" },
  { value: "Stanly (NC)", label: "Stanly (NC)" },
  { value: "Stokes (NC)", label: "Stokes (NC)" },
  { value: "Surry (NC)", label: "Surry (NC)" },
  { value: "Swain (NC)", label: "Swain (NC)" },
  { value: "Transylvania (NC)", label: "Transylvania (NC)" },
  { value: "Tyrrell (NC)", label: "Tyrrell (NC)" },
  { value: "Union (NC)", label: "Union (NC)" },
  { value: "Vance (NC)", label: "Vance (NC)" },
  { value: "Wake (NC)", label: "Wake (NC)" },
  { value: "Warren (NC)", label: "Warren (NC)" },
  { value: "Washington (NC)", label: "Washington (NC)" },
  { value: "Watauga (NC)", label: "Watauga (NC)" },
  { value: "Wayne (NC)", label: "Wayne (NC)" },
  { value: "Wilkes (NC)", label: "Wilkes (NC)" },
  { value: "Wilson (NC)", label: "Wilson (NC)" },
  { value: "Yadkin (NC)", label: "Yadkin (NC)" },
  { value: "Yancey (NC)", label: "Yancey (NC)" },
  { value: "Adams (ND)", label: "Adams (ND)" },
  { value: "Barnes (ND)", label: "Barnes (ND)" },
  { value: "Benson (ND)", label: "Benson (ND)" },
  { value: "Billings (ND)", label: "Billings (ND)" },
  { value: "Bottineau (ND)", label: "Bottineau (ND)" },
  { value: "Bowman (ND)", label: "Bowman (ND)" },
  { value: "Burke (ND)", label: "Burke (ND)" },
  { value: "Burleigh (ND)", label: "Burleigh (ND)" },
  { value: "Cass (ND)", label: "Cass (ND)" },
  { value: "Cavalier (ND)", label: "Cavalier (ND)" },
  { value: "Dickey (ND)", label: "Dickey (ND)" },
  { value: "Divide (ND)", label: "Divide (ND)" },
  { value: "Dunn (ND)", label: "Dunn (ND)" },
  { value: "Eddy (ND)", label: "Eddy (ND)" },
  { value: "Emmons (ND)", label: "Emmons (ND)" },
  { value: "Foster (ND)", label: "Foster (ND)" },
  { value: "Golden Valley (ND)", label: "Golden Valley (ND)" },
  { value: "Grand Forks (ND)", label: "Grand Forks (ND)" },
  { value: "Grant (ND)", label: "Grant (ND)" },
  { value: "Griggs (ND)", label: "Griggs (ND)" },
  { value: "Hettinger (ND)", label: "Hettinger (ND)" },
  { value: "Kidder (ND)", label: "Kidder (ND)" },
  { value: "LaMoure (ND)", label: "LaMoure (ND)" },
  { value: "Logan (ND)", label: "Logan (ND)" },
  { value: "McHenry (ND)", label: "McHenry (ND)" },
  { value: "McIntosh (ND)", label: "McIntosh (ND)" },
  { value: "McKenzie (ND)", label: "McKenzie (ND)" },
  { value: "McLean (ND)", label: "McLean (ND)" },
  { value: "Mercer (ND)", label: "Mercer (ND)" },
  { value: "Morton (ND)", label: "Morton (ND)" },
  { value: "Mountrail (ND)", label: "Mountrail (ND)" },
  { value: "Nelson (ND)", label: "Nelson (ND)" },
  { value: "Oliver (ND)", label: "Oliver (ND)" },
  { value: "Pembina (ND)", label: "Pembina (ND)" },
  { value: "Pierce (ND)", label: "Pierce (ND)" },
  { value: "Ramsey (ND)", label: "Ramsey (ND)" },
  { value: "Ransom (ND)", label: "Ransom (ND)" },
  { value: "Renville (ND)", label: "Renville (ND)" },
  { value: "Richland (ND)", label: "Richland (ND)" },
  { value: "Rolette (ND)", label: "Rolette (ND)" },
  { value: "Sargent (ND)", label: "Sargent (ND)" },
  { value: "Sheridan (ND)", label: "Sheridan (ND)" },
  { value: "Sioux (ND)", label: "Sioux (ND)" },
  { value: "Slope (ND)", label: "Slope (ND)" },
  { value: "Stark (ND)", label: "Stark (ND)" },
  { value: "Steele (ND)", label: "Steele (ND)" },
  { value: "Stutsman (ND)", label: "Stutsman (ND)" },
  { value: "Towner (ND)", label: "Towner (ND)" },
  { value: "Traill (ND)", label: "Traill (ND)" },
  { value: "Walsh (ND)", label: "Walsh (ND)" },
  { value: "Ward (ND)", label: "Ward (ND)" },
  { value: "Wells (ND)", label: "Wells (ND)" },
  { value: "Williams (ND)", label: "Williams (ND)" },
  { value: "Adams (OH)", label: "Adams (OH)" },
  { value: "Allen (OH)", label: "Allen (OH)" },
  { value: "Ashland (OH)", label: "Ashland (OH)" },
  { value: "Ashtabula (OH)", label: "Ashtabula (OH)" },
  { value: "Athens (OH)", label: "Athens (OH)" },
  { value: "Auglaize (OH)", label: "Auglaize (OH)" },
  { value: "Belmont (OH)", label: "Belmont (OH)" },
  { value: "Brown (OH)", label: "Brown (OH)" },
  { value: "Butler (OH)", label: "Butler (OH)" },
  { value: "Carroll (OH)", label: "Carroll (OH)" },
  { value: "Champaign (OH)", label: "Champaign (OH)" },
  { value: "Clark (OH)", label: "Clark (OH)" },
  { value: "Clermont (OH)", label: "Clermont (OH)" },
  { value: "Clinton (OH)", label: "Clinton (OH)" },
  { value: "Columbiana (OH)", label: "Columbiana (OH)" },
  { value: "Coshocton (OH)", label: "Coshocton (OH)" },
  { value: "Crawford (OH)", label: "Crawford (OH)" },
  { value: "Cuyahoga (OH)", label: "Cuyahoga (OH)" },
  { value: "Darke (OH)", label: "Darke (OH)" },
  { value: "Defiance (OH)", label: "Defiance (OH)" },
  { value: "Delaware (OH)", label: "Delaware (OH)" },
  { value: "Erie (OH)", label: "Erie (OH)" },
  { value: "Fairfield (OH)", label: "Fairfield (OH)" },
  { value: "Fayette (OH)", label: "Fayette (OH)" },
  { value: "Franklin (OH)", label: "Franklin (OH)" },
  { value: "Fulton (OH)", label: "Fulton (OH)" },
  { value: "Gallia (OH)", label: "Gallia (OH)" },
  { value: "Geauga (OH)", label: "Geauga (OH)" },
  { value: "Greene (OH)", label: "Greene (OH)" },
  { value: "Guernsey (OH)", label: "Guernsey (OH)" },
  { value: "Hamilton (OH)", label: "Hamilton (OH)" },
  { value: "Hancock (OH)", label: "Hancock (OH)" },
  { value: "Hardin (OH)", label: "Hardin (OH)" },
  { value: "Harrison (OH)", label: "Harrison (OH)" },
  { value: "Henry (OH)", label: "Henry (OH)" },
  { value: "Highland (OH)", label: "Highland (OH)" },
  { value: "Hocking (OH)", label: "Hocking (OH)" },
  { value: "Holmes (OH)", label: "Holmes (OH)" },
  { value: "Huron (OH)", label: "Huron (OH)" },
  { value: "Jackson (OH)", label: "Jackson (OH)" },
  { value: "Jefferson (OH)", label: "Jefferson (OH)" },
  { value: "Knox (OH)", label: "Knox (OH)" },
  { value: "Lake (OH)", label: "Lake (OH)" },
  { value: "Lawrence (OH)", label: "Lawrence (OH)" },
  { value: "Licking (OH)", label: "Licking (OH)" },
  { value: "Logan (OH)", label: "Logan (OH)" },
  { value: "Lorain (OH)", label: "Lorain (OH)" },
  { value: "Lucas (OH)", label: "Lucas (OH)" },
  { value: "Madison (OH)", label: "Madison (OH)" },
  { value: "Mahoning (OH)", label: "Mahoning (OH)" },
  { value: "Marion (OH)", label: "Marion (OH)" },
  { value: "Medina (OH)", label: "Medina (OH)" },
  { value: "Meigs (OH)", label: "Meigs (OH)" },
  { value: "Mercer (OH)", label: "Mercer (OH)" },
  { value: "Miami (OH)", label: "Miami (OH)" },
  { value: "Monroe (OH)", label: "Monroe (OH)" },
  { value: "Montgomery (OH)", label: "Montgomery (OH)" },
  { value: "Morgan (OH)", label: "Morgan (OH)" },
  { value: "Morrow (OH)", label: "Morrow (OH)" },
  { value: "Muskingum (OH)", label: "Muskingum (OH)" },
  { value: "Noble (OH)", label: "Noble (OH)" },
  { value: "Ottawa (OH)", label: "Ottawa (OH)" },
  { value: "Paulding (OH)", label: "Paulding (OH)" },
  { value: "Perry (OH)", label: "Perry (OH)" },
  { value: "Pickaway (OH)", label: "Pickaway (OH)" },
  { value: "Pike (OH)", label: "Pike (OH)" },
  { value: "Portage (OH)", label: "Portage (OH)" },
  { value: "Preble (OH)", label: "Preble (OH)" },
  { value: "Putnam (OH)", label: "Putnam (OH)" },
  { value: "Richland (OH)", label: "Richland (OH)" },
  { value: "Ross (OH)", label: "Ross (OH)" },
  { value: "Sandusky (OH)", label: "Sandusky (OH)" },
  { value: "Scioto (OH)", label: "Scioto (OH)" },
  { value: "Seneca (OH)", label: "Seneca (OH)" },
  { value: "Shelby (OH)", label: "Shelby (OH)" },
  { value: "Stark (OH)", label: "Stark (OH)" },
  { value: "Summit (OH)", label: "Summit (OH)" },
  { value: "Trumbull (OH)", label: "Trumbull (OH)" },
  { value: "Tuscarawas (OH)", label: "Tuscarawas (OH)" },
  { value: "Union (OH)", label: "Union (OH)" },
  { value: "Van Wert (OH)", label: "Van Wert (OH)" },
  { value: "Vinton (OH)", label: "Vinton (OH)" },
  { value: "Warren (OH)", label: "Warren (OH)" },
  { value: "Washington (OH)", label: "Washington (OH)" },
  { value: "Wayne (OH)", label: "Wayne (OH)" },
  { value: "Williams (OH)", label: "Williams (OH)" },
  { value: "Wood (OH)", label: "Wood (OH)" },
  { value: "Wyandot (OH)", label: "Wyandot (OH)" },
  { value: "Adair (OK)", label: "Adair (OK)" },
  { value: "Alfalfa (OK)", label: "Alfalfa (OK)" },
  { value: "Atoka (OK)", label: "Atoka (OK)" },
  { value: "Beaver (OK)", label: "Beaver (OK)" },
  { value: "Beckham (OK)", label: "Beckham (OK)" },
  { value: "Blaine (OK)", label: "Blaine (OK)" },
  { value: "Bryan (OK)", label: "Bryan (OK)" },
  { value: "Caddo (OK)", label: "Caddo (OK)" },
  { value: "Canadian (OK)", label: "Canadian (OK)" },
  { value: "Carter (OK)", label: "Carter (OK)" },
  { value: "Cherokee (OK)", label: "Cherokee (OK)" },
  { value: "Choctaw (OK)", label: "Choctaw (OK)" },
  { value: "Cimarron (OK)", label: "Cimarron (OK)" },
  { value: "Cleveland (OK)", label: "Cleveland (OK)" },
  { value: "Coal (OK)", label: "Coal (OK)" },
  { value: "Comanche (OK)", label: "Comanche (OK)" },
  { value: "Cotton (OK)", label: "Cotton (OK)" },
  { value: "Craig (OK)", label: "Craig (OK)" },
  { value: "Creek (OK)", label: "Creek (OK)" },
  { value: "Custer (OK)", label: "Custer (OK)" },
  { value: "Delaware (OK)", label: "Delaware (OK)" },
  { value: "Dewey (OK)", label: "Dewey (OK)" },
  { value: "Ellis (OK)", label: "Ellis (OK)" },
  { value: "Garfield (OK)", label: "Garfield (OK)" },
  { value: "Garvin (OK)", label: "Garvin (OK)" },
  { value: "Grady (OK)", label: "Grady (OK)" },
  { value: "Grant (OK)", label: "Grant (OK)" },
  { value: "Greer (OK)", label: "Greer (OK)" },
  { value: "Harmon (OK)", label: "Harmon (OK)" },
  { value: "Harper (OK)", label: "Harper (OK)" },
  { value: "Haskell (OK)", label: "Haskell (OK)" },
  { value: "Hughes (OK)", label: "Hughes (OK)" },
  { value: "Jackson (OK)", label: "Jackson (OK)" },
  { value: "Jefferson (OK)", label: "Jefferson (OK)" },
  { value: "Johnston (OK)", label: "Johnston (OK)" },
  { value: "Kay (OK)", label: "Kay (OK)" },
  { value: "Kingfisher (OK)", label: "Kingfisher (OK)" },
  { value: "Kiowa (OK)", label: "Kiowa (OK)" },
  { value: "Latimer (OK)", label: "Latimer (OK)" },
  { value: "Le Flore (OK)", label: "Le Flore (OK)" },
  { value: "Lincoln (OK)", label: "Lincoln (OK)" },
  { value: "Logan (OK)", label: "Logan (OK)" },
  { value: "Love (OK)", label: "Love (OK)" },
  { value: "McClain (OK)", label: "McClain (OK)" },
  { value: "McCurtain (OK)", label: "McCurtain (OK)" },
  { value: "McIntosh (OK)", label: "McIntosh (OK)" },
  { value: "Major (OK)", label: "Major (OK)" },
  { value: "Marshall (OK)", label: "Marshall (OK)" },
  { value: "Mayes (OK)", label: "Mayes (OK)" },
  { value: "Murray (OK)", label: "Murray (OK)" },
  { value: "Muskogee (OK)", label: "Muskogee (OK)" },
  { value: "Noble (OK)", label: "Noble (OK)" },
  { value: "Nowata (OK)", label: "Nowata (OK)" },
  { value: "Okfuskee (OK)", label: "Okfuskee (OK)" },
  { value: "Oklahoma (OK)", label: "Oklahoma (OK)" },
  { value: "Okmulgee (OK)", label: "Okmulgee (OK)" },
  { value: "Osage (OK)", label: "Osage (OK)" },
  { value: "Ottawa (OK)", label: "Ottawa (OK)" },
  { value: "Pawnee (OK)", label: "Pawnee (OK)" },
  { value: "Payne (OK)", label: "Payne (OK)" },
  { value: "Pittsburg (OK)", label: "Pittsburg (OK)" },
  { value: "Pontotoc (OK)", label: "Pontotoc (OK)" },
  { value: "Pottawatomie (OK)", label: "Pottawatomie (OK)" },
  { value: "Pushmataha (OK)", label: "Pushmataha (OK)" },
  { value: "Roger Mills (OK)", label: "Roger Mills (OK)" },
  { value: "Rogers (OK)", label: "Rogers (OK)" },
  { value: "Seminole (OK)", label: "Seminole (OK)" },
  { value: "Sequoyah (OK)", label: "Sequoyah (OK)" },
  { value: "Stephens (OK)", label: "Stephens (OK)" },
  { value: "Texas (OK)", label: "Texas (OK)" },
  { value: "Tillman (OK)", label: "Tillman (OK)" },
  { value: "Tulsa (OK)", label: "Tulsa (OK)" },
  { value: "Wagoner (OK)", label: "Wagoner (OK)" },
  { value: "Washington (OK)", label: "Washington (OK)" },
  { value: "Washita (OK)", label: "Washita (OK)" },
  { value: "Woods (OK)", label: "Woods (OK)" },
  { value: "Woodward (OK)", label: "Woodward (OK)" },
  { value: "Baker (OR)", label: "Baker (OR)" },
  { value: "Benton (OR)", label: "Benton (OR)" },
  { value: "Clackamas (OR)", label: "Clackamas (OR)" },
  { value: "Clatsop (OR)", label: "Clatsop (OR)" },
  { value: "Columbia (OR)", label: "Columbia (OR)" },
  { value: "Coos (OR)", label: "Coos (OR)" },
  { value: "Crook (OR)", label: "Crook (OR)" },
  { value: "Curry (OR)", label: "Curry (OR)" },
  { value: "Deschutes (OR)", label: "Deschutes (OR)" },
  { value: "Douglas (OR)", label: "Douglas (OR)" },
  { value: "Gilliam (OR)", label: "Gilliam (OR)" },
  { value: "Grant (OR)", label: "Grant (OR)" },
  { value: "Harney (OR)", label: "Harney (OR)" },
  { value: "Hood River (OR)", label: "Hood River (OR)" },
  { value: "Jackson (OR)", label: "Jackson (OR)" },
  { value: "Jefferson (OR)", label: "Jefferson (OR)" },
  { value: "Josephine (OR)", label: "Josephine (OR)" },
  { value: "Klamath (OR)", label: "Klamath (OR)" },
  { value: "Lake (OR)", label: "Lake (OR)" },
  { value: "Lane (OR)", label: "Lane (OR)" },
  { value: "Lincoln (OR)", label: "Lincoln (OR)" },
  { value: "Linn (OR)", label: "Linn (OR)" },
  { value: "Malheur (OR)", label: "Malheur (OR)" },
  { value: "Marion (OR)", label: "Marion (OR)" },
  { value: "Morrow (OR)", label: "Morrow (OR)" },
  { value: "Multnomah (OR)", label: "Multnomah (OR)" },
  { value: "Polk (OR)", label: "Polk (OR)" },
  { value: "Sherman (OR)", label: "Sherman (OR)" },
  { value: "Tillamook (OR)", label: "Tillamook (OR)" },
  { value: "Umatilla (OR)", label: "Umatilla (OR)" },
  { value: "Union (OR)", label: "Union (OR)" },
  { value: "Wallowa (OR)", label: "Wallowa (OR)" },
  { value: "Wasco (OR)", label: "Wasco (OR)" },
  { value: "Washington (OR)", label: "Washington (OR)" },
  { value: "Wheeler (OR)", label: "Wheeler (OR)" },
  { value: "Yamhill (OR)", label: "Yamhill (OR)" },
  { value: "Adams (PA)", label: "Adams (PA)" },
  { value: "Allegheny (PA)", label: "Allegheny (PA)" },
  { value: "Armstrong (PA)", label: "Armstrong (PA)" },
  { value: "Beaver (PA)", label: "Beaver (PA)" },
  { value: "Bedford (PA)", label: "Bedford (PA)" },
  { value: "Berks (PA)", label: "Berks (PA)" },
  { value: "Blair (PA)", label: "Blair (PA)" },
  { value: "Bradford (PA)", label: "Bradford (PA)" },
  { value: "Bucks (PA)", label: "Bucks (PA)" },
  { value: "Butler (PA)", label: "Butler (PA)" },
  { value: "Cambria (PA)", label: "Cambria (PA)" },
  { value: "Cameron (PA)", label: "Cameron (PA)" },
  { value: "Carbon (PA)", label: "Carbon (PA)" },
  { value: "Centre (PA)", label: "Centre (PA)" },
  { value: "Chester (PA)", label: "Chester (PA)" },
  { value: "Clarion (PA)", label: "Clarion (PA)" },
  { value: "Clearfield (PA)", label: "Clearfield (PA)" },
  { value: "Clinton (PA)", label: "Clinton (PA)" },
  { value: "Columbia (PA)", label: "Columbia (PA)" },
  { value: "Crawford (PA)", label: "Crawford (PA)" },
  { value: "Cumberland (PA)", label: "Cumberland (PA)" },
  { value: "Dauphin (PA)", label: "Dauphin (PA)" },
  { value: "Delaware (PA)", label: "Delaware (PA)" },
  { value: "Elk (PA)", label: "Elk (PA)" },
  { value: "Erie (PA)", label: "Erie (PA)" },
  { value: "Fayette (PA)", label: "Fayette (PA)" },
  { value: "Forest (PA)", label: "Forest (PA)" },
  { value: "Franklin (PA)", label: "Franklin (PA)" },
  { value: "Fulton (PA)", label: "Fulton (PA)" },
  { value: "Greene (PA)", label: "Greene (PA)" },
  { value: "Huntingdon (PA)", label: "Huntingdon (PA)" },
  { value: "Indiana (PA)", label: "Indiana (PA)" },
  { value: "Jefferson (PA)", label: "Jefferson (PA)" },
  { value: "Juniata (PA)", label: "Juniata (PA)" },
  { value: "Lackawanna (PA)", label: "Lackawanna (PA)" },
  { value: "Lancaster (PA)", label: "Lancaster (PA)" },
  { value: "Lawrence (PA)", label: "Lawrence (PA)" },
  { value: "Lebanon (PA)", label: "Lebanon (PA)" },
  { value: "Lehigh (PA)", label: "Lehigh (PA)" },
  { value: "Luzerne (PA)", label: "Luzerne (PA)" },
  { value: "Lycoming (PA)", label: "Lycoming (PA)" },
  { value: "McKean (PA)", label: "McKean (PA)" },
  { value: "Mercer (PA)", label: "Mercer (PA)" },
  { value: "Mifflin (PA)", label: "Mifflin (PA)" },
  { value: "Monroe (PA)", label: "Monroe (PA)" },
  { value: "Montgomery (PA)", label: "Montgomery (PA)" },
  { value: "Montour (PA)", label: "Montour (PA)" },
  { value: "Northampton (PA)", label: "Northampton (PA)" },
  { value: "Northumberland (PA)", label: "Northumberland (PA)" },
  { value: "Perry (PA)", label: "Perry (PA)" },
  { value: "Philadelphia (PA)", label: "Philadelphia (PA)" },
  { value: "Pike (PA)", label: "Pike (PA)" },
  { value: "Potter (PA)", label: "Potter (PA)" },
  { value: "Schuylkill (PA)", label: "Schuylkill (PA)" },
  { value: "Snyder (PA)", label: "Snyder (PA)" },
  { value: "Somerset (PA)", label: "Somerset (PA)" },
  { value: "Sullivan (PA)", label: "Sullivan (PA)" },
  { value: "Susquehanna (PA)", label: "Susquehanna (PA)" },
  { value: "Tioga (PA)", label: "Tioga (PA)" },
  { value: "Union (PA)", label: "Union (PA)" },
  { value: "Venango (PA)", label: "Venango (PA)" },
  { value: "Warren (PA)", label: "Warren (PA)" },
  { value: "Washington (PA)", label: "Washington (PA)" },
  { value: "Wayne (PA)", label: "Wayne (PA)" },
  { value: "Westmoreland (PA)", label: "Westmoreland (PA)" },
  { value: "Wyoming (PA)", label: "Wyoming (PA)" },
  { value: "York (PA)", label: "York (PA)" },
  { value: "Bristol (RI)", label: "Bristol (RI)" },
  { value: "Kent (RI)", label: "Kent (RI)" },
  { value: "Newport (RI)", label: "Newport (RI)" },
  { value: "Providence (RI)", label: "Providence (RI)" },
  { value: "Washington (RI)", label: "Washington (RI)" },
  { value: "Abbeville (SC)", label: "Abbeville (SC)" },
  { value: "Aiken (SC)", label: "Aiken (SC)" },
  { value: "Allendale (SC)", label: "Allendale (SC)" },
  { value: "Anderson (SC)", label: "Anderson (SC)" },
  { value: "Bamberg (SC)", label: "Bamberg (SC)" },
  { value: "Barnwell (SC)", label: "Barnwell (SC)" },
  { value: "Beaufort (SC)", label: "Beaufort (SC)" },
  { value: "Berkeley (SC)", label: "Berkeley (SC)" },
  { value: "Calhoun (SC)", label: "Calhoun (SC)" },
  { value: "Charleston (SC)", label: "Charleston (SC)" },
  { value: "Cherokee (SC)", label: "Cherokee (SC)" },
  { value: "Chester (SC)", label: "Chester (SC)" },
  { value: "Chesterfield (SC)", label: "Chesterfield (SC)" },
  { value: "Clarendon (SC)", label: "Clarendon (SC)" },
  { value: "Colleton (SC)", label: "Colleton (SC)" },
  { value: "Darlington (SC)", label: "Darlington (SC)" },
  { value: "Dillon (SC)", label: "Dillon (SC)" },
  { value: "Dorchester (SC)", label: "Dorchester (SC)" },
  { value: "Edgefield (SC)", label: "Edgefield (SC)" },
  { value: "Fairfield (SC)", label: "Fairfield (SC)" },
  { value: "Florence (SC)", label: "Florence (SC)" },
  { value: "Georgetown (SC)", label: "Georgetown (SC)" },
  { value: "Greenville (SC)", label: "Greenville (SC)" },
  { value: "Greenwood (SC)", label: "Greenwood (SC)" },
  { value: "Hampton (SC)", label: "Hampton (SC)" },
  { value: "Horry (SC)", label: "Horry (SC)" },
  { value: "Jasper (SC)", label: "Jasper (SC)" },
  { value: "Kershaw (SC)", label: "Kershaw (SC)" },
  { value: "Lancaster (SC)", label: "Lancaster (SC)" },
  { value: "Laurens (SC)", label: "Laurens (SC)" },
  { value: "Lee (SC)", label: "Lee (SC)" },
  { value: "Lexington (SC)", label: "Lexington (SC)" },
  { value: "McCormick (SC)", label: "McCormick (SC)" },
  { value: "Marion (SC)", label: "Marion (SC)" },
  { value: "Marlboro (SC)", label: "Marlboro (SC)" },
  { value: "Newberry (SC)", label: "Newberry (SC)" },
  { value: "Oconee (SC)", label: "Oconee (SC)" },
  { value: "Orangeburg (SC)", label: "Orangeburg (SC)" },
  { value: "Pickens (SC)", label: "Pickens (SC)" },
  { value: "Richland (SC)", label: "Richland (SC)" },
  { value: "Saluda (SC)", label: "Saluda (SC)" },
  { value: "Spartanburg (SC)", label: "Spartanburg (SC)" },
  { value: "Sumter (SC)", label: "Sumter (SC)" },
  { value: "Union (SC)", label: "Union (SC)" },
  { value: "Williamsburg (SC)", label: "Williamsburg (SC)" },
  { value: "York (SC)", label: "York (SC)" },
  { value: "Aurora (SD)", label: "Aurora (SD)" },
  { value: "Beadle (SD)", label: "Beadle (SD)" },
  { value: "Bennett (SD)", label: "Bennett (SD)" },
  { value: "Bon Homme (SD)", label: "Bon Homme (SD)" },
  { value: "Brookings (SD)", label: "Brookings (SD)" },
  { value: "Brown (SD)", label: "Brown (SD)" },
  { value: "Brule (SD)", label: "Brule (SD)" },
  { value: "Buffalo (SD)", label: "Buffalo (SD)" },
  { value: "Butte (SD)", label: "Butte (SD)" },
  { value: "Campbell (SD)", label: "Campbell (SD)" },
  { value: "Charles Mix (SD)", label: "Charles Mix (SD)" },
  { value: "Clark (SD)", label: "Clark (SD)" },
  { value: "Clay (SD)", label: "Clay (SD)" },
  { value: "Codington (SD)", label: "Codington (SD)" },
  { value: "Corson (SD)", label: "Corson (SD)" },
  { value: "Custer (SD)", label: "Custer (SD)" },
  { value: "Davison (SD)", label: "Davison (SD)" },
  { value: "Day (SD)", label: "Day (SD)" },
  { value: "Deuel (SD)", label: "Deuel (SD)" },
  { value: "Dewey (SD)", label: "Dewey (SD)" },
  { value: "Douglas (SD)", label: "Douglas (SD)" },
  { value: "Edmunds (SD)", label: "Edmunds (SD)" },
  { value: "Fall River (SD)", label: "Fall River (SD)" },
  { value: "Faulk (SD)", label: "Faulk (SD)" },
  { value: "Grant (SD)", label: "Grant (SD)" },
  { value: "Gregory (SD)", label: "Gregory (SD)" },
  { value: "Haakon (SD)", label: "Haakon (SD)" },
  { value: "Hamlin (SD)", label: "Hamlin (SD)" },
  { value: "Hand (SD)", label: "Hand (SD)" },
  { value: "Hanson (SD)", label: "Hanson (SD)" },
  { value: "Harding (SD)", label: "Harding (SD)" },
  { value: "Hughes (SD)", label: "Hughes (SD)" },
  { value: "Hutchinson (SD)", label: "Hutchinson (SD)" },
  { value: "Hyde (SD)", label: "Hyde (SD)" },
  { value: "Jackson (SD)", label: "Jackson (SD)" },
  { value: "Jerauld (SD)", label: "Jerauld (SD)" },
  { value: "Jones (SD)", label: "Jones (SD)" },
  { value: "Kingsbury (SD)", label: "Kingsbury (SD)" },
  { value: "Lake (SD)", label: "Lake (SD)" },
  { value: "Lawrence (SD)", label: "Lawrence (SD)" },
  { value: "Lincoln (SD)", label: "Lincoln (SD)" },
  { value: "Lyman (SD)", label: "Lyman (SD)" },
  { value: "McCook (SD)", label: "McCook (SD)" },
  { value: "McPherson (SD)", label: "McPherson (SD)" },
  { value: "Marshall (SD)", label: "Marshall (SD)" },
  { value: "Meade (SD)", label: "Meade (SD)" },
  { value: "Mellette (SD)", label: "Mellette (SD)" },
  { value: "Miner (SD)", label: "Miner (SD)" },
  { value: "Minnehaha (SD)", label: "Minnehaha (SD)" },
  { value: "Moody (SD)", label: "Moody (SD)" },
  { value: "Pennington (SD)", label: "Pennington (SD)" },
  { value: "Perkins (SD)", label: "Perkins (SD)" },
  { value: "Potter (SD)", label: "Potter (SD)" },
  { value: "Roberts (SD)", label: "Roberts (SD)" },
  { value: "Sanborn (SD)", label: "Sanborn (SD)" },
  { value: "Shannon (SD)", label: "Shannon (SD)" },
  { value: "Spink (SD)", label: "Spink (SD)" },
  { value: "Stanley (SD)", label: "Stanley (SD)" },
  { value: "Sully (SD)", label: "Sully (SD)" },
  { value: "Todd (SD)", label: "Todd (SD)" },
  { value: "Tripp (SD)", label: "Tripp (SD)" },
  { value: "Turner (SD)", label: "Turner (SD)" },
  { value: "Union (SD)", label: "Union (SD)" },
  { value: "Walworth (SD)", label: "Walworth (SD)" },
  { value: "Yankton (SD)", label: "Yankton (SD)" },
  { value: "Ziebach (SD)", label: "Ziebach (SD)" },
  { value: "Anderson (TN)", label: "Anderson (TN)" },
  { value: "Bedford (TN)", label: "Bedford (TN)" },
  { value: "Benton (TN)", label: "Benton (TN)" },
  { value: "Bledsoe (TN)", label: "Bledsoe (TN)" },
  { value: "Blount (TN)", label: "Blount (TN)" },
  { value: "Bradley (TN)", label: "Bradley (TN)" },
  { value: "Campbell (TN)", label: "Campbell (TN)" },
  { value: "Cannon (TN)", label: "Cannon (TN)" },
  { value: "Carroll (TN)", label: "Carroll (TN)" },
  { value: "Carter (TN)", label: "Carter (TN)" },
  { value: "Cheatham (TN)", label: "Cheatham (TN)" },
  { value: "Chester (TN)", label: "Chester (TN)" },
  { value: "Claiborne (TN)", label: "Claiborne (TN)" },
  { value: "Clay (TN)", label: "Clay (TN)" },
  { value: "Cocke (TN)", label: "Cocke (TN)" },
  { value: "Coffee (TN)", label: "Coffee (TN)" },
  { value: "Crockett (TN)", label: "Crockett (TN)" },
  { value: "Cumberland (TN)", label: "Cumberland (TN)" },
  { value: "Davidson (TN)", label: "Davidson (TN)" },
  { value: "Decatur (TN)", label: "Decatur (TN)" },
  { value: "DeKalb (TN)", label: "DeKalb (TN)" },
  { value: "Dickson (TN)", label: "Dickson (TN)" },
  { value: "Dyer (TN)", label: "Dyer (TN)" },
  { value: "Fayette (TN)", label: "Fayette (TN)" },
  { value: "Fentress (TN)", label: "Fentress (TN)" },
  { value: "Franklin (TN)", label: "Franklin (TN)" },
  { value: "Gibson (TN)", label: "Gibson (TN)" },
  { value: "Giles (TN)", label: "Giles (TN)" },
  { value: "Grainger (TN)", label: "Grainger (TN)" },
  { value: "Greene (TN)", label: "Greene (TN)" },
  { value: "Grundy (TN)", label: "Grundy (TN)" },
  { value: "Hamblen (TN)", label: "Hamblen (TN)" },
  { value: "Hamilton (TN)", label: "Hamilton (TN)" },
  { value: "Hancock (TN)", label: "Hancock (TN)" },
  { value: "Hardeman (TN)", label: "Hardeman (TN)" },
  { value: "Hardin (TN)", label: "Hardin (TN)" },
  { value: "Hawkins (TN)", label: "Hawkins (TN)" },
  { value: "Haywood (TN)", label: "Haywood (TN)" },
  { value: "Henderson (TN)", label: "Henderson (TN)" },
  { value: "Henry (TN)", label: "Henry (TN)" },
  { value: "Hickman (TN)", label: "Hickman (TN)" },
  { value: "Houston (TN)", label: "Houston (TN)" },
  { value: "Humphreys (TN)", label: "Humphreys (TN)" },
  { value: "Jackson (TN)", label: "Jackson (TN)" },
  { value: "Jefferson (TN)", label: "Jefferson (TN)" },
  { value: "Johnson (TN)", label: "Johnson (TN)" },
  { value: "Knox (TN)", label: "Knox (TN)" },
  { value: "Lake (TN)", label: "Lake (TN)" },
  { value: "Lauderdale (TN)", label: "Lauderdale (TN)" },
  { value: "Lawrence (TN)", label: "Lawrence (TN)" },
  { value: "Lewis (TN)", label: "Lewis (TN)" },
  { value: "Lincoln (TN)", label: "Lincoln (TN)" },
  { value: "Loudon (TN)", label: "Loudon (TN)" },
  { value: "McMinn (TN)", label: "McMinn (TN)" },
  { value: "McNairy (TN)", label: "McNairy (TN)" },
  { value: "Macon (TN)", label: "Macon (TN)" },
  { value: "Madison (TN)", label: "Madison (TN)" },
  { value: "Marion (TN)", label: "Marion (TN)" },
  { value: "Marshall (TN)", label: "Marshall (TN)" },
  { value: "Maury (TN)", label: "Maury (TN)" },
  { value: "Meigs (TN)", label: "Meigs (TN)" },
  { value: "Monroe (TN)", label: "Monroe (TN)" },
  { value: "Montgomery (TN)", label: "Montgomery (TN)" },
  { value: "Moore (TN)", label: "Moore (TN)" },
  { value: "Morgan (TN)", label: "Morgan (TN)" },
  { value: "Obion (TN)", label: "Obion (TN)" },
  { value: "Overton (TN)", label: "Overton (TN)" },
  { value: "Perry (TN)", label: "Perry (TN)" },
  { value: "Pickett (TN)", label: "Pickett (TN)" },
  { value: "Polk (TN)", label: "Polk (TN)" },
  { value: "Putnam (TN)", label: "Putnam (TN)" },
  { value: "Rhea (TN)", label: "Rhea (TN)" },
  { value: "Roane (TN)", label: "Roane (TN)" },
  { value: "Robertson (TN)", label: "Robertson (TN)" },
  { value: "Rutherford (TN)", label: "Rutherford (TN)" },
  { value: "Scott (TN)", label: "Scott (TN)" },
  { value: "Sequatchie (TN)", label: "Sequatchie (TN)" },
  { value: "Sevier (TN)", label: "Sevier (TN)" },
  { value: "Shelby (TN)", label: "Shelby (TN)" },
  { value: "Smith (TN)", label: "Smith (TN)" },
  { value: "Stewart (TN)", label: "Stewart (TN)" },
  { value: "Sullivan (TN)", label: "Sullivan (TN)" },
  { value: "Sumner (TN)", label: "Sumner (TN)" },
  { value: "Tipton (TN)", label: "Tipton (TN)" },
  { value: "Trousdale (TN)", label: "Trousdale (TN)" },
  { value: "Unicoi (TN)", label: "Unicoi (TN)" },
  { value: "Union (TN)", label: "Union (TN)" },
  { value: "Van Buren (TN)", label: "Van Buren (TN)" },
  { value: "Warren (TN)", label: "Warren (TN)" },
  { value: "Washington (TN)", label: "Washington (TN)" },
  { value: "Wayne (TN)", label: "Wayne (TN)" },
  { value: "Weakley (TN)", label: "Weakley (TN)" },
  { value: "White (TN)", label: "White (TN)" },
  { value: "Williamson (TN)", label: "Williamson (TN)" },
  { value: "Wilson (TN)", label: "Wilson (TN)" },
  { value: "Anderson (TX)", label: "Anderson (TX)" },
  { value: "Andrews (TX)", label: "Andrews (TX)" },
  { value: "Angelina (TX)", label: "Angelina (TX)" },
  { value: "Aransas (TX)", label: "Aransas (TX)" },
  { value: "Archer (TX)", label: "Archer (TX)" },
  { value: "Armstrong (TX)", label: "Armstrong (TX)" },
  { value: "Atascosa (TX)", label: "Atascosa (TX)" },
  { value: "Austin (TX)", label: "Austin (TX)" },
  { value: "Bailey (TX)", label: "Bailey (TX)" },
  { value: "Bandera (TX)", label: "Bandera (TX)" },
  { value: "Bastrop (TX)", label: "Bastrop (TX)" },
  { value: "Baylor (TX)", label: "Baylor (TX)" },
  { value: "Bee (TX)", label: "Bee (TX)" },
  { value: "Bell (TX)", label: "Bell (TX)" },
  { value: "Bexar (TX)", label: "Bexar (TX)" },
  { value: "Blanco (TX)", label: "Blanco (TX)" },
  { value: "Borden (TX)", label: "Borden (TX)" },
  { value: "Bosque (TX)", label: "Bosque (TX)" },
  { value: "Bowie (TX)", label: "Bowie (TX)" },
  { value: "Brazoria (TX)", label: "Brazoria (TX)" },
  { value: "Brazos (TX)", label: "Brazos (TX)" },
  { value: "Brewster (TX)", label: "Brewster (TX)" },
  { value: "Briscoe (TX)", label: "Briscoe (TX)" },
  { value: "Brooks (TX)", label: "Brooks (TX)" },
  { value: "Brown (TX)", label: "Brown (TX)" },
  { value: "Burleson (TX)", label: "Burleson (TX)" },
  { value: "Burnet (TX)", label: "Burnet (TX)" },
  { value: "Caldwell (TX)", label: "Caldwell (TX)" },
  { value: "Calhoun (TX)", label: "Calhoun (TX)" },
  { value: "Callahan (TX)", label: "Callahan (TX)" },
  { value: "Cameron (TX)", label: "Cameron (TX)" },
  { value: "Camp (TX)", label: "Camp (TX)" },
  { value: "Carson (TX)", label: "Carson (TX)" },
  { value: "Cass (TX)", label: "Cass (TX)" },
  { value: "Castro (TX)", label: "Castro (TX)" },
  { value: "Chambers (TX)", label: "Chambers (TX)" },
  { value: "Cherokee (TX)", label: "Cherokee (TX)" },
  { value: "Childress (TX)", label: "Childress (TX)" },
  { value: "Clay (TX)", label: "Clay (TX)" },
  { value: "Cochran (TX)", label: "Cochran (TX)" },
  { value: "Coke (TX)", label: "Coke (TX)" },
  { value: "Coleman (TX)", label: "Coleman (TX)" },
  { value: "Collin (TX)", label: "Collin (TX)" },
  { value: "Collingsworth (TX)", label: "Collingsworth (TX)" },
  { value: "Colorado (TX)", label: "Colorado (TX)" },
  { value: "Comal (TX)", label: "Comal (TX)" },
  { value: "Comanche (TX)", label: "Comanche (TX)" },
  { value: "Concho (TX)", label: "Concho (TX)" },
  { value: "Cooke (TX)", label: "Cooke (TX)" },
  { value: "Coryell (TX)", label: "Coryell (TX)" },
  { value: "Cottle (TX)", label: "Cottle (TX)" },
  { value: "Crane (TX)", label: "Crane (TX)" },
  { value: "Crockett (TX)", label: "Crockett (TX)" },
  { value: "Crosby (TX)", label: "Crosby (TX)" },
  { value: "Culberson (TX)", label: "Culberson (TX)" },
  { value: "Dallam (TX)", label: "Dallam (TX)" },
  { value: "Dallas (TX)", label: "Dallas (TX)" },
  { value: "Dawson (TX)", label: "Dawson (TX)" },
  { value: "Deaf Smith (TX)", label: "Deaf Smith (TX)" },
  { value: "Delta (TX)", label: "Delta (TX)" },
  { value: "Denton (TX)", label: "Denton (TX)" },
  { value: "DeWitt (TX)", label: "DeWitt (TX)" },
  { value: "Dickens (TX)", label: "Dickens (TX)" },
  { value: "Dimmit (TX)", label: "Dimmit (TX)" },
  { value: "Donley (TX)", label: "Donley (TX)" },
  { value: "Duval (TX)", label: "Duval (TX)" },
  { value: "Eastland (TX)", label: "Eastland (TX)" },
  { value: "Ector (TX)", label: "Ector (TX)" },
  { value: "Edwards (TX)", label: "Edwards (TX)" },
  { value: "Ellis (TX)", label: "Ellis (TX)" },
  { value: "El Paso (TX)", label: "El Paso (TX)" },
  { value: "Erath (TX)", label: "Erath (TX)" },
  { value: "Falls (TX)", label: "Falls (TX)" },
  { value: "Fannin (TX)", label: "Fannin (TX)" },
  { value: "Fayette (TX)", label: "Fayette (TX)" },
  { value: "Fisher (TX)", label: "Fisher (TX)" },
  { value: "Floyd (TX)", label: "Floyd (TX)" },
  { value: "Foard (TX)", label: "Foard (TX)" },
  { value: "Fort Bend (TX)", label: "Fort Bend (TX)" },
  { value: "Franklin (TX)", label: "Franklin (TX)" },
  { value: "Freestone (TX)", label: "Freestone (TX)" },
  { value: "Frio (TX)", label: "Frio (TX)" },
  { value: "Gaines (TX)", label: "Gaines (TX)" },
  { value: "Galveston (TX)", label: "Galveston (TX)" },
  { value: "Garza (TX)", label: "Garza (TX)" },
  { value: "Gillespie (TX)", label: "Gillespie (TX)" },
  { value: "Glasscock (TX)", label: "Glasscock (TX)" },
  { value: "Goliad (TX)", label: "Goliad (TX)" },
  { value: "Gonzales (TX)", label: "Gonzales (TX)" },
  { value: "Gray (TX)", label: "Gray (TX)" },
  { value: "Grayson (TX)", label: "Grayson (TX)" },
  { value: "Gregg (TX)", label: "Gregg (TX)" },
  { value: "Grimes (TX)", label: "Grimes (TX)" },
  { value: "Guadalupe (TX)", label: "Guadalupe (TX)" },
  { value: "Hale (TX)", label: "Hale (TX)" },
  { value: "Hall (TX)", label: "Hall (TX)" },
  { value: "Hamilton (TX)", label: "Hamilton (TX)" },
  { value: "Hansford (TX)", label: "Hansford (TX)" },
  { value: "Hardeman (TX)", label: "Hardeman (TX)" },
  { value: "Hardin (TX)", label: "Hardin (TX)" },
  { value: "Harris (TX)", label: "Harris (TX)" },
  { value: "Harrison (TX)", label: "Harrison (TX)" },
  { value: "Hartley (TX)", label: "Hartley (TX)" },
  { value: "Haskell (TX)", label: "Haskell (TX)" },
  { value: "Hays (TX)", label: "Hays (TX)" },
  { value: "Hemphill (TX)", label: "Hemphill (TX)" },
  { value: "Henderson (TX)", label: "Henderson (TX)" },
  { value: "Hidalgo (TX)", label: "Hidalgo (TX)" },
  { value: "Hill (TX)", label: "Hill (TX)" },
  { value: "Hockley (TX)", label: "Hockley (TX)" },
  { value: "Hood (TX)", label: "Hood (TX)" },
  { value: "Hopkins (TX)", label: "Hopkins (TX)" },
  { value: "Houston (TX)", label: "Houston (TX)" },
  { value: "Howard (TX)", label: "Howard (TX)" },
  { value: "Hudspeth (TX)", label: "Hudspeth (TX)" },
  { value: "Hunt (TX)", label: "Hunt (TX)" },
  { value: "Hutchinson (TX)", label: "Hutchinson (TX)" },
  { value: "Irion (TX)", label: "Irion (TX)" },
  { value: "Jack (TX)", label: "Jack (TX)" },
  { value: "Jackson (TX)", label: "Jackson (TX)" },
  { value: "Jasper (TX)", label: "Jasper (TX)" },
  { value: "Jeff Davis (TX)", label: "Jeff Davis (TX)" },
  { value: "Jefferson (TX)", label: "Jefferson (TX)" },
  { value: "Jim Hogg (TX)", label: "Jim Hogg (TX)" },
  { value: "Jim Wells (TX)", label: "Jim Wells (TX)" },
  { value: "Johnson (TX)", label: "Johnson (TX)" },
  { value: "Jones (TX)", label: "Jones (TX)" },
  { value: "Karnes (TX)", label: "Karnes (TX)" },
  { value: "Kaufman (TX)", label: "Kaufman (TX)" },
  { value: "Kendall (TX)", label: "Kendall (TX)" },
  { value: "Kenedy (TX)", label: "Kenedy (TX)" },
  { value: "Kent (TX)", label: "Kent (TX)" },
  { value: "Kerr (TX)", label: "Kerr (TX)" },
  { value: "Kimble (TX)", label: "Kimble (TX)" },
  { value: "King (TX)", label: "King (TX)" },
  { value: "Kinney (TX)", label: "Kinney (TX)" },
  { value: "Kleberg (TX)", label: "Kleberg (TX)" },
  { value: "Knox (TX)", label: "Knox (TX)" },
  { value: "Lamar (TX)", label: "Lamar (TX)" },
  { value: "Lamb (TX)", label: "Lamb (TX)" },
  { value: "Lampasas (TX)", label: "Lampasas (TX)" },
  { value: "La Salle (TX)", label: "La Salle (TX)" },
  { value: "Lavaca (TX)", label: "Lavaca (TX)" },
  { value: "Lee (TX)", label: "Lee (TX)" },
  { value: "Leon (TX)", label: "Leon (TX)" },
  { value: "Liberty (TX)", label: "Liberty (TX)" },
  { value: "Limestone (TX)", label: "Limestone (TX)" },
  { value: "Lipscomb (TX)", label: "Lipscomb (TX)" },
  { value: "Live Oak (TX)", label: "Live Oak (TX)" },
  { value: "Llano (TX)", label: "Llano (TX)" },
  { value: "Loving (TX)", label: "Loving (TX)" },
  { value: "Lubbock (TX)", label: "Lubbock (TX)" },
  { value: "Lynn (TX)", label: "Lynn (TX)" },
  { value: "McCulloch (TX)", label: "McCulloch (TX)" },
  { value: "McLennan (TX)", label: "McLennan (TX)" },
  { value: "McMullen (TX)", label: "McMullen (TX)" },
  { value: "Madison (TX)", label: "Madison (TX)" },
  { value: "Marion (TX)", label: "Marion (TX)" },
  { value: "Martin (TX)", label: "Martin (TX)" },
  { value: "Mason (TX)", label: "Mason (TX)" },
  { value: "Matagorda (TX)", label: "Matagorda (TX)" },
  { value: "Maverick (TX)", label: "Maverick (TX)" },
  { value: "Medina (TX)", label: "Medina (TX)" },
  { value: "Menard (TX)", label: "Menard (TX)" },
  { value: "Midland (TX)", label: "Midland (TX)" },
  { value: "Milam (TX)", label: "Milam (TX)" },
  { value: "Mills (TX)", label: "Mills (TX)" },
  { value: "Mitchell (TX)", label: "Mitchell (TX)" },
  { value: "Montague (TX)", label: "Montague (TX)" },
  { value: "Montgomery (TX)", label: "Montgomery (TX)" },
  { value: "Moore (TX)", label: "Moore (TX)" },
  { value: "Morris (TX)", label: "Morris (TX)" },
  { value: "Motley (TX)", label: "Motley (TX)" },
  { value: "Nacogdoches (TX)", label: "Nacogdoches (TX)" },
  { value: "Navarro (TX)", label: "Navarro (TX)" },
  { value: "Newton (TX)", label: "Newton (TX)" },
  { value: "Nolan (TX)", label: "Nolan (TX)" },
  { value: "Nueces (TX)", label: "Nueces (TX)" },
  { value: "Ochiltree (TX)", label: "Ochiltree (TX)" },
  { value: "Oldham (TX)", label: "Oldham (TX)" },
  { value: "Orange (TX)", label: "Orange (TX)" },
  { value: "Palo Pinto (TX)", label: "Palo Pinto (TX)" },
  { value: "Panola (TX)", label: "Panola (TX)" },
  { value: "Parker (TX)", label: "Parker (TX)" },
  { value: "Parmer (TX)", label: "Parmer (TX)" },
  { value: "Pecos (TX)", label: "Pecos (TX)" },
  { value: "Polk (TX)", label: "Polk (TX)" },
  { value: "Potter (TX)", label: "Potter (TX)" },
  { value: "Presidio (TX)", label: "Presidio (TX)" },
  { value: "Rains (TX)", label: "Rains (TX)" },
  { value: "Randall (TX)", label: "Randall (TX)" },
  { value: "Reagan (TX)", label: "Reagan (TX)" },
  { value: "Real (TX)", label: "Real (TX)" },
  { value: "Red River (TX)", label: "Red River (TX)" },
  { value: "Reeves (TX)", label: "Reeves (TX)" },
  { value: "Refugio (TX)", label: "Refugio (TX)" },
  { value: "Roberts (TX)", label: "Roberts (TX)" },
  { value: "Robertson (TX)", label: "Robertson (TX)" },
  { value: "Rockwall (TX)", label: "Rockwall (TX)" },
  { value: "Runnels (TX)", label: "Runnels (TX)" },
  { value: "Rusk (TX)", label: "Rusk (TX)" },
  { value: "Sabine (TX)", label: "Sabine (TX)" },
  { value: "San Augustine (TX)", label: "San Augustine (TX)" },
  { value: "San Jacinto (TX)", label: "San Jacinto (TX)" },
  { value: "San Patricio (TX)", label: "San Patricio (TX)" },
  { value: "San Saba (TX)", label: "San Saba (TX)" },
  { value: "Schleicher (TX)", label: "Schleicher (TX)" },
  { value: "Scurry (TX)", label: "Scurry (TX)" },
  { value: "Shackelford (TX)", label: "Shackelford (TX)" },
  { value: "Shelby (TX)", label: "Shelby (TX)" },
  { value: "Sherman (TX)", label: "Sherman (TX)" },
  { value: "Smith (TX)", label: "Smith (TX)" },
  { value: "Somervell (TX)", label: "Somervell (TX)" },
  { value: "Starr (TX)", label: "Starr (TX)" },
  { value: "Stephens (TX)", label: "Stephens (TX)" },
  { value: "Sterling (TX)", label: "Sterling (TX)" },
  { value: "Stonewall (TX)", label: "Stonewall (TX)" },
  { value: "Sutton (TX)", label: "Sutton (TX)" },
  { value: "Swisher (TX)", label: "Swisher (TX)" },
  { value: "Tarrant (TX)", label: "Tarrant (TX)" },
  { value: "Taylor (TX)", label: "Taylor (TX)" },
  { value: "Terrell (TX)", label: "Terrell (TX)" },
  { value: "Terry (TX)", label: "Terry (TX)" },
  { value: "Throckmorton (TX)", label: "Throckmorton (TX)" },
  { value: "Titus (TX)", label: "Titus (TX)" },
  { value: "Tom Green (TX)", label: "Tom Green (TX)" },
  { value: "Travis (TX)", label: "Travis (TX)" },
  { value: "Trinity (TX)", label: "Trinity (TX)" },
  { value: "Tyler (TX)", label: "Tyler (TX)" },
  { value: "Upshur (TX)", label: "Upshur (TX)" },
  { value: "Upton (TX)", label: "Upton (TX)" },
  { value: "Uvalde (TX)", label: "Uvalde (TX)" },
  { value: "Val Verde (TX)", label: "Val Verde (TX)" },
  { value: "Van Zandt (TX)", label: "Van Zandt (TX)" },
  { value: "Victoria (TX)", label: "Victoria (TX)" },
  { value: "Walker (TX)", label: "Walker (TX)" },
  { value: "Waller (TX)", label: "Waller (TX)" },
  { value: "Ward (TX)", label: "Ward (TX)" },
  { value: "Washington (TX)", label: "Washington (TX)" },
  { value: "Webb (TX)", label: "Webb (TX)" },
  { value: "Wharton (TX)", label: "Wharton (TX)" },
  { value: "Wheeler (TX)", label: "Wheeler (TX)" },
  { value: "Wichita (TX)", label: "Wichita (TX)" },
  { value: "Wilbarger (TX)", label: "Wilbarger (TX)" },
  { value: "Willacy (TX)", label: "Willacy (TX)" },
  { value: "Williamson (TX)", label: "Williamson (TX)" },
  { value: "Wilson (TX)", label: "Wilson (TX)" },
  { value: "Winkler (TX)", label: "Winkler (TX)" },
  { value: "Wise (TX)", label: "Wise (TX)" },
  { value: "Wood (TX)", label: "Wood (TX)" },
  { value: "Yoakum (TX)", label: "Yoakum (TX)" },
  { value: "Young (TX)", label: "Young (TX)" },
  { value: "Zapata (TX)", label: "Zapata (TX)" },
  { value: "Zavala (TX)", label: "Zavala (TX)" },
  { value: "Beaver (UT)", label: "Beaver (UT)" },
  { value: "Box Elder (UT)", label: "Box Elder (UT)" },
  { value: "Cache (UT)", label: "Cache (UT)" },
  { value: "Carbon (UT)", label: "Carbon (UT)" },
  { value: "Daggett (UT)", label: "Daggett (UT)" },
  { value: "Davis (UT)", label: "Davis (UT)" },
  { value: "Duchesne (UT)", label: "Duchesne (UT)" },
  { value: "Emery (UT)", label: "Emery (UT)" },
  { value: "Garfield (UT)", label: "Garfield (UT)" },
  { value: "Grand (UT)", label: "Grand (UT)" },
  { value: "Iron (UT)", label: "Iron (UT)" },
  { value: "Juab (UT)", label: "Juab (UT)" },
  { value: "Kane (UT)", label: "Kane (UT)" },
  { value: "Millard (UT)", label: "Millard (UT)" },
  { value: "Morgan (UT)", label: "Morgan (UT)" },
  { value: "Piute (UT)", label: "Piute (UT)" },
  { value: "Rich (UT)", label: "Rich (UT)" },
  { value: "Salt Lake (UT)", label: "Salt Lake (UT)" },
  { value: "San Juan (UT)", label: "San Juan (UT)" },
  { value: "Sanpete (UT)", label: "Sanpete (UT)" },
  { value: "Sevier (UT)", label: "Sevier (UT)" },
  { value: "Summit (UT)", label: "Summit (UT)" },
  { value: "Tooele (UT)", label: "Tooele (UT)" },
  { value: "Uintah (UT)", label: "Uintah (UT)" },
  { value: "Utah (UT)", label: "Utah (UT)" },
  { value: "Wasatch (UT)", label: "Wasatch (UT)" },
  { value: "Washington (UT)", label: "Washington (UT)" },
  { value: "Wayne (UT)", label: "Wayne (UT)" },
  { value: "Weber (UT)", label: "Weber (UT)" },
  { value: "Addison (VT)", label: "Addison (VT)" },
  { value: "Bennington (VT)", label: "Bennington (VT)" },
  { value: "Caledonia (VT)", label: "Caledonia (VT)" },
  { value: "Chittenden (VT)", label: "Chittenden (VT)" },
  { value: "Essex (VT)", label: "Essex (VT)" },
  { value: "Franklin (VT)", label: "Franklin (VT)" },
  { value: "Grand Isle (VT)", label: "Grand Isle (VT)" },
  { value: "Lamoille (VT)", label: "Lamoille (VT)" },
  { value: "Orange (VT)", label: "Orange (VT)" },
  { value: "Orleans (VT)", label: "Orleans (VT)" },
  { value: "Rutland (VT)", label: "Rutland (VT)" },
  { value: "Washington (VT)", label: "Washington (VT)" },
  { value: "Windham (VT)", label: "Windham (VT)" },
  { value: "Windsor (VT)", label: "Windsor (VT)" },
  { value: "Accomack (VA)", label: "Accomack (VA)" },
  { value: "Albemarle (VA)", label: "Albemarle (VA)" },
  { value: "Alleghany (VA)", label: "Alleghany (VA)" },
  { value: "Amelia (VA)", label: "Amelia (VA)" },
  { value: "Amherst (VA)", label: "Amherst (VA)" },
  { value: "Appomattox (VA)", label: "Appomattox (VA)" },
  { value: "Arlington (VA)", label: "Arlington (VA)" },
  { value: "Augusta (VA)", label: "Augusta (VA)" },
  { value: "Bath (VA)", label: "Bath (VA)" },
  { value: "Bedford (VA)", label: "Bedford (VA)" },
  { value: "Bland (VA)", label: "Bland (VA)" },
  { value: "Botetourt (VA)", label: "Botetourt (VA)" },
  { value: "Brunswick (VA)", label: "Brunswick (VA)" },
  { value: "Buchanan (VA)", label: "Buchanan (VA)" },
  { value: "Buckingham (VA)", label: "Buckingham (VA)" },
  { value: "Campbell (VA)", label: "Campbell (VA)" },
  { value: "Caroline (VA)", label: "Caroline (VA)" },
  { value: "Carroll (VA)", label: "Carroll (VA)" },
  { value: "Charles City (VA)", label: "Charles City (VA)" },
  { value: "Charlotte (VA)", label: "Charlotte (VA)" },
  { value: "Chesterfield (VA)", label: "Chesterfield (VA)" },
  { value: "Clarke (VA)", label: "Clarke (VA)" },
  { value: "Craig (VA)", label: "Craig (VA)" },
  { value: "Culpeper (VA)", label: "Culpeper (VA)" },
  { value: "Cumberland (VA)", label: "Cumberland (VA)" },
  { value: "Dickenson (VA)", label: "Dickenson (VA)" },
  { value: "Dinwiddie (VA)", label: "Dinwiddie (VA)" },
  { value: "Essex (VA)", label: "Essex (VA)" },
  { value: "Fairfax (VA)", label: "Fairfax (VA)" },
  { value: "Fauquier (VA)", label: "Fauquier (VA)" },
  { value: "Floyd (VA)", label: "Floyd (VA)" },
  { value: "Fluvanna (VA)", label: "Fluvanna (VA)" },
  { value: "Franklin (VA)", label: "Franklin (VA)" },
  { value: "Frederick (VA)", label: "Frederick (VA)" },
  { value: "Giles (VA)", label: "Giles (VA)" },
  { value: "Gloucester (VA)", label: "Gloucester (VA)" },
  { value: "Goochland (VA)", label: "Goochland (VA)" },
  { value: "Grayson (VA)", label: "Grayson (VA)" },
  { value: "Greene (VA)", label: "Greene (VA)" },
  { value: "Greensville (VA)", label: "Greensville (VA)" },
  { value: "Halifax (VA)", label: "Halifax (VA)" },
  { value: "Hanover (VA)", label: "Hanover (VA)" },
  { value: "Henrico (VA)", label: "Henrico (VA)" },
  { value: "Henry (VA)", label: "Henry (VA)" },
  { value: "Highland (VA)", label: "Highland (VA)" },
  { value: "Isle of Wight (VA)", label: "Isle of Wight (VA)" },
  { value: "James City (VA)", label: "James City (VA)" },
  { value: "King and Queen (VA)", label: "King and Queen (VA)" },
  { value: "King George (VA)", label: "King George (VA)" },
  { value: "King William (VA)", label: "King William (VA)" },
  { value: "Lancaster (VA)", label: "Lancaster (VA)" },
  { value: "Lee (VA)", label: "Lee (VA)" },
  { value: "Loudoun (VA)", label: "Loudoun (VA)" },
  { value: "Louisa (VA)", label: "Louisa (VA)" },
  { value: "Lunenburg (VA)", label: "Lunenburg (VA)" },
  { value: "Madison (VA)", label: "Madison (VA)" },
  { value: "Mathews (VA)", label: "Mathews (VA)" },
  { value: "Mecklenburg (VA)", label: "Mecklenburg (VA)" },
  { value: "Middlesex (VA)", label: "Middlesex (VA)" },
  { value: "Montgomery (VA)", label: "Montgomery (VA)" },
  { value: "Nelson (VA)", label: "Nelson (VA)" },
  { value: "New Kent (VA)", label: "New Kent (VA)" },
  { value: "Northampton (VA)", label: "Northampton (VA)" },
  { value: "Northumberland (VA)", label: "Northumberland (VA)" },
  { value: "Nottoway (VA)", label: "Nottoway (VA)" },
  { value: "Orange (VA)", label: "Orange (VA)" },
  { value: "Page (VA)", label: "Page (VA)" },
  { value: "Patrick (VA)", label: "Patrick (VA)" },
  { value: "Pittsylvania (VA)", label: "Pittsylvania (VA)" },
  { value: "Powhatan (VA)", label: "Powhatan (VA)" },
  { value: "Prince Edward (VA)", label: "Prince Edward (VA)" },
  { value: "Prince George (VA)", label: "Prince George (VA)" },
  { value: "Prince William (VA)", label: "Prince William (VA)" },
  { value: "Pulaski (VA)", label: "Pulaski (VA)" },
  { value: "Rappahannock (VA)", label: "Rappahannock (VA)" },
  { value: "Richmond (VA)", label: "Richmond (VA)" },
  { value: "Roanoke (VA)", label: "Roanoke (VA)" },
  { value: "Rockbridge (VA)", label: "Rockbridge (VA)" },
  { value: "Rockingham (VA)", label: "Rockingham (VA)" },
  { value: "Russell (VA)", label: "Russell (VA)" },
  { value: "Scott (VA)", label: "Scott (VA)" },
  { value: "Shenandoah (VA)", label: "Shenandoah (VA)" },
  { value: "Smyth (VA)", label: "Smyth (VA)" },
  { value: "Southampton (VA)", label: "Southampton (VA)" },
  { value: "Spotsylvania (VA)", label: "Spotsylvania (VA)" },
  { value: "Stafford (VA)", label: "Stafford (VA)" },
  { value: "Surry (VA)", label: "Surry (VA)" },
  { value: "Sussex (VA)", label: "Sussex (VA)" },
  { value: "Tazewell (VA)", label: "Tazewell (VA)" },
  { value: "Warren (VA)", label: "Warren (VA)" },
  { value: "Washington (VA)", label: "Washington (VA)" },
  { value: "Westmoreland (VA)", label: "Westmoreland (VA)" },
  { value: "Wise (VA)", label: "Wise (VA)" },
  { value: "Wythe (VA)", label: "Wythe (VA)" },
  { value: "York (VA)", label: "York (VA)" },
  { value: "Alexandria city (VA)", label: "Alexandria city (VA)" },
  { value: "Bedford city (VA)", label: "Bedford city (VA)" },
  { value: "Bristol city (VA)", label: "Bristol city (VA)" },
  { value: "Buena Vista city (VA)", label: "Buena Vista city (VA)" },
  { value: "Charlottesville city (VA)", label: "Charlottesville city (VA)" },
  { value: "Chesapeake city (VA)", label: "Chesapeake city (VA)" },
  { value: "Colonial Heights city (VA)", label: "Colonial Heights city (VA)" },
  { value: "Covington city (VA)", label: "Covington city (VA)" },
  { value: "Danville city (VA)", label: "Danville city (VA)" },
  { value: "Emporia city (VA)", label: "Emporia city (VA)" },
  { value: "Fairfax city (VA)", label: "Fairfax city (VA)" },
  { value: "Falls Church city (VA)", label: "Falls Church city (VA)" },
  { value: "Franklin city (VA)", label: "Franklin city (VA)" },
  { value: "Fredericksburg city (VA)", label: "Fredericksburg city (VA)" },
  { value: "Galax city (VA)", label: "Galax city (VA)" },
  { value: "Hampton city (VA)", label: "Hampton city (VA)" },
  { value: "Harrisonburg city (VA)", label: "Harrisonburg city (VA)" },
  { value: "Hopewell city (VA)", label: "Hopewell city (VA)" },
  { value: "Lexington city (VA)", label: "Lexington city (VA)" },
  { value: "Lynchburg city (VA)", label: "Lynchburg city (VA)" },
  { value: "Manassas city (VA)", label: "Manassas city (VA)" },
  { value: "Manassas Park city (VA)", label: "Manassas Park city (VA)" },
  { value: "Martinsville city (VA)", label: "Martinsville city (VA)" },
  { value: "Newport News city (VA)", label: "Newport News city (VA)" },
  { value: "Norfolk city (VA)", label: "Norfolk city (VA)" },
  { value: "Norton city (VA)", label: "Norton city (VA)" },
  { value: "Petersburg city (VA)", label: "Petersburg city (VA)" },
  { value: "Poquoson city (VA)", label: "Poquoson city (VA)" },
  { value: "Portsmouth city (VA)", label: "Portsmouth city (VA)" },
  { value: "Radford city (VA)", label: "Radford city (VA)" },
  { value: "Richmond city (VA)", label: "Richmond city (VA)" },
  { value: "Roanoke city (VA)", label: "Roanoke city (VA)" },
  { value: "Salem city (VA)", label: "Salem city (VA)" },
  { value: "Staunton city (VA)", label: "Staunton city (VA)" },
  { value: "Suffolk city (VA)", label: "Suffolk city (VA)" },
  { value: "Virginia Beach city (VA)", label: "Virginia Beach city (VA)" },
  { value: "Waynesboro city (VA)", label: "Waynesboro city (VA)" },
  { value: "Williamsburg city (VA)", label: "Williamsburg city (VA)" },
  { value: "Winchester city (VA)", label: "Winchester city (VA)" },
  { value: "Adams (WA)", label: "Adams (WA)" },
  { value: "Asotin (WA)", label: "Asotin (WA)" },
  { value: "Benton (WA)", label: "Benton (WA)" },
  { value: "Chelan (WA)", label: "Chelan (WA)" },
  { value: "Clallam (WA)", label: "Clallam (WA)" },
  { value: "Clark (WA)", label: "Clark (WA)" },
  { value: "Columbia (WA)", label: "Columbia (WA)" },
  { value: "Cowlitz (WA)", label: "Cowlitz (WA)" },
  { value: "Douglas (WA)", label: "Douglas (WA)" },
  { value: "Ferry (WA)", label: "Ferry (WA)" },
  { value: "Franklin (WA)", label: "Franklin (WA)" },
  { value: "Garfield (WA)", label: "Garfield (WA)" },
  { value: "Grant (WA)", label: "Grant (WA)" },
  { value: "Grays Harbor (WA)", label: "Grays Harbor (WA)" },
  { value: "Island (WA)", label: "Island (WA)" },
  { value: "Jefferson (WA)", label: "Jefferson (WA)" },
  { value: "King (WA)", label: "King (WA)" },
  { value: "Kitsap (WA)", label: "Kitsap (WA)" },
  { value: "Kittitas (WA)", label: "Kittitas (WA)" },
  { value: "Klickitat (WA)", label: "Klickitat (WA)" },
  { value: "Lewis (WA)", label: "Lewis (WA)" },
  { value: "Lincoln (WA)", label: "Lincoln (WA)" },
  { value: "Mason (WA)", label: "Mason (WA)" },
  { value: "Okanogan (WA)", label: "Okanogan (WA)" },
  { value: "Pacific (WA)", label: "Pacific (WA)" },
  { value: "Pend Oreille (WA)", label: "Pend Oreille (WA)" },
  { value: "Pierce (WA)", label: "Pierce (WA)" },
  { value: "San Juan (WA)", label: "San Juan (WA)" },
  { value: "Skagit (WA)", label: "Skagit (WA)" },
  { value: "Skamania (WA)", label: "Skamania (WA)" },
  { value: "Snohomish (WA)", label: "Snohomish (WA)" },
  { value: "Spokane (WA)", label: "Spokane (WA)" },
  { value: "Stevens (WA)", label: "Stevens (WA)" },
  { value: "Thurston (WA)", label: "Thurston (WA)" },
  { value: "Wahkiakum (WA)", label: "Wahkiakum (WA)" },
  { value: "Walla Walla (WA)", label: "Walla Walla (WA)" },
  { value: "Whatcom (WA)", label: "Whatcom (WA)" },
  { value: "Whitman (WA)", label: "Whitman (WA)" },
  { value: "Yakima (WA)", label: "Yakima (WA)" },
  { value: "Barbour (WV)", label: "Barbour (WV)" },
  { value: "Berkeley (WV)", label: "Berkeley (WV)" },
  { value: "Boone (WV)", label: "Boone (WV)" },
  { value: "Braxton (WV)", label: "Braxton (WV)" },
  { value: "Brooke (WV)", label: "Brooke (WV)" },
  { value: "Cabell (WV)", label: "Cabell (WV)" },
  { value: "Calhoun (WV)", label: "Calhoun (WV)" },
  { value: "Clay (WV)", label: "Clay (WV)" },
  { value: "Doddridge (WV)", label: "Doddridge (WV)" },
  { value: "Fayette (WV)", label: "Fayette (WV)" },
  { value: "Gilmer (WV)", label: "Gilmer (WV)" },
  { value: "Grant (WV)", label: "Grant (WV)" },
  { value: "Greenbrier (WV)", label: "Greenbrier (WV)" },
  { value: "Hampshire (WV)", label: "Hampshire (WV)" },
  { value: "Hancock (WV)", label: "Hancock (WV)" },
  { value: "Hardy (WV)", label: "Hardy (WV)" },
  { value: "Harrison (WV)", label: "Harrison (WV)" },
  { value: "Jackson (WV)", label: "Jackson (WV)" },
  { value: "Jefferson (WV)", label: "Jefferson (WV)" },
  { value: "Kanawha (WV)", label: "Kanawha (WV)" },
  { value: "Lewis (WV)", label: "Lewis (WV)" },
  { value: "Lincoln (WV)", label: "Lincoln (WV)" },
  { value: "Logan (WV)", label: "Logan (WV)" },
  { value: "McDowell (WV)", label: "McDowell (WV)" },
  { value: "Marion (WV)", label: "Marion (WV)" },
  { value: "Marshall (WV)", label: "Marshall (WV)" },
  { value: "Mason (WV)", label: "Mason (WV)" },
  { value: "Mercer (WV)", label: "Mercer (WV)" },
  { value: "Mineral (WV)", label: "Mineral (WV)" },
  { value: "Mingo (WV)", label: "Mingo (WV)" },
  { value: "Monongalia (WV)", label: "Monongalia (WV)" },
  { value: "Monroe (WV)", label: "Monroe (WV)" },
  { value: "Morgan (WV)", label: "Morgan (WV)" },
  { value: "Nicholas (WV)", label: "Nicholas (WV)" },
  { value: "Ohio (WV)", label: "Ohio (WV)" },
  { value: "Pendleton (WV)", label: "Pendleton (WV)" },
  { value: "Pleasants (WV)", label: "Pleasants (WV)" },
  { value: "Pocahontas (WV)", label: "Pocahontas (WV)" },
  { value: "Preston (WV)", label: "Preston (WV)" },
  { value: "Putnam (WV)", label: "Putnam (WV)" },
  { value: "Raleigh (WV)", label: "Raleigh (WV)" },
  { value: "Randolph (WV)", label: "Randolph (WV)" },
  { value: "Ritchie (WV)", label: "Ritchie (WV)" },
  { value: "Roane (WV)", label: "Roane (WV)" },
  { value: "Summers (WV)", label: "Summers (WV)" },
  { value: "Taylor (WV)", label: "Taylor (WV)" },
  { value: "Tucker (WV)", label: "Tucker (WV)" },
  { value: "Tyler (WV)", label: "Tyler (WV)" },
  { value: "Upshur (WV)", label: "Upshur (WV)" },
  { value: "Wayne (WV)", label: "Wayne (WV)" },
  { value: "Webster (WV)", label: "Webster (WV)" },
  { value: "Wetzel (WV)", label: "Wetzel (WV)" },
  { value: "Wirt (WV)", label: "Wirt (WV)" },
  { value: "Wood (WV)", label: "Wood (WV)" },
  { value: "Wyoming (WV)", label: "Wyoming (WV)" },
  { value: "Adams (WI)", label: "Adams (WI)" },
  { value: "Ashland (WI)", label: "Ashland (WI)" },
  { value: "Barron (WI)", label: "Barron (WI)" },
  { value: "Bayfield (WI)", label: "Bayfield (WI)" },
  { value: "Brown (WI)", label: "Brown (WI)" },
  { value: "Buffalo (WI)", label: "Buffalo (WI)" },
  { value: "Burnett (WI)", label: "Burnett (WI)" },
  { value: "Calumet (WI)", label: "Calumet (WI)" },
  { value: "Chippewa (WI)", label: "Chippewa (WI)" },
  { value: "Clark (WI)", label: "Clark (WI)" },
  { value: "Columbia (WI)", label: "Columbia (WI)" },
  { value: "Crawford (WI)", label: "Crawford (WI)" },
  { value: "Dane (WI)", label: "Dane (WI)" },
  { value: "Dodge (WI)", label: "Dodge (WI)" },
  { value: "Door (WI)", label: "Door (WI)" },
  { value: "Douglas (WI)", label: "Douglas (WI)" },
  { value: "Dunn (WI)", label: "Dunn (WI)" },
  { value: "Eau Claire (WI)", label: "Eau Claire (WI)" },
  { value: "Florence (WI)", label: "Florence (WI)" },
  { value: "Fond du Lac (WI)", label: "Fond du Lac (WI)" },
  { value: "Forest (WI)", label: "Forest (WI)" },
  { value: "Grant (WI)", label: "Grant (WI)" },
  { value: "Green (WI)", label: "Green (WI)" },
  { value: "Green Lake (WI)", label: "Green Lake (WI)" },
  { value: "Iowa (WI)", label: "Iowa (WI)" },
  { value: "Iron (WI)", label: "Iron (WI)" },
  { value: "Jackson (WI)", label: "Jackson (WI)" },
  { value: "Jefferson (WI)", label: "Jefferson (WI)" },
  { value: "Juneau (WI)", label: "Juneau (WI)" },
  { value: "Kenosha (WI)", label: "Kenosha (WI)" },
  { value: "Kewaunee (WI)", label: "Kewaunee (WI)" },
  { value: "La Crosse (WI)", label: "La Crosse (WI)" },
  { value: "Lafayette (WI)", label: "Lafayette (WI)" },
  { value: "Langlade (WI)", label: "Langlade (WI)" },
  { value: "Lincoln (WI)", label: "Lincoln (WI)" },
  { value: "Manitowoc (WI)", label: "Manitowoc (WI)" },
  { value: "Marathon (WI)", label: "Marathon (WI)" },
  { value: "Marinette (WI)", label: "Marinette (WI)" },
  { value: "Marquette (WI)", label: "Marquette (WI)" },
  { value: "Menominee (WI)", label: "Menominee (WI)" },
  { value: "Milwaukee (WI)", label: "Milwaukee (WI)" },
  { value: "Monroe (WI)", label: "Monroe (WI)" },
  { value: "Oconto (WI)", label: "Oconto (WI)" },
  { value: "Oneida (WI)", label: "Oneida (WI)" },
  { value: "Outagamie (WI)", label: "Outagamie (WI)" },
  { value: "Ozaukee (WI)", label: "Ozaukee (WI)" },
  { value: "Pepin (WI)", label: "Pepin (WI)" },
  { value: "Pierce (WI)", label: "Pierce (WI)" },
  { value: "Polk (WI)", label: "Polk (WI)" },
  { value: "Portage (WI)", label: "Portage (WI)" },
  { value: "Price (WI)", label: "Price (WI)" },
  { value: "Racine (WI)", label: "Racine (WI)" },
  { value: "Richland (WI)", label: "Richland (WI)" },
  { value: "Rock (WI)", label: "Rock (WI)" },
  { value: "Rusk (WI)", label: "Rusk (WI)" },
  { value: "St. Croix (WI)", label: "St. Croix (WI)" },
  { value: "Sauk (WI)", label: "Sauk (WI)" },
  { value: "Sawyer (WI)", label: "Sawyer (WI)" },
  { value: "Shawano (WI)", label: "Shawano (WI)" },
  { value: "Sheboygan (WI)", label: "Sheboygan (WI)" },
  { value: "Taylor (WI)", label: "Taylor (WI)" },
  { value: "Trempealeau (WI)", label: "Trempealeau (WI)" },
  { value: "Vernon (WI)", label: "Vernon (WI)" },
  { value: "Vilas (WI)", label: "Vilas (WI)" },
  { value: "Walworth (WI)", label: "Walworth (WI)" },
  { value: "Washburn (WI)", label: "Washburn (WI)" },
  { value: "Washington (WI)", label: "Washington (WI)" },
  { value: "Waukesha (WI)", label: "Waukesha (WI)" },
  { value: "Waupaca (WI)", label: "Waupaca (WI)" },
  { value: "Waushara (WI)", label: "Waushara (WI)" },
  { value: "Winnebago (WI)", label: "Winnebago (WI)" },
  { value: "Wood (WI)", label: "Wood (WI)" },
  { value: "Albany (WY)", label: "Albany (WY)" },
  { value: "Big Horn (WY)", label: "Big Horn (WY)" },
  { value: "Campbell (WY)", label: "Campbell (WY)" },
  { value: "Carbon (WY)", label: "Carbon (WY)" },
  { value: "Converse (WY)", label: "Converse (WY)" },
  { value: "Crook (WY)", label: "Crook (WY)" },
  { value: "Fremont (WY)", label: "Fremont (WY)" },
  { value: "Goshen (WY)", label: "Goshen (WY)" },
  { value: "Hot Springs (WY)", label: "Hot Springs (WY)" },
  { value: "Johnson (WY)", label: "Johnson (WY)" },
  { value: "Laramie (WY)", label: "Laramie (WY)" },
  { value: "Lincoln (WY)", label: "Lincoln (WY)" },
  { value: "Natrona (WY)", label: "Natrona (WY)" },
  { value: "Niobrara (WY)", label: "Niobrara (WY)" },
  { value: "Park (WY)", label: "Park (WY)" },
  { value: "Platte (WY)", label: "Platte (WY)" },
  { value: "Sheridan (WY)", label: "Sheridan (WY)" },
  { value: "Sublette (WY)", label: "Sublette (WY)" },
  { value: "Sweetwater (WY)", label: "Sweetwater (WY)" },
  { value: "Teton (WY)", label: "Teton (WY)" },
  { value: "Uinta (WY)", label: "Uinta (WY)" },
  { value: "Washakie (WY)", label: "Washakie (WY)" },
  { value: "Weston (WY)", label: "Weston (WY)" },
];
