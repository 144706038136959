import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";


import { Col, Row } from "react-bootstrap";

const BillingPaymentCardElement = ({ confirmResult }) => {

  // props.confirmResult  ... defined in main component

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element

      elements,
      redirect: "if_required",
    });

    // Return back the result to parent component
    if(result.error){
      setErrorMessage(result.error.message)
    }else{
      confirmResult(result);
    }

    // if (result.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log(result.error.message);
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
        {errorMessage ? (
        <div className="alert alert-danger">{errorMessage}</div>
      ) : (
        ""
      )}
      <PaymentElement />
      <Row>
        <Col xs={11} sm={10} md={8} lg={6} xl={6} className="mx-auto mt-4">
          <button className="submit w-100" disabled={!stripe}>Next</button>
        </Col>
      </Row>
    </form>
  );
};

export default BillingPaymentCardElement;
