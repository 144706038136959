import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Modal,
  Image,
  Card,
} from "react-bootstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import { AiFillCreditCard } from "react-icons/ai";
import { AddBillingModal } from "./add-billing-modal";
// import { STRIPE_SK } from "../../Constants/api-credentials.constant";
import {
  createStripePaymentIntent,
  getActiveBilling,
  newPlanPurchased,
  paymentFailed,
} from "../../Utils/billing.util";
import {
  GetFormattedCurrency,
  formatCardEndFourDigits,
  getFormattedCurrencyInInteger,
} from "../../Utils/formating.util";
import {
  resetSelectedPlan,
  updateCurrentBilling,
} from "../../store/slices/features/payment-plan";
import {
  getCurrentBilling,
  getCurrentPlanData,
  getSelectedPlan,
  getSelectedPlanId,
  getSelectedPlanPriceCalculate,
  getSelectedPlanTitle,
} from "../../store/selectors/features/payment-plan";
import visaIcon from "./../../assets/new_theme/img/payment/visa.png";
import discoverIcon from "./../../assets/new_theme/img/payment/discover.png";
import mastercardIcon from "./../../assets/new_theme/img/payment/card.png";
import jbccardIcon from "./../../assets/new_theme/img/payment/jcb.png";
import linkIcon from "./../../assets/new_theme/img/payment/link.png";
import amexIcon from "./../../assets/new_theme/img/payment/amex.png";
import addIcon from "./../../assets/new_theme/img/add.png";

const BillingDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const selectedPlan = useSelector(getSelectedPlan);
  const features = selectedPlan?.features?.split("\n") || [];

  const planId = useSelector(getSelectedPlanId);
  const planTitle = useSelector(getSelectedPlanTitle);
  const planPrice = useSelector(getSelectedPlanPriceCalculate);

  const currentPlanData = useSelector(getCurrentPlanData);
  const currentPlanPrice = currentPlanData?.price || 0;

  const activeBilling = useSelector(getCurrentBilling);
  const stripePaymentId = activeBilling?.stripePaymentMethodId;
  const stripeCustomerId = activeBilling?.stripeCustomerId;
  const stripeClientId = activeBilling?.clientId;

  const [title, setTitle] = useState("Checkout");
  const [showLoader, setShowLoader] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);

  const handleCloseConfirmModal = () => setShowConfirm(false);
  const handleShowConfirmModal = () => setShowConfirm(true);

  const handleCloseAddCardModal = () => setShowAddCard(false);
  const handleShowAddCardModal = () => {
    //localStorage.setItem("payment-card-process", true);
    setShowAddCard(true);
  };

  const handleBackClick = () => {
    dispatch(resetSelectedPlan());
  };

  const location = useLocation();
  const refButtonSubmitPayment = useRef(null);

  useEffect(() => {
    getActiveBillingData();

    const handlePayment = async () => {
      console.log("executing payment");

      setTimeout(function () {
        refButtonSubmitPayment.current.click();
        //document.getElementById('btnSubmitPayment').click();
      }, 5000);
    };

    //refButtonSubmitPayment.current.addEventListener('click', handlePayment);

    window.addEventListener("goto-billing", handlePayment);
    localStorage.setItem("payment-in-process", false);
    localStorage.setItem("payment-card-process", false);
    return () => {
      window.removeEventListener("goto-billing", handlePayment);
    };
  }, []);

  const getActiveBillingData = async () => {
    setShowLoader(true);
    try {
      const response = await getActiveBilling();

      if (response?.statusCode == 200 && response?.result) {
        dispatch(updateCurrentBilling(response.result));
      }

      setShowLoader(false);
    } catch (error) {
      console.log("error", error);
      setShowLoader(false);
    }
  };

  const paymentFaied = async (message) => {
    setStripeErrorMsg(message);
    setShowLoader(false);

    try {
      const result = await paymentFailed();
    } catch (error) {
      console.log(error);
    }
  };

  const newPlanBuy = async (stripeResult) => {
    if (!stripeResult?.paymentIntent) {
      return;
    }

    const requestBody = {
      packageId: planId,
      paymentIntentId: stripeResult.paymentIntent.id,
      paymentAmountInCent: stripeResult.paymentIntent.amount,
      paymentMethodId: stripeResult.paymentIntent.payment_method,
      paymentCreated: stripeResult.paymentIntent.created,
    };

    try {
      const response = await newPlanPurchased(requestBody);
      setShowLoader(false);

      if (response?.statusCode == 200 && response?.result) {
        setSuccessMsg(
          "Thank you for purchasing this Plan. Your information will be updated in a few seconds."
        );
        setTimeout(() => {
          dispatch(resetSelectedPlan());
          window.location.href = "/dashboard";
        }, 3000);
      } else {
        setStripeErrorMsg(
          response?.message ?? "We are unable to verify your payment."
        );
      }
    } catch (error) {
      setShowLoader(false);
      setStripeErrorMsg(
        error?.message ?? "We are unable to verify your payment."
      );
    }
  };

  const handlePay = async () => {
    setShowConfirm(false);

    if (!activeBilling?._id) {
      if (localStorage.getItem("payment-card-process") == "true") {
        return;
      }

      //localStorage.setItem("payment-card-process", true);
      setShowAddCard(true);
      //setErrorMsg('Please add Payment Method to proceed');
      return;
    }

    setShowLoader(true);
    setStripeErrorMsg("");
    setErrorMsg("");

    try {
      const paymentIntentResult = await createStripePaymentIntent(
        planPrice,
        "Skipify - Plan Purchase Payment"
      );

      if (!paymentIntentResult?.clientSecret) {
        if (
          paymentIntentResult?.message?.includes(
            "confirmCardPayment intent secret"
          )
        ) {
          setStripeErrorMsg(
            "Payment Failed due to an unexpected error. Please try later."
          );
        } else {
          setStripeErrorMsg(
            paymentIntentResult?.message ??
              "Error while creating a payment request."
          );
        }
        setShowLoader(false);
        return;
      }

      const paymentClientSecret = paymentIntentResult?.clientSecret;

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setShowLoader(false);
        setStripeErrorMsg(
          "Payment Failed. Please refresh the page and try again."
        );
        return;
      }

      let confirmPaymentOptions = {};

      if (!stripePaymentId) {
        confirmPaymentOptions = {
          payment_method: {
            card: elements.getElement(CardElement),
            metadata: {
              "customer ID": stripeClientId,
              "Plan Purchase": `${planTitle} $${planPrice}`,
            },
          },
        };
      }

      localStorage.setItem("payment-in-process", true);
      const result = await stripe.confirmCardPayment(
        `${paymentClientSecret}`,
        confirmPaymentOptions
      );

      if (result.error) {
        await paymentFaied(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          await newPlanBuy(result);
        } else {
          await paymentFaied(
            "Payment confirmation failed. Make sure your card is valid."
          );
        }
      }
    } catch (error) {
      if (error?.message?.includes("confirmCardPayment intent secret")) {
        setStripeErrorMsg(
          "Payment Failed due to an unexpected error. Please try later."
        );
      } else {
        setStripeErrorMsg(error?.message);
      }
      setShowLoader(false);
    }
  };

  const handlePayNow = async (event) => {
    event.preventDefault();

    if (localStorage.getItem("payment-in-process") == "true") {
      return;
    }

    await handlePay();
  };

  const renderConfirmPaymentModal = () => {
    return (
      <Modal
        show={showConfirm}
        onHide={handleCloseConfirmModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="modal_notification">
            <h5>Proceed with your payment?</h5>

            <div>
              <Button variant="primary" onClick={handlePayNow}>
                Yes
              </Button>
              <Button variant="warning" onClick={handleCloseConfirmModal}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderAddBillingModal = () => {
    return (
      <AddBillingModal
        showModal={showAddCard}
        closeModalHandler={handleCloseAddCardModal}
      />
    );
  };

  return (
    <>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {stripeErrorMsg ? (
        <div className="alert alert-danger">{stripeErrorMsg}</div>
      ) : (
        ""
      )}
      {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : ""}
      {successMsg ? (
        <div className="alert alert-success">{successMsg}</div>
      ) : (
        ""
      )}

      <div>
        <Row>
          <Col md={5}>
            <div className="back_icon" onClick={handleBackClick}>
              <IoIosArrowRoundBack />
            </div>
          </Col>
        </Row>

        <Row className="skips-checkout justify-content-between">
          <Col xs={12} sm={12} md={12} xl={5} xxl={5}>
            <Row>
              <Col xs={12}>
                <h2 className="mb-4 font-lg bill-gap">Checkout</h2>
                <hr className="mb-4 mt-2 hr-custom"></hr>
              </Col>
              <Col className="mb-3" xl={6}>
                <span className="step">1</span>
                <h3>{planTitle}</h3>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <h3>{`$${getFormattedCurrencyInInteger(
                  selectedPlan.price
                )}/month`}</h3>
              </Col>
            </Row>

            <div className="billing_detail_section_des">
              <h5 className="selected_price_title">Features</h5>
              <ul>
                {features.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </div>

            {/* <div className='payment_detial_section'>
              <Row className='d-flex justify-content-end'>
                <Col xs={12} sm={12} md={8} lg={8} xl={6}>
                  <div className="d-grid gap-2">
                    <Button variant="primary" onClick={handleBackClick}>
                      RETURN TO PLANS
                    </Button>
                  </div>
                </Col>
              </Row>
            </div> */}

            <Col xl={12}>
              <hr className="mb-4 mt-3 hr-custom"></hr>
            </Col>

            <Col className="my-3">
              <span className="step">2</span>
              <h3 className="mb-4">Choose payment method</h3>
            </Col>
            {activeBilling._id && (
              <Col xl={12}>
                <Form.Check
                  type="checkbox"
                  className="form-check-cst mb-2"
                  label={
                    <Row className="payment-skin-struct addSkips-cst">
                      <Col
                        className="cst-wdth-skp align-self-center"
                        xl={2}
                        lg={2}
                        sm={2}
                        md={2}
                        xs={2}
                      >
                        {activeBilling.cardType == "visa" && (
                          <Image className="icon" src={visaIcon}></Image>
                        )}
                        {activeBilling.cardType == "discover" && (
                          <Image className="icon" src={discoverIcon}></Image>
                        )}
                        {activeBilling.cardType == "mastercard" && (
                          <Image className="icon" src={mastercardIcon}></Image>
                        )}
                        {activeBilling.cardType == "amex" && (
                          <Image className="icon" src={amexIcon}></Image>
                        )}
                        {activeBilling.cardType == "jcb" && (
                          <Image className="icon" src={jbccardIcon}></Image>
                        )}
                        {activeBilling.cardType == "link" && (
                          <Image className="icon" src={linkIcon}></Image>
                        )}
                      </Col>
                      <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                        <div className="container-content">
                          <Row>
                            <Col>
                              <span className="name mb-1">
                                {activeBilling?.cardOwnerName
                                  ? activeBilling?.cardOwnerName
                                  : ""}
                              </span>
                              {activeBilling.cardType != "link" ? (
                                <span className="number">
                                  ****{" "}
                                  {formatCardEndFourDigits(
                                    activeBilling.cardLastFourDigits
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span className="expiry">
                                Expires {activeBilling.cardExpiryMonth}/
                                {activeBilling.cardExpiryYear}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  }
                  checked
                />
              </Col>
            )}

            <Col xl={8} md={6}>
              <button
                className="download-table wd-100 p-2 my-4 pl-all-4"
                onClick={handleShowAddCardModal}
              >
                <Image src={addIcon}></Image>
                Add new payment method
              </button>
            </Col>
          </Col>
          <Col xs={12} sm={12} md={12} xl={6} xxl={6}>
            <div className="billing_detail_section">
              <h3 className="sub_title_h3">Order Summary</h3>
              <hr className="my-4 hr-custom"></hr>

              <Card className="addskip-card">
                <Row className="mb-xl-3 mb-md-0">
                  <Col lg={7}>
                    <h6>
                      {currentPlanData.title ? (
                         <>
                          {`Upgrade from `}
                          <strong>{currentPlanData.title}</strong> {` to `}{" "}
                          <strong>{planTitle}</strong>
                       </>
                      ) : (
                      {planTitle}
                      )}
                    </h6>
                  </Col>

                  <Col lg={5}>
                    <span>{GetFormattedCurrency(planPrice)}</span>
                  </Col>
                </Row>

                <hr className="my-3 hr-custom"></hr>

                <Row className="mt-3">
                  <Col lg={6}>
                    <h6>Total amount due</h6>
                  </Col>
                  <Col lg={6}>
                    <span>{GetFormattedCurrency(planPrice)}</span>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      label={`I agree to all of Skipify.ai’s Terms of Service & Privacy Policy.`}
                      className="term_checkbox"
                      checked={true}
                    />
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg={6}>
                    {/* <Button variant='warning' onClick={handleShowConfirmModal}>SUBMIT PAYMENT</Button> */}
                    {successMsg ? (
                      ""
                    ) : (
                      <button className="submit_Payment" onClick={handlePayNow}>
                        Submit payment
                      </button>
                    )}
                  </Col>
                  <Col lg={12}>
                    <div className="billing_detail_section_des">
                      <h4> Subscription Details: </h4>
                      <ul>
                        <li>
                          The subscription is set to auto-renew by default every
                          month.
                        </li>
                        {/* <li>Sales tax may be added at the time of checkout</li> */}
                        <li>
                          You can upgrade, cancel, or pause your subscription at
                          any time.
                        </li>
                        <li>
                          All payments made once the subscription is active are
                          final.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
        {renderConfirmPaymentModal()}
        {renderAddBillingModal()}
      </div>
    </>
  );
};

export default BillingDetail;
