import { BsArrowRight } from "react-icons/bs";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import smallLogo from "../assets/img/small-logo.png";
import logo from "../assets/new_theme/img/logo.png";

import dashboardIcon from "../assets/new_theme/img/menu/dashboard.png";
import mydataIcon from "../assets/new_theme/img/menu/mydata.png";
import searchIcon from "../assets/new_theme/img/menu/search.png";
import affiliateIcon from "../assets/new_theme/img/menu/affiliate.png";
import billingIcon from "../assets/new_theme/img/menu/billing.png";
import settingIcon from "../assets/new_theme/img/menu/setting.png";
import IconExpand from "../assets/new_theme/img/icon-expand.png";
import { FiLogOut } from "react-icons/fi";

const NavBarMenu = ({ active, setActive, screen }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const Menus = [
    { icon: <Image src={dashboardIcon} className={"icon-nav"} />, title: "Dashboard", link: "/dashboard" },
    { icon: <Image src={searchIcon} className={"icon-nav"} />, title: "Property Search", link: "/property-search" },
    { icon: <Image src={mydataIcon} className={"icon-nav"} />, title: "My Data", link: "/my-lists" },
    // { icon: <FaFileInvoice />, title: "Invoices", link: "/invoice" },
    { icon: <Image src={settingIcon} className={"icon-nav"} />, title: "Settings", link: "/settings" },
    { icon: <Image src={billingIcon} className={"icon-nav"} />, title: "Billing", link: "/billing" },
    { icon: <Image src={affiliateIcon} className={"icon-nav"} />, title: "Affiliate Program", link: "/affiliate-program" },
  ];

  const logoClickHandler = () => {
    navigate('/dashboard');
  }

  const handleButtonExpand = (actionPadding) => {
    const h4Text = document.querySelector('.main-header .main-title-desktop');
    if(actionPadding=="closeSidebar"){
      h4Text.classList.add("ml-4");
    }else {
      h4Text.classList.remove("ml-4");
    }
  }

  return (
    <>
      {/* className="active" */}
      <div
        className={` sidebar ${active ? "active" : ""}`}
        onClick={() => setActive(!active)}
      > 
        <div className="scrollable-xs">
          <ul>
            <div className="logo">
              {active ? (
                <img
                  className="logo-icon"
                  src={logo}
                  alt=""
                  onClick={logoClickHandler}
                />
              ) : (
                <img
                  className="logo-icon"
                  src={smallLogo}
                  alt=""
                  onClick={logoClickHandler}
                />
              )}

              {!screen && (
                <>
                <Image
                  src={IconExpand}
                  className="icon-nav-expand navicon"
                  title="Open Side Menu"
                  onClick={() => {setActive(!active);}}
                />
                  {/* {!active ? (
                    <Image
                      src={IconExpand}
                      className="icon-nav-expand navicon bar-closed"
                      title="Open Side Menu"
                      onClick={() => {setActive(!active);handleButtonExpand("open")}}
                    />
                  ) : (
                    <Image
                      src={IconExpand}
                      className="navicon icon-nav-expand bar-expanded"
                      title="Close Side Menu"
                      onClick={() => {setActive(!active);handleButtonExpand("closeSidebar")}}
                    />
                  )} */}
                </>
              )}
            </div>

            <div className="sidebar_main">
              {Menus?.map((menu, k) => (
                <li
                  className={`${pathname === menu.link ? "active" : ""}`}
                  key={k}
                >
                  <Link to={menu.link}>
                    <div className="icon">{menu.icon}</div>
                    <div className="text">{menu.title}</div>
                  </Link>
                </li>
              ))}
            </div>
          </ul>

          {active && (
            <div className="navbar_bottom_div">
              <Link to="/billing">
                <div className="navbar_bottom_div_design">
                  <h5>Upgrade for wider reach</h5>
                  <p>Generate more leads when you upgrade your account</p>
                  <button>
                    Upgrade <BsArrowRight />
                  </button>
                </div>
              </Link>
            </div>
          )}
          <div className="sidebar_main">
            <ul>
              <li>
                <Link to="/logout">
                  <div className="icon">
                    <FiLogOut color="var(--navFontColor)" />
                  </div>
                  <div className="text">Log out</div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBarMenu;
