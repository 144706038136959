import React, { useState, useEffect } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { BiBorderAll } from "react-icons/bi";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const AreaChart = (props) => {
  const [finalData, setFinalData] = useState([]);
  const [revenueByDay, setRevenueByDay] = useState([]);
  const revenueData = [];

  const calculatePrevDayRevenue = (revenueData) => {
    console.clear();
    let comparedRevenue=['']; 
    let count = 1;
    for (let i in revenueData){
      if(count!==1){ 
        comparedRevenue.push(revenueData[i].y-revenueData[i-1].y); 
      }
      count++;
    }
    setRevenueByDay(comparedRevenue); 
  }

  useEffect(() => {
    if (props.data?.length) { 
      for (const rdata of props.data) {
        const row = {
          x: new Date(rdata.x),
          y: rdata.y,
        };
        revenueData.push(row); 
      }
      setFinalData(revenueData); 
      // calculatePrevDayRevenue(revenueData);
    }
  }, []);

  const options = {
    animationEnabled: true,
    theme: "light2",
    axisX: {
      valueFormatString: "DD/MM",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    axisY: {
      valueFormatString: "0",
      minimum: 0,
      gridThickness: 0,
      prefix: "$",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: function (e) {
          return CanvasJS.formatNumber(e.value, "##0");
        },
      },
    },
    data: [
      {
        lineColor: "#28C7B5",
        type: "splineArea",
        xValueFormatString: "MM/DD/YYYY",
        yValueFormatString: "$#,##0.## Revenue",
        color: "rgb(40 199 181 / 32%)",
        lineTension: 100,
        dataPoints: finalData, 
        fillOpacity: .3, 
      },
    ],
  };
  return <CanvasJSChart options={options} />;
};

export default AreaChart;
