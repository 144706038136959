import { FcGoogle } from "react-icons/fc";
import { HiCreditCard } from "react-icons/hi";
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Image, Row, Form, Button } from 'react-bootstrap'
import StripeContainer from './stripecheckout/StripeContainer';
import { useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import {
  enableBillingById,
  getActiveBilling,
  getNewBillingDetails,
  updatePaymentIntentDetails
} from "../../Utils/billing.util";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BillingPaymentCardElement from "../Billings/billing-payment-card-element";
import ConfirmYourBilling from "./ConfirmYourBilling";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const RegisterCheckout = ({ setSwitchScreen }) => {

  const history = useNavigate()

  const [amount, setAmount] = useState(1);
  const [client, setClient] = useState({});
  const [showPass, setShowPass] = useState(false)
  const [skipifyClientID, setSkipifyClientID] = useState("");
  const [stripeCustomerID, setStripeCustomerID] = useState("");
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [stripeSetupIntent, setStripeSetupIntent] = useState("");
  const [stripeOptions, setStripeOptions] = useState("");

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const getNewBillingData = async () => {
    setShowLoader(true);
    try {

      let response = await getNewBillingDetails();

      if (response?.result) {
        setStripeCustomerID(response.result?.stripeCustomerId);
        setStripeClientSecret(response?.result?.stripeClientSecret);
        setStripeSetupIntent(response.result?.stripeSetupIntentId);
        setSkipifyClientID(response.result?.clientId);

        let options = {
          // passing the client secret obtained from the server
          clientSecret: response?.result?.stripeClientSecret,
        };

        setStripeOptions(options);
      }
      setTimeout(() => {
        setShowLoader(false);
      }, 4000);
    } catch (error) {

      setTimeout(() => {
        setShowLoader(false);
      }, 4000);

      setErrorMsg(error?.message || '');
    }
  }



  const confirmSetupIntentResult = async (result) => {
    if (result.error) {
      setErrorMsg(result.error.message);
      return;
    }

    try {
      if (result?.setupIntent?.status == "succeeded") {
        setShowLoader(true);

        const requestPayload = {
          stripeSetupIntentId: result.setupIntent.id,
          stripePaymentMethodId: result.setupIntent.payment_method,
          stripeCustomerId: stripeCustomerID,
          stripeClientSecret: stripeClientSecret,
        };

        const response = await updatePaymentIntentDetails(requestPayload);

        if (response.statusCode == 200) {
          localStorage.setItem('00cus', JSON.stringify(response.result));
          setSuccessMsg("Your credit card has been added successfully.");
          setShowLoader(false);
          setBillingCheckout(true)
        } else {
          setErrorMsg(response?.message);
          setShowLoader(false);
        }
      }
      else {
        setErrorMsg("Sorry! We cannot add the card at the moment. Please try again.");
        setShowLoader(false);
      }
    } catch (error) {
      setErrorMsg(error?.message || '');
      setShowLoader(false);
    }
  }




  const [billingCheckout, setBillingCheckout] = useState(false)


  useEffect(() => {
    getNewBillingData();
  }, []);


  return (

    <Row>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <Col md={11} className="mx-auto">

        <Row>
          <Col xs={11} md={11} className="mx-auto">

            {billingCheckout ?
              <h2 className="register_title mb-3">Confirm Your Plan</h2>
              :
              <>
                <h2 className="register_title">Add Billing Information</h2>
                <p className="register_des">Fill out the information below to create your account.</p>
              </>
            }


          </Col>
        </Row>

        {/* <Row>
          <Col md={10} className='mx-auto'>
            {Object.keys(client).length > 0 && <StripeContainer client={client || {}} />}
          </Col>
        </Row> */}
        <div className="main-form2">
          {stripeOptions ? (
            <Elements
              stripe={stripePromise}
              options={stripeOptions}
            >
              {billingCheckout ? <ConfirmYourBilling /> :
                <>
                  <Row>
                    <Col sm={11} md={11} lg={11} xl={10} className="mx-auto mt-3">

                      <div className="strip_card">
                        <BillingPaymentCardElement
                          confirmResult={confirmSetupIntentResult} setSwitchScreen={setSwitchScreen}

                        />
                      </div>
                    </Col>
                  </Row>
                </>

              }

            </Elements>
          ) : (
            <div className="loaderBlocker">
              <div className="fadeOut"></div>
              <div className="loader"></div>
            </div>
          )}






          {/*
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-3">
              <div class="radio-item">
                <input
                  name="radio"
                  id="radio1"
                  type="radio"
                  value="No"
                />
                <label htmlFor="radio1">Card <HiCreditCard /></label>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-3">
              <div class="radio-item">
                <input
                  name="radio"
                  id="radio2"
                  type="radio"
                  value="yes"
                />
                <label htmlFor="radio2">Google Pay <FcGoogle /></label>
              </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3 ">
              <Form.Group className="mb-3" >
                <Form.Label>Card Number *</Form.Label>
                <Form.Control type="text"
                  name="firstName"
                  className="form-control"
                  placeholder="1234 1234 1234 1234"
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label>Expiration *</Form.Label>
                <Form.Control type="text"
                  name="firstName"
                  className="form-control"
                  placeholder="MM/YY"
                  id="first_name"
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-3">
              <Form.Group className="mb-3" >
                <Form.Label>CVC *</Form.Label>
                <Form.Control type="text"
                  name="firstName"
                  className="form-control"
                  placeholder="CVC"
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-3">
              <Form.Group className="mb-3 password">
                <Form.Label>Country *</Form.Label>
                <Form.Control
                  type={showPass ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="*******"
                  autoComplete="new-password"
                />
                {showPass ? <AiFillEye className="show_password" onClick={() => setShowPass(!showPass)} /> : <AiFillEyeInvisible className="show_password" onClick={() => setShowPass(!showPass)} />}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-3">
              <Form.Group className="mb-3 password">
                <Form.Label>Zip *</Form.Label>
                <Form.Control
                  type={showPass ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="*******"
                />
                {showPass ? <AiFillEye className="show_password" onClick={() => setShowPass(!showPass)} /> : <AiFillEyeInvisible className="show_password" onClick={() => setShowPass(!showPass)} />}
              </Form.Group>
            </Col>
          </Row>
          */}


        </div>

      </Col>
    </Row>
  )
}

export default RegisterCheckout