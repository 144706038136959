import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { AffiliateGetDuePayments } from "../../Utils/affiliate.util";
import DataTable from "react-data-table-component";
import * as moment from "moment";
import { ToTitleCase } from "../../Utils/formating.util";
// const customStyles = {
// 	headCells: {
// 		style: {
 
//       fontSize: '.8rem',
//       fontWeight: '600'
// 		},
// 	},
// };
const customStyles = {
  tableWrapper: {
		style: {
			padding: '0px 20px',
		},
	},
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "600",
      backgroundColor:"#FCFCFD",
      color:"#101828",
      justifyContent:"center !important"
    },
  },
  headRow: {
		style: {
      
		},
	},
  cells: {
		style: {
      fontSize: "16px",
      fontWeight: "400",
      backgroundColor:"#FCFCFD",
      padding:"20px 15px",
      justifyContent:"center",
      color:"#101828",
    },
    '&:not(:last-of-type)': {

    },
		draggingStyle: {},
	},
  rows: {
		style: {
      justifyContent:"center",
			// '&:not(:last-of-type)': {
			// 	borderBottomStyle: 'solid',
			// 	borderBottomWidth: '1px',
			// 	borderBottomColor: theme.divider.default,
			// },
		},
		selectedHighlightStyle: {
			// use nth-of-type(n) to override other nth selectors
			'&:nth-of-type(3)': {
				justifyContent:"start !important",
        textAlign: "left !important",
			},
		},
		highlightOnHoverStyle: {
			transitionDuration: '0.15s',
			transitionProperty: 'background-color',
			borderBottomColor: 'none !important',
			outlineStyle: 'none !important',
			outlineWidth: '0 !important',
			outlineColor: "none !important",
      cursor:"text"
		},
		// stripedStyle: {
		// 	color: theme.striped.text,
		// 	backgroundColor: theme.striped.default,
		// },
	},
};

const AffiliateDuePayments = () => {
  const [paymentsData, setPaymentsData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const columns = [
    {
      name: "Statement ID",
      selector: (row) => `#PAY-${row.paymentNumber}`,
      style: { 
        textAlign: "left"
      },
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format("MMM DD, YYYY"),
      sortable: true
    },
    {
      name: "Status",
      selector: (row) => ToTitleCase(row.status),
      conditionalCellStyles: [
        {
          when: row => row.status?.toLowerCase() == 'paid',
          classNames: ['status_label_simple', 'success']
        },
        {
          when: row => row.status?.toLowerCase() == 'pending',
          classNames: ['status_label_simple', 'warning']
        }
      ]
    },
    {
      name: "Earnings (USD)",
      selector: (row) =>
        "$" +
        Number(row.commissionAmount)
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
       style: {fontWeight: '600'},
       sortable: true
    },
  ];

  useEffect(() => {
    setShowLoader(true);
    AffiliateGetDuePayments().then((response) => {
      if (response.statusCode == 200) {
        setPaymentsData(response?.result);
      }
      setShowLoader(false);
    });
  }, []);

  return (
    <Row> 
      <Col md={12}>
        <hr className="my-4 custom-hr"></hr>
      </Col>
      <Col md={12} className="my-4"> 
        <Row>
          <Col md={12}>
            <h2 className="mb-3">Commission Payout</h2>
            <p>Find all your due commissions available on the 1st of each month. <br /></p>
          </Col>
            
        </Row>
        {showLoader ? (
          <div className="loader-small"></div>
        ) : (
          <div className="table-data-main resp-table2 p-0 m-0">
            <DataTable columns={columns} data={paymentsData} striped highlightOnHover pagination customStyles={customStyles}/>
          </div>
        )} 
      </Col>

      <Row> 
        <Col md={12} className="gray500-color">
          <p><span className="small"><strong>Note: </strong>Referral transactions completed by the 15th of the current month will be eligible for commissions on the 1st of the following month.</span></p>
        </Col>
      </Row>
    </Row>
  );
};

export default AffiliateDuePayments;
