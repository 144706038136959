export const ResidentialOptionsConstant = [
  { value: "1000", label: "General (Single)", category: "Residential" },
  { value: "1001", label: "Single Family", category: "Residential" },
  { value: "1002", label: "Townhouse", category: "Residential" },
  { value: "1003", label: "Cluster home", category: "Residential" },
  { value: "1004", label: "Condominium Unit", category: "Residential" },
  { value: "1005", label: "Cooperative Unit", category: "Residential" },
  {
    value: "1006",
    label: "Mobile/Manufactured Home (Regardless of Land ownership)", category: "Residential"
  },
  { value: "1007", label: "Row house", category: "Residential" },
  { value: "1008", label: "Rural/Agriculture Residence", category: "Residential" },
  { value: "1009", label: "Planned Unit Development", category: "Residential" },
  { value: "1010", label: "Common Area", category: "Residential" },
  { value: "1011", label: "Timeshare", category: "Residential" },
  { value: "1012", label: "Seasonal, Cabin, Vacation Residence", category: "Residential" },
  { value: "1013", label: "Bungalow", category: "Residential" },
  { value: "1014", label: "Zero Lot Line", category: "Residential" },
  { value: "1015", label: "Misc Residential Improvement", category: "Residential" },
  { value: "1016", label: "Modular/Pre-Fabricated Homes", category: "Residential" },
  { value: "1017", label: "Patio Home", category: "Residential" },
  { value: "1018", label: "Garden Home", category: "Residential" },
  { value: "1019", label: "Landominium", category: "Residential" },
  { value: "1020", label: "Barndominium", category: "Residential" },
  { value: "1021", label: "Tiny House", category: "Residential" },
  { value: "1100", label: "Income General (Multi-Family)", category: "Residential" },
  { value: "1101", label: "Duplex (2 units, any combination)", category: "Residential" },
  { value: "1102", label: "Triplex (3 units, any combination)", category: "Residential" },
  { value: "1104", label: "Quadruplex (4 units, any combination)", category: "Residential" },
  { value: "1105", label: "Apartment house (5+ units)", category: "Residential" },
  { value: "1106", label: "Garden Apt, Court Apt (5+ units)", category: "Residential" },
  { value: "1107", label: "Highrise Apartments", category: "Residential" },
  {
    value: "1108",
    label:
      "Boarding House, Rooming House, Apt Hotel, Transient Lodgings, Hostel", category: "Residential"
  },
  { value: "1109", label: "Mobile Home or Trailer Park", category: "Residential" },
  { value: "1110", label: "Multi-Family Dwellings (Generic, 2+)", category: "Residential" },
  { value: "1111", label: "Fratenity or Sorority House", category: "Residential" },
  { value: "1112", label: "Apartments (Generic)", category: "Residential" },
  { value: "1113", label: "Dormitory, Group Quarters", category: "Residential" },
  { value: "1114", label: "Condominium Development (Association Assessment)", category: "Residential" },
  { value: "1124", label: "Condominium Building", category: "Residential" },
  { value: "1125", label: "Corporative Building", category: "Residential" },
  { value: "1901", label: "Parking Garage", category: "Residential" },
  { value: "1902", label: "Storage Space", category: "Residential" },
  { value: "1999", label: "Single Family Residential Assumed", category: "Residential" },
];
