import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Modal,
  Image,
  Card,
} from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import { AiFillCreditCard } from "react-icons/ai";
import { AddBillingModal } from "./../Billings/add-billing-modal";
// import { STRIPE_SK } from "../../Constants/api-credentials.constant";
import {
  createStripePaymentIntent,
  getActiveBilling,
  paymentFailed,
} from "../../Utils/billing.util";
import {
  GetFormattedCurrency,
  formatCardEndFourDigits,
  getFormattedCurrencyInInteger,
} from "../../Utils/formating.util";
import {
  getCurrentBilling,
  getCurrentPlanData,
} from "../../store/selectors/features/payment-plan";
import { updateCurrentBilling } from "../../store/slices/features/payment-plan";
import {
  OrderPaymentSuccess,
  PlaceFileSkiptraceOrder,
} from "../../Utils/files.util";
import visaIcon from "./../../assets/new_theme/img/payment/visa.png";
import discoverIcon from "./../../assets/new_theme/img/payment/discover.png";
import mastercardIcon from "./../../assets/new_theme/img/payment/card.png";
import jbccardIcon from "./../../assets/new_theme/img/payment/jcb.png";
import linkIcon from "./../../assets/new_theme/img/payment/link.png";
import amexIcon from "./../../assets/new_theme/img/payment/amex.png";
import addIcon from "./../../assets/new_theme/img/add.png";
import {
  AGE_FILTER_SKIP_PER_HIT,
  DNC_CHECK_SKIP_PER_HIT,
} from "../../Constants/common-limit.constant";

const FileUploadStep4New = (props) => {
  const profileData = props.profileData;
  const fileUpload = props.fileUpload;
  const fileUploadData = props.fileUploadData;
  const amountDue = props.amountDue;
  const chargableHits = Number(props.chargableHits);
  const orderNumber = props.orderNumber;
  const orderId = props.orderId;
  const dncCheck = props.dncCheck; // boolean value
  const ageMin = props.ageMin;
  const ageMax = props.ageMax;
  const relativeContacts = props.relativeContacts;
  const mostLikelyGender = props.mostLikelyGender;
  const skiptraceRecordCount = fileUploadData.length - 1; //
  const customerPricePerHit = profileData.billing?.pricePerHit ?? 0.15;
  const remainingSkiptraceHits = profileData.billing.remainingHits;
  let ageFilterAdditionalSkips = 0;
  let relativeContactsAdditionalSkips = 0;
  let likelyGenderAdditionalSkips = 0;
  let dncCheckAdditionalSkips = 0;

  // Dnc Charges
  if (dncCheck == true || dncCheck == "true") {
    dncCheckAdditionalSkips = (
      skiptraceRecordCount * Number(DNC_CHECK_SKIP_PER_HIT)
    ).toLocaleString();
  }

  // Age filter charges
  if (ageMin && ageMax) {
    ageFilterAdditionalSkips = (
      skiptraceRecordCount * Number(AGE_FILTER_SKIP_PER_HIT)
    ).toLocaleString();
  }

  // Relative Filter charges
  if (relativeContacts == true || relativeContacts == "true") {
    relativeContactsAdditionalSkips = (
      skiptraceRecordCount * Number(AGE_FILTER_SKIP_PER_HIT)
    ).toLocaleString();
  }
  // Most Likely Gender
  if (mostLikelyGender) {
    likelyGenderAdditionalSkips = (
      skiptraceRecordCount * Number(AGE_FILTER_SKIP_PER_HIT)
    ).toLocaleString();
  }

  const [paymentStatusFlag, setPaymentStatusFlag] = useState(false); // true = paid, false = unpaid
  
  //-----------------------------------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const planId = profileData._id;
  const planTitle = "File Upload Extra Hits Purchase";
  const activeBilling = useSelector(getCurrentBilling);
  const stripePaymentId = activeBilling?.stripePaymentMethodId;
  const stripeCustomerId = activeBilling?.stripeCustomerId;
  const stripeClientId = activeBilling?.clientId;

  let paymentIntentData = null;
  const [showLoader, setShowLoader] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);

  const [totalPrice, setTotalPrice] = useState(amountDue);

  const handleCloseConfirmModal = () => setShowConfirm(false);
  const handleShowConfirmModal = () => setShowConfirm(true);
  const handleCloseAddCardModal = () => setShowAddCard(false);
  const handleShowAddCardModal = () => setShowAddCard(true);

  useEffect(() => {
    setTotalPrice(amountDue);
  }, []);

  useEffect(() => {
    getActiveBillingData();
  }, []);

  const getActiveBillingData = async () => {
    setShowLoader(true);
    try {
      const response = await getActiveBilling();

      if (response?.statusCode == 200 && response?.result) {
        dispatch(updateCurrentBilling(response.result));
      }

      setShowLoader(false);
    } catch (error) {
      console.log("error", error);
      setShowLoader(false);
    }
  };

  const paymentFailed = async (message) => {
    setStripeErrorMsg(message);
    setShowLoader(false);

    try {
      const result = await paymentFailed();
    } catch (error) {
      console.log(error);
    }
  };

  // Save the payment in backend
  const handlePaymentStatusCall = async (
    paymentStatusFlag,
    paymentIntentData
  ) => {
    setPaymentStatusFlag(paymentStatusFlag);
    const orderSavedResponse = await OrderPaymentSuccess(
      paymentStatusFlag,
      orderId,
      totalPrice,
      "paid",
      paymentIntentData,
      chargableHits
    );

    if (orderSavedResponse?.statusCode == 200) {
      const orderPlacedResponse = await PlaceFileSkiptraceOrder(
        orderId,
        skiptraceRecordCount,
        paymentIntentData?.status
      );

      if (orderPlacedResponse?.statusCode == 200) {
        props.handleStep4Call(true);
      } else {
        setStripeErrorMsg(
          "We are unable to place your skiptrace order. Please contact the Skipify.ai support."
        );
      }
    } else {
      setStripeErrorMsg("We are unable to verify your payment.");
    }
  };

  // Place order
  const handlePlaceOrderBtnClick = async () => {
    setShowLoader(true);
    // Place order API
    const placeOrderResponse = await PlaceFileSkiptraceOrder(
      orderId,
      skiptraceRecordCount,
      paymentIntentData?.status
    );
    if (placeOrderResponse?.statusCode == 200) {
      props.handleStep4Call(true);
    } else {
      setStripeErrorMsg(
        "We are unable to place your skiptrace order. Please contact the Skipify.ai support."
      );
    }
    setShowLoader(false);
  };

  const handlePayNow = async (event) => {
    event.preventDefault();
    setShowConfirm(false);

    if (!activeBilling?._id) {
      setErrorMsg("Please add Payment Method to proceed");
      return;
    }

    // if (additionalDownloads === 0 && additionalSkips === 0) {
    //   setErrorMsg("Please add Skips or Downloads to proceed");
    //   return;
    // }

    setShowLoader(true);
    setStripeErrorMsg("");
    setErrorMsg("");

    try {
      const paymentIntentResult = await createStripePaymentIntent(
        totalPrice,
        "Skipify - Additional Skips/Downloads File Upload"
      );

      if (!paymentIntentResult?.clientSecret) {
        if (
          paymentIntentResult?.message?.includes(
            "confirmCardPayment intent secret"
          )
        ) {
          setStripeErrorMsg(
            "Payment Failed due to an unexpected error. Please try later."
          );
        } else {
          setStripeErrorMsg(
            paymentIntentResult?.message ??
              "Error while creating a payment request."
          );
        }
        setShowLoader(false);
        return;
      }

      const paymentClientSecret = paymentIntentResult?.clientSecret;
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setShowLoader(false);
        setStripeErrorMsg(
          "Payment Failed. Please refresh the page and try again."
        );
        return;
      }

      let confirmPaymentOptions = {};

      if (!stripePaymentId) {
        confirmPaymentOptions = {
          payment_method: {
            card: elements.getElement(CardElement),
            metadata: {
              "customer ID": stripeClientId,
              "Additional Skips Purchase": `${planTitle} $${totalPrice}`,
            },
          },
        };
      }

      const result = await stripe.confirmCardPayment(
        `${paymentClientSecret}`,
        confirmPaymentOptions
      );

      if (result.error) {
        await paymentFailed(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          paymentIntentData = result.paymentIntent;
          await handlePaymentStatusCall(true, result.paymentIntent);
          setShowLoader(false);
        } else {
          await paymentFailed(
            "Payment confirmation failed. Make sure your card is valid."
          );
          setShowLoader(false);
        }
      }
    } catch (error) {
      if (error?.message?.includes("confirmCardPayment intent secret")) {
        setStripeErrorMsg(
          "Payment Failed due to an unexpected error. Please try later."
        );
      } else {
        setStripeErrorMsg(error?.message);
      }
      setShowLoader(false);
    }
  };

  const renderConfirmPaymentModal = () => {
    return (
      <Modal
        show={showConfirm}
        onHide={handleCloseConfirmModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="modal_notification">
            <h5>Proceed with your payment?</h5>

            <div>
              <Button variant="primary" onClick={handlePayNow}>
                Yes
              </Button>
              <Button variant="warning" onClick={handleCloseConfirmModal}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderAddBillingModal = () => {
    return (
      <AddBillingModal
        showModal={showAddCard}
        closeModalHandler={handleCloseAddCardModal}
      />
    );
  };

  return (
    <>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {stripeErrorMsg ? (
        <div className="alert alert-danger">{stripeErrorMsg}</div>
      ) : (
        ""
      )}
      {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : ""}
      {successMsg ? (
        <div className="alert alert-success">{successMsg}</div>
      ) : (
        ""
      )}

      <div>
        {/* <Row>

          <Col md={12} className="text-center">
            <h3 className='sub_title'> Checkout</h3>
          </Col>
        </Row> */}

        <Row className="skips-checkout justify-content-between">
          <Col md={5}>
            <Row>
              <Col className="mb-3 mt-2" xl={12}>
                <span className="step">1</span>
                <h3>Details</h3>
              </Col>
            </Row>
            <Row className="mt-3 ms-1">
              <Col md={10}>
                <h6>Total Records in the File</h6>
              </Col>
              <Col xs={6} md={2}>
                {" "}
                <h6 className="float-end">{skiptraceRecordCount}</h6>
              </Col>
            </Row>

            <Row className="mt-3 ms-1">
              <Col md={10}>
                <h6>Available Skips</h6>
              </Col>

              <Col md={2}>
                {" "}
                <h6 className="float-end">
                  {remainingSkiptraceHits?.toLocaleString()}
                </h6>
              </Col>
            </Row>

            {dncCheck ? (
              <Row className="mt-3 ms-1">
                <Col md={10}>
                  <h6>DNC Check Additional Skips</h6>
                </Col>
                <Col xs={6} md={2}>
                  {" "}
                  <h6 className="float-end">{dncCheckAdditionalSkips}</h6>
                </Col>
              </Row>
            ) : (
              ""
            )}

           {ageMin && ageMax ? (
              <Row className="mt-3 ms-1">
                <Col md={10}>
                  <h6>Age Filter Additional Skips</h6>
                </Col>
                <Col xs={6} md={2}>
                  {" "}
                  <h6 className="float-end">{ageFilterAdditionalSkips}</h6>
                </Col>
              </Row>
            ) : (
              ""
            )}

           {relativeContacts == true || relativeContacts == 'true' ? (
              <Row className="mt-3 ms-1">
                <Col md={10}>
                  <h6>Relatives' Contacts Additional Skips</h6>
                </Col>
                <Col xs={6} md={2}>
                  {" "}
                  <h6 className="float-end">{relativeContactsAdditionalSkips}</h6>
                </Col>
              </Row>
            ) : (
              ""
            )}

           {mostLikelyGender ? (
              <Row className="mt-3 ms-1">
                <Col md={10}>
                  <h6>Most Likely Gender Additional Skips</h6>
                </Col>
                <Col xs={6} md={2}>
                  {" "}
                  <h6 className="float-end">{likelyGenderAdditionalSkips}</h6>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row className="mt-3 ms-1">
              <Col md={10}>
                <h6>Total Additional Skips to Purchase</h6>
              </Col>

              <Col xs={6} md={2}>
                {" "}
                <h6 className="float-end">{chargableHits?.toLocaleString()}</h6>
              </Col>
            </Row>

            {/* 
            <div className="billing_detail_section">
              <Row>
                <Col md={7}>
                  <h4 className="sub_title_h4  mt-3">Additional Skips Required</h4>
                </Col>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={3}>
                  {" "}
                  <h4 className="sub_title_h4 right mt-3">{chargableHits?.toLocaleString()}</h4>
                </Col>
              </Row>
            </div>
            

            <div className="billing_detail_section">
              <Row>
                <Col md={7}>
                  <h4 className="sub_title_h4  mt-3">Amount Due</h4>
                </Col>
                <Col xs={6} md={2}>
                  <h4 className="mt-3 sub_title_h4">
                    ${customerPricePerHit}/Hit
                  </h4>
                </Col>
                <Col xs={6} md={3}>
                  {" "}
                  <h4 className="sub_title_h4 right mt-3">
                    {GetFormattedCurrency(amountDue)}
                  </h4>
                </Col>
              </Row>
            </div> */}

            <Col xl={12}>
              <hr className="mb-4 mt-3 hr-custom"></hr>
            </Col>

            <Col className="my-3">
              <span className="step">2</span>
              <h3 className="mb-5">Choose payment method</h3>
            </Col>
            {activeBilling._id && (
              <Col xl={12}>
                <Form.Check
                  type="checkbox"
                  className="form-check-cst mb-2"
                  label={
                    <Row className="payment-skin-struct addSkips-cst">
                      <Col
                        className="cst-wdth-skp align-self-center"
                        xl={2}
                        lg={2}
                        sm={2}
                        md={2}
                        xs={2}
                      >
                        {activeBilling.cardType == "visa" && (
                          <Image className="icon" src={visaIcon}></Image>
                        )}
                        {activeBilling.cardType == "discover" && (
                          <Image className="icon" src={discoverIcon}></Image>
                        )}
                        {activeBilling.cardType == "mastercard" && (
                          <Image className="icon" src={mastercardIcon}></Image>
                        )}
                        {activeBilling.cardType == "amex" && (
                          <Image className="icon" src={amexIcon}></Image>
                        )}
                        {activeBilling.cardType == "jcb" && (
                          <Image className="icon" src={jbccardIcon}></Image>
                        )}
                        {activeBilling.cardType == "link" && (
                          <Image className="icon" src={linkIcon}></Image>
                        )}
                      </Col>
                      <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                        <div className="container-content">
                          <Row>
                            <Col>
                              <span className="name mb-1">
                                {activeBilling?.cardOwnerName
                                  ? activeBilling?.cardOwnerName
                                  : ""}
                              </span>
                              {activeBilling.cardType != "link" ? (
                                <span className="number">
                                  ****{" "}
                                  {formatCardEndFourDigits(
                                    activeBilling.cardLastFourDigits
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span className="expiry">
                                Expires {activeBilling.cardExpiryMonth}/
                                {activeBilling.cardExpiryYear}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                    </Row>
                  }
                  checked
                />
              </Col>
            )}

            <Col xl={8} md={6}>
              <button
                className="download-table wd-100 p-2 my-4 pl-all-4"
                onClick={handleShowAddCardModal}
              >
                <Image src={addIcon}></Image>
                Add new payment method
              </button>
            </Col>
          </Col>
          <Col xs={12} sm={12} md={12} xl={6} xxl={6}>
            <div className="billing_detail_section">
              <h3 className="sub_title_h3">Order Summary</h3>

              <hr className="my-4 hr-custom"></hr>

              <Card className="addskip-card">
                <Row className="mb-xl-3 mb-md-0">
                  <Col lg={12}>
                    <h6>Additional Skips</h6>
                  </Col>
                  <Col lg={6}>
                    <p>
                      {chargableHits?.toLocaleString()} skips x $
                      {customerPricePerHit} per skip
                    </p>
                  </Col>
                  <Col lg={6}>
                    <span>{GetFormattedCurrency(amountDue)}</span>
                  </Col>
                </Row>

                <hr className="my-3 hr-custom"></hr>

                <Row className="mt-3">
                  <Col lg={6}>
                    <h6>Total amount due</h6>
                  </Col>
                  <Col lg={6}>
                    <span>{GetFormattedCurrency(amountDue)}</span>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      label={`I agree to all of Skipify.ai’s Terms of Service & Privacy Policy.`}
                      className="term_checkbox"
                      checked={true}
                    />
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  {totalPrice > 0 || amountDue > 0 ? (
                    <>
                      <Col lg={6}>
                        {successMsg ? (
                          ""
                        ) : (
                          <button
                            className="submit_Payment"
                            onClick={handleShowConfirmModal}
                          >
                            Submit Payment
                          </button>
                        )}
                      </Col>
                      <Col lg={12}>
                        <div className="billing_detail_section_des">
                          <h4> Purchase Details: </h4>
                          <ul>
                            <li>These skips are a one time purchase.</li>
                            <li>
                              If you close your account, you will no longer have
                              access to the skip trace or property download
                              credited to your account.
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <Col lg={6}>
                      <button
                        className="submit_Payment"
                        onClick={handlePlaceOrderBtnClick}
                      >
                        Place Order
                      </button>
                    </Col>
                  )}
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
        {renderConfirmPaymentModal()}
        {renderAddBillingModal()}
      </div>
    </>
  );
};

export default FileUploadStep4New;
