import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import * as moment from "moment";
import { ToTitleCase } from "../../Utils/formating.util";
import { AffiliateGetReferralActivity } from "../../Utils/affiliate.util";
import DataTable from "react-data-table-component";

const customStyles = {
  tableWrapper: {
		style: {
			padding: '0px 20px',
		},
	},
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "600",
      backgroundColor:"#FCFCFD",
      color:"#101828",
      justifyContent:"center !important", 
      '&:first-child': {
        justifyContent:"start !important", 
      },
      '&:nth-child(2n)': {
        justifyContent:"start !important", 
      },
      '&:nth-child(4n)': {
        justifyContent:"center !important", 
      },
    },
  },
  headRow: {
		style: {
      
		},
	},
  cells: {
		style: {
      fontSize: "16px",
      fontWeight: "400",
      backgroundColor:"#FCFCFD",
      padding:"20px 15px",
      justifyContent:"center",
      color:"#101828",
    },
    '&:not(:last-of-type)': {

    },
		draggingStyle: {},
	},
  rows: {
		style: {
      justifyContent:"center",
			// '&:not(:last-of-type)': {
			// 	borderBottomStyle: 'solid',
			// 	borderBottomWidth: '1px',
			// 	borderBottomColor: theme.divider.default,
			// },
		},
		selectedHighlightStyle: {
			// use nth-of-type(n) to override other nth selectors
			'&:nth-of-type(3)': {
				justifyContent:"start !important",
        textAlign: "left !important",
			},
		},
		highlightOnHoverStyle: {
			transitionDuration: '0.15s',
			transitionProperty: 'background-color',
			borderBottomColor: 'none !important',
			outlineStyle: 'none !important',
			outlineWidth: '0 !important',
			outlineColor: "none !important",
      cursor:"text"
		},
		// stripedStyle: {
		// 	color: theme.striped.text,
		// 	backgroundColor: theme.striped.default,
		// },
	},
};

const AffiliateReferralActivity = () => {
  const [activityData, setActivityData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const columns = [
    {
      name: "Customer",
      selector: (row) => row.customer,
      style: { 
        textAlign: "left",
        justifyContent: "start",
      },
    },
    {
      name: "Order Type",
      selector: (row) => row.orderType,
      style: { 
        textAlign: "left",
        justifyContent: "start"
      },
    },
    {
      name: "Amount",
      selector: (row) =>
        "$" +
        Number(row.amount)
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.date).format("MMM DD, YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => ToTitleCase(row.status),
      conditionalCellStyles: [
        {
          when: (row) => row.status?.toLowerCase() == "paid",
          classNames: ["status_label_simple", "success"],
        },
        // {
        //   when: row => row.status?.toLowerCase() == 'pending',
        //   classNames: ['status_label_simple', 'warning']
        // }
      ],
    },
  ];

  useEffect(() => {
    setShowLoader(true);
    AffiliateGetReferralActivity().then((response) => {
      if (response.statusCode == 200) {
        setActivityData(response?.result);
      }
      setShowLoader(false);
    });
  }, []);

  return (
    <Row>
      

      <Col md={12}>
        <hr className="my-4 custom-hr"></hr>
      </Col>
      <Col md={12} className="my-4"> 
        <h2 className="mb-4">Referrals Activities</h2>
        {showLoader ? (
          <div className="loader-small"></div>
        ) : (
          <div className="table-data-main resp-table2 p-0 m-0 cst-paginatio">
            <DataTable
              columns={columns}
              data={activityData}
              striped
              highlightOnHover
              pagination
              customStyles={customStyles}
            />
          </div>
        )} 
      </Col>
    </Row>
  );
};

export default AffiliateReferralActivity;
