import React, { useEffect, useState } from "react";
import { GetFormattedNumber } from "../../Utils/formating.util";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import CheckoutForm from "./CheckoutForm";
import {
  PAYMENT_SUCCESS_API_ENDPOINT,
  SKIPTRACE_FILE_UPLOAD_ENDPOINT,
  SKIPTRACE_PLACE_ORDER_API_ENDPOINT,
} from "../../Constants/api-routes.constant";
import { GetAuthToken } from "../../Utils/auth-verification.util";
// import { STRIPE_PK } from "../../Constants/api-credentials.constant";
import { Col, Row } from "react-bootstrap";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PK}`
);



const FileUploadStep4 = (props) => {


  const profileData = props.profileData;
  const fileUpload = props.fileUpload;
  const fileUploadData = props.fileUploadData;
  const amountDue = props.amountDue;
  const chargableHits = props.chargableHits;
  const paymentIntentData = props.paymentIntentData;
  const orderNumber = props.orderNumber;
  const orderId = props.orderId;

  const customerPricePerHit = profileData.billing?.pricePerHit ?? 0.25;
  const Price_Per_Hits = customerPricePerHit;

  // props.handleStep4Call

  const remainingSkiptraceHits = profileData.billing.remainingHits;
  const stripClientSecret = paymentIntentData.client_secret;
  const [paymentStatusFlag, setPaymentStatusFlag] = useState(false); // true = paid, false = unpaid
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // Save the payment in backend
  const handlePaymentStatusCall = async (paymentStatusFlag) => {
    setShowLoader(true);
    setPaymentStatusFlag(paymentStatusFlag);
    if (paymentStatusFlag) {
      const reqBody = {
        orderId: orderId,
        amountPaid: amountDue,
        paymentStatus: "paid",
        paymentIntentId: paymentIntentData?.id,
        dateCreated: paymentIntentData?.created,
        hitsPurchased: chargableHits,
      };
      const token = GetAuthToken();

      // Store the payment in the DB
      fetch(PAYMENT_SUCCESS_API_ENDPOINT, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqBody),
      })
        .then((rawResponse) => rawResponse.json())
        .then((response) => {
          if (response.statusCode == "200") {
            console.log(response.statusCode);
          } else {
            setErrorMsg("Unable to create new payment.");
          }
          setShowLoader(false);
        });
    } else {
      setErrorMsg("Payment Failed. Make sure your credit card is valid.");
      setShowLoader(false);
    }
  };

  // Place order
  const handlePlaceOrderBtnClick = () => {
    setShowLoader(true);
    // Place order API
    const request = {
      orderId: orderId,
      skiptraceCount: fileUploadData.length - 1,
      paymentStatus: paymentIntentData?.status,
    };
    const token = GetAuthToken();
    fetch(SKIPTRACE_PLACE_ORDER_API_ENDPOINT, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    })
      .then((rawRes) => {
        return rawRes.json();
      })
      .then((response) => {
        if (response.statusCode == "200") {
          props.handleStep4Call(true);
        } else {
          setErrorMsg(response?.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setErrorMsg(error?.message);
        setShowLoader(false);
      });
  };

  return (
    <>
 {showLoader && (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      )}
      <div className="row mt-5">
        <div className="col-md-12 mx-auto">
          {errorMsg ? (
            <div className="alert alert-danger">
              <h6 style={{ color: "#0f5132 !important", fontSize: "0.8rem" }}> <BsFillExclamationCircleFill /> {errorMsg}</h6>
            </div>
          ) : (
            ""
          )}
          <h4 className="mb-3">Order Summary</h4>

          <div className="resp-table2">
            <table className="table ">
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>File Name</th>
                  <th>No. of Records in File</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                <tr key="">
                  <td>{orderNumber}</td>
                  <td>{fileUpload.name}</td>
                  <td>{fileUploadData.length - 1}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4 className="mb-3">Order Total</h4>
          <div className="resp-table2">
            <table className="table ">
              <thead>
                <tr>
                  <th> Tokens from balance of</th>
                  <th>Total Use </th>
                  <th>Detail</th>
                  <th>Due Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr key="">
                  <td>{GetFormattedNumber(remainingSkiptraceHits)}</td>
                  <td> {remainingSkiptraceHits > fileUploadData.length - 1
                    ? fileUploadData.length - 1
                    : remainingSkiptraceHits}</td>
                  <td>{chargableHits} records @ {Price_Per_Hits} per hit:</td>
                  <td>${amountDue}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>



      {paymentStatusFlag ? (
        <div className="row mt-5">
          <div className="col-md-10">
            <div className="alert alert-success">
              <h4>Payment Confirmation!</h4>
              <p>
                Thank you for your payment of ${amountDue}. Click the "Place
                Order" button to complete the order.
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {amountDue > 0 && !paymentStatusFlag ? (
        <div className="row mt-5">
          <div className="col-md-5">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                clientSecret={stripClientSecret}
                clientName={profileData.firstName + " " + profileData.lastName}
                paymentStatusCall={handlePaymentStatusCall}
              />
            </Elements>
          </div>
        </div>
      ) : (
        <div className="row mt-3">
          <div className="col-md-12  filestyle">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handlePlaceOrderBtnClick}
            >
              Place Order
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploadStep4;
