import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Spinner, Form, Button, Image } from "react-bootstrap";
import { BsFillExclamationCircleFill } from "react-icons/bs";

import { LOGIN_API_ENDPOINT } from "../../Constants/api-routes.constant";
import "../../assets/css/main.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import leftBannerImage from "../../assets/img/signup_new.png";
import moment from "moment";
const Login = () => {
  const history = useNavigate();
  // Save affiliate referral id. This will be used during registration
  const authResult = new URLSearchParams(window.location.search);
  const referralQS = authResult.get('ref');
  if (referralQS) {
    localStorage.setItem('affiliate_id', referralQS);
  }

  useEffect(() => {
    document.title = "Login - Skipify.ai";
    window.scrollTo(0, 0);
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [tokenExpiry, setTokenExpiry] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    localStorage.setItem("skipifyAuthToken", authToken);
    localStorage.setItem("skipifyTokenExpiry", tokenExpiry);
    localStorage.setItem("skipifyUserFullName", userFullName);
    localStorage.setItem("skipifyUserEmail", userEmail);
    localStorage.removeItem("isRegPayment");
    localStorage.removeItem("NavigatePayment");
  });

  // Form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username?.trim()) {
      setUsernameError("Email is required.");
    } else {
      setUsernameError("");
    }
    if (!password?.trim()) {
      setPasswordError("Password is required.");
    } else {
      setPasswordError("");
    }

    // Post data
    if (username?.trim() && password?.trim()) {
      setShowLoader(true);
      fetch(LOGIN_API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          setFormError("Error: Invalid Username or Password provided.");
        })
        .then((response) => {
          setShowLoader(false);
          if (response?.access_token && response?.token_expiry) {
            setAuthToken(response.access_token);
            setTokenExpiry(response.token_expiry);
            setUserFullName(response?.firstName + " " + response?.lastName);
            setUserEmail(response?.email);
            setFormError("");
            const nextBillingData = moment(response.currentPackageNextChargingDate)
            if(response?.package.toLowerCase().includes('free')){
              return (window.location = "/billing");
            } else if (response?.isRegPayment) {
              return (window.location = "/billing");
            }else if(nextBillingData < moment()){
              return (window.location = "/billing?last-payment-declined=true");
            }
            return (window.location = "/dashboard");
          } else {
            setFormError("Error: Invalid Username or Password provided.");
          }
        })
        .catch((e) => {
          setFormError("Error: Invalid Username or Password provided.");
          setShowLoader(false);
        });
    }
  };

  return (
    <>
      {/* <TopNav /> */}

      <div className="register_section">
        <div className="register_section_overlay">
          <Row>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={12} lg={{ span: 7, order: 1 }} className="p-0 text-center">
              <div className="signup_image_div" >
                <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                  <Image src={leftBannerImage} alt="" className="bg_image" />
                </a>
                <div className="text-div">
                  <h4>Lead Generation.</h4>
                  <h4>Made Easy.</h4>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={12} lg={{ span: 5, order: 2 }} className="mb-5">
              <div className="main-form2">
                <Row>
                  <Col sm={12} md={12} lg={10} className="mx-auto text-center mt-5">
                    <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                      <Image
                        src={require("./../../assets/img/logo.png")}
                        alt="logo"
                        className="register_logo"
                      />
                    </a>
                    <h2 className="register_title">Welcome Back!</h2>
                    <p className="register_des">
                      Please login using your account details.
                    </p>
                  </Col>
                </Row>

                <Form onSubmit={handleSubmit} >
                  <Row>
                    <Col sm={12} md={12} lg={9} className="mx-auto">
                      {formError && (
                        <div className="alert alert-danger mt-2">
                          <h6
                            style={{
                              color: "#0f5132 !important",
                              fontSize: "0.8rem",
                            }}
                          >
                            {" "}
                            <BsFillExclamationCircleFill /> {formError}
                          </h6>
                        </div>
                      )}
                      <Row>
                        <Col md={12}>
                          <Form.Group className="my-4">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control
                              onChange={(e) => setUsername(e.target.value)}
                              type="text"
                              name="username"
                              required
                              placeholder="email@domain.com"
                            />
                            <p className="text-danger">{usernameError}</p>
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group className="mb-5 password">
                            <Form.Label>Password *</Form.Label>
                            <div className="password-input-container">
                              <Form.Control
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPass ? "text" : "password"}
                                name="password"
                                id="password"
                                placeholder="***********"
                              />
                              {showPass ? (
                                <AiFillEye
                                  className="show_password"
                                  onClick={() => setShowPass(!showPass)}
                                />
                              ) : (
                                <AiFillEyeInvisible
                                  className="show_password"
                                  onClick={() => setShowPass(!showPass)}
                                />
                              )}
                              {/* {showPass ? <IoIosEye className="show_password" onClick={() => setShowPass(!showPass)} /> : <IoIosEyeOff className="show_password" onClick={() => setShowPass(!showPass)} />} */}
                            </div>

                            <p className="text-danger">{passwordError}</p>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <div className="tab-container">
                            {showLoader ? (
                              <button type="button" className="submit">
                                {" "}
                                <Spinner animation="border" size="sm" />
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="submit"
                                onClick={handleSubmit}
                              >
                                LOGIN
                              </button>
                            )}
                          </div>
                        </Col>

                        <Col md={12} className="my-4">
                          <Link
                            to="/forgot-password"
                            className="forgot-password-login"
                          >
                            Forgot your password?
                          </Link>
                        </Col>

                        <Col md={12}>
                          <div className="d-grid gap-2">
                            <a
                              href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/pricing-plan`}
                              className="registerbtn"
                            >
                              Create an account
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <FooterFront /> */}
    </>
  );
};

export default Login;
