import { BiPlusMedical } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal, Button, Image, Form } from "react-bootstrap";
import Select from "react-select";
import "./../../assets/css/main.css";
import { BsFillCheckCircleFill, BsRecycle  } from "react-icons/bs";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BillingCard from "./BillingCard";
import BillingDetail from "./BillingDetail";
import SubscriptionCheckout from "./SubscriptionCheckout";
import { React_Select_Style } from "../../Constants/react-select-style.constants";
import moment from "moment";
import {
  GetFormattedCurrency,
  GetFormattedCurrency2,
  GetFormattedNumber,
  getFormattedCurrencyInInteger,
} from "../../Utils/formating.util";
import {
  changeSelectedPlan,
  resetSelectedPlan,
  updateCurrentBilling,
  updatesPackagesData,
} from "../../store/slices/features/payment-plan";
import {
  getCurrentPlan,
  getCurrentPlanData,
  getFreePlanData,
  getSelectedPlan,
  getTotalPackages,
} from "../../store/selectors/features/payment-plan";
import { GetAuthToken } from "../../Utils/auth-verification.util";
import {
  BILLING_GET_CURRENT_PLAN_DETAILS_ENDPOINT,
  BILLING_GET_SINGLE_ACTIVE_ENDPOINT,
  PACKAGES_GET_LIST_ENDPOINT,
} from "../../Constants/api-routes.constant";
// import { STRIPE_PK } from "../../Constants/api-credentials.constant";
import { createActiveAddSkip } from "../../store/slices/features/app";
import {
  downgradePlanPurchase,
  freePlanPurchase,
} from "../../Utils/billing.util";
import { CancelAccount } from "../../Utils/clients.util";
import { GetProfile } from "../../Utils/common";
import DeleteModal from "./DeleteModal";
import deleteIcon from "./../../assets/img/delete.png"
import { FiAlertTriangle } from "react-icons/fi";
import { GetListRotationDetails } from "../../Utils/billing.util";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const SubscriptionPlan = () => {
  document.title = "Subscriptions - Skipify.ai";
  const dispatch = useDispatch();

  const selectedPlan = useSelector(getSelectedPlan);
  const packageList = useSelector(getTotalPackages);
  const currentPlan = useSelector(getCurrentPlan);
  const currentPlanData = useSelector(getCurrentPlanData);

  const freePlanData = useSelector(getFreePlanData);
  const freePlanFeatures = freePlanData?.features?.split("\n") || [];

  // const [planDetails, setPlanDetails] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showDowngrade, setShowDowngrade] = useState(false);
  const [showFreeModal, setShowFreeModal] = useState(false);
  const [showCancelAccountModel, setShowCancelAccountModel] = useState(false);
  const [deletePopupStatus, setDeletePopupStatus] = useState(false);
  const [listRotationDaysRemaining, setListRotationDaysRemaining] = useState(null)

  // For monthly, commitment, annually
  const [type, setType] = useState("basic");
  const [plans, setPlans] = useState([]);

  // handle Add Skips
  const handleSkipRedirection = (index) => {
    dispatch(createActiveAddSkip(index));
  };

   // handle List Rotation
   const handleListRotationRedirection = (index) => {
    dispatch(createActiveAddSkip(index));
  };

    // Get list rotation customer data
    const getListRotationData = async () => {
      try {
        const response = await GetListRotationDetails();
        if (response?.statusCode == 200 && response?.result?.daysRemaining) {
          setListRotationDaysRemaining(response.result?.daysRemaining)
        }  
        
      } catch (error) {
        console.log('error', error);
      }
    }

  useEffect(() => {
    // getCurrentSubscriptionPlan();
    getPackageList();
    getActiveBilling();
    getListRotationData();
  }, []);

  /**
   * Get Current subscription details
   * @returns
   */
  const token = GetAuthToken();
  /**
   * Get All available packages
   * @returns
   */
  const getPackageList = async () => {
    setShowLoader(true);

    return fetch(PACKAGES_GET_LIST_ENDPOINT, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((rawRes) => rawRes.json())
      .then((response) => {
        if (response?.statusCode == 200 && response?.result) {
          dispatch(updatesPackagesData(response.result));
          const list = response.result.filter((item) => item.title.includes('Basic'));
          const paidPlans = list?.filter((plan) => plan.price > 0);
          setPlans(paidPlans)
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setErrorMsg(err?.message);
        setShowLoader(false);
      });
  };

  /**
   * Get customer Single Active Billing data
   */

  const getActiveBilling = async () => {
    const activeBilingResponse = await fetch(
      BILLING_GET_SINGLE_ACTIVE_ENDPOINT,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        setErrorMsg(err?.message);
        return;
      });

    if (
      activeBilingResponse?.statusCode == 200 &&
      activeBilingResponse?.result
    ) {
      dispatch(updateCurrentBilling(activeBilingResponse.result));
    }
  };

  const handleCloseDowngradeModal = () => {
    dispatch(resetSelectedPlan());
    setShowDowngrade(false);
  };

  const handleShowDowngradeModal = () => setShowDowngrade(true);

  const handleDowngradePlan = async () => {
    try {
      const response = await downgradePlanPurchase({
        packageId: selectedPlan._id,
      });

      handleCloseDowngradeModal();
      setSuccessMsg("Plan has been updated successfully.");
      setTimeout(() => {
        window.location.href = "/billing";
      }, 2000);
    } catch (error) {
      console.log(error);
      handleCloseDowngradeModal();
      setErrorMsg(error?.message || "");
    }
  };

  const renderConfirmDowngradeModal = () => {
    return (
      <Modal
        show={showDowngrade}
        onHide={handleCloseDowngradeModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="modal_notification">
            <h5>
              {`By selecting this package, your current plan will be downgraded to ${selectedPlan.title}`}
              .{" "}
            </h5>
            <h5>
              Your new plan will take affect at your new billing date and you
              will be charged the new lower amount.
            </h5>

            <h6>Please click YES below to confirm the downgrade.</h6>
            <div className="mt-3">
              <Button variant="primary" onClick={handleDowngradePlan}>
                Yes
              </Button>
              <Button variant="warning" onClick={handleCloseDowngradeModal}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleSelectPlanClick = (planSelected, isDowngrade = false) => {
    if (isDowngrade) {
      handleShowDowngradeModal();
    }
    dispatch(changeSelectedPlan(planSelected));
  };

  const monthlyHandler = () => {
    setType("month");
    const monthlyChargeList = packageList.filter(
      (item) => item?.isMonthly || item?.isMonthlyCharge
    );
    setPlans(monthlyChargeList);
  };

  const commitmentHandler = () => {
    setType("commit");
    const commitmentChargeList = packageList.filter(
      (item) => item?.isCommitedCharge
    );
    setPlans(commitmentChargeList);
  };

  const yearlyHandler = () => {
    setType("year");
    const yearlyChargeList = packageList.filter((item) => item?.isAnnualCharge);
    setPlans(yearlyChargeList);
  };

  const handleCloseFreeModal = () => setShowFreeModal(false);

  const handleShowFreeModal = () => setShowFreeModal(true);

  const handleFreePlan = async () => {
    try {
      const response = await freePlanPurchase();

      handleCloseFreeModal();
      setSuccessMsg("Plan has been updated successfully.");
      setTimeout(() => {
        window.location.href = "/billing";
      }, 2000);
    } catch (error) {
      console.log(error);
      handleCloseFreeModal();
      setErrorMsg(error?.message || "");
    }
  };

  const renderFreeModal = () => {
    return (
      <Modal
        show={showFreeModal}
        onHide={handleCloseFreeModal}
        backdrop="static"
        keyboard={false}
        centered
        className="glassModel"
      >
        <Modal.Body>
          <Row className="d-flex justify-content-end">
            <Col md={1}>
              <AiFillCloseCircle size={25} onClick={handleCloseFreeModal} />
            </Col>
          </Row>
          <Row>
            <Col md={10} className="mx-auto">
              <div className={`pricing_item basic billing`}>
                <div className="pricing_item_header">
                  <h2>Free Plan</h2>
                </div>
                <div className={`pricing_item_price my-5`}>
                  {/* <div className="pricing">
                <span className="dollar">$</span>
                <span className="amount">{getFormattedCurrencyInInteger(data.price)}</span>
                <span className="type">/{type === 'year' ? 'year' : 'month'}</span>
              </div> */}
                  <button className="select_plan" onClick={handleFreePlan}>
                    Downgrade Plan
                  </button>
                  <div className="details">
                    <h4>Plan Features:</h4>
                    <ul>
                      {freePlanFeatures.map((item, index) => {
                        return (
                          <li key={index}>
                            <BsFillCheckCircleFill />
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  // For filltring
  const proTypeHandler = (type) => {
    setType(type.toLowerCase());
    const proPackages = packageList.filter(item => item.title.includes(type) && item.price > 0);
    setPlans(proPackages)
  }

  // Hide First paid plan if the current plan is higher
  const updatePackageList = (activePlans) => {
      GetProfile().then((response) => {
        if (response.statusCode == 200) {
          const currentPlanID = response.result.billing.currentPackageId;
          const currentPlan = activePlans.find(
            (actplan) => actplan._id == currentPlanID
          );

          activePlans = activePlans.filter((actPlan) => actPlan.price > 0);
          if (
            !currentPlan || 
            (activePlans[0].title?.toLowerCase().includes("basic") &&
            currentPlan?.price > activePlans[0].price)
          ) {
            activePlans.shift();
            
          } 
          
          setPlans(activePlans);
          
        }
      });
    
  };


  // Work of popup starts here
  
  // const [deletePopupStatus, setDeletePopupStatus] = useState(false);
  const cancellationReason = [
    {
      value: "Too expensive",
      label: "Too expensive",
    },
    {
      value: "Not getting desired results",
      label: "Not getting desired results",
    },
    {
      value: "Unsatisfactory customer support",
      label: "Unsatisfactory customer support",
    },
    {
      value: "Not using the service enough",
      label: "Not using the service enough",
    },
    {
      value: "Technical problems or bugs",
      label: "Technical problems or bugs",
    },
    {
      value: "Switching to a competitor",
      label: "Switching to a competitor",
    },
    {
      value: "Pausing business activities temporarily",
      label: "Pausing business activities temporarily",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  const onReasonChange = (reason) => {
    // if(reason.value == "other") {
    //   setShowOtherReasonTextbox(true);
    // } else {
    //   setShowOtherReasonTextbox(false);
    // }
    // setReasonSelected(reason.value);
  };

  function showHideFunc() {
    alert("Hello");
  }

  return (
    <div>
      {/* Purchase Area */}
      {selectedPlan?.title && !showDowngrade ? (
        <Elements stripe={stripePromise}>
          <BillingDetail />
        </Elements>
      ) : (
        <div>
          {showLoader === true ? (
            <div className="loaderBlocker">
              <div className="fadeOut"></div>
              <div className="loader"></div>
            </div>
          ) : (
            ""
          )}
          {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : ""}
          {successMsg ? (
            <div className="alert alert-success">{successMsg}</div>
          ) : (
            ""
          )}

        <Row>
            <Col>
            {currentPlan?.isCancelledSubscription ? 
                      <div className="mt-1 alert alert-warning">
                        <FiAlertTriangle  style={{fontSize: '25px'}}/> Your plan renewal has been canceled. But the current plan will remain active until <strong>{moment(currentPlan.currentPackageExpiryDate).format(
                        "MMMM DD, YYYY"
                      )}</strong>.  
                      </div>
              : ""}
            </Col>
          </Row>
          <Row className="my-3">
            <Col xxl={6} xl={6} lg={12} md={12} className="mb-lg-3 mb-md-3">
              <div className="box-glow fade-grey">
                <Row className="justify-content-between"> 
                  <Col sm={12} className="">            
                    <h6>Your current plan is </h6>
                  </Col>
                  <Col sm={6}>
                    <h1>{currentPlan?.currentPackageTitle ?? "--"}</h1>
                    <p className="m-0 pb-2 payment-date-bill">
                      <span className="pr-cst-2">Next payment due:</span>  
                      {currentPlan?.currentPackageExpiryDate && !currentPlanData?.isFreePackage
                        ? moment(currentPlan?.currentPackageExpiryDate).format(
                          "MMM DD, YYYY"
                        )
                        : "-"}
                    </p>
                  </Col>
                  <Col sm={6} className="text-lg-end">
                    <h1>{currentPlanData?.price
                        ? `${GetFormattedCurrency(currentPlanData.price)}`
                        : "$0.00"}</h1>
                    <p className="m-0">{currentPlanData?.isAnnualCharge ? "Yearly" : "Monthly"}</p>
                  </Col> 
                </Row>
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={6} sm={6} md={6} className="mb-md-3">
              <div className="box-glow">
                <Row className="justify-content-start"> 
                  <Col sm={12} className="">            
                    <h4>{currentPlan?.remainingHits?.toLocaleString()}</h4>
                  </Col>
                  <Col sm={12}>
                    <p>
                      Skips remaining
                    </p>
                  </Col>
                  <Col sm={12} className="text-lg-start">
                    <Button
                      className="mini-btn-prim"
                      variant=""
                      onClick={(e)=>{handleSkipRedirection(1)}}
                    >
                      Add Skips
                    </Button>
                  </Col> 
                </Row>
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={6} sm={6} md={6}>
              <div className="box-glow">
                <Row className="justify-content-start"> 
                  {listRotationDaysRemaining > 0 ? (
                    <>
                    <Col sm={12} className="">            
                      <h4>{listRotationDaysRemaining}</h4>
                    </Col>
                    <Col sm={12} className="">
                      <p>
                        List rotation days left
                      </p>
                    </Col>
                    <Col sm={12} className="text-lg-start">
                    <Button
                      className="mini-btn-prim"
                      variant=""
                      onClick={(e)=>{handleSkipRedirection(4)}}
                    >
                      Details
                    </Button>
                  </Col> 
                  </>
                  ) : (
                    <>
                     <Col sm={12} className="mb-4">            
                      <h4>List Rotation</h4>
                    </Col>
                    
                  <Col sm={12} className="text-lg-start">
                    <Button
                      className="mini-btn-prim mt-3"
                      variant=""
                      onClick={(e)=>{handleSkipRedirection(4)}}
                    >
                      Add list rotations
                    </Button>
                  </Col> 
                  </>
                  )}
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-between">
            <Col xl={6} className="mx-auto">
              <h3 className="text-start my-4">Choose a subscription plan</h3>
            </Col>
            <Col xl={6} className="mx-auto text-end align-self-center">
              <div className="top_button_section justify-content-end">
                <ul>
                  <li className={`${type === 'basic' ? 'active' : ''}`} onClick={() => proTypeHandler('Basic')}>Basic</li>
                  <li className={`${type === 'pro' ? 'active' : ''}`} onClick={() => proTypeHandler('Pro')}>Pro</li>
                  <li className={`${type === 'vip' ? 'active' : ''}`} onClick={() => proTypeHandler('VIP')}>VIP</li>
                </ul>
              </div>
            </Col>
          </Row>

          <section className="pricing-plan" style={{ padding: "20px 0px" }}>
            <div className="plan-container">
              <Row>
                <Col md={12} lg={12} xl={12} className="mx-auto justify-content-start">
                  {/* <Row>
                    <Col md={12}>
                      <div className="top_button_section billing">
                        <button
                          className={`plan_btn baisc ${type === 'month' ? 'active' : ''}`}
                        // onClick={monthlyHandler}
                        >
                          Pay Monthly
                        </button>
                        <button
                          className={`plan_btn pro ${type === 'commit' || type === 'year' ? 'active' : ''}`}
                          onClick={commitmentHandler}
                        >
                          Pay Annually
                        </button>
                      </div>
                    </Col>
                  </Row> */}

                  {/* <Row>
                    <Col md={4} className="mx-auto mb-2">
                      {type === 'commit' && (
                        <span className='commit_text' onClick={yearlyHandler}>
                          Save up to 8% more by paying up front
                        </span>
                      )}

                      {type === 'year' && (
                        <span className='commit_text' onClick={commitmentHandler}>
                          Change to annual plans billed monthly
                        </span>
                      )}
                    </Col>
                  </Row> */}

                  <Row className="justify-content-center">
                    {plans.map((plan) => (
                      <Col
                        xs={12}
                        sm={12}
                        md={9}
                        lg={6}
                        xl={4}
                        className="p-2"
                        key={plan.id}
                      >
                        <BillingCard
                          data={plan}
                          type={type}
                          selectPlan={handleSelectPlanClick}
                        />
                      </Col>
                    ))}

                  </Row>

                  {/* <Row className="d-flex justify-content-end">
                    <Col md={3}>
                      <Button
                        variant=" btn btn-default2"
                        onClick={(e) => window.location.href="/cancel-account-request"}
                      >
                        Cancel Account
                      </Button>
                    </Col>
                  </Row> */}
                {!currentPlan?.isCancelledSubscription ? 
                  <Row className="d-flex justify-content-start mt-4">
                    <Col className="col-lg-3 col-md-6 col-sm-12">
                      <Button
                        variant=" btn text-danger deleteSubs"
                        onClick={(e) => setDeletePopupStatus(true)}
                      >
                        <Image src={deleteIcon}></Image>
                        Cancel my subscription
                      </Button>
                    </Col>
                  </Row>
                  : '' }

                  
                </Col>
              </Row>
            </div>
          </section>


          <DeleteModal 
            showStatus={deletePopupStatus}
            modalStatus={setDeletePopupStatus}
            dateExp={currentPlan?.currentPackageExpiryDate}
          ></DeleteModal>


          <DeleteModal 
            showStatus={deletePopupStatus}
            modalStatus={setDeletePopupStatus}
            dateExp={currentPlan?.currentPackageExpiryDate}
          ></DeleteModal>

          {renderConfirmDowngradeModal()}
          {renderFreeModal()}
        </div>
      )}

       
    </div>
  );
};

export default SubscriptionPlan;
