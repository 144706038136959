import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Col, Row } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BillingList from "./BillingList";  
import SubscriptionPlan from "./SubscriptionPlan";
import DashbaordLayout from "../DashbaordLayout";
import AddSkip from "./AddSkip";
import InvoiceCom from "../invoicedetail";
import { activeAddSkip } from "../../store/selectors/features/app";
import { createActiveAddSkip } from "../../store/slices/features/app";
import { updatesPackagesData } from "../../store/slices/features/payment-plan";
import {
  BILLING_DISABLE_ENDPOINT,
  BILLING_ENABLE_ENDPOINT,
  BILLING_GET_LIST_ENDPOINT,
  PACKAGES_GET_LIST_ENDPOINT,
} from "../../Constants/api-routes.constant";
import { useSearchParams } from "react-router-dom";
import AddListRotation from "./AddListRotation";
import AddListStorage from "./AddListStorage";
import "../../assets/css/billing.css";


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const Billings = (props) => {
 const [searchParams] = useSearchParams()
  

  const dispatch = useDispatch();
  const addSkip = useSelector(activeAddSkip);


  // const [billingList, setBillingList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");


  const handleSkipRedirection = (index) => {
    dispatch(createActiveAddSkip(index));
  }

  useEffect(() => {
    // GetBillingList();
    getPackageList();
    document.title = 'Billing - Skipify.ai';
    if(Boolean(searchParams.get('last-payment-declined')) === true){
      handleSkipRedirection(2)
    }
  }, []);

  const getPackageList = async () => {
    setShowLoader(true);

    return fetch(PACKAGES_GET_LIST_ENDPOINT, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((rawRes) => rawRes.json())
      .then((response) => {
        if (response?.statusCode == 200 && response?.result) {
          dispatch(updatesPackagesData(response.result));
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setErrorMsg(err?.message);
        setShowLoader(false);
      });
  };

  // const GetBillingList = () => {
  //   const token = GetAuthToken();
  //   if (!token) return [];
  //   setShowLoader(true);
  //   return fetch(BILLING_GET_LIST_ENDPOINT, {
  //     method: "Get",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`, // notice the Bearer before your token
  //     },
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((response) => {
  //       if (response?.result) {
  //         setBillingList(response.result);
  //       }
  //       setShowLoader(false);
  //     })
  //     .catch((err) => {
  //       setShowLoader(false);
  //       return err;
  //     });
  // };

  const pageTitle = "Billing";

  return (
    <div>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex" id="wrapper">

        <DashbaordLayout title={pageTitle}>
          <div id="page-content-wrapper">
            <h2 className="main-title-mobile">{pageTitle}</h2>
            {/* <!-- Page content--> */}
            {errorMsg ? (
              <div className="alert alert-danger">{errorMsg}</div>
            ) : (
              ""
            )}
            {successMsg ? (
              <div className="alert alert-success">{successMsg}</div>
            ) : (
              ""
            )}

            <Tabs selectedIndex={addSkip}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                  <div className="tabs_top_main">
                    {/* <h4 className="biling_title">Billing Management</h4> */}
                    <TabList>
                      <Tab onClick={() => handleSkipRedirection(0)}> Subscriptions</Tab>
                      <Tab onClick={() => handleSkipRedirection(1)}> Add Skips</Tab>
                      <Tab onClick={() => handleSkipRedirection(2)}> Payment Methods</Tab>
                      <Tab onClick={() => handleSkipRedirection(3)}> Invoices</Tab>
                      <Tab onClick={() => handleSkipRedirection(4)}> List Rotation</Tab>
                      <Tab onClick={() => handleSkipRedirection(5)}> List Storage</Tab>
                    </TabList>
                  </div>
                </Col>
              </Row>

              <TabPanel>
                <SubscriptionPlan />
              </TabPanel>
              <TabPanel >
                <Elements stripe={stripePromise}>
                  <AddSkip />
                </Elements>
              </TabPanel>
              <TabPanel style={{minHeight:"500px"}}>
                <BillingList />
                {/* <AutoRenewSettings /> */}
              </TabPanel>
              <TabPanel style={{minHeight:"500px"}}>
                <InvoiceCom />
                {/* <Invoice /> */}
              </TabPanel>
              <TabPanel style={{minHeight:"500px"}}>
              <Elements stripe={stripePromise}>
                <AddListRotation />
                </Elements>
                {/* <Invoice /> */}
              </TabPanel>
              <TabPanel style={{minHeight:"500px"}}>
              <Elements stripe={stripePromise}>
                <AddListStorage />
                </Elements>
                {/* <Invoice /> */}
              </TabPanel>
            </Tabs>
          </div>
        </DashbaordLayout>

      </div>
    </div>
  );
};

export default Billings;
