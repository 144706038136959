import { DASHBOARD_GET_PROPERTY_CHART_DATA_ENDPOINT, DASHBOARD_GET_SUMMARY_ENDPOINT } from "../Constants/api-routes.constant";
import { GetAuthToken } from "./auth-verification.util";

export const GetDashboardSummary = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(DASHBOARD_GET_SUMMARY_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};


export const GetDashboardPropertyChartData = async (startDate, endDate) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(DASHBOARD_GET_PROPERTY_CHART_DATA_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate
    })
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};
