export const AgeFilterOptionConstant = [
    {
      label: "Any",
      min: null,
      max: null,
    },
    {
      label: "18-34",
      min: 18,
      max: 34,
    },
    {
      label: "35-49",
      min: 35,
      max: 49,
    },
    {
      label: "50-64",
      min: 50,
      max: 64,
    },
    {
      label: "65-79",
      min: 65,
      max: 79,
    },
    {
      label: "80+",
      min: 80,
      max: 125,
    },
  ];