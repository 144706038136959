import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Form, InputGroup } from "react-bootstrap";
import { AffiliateJoinProgramRequest } from "../../Utils/affiliate.util";
import Modal from "react-bootstrap/Modal";

const AffiliateJoinProgram = () => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleJoinProgramClick = () => {
    setShowConfirmation(true);
  };

  const handleModelYesClick = () => {
    setShowLoader(true);
    setShowConfirmation(false);
    setErrorMsg("");
    AffiliateJoinProgramRequest().then((response) => {
      if (response?.statusCode == 200) {
        setDisableButton(true);
        // setSuccessMsg(
        //   "Your request received successfully. We will update you shortly."
        // );
        window.location.reload();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      } else {
        setErrorMsg(
          response?.message || "Error: Please try again in a few minutes"
        );
      }
      setShowLoader(false);
    });
  };

  return (
    <>
      {showLoader &&
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      }
      <Row>
        <Col md={12} className="my-4">
          <Card className="affiliate_card">
            <Card.Body>
              {errorMsg && <p className="alert alert-danger">{errorMsg}</p>}
              {successMsg && <p className="alert alert-success">{successMsg}</p>}

              <h2 className="mb-4">Affiliate Program</h2>
              <p>
                Join our Affiliate Program today and unlock endless
                opportunities to monetize your platform! Click the "Join
                Affiliate Program" button to kickstart your journey towards
                earning lucrative commissions.{" "}
              </p>
              <p>
                By joining us, you'll gain access to a plethora of marketing
                resources, exclusive promotions, and dedicated support to
                maximize your success. Whether you're a seasoned affiliate
                marketer or just starting out, we welcome you to be part of our
                thriving community.{" "}
              </p>
              <p>
                Don't miss out on this chance to collaborate with us and turn
                your audience into a source of revenue. Click the button now and
                let's embark on a mutually beneficial partnership!
              </p>
              <Button
                className="float-end"
                onClick={handleModelYesClick}
                // onClick={handleJoinProgramClick}
                disabled={disableButton}
              >
                Join Affiliate Program
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showConfirmation} centered onHide={() => setShowConfirmation(false)}>
        <Modal.Header style={{ background: "#0b2548" }}>
          <h5 style={{ padding: "10px 5px 0px", color: "white" }}>
            Request Confirmation
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="newsletter_message_modal">
            <p>Are you sure you want to join our Affiliate Program?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-default2"
            onClick={() => setShowConfirmation(false)}
          >
            No
          </button>
          <button className="btn btn-primary" onClick={handleModelYesClick}>
            Yes, please
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AffiliateJoinProgram;
