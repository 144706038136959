import React from "react";
import AllRoutes from "./Routes/allRoutes";

import reduxStore from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Aos from 'aos';
import './App.css';
import './assets/new_theme/css/fonts.css';
import "aos/dist/aos.css";
import './assets/css/bootstrap-icons.css';
import './assets/css/bootstrap.min.css';
import './assets/css/fonts.css';
import './assets/css/custom.css';
import './assets/new_theme/css/styles.css';
import './assets/js/scripts.js';

import { IdleTimerProvider } from 'react-idle-timer'; // To logout user if inactivity for last 60 mins
import MaintenanceRoute from "./Routes/MaintenanceRoute.js";

<script src="https://cdn.jsdelivr.net/npm/chart.js"></script>

export const { store, persistor } = reduxStore();


function App() {

  const tokenExpTime = Date.now(localStorage.skipifyTokenExpiry / 1000);
  // Check for token to keep user logged in
  if (tokenExpTime) {

    const currentTime = Date.now() / 1000; // to get in milliseconds

    if (tokenExpTime < currentTime) {
      // store.dispatch(logoutUser());
      localStorage.clear();
      // Redirect to login
      window.location.href = "/";
    }
  }


  // for animation
  Aos.init({
    duration: 1500,
  });

  const handleLogout = () => {
    // Redirect to login
    if (window.location.pathname !== '/') {
      localStorage.clear();
      window.location.href = "/";
    }
  }


  const maintenence = process.env.REACT_APP_IN_MAINTENANCE

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IdleTimerProvider
          timeout={60 * 60 * 1000} // 60 minutes in milliseconds
          onIdle={handleLogout}
        >
          {maintenence == 'true' ? <MaintenanceRoute /> : <AllRoutes />}
        </IdleTimerProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
