import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import NavBarMenu from './../Essentials/NavBarMenu'
import avatar from '../assets/img/user.jpg'
import menubtn2 from '../assets/img/semi-menu.svg'
import bell from '../assets/img/ic-bell.svg'


const InvoicesList = (props) => {
    return (
        <div>
            <div className="d-flex" id="wrapper">
                <NavBarMenu></NavBarMenu>
                <div id="page-content-wrapper">
                    <header className="main-header">
                        <div className="top-right">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="menuTab-pageTitle">
                                            <div className="full-semi-menu">
                                                <img src={menubtn2} alt="" />
                                            </div>
                                            <h2>Profile</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="search-container">
                                            <form>
                                                <legend>
                                                    <input type="search" placeholder="Search here" />
                                                </legend>
                                                <button type="submit">
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="header-notification float-end">
                                            <ul>
                                                <li className="notification"><img src={bell} alt="" /><span>3</span></li>
                                                <li className="avatar"><a href=""><img src={avatar} alt="" />Ryan Dunn <i className="fa-solid fa-sort-down"></i></a>  </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Page content--> */}
                    <div className="content-page invoices-list-page">
                        <div className="container p-5">
                            <div className="row">
                                <div className="col-xl-6">
                                    <h3>Invoices List</h3>
                                    <h6>Find all of your invoices here.</h6>
                                </div>
                                <div className="col-xl-4">
                                    <div className="invoices-search">
                                        <form>
                                            <input type="search" placeholder="Search here" className="inv-search-field" />
                                            <button className="inv-search-tab"><i className="fa-solid fa-magnifying-glass"></i></button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xl-2">
                                    <button className="exp-tab">Export Statement</button>
                                </div>
                            </div>
                            <div className="resp-table">
                                <table>
                                    <tr>
                                        <th></th>
                                        <th>ID Invoice</th>
                                        <th>Due Date</th>
                                        <th>Transaction Type</th>
                                        <th>Contact</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" className="form-check-input" /></td>
                                        <td>#INV-0001234</td>
                                        <td>June 1, 2020, 08:22 AM</td>
                                        <td>Personal Plus</td>
                                        <td>Johndoe@gmail.com</td>
                                        <td>$ 650,036.34</td>
                                        <td><button type="button" className="btn btn-success completed"><i className="fa-solid fa-check"></i> Completed</button></td>
                                        <td><a href="#"><i className="fa-solid fa-ellipsis-vertical"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" className="form-check-input" /></td>
                                        <td>#INV-0001234</td>
                                        <td>June 1, 2020, 08:22 AM</td>
                                        <td>Personal Plus</td>
                                        <td>Johndoe@gmail.com</td>
                                        <td>$ 650,036.34</td>
                                        <td><button type="button" className="btn btn-success completed"><i className="fa-solid fa-check"></i> Completed</button></td>
                                        <td><a href="#"><i className="fa-solid fa-ellipsis-vertical"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" className="form-check-input" /></td>
                                        <td>#INV-0001234</td>
                                        <td>June 1, 2020, 08:22 AM</td>
                                        <td>Personal Plus</td>
                                        <td>Johndoe@gmail.com</td>
                                        <td>$ 650,036.34</td>
                                        <td><button type="button" className="btn btn-success pending"><i className="fa-solid fa-clock"></i> Pending</button></td>
                                        <td><a href="#"><i className="fa-solid fa-ellipsis-vertical"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" className="form-check-input" /></td>
                                        <td>#INV-0001234</td>
                                        <td>June 1, 2020, 08:22 AM</td>
                                        <td>Personal Plus</td>
                                        <td>Johndoe@gmail.com</td>
                                        <td>$ 650,036.34</td>
                                        <td><button type="button" className="btn btn-success completed"><i className="fa-solid fa-check"></i> Completed</button></td>
                                        <td><a href="#"><i className="fa-solid fa-ellipsis-vertical"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" className="form-check-input" /></td>
                                        <td>#INV-0001234</td>
                                        <td>June 1, 2020, 08:22 AM</td>
                                        <td>Personal Plus</td>
                                        <td>Johndoe@gmail.com</td>
                                        <td>$ 650,036.34</td>
                                        <td><button type="button" className="btn btn-succes pending"><i className="fa-solid fa-clock"></i> Pending</button></td>
                                        <td><a href="#"><i className="fa-solid fa-ellipsis-vertical"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" className="form-check-input" /></td>
                                        <td>#INV-0001234</td>
                                        <td>June 1, 2020, 08:22 AM</td>
                                        <td>Personal Plus</td>
                                        <td>Johndoe@gmail.com</td>
                                        <td>$ 650,036.34</td>
                                        <td><button type="button" className="btn btn-success completed"><i className="fa-solid fa-check"></i> Completed</button></td>
                                        <td><a href="#"><i className="fa-solid fa-ellipsis-vertical"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" className="form-check-input" /></td>
                                        <td>#INV-0001234</td>
                                        <td>June 1, 2020, 08:22 AM</td>
                                        <td>Personal Plus</td>
                                        <td>Johndoe@gmail.com</td>
                                        <td>$ 650,036.34</td>
                                        <td><button type="button" className="btn btn-success pending"><i className="fa-solid fa-clock"></i> Pending</button></td>
                                        <td><a href="#"><i className="fa-solid fa-ellipsis-vertical"></i></a></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="bottom-footer-invoices">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="list-pages">
                                            Showing <strong>1-10</strong> from <strong>46</strong> data
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="pager float-end">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <span className="first-pager">
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Previous">
                                                                <span aria-hidden="true">&laquo;</span>
                                                            </a>
                                                        </li>
                                                    </span>
                                                    <span className="middle-pager">
                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    </span>
                                                    <span className="last-pager">
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Next">
                                                                <span aria-hidden="true">&raquo;</span>
                                                            </a>
                                                        </li>
                                                    </span>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoicesList
