import { BsCurrencyDollar } from "react-icons/bs";
import { TbHomeSearch } from "react-icons/tb";
import { FaHome } from "react-icons/fa";
import { FaClockRotateLeft } from "react-icons/fa6";
import { HiOutlineDownload } from "react-icons/hi";
import Check from "./../assets/img/icon.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { Row, Col, Button, Modal, Image } from "react-bootstrap";
// import { FaFilter } from "react-icons/fa";
import { BiSearchAlt2 } from "react-icons/bi";
// import DatePicker from "react-datepicker";
import {
  GetDashboardSummary,
  GetDashboardPropertyChartData,
} from "../Utils/dashboard.util";
import {
  GetFormattedCurrency,
  GetFormattedNumber,
} from "../Utils/formating.util";
import DashbaordLayout from "./DashbaordLayout";
import { getCurrentPlan } from "../store/selectors/features/payment-plan";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { FlashSalesModal } from "./FlashSales/FlashSaleModal";
import { GetListRotationDetails } from "../Utils/billing.util";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const Dashboard = ({ title }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentPlan = useSelector(getCurrentPlan);

  document.title = "Dashboard - Skipify.ai";
  const pageTitle = "Dashboard";

  const [remainingDownloads, setRemainingDownloads] = useState(0);
  const [remainingHits, setRemainingHits] = useState(0);
  const [totalProperties, setTotalProperties] = useState(0);
  const [totalSkipTrace, setTotalSkipTrace] = useState(0);
  const [searchLast30Days, setSearchLast30Days] = useState(0);
  const [pricePerHit, setPricePerHit] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [listStorageLimit, setListStorageLimit] = useState();
  const [listStorageCustomerCount, setListStorageCustomerCount] = useState();

  // Property Chart Data
  const [chartDateList, setChartDateList] = useState([]);
  const [chartSkiptraceCountList, setChartSkiptraceCountList] = useState([]);
  const [chartPropertyCountList, setChartPropertyCountList] = useState([]);
  // Date Filter
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [listRotationDaysRemaining, setListRotationDaysRemaining] = useState(null);

  // To set the acsept ratio the chart when 380 1 and above axcept ratio is 2
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [showSkipPopup, setShowSkipPopup] = useState(true);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  let data = {
    labels: chartDateList,
    datasets: [
      {
        label: "Property Search",
        data: chartPropertyCountList,
        borderColor: "rgb(201, 146, 43)",
        backgroundColor: "rgba(201, 146, 43, 0.5)",
      },
      {
        label: "Skip Trace",
        data: chartSkiptraceCountList,
        borderColor: "rgb(1, 28, 64)",
        backgroundColor: "rgba(1, 28, 64, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: false,
    // aspectRatio: windowSize[0] === 380 ? 1 : 2,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Property Search vs Skip Trace",
      },
    },
    scale: {
      ticks: {
        precision: 0
      }
    }
  };

  useEffect(() => {
    GetDashboardData();
    GetPropertyChartData();
    getListRotationData();
  }, []);

  const GetDashboardData = () => {
    setShowLoader(true);
    GetDashboardSummary().then((response) => {
      if (response?.statusCode == 200 && response?.result) {
        if (response.result?.remainingDownloads) {
          setRemainingDownloads(response.result.remainingDownloads);
        }
        if (response.result?.remainingHits) {
          setRemainingHits(response.result.remainingHits);
        }
        if (response.result?.totalProperties) {
          setTotalProperties(response.result.totalProperties);
        }
        if (response.result?.totalSkiptrace) {
          setTotalSkipTrace(response.result.totalSkiptrace);
        }
        if (response.result?.searchLast30Days) {
          setSearchLast30Days(response.result.searchLast30Days);
        }
        if (response.result?.pricePerHit) {
          setPricePerHit(response.result.pricePerHit);
        }
      }
      setShowLoader(false);
    });
  };

  const GetPropertyChartData = () => {
    GetDashboardPropertyChartData(startDateFilter, endDateFilter).then(
      (response) => {
        if (response?.statusCode == 200 && response?.result) {
          setChartDateList(response.result?.datesList);
          setChartSkiptraceCountList(
            Object.values(response.result?.skiptraceCountList)
          );
          setChartPropertyCountList(
            Object.values(response.result?.propertyCountList)
          );
        }
      }
    );
  };

  // Get list rotation customer data
  const getListRotationData = async () => {
    try {
      const response = await GetListRotationDetails();
      if (response?.statusCode == 200 && response?.result?.daysRemaining) {
        setListRotationDaysRemaining(response.result?.daysRemaining)
      }  
      
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleDateChangeFilter = (dates) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
  };

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const handleShowWelcomeModal = () => {
    setShowWelcomeModal(true);
    localStorage.setItem("showWelcomeModal", 'done');
  };

  const handleCloseWelcomeModal = () => {
    setShowWelcomeModal(false);
  }

  useEffect(() => {
    const isActive = localStorage.getItem('showWelcomeModal');
    if (currentPlan?.currentPackageTitle === 'Free Onboarding Plan') {
      if (!isActive && isActive !== 'done') {
        handleShowWelcomeModal();
      }
    }
  }, [currentPlan]);

  const showNewUserModal = () => {
    return (
      <Modal
        show={showWelcomeModal}
        onHide={handleCloseWelcomeModal}
        backdrop="static"
        keyboard={false}
        centered
        className="glassModel"
      >
        <Modal.Body>
          <Row className="d-flex justify-content-end mb-2">
            <Col md={1} ><AiFillCloseCircle onClick={handleCloseWelcomeModal} cursor={"pointer"} /></Col>
          </Row>
          <div className="newsletter_message_modal">
            <Image src={Check} alt="check" width={100} />
            <h2>Login Successful</h2>
            <p>To upgrade your plan visit our billing page.</p>
            <Link to="/billing" className="btn btn-primary py-3 px-5 ">
              Upgrade Now
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const handleClose = () => setShowSkipPopup(false);


  // const showFlashSalesModal = () => {
  //   return (
  //     <FlashSalesModal show={showSkipPopup} handleClose={handleClose} />
  //   )
  // }


  return (
    <div className="d-flex" id="wrapper">
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}

      {/* <NavBarMenu page="dashboard" /> */}

      <DashbaordLayout title={pageTitle}>
        {showNewUserModal()}
        {/* <FlashSalesModal /> */}

        <div id="page-content-wrapper">
          <Row className="my-4 justify-content-between">
            <Col xs={12} md={6} xl={4}>
              <h2 className="main-title-mobile">{pageTitle}</h2>
            </Col>
            {/* <Col xs={12} md={12} xl={4}>
              <div className="filter_div">
                <DatePicker
                  selected={startDateFilter}
                  onChange={handleDateChangeFilter}
                  startDate={startDateFilter}
                  endDate={endDateFilter}
                  selectsRange
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control mb-2 mr-sm-2 "
                  placeholderText="Filter by Date"
                />
                <Button onClick={GetPropertyChartData}><FaFilter /></Button>
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} xl={4}>
              <div className="data-box">
                <h6>Property Download Credits</h6>
                <div className="sub-data-box">
                  <div className="data-div">
                    <h3>{GetFormattedNumber(remainingDownloads)}</h3>

                    {/* <p> <span>2.3% <i className="fa-solid fa-arrow-up-long"></i></span>  than last year</p> */}
                  </div>
                  <div className="icon-div">
                    <div className="data-icon">
                      <FaHome />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} xl={4}>
              <div className="data-box">
                <h6>Pre-Paid Skip Trace Hit Credits</h6>
                <div className="sub-data-box">
                  <div className="data-div">
                    <h3>{GetFormattedNumber(remainingHits)}</h3>
                    {/* <p> <span>2.3% <i className="fa-solid fa-arrow-up-long"></i></span>  than last year</p> */}
                  </div>
                  <div className="icon-div">
                    <div className="data-icon">
                      {/* <FaSearchLocation /> */}
                      <TbHomeSearch />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} xl={4}>
              <div className="data-box">
                <h6>Total Properties</h6>
                <div className="sub-data-box">
                  <div className="data-div">
                    <h3>{GetFormattedNumber(totalProperties)}</h3>
                    {/* <p> <span>2.3% <i className="fa-solid fa-arrow-up-long"></i></span>  than last year</p> */}
                  </div>
                  <div className="icon-div">
                    <div className="data-icon">
                      <FaHome />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} xl={4}>
              <div className="data-box">
                <h6>Total Skip Trace</h6>
                <div className="sub-data-box">
                  <div className="data-div">
                    <h3>{GetFormattedNumber(totalSkipTrace)}</h3>
                    {/* <p> <span>2.3% <i className="fa-solid fa-arrow-up-long"></i></span>  than last year</p> */}
                  </div>
                  <div className="icon-div">
                    <div className="data-icon">
                      <TbHomeSearch />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} xl={4}>
              <div className="data-box">
                <h6>Price Per Hit Rate</h6>
                <div className="sub-data-box">
                  <div className="data-div">
                    <h3>{`$ ${String(pricePerHit.toFixed(2))}`}</h3>
                    {/* <p> <span>2.3% <i className="fa-solid fa-arrow-up-long"></i></span>  than last year</p> */}
                  </div>
                  <div className="icon-div">
                    <div className="data-icon">
                      <BsCurrencyDollar />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} xl={4}>
              <div className="data-box">
                <h6>Searches Last 30 Days</h6>
                <div className="sub-data-box">
                  <div className="data-div">
                    <h3>{GetFormattedNumber(searchLast30Days)}</h3>
                    {/* <p> <span>2.3% <i className="fa-solid fa-arrow-up-long"></i></span>  than last year</p> */}
                  </div>
                  <div className="icon-div">
                    <div className="data-icon">
                      <BiSearchAlt2 />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {listRotationDaysRemaining !== null ? (
             <Row>
             <Col xs={12} sm={12} md={6} xl={4}>
                 <div className="data-box">
                   <h6>List Rotation Days Remaining</h6>
                   <div className="sub-data-box">
                     <div className="data-div">
                       <h3>{listRotationDaysRemaining}</h3>
                     </div>
                     <div className="icon-div">
                       <div className="data-icon">
                         <FaClockRotateLeft />
                       </div>
                     </div>
                   </div>
                 </div>
               </Col>
             </Row>
          ) : ''}
         

          <Row className="mt-1">
            <Col xs={12} md={12} xl={12} className="mx-auto">
              {/* chart Div */}

              <div className={`chart-container`}>
                {data?.labels?.length === 0 && (
                  <div className="overlay">
                    <div className="empty-graph-message">
                      <p>
                        Welcome to Skipify.ai! Your dashboard is ready and
                        waiting for your data.
                      </p>
                      <p>
                        Currently, there is no usage data available, but don't
                        worry! The graph will start populating when you start
                        skip tracing and downloading properties.
                      </p>
                    </div>
                  </div>
                )}

                <h3>Property Chart</h3>
                <Line options={options} data={data} />
              </div>
            </Col>
          </Row>
        </div>

      </DashbaordLayout>


    </div>
  );
};

export default Dashboard;
