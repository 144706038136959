export const ExemptOptionsConstant = [
{value: "9000",label: "Exempt (full or partial)", category: "Exempt"},
{value: "9001",label: "Native American Lands / American Indian Lands", category: "Exempt"},
{value: "9100",label: "Institutional (General)", category: "Exempt"},
{value: "9101",label: "Religious, Church, Worship", category: "Exempt"},
{value: "9102",label: "Parochial School, Private School", category: "Exempt"},
{value: "9103",label: "Educational - Private", category: "Exempt"},
{value: "9104",label: "Hospital - Private", category: "Exempt"},
{value: "9105",label: "Medical Clinic", category: "Exempt"},
{value: "9106",label: "Retired, Handicap, Convalescent, Nursing Home", category: "Exempt"},
{value: "9107",label: "Childrens Home, Orphanage", category: "Exempt"},
{value: "9108",label: "Cemetery", category: "Exempt"},
{value: "9109",label: "Crematorium, Mortuary", category: "Exempt"},
{value: "9110",label: "Charitable organization", category: "Exempt"},
{value: "9111",label: "Recreational Non-Taxable (Camps, Boy/Girl Scouts)", category: "Exempt"},
{value: "9112",label: "Private Utility (electric, water, gas, nuclear, solar, wind, etc.)", category: "Exempt"}
]