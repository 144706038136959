export const OfficeOptionsConstant = [
  { value: "3000", label: "Commercial Office (General)" , category: "Office"},
  {
    value: "3001",
    label: "Professional Bldg (Legal, insurance, real estate etc)",
  category: "Office"},
  { value: "3002", label: "Professional Bldg (Multi-story)" , category: "Office"},
  { value: "3003", label: "Office Bldg (General)" , category: "Office"},
  { value: "3004", label: "Office Bldg (Multi-Story)" , category: "Office"},
  { value: "3005", label: "Dental Bldg" , category: "Office"},
  { value: "3006", label: "Medical Bldg/Clinic" , category: "Office"},
  { value: "3007", label: "Financial Bldg" , category: "Office"},
  { value: "3008", label: "Condominium Offices" , category: "Office"},
  { value: "3009", label: "Skyscraper/Highrise (Commercial office)" , category: "Office"},
  { value: "3010", label: "Commercial/Industrial (mixed use)" , category: "Office"},
  { value: "3011", label: "Common Area" , category: "Office"},
  { value: "3012", label: "Mobile Commercial Units" , category: "Office"},
];
