import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckOutForm from './CheckOutForm';




const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const StripeContainer = ({ client }) => {
  const options = {
    clientSecret: client?.client_secret,
  };
  return (
    <Elements stripe={stripePromise} options={options} >
      <CheckOutForm />
    </Elements>
  )
}

export default StripeContainer