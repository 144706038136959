import { 
  ALL_NOTIFICATIONS_MARK_AS_READ_API_ENDPOINT, 
  NOTIFICATION_LIST_API_ENDPOINT, 
  NOTIFICATION_MARK_AS_READ_API_ENDPOINT, 
  NOTIFICATION_UNREAD_LIST_API_ENDPOINT 
} from "../Constants/api-routes.constant";
import { GetAuthToken } from "./auth-verification.util";


export const GetUnreadNotificationList = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(NOTIFICATION_UNREAD_LIST_API_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const GetAllNotificationList = async (newOffset) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(`${NOTIFICATION_LIST_API_ENDPOINT}?skip=${newOffset}`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const NotificationMarkAsRead = async (ids) => {
  const requestBody = {
    notificationIds: ids
  };

  const token = GetAuthToken();
  if (!token) return [];
  return fetch(NOTIFICATION_MARK_AS_READ_API_ENDPOINT, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  }).then((response) => {
    return response.json();
  })
    .catch((err) => {
      return err;
    });;
}

export const allNotificationsMarkAsRead = async () => {

  const token = GetAuthToken();
  if (!token) return;

  return fetch(ALL_NOTIFICATIONS_MARK_AS_READ_API_ENDPOINT, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response.json();
  })
    .catch((err) => {
      return err;
    });;
}