import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetSelectedPlan } from "../../store/slices/features/payment-plan";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSelectedPlan());
  });

  useEffect(() => {
    localStorage.removeItem("isRegPayment");
    localStorage.removeItem("NavigatePayment");
    localStorage.removeItem("skipifyAuthToken");
    localStorage.removeItem("skipifyTokenExpiry");
    localStorage.removeItem("skipifyUserFullName");
    localStorage.removeItem("skipifyUserEmail");
    localStorage.removeItem("showWelcomeModal");
    window.location = "/";
  });
};

export default Logout;
