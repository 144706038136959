export const SiteInfluenceConstant = [
  { value: "1", label: "Proximity - Freeway" },
  { value: "2", label: "WATERFRONT-BEACH (Ocean, River or Lake)" },
  { value: "3", label: "Contamination site" },
  { value: "4", label: "Cul-de-sac" },
  { value: "5", label: "CORNER" },
  { value: "6", label: "View - Negative" },
  { value: "7", label: "View - Average" },
  { value: "8", label: "Historical" },
  { value: "9", label: "Proximity - School" },
  { value: "10", label: "Golf Course Adjacent" },
  { value: "11", label: "View - None" },
  { value: "12", label: "View - Lake" },
  { value: "13", label: "View - Mountain" },
  { value: "14", label: "WATERFRONT - CANAL" },
  { value: "15", label: "View - Ocean" },
  { value: "16", label: "Proximity - Airport" },
  { value: "17", label: "GREEN BELT" },
  { value: "18", label: "Proximity - Railroad" },
  { value: "19", label: "Major street/thoroughfare" },
  { value: "20", label: "High traffic area" },
  { value: "21", label: "View - River" },
  { value: "22", label: "View not specified" },
  { value: "23", label: "Waterfront-Not Specified" },
  { value: "24", label: "FLOOD PLAIN/FLOOD ZONE" },
  { value: "25", label: "Alley" },
  { value: "26", label: "View - Bay" },
  { value: "27", label: "Canal" },
  { value: "28", label: "City" },
  { value: "29", label: "Creek" },
  { value: "30", label: "Industrial" },
  { value: "31", label: "Inferior" },
  { value: "32", label: "Poor Access" },
  { value: "33", label: "Suburban" },
  { value: "34", label: "Woodland" },
  { value: "35", label: "Water Front" },
  { value: "36", label: "Creek Frontage" },
  { value: "37", label: "Lake Frontage" },
  { value: "38", label: "Flag Lot" },
  { value: "39", label: "Small Lake or Pond" },
  { value: "40", label: "Flood Plain" },
  { value: "41", label: "Historical" },
  { value: "42", label: "Wooded Lot" },
  { value: "43", label: "Transition" },
  { value: "44", label: "Perimeter" },
  { value: "45", label: "Interior" },
  { value: "46", label: "Rural Property" },
  { value: "47", label: "Busy Street" },
  { value: "48", label: "Exclusive" },
  { value: "49", label: "Golf Course" },
  { value: "50", label: "Highway Frontage" },
  { value: "51", label: "BYPASS" },
  { value: "52", label: "TYPE UNKNOWN" },
  { value: "53", label: "ALLEY/CORNER" },
  { value: "54", label: "AGRICULTURAL" },
  { value: "55", label: "AIRPORT" },
  { value: "57", label: "AVERAGE" },
  { value: "58", label: "BAY ACCESS" },
  { value: "59", label: "BEHIND BAY FRONT" },
  { value: "60", label: "BEACH" },
  { value: "61", label: "BAY FRONT" },
  { value: "62", label: "BEHIND GULF FRONT" },
  { value: "63", label: "BAY FRONT ISLAND" },
  { value: "65", label: "BAY FRONT MAIN-LAND" },
  { value: "66", label: "BEACH ROAD" },
  { value: "67", label: "BUSINESS" },
  { value: "68", label: "BAYOU" },
  { value: "69", label: "BAY" },
  { value: "71", label: "CANAL/WATERFRONT" },
  { value: "73", label: "CANAL DRAINAGE" },
  { value: "74", label: "CORNER/EXTRA FRONT" },
  { value: "75", label: "CANAL FRONT" },
  { value: "76", label: "CANAL FRONT ISLAND" },
  { value: "77", label: "CREEK/RIVER" },
  { value: "78", label: "CORNER/LANDLOCK" },
  { value: "79", label: "CANAL MAIN-LAND" },
  { value: "80", label: "CORNER/WOODLOT/WATERFRONT" },
  { value: "81", label: "CORNER/WOODLOT" },
  { value: "82", label: "CORNER" },
  { value: "83", label: "CORNER/RESTRICTIONS" },
  { value: "84", label: "CORNER/WATERFRONT" },
  { value: "85", label: "CUL-DE-SAC" },
  { value: "86", label: "CORNER/LANDLOCK/WOODLOT" },
  { value: "88", label: "DEAD END" },
  { value: "89", label: "DRAINAGE EASEMENT" },
  { value: "90", label: "DOWNTOWN" },
  { value: "91", label: "DRAINAGE" },
  { value: "92", label: "DESIRABLE" },
  { value: "93", label: "DUPLEX LOTS" },
  { value: "94", label: "EXTRA FRONT" },
  { value: "95", label: "EXPENSIVE LAND" },
  { value: "96", label: "EXTRA FRONT/RESTRICTIONS" },
  { value: "97", label: "EASEMENT" },
  { value: "98", label: "FLOOD LINE" },
  { value: "99", label: "FOUR PLEX LOT" },
  { value: "100", label: "FLOOD PLAIN" },
  { value: "101", label: "GOLF COURSE" },
  { value: "102", label: "GREENBELT/GOLF COURSE" },
  { value: "103", label: "GOLF/LAKE" },
  { value: "104", label: "GOOD" },
  { value: "105", label: "GREENBELT" },
  { value: "106", label: "GULF" },
  { value: "107", label: "GOLF/WATER" },
  { value: "108", label: "HIGHWAY" },
  { value: "109", label: "INSIDE CITY" },
  { value: "110", label: "ISLAND GULF FRONT" },
  { value: "111", label: "INDUSTRIAL" },
  { value: "112", label: "IN-OUT CITY" },
  { value: "113", label: "ISLAND" },
  { value: "114", label: "INLAND WATERWAY" },
  { value: "115", label: "ISLAND NO WATERFRONT" },
  { value: "116", label: "LAGOON" },
  { value: "117", label: "LANDLOCKED/WOODLOT/WATERFRONT" },
  { value: "118", label: "LAKE FRONT" },
  { value: "119", label: "LAKE" },
  { value: "120", label: "LANDLOCKED" },
  { value: "121", label: "LAKE/POND" },
  { value: "122", label: "LANDLOCKED/WOODLOT" },
  { value: "123", label: "LANDLOCKED/WATERFRONT" },
  { value: "124", label: "MOBILE HOME" },
  { value: "125", label: "MAIN LAND" },
  { value: "126", label: "MOUNTAIN" },
  { value: "127", label: "NON BUILDABLE" },
  { value: "128", label: "INTRACOASTAL" },
  { value: "129", label: "NEIGHBORHOOD" },
  { value: "131", label: "INTERIOR" },
  { value: "132", label: "OCEAN" },
  { value: "133", label: "OPEN SPACE" },
  { value: "134", label: "OUTSIDE CITY" },
  { value: "135", label: "PARK LAND" },
  { value: "136", label: "POOR ACCESS" },
  { value: "137", label: "POND" },
  { value: "138", label: "PERIMETER LOT" },
  { value: "139", label: "PARK FRONT" },
  { value: "140", label: "POOL" },
  { value: "141", label: "PRESERVE" },
  { value: "143", label: "PRIVATE ROAD" },
  { value: "144", label: "RAVINE" },
  { value: "145", label: "RECREATIONAL" },
  { value: "146", label: "REAR" },
  { value: "147", label: "RIVER" },
  { value: "148", label: "IRREGULAR LOT" },
  { value: "149", label: "ROAD" },
  { value: "150", label: "RESTRICTIONS" },
  { value: "151", label: "RURAL" },
  { value: "152", label: "SOUND" },
  { value: "153", label: "SUBMERGED LAND" },
  { value: "154", label: "STREET" },
  { value: "156", label: "TOWNHOUSE" },
  { value: "157", label: "TRI PLEX LOT" },
  { value: "158", label: "TRAFFIC" },
  { value: "159", label: "URBAN" },
  { value: "160", label: "VALLEY" },
  { value: "161", label: "WATER ACCESS" },
  { value: "162", label: "WATER/CUL-DE-SAC" },
  { value: "163", label: "WETLAND" },
  { value: "164", label: "WATERFRONT" },
  { value: "166", label: "WATERFALLS" },
  { value: "167", label: "WATER" },
  { value: "168", label: "WOODLOT/WATERFRONT" },
  { value: "169", label: "APT/CONDO COMPLEX" },
  { value: "170", label: "BUSINESS CLUSTER" },
  { value: "171", label: "CENTRAL BUSINESS" },
  { value: "172", label: "COMMERCIAL/INDUSTRIAL" },
  { value: "173", label: "INDUSTRIAL SIZE" },
  { value: "174", label: "MAJOR STRIP" },
  { value: "175", label: "NEIGHBORHOOD / SPOT" },
  { value: "176", label: "PERM CENTRAL BUSINESS" },
  { value: "177", label: "SECONDARY BUS STRIP" },
  { value: "178", label: "ZERO LOT LINE" },
  { value: "179", label: "TYPE UNKNOWN" },
  { value: "180", label: "UNIT ABUTS ELEVATOR" },
  { value: "181", label: "AVERAGE" },
  { value: "182", label: "CONDOMINIMUM HI-RISE" },
  { value: "183", label: "CONDOMINIMUM LO-RISE" },
  { value: "184", label: "CONDO" },
  { value: "185", label: "CORNER UNIT" },
  { value: "186", label: "CONDOMINIMUM VILLAS" },
  { value: "187", label: "DUPLEX" },
  { value: "188", label: "END UNIT" },
  { value: "189", label: "EXCELLENT" },
  { value: "190", label: "FIRE DAMAGE" },
  { value: "191", label: "FRONT UNIT" },
  { value: "192", label: "GOOD" },
  { value: "193", label: "INFERIOR" },
  { value: "194", label: "INTERIOR UNIT" },
  { value: "195", label: "OUTSIDE CITY" },
  { value: "196", label: "PENTHOUSE" },
  { value: "197", label: "RECREATIONAL" },
  { value: "198", label: "RIVER" },
  { value: "199", label: "REAR UNIT" },
  { value: "200", label: "SPLIT PLAN/MASTER" },
  { value: "201", label: "SUPERIOR" },
  { value: "202", label: "TYPICAL" },
  { value: "203", label: "EXTERIOR UNIT" },
  { value: "204", label: "APARTMENT/CONDO" },
  { value: "205", label: "APARTMENT" },
  { value: "206", label: "BUILDABLE" },
  { value: "207", label: "CITY" },
  { value: "208", label: "COMMERCIAL" },
  { value: "209", label: "SECONDARY" },
  { value: "210", label: "FRONT" },
  { value: "211", label: "HOMESITE" },
  { value: "213", label: "INSTITUTIONAL" },
  { value: "214", label: "MOBILE HOME" },
  { value: "215", label: "NEIGHBORHOOD" },
  { value: "216", label: "PRIMARY" },
  { value: "217", label: "RESIDUAL" },
  { value: "218", label: "REHABILITATION" },
  { value: "219", label: "ROAD" },
  { value: "220", label: "RESTAURANT" },
  { value: "221", label: "REAR UNIT" },
  { value: "222", label: "WAREHOUSE" },
  { value: "223", label: "EXCESS" },
  { value: "224", label: "TYPE UNKNOWN" },
  { value: "225", label: "AIRPORT" },
  { value: "226", label: "AVERAGE" },
  { value: "227", label: "BAY" },
  { value: "228", label: "BEST" },
  { value: "229", label: "BLUFF" },
  { value: "230", label: "BETTER" },
  { value: "231", label: "CANAL" },
  { value: "232", label: "CITY" },
  { value: "233", label: "CREEK/LAKE" },
  { value: "234", label: "CANYON" },
  { value: "235", label: "MONSERVATION / PROTECTED AREA" },
  { value: "236", label: "CANYON/VALLEY" },
  { value: "237", label: "EXCELLENT" },
  { value: "238", label: "FAIR" },
  { value: "239", label: "GOLF COURSE" },
  { value: "240", label: "GOOD" },
  { value: "241", label: "GREENBELT/PARK" },
  { value: "242", label: "GULF" },
  { value: "243", label: "HILLS/MOUNTAINS" },
  { value: "244", label: "HILL/VALLEY" },
  { value: "245", label: "INFERIOR" },
  { value: "246", label: "INTERSTATE" },
  { value: "247", label: "INLAND WATERWAY" },
  { value: "248", label: "INTERCOASTAL WATERWAY" },
  { value: "249", label: "LAKE" },
  { value: "250", label: "LAGOON" },
  { value: "251", label: "LAKE/POND" },
  { value: "252", label: "MOUNTAIN" },
  { value: "253", label: "MOUNTAIN/OCEAN" },
  { value: "254", label: "OBSTRUCTED" },
  { value: "255", label: "OCEAN" },
  { value: "256", label: "PARK" },
  { value: "257", label: "POND" },
  { value: "258", label: "PARKING" },
  { value: "259", label: "PRIME" },
  { value: "260", label: "POOL" },
  { value: "261", label: "PREMIUM" },
  { value: "262", label: "POOR" },
  { value: "263", label: "RECREATIONAL" },
  { value: "264", label: "ROAD" },
  { value: "265", label: "RIVER" },
  { value: "266", label: "STANDARD" },
  { value: "267", label: "SUBURBAN" },
  { value: "268", label: "SUPERIOR" },
  { value: "269", label: "STREET" },
  { value: "270", label: "TYPICAL" },
  { value: "271", label: "WOODLAND" },
  { value: "272", label: "WATER" },
  { value: "273", label: "WATER VIEW" },
];
