import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Col, Row, Spinner, Form, Image } from "react-bootstrap";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import "../../assets/css/main.css";

import {
  VERIFY_RESET_CODE_API_ENDPOINT,
  NEW_PASSWORD_API_ENDPOINT,
} from "../../Constants/api-routes.constant";
import { NEW_PASSWORD_REGEX } from "../../Constants/regex.constant";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import leftBannerImage from "../../assets/img/signup.png";
const ResetPassword = () => {

  document.title = "Reset Password - Skipify.ai";

  const { id } = useParams();
  const [codeError, setCodeError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [hasCodeVerified, setHasCodeVerified] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password1Error, setPassword1Error] = useState("");
  const [password2Error, setPassword2Error] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showPass, setShowPass] = useState(false)
  const [showConPass, setShowConPass] = useState(false)



  // Password Validation
  const [lower, setLower] = useState(false);
  const [upper, setUpper] = useState(false);
  const [number, setNumber] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [char, setChar] = useState(false);
  // hanle validation
  const handleValidatoin = (value) => {
    // regex
    const lower = new RegExp('(?=.*[a-z])');
    const upper = new RegExp('(?=.*[A-Z])');
    const number = new RegExp('(?=.*[0-9])');
    const symbol = new RegExp('(?=.*[#$^+=!*()@%&])');
    const length = new RegExp('(?=.{8,})');

    lower.test(value) ? setLower(true) : setLower(false)
    upper.test(value) ? setUpper(true) : setUpper(false)
    number.test(value) ? setNumber(true) : setNumber(false)
    symbol.test(value) ? setSymbol(true) : setSymbol(false)
    length.test(value) ? setChar(true) : setChar(false)
  }

  /**
   * Verify reset code
   * @returns
   */
  const verifyUrlCode = () => {
    fetch(VERIFY_RESET_CODE_API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ resetCode: id }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.statusCode == 200 && response?.result == true) {
          setHasCodeVerified(true);
          setCodeError('');
        } else {
          const errMessage = response?.message
            ? response.message
            : "Sorry! Unable to verify the link at this moment. Please try again.";
          setCodeError(errMessage);
          setHasCodeVerified(false);
        }
      })
      .catch((err) => {
        setCodeError(err);
      });
  };

  useEffect(() => {
    verifyUrlCode();
  }, [])

  /**
   * Set new password
   * @returns
   */
  const handleNewPasswordSubmit = () => {
    setShowLoader(true)
    setPassword1Error("");
    setPassword2Error("");

    if (!password1) {
      setPassword1Error("Please enter the new password.");
      setShowLoader(false)
      return;
    }

    if (!password1.match(NEW_PASSWORD_REGEX)) {
      setPassword1Error("Password must contain uppercase letters, special characters, numeric digits with at least 8 characters");
      setShowLoader(false)
      return;
    }

    if (!password2) {
      setPassword2Error("Please re-enter the new password.");
      setShowLoader(false)
      return;
    }



    if (password1 != password2) {
      setPassword2Error(
        "Confirm password did not match."
      );
      setShowLoader(false)
      return;
    }

    fetch(NEW_PASSWORD_API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password1,
        resetCode: id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.statusCode == 200) {
          setShowLoader(false)
          setSuccessMessage("Your password has been updated successfully.")
          setTimeout(() => {
            window.location = '/';
            return;
          }, 2000)
        } else {
          const errMessage = response?.message ? response.message : "Sorry! Unable to set new password. Please try again.";
          setShowLoader(false)
          setPassword2Error(errMessage)
        }
      })
      .catch((err) => {
        setShowLoader(false)
        setPassword2Error(err)
      });
  };

  return (
    <>
      {/* <TopNav /> */}
      <div className="register_section">
        <div className="register_section_overlay">
          <Row>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={12} lg={{ span: 7, order: 1 }} className="p-0 text-center">
              <div className="signup_image_div">
                <Link to="/">
                  <Image src={leftBannerImage} alt="" className="bg_image" />
                </Link>
                <div className="text-div">
                  <h4>Lead Generation.</h4>
                  <h4>Made Easy.</h4>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={12} lg={{ span: 5, order: 2 }} className="mb-5">
              <div className="main-form2">

                <Row>
                  <Col md={10} className="mx-auto text-center">
                    <Link to="/">
                      <Image src={require('./../../assets/img/logo.png')} alt="logo" className="register_logo" />
                    </Link>
                    {!hasCodeVerified && !codeError ?
                      <>
                        <div className="alert alert-success mt-2">
                          <h6 style={{ color: "#0f5132 !important", fontSize: "0.9rem" }}>We are verifying this link. Please wait...</h6>
                        </div>
                      </>
                      : ''
                    }
                    {codeError &&
                      <div className="alert alert-danger mt-2">
                        <h6 style={{ color: "#0f5132 !important", fontSize: "0.9rem" }}>{codeError}</h6>
                      </div>
                    }
                  </Col>
                </Row>


                {hasCodeVerified && (
                  <Row>
                    <Col md={10} className="mx-auto">
                      <Row>
                        <Col md={10} className="mx-auto text-center">
                          <h2 className="register_title">Please Enter New Password</h2>
                          <p className="register_des">Please enter your password  to update your password.</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mt-2 mb-3">
                          <Form.Group className="mb-3 password">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              onChange={(e) => {
                                setPassword1(e.target.value);
                                setPassword1Error('');
                                handleValidatoin(e.target.value)
                              }}
                              type={showPass ? 'text' : 'password'}
                              name="password1"
                              placeholder="*****"
                            />
                            {showPass ? <AiFillEye className="show_password" onClick={() => setShowPass(!showPass)} /> : <AiFillEyeInvisible className="show_password" onClick={() => setShowPass(!showPass)} />}
                          </Form.Group>
                          {password1Error ?
                            <p className="text-danger"><BsFillExclamationCircleFill /> {password1Error}</p>
                            : ""
                          }
                        </Col>
                        <Col md={12}>
                          <Form.Group className="mb-3 password">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                              onChange={(e) => setPassword2(e.target.value)}
                              type={showConPass ? 'text' : 'password'}
                              name="password2"
                              placeholder="*****"
                            />
                            {showConPass ? <AiFillEye className="show_password" onClick={() => setShowConPass(!showConPass)} /> : <AiFillEyeInvisible className="show_password" onClick={() => setShowConPass(!showConPass)} />}
                          </Form.Group>
                          {password2Error ?
                            <p className="text-danger"><BsFillExclamationCircleFill /> {password2Error}</p>
                            : ""
                          }
                        </Col>
                        <Row className="mb-4">
                          <Col md={12}>
                            <h4 className="validation_heading">Your password must include:</h4>
                            <ul className="validation_list">
                              <li className={upper && 'active'}><h5>A</h5><span>Uppercase</span></li>
                              <li className={lower && 'active'}><h5>a</h5><span>Lowercase</span></li>
                              <li className={symbol && 'active'}><h5>%</h5><span>Symbol</span></li>
                              <li className={number && 'active'}><h5>123</h5><span>Number</span></li>
                              <li className={char && 'active'}><h5>8+</h5><span>Characters</span></li>
                            </ul>
                          </Col>
                        </Row>
                        <Col md={12}>
                          <div className="tab-container">
                            {showLoader ? <button type="button" className="submit"> <Spinner animation="border" size="sm" /></button> : <button type="submit" className="submit" onClick={handleNewPasswordSubmit}>Submit</button>}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <FooterFront /> */}
    </>
  );
};

export default ResetPassword;
