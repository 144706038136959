import { GetAuthToken } from "./auth-verification.util";
import {
  CANCEL_ACCOUNT_ENDPOINT,
  GET_CUSTOMER_LIST_COUNT_ENDPOINT,
  GET_LIST_STORAGE_LIMIT_ENDPOINT,
  BILLING_GET_CURRENT_PLAN_DETAILS_ENDPOINT,
  NEW_EMAIL_VERIFICATION_API_ENDPOINT
} from "../Constants/api-routes.constant";

export const CancelAccount = (cancelReason) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(CANCEL_ACCOUNT_ENDPOINT, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      reason: cancelReason,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const GetCustomerListStorageLimit = () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(GET_LIST_STORAGE_LIMIT_ENDPOINT, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.statusCode == 200 && response.result?.listStorageLimit) {
        return Number(response.result.listStorageLimit);
      } else {
        return 0;
      }
    })
    .catch((err) => {
      return err;
    });
};

export const GetCustomerListStorageCount = () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(GET_CUSTOMER_LIST_COUNT_ENDPOINT, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.statusCode == 200 && response.result?.count) {
        return Number(response.result.count);
      }
    })
    .catch((err) => {
      return err;
    });
};


export const GetCustomerCurrentPlanDetails = async () => {
  const token = GetAuthToken();
  return fetch(BILLING_GET_CURRENT_PLAN_DETAILS_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, 
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
}

export const NewEmailVerificationApi = async (encryptedText) => {

  return fetch(NEW_EMAIL_VERIFICATION_API_ENDPOINT, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      encryptedText,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};