import { createSelector } from 'reselect';
import { US_LOCATION_COORDINATES } from '../../../Constants/us-coordinates.constant';

/**
 *
 * @param state
 * Implementation of memoized selectors using reselect to get particular data out of store.
 */

const appFeatureSelector = (state) => state.features.app;

export const getAppLanguage = createSelector(
  appFeatureSelector,
  (app) => app.language
);

export const getBaseUrl = createSelector(
  appFeatureSelector,
  (app) => app.baseUrl
);

export const activeAddSkip = createSelector(
  appFeatureSelector,
  (app) => app.addSkips
);

export const getLocationData = createSelector(
  appFeatureSelector,
  (app) => {
    const {
      loaded,
      lat = '',
      lng = '',
    } = app.location;

    if (loaded === true && lat && lng) {
      return { lat, lng, zoom: 10 };
    }

    return { lat: US_LOCATION_COORDINATES.LATITUDE, lng: US_LOCATION_COORDINATES.LONGITUDE, zoom: 10 };
  }
);