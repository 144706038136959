import { FaTimes } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BillingCardElement from "./billing-card-element";
// import { STRIPE_PK } from "../../Constants/api-credentials.constant";
import {
  enableBillingById,
  getActiveBilling,
  getNewBillingDetails,
  updatePaymentIntentDetails
} from "../../Utils/billing.util";
import { updateCurrentBilling } from "../../store/slices/features/payment-plan";
import { BILLING_CRON_JOB, BILLING_GET_CURRENT_PLAN_DETAILS_ENDPOINT } from "../../Constants/api-routes.constant";
import { GetAuthToken } from "../../Utils/auth-verification.util";
import moment from "moment";


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

export const AddBillingModal = ({ showModal, closeModalHandler, shouldActive = true }) => {

  const dispatch = useDispatch();
  const closeModel = () => {
    setBillingIssueAlert(false)
    closeModalHandler()
  }

  const [skipifyClientID, setSkipifyClientID] = useState("");
  const [stripeCustomerID, setStripeCustomerID] = useState("");
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [stripeSetupIntent, setStripeSetupIntent] = useState("");
  const [stripeOptions, setStripeOptions] = useState("");
  const [needToMakePayment, setNeedToMakePayment] = useState(false)
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [billingIssueAlert, setBillingIssueAlert] = useState(false)

  const getClientLastBillDetails = async () => {
    const token = GetAuthToken();
    return fetch(BILLING_GET_CURRENT_PLAN_DETAILS_ENDPOINT, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.result) {
          if(moment(response.result.clientBillings.currentPackageNextChargingDate) < moment() && moment(response.result.clientBillings.currentPackageExpiryDate) < moment()){
            setNeedToMakePayment(true)
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        return err;
      });
  }
  const getNewBillingData = async () => {
    setShowLoader(true);
    try {

      if (!showModal) return; // generate new billing if someone load the billing
      let response = await getNewBillingDetails();

      if (response?.result) {
        setStripeCustomerID(response.result?.stripeCustomerId);
        setStripeClientSecret(response?.result?.stripeClientSecret);
        setStripeSetupIntent(response.result?.stripeSetupIntentId);
        setSkipifyClientID(response.result?.clientId);

        let options = {
          // passing the client secret obtained from the server
          clientSecret: response?.result?.stripeClientSecret,
        };

        setStripeOptions(options);
      }
      setTimeout(() => {
        setShowLoader(false);
      }, 4000);
    } catch (error) {

      setTimeout(() => {
        setShowLoader(false);
      }, 4000);

      setErrorMsg(error?.message || '');
    }
  }

  useEffect(() => {
    getClientLastBillDetails()
    getNewBillingData();
    setErrorMsg('');
    setSuccessMsg('')
    if(Boolean(searchParams.get('last-payment-declined')) === true){
      setBillingIssueAlert(true)
      searchParams.delete('last-payment-declined')
      setSearchParams(searchParams)
    }
  }, [showModal]);

  const getActiveBillingData = async () => {

    try {
      const response = await getActiveBilling();

      if (response?.statusCode == 200 && response?.result) {
        dispatch(updateCurrentBilling(response.result));
      }
      setShowLoader(false);
      closeModel();

    } catch (error) {
      setErrorMsg(error?.message || '');
      setShowLoader(false);
    }
  }

  const enableBilling = async (billingId) => {

    try {
      const response = await enableBillingById(billingId);

      if (response?.statusCode == 200 && response?.result) {
        setTimeout(() => {
          getActiveBillingData();
        }, 2000);
      } else {
        setErrorMsg(response?.message);
        setShowLoader(false);
      }
    } catch (error) {
      setErrorMsg(error?.message || '');
      setShowLoader(false);
    }
  }
  const runCronJob = () => {
    return fetch(BILLING_CRON_JOB, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        return err;
      });
  }
  const confirmSetupIntentResult = async (result) => {
    if (result.error) {
      setErrorMsg(result.error.message);
      return;
    }

    try {
      if (result?.setupIntent?.status == "succeeded") {
        setShowLoader(true);

        const requestPayload = {
          stripeSetupIntentId: result.setupIntent.id,
          stripePaymentMethodId: result.setupIntent.payment_method,
          stripeCustomerId: stripeCustomerID,
          stripeClientSecret: stripeClientSecret,
        };

        const response = await updatePaymentIntentDetails(requestPayload);

        if (response.statusCode == 200) {
          setSuccessMsg("Your credit card has been added successfully.");
          if(needToMakePayment){
            runCronJob()
            setNeedToMakePayment(false)
          }
          closeModel();
          setTimeout(() => {
            //window.location.href = "/billing?card=yes";
            //navigate('/billing?card=yes', {  state: { chargeCard: true }  });
            window.dispatchEvent(new Event('goto-billing'));
          }, 2000);
          if (shouldActive) {
            enableBilling(response.result._id);
          } else {
            setShowLoader(false);
            closeModel();
          }
        } else {
          setErrorMsg(response?.message);
          setShowLoader(false);
        }
      }
      else {
        setErrorMsg("Sorry! We cannot add the card at the moment. Please try again.");
        setShowLoader(false);
      }
    } catch (error) {
      setErrorMsg(error?.message || '');
      setShowLoader(false);
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModel}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
      className="glassModel"
    >
      <Modal.Body>

        <div>
          {showLoader === true ? (
            <div className="loaderBlocker">
              <div className="fadeOut"></div>
              <div className="loader"></div>
            </div>
          ) : (
            ""
          )}

          <div className="d-flex" id="wrapper">
            <div id="page-content-wrapper p-0" style={{"width":"100%"}}>
              {/* <!-- Page content--> */}
              <Row className="d-flex justify-content-end">
                <Col xs={1} sm={1} md={1} lg={1}><FaTimes onClick={closeModel} /></Col>
              </Row>
              <div className="container">
                {errorMsg ? (
                  <div className="alert alert-danger">{errorMsg}</div>
                ) : (
                  ""
                )}
                {successMsg ? (
                  <div className="alert alert-success">{successMsg}</div>
                ) : (
                  ""
                )}
                {billingIssueAlert && <>
                <h3 className="text-center text-primary-new bold">Billing Issue Alert</h3>
                <p className="text-center text-primary-new">Your recent subscription renewal failed due to a declined payment method. Please update your billing info to renew your subscription.</p>
                <h5 className="text-center text-primary-new bold">Need help?   <a
                                href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/contact-us`}
                                target="_blank"
                                className=" text-decoration-underline h5 text-primary-new bold"
                              >
                                Contact Us.
                              </a></h5>
                </>}
                <div className="payment-container">
                  <div className="row">
                    <div className="col-xl-12">
                      <h3 className="mt-2">Add New Billing</h3>
                      {stripeOptions ? (
                        <Elements
                          stripe={stripePromise}
                          options={stripeOptions}
                        >
                          <BillingCardElement
                            confirmResult={confirmSetupIntentResult}
                          />
                        </Elements>
                      ) : (
                        "Loading"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
