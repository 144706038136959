import React from "react";
import { GetFormattedCurrencyWithSuffixK, GetFormattedDate, getFormattedCurrencyInInteger } from "../Utils/formating.util";
const MarkerContent = ({ propertyItem }) => {
    return (
        <div className="mapPopContent">
            <p>
                {propertyItem?.SitusFullStreetAddress}, {propertyItem?.SitusCity},{" "}
                {propertyItem?.SitusState} {propertyItem?.SitusZIP5}
            </p>
            <ul>
                <li>
                    <strong>Property Type : </strong>
                    {propertyItem?.PropertyTypeDetail}
                </li>
                <li>
                    <strong>Owner Occupied : </strong>{" "}
                    {propertyItem?.OwnerOccupied == "Y" ? "Yes" : "No"}
                </li>
                <li>
                    <strong>Assessed Total Value: </strong>{" "}
                    ${getFormattedCurrencyInInteger(propertyItem?.AssdTotalValue)}
                </li>
                <li>
                    <strong>Last Sale Date : </strong>
                    {GetFormattedDate(propertyItem?.CurrentSaleRecordingDate)}
                </li>
                <li>
                    <strong>Last Sale Price: </strong>
                    ${getFormattedCurrencyInInteger(propertyItem?.CurrentSalesPrice)}
                </li>
            </ul>
            <ul className="detailList">
                <li>
                    <p>{propertyItem?.Bedrooms}</p>
                    <h6>Beds</h6>
                </li>
                <li>
                    <p>{propertyItem?.BathFull}</p>
                    <h6>Baths</h6>
                </li>
                {propertyItem.SumBuildingSqFt && (
                    <li>
                        <p>{propertyItem?.SumBuildingSqFt} </p>
                        <h6>Sq. Ft.</h6>
                    </li>
                )}

                <li>
                    <p>
                        ${getFormattedCurrencyInInteger(propertyItem?.MarketTotalValue)}
                    </p>
                    <h6>Est. Value</h6>
                </li>
                <li>
                    <p>{propertyItem?.EffectiveYearBuilt}</p>
                    <h6>Yr. Build</h6>
                </li>
            </ul>
        </div>
    );
};

export default MarkerContent;
