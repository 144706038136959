export const PropertyClassificationConstant = [
    // {
    //   name: "All",
    //   id: "",
    // },
    {
      name: "Residential",
      id: "residential",
    },
    {
      name: "Commercial",
      id: "commercial",
    },
    {
      name: "Office",
      id: "office"
    },
    {
      name: "Recreational",
      id: "recreational"
    },
    {
      name: "Industrial",
      id: "industrial"
    },
    {
      name: "Agricultural",
      id: "agricultural",
    },
    {
      name: "Vacant Land",
      id: "vacant",
    },
    {
      name: "Exempt",
      id: "exempt",
    },
    {
      name: "Miscellaneous",
      id: "miscellaneous",
    },
  ]
