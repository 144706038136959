import { PROPERTY_SEARCH_ENDPOINT } from "../Constants/api-routes.constant";
import { LikelyToDefaultConstant, UnLikelyToDefaultConstant, VeryLikelyToDefaultConstant } from "../Constants/likely_to_default.constants";
import { GetAuthToken } from "./auth-verification.util";
import * as moment from "moment";

export const SearchPropertyApi = async (filters, skip = 0, take = 500) => {
  const searchCriteria = prepareSearchCriteria(filters);
  const authToken = GetAuthToken();
  if (!authToken) return [];
  try {
    const properties = await fetch(PROPERTY_SEARCH_ENDPOINT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`, // notice the Bearer before your token
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchCriteria, options: { skip, take } }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        return response;
      })
      .catch((err) => console.log("property search error"));
    return properties;
  } catch (error) {
    console.log(error.message);
  }
};

// Utilities
/**
 * Create search criteria
 * @param {*} filters
 * @returns
 */
function prepareSearchCriteria(filters) {
  const searchCriteria = {};
  if (filters.zipCode) {
    const zipCodeList = filters.zipCode.split(",");
    searchCriteria.zipCode = addSearchList(zipCodeList);
  }
  if (filters.state) {
    searchCriteria.state = addSearchString(filters.state?.trim());
  }
  if (filters.city) {
    searchCriteria.city = addSearchString(filters.city?.trim());
  }
  if (filters.county) {
    searchCriteria.county = addSearchString(filters.county?.trim());
  }

  if (filters?.counties?.length) {
    searchCriteria.county = addSearchList(filters.counties);
  }

  if (filters.street) {
    searchCriteria.street = addSearchString(filters.street?.trim());
  }

  if (filters?.coordinates?.length) {
    searchCriteria.latLong = addSearchLatLongList(filters.coordinates[0]);
  }

  // Property Charateristics
  if (filters.propertyCharacteristics?.classification?.length) {
    searchCriteria.propertyType = addSearchList(
      filters.propertyCharacteristics.classification
    );
  }

  if (filters.propertyCharacteristics?.propertyTypes) {
    const propertyTypesCode = filters.propertyCharacteristics.propertyTypes.map(
      (ptc) => ptc.value
    );
    if (propertyTypesCode?.length)
      searchCriteria.propertyTypeDetailCode = addSearchList(propertyTypesCode);
  }
  if (
    filters.propertyCharacteristics?.bedroomMin ||
    filters.propertyCharacteristics?.bedroomMax
  ) {
    searchCriteria.bedrooms = addSearchNumber(
      filters.propertyCharacteristics?.bedroomMin,
      filters.propertyCharacteristics?.bedroomMax
    );
  }
  if (
    filters.propertyCharacteristics?.bathroomMin ||
    filters.propertyCharacteristics?.bathroomMax
  ) {
    searchCriteria.bathrooms = addSearchNumber(
      filters.propertyCharacteristics?.bathroomMin,
      filters.propertyCharacteristics?.bathroomMax
    );
  }
  if (
    filters.propertyCharacteristics?.buildingAreaMin ||
    filters.propertyCharacteristics?.buildingAreaMax
  ) {
    searchCriteria.squareFootage = addSearchNumber(
      filters.propertyCharacteristics?.buildingAreaMin,
      filters.propertyCharacteristics?.buildingAreaMax
    );
  }
  if (
    filters.propertyCharacteristics?.yearBuildMin ||
    filters.propertyCharacteristics?.yearBuildMax
  ) {
    searchCriteria.yearBuilt = addSearchNumber(
      filters.propertyCharacteristics?.yearBuildMin,
      filters.propertyCharacteristics?.yearBuildMax
    );
  }
  if (
    filters.propertyCharacteristics?.effectiveYearBuiltMin ||
    filters.propertyCharacteristics?.effectiveYearBuiltMax
  ) {
    searchCriteria.effectiveYearBuilt = addSearchNumber(
      filters.propertyCharacteristics?.effectiveYearBuiltMin,
      filters.propertyCharacteristics?.effectiveYearBuiltMax
    );
  }
  if (
    filters.propertyCharacteristics?.residentialUnitsMin ||
    filters.propertyCharacteristics?.residentialUnitsMax
  ) {
    searchCriteria.residentialUnits = addSearchNumber(
      filters.propertyCharacteristics?.residentialUnitsMin,
      filters.propertyCharacteristics?.residentialUnitsMax
    );
  }
  if (
    filters.propertyCharacteristics?.commercialUnitsMin ||
    filters.propertyCharacteristics?.commercialUnitsMax
  ) {
    searchCriteria.commercialUnits = addSearchNumber(
      filters.propertyCharacteristics?.commercialUnitsMin,
      filters.propertyCharacteristics?.commercialUnitsMax
    );
  }
  if (
    filters.propertyCharacteristics?.lotSizeMin ||
    filters.propertyCharacteristics?.lotSizeMax
  ) {
    searchCriteria.lotSize = addSearchNumber(
      filters.propertyCharacteristics?.lotSizeMin,
      filters.propertyCharacteristics?.lotSizeMax
    );
  }
  if (
    filters.propertyCharacteristics?.lotSizeAcresMin ||
    filters.propertyCharacteristics?.lotSizeAcresMax
  ) {
    searchCriteria.lotSizeAcres = addSearchNumber(
      filters.propertyCharacteristics?.lotSizeAcresMin,
      filters.propertyCharacteristics?.lotSizeAcresMax
    );
  }
  if (filters.propertyCharacteristics?.vacantFlag) {
    const vacantFlag =
      filters.propertyCharacteristics.vacantFlag == "y" ? true : false;
    searchCriteria.vacantFlag = { equals: vacantFlag };
    searchCriteria.ownerOccupied = { equals: !vacantFlag };
  }

  if (filters.propertyCharacteristics?.siteInfluence?.length) {
    searchCriteria.siteInfluence = addSearchList(
      filters.propertyCharacteristics.siteInfluence
    );
  }

  //   if(filters.propertyCharacteristics.storyMin || filters.propertyCharacteristics.storyMax) {
  //     searchCriteria.lotSize = addSearchNumber(filters.propertyCharacteristics.storyMin, filters.propertyCharacteristics.storyMax);
  //   }

  // MLS
  if (filters.mls?.listingStatus) {
    searchCriteria.listingStatus = addSearchString(filters.mls.listingStatus);
  }
  if (filters.mls?.listingDateMin || filters.mls?.listingDateMax) {
    searchCriteria.originalListDate = addSearchDate(
      filters.mls?.listingDateMin,
      filters.mls?.listingDateMax
    );
  }

  // Preforeclosure
  if (filters.preforeclosure?.preforeclosureStatus) {
    // if(filters.preforeclosure?.preforeclosureStatus.includes("activeAuction")) {
    //   searchCriteria.activeAuction = { equals: true};
    // }
     
    const statues = [];
    for(const status of filters.preforeclosure.preforeclosureStatus) {
      if(status != 'activeAuction' && status != "select_all") statues.push(status);
    }
    if(statues.length) searchCriteria.foreclosureStatus = addSearchList(statues);
  }
  if (filters.preforeclosure?.preforeclosureRecordingDateMin || filters.preforeclosure?.preforeclosureRecordingDateMax) {
    searchCriteria.foreclosureRecordingDate = addSearchDate(
      filters.preforeclosure?.preforeclosureRecordingDateMin,
      filters.preforeclosure?.preforeclosureRecordingDateMax
    );
  }
  if (filters.preforeclosure?.preforeclosureAuctionDateMin || filters.preforeclosure?.preforeclosureAuctionDateMax) {
    searchCriteria.auctionDate = addSearchDate(
      filters.preforeclosure?.preforeclosureAuctionDateMin,
      filters.preforeclosure?.preforeclosureAuctionDateMax
    );
  }
   
    
    if (filters.preforeclosure?.preforeclosureUnLikeylyToDefault) {
      searchCriteria.defaultScore = { min: 1, max: 100 };
    }
    if (filters.preforeclosure?.preforeclosureLikeylyToDefault) {
      searchCriteria.defaultScore = { min: 101, max: 300 };
    }
    if (filters.preforeclosure?.preforeclosureVeryLikeylyToDefault) {
      searchCriteria.defaultScore = { min: 301 };
    }
    if (
      filters.preforeclosure?.preforeclosureLikeylyToDefault &&
      filters.preforeclosure?.preforeclosureVeryLikeylyToDefault
    ) {
      searchCriteria.defaultScore = { min: 101 };
    }
    if (
      filters.preforeclosure?.preforeclosureUnLikeylyToDefault &&
      (filters.preforeclosure?.preforeclosureLikeylyToDefault ||
      filters.preforeclosure?.preforeclosureVeryLikeylyToDefault)
     
    ) {
      searchCriteria.defaultScore = { min: 1 };
    }
  
  if(filters.preforeclosure.preforeclosureExclude) {
    searchCriteria.excludeForeclosures = { equals: true};
  }
  if(filters.preforeclosure?.preforeclosureActiveAuction) {
    searchCriteria.activeAuction = { equals: true};
  }

  // Owner
  if (filters.owner?.ownerType?.length) {
    if (filters.owner.ownerType.includes("company")) {
      searchCriteria.coorporateOwned = { equals: true };
    }
    if (filters.owner.ownerType.includes("individual")) {
      searchCriteria.coorporateOwned = { equals: false };
    }
    if (
      filters.owner.ownerType.includes("company") &&
      filters.owner.ownerType.includes("individual")
    ) {
      delete searchCriteria.coorporateOwned;
    }
  }
  if (
    filters.owner?.yearsOfOwnershipMin ||
    filters.owner?.yearsOfOwnershipMax
  ) {
    searchCriteria.yearsOwned = addSearchNumber(
      filters.owner?.yearsOfOwnershipMin,
      filters.owner?.yearsOfOwnershipMax
    );
  }
  if (filters.owner?.lastSaleDateMin || filters.owner?.lastSaleDateMax) {
    searchCriteria.saleDate = addSearchDate(
      filters.owner?.lastSaleDateMin,
      filters.owner?.lastSaleDateMax
    );
  }
  if (filters.owner?.lastSalePriceMin || filters.owner?.lastSalePriceMax) {
    searchCriteria.salePrice = addSearchNumber(
      filters.owner?.lastSalePriceMin,
      filters.owner?.lastSalePriceMax
    );
  }
  if (filters.owner?.ownerOccupied) {
    const ownerOccupied = filters.owner.ownerOccupied == "y" ? true : false;
    searchCriteria.ownerOccupied = { equals: ownerOccupied };
  }
  if (filters.owner?.coorporateOwned) {
    const coorporateOwned = filters.owner.coorporateOwned == "y" ? true : false;
    searchCriteria.coorporateOwned = { equals: coorporateOwned };
  }
  if (filters.owner?.isTaxDelinquent) {
    const isTaxDeliquent = filters.owner.isTaxDelinquent == "y" ? true : false;
    searchCriteria.isTaxDeliquent = { equals: isTaxDeliquent };
  }
  if (
    filters.owner?.taxDelinquentYearMin ||
    filters.owner?.taxDelinquentYearMax
  ) {
    searchCriteria.taxDeliquentYear = addSearchNumber(
      filters.owner?.taxDelinquentYearMin,
      filters.owner?.taxDelinquentYearMax
    );
  }
  if (filters.owner?.mailingState) {
    const notEquals = true;
    searchCriteria.mailingState = addSearchString(
      filters.owner.mailingState,
      notEquals
    );
  }

  // Valuation
  if (filters.valuation) {
    if (
      filters.valuation?.estimatedValueMin ||
      filters.valuation?.estimatedValueMax
    ) {
      // As estimatedValue is not available in the DB therefore we are using marketTotalValue for filter
      searchCriteria.marketTotalValue = addSearchNumber(
        filters.valuation?.estimatedValueMin,
        filters.valuation?.estimatedValueMax
      );
    }
    if (
      filters.valuation?.equityPercentageMin ||
      filters.valuation?.equityPercentageMax
    ) {
      searchCriteria.equityPercentage = addSearchNumber(
        filters.valuation?.equityPercentageMin,
        filters.valuation?.equityPercentageMax
      );
    }
    if (
      filters.valuation?.assessedLandValueMin ||
      filters.valuation?.assessedLandValueMax
    ) {
      searchCriteria.assdLandValue = addSearchNumber(
        filters.valuation?.assessedLandValueMin,
        filters.valuation?.assessedLandValueMax
      );
    }
    if (
      filters.valuation?.assessedTotalValueMin ||
      filters.valuation?.assessedTotalValueMax
    ) {
      searchCriteria.assdTotalValue = addSearchNumber(
        filters.valuation?.assessedTotalValueMin,
        filters.valuation?.assessedTotalValueMax
      );
    }
    if (
      filters.valuation?.marketLandValueMin ||
      filters.valuation?.marketLandValueMax
    ) {
      searchCriteria.marketLandValue = addSearchNumber(
        filters.valuation?.marketLandValueMin,
        filters.valuation?.marketLandValueMax
      );
    }
    if (
      filters.valuation?.marketTotalValueMin ||
      filters.valuation?.marketTotalValueMax
    ) {
      searchCriteria.marketTotalValue = addSearchNumber(
        filters.valuation?.marketTotalValueMin,
        filters.valuation?.marketTotalValueMax
      );
    }
  }

  // Mortgage
  if (filters.mortgage) {
    if (
      filters.mortgage?.mortgageInterestRateMin ||
      filters.mortgage?.mortgageInterestRateMax
    ) {
      searchCriteria.interestRate = addSearchNumber(
        filters.mortgage?.mortgageInterestRateMin,
        filters.mortgage?.mortgageInterestRateMax
      );
    }
    if (
      filters.mortgage?.mortgageAmountMin ||
      filters.mortgage?.mortgageAmountMax
    ) {
      searchCriteria.mortgageAmount = addSearchNumber(
        filters.mortgage?.mortgageAmountMin,
        filters.mortgage?.mortgageAmountMax
      );
    }
    if (
      filters.mortgage?.mortgageRecordingDateMin ||
      filters.mortgage?.mortgageRecordingDateMax
    ) {
      searchCriteria.mortgageRecordingDate = addSearchDate(
        filters.mortgage?.mortgageRecordingDateMin,
        filters.mortgage?.mortgageRecordingDateMax
      );
    }

    // Weather Events
    if(filters.weather?.weatherEventType) {
      searchCriteria.weatherEventType = addSearchString(filters.weather.weatherEventType)
    }
    if(filters.weather?.weatherEventTypeDataMin || filters.weather?.weatherEventTypeDataMax) {
      searchCriteria.weatherEventTypeData = addSearchNumber(
        filters.weather?.weatherEventTypeDataMin,
        filters.weather?.weatherEventTypeDataMax
      );
    }
    if(filters.weather?.weatherEventLastDays) {
      searchCriteria.weatherEventLastDays = addSearchString(filters.weather.weatherEventLastDays)
    }
  }
  return searchCriteria;
}

function addSearchString(value, notEqual = false) {
  if (notEqual) {
    return { "not-equals": `${value}` };
  }
  return { equals: `${value}` };
}

function addSearchNumber(minValue = 0, maxValue = 0) {
  if (minValue) minValue = Number(minValue);
  if (maxValue) maxValue = Number(maxValue);
  if (minValue > -1 && maxValue >= minValue) {
    return { min: minValue, max: maxValue };
  }
  if (minValue > -1 && maxValue <= 0) {
    return { min: minValue };
  }
  if (maxValue > -1 && minValue <= 0) {
    return { max: maxValue };
  }
  if (minValue < 0 && maxValue < 0) {
    return "";
  }
}

function addSearchList(listVal) {
  if (listVal?.length) {
    listVal = listVal.map((val) => val?.trim());
  }
  return { list: listVal };
}

function addSearchDate(minValue = "", maxValue = "") {
  if (minValue && maxValue) {
    return {
      min: `${moment(minValue).toISOString()}`,
      max: `${moment(maxValue).toISOString()}`,
    };
  }
  if (minValue && !maxValue) {
    return { min: `${moment(minValue).toISOString()}` };
  }
  if (maxValue && !minValue) {
    return { max: `${moment(maxValue).toISOString()}` };
  }
  if (!minValue && !maxValue) {
    return "";
  }
}

function addSearchLatLongList(listVal) {
  if (listVal?.length) {
    const latLongList = [];
    for (const latLng of listVal) {
      if (latLng?.length > 1) {
        const item = {
          long: latLng[0],
          lat: latLng[1],
        };
        latLongList.push(item)
      }
    }
    return { list: latLongList };
  }
}
