import axios from "axios";
import React, { useState, useEffect } from "react";
import { GetAuthToken } from "../../Utils/auth-verification.util";
import { Col, Row, Button, Card, Form, InputGroup, Alert } from "react-bootstrap";
import {
  AFFILIATE_SAVE_PAYOUT_INFO_ENDPOINT,
} from "../../Constants/api-routes.constant";
import { EMAIL_ADDRESS_REGEX } from "../../Constants/regex.constant";

const AffiliateLinkAndPayEmail = (props) => {
  const [copy, setCopy] = useState(false);
  const [payoutEmail, setPayoutEmail] = useState('');
  const [phoneNumberWithMaking, setPhoneNumberWithMaking] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showMessage, setShowMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const handleCopyClick = () => {
    const inputField = document.getElementById("inputField");
    inputField.select();
    document.execCommand("copy");
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  // Function to remove the Masking from phone Number
  const NormalizePhoneNumber = (phone) => {
    const numericOnly = phone.replace(/\D/g, '');
    let maskedNumber = '';
    if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
    }
    if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
    }
    if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
    }
    setPhoneNumber(numericOnly.slice(0, 10));
    setPhoneNumberWithMaking(maskedNumber); // saving value for input to the previous value in input

  }


  useEffect(() => {
    setPayoutEmail(props?.affiliateInfo?.paymentEmail ?? '')
    NormalizePhoneNumber(props?.affiliateInfo?.paymentPhone ?? '');
  }, [])


  const handleSavePayoutDetail = async () => {
    const token = GetAuthToken();
    if (!token) return [];
    setEmailError('');
    setPhoneNumberError('');

    if(!payoutEmail?.match(EMAIL_ADDRESS_REGEX)) {
      setEmailError('Please provide a valid email.');
      return;
    }
    if(!phoneNumberWithMaking?.trim()) {
      setPhoneNumberError('Please provide a valid phone number.');
      return;
    }

    let endpoint = AFFILIATE_SAVE_PAYOUT_INFO_ENDPOINT;
    return fetch(endpoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email: payoutEmail, phone: phoneNumberWithMaking }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.result) {
          // console.log('success');
          setShowMessage('Payout Details updated successfully.')
          setTimeout(() => {
            setShowMessage('')
          }, 3000);
        } else {
          console.log(response?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err?.message);
      });
  }


  return (
    <>
      <Row className="affiliate-link">
        <Col md={12}>
          <Form.Label className="affiliat">
            Your Affiliate Link
          </Form.Label>  
        </Col>
        <Col className="mb-4" md={6}>
          <InputGroup>
            <Form.Control
              className="normal form-control"
              aria-label="Example text with button addon"
              aria-describedby="basic-addon1"
              value={props?.affiliateInfo?.affiliateLink ?? ''}
              readOnly
              id="inputField"
            /> 
            {copy ? (
              <span className="copy_message animated"> Text Copied</span>
            ) : (
              ""
            )}
          </InputGroup>
        </Col>
        <Col md={2}>
          <Button onClick={handleCopyClick}>Copy</Button>
        </Col>
 
        <Col md={12}>
          <hr className="my-4 custom-hr"></hr>
        </Col>

        <Col className="mt-4 mb-4" md={12}>
          <Row>
            <Col md={12}>
              <h2 className="mb-3">Payout Details</h2>
            </Col>
            <Col md={12}>
              <p>Please provide your email OR phone number associated with your Zelle Account.</p>
            </Col>
            <Col md={12}>
              {showMessage &&
                <Row>
                  <Col sm={12} md={6} lg={4} xl={4}>
                    <Alert variant="success">
                      {showMessage}
                    </Alert>
                  </Col>
                </Row>
              }

              <Row className="mt-2">
                <Col className="mb-sm-4" xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Form.Label className="affiliat">
                    Zelle Email Address
                  </Form.Label>
                  <Form.Control
                    className="normal2"
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                    placeholder="email@domain.com"
                    value={payoutEmail}
                    onChange={(e) => { setPayoutEmail(e.target.value) }}
                  />
                  {emailError && (
                    <p className="alert-danger mt-1 px-2 py-1">{emailError}</p>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Form.Label className="affiliat">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="normal2 p-2"
                    name="phoneNumber"
                    onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); }}
                    placeholder="(232) 342-3423"
                    value={phoneNumberWithMaking}
                    id="phone_number"
                  />
                   {phoneNumberError && (
                    <p className="alert-danger mt-1 px-2 py-1">{phoneNumberError}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={4}> 
                  <Button size="xs" onClick={handleSavePayoutDetail} className="affiliat_button">Save</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          
        </Col>
        
        <Col md={12}>
          <hr className="my-4 custom-hr"></hr>
        </Col>

      </Row> 
    </>
  );
};

export default AffiliateLinkAndPayEmail;
