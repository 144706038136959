import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Spinner, Form, Button, Image } from "react-bootstrap";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import "../../assets/css/main.css";
import leftBannerImage from "../../assets/img/signup_new.png";
import { NewEmailVerificationApi } from "../../Utils/clients.util";
import { BiSolidUserCheck, BiSolidUserX } from "react-icons/bi";
import goodCheckSign from "../../assets/img/good_check_sign.png";

const NewEmailVerification = () => {
  const { id } = useParams();
  const [formError, setFormError] = useState("");
  const [hasVerified, setHasVerified] = useState(false);
  const [apiCallCompleted, setApiCallCompleted] = useState(false);

  useEffect(() => {
    document.title = "Email Verification - Skipify.ai";
    window.scrollTo(0, 0);
    setApiCallCompleted(false)
    handleEmailVerificationCode();
  }, []);

  const handleEmailVerificationCode = async () => {
    const response = await NewEmailVerificationApi(id);
    setApiCallCompleted(true);

    if (response?.statusCode == 200 && response?.result) {
      setHasVerified(true);
    } else {
      setFormError(
        response?.message ?? "Invalid request received. Please try again"
      );
      setHasVerified(false);
    }
  };

  return (
    <>
      {/* <TopNav /> */}

      <div className="register_section">
        <div className="register_section_overlay">
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              md={12}
              lg={{ span: 7, order: 1 }}
              className="p-0 text-center"
            >
              <div className="signup_image_div">
                <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                  <Image src={leftBannerImage} alt="" className="bg_image" />
                </a>
                <div className="text-div">
                  <h4>Lead Generation.</h4>
                  <h4>Made Easy.</h4>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={12}
              lg={{ span: 5, order: 2 }}
              className="mb-5"
            >
              <div className="main-form2">
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={10}
                    className="mx-auto text-center mt-5"
                  >
                    <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                      <Image
                        src={require("./../../assets/img/logo.png")}
                        alt="logo"
                        className="register_logo"
                      />
                    </a>
                    <h2 className="register_title mt-4">
                      New Email Verification
                    </h2>

                    {!apiCallCompleted ? (
                      <p className="register_des mt-5">
                        Please wait while we are verifying your request.
                      </p>
                    ) : (
                      ""
                    )}

                    {formError && (
                      <div className="alert alert-danger mt-5">
                        <h6
                          style={{
                            color: "#0f5132 !important",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          <BsFillExclamationCircleFill /> {formError}
                        </h6>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col sm={12} md={12} lg={9} className="mx-auto">
                    {hasVerified ? (
                      <>
                        <Row className="mt-2">
                          <Col md={12}>
                            <p className="mt-1 mb-4 text-center">
                              <Image src={goodCheckSign} alt="" width={90} />
                            </p>
                            <p className="register_des mb-5">
                              Your email address has been updated. You can now
                              use your new email to log in.
                            </p>
                            <div className="d-grid gap-2 mt-4">
                              <a href={"/login"} className="registerbtn">
                                Login
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <FooterFront /> */}
    </>
  );
};

export default NewEmailVerification;
