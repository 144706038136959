import React, { useEffect, useState } from "react";
import NavBarMenu from "../../Essentials/NavBarMenu";
import Header from "../../Essentials/Header";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  BILLING_GET_CUSTOMER_DETAILS_ENDPOINT,
  BILLING_UPDATE_PAYMENT_DETAILS_ENDPOINT,
} from "../../Constants/api-routes.constant";
import { GetAuthToken } from "../../Utils/auth-verification.util";
// import { STRIPE_PK } from "../../Constants/api-credentials.constant";
import BillingCardElement from "./billing-card-element";
import DashbaordLayout from "../DashbaordLayout";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const AddBilling = (props) => {
  const [skipifyClientID, setSkipifyClientID] = useState("");
  const [stripeCustomerID, setStripeCustomerID] = useState("");
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [stripeSetupIntent, setStripeSetupIntent] = useState("");
  const [stripeOptions, setStripeOptions] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    GetBillingDetails();
  }, []);

  const GetBillingDetails = () => {
    setErrorMsg("");
    setSuccessMsg("");
    const token = GetAuthToken();
    if (!token) return [];
    setShowLoader(true);
    return fetch(BILLING_GET_CUSTOMER_DETAILS_ENDPOINT, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.result) {
          setStripeCustomerID(response.result?.stripeCustomerId);
          setStripeClientSecret(response?.result?.stripeClientSecret);
          setStripeSetupIntent(response.result?.stripeSetupIntentId);
          setSkipifyClientID(response.result?.clientId);
          let options = {
            // passing the client secret obtained from the server
            clientSecret: response?.result?.stripeClientSecret,
          };
          setStripeOptions(options);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        return err;
      });
  };

  const confirmSetupIntentResult = (result) => {
    setErrorMsg("");
    setSuccessMsg("");

    const token = GetAuthToken();
    if (!token) return [];

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setErrorMsg(result.error.message);
      return;
    }

    if (result?.setupIntent?.status == "succeeded") {
      // result.setupIntent.id  // setupIntent ID
      //result.setupIntent.payment_method  // Payment method ID. This will help us to retrive the card
      // result.setupIntent.status == succeeded
      fetch(BILLING_UPDATE_PAYMENT_DETAILS_ENDPOINT, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
        body: JSON.stringify({
          stripeSetupIntentId: result.setupIntent.id,
          stripePaymentMethodId: result.setupIntent.payment_method,
          stripeCustomerId: stripeCustomerID,
          stripeClientSecret: stripeClientSecret,
        }),
      })
        .then((raw) => raw.json())
        .then((response) => {
          if (response.statusCode == 200) {
            setSuccessMsg("Your credit card has been updated successfully.");
            GetBillingDetails();
            setTimeout(() => {
              window.location.href = "/billing";
            }, 2000);
          } else {
            setErrorMsg(response?.message);
            setTimeout(() => {
              window.location.href = "/add-new-billing";
            }, 2000);
          }

        })
        .catch((err) => {
          setErrorMsg(err?.message);
        });
    } else {
      setErrorMsg(
        "Sorry! We cannot add the card at the moment. Please try again."
      );
    }
    console.log(result);
  };

  return (
    <div>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <DashbaordLayout>

        <div className="d-flex" id="wrapper">
          <div id="page-content-wrapper">
            {/* <!-- Page content--> */}
            <div className="container">
              {errorMsg ? (
                <div className="alert alert-danger">{errorMsg}</div>
              ) : (
                ""
              )}
              {successMsg ? (
                <div className="alert alert-success">{successMsg}</div>
              ) : (
                ""
              )}
              <div className="payment-container">
                <div className="row">
                  <div className="col-xl-6  mx-auto">
                    <h4>ADD NEW BILLING</h4>
                    {stripeOptions ? (
                      <Elements
                        stripe={stripePromise}
                        options={stripeOptions}
                      >
                        <BillingCardElement
                          confirmResult={confirmSetupIntentResult}
                        />
                      </Elements>
                    ) : (
                      "Loading"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashbaordLayout >
    </div >
  );
};

export default AddBilling;
