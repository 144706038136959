import React, { useEffect, useState } from 'react'
import './../assets/css/layout.css'
import NavBarMenu from '../Essentials/NavBarMenu'
import Header from '../Essentials/Header'

const DashbaordLayout = ({ children, title }) => {
    const [active, setActive] = useState(true);
    const [screen, setScreen] = useState(false)

    useEffect(() => {
        getWindowSize()
    }, []);


    const getWindowSize = () => {
        const { innerWidth: width, innerHeight: height } = window;
        // if (width <= 576) {
        if (width <= 992) {
            setActive(false)
            setScreen(true)
        } else {
            setScreen(false)
        }
    }

    return (
        <div className="layout_section">

            <div className={`side_nav_div ${active ? 'active' : ''}`}>
                <NavBarMenu active={active} setActive={setActive} />
            </div>

            <div className={`main_content_div ${active ? 'active' : ''}`}>
                <Header setActive={setActive} active={active} title={title} screen={screen} />
                {children}

                <div className='dashboard_footer'>
                    <span >© {new Date().getFullYear()}<a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`} target="_blank" >Skipify.ai {' '} </a>

                        All Rights Reserved  {' '} | Powered by</span> <a href="https://www.plego.com/" target="_blank" >Plego Technologies

                    </a>
                </div>

            </div>

        </div>
    )
}

export default DashbaordLayout