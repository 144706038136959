import {
  GET_PROPERTIES_BY_REQUEST_ID_ENDPOINT,
  GET_PROFILE_ENDPOINT,
  SKIPTRACE_BATCH_ENDPOINT,
  UPDATE_PROFILE_ENDPOINT,
  GET_PROPERTIES_BY_IDS_ENDPOINT,
} from "../Constants/api-routes.constant";
import {
  AGE_FILTER_SKIP_PER_HIT,
  DNC_CHECK_SKIP_PER_HIT,
  SKIPTRACE_BATCH_MAX_LIMIT,
} from "../Constants/common-limit.constant";
import { GetAuthToken } from "./auth-verification.util";

export const GetProfile = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(GET_PROFILE_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const UpdateProfile = (data) => {
  const token = GetAuthToken();
  if (!token) return [];

  const formData = new FormData();
  for (const name in data) {
    if (name == "profileImage" && !data[name]) continue;

    formData.append(name, data[name]);
  }

  return fetch(UPDATE_PROFILE_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

/**
 *
 * @param {*} data
 * @returns
 */
export const SkiptraceBatchByRequestId = (data, fileName) => {
  const token = GetAuthToken();
  if (!token) return [];

  // DNC and Age additional Skips
  let additionalSkipsRequired = 0;
  if (data?.dncCheck == true || data?.dncCheck == "true") {
    additionalSkipsRequired = data.propertyCount * DNC_CHECK_SKIP_PER_HIT;
  }

  // Age filter
  if (data.ageMin > 15 && data.ageMax < 150) {
    additionalSkipsRequired += data.propertyCount * AGE_FILTER_SKIP_PER_HIT;
  }
  // Relatives Contact Data. Same amount as age filter
  if (data?.relativeContacts) {
    additionalSkipsRequired += data.propertyCount * AGE_FILTER_SKIP_PER_HIT;
  }
  // Most Likely Gender
  if (data?.likelyGender) {
    additionalSkipsRequired += data.propertyCount * AGE_FILTER_SKIP_PER_HIT;
  }

  const totalSkipsRequired = data.propertyCount + additionalSkipsRequired;
  if (totalSkipsRequired > data.remainingHits) {
    return {
      error: "moreSkipsRequired",
      message: `We need total ${totalSkipsRequired?.toLocaleString()} skips to proceed. You have ${data.remainingHits?.toLocaleString()} skips remaining.`,
    };
  }

  if (data.propertyCount > SKIPTRACE_BATCH_MAX_LIMIT) {
    return {
      error: "maxLimitExceed",
      message: `You can Skip Trace maximum ${SKIPTRACE_BATCH_MAX_LIMIT} property records in a single request.`,
    };
  }

  const request = {
    propertyRequestId: data.propertyRequestId,
    propertyCount: data.propertyCount,
    yourName: data?.yourName ?? "",
    ccEmail: data?.ccEmail ?? "",
    dncCheck: data?.dncCheck == true || data?.dncCheck == "true" ? true : false,
    relativeContactCheck: data?.relativeContacts == true || data?.relativeContacts == "true" ? true : false,
    fileName,
    options: {
      skip: 0,
      take: data?.propertyCount,
    },
  };

  if (data.ageMin && data.ageMax) {
    request.ageMin = data.ageMin;
    request.ageMax = data.ageMax;
  }
  
  if (data?.likelyGender) {
    request.likelyGender = data.likelyGender;
  }

  return fetch(SKIPTRACE_BATCH_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request, fileName),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

/**
 *
 * @param {*} data
 * @returns
 */
export const SkiptraceBatchSelectedItems = (
  properties,
  fileName,
  ageMin = null,
  ageMax = null,
  dncCheck = false,
  remainingHits = 0,
  relativeContacts = false,
  likelyGender = null
) => {
  const token = GetAuthToken();
  if (!token || !properties.length) return [];

  // DNC and Age additional Skips
  let additionalSkipsRequired = 0;
  if (dncCheck == true || dncCheck == "true") {
    additionalSkipsRequired = properties.length * DNC_CHECK_SKIP_PER_HIT;
  }
  // Age filter
  if (ageMin > 15 && ageMax < 150) {
    additionalSkipsRequired += properties.length * AGE_FILTER_SKIP_PER_HIT;
  }
  // Relative Contact information filter
  if (relativeContacts == true || relativeContacts == "true") {
    additionalSkipsRequired += properties.length * AGE_FILTER_SKIP_PER_HIT;
  }
  // Most Likely Gender
  if (likelyGender) {
    additionalSkipsRequired += properties.length * AGE_FILTER_SKIP_PER_HIT;
  }

  const totalSkipsRequired = properties.length + additionalSkipsRequired;
  if (totalSkipsRequired > remainingHits) {
    return {
      error: "moreSkipsRequired",
      message: `We need total ${totalSkipsRequired?.toLocaleString()} skips to proceed. You have ${remainingHits?.toLocaleString()} skips remaining.`,
    };
  }

  if (properties.length > SKIPTRACE_BATCH_MAX_LIMIT) {
    return {
      error: "maxLimitExceed",
      message: `You can Skip Trace maximum ${SKIPTRACE_BATCH_MAX_LIMIT} property records in a single request.`,
    };
  }

  const requests = [];
  for (const property of properties) {
    const request = {
      apn: property?.APN?.trim() ?? "",
      ownerName: {
        firstName: property?.Owner1FirstName ?? "",
        middleName: property?.Owner1MiddleName ?? "",
        lastName: property?.Owner1LastName ?? "",
      },
      propertyAddress: {
        street: property?.SitusFullStreetAddress ?? "",
        city: property?.SitusCity ?? "",
        state: property?.SitusState ?? "",
        zipCode: property?.SitusZIP5 ?? "",
      },
      mailingAddress: {
        street: property?.MailingFullStreetAddress ?? "",
        city: property?.MailingCity ?? "",
        state: property?.MailingState ?? "",
        zipCode: property?.MailingZIP5 ?? "",
      },
    };

    if (ageMin && ageMax) {
      request.ageMin = ageMin;
      request.ageMax = ageMax;
    }

    if (dncCheck == true || dncCheck == "true") {
      request.dncCheck = true;
    }

    if (relativeContacts == true || relativeContacts == "true") {
      request.relativeContactCheck = true;
    }
    
    if(likelyGender) {
      request.likelyGender = likelyGender;
    }

    requests.push(request);
  }

  return fetch(SKIPTRACE_BATCH_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ requests, fileName }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Download Properties by Request ID
 * @returns
 */
export const DownloadPropertiesByRequestId = async (id, propertyCount) => {
  const token = GetAuthToken();
  if (!token || !id) return [];

  if (propertyCount > SKIPTRACE_BATCH_MAX_LIMIT) {
    return {
      error: "maxLimitExceed",
      message: `You can download maximum ${SKIPTRACE_BATCH_MAX_LIMIT} property records in a single request.`,
    };
  }

  return fetch(`${GET_PROPERTIES_BY_REQUEST_ID_ENDPOINT}/${id}`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Download Properties by Property IDs
 * @returns
 */
export const DownloadPropertiesByPropertiesId = async (
  ids,
  fileName,
  type = "ids"
) => {
  const token = GetAuthToken();
  if (!token || !ids.length) return [];

  let request = {
    propertyIds: ids,
    fileName,
  };

  if (type != "ids") {
    request = {
      requestId: ids,
      fileName,
    };
  }

  return fetch(`${GET_PROPERTIES_BY_IDS_ENDPOINT}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // notice the Bearer before your token
    },
    body: JSON.stringify(request),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};
