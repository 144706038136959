import { combineReducers } from 'redux';
import { appFeatureReducer } from './app';
import { paymentPlanFeatureReducer } from './payment-plan';
import { propertyListFeatureReducer } from './property-list'

const featuresReducer = combineReducers({
  app: appFeatureReducer,
  paymentPlan: paymentPlanFeatureReducer,
  propertyList: propertyListFeatureReducer,
});

export { featuresReducer };
