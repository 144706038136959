import React from "react"
import { Routes, Route } from "react-router-dom"

// Components
import Dashboard from "../Components/Dashboard"
import Login from "../Components/Auth/Login"
import BookDemo from "../Components/Frontend/BookADemo"
import Register from "../Components/Auth/Register"
import RegisterPayment from "../Components/Auth/RegisterPayment"
import Profile from "../Components/Profile"
import Payment from "../Components/Payment"
import InvoicesList from "../Components/InvoicesList"
import Invoice from "../Components/Invoice"
import PropertySearch from "../Components/PropertySearch"
import MyLists from "../Components/MyLists"
import Settings from "../Components/Settings"
import ForgetPassword from "../Components/Auth/ForgetPassword"
import ResetPassword from "../Components/Auth/ResetPassword"
import AccountActivation from "../Components/Auth/AccountActivation"
import FileUploadMain from "../Components/MyList_FileUpload/FileUploadMain"
import Logout from "../Components/Auth/Logout"
import NotificationList from "../Components/NotificationList"
import BillingList from "../Components/Billings/BillingList"
import Billings from '../Components/Billings/Billings'
import AddBilling from "../Components/Billings/AddBilling"
import { DownloadFile } from "../Components/DownloadFile";
import { AutoRenewSettings } from "../Components/Billings/AutoRenewSettings"
import AffiliateProgram from "../Components/affiliateprogram"
import EmailVerification from "../Components/Auth/EmailVerification"
import CancelAccountRequest from "../Components/Billings/CancelAccount"
import ThankYouPage from "../Components/Auth/ThankYou"
import AddListRotation from "../Components/Billings/AddListRotation"
import NewEmailVerification from "../Components/Auth/NewEmailVerification"



// import NotFound from "../Components/Frontend/NotFound"
// import AboutUs from "../Components/Frontend/pages/aboutus"
// import PersonalInfo from "../Components/Frontend/pages/personalInfo"
// import ContactSale from "../Components/Frontend/ContactSale"
// import TermsCondition from "../Components/Frontend/pages/terms"
// import Privacy from "../Components/Frontend/pages/privacy"
// import HomePage from "../Components/Frontend/HomePage"
// import PricingPlanPage from "../Components/Frontend/PricingPlanPage"
// import ContactPage from "../Components/Frontend/ContactPage"




const allRoutes = () => {

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard title="Dashboard" />}></Route>
      <Route path="/" element={<Login title="Login" />}></Route>
      <Route path="/login" element={<Login title="Login" />}></Route>
      <Route path="/logout" element={<Logout title="Logout" />}></Route>
      <Route path="/register" element={<Register title="Register" />}></Route>
      <Route path="/register-payment" element={<RegisterPayment title="RegisterPayment" />}></Route>
      <Route path="/email-verification" element={<EmailVerification title="Email Verification" />}></Route>
      <Route path="/account-activation/:id" element={<AccountActivation title="Account Activation" />}></Route>
      <Route path="/profile" element={<Profile title="Profile" />}></Route>
      <Route path="/book-a-demo" element={<BookDemo title="Book A Demo" />}></Route>
      <Route path="/new-email-verification/:id" element={<NewEmailVerification title="Email Verification" />}></Route>

      <Route path="/billing" element={<Billings title="Billings" />}></Route>
      <Route path="/billing-list" element={<BillingList title="BillingList" />}></Route>
      <Route path="/add-new-billing" element={<AddBilling title="AddBilling" />}></Route>
      <Route path="/auto-renew-settings" element={<AutoRenewSettings title="Auto Renew Settings" />}></Route>
      <Route path="/affiliate-program" element={<AffiliateProgram title="Affiliate Program" />}></Route>
      <Route path="/billing/add-list-rotation" element={<AddListRotation title="Add List Rotation" />}></Route>

      <Route path="/invoices-list" element={<InvoicesList title="Invoices List" />}></Route>
      <Route path="/invoice" element={<Invoice title="Invoice" />}></Route>
      <Route path="/property-search" activeClassName="active" element={<PropertySearch title="Property Search" />}></Route>
      <Route path="/my-lists" element={<MyLists title="My Data" />}></Route>
      <Route path="/settings" element={<Settings title="Settings" />}></Route>
      <Route path="/forgot-password" element={<ForgetPassword title="Forget Password" />}></Route>
      <Route path="/reset-password/:id" element={<ResetPassword title="Reset Password" />}></Route>
      <Route path="/file-upload" element={<FileUploadMain title="File Upload Wizard" />}></Route>
      <Route path="/notification-list" element={<NotificationList title="Notification List" />}></Route>

      <Route path="/files/download/:id" element={<DownloadFile title="Download File" />}></Route>
      <Route path="/cancel-account-request" element={<CancelAccountRequest title="Cancel Account Request" />}></Route>
      <Route path="/register/thank-you" element={<ThankYouPage title="Thank You" />}></Route>
      {/* Front website routes */}
      {/* <Route path="/" element={<HomePage title="Home" />}></Route>
      <Route path="/home" element={<HomePage title="Home" />}></Route>
      <Route path="/pricing-plan" element={<PricingPlanPage title="Pricing Plan" />}></Route>
      <Route path="/contact-us" element={<ContactPage title="Contact" />}></Route>
      <Route path="/contact-sales" element={<ContactSale title="Contact Sales" />}></Route>
      <Route path="/terms" element={<TermsCondition title="TermsCondition" />}></Route>
      <Route path="/privacy-policy" element={<Privacy title="Privacy Policy" />}></Route>
      <Route path="/about-us" element={<AboutUs title="About Us" />} />
      <Route path="/personal-info" element={<PersonalInfo title="About Us" />} />
      <Route path="/404" element={<NotFound title="404" />} />
      <Route path="/*" element={<NotFound title="404" />} /> */}
      {/*  */}
    </Routes>
  )
}


export default allRoutes;
