
import { GetAuthToken } from "./auth-verification.util";
import {
  BILLING_ADDITIONAL_PURCHASED_ENDPOINT,
  BILLING_DOWNGRADE_PURCHASED_ENDPOINT,
  BILLING_ENABLE_ENDPOINT,
  BILLING_FREE_PURCHASED_ENDPOINT,
  BILLING_GET_CUSTOMER_DETAILS_ENDPOINT,
  BILLING_GET_SINGLE_ACTIVE_ENDPOINT,
  BILLING_NEW_PLAN_PURCHASED_ENDPOINT,
  BILLING_PAYMENT_FAILED,
  BILLING_UPDATE_PAYMENT_DETAILS_ENDPOINT,
  GET_INVOICE_DETAIL,
  REGISTER_API_PAYMENT_CLIENT_UPDATE_ENDPOINT,
  CREATE_PAYMENT_INTENT_ENDPOINT,
  LIST_ROTATION_ACTIVATE_ENDPOINT,
  LIST_ROTATION_GET_DETAILS_ENDPOINT,
  LIST_ROTATION_GET_ACTIVATION_PRICE_ENDPOINT,
  LIST_STORAGE_SUBSCRIPTION_PURCHASE_ENDPOINT,
  LIST_STORAGE_SUBSCRIPTION_PRICE_ENDPOINT,
  LIST_STORAGE_GET_CUSTOMER_REMAINING_ENDPOINT,
  LIST_STORAGE_GET_CUSTOMER_ACTIVE_SUBSCRIPTION_ENDPOINT,
  LIST_STORAGE_REMOVE_CUSTOMER_ACTIVE_SUBSCRIPTION_ENDPOINT
} from "../Constants/api-routes.constant";


export const paymentFailed = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(BILLING_PAYMENT_FAILED, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const newPlanPurchased = async (requestBody) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(BILLING_NEW_PLAN_PURCHASED_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};


export const paymentSuccessful = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(REGISTER_API_PAYMENT_CLIENT_UPDATE_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};




export const getNewBillingDetails = async () => {
  const token = GetAuthToken();
  console.log(token);
  if (!token) return [];

  return fetch(BILLING_GET_CUSTOMER_DETAILS_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
}

export const updatePaymentIntentDetails = async (requestPayload) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(BILLING_UPDATE_PAYMENT_DETAILS_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestPayload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
}

export const enableBillingById = async (billingId) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(BILLING_ENABLE_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ billingId: billingId }),

  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
}

export const getActiveBilling = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(
    BILLING_GET_SINGLE_ACTIVE_ENDPOINT,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
}

export const additionalPurchase = async (requestBody) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(BILLING_ADDITIONAL_PURCHASED_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};


export const getInvoiceDetail = async (id) => {
  const token = GetAuthToken();
  if (!token) return [];
  return fetch(`${GET_INVOICE_DETAIL}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const downgradePlanPurchase = async (requestBody) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(BILLING_DOWNGRADE_PURCHASED_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const freePlanPurchase = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(
    BILLING_FREE_PURCHASED_ENDPOINT,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};


export const createStripePaymentIntent = async (amountInDollars, description) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(CREATE_PAYMENT_INTENT_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      amountInDollars,
      description
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if(response?.result) return response.result;
      else return response;
    })
    .catch((err) => {
      return err;
    });
};

export const activateListRotation = async (requestBody) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_ROTATION_ACTIVATE_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

// Customer List Rotation details
export const GetListRotationDetails = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_ROTATION_GET_DETAILS_ENDPOINT, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const GetListRotationActivationPrice = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_ROTATION_GET_ACTIVATION_PRICE_ENDPOINT, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

/**
 * List Storage Subscription Activation
 * @param {*} requestBody 
 * @returns 
 */
export const activateListStorageSubscription = async (requestBody) => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_STORAGE_SUBSCRIPTION_PURCHASE_ENDPOINT, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

/**
 * Get Price Details
 * @param {*} requestBody 
 * @returns 
 */
export const GetListStoragePriceDetails = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_STORAGE_SUBSCRIPTION_PRICE_ENDPOINT, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const GetCustomerRemainingListStorage = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_STORAGE_GET_CUSTOMER_REMAINING_ENDPOINT, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};


export const GetActiveListStorageSubscription = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_STORAGE_GET_CUSTOMER_ACTIVE_SUBSCRIPTION_ENDPOINT, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const RemoveListStorageSubscription = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(LIST_STORAGE_REMOVE_CUSTOMER_ACTIVE_SUBSCRIPTION_ENDPOINT, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};