import * as moment from "moment";

/**
 * This method will check if the user already logged in and has a valid Auth token
 * @returns bool
 */
export const IsLoggedIn = () => {
  const authToken = localStorage.getItem("skipifyAuthToken");
  if (!authToken) return false;

  const tokenExpiry = localStorage.getItem("skipifyTokenExpiry");
  if (!tokenExpiry) return false;

  if (moment() > moment(tokenExpiry)) {
    return false;
  }

  return true;
};

export const GetAuthToken = () => {
  const authToken = localStorage.getItem("skipifyAuthToken");
  return authToken;
}