import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-dropdown";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import {
  FirstNameRegex,
  LastNameRegex,
  PropertyAddressRegex,
  PropertyCityRegex,
  PropertyStateRegex,
  PropertyZipRegex,
  MailingAddressRegex,
  MailingCityRegex,
  MailingStateRegex,
  MailingZipRegex,
  ApnRegex,
} from "./constants/fieldname-regex.constant";

const FileUploadStep2 = (props) => {
  const fileHeaders = props.fileHeaders;
  const fileUploadData = props.fileUploadData;
  // props.handleStep2Call

  // Fields Names
  const [firstName, setFirstName] = useState(
    fileHeaders.find((fh) => fh?.match(FirstNameRegex))
  );
  const [lastName, setLastName] = useState(
    fileHeaders.find((fh) => fh?.match(LastNameRegex))
  );
  const [apn, setApn] = useState(fileHeaders.find((fh) => fh?.match(ApnRegex)));
  const [propertyAddress, setPropertyAddress] = useState(
    fileHeaders.find((fh) => fh?.match(PropertyAddressRegex))
  );
  const [propertyCity, setPropertyCity] = useState(
    fileHeaders.find((fh) => fh?.match(PropertyCityRegex))
  );
  const [propertyState, setPropertyState] = useState(
    fileHeaders.find((fh) => fh?.match(PropertyStateRegex))
  );
  const [propertyZip, setPropertyZip] = useState(
    fileHeaders.find((fh) => fh?.match(PropertyZipRegex))
  );
  const [mailingAddress, setMailingAddress] = useState(
    fileHeaders.find(
      (fh) => fh?.match(MailingAddressRegex) && fh != propertyAddress
    )
  );
  const [mailingCity, setMailingCity] = useState(
    fileHeaders.find((fh) => fh?.match(MailingCityRegex) && fh != propertyCity)
  );
  const [mailingState, setMailingState] = useState(
    fileHeaders.find(
      (fh) => fh?.match(MailingStateRegex) && fh != propertyState
    )
  );
  const [mailingZip, setMailingZip] = useState(
    fileHeaders.find((fh) => fh?.match(MailingZipRegex) && fh != propertyZip)
  );
  const [customField1, setCustomField1] = useState("");
  const [customField2, setCustomField2] = useState("");
  // Order

  const [step2ErrorMsg, setStep2ErrorMsg] = useState("");

  const matchAndSetPropertyHeaders = () => {
    if (!propertyAddress) {
      setPropertyAddress(
        fileHeaders.find((fh) => fh?.match(/(street|address)/gi))
      );
    }
    if (!propertyCity) {
      setPropertyCity(fileHeaders.find((fh) => fh?.match(/(city)/gi)));
    }
    if (!propertyState) {
      setPropertyState(fileHeaders.find((fh) => fh?.match(/(state)/gi)));
    }
    if (!propertyZip) {
      setPropertyZip(fileHeaders.find((fh) => fh?.match(/(zip)/gi)));
    }
  };

  const matchAndSetMailingHeaders = () => {
    if (!mailingAddress) {
      setMailingAddress(
        fileHeaders.find(
          (fh) => fh?.match(/(street|address)/gi) && fh != propertyAddress
        )
      );
    }
    if (!mailingCity) {
      setMailingCity(
        fileHeaders.find((fh) => fh?.match(/(city)/gi) && fh != propertyCity)
      );
    }
    if (!mailingState) {
      setMailingState(
        fileHeaders.find((fh) => fh?.match(/(state)/gi) && fh != propertyState)
      );
    }
    if (!mailingZip) {
      setMailingZip(
        fileHeaders.find((fh) => fh?.match(/(zip)/gi) && fh != propertyZip)
      );
    }
  };

  useEffect(() => {
    matchAndSetPropertyHeaders();
    if(propertyAddress) {
      matchAndSetMailingHeaders();
    }
    
  }, [propertyAddress, propertyCity, propertyState, propertyZip]);

  // Step 2 Submit
  const handleStep2Submit = () => {
    setStep2ErrorMsg("");
    if (
      !lastName ||
      !propertyAddress ||
      !propertyCity ||
      !propertyState ||
      !propertyZip ||
      !mailingAddress ||
      !mailingCity ||
      !mailingState ||
      !mailingZip
    ) {
      setStep2ErrorMsg("Please select mappings for all the fields.");
      return;
    }
    // Prepare sample data of 10 records to show on step-3
    const firstNameIndex = fileHeaders.indexOf(firstName);
    const lastNameIndex = fileHeaders.indexOf(lastName);
    const apnIndex = fileHeaders.indexOf(apn);
    const propertyAddressIndex = fileHeaders.indexOf(propertyAddress);
    const propertyCityIndex = fileHeaders.indexOf(propertyCity);
    const propertyStateIndex = fileHeaders.indexOf(propertyState);
    const propertyZipIndex = fileHeaders.indexOf(propertyZip);
    const mailingAddressIndex = fileHeaders.indexOf(mailingAddress);
    const mailingCityIndex = fileHeaders.indexOf(mailingCity);
    const mailingStateIndex = fileHeaders.indexOf(mailingState);
    const mailingZipIndex = fileHeaders.indexOf(mailingZip);
    const customField1Index = fileHeaders.indexOf(customField1);
    const customField2Index = fileHeaders.indexOf(customField2);

    let i = 0;
    const sampleData = [];
    if (fileUploadData.length) {
      for (const rec of fileUploadData) {
        if (i == 0) {
          i++;
          continue;
        }

        if (i >= 11) {
          break;
        }

        const sampleRecord = {
          firstName: rec[firstNameIndex],
          lastName: rec[lastNameIndex],
          apn: rec[apnIndex],
          propertyAddress: rec[propertyAddressIndex],
          propertyCity: rec[propertyCityIndex],
          propertyState: rec[propertyStateIndex],
          propertyZip: rec[propertyZipIndex],
          mailingAddress: rec[mailingAddressIndex],
          mailingCity: rec[mailingCityIndex],
          mailingState: rec[mailingStateIndex],
          mailingZip: rec[mailingZipIndex],
          customField1: rec[customField1Index],
          customField2: rec[customField2Index],
        };
        sampleData.push(sampleRecord);
        i++;
      }
    }

    if (sampleData.length > 0) {
      const fieldsMapping = {
        firstName,
        lastName,
        apn,
        propertyAddress,
        propertyCity,
        propertyState,
        propertyZip,
        mailingAddress,
        mailingCity,
        mailingState,
        mailingZip,
        customField1,
        customField2,
      };
      props.handleStep2Call(sampleData, fieldsMapping, true);
      // The 10 rows data and isSuccessfullStep flag
    } else {
      setStep2ErrorMsg(
        "The upload file is either empty or contains invalid data."
      );
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 mx-auto stripeForm">
        <h4>Fields Mappings</h4>
        <p>Please map all company / LLC owner names to the Last Name column</p>
        {step2ErrorMsg ? (
          <div className="alert alert-danger">
            <h6 style={{ color: "#0f5132 !important", fontSize: "0.8rem" }}>
              {" "}
              <BsFillExclamationCircleFill /> {step2ErrorMsg}
            </h6>
          </div>
        ) : (
          ""
        )}

        <Row className="my-2">
          <Col xs={12} md={12} xl={4}>
            <label>First Name</label>
            <Dropdown
              options={fileHeaders}
              value={firstName}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="firstName"
              onChange={(val) => setFirstName(val.value)}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <label>Last Name</label>
            <Dropdown
              options={fileHeaders}
              value={lastName}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="lastName"
              onChange={(val) => setLastName(val.value)}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <label>Property Address</label>
            <Dropdown
              options={fileHeaders}
              value={propertyAddress}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="propertyAddress"
              onChange={(val) => setPropertyAddress(val.value)}
            />
          </Col>
        </Row>

        <Row className=" mb-2">
          <Col xs={12} md={12} xl={4}>
            <label>Property City</label>
            <Dropdown
              options={fileHeaders}
              value={propertyCity}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="propertyCity"
              onChange={(val) => setPropertyCity(val.value)}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <label>Property State</label>
            <Dropdown
              options={fileHeaders}
              value={propertyState}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="propertyState"
              onChange={(val) => setPropertyState(val.value)}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <label>Property Zip</label>
            <Dropdown
              options={fileHeaders}
              value={propertyZip}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="propertyZip"
              onChange={(val) => setPropertyZip(val.value)}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs={12} md={12} xl={4}>
            <label>Property APN</label>
            <Dropdown
              options={fileHeaders}
              value={apn}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="apn"
              onChange={(val) => setApn(val.value)}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <label>Mailing Address</label>
            <Dropdown
              options={fileHeaders}
              value={mailingAddress}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="mailingAddress"
              onChange={(val) => setMailingAddress(val.value)}
            />
          </Col>
          <Col xs={12} md={12} xl={4}>
            <label>Mailing City</label>
            <Dropdown
              options={fileHeaders}
              value={mailingCity}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="mailingCity"
              onChange={(val) => setMailingCity(val.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12} md={12} xl={4}>
            <label>Mailing State</label>
            <Dropdown
              options={fileHeaders}
              value={mailingState}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="mailingState"
              onChange={(val) => setMailingState(val.value)}
            />
          </Col>
          <Col xs={12} md={12} xl={4}>
            <label>Mailing Zip</label>
            <Dropdown
              options={fileHeaders}
              value={mailingZip}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="mailingZip"
              onChange={(val) => setMailingZip(val.value)}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs={12} md={12} xl={4}>
            <label>Custom Field 1</label>
            <Dropdown
              options={fileHeaders}
              value={customField1}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="customField1"
              onChange={(val) => setCustomField1(val.value)}
            />
          </Col>
          <Col xs={12} md={12} xl={4}>
            <label>Custom Field 2</label>
            <Dropdown
              options={fileHeaders}
              value={customField2}
              className="fields-dropdown"
              placeholder={"-- Select --"}
              name="customField2"
              onChange={(val) => setCustomField2(val.value)}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col md={12} className="mx-auto">
          <Row className="mt-4 justify-content-between">
            <Col xs={6} md={12} xl={4}>
              <button
                type="button"
                className="btn btn-primary ml-3 px-5 py-2"
                onClick={() => props.setStepName(1)}
              >
                Back
              </button>
            </Col>

            <Col xs={6} md={12} xl={4} style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-primary px-5 py-2"
                onClick={handleStep2Submit}
              >
                Next
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FileUploadStep2;
