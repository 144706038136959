import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BiSolidBadgeCheck } from "react-icons/bi";
const Success = () => {
    return (
        <Row>
            <Col md={6} className="text-center mx-auto success_div">
                <BiSolidBadgeCheck />
                <h4>Your order have been placed successfully. Please visit for more details
                </h4>
                <Link to="/my-lists" className='blue_btn'>My Files</Link>
            </Col>

        </Row>
    )
}

export default Success