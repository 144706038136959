export const VacantLandOptionsConstant = [
{value:"8000",label: "General", category: "Vacant Land"},
{value:"8001",label: "Residential", category: "Vacant Land"},
{value:"8002",label: "Commercial", category: "Vacant Land"},
{value:"8003",label: "Industrial", category: "Vacant Land"},
{value:"8004",label: "Private Preserve, Open Space (Forest Land Conservation)", category: "Vacant Land"},
{value:"8005",label: "Institutional", category: "Vacant Land"},
{value:"8006",label: "Government", category: "Vacant Land"},
{value:"8007",label: "Multi-Family", category: "Vacant Land"},
{value:"8008",label: "Rural/Agriculture", category: "Vacant Land"},
{value:"8009",label: "Waste Land, Marsh, Swamp", category: "Vacant Land"},
{value:"8010",label: "Recreational", category: "Vacant Land"},
{value:"8011",label: "Water Area (Lakes, River, Shore)", category: "Vacant Land"},
{value:"8012",label: "Unusable Land(Remant, Steep, etc)", category: "Vacant Land"},
{value:"8013",label: "Abandoned Site, Contaiminated Site", category: "Vacant Land"},
{value:"8014",label: "Under Construction", category: "Vacant Land"},
{value:"8015",label: "Destroyed/Uninhabitable improvement", category: "Vacant Land"},
{value:"8016",label: "Unspecified Improvment", category: "Vacant Land"},
{value:"8017",label: "Exempt", category: "Vacant Land"}
]