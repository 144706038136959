import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardElement, useElements, useStripe, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { Row, Col, Form, InputGroup, Button, Modal, Image, Card } from 'react-bootstrap';
import { FaPlus, FaMinus } from "react-icons/fa";
import { AiFillCreditCard } from 'react-icons/ai';
import { AddBillingModal } from './add-billing-modal';
// import { STRIPE_SK } from "../../Constants/api-credentials.constant";
import { createStripePaymentIntent, additionalPurchase, getActiveBilling, paymentFailed } from '../../Utils/billing.util';
import {
  GetFormattedCurrency,
  formatCardEndFourDigits,
  getFormattedCurrencyInInteger
} from '../../Utils/formating.util';
import {
  getCurrentBilling,
  getCurrentPlanData,
} from '../../store/selectors/features/payment-plan';
import { updateCurrentBilling } from '../../store/slices/features/payment-plan';
import visaIcon from "./../../assets/new_theme/img/payment/visa.png";
import discoverIcon from "./../../assets/new_theme/img/payment/discover.png";
import mastercardIcon from "./../../assets/new_theme/img/payment/card.png";
import jbccardIcon from "./../../assets/new_theme/img/payment/jcb.png";
import linkIcon from "./../../assets/new_theme/img/payment/link.png";
import amexIcon from "./../../assets/new_theme/img/payment/amex.png";
import addIcon from "./../../assets/new_theme/img/add.png";


const AddSkip = () => {
  document.title = "Add Skips - Skipify.ai";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const currentPlanData = useSelector(getCurrentPlanData);

  const planId = currentPlanData?._id;
  const planTitle = currentPlanData?.title || currentPlanData?.currentPackageTitle;

  const minSkips = parseInt(currentPlanData.minimumAdditionalSkipBuy);
  const minDownloads = parseInt(currentPlanData.minimumAdditionalDownloadBuy);
  const skipPricePerHit = parseFloat(currentPlanData.additionalPricePerHit);
  const downloadPricePerHit = parseFloat(currentPlanData.additionalPricePerDownload);

  const activeBilling = useSelector(getCurrentBilling);

  const stripePaymentId = activeBilling?.stripePaymentMethodId;
  const stripeCustomerId = activeBilling?.stripeCustomerId;
  const stripeClientId = activeBilling?.clientId

  const [showLoader, setShowLoader] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);

  const [additionalDownloads, setAdditionalDownloads] = useState(0);
  const [additionalSkips, setAdditionalSkips] = useState(0);

  const [downloadPrice, setDownloadPrice] = useState(0);
  const [skipsPrice, setSkipsPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);


  const handleCloseConfirmModal = () => setShowConfirm(false);
  const handleShowConfirmModal = () => setShowConfirm(true);

  const handleCloseAddCardModal = () => setShowAddCard(false);
  const handleShowAddCardModal = () => setShowAddCard(true);

  useEffect(() => {
    setTotalPrice(downloadPrice + skipsPrice);
  }, [downloadPrice, skipsPrice]);

  useEffect(() => {
   // getActiveBillingData();
  }, [])

  const getActiveBillingData = async () => {
    setShowLoader(true);
    try {
      const response = await getActiveBilling();

      if (response?.statusCode == 200 && response?.result) {
        dispatch(updateCurrentBilling(response.result));
      }

      setShowLoader(false);

    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
    }
  }

  const plusSkips = () => {
    const newSkips = additionalSkips + minSkips;
    setAdditionalSkips(newSkips);
    setSkipsPrice(newSkips * skipPricePerHit);
    plusDownloads()
  }

  const minusSkips = () => {
    const newSkips = additionalSkips - minSkips;
    if (newSkips <= 0) {
      setAdditionalSkips(0);
      setSkipsPrice(0);
    } else {
      setAdditionalSkips(newSkips);
      setSkipsPrice(newSkips * skipPricePerHit);
    }

    minusDownloads()
  }

  const plusDownloads = () => {
    // according to package
    // const newDownloads = additionalDownloads + minDownloads;
    const newDownloads = additionalDownloads + minSkips;  // just for tem
    setAdditionalDownloads(newDownloads);
    setDownloadPrice(newDownloads * downloadPricePerHit);
  }

  const minusDownloads = () => {
    const newDownloads = additionalDownloads - minSkips;  // just for tem
    // according to package
    // const newDownloads = additionalDownloads - minDownloads;

    if (newDownloads <= 0) {
      setAdditionalDownloads(0);
      setDownloadPrice(0);
    } else {
      setAdditionalDownloads(newDownloads);
      setDownloadPrice(newDownloads * downloadPricePerHit);
    }
  }

  const paymentFaied = async (message) => {
    setStripeErrorMsg(message);
    setShowLoader(false);

    try {
      const result = await paymentFailed()
    } catch (error) {
      console.log(error);
    }
  }

  const additionalBuy = async (stripeResult) => {
    if (!stripeResult?.paymentIntent) {
      return;
    }

    const requestBody = {
      packageId: planId,
      paymentIntentId: stripeResult.paymentIntent.id,
      paymentAmountInCent: stripeResult.paymentIntent.amount,
      paymentMethodId: stripeResult.paymentIntent.payment_method,
      paymentCreated: stripeResult.paymentIntent.created,
      noOfDownloads: additionalDownloads,
      noOfHits: additionalSkips,
    };

    try {

      const response = await additionalPurchase(requestBody);
      setShowLoader(false);

      if (response?.statusCode == 200 && response?.result) {
        setSuccessMsg(
          "Thank you for purchasing. Your information will be updated in a few seconds."
        );
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 3000);
      } else {
        setStripeErrorMsg(
          response?.message ?? "We are unable to verify your payment."
        );
      }

    } catch (error) {
      setShowLoader(false);
      setStripeErrorMsg(
        error?.message ?? "We are unable to verify your payment."
      )
    }
  }

  const handlePayNow = async (event) => {
    event.preventDefault();
    setShowConfirm(false)
    window.scrollTo(0, 0);

    if (!activeBilling?._id) {
      setErrorMsg('Please add Payment Method to proceed');
      return;
    }

    if (additionalDownloads === 0 && additionalSkips === 0) {
      setErrorMsg('Please add Skips or Downloads to proceed');
      return;
    }

    setShowLoader(true);
    setStripeErrorMsg("");
    setErrorMsg('');

    try {
      const paymentIntentResult = await createStripePaymentIntent(
        totalPrice,
        'Skipify - Additional Skips/Downloads Purchase Payment');

      if(!paymentIntentResult?.clientSecret) {
        if (paymentIntentResult?.message?.includes("confirmCardPayment intent secret")) {
          setStripeErrorMsg('Payment Failed due to an unexpected error. Please try later.')
        } else {
          setStripeErrorMsg(paymentIntentResult?.message ?? 'Error while creating a payment request.');
        }
        setShowLoader(false);
        return;
      }
      
      const paymentClientSecret = paymentIntentResult?.clientSecret;

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setShowLoader(false);
        setStripeErrorMsg(
          "Payment Failed. Please refresh the page and try again."
        );
        return;
      }

      let confirmPaymentOptions = {};

      if (!stripePaymentId) {
        confirmPaymentOptions = {
          payment_method: {
            card: elements.getElement(CardElement),
            metadata: {
              "customer ID": stripeClientId,
              "Add Skips Purchase": `$${totalPrice}`,
            },
          },
        };
      }

      const result = await stripe.confirmCardPayment(`${paymentClientSecret}`, confirmPaymentOptions);

      if (result.error) {

        await paymentFaied(result.error.message);
      } else {

        if (result.paymentIntent.status === "succeeded") {

          await additionalBuy(result);
        } else {

          await paymentFaied("Payment confirmation failed. Make sure your card is valid.");
        }
      }

    } catch (error) {
      if (error?.message?.includes('confirmCardPayment intent secret')) {
        setStripeErrorMsg('Payment Failed due to an unexpected error. Please try later.')
      } else {
        setStripeErrorMsg(error?.message);
      }
      setShowLoader(false);
    }
  }

  const renderConfirmPaymentModal = () => {
    return (
      <Modal
        show={showConfirm}
        onHide={handleCloseConfirmModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="modal_notification">
            <h5>Proceed with your payment?</h5>

            <div>
              <Button variant="primary" onClick={handlePayNow}>Yes</Button>
              <Button variant="warning" onClick={handleCloseConfirmModal}>No</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const renderAddBillingModal = () => {
    return (
      <AddBillingModal
        showModal={showAddCard}
        closeModalHandler={handleCloseAddCardModal}
      />
    );
  }


  return (
    <>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {stripeErrorMsg ? (
        <div className="alert alert-danger">{stripeErrorMsg}</div>
      ) : (
        ""
      )}
      {errorMsg ? (
        <div className="alert alert-danger">{errorMsg}</div>
      ) : (
        ""
      )}
      {successMsg ? (
        <div className="alert alert-success">{successMsg}</div>
      ) : (
        ""
      )}

      <div>
        <Row className='mt-3 skips-checkout justify-content-between'> 
          <Col xs={12} sm={12} md={12} xl={5} xxl={5}>
            <Row>
              <Col>
                <h2 className="mb-4 font-lg bill-gap">Checkout</h2> 
                <hr className="mb-4 mt-2 hr-custom"></hr>
              </Col>
 
              <Col className='mb-3 mt-2' xl={12}>
                <span className='step'>1</span>
                <h3>Select amount</h3>
              </Col>
              <Col className='mb-3' xl={12}>
                <p>How many additional skips do you want to purchase?</p>
              </Col>
              <Col className='mb-3' xl={12}>
                <Row> 
                  <Col xl={8} lg={8} md={10} sm={8} xs={8} className='align-self-center flex-sm justify-content-center'>
                    <button className='arthmetic' onClick={minusSkips}><FaMinus /></button>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type='text'
                        value={additionalSkips}
                      />
                    </InputGroup>
                    <button className='arthmetic' onClick={plusSkips}><FaPlus /></button>
                  </Col>
                  <Col xl={4} lg={4} md={2} sm={4} xs={4}  className='align-self-center'>
                    <h4 className='right'>${getFormattedCurrencyInInteger(skipsPrice)}</h4>
                  </Col>
                </Row>
              </Col> 
              
              <Col className='mb-3' xl={12}>
                <p>How many additional downloads do you want to purchase?</p>
              </Col>
              <Col className='mb-3' xl={12}>
                <Row> 
                  <Col xl={8} lg={8} md={10} sm={8} xs={8} className='align-self-center flex-sm justify-content-center'>
                    <button className='arthmetic' onClick={minusDownloads}><FaMinus /></button>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type='text'
                        value={additionalDownloads}
                      />
                    </InputGroup>
                    <button className='arthmetic' onClick={plusDownloads}><FaPlus /></button>
                  </Col> 
                  <Col xl={4} lg={4} md={2} sm={4} xs={4}  className='align-self-center'>
                    <h4 className='right'>${getFormattedCurrencyInInteger(downloadPrice)}</h4>
                  </Col>
                </Row>
              </Col> 

              <Col xl={12}>
                <hr className="mb-4 mt-3 hr-custom"></hr>
              </Col>

              <Col className='my-3' xl={12}>
                <span className='step'>2</span>
                <h3 className='mb-3'>Choose payment method</h3>
              </Col>

              {activeBilling._id && (
                <>
                  <Col xl={12}> 
                    <Form.Check
                      type="checkbox"
                      className='form-check-cst mb-2'
                      label={
                        <Row className="payment-skin-struct addSkips-cst">
                          <Col className='cst-wdth-skp align-self-center' xl={2} lg={2} sm={2} md={2} xs={2}>
                            {activeBilling.cardType == 'visa' && (<Image className="icon" src={visaIcon}></Image>)}
                            {activeBilling.cardType == 'discover' && (<Image className="icon" src={discoverIcon}></Image>)}
                            {activeBilling.cardType == 'mastercard' && (<Image className="icon" src={mastercardIcon}></Image>)}
                            {activeBilling.cardType == 'amex' && (<Image className="icon" src={amexIcon}></Image>)}
                            {activeBilling.cardType == 'jcb' && (<Image className="icon" src={jbccardIcon}></Image>)}
                            {activeBilling.cardType == 'link' && (<Image className="icon" src={linkIcon}></Image>)}
                             
                          </Col>
                          <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                            <div className="container-content">
                              <Row>
                                <Col>
                                  <span className="name mb-1">
                                    {activeBilling?.cardOwnerName ? activeBilling?.cardOwnerName : ''}
                                  </span>
                                  {activeBilling.cardType != "link" ? 
                                  <span className="number">
                                    **** {formatCardEndFourDigits(activeBilling.cardLastFourDigits)}
                                  </span>  
                                  : '' }
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <span className="expiry">Expires {activeBilling.cardExpiryMonth}/{activeBilling.cardExpiryYear}</span>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                          
                          </Col>
                        </Row>
                      }
                      checked
                    />
                    
                  </Col> 
                  
                </>
              )}

              <Col xl={8} md={6}> 
                <button className="download-table wd-100 p-2 my-4 pl-all-4" onClick={handleShowAddCardModal}>
                  <Image src={addIcon}></Image>
                  Add new payment method
                </button>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={12} sm={12} md={12} xl={6}>
            <div className='billing_detail_section'>

              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <h4 className='sub_title_h4  mt-3'>ADDITIONAL SKIPS</h4>
                </Col>
                <Col xs={12} md={6} lg={3} xl={3} className='p-md-0'>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type='text'
                      value={additionalSkips}
                    />
                    <div className='input-group-text addition'>
                      <button onClick={plusSkips}><FaPlus /></button>
                      <button onClick={minusSkips}><FaMinus /></button>
                    </div>
                  </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={3} xl={3}> <h4 className='sub_title_h4 right mt-3'>${getFormattedCurrencyInInteger(skipsPrice)}</h4></Col>
              </Row>
            </div>

            <div className='billing_detail_section'>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <h4 className='sub_title_h4  mt-3'>ADDITIONAL DOWNLOADS</h4>
                </Col>
                <Col xs={12} md={6} lg={3} xl={3} className='p-md-0'>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type='text'
                      value={additionalDownloads}
                    />
                    <div className='input-group-text addition'>
                      <button onClick={plusDownloads}><FaPlus /></button>
                      <button onClick={minusDownloads}><FaMinus /></button>
                    </div>
                  </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={3} xl={3}> <h4 className='sub_title_h4 right mt-3'>${getFormattedCurrencyInInteger(downloadPrice)}</h4></Col>
              </Row>
            </div>

            {activeBilling._id && (
              <Row className="mt-5">
                <Col md={12}>
                  <h4 className='sub_title_h4'>PAYMENT METHOD</h4>
                  <Form.Check
                    type="checkbox"
                    label={
                      <div className="checkbox_main">
                        <div className='img_checkbox_div'>
                          <Image src={require(`./../../assets/img/card/${activeBilling?.cardType}.png`)} alt={activeBilling?.cardType} className='img-fluid' />
                        </div>
                        <p>{activeBilling.cardLastFourDigits != '0000' ? <>Card ending in {formatCardEndFourDigits(activeBilling.cardLastFourDigits)} </> : ""} (Exp. {activeBilling.cardExpiryMonth}/{activeBilling.cardExpiryYear})</p>
                      </div>
                    }
                    checked
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-5">
              <Col md={12}>
                <h4 className='sub_title_h4'>ADD NEW PAYMENT METHOD</h4>
                <div className="checkbox_main">
                  <div className='img_checkbox_div py-1' onClick={handleShowAddCardModal}>
                    <AiFillCreditCard />
                  </div>
                </div>
              </Col>
            </Row>

          </Col> */}
          <Col xs={12} sm={12} md={12} xl={6} xxl={6}>
            <div className='billing_detail_section'>
              <h3 className='sub_title_h3'>Order Summary</h3>
              
              <hr className="my-4 hr-custom"></hr>
            
              <Card className='addskip-card'>
                <Row className='mb-xl-3 mb-md-0'>
                  <Col lg={12}>
                    <h6>Additional Skips</h6>
                  </Col>
                  <Col lg={9}>
                    <p>{getFormattedCurrencyInInteger(additionalSkips)} skips x ${skipPricePerHit} per skip</p>
                  </Col>
                  <Col lg={3}>
                    <span>{GetFormattedCurrency(skipsPrice)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <h6>Additional Downloads</h6>
                  </Col>
                  <Col lg={9}>
                    <p>{getFormattedCurrencyInInteger(additionalDownloads)} Downloads x ${downloadPricePerHit} per download</p>
                  </Col>
                  <Col lg={3}>
                    <span>{GetFormattedCurrency(downloadPrice)}</span>
                  </Col>
                </Row>

                <hr className="my-3 hr-custom"></hr>

                <Row className='mt-3'> 
                  <Col lg={6}>
                    <h6>Total amount due</h6>
                  </Col>
                  <Col lg={6}>
                    <span>{GetFormattedCurrency(totalPrice)}</span>
                  </Col> 
                </Row>

                <Row> 
                  <Col lg={12}>
                    <Form.Check  // prettier-ignore
                      type="checkbox"
                      label={`I agree to all of Skipify.ai’s Terms of Service & Privacy Policy.`}
                      className='term_checkbox'
                      checked={true}
                    />
                  </Col> 
                </Row>

                <Row className='justify-content-center'>
                  <Col lg={6}>
                    {/* <Button variant='warning' onClick={handleShowConfirmModal}>SUBMIT PAYMENT</Button> */}
                    {successMsg ? '' : <button className='submit_Payment' onClick={handlePayNow}>Submit payment</button> }
                  </Col>
                  <Col lg={12}>
                    <div className="billing_detail_section_des">
                      <h4> Purchase Details: </h4>
                      <ul>
                        <li>These skips are a one time purchase.</li>
                        <li>If you close your account, you will no longer have access to the skip trace or property download credited to your account.</li>
                      </ul>
                    </div>
                    </Col>
                </Row>

              </Card>

              {/* <Row>
                <Col md={12}>
                  <div className='table_head'>
                    <p>Item</p>
                    <p>Quantity</p>
                    <p>Amount</p>
                  </div>


                  <div className='table_body'>
                    <span>Additional Skips</span>
                    <span>{getFormattedCurrencyInInteger(additionalSkips)}</span>
                    <span>{GetFormattedCurrency(skipsPrice)}</span>
                  </div>

                  <div className='table_body'>
                    <span>Additional Downloads</span>
                    <span>{getFormattedCurrencyInInteger(additionalDownloads)}</span>
                    <span>{GetFormattedCurrency(downloadPrice)}</span>
                  </div>
 
                  <div className='sub_total_section'>
                    <div>
                      <span>Subtotal </span>
                      <span>{GetFormattedCurrency(totalPrice)}</span>
                    </div>
                  </div>
 
                  <div className='total_section'>
                    <span>Order Total  </span>
                    <span>{GetFormattedCurrency(totalPrice)}</span>
                  </div>


                  <Form.Check  
                    type="checkbox"
                    label={`I agree to all of Skipify.ai’s Terms of Service & Privacy Policy.`}
                    className='term_checkbox'
                    checked={true}
                  />
                  
                  {successMsg ? '' : <Button variant='warning' onClick={handlePayNow}>SUBMIT PAYMENT</Button> }
                  <div className="billing_detail_section_des">
                    <h4> Purchase Details: </h4>
                    <ul>
                      <li>These skips are a one time purchase.</li>
                      <li>If you close your account, you will no longer have access to the skip trace or property download credited to your account.</li>
                    </ul>
                  </div>
                </Col>
              </Row> */}
            </div>
          </Col>
        </Row>
        {renderConfirmPaymentModal()}
        {renderAddBillingModal()}
      </div>
    </>
  )
}

export default AddSkip;
