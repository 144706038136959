export const CommercialOptionsConstant = [
  { value: "2000", label: "General", category: "Commercial" },
  {
    value: "2001",
    label: "Retail Stores (Personal Services, Photography, Personal)", category: "Commercial"
  },
  { value: "2002", label: "Store (Multi-story)", category: "Commercial" },
  { value: "2003", label: "Store/Office (mixed use)", category: "Commercial" },
  {
    value: "2004",
    label: "Department Store (apparel, household goods, furniture)", category: "Commercial"
  },
  { value: "2005", label: "Department Store (Multi-store)", category: "Commercial" },
  { value: "2006", label: "Grocery, Supermarket", category: "Commercial" },
  {
    value: "2007",
    label: "Regional Shopping Center or Mall with Anchor Store",
    category: "Commercial"
  },
  {
    value: "2008",
    label: "Community Shopping Plaza or Shopping center, Mini-Mall",
    category: "Commercial"
  },
  {
    value: "2009",
    label: "Neighborhood Shopping Center, Strip Center/Mall, Enterprise Zone",
    category: "Commercial"
  },
  { value: "2010", label: "Shopping Center Common Area Parking", category: "Commercial" },
  { value: "2011", label: "Veterinary or Animal Hospital", category: "Commercial" },
  { value: "2012", label: "Restaurant", category: "Commercial" },
  { value: "2013", label: "Fast Food Restaurant / Drive-thru", category: "Commercial" },
  { value: "2014", label: "Take-out Restaurant", category: "Commercial" },
  { value: "2015", label: "Bakery", category: "Commercial" },
  { value: "2016", label: "Bar, Tavern", category: "Commercial" },
  { value: "2017", label: "Liquor Store", category: "Commercial" },
  { value: "2018", label: "Convenience Store", category: "Commercial" },
  { value: "2019", label: "Convenience Store with Fuel", category: "Commercial" },
  { value: "2020", label: "Service station (Full Service)", category: "Commercial" },
  { value: "2021", label: "Service station w/convenience store (Food Mart)", category: "Commercial" },
  { value: "2022", label: "Truck stop (Fuel and Diner)", category: "Commercial" },
  { value: "2023", label: "Vehicle rentals, vehicle sales", category: "Commercial" },
  { value: "2024", label: "Auto Repair, auto parts, Garage", category: "Commercial" },
  { value: "2025", label: "Car Wash", category: "Commercial" },
  { value: "2026", label: "Dry cleaner, Laundry Service", category: "Commercial" },
  { value: "2027", label: "Service Shop", category: "Commercial" },
  { value: "2028", label: "Nursery, Greenhouse, Florist", category: "Commercial" },
  { value: "2029", label: "Wholesale Outlet, Discount Store", category: "Commercial" },
  {
    value: "2030",
    label: "Printer/Delivery - Retail (Kinkos, UPS, FedEx, etc.)",
    category: "Commercial"
  },
  { value: "2031", label: "Mini-Warehouse, self-storage", category: "Commercial" },
  { value: "2032", label: "Day Care, Pre-school", category: "Commercial" },
  { value: "2033", label: "Motel", category: "Commercial" },
  { value: "2034", label: "Hotel", category: "Commercial" },
  { value: "2035", label: "Parking Garage, Parking Structure", category: "Commercial" },
  { value: "2036", label: "Parking Lot", category: "Commercial" },
  { value: "2037", label: "Funeral Home, Mortuary", category: "Commercial" },
  { value: "2038", label: "Casino", category: "Commercial" },
  { value: "2039", label: "Hotel-Resort", category: "Commercial" },
  { value: "2040", label: "Hotel or Motel", category: "Commercial" },
  { value: "2041", label: "Gas Station", category: "Commercial" },
  { value: "2043", label: "Commercial Building", category: "Commercial" },
  { value: "2044", label: "Commercial/Office/Residential (mixed use)", category: "Commercial" },
  { value: "2045", label: "Appliance Store (Best Buy, HH Gregg)", category: "Commercial" },
  { value: "2046", label: "Kennel", category: "Commercial" },
  { value: "2047", label: "Laundromat (Self-service)", category: "Commercial" },
  { value: "2048", label: "Nightclub (cocktail Lounge)", category: "Commercial" },
  { value: "2050", label: "Farm Supply & Equipment (Retail)", category: "Commercial" },
  {
    value: "2051",
    label: "Home Improvement, Garden Center (Home Depot, Lowes)",
    category: "Commercial"
  },
  { value: "2052", label: "Commercial Condominium (not offices)", category: "Commercial" },
  { value: "2053", label: "Drug store / Pharmacy (CVS, Walgreens)", category: "Commercial" },
  { value: "2054", label: "Bed & Breakfast", category: "Commercial" },
  { value: "2055", label: "Barber/Hair Salon", category: "Commercial" },
  { value: "2056", label: "Pet Boarding & Grooming", category: "Commercial" },
  { value: "2057", label: "Roadside Market", category: "Commercial" },
  { value: "2058", label: "Car Wash - Automated", category: "Commercial" },
  { value: "2059", label: "Car Wash - Self-Serve", category: "Commercial" },
  { value: "2060", label: "Cannabis Dispensary", category: "Commercial" },
];
