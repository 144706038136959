import React, { useEffect, useState } from "react";
import NavBarMenu from "../../Essentials/NavBarMenu";
import Header from "../../Essentials/Header";
import { GetProfile } from "../../Utils/common";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FileUploadStep1 from "./FileUploadStep1";
import FileUploadStep2 from "./FileUploadStep2";
import FileUploadStep3 from "./FileUploadStep3";
import FileUploadStep4 from "./FileUploadStep4";
import Success from "./Success";
import DashbaordLayout from "../DashbaordLayout";
import {
  BsFillCloudUploadFill,
  BsPinFill,
  BsClipboardCheckFill,
  BsCheckSquareFill,
} from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import FileUploadStep4New from "./FileUploadStep4New";
import {
  GetCustomerListStorageLimit,
  GetCustomerListStorageCount,
} from "../../Utils/clients.util";
import {
  DNC_CHECK_SKIP_PER_HIT,
  AGE_FILTER_SKIP_PER_HIT,
} from "../../Constants/common-limit.constant";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const FileUploadMain = (prop) => {
  const Price_Per_Hits = 0.11;
  const STRIPE_MIN_AMOUNT = 0.5;

  const [profileData, setProfileData] = useState(null);
  const [ccEmail, setCcEmail] = useState("");
  const [fieldMappings, setFieldMappings] = useState("");

  const [dncCheck, setDncCheck] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [ccEmailError, setCcEmailError] = useState("");
  const [fileError, setFileError] = useState("");
  const [fileHeaders, setFileHeaders] = useState([]);
  const [fileUploadData, setFileUploadData] = useState(0);
  const [fileUploadSampleData, setFileUploadSampleData] = useState([]);
  // Fields Names
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [apn, setApn] = useState("");
  const [propertyAddress, setPropertyAddress] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [mailingCity, setMailingCity] = useState("");
  const [mailingState, setMailingState] = useState("");
  const [mailingZip, setMailingZip] = useState("");
  const [customField1, setCustomField1] = useState("");
  const [customField2, setCustomField2] = useState("");

  const [amountDue, setAmountDue] = useState(0.0);
  const [chargableHits, setChargableHits] = useState(0);
  const [remainingSkiptraceHits, setRemainingSkiptraceHits] = useState(0);
  const [paymentIntentData, setPaymentIntentData] = useState("");
  const [stripClientSecret, setStripeClientSecret] = useState("");
  const [paymentStatusFlag, setPaymentStatusFlag] = useState(false); // true = paid, false = unpaid
  const [orderNumber, setOrderNumber] = useState("");
  const [orderId, setOrderId] = useState("");

  const [ageMin, setAgeMin] = useState(0);
  const [ageMax, setAgeMax] = useState(0);
  const [relativeContacts, setRelativeContacts] = useState(false);
  const [mostLikelyGender, setMostLikelyGender] = useState();

  // Steps completion flags
  const [stepName, setStepName] = useState(1);
  const [listStorageLimit, setListStorageLimit] = useState(0);
  const [customerListStorageCount, setCustomerListStorageCount] = useState();

  const getCustomerListStorageLimit = async () => {
    const lsLimit = await GetCustomerListStorageLimit();
    setListStorageLimit(lsLimit);
  };

  const getCustomerListStorageCount = async () => {
    const customerLsCount = await GetCustomerListStorageCount();
    setCustomerListStorageCount(customerLsCount);
  };

  useEffect(() => {
    GetProfile().then((response) => {
      if (response?.result) {
        setProfileData(response.result);
        if (response.result.billing?.remainingHits) {
          setRemainingSkiptraceHits(response.result.billing?.remainingHits);
        }
      }
    });
    getCustomerListStorageLimit();
    getCustomerListStorageCount();
  }, []);

  /**
   * Step 1 Submit on Successful File Upload
   * This is call from FileUploadStep1 component
   * @param {*} fileName
   * @param {*} fileData
   * @param {*} isSuccessfullyParsed
   */
  const handleStep1Call = (
    fileName1,
    fileData,
    file,
    ccEmail,
    isSuccessfullyParsed = false,
    dncCheck = false,
    demographicFilters = null
  ) => {
    setCcEmailError("");
    setFileError("");
    const totalRecords = fileData.length - 1;
    let additionalSkipsRequired = 0;

    if (isSuccessfullyParsed == true) {
      setFileHeaders(fileData[0]);
      setFileUploadData(fileData);
      setFileUpload(file);
      setCcEmail(ccEmail);
      setFileName(fileName1);
      setDncCheck(dncCheck);

      // Demographic Filters
      // Age range
      try {
        if (demographicFilters) {
          if (demographicFilters?.ageMin && demographicFilters?.ageMax) {
            setAgeMin(demographicFilters.ageMin);
            setAgeMax(demographicFilters.ageMax);
            additionalSkipsRequired +=
              Number(AGE_FILTER_SKIP_PER_HIT) * totalRecords;
          }
          // Relative contacts
          if (demographicFilters?.relativeContacts) {
            setRelativeContacts(demographicFilters.relativeContacts);
            additionalSkipsRequired +=
              Number(AGE_FILTER_SKIP_PER_HIT) * totalRecords;
          }
          // Most Likely Gender
          if (demographicFilters?.likelyGender) {
            setMostLikelyGender(demographicFilters.likelyGender);
            additionalSkipsRequired +=
              Number(AGE_FILTER_SKIP_PER_HIT) * totalRecords;
          }
        }
        // DNC
        additionalSkipsRequired += dncCheck
          ? Number(DNC_CHECK_SKIP_PER_HIT) * totalRecords
          : 0;
      } catch (err) {
        console.log(err);
      }
      additionalSkipsRequired = Number(additionalSkipsRequired.toFixed(2));

      // console.log(dncCheck, demographicFilters, additionalSkipsRequired)

      // Calculate Amount Due
      const remainingHits = Number(profileData.billing?.remainingHits) || 0;
      const customerPricePerHit =
        Number(profileData.billing?.pricePerHit) || Price_Per_Hits;

      // Check customer list storage limit
      if (totalRecords > 0 && listStorageLimit > 0) {
        const newStorageLimit = totalRecords + customerListStorageCount;
        let remainingListCount = listStorageLimit - customerListStorageCount;
        if (remainingListCount < 0) remainingListCount = 0;

        if (newStorageLimit > listStorageLimit) {
          setFileError(
            <span>
              Your remaining skip-trace list storage limit is{" "}
              <strong>{remainingListCount}</strong>. To proceed with this order,
              please upgrade your plan or delete some previously skip-traced
              data from the <a href="/my-lists">My Data</a> section.
            </span>
          );
          return;
        }
      }
      // Calculate charge amount
      if (totalRecords + additionalSkipsRequired > remainingHits) {
        const extraHits =
          totalRecords + additionalSkipsRequired - remainingHits;
        const amountDue = Number((extraHits * customerPricePerHit).toFixed(2));

        setAmountDue(amountDue);
        setChargableHits(extraHits);

        // Stripe accept price >= $0.50
        // We need to ask the customer to add a few more records into the uploaded files to proceed with the order.
        if (amountDue < STRIPE_MIN_AMOUNT) {
          setFileError(
            "The uploaded file contains too few records to charge. Please add a few more records to proceed."
          );
          return;
        }
      } else {
        setAmountDue(0.0);
        setChargableHits(0);
      }

      // Set next step
      setStepName(2);
    }
  };

  /**
   * Step 2 Call from FileUploadStep2 component
   * @param {*} sampleData
   * @param {*} isSuccessFullStep
   */
  const handleStep2Call = (
    sampleData,
    fieldsMappings,
    isSuccessFullStep = false
  ) => {
    if (isSuccessFullStep == true) {
      setFieldMappings(fieldsMappings);
      setFirstName(fieldsMappings.firstName);
      setLastName(fieldsMappings.lastName);
      setApn(fieldsMappings.apn);
      setPropertyAddress(fieldsMappings.propertyAddress);
      setPropertyCity(fieldsMappings.propertyCity);
      setPropertyState(fieldsMappings.propertyState);
      setPropertyZip(fieldsMappings.propertyZip);
      setMailingAddress(fieldsMappings.mailingAddress);
      setMailingCity(fieldsMappings.mailingCity);
      setMailingState(fieldsMappings.mailingState);
      setMailingZip(fieldsMappings.mailingZip);
      setCustomField1(fieldMappings.customField1);
      setCustomField2(fieldMappings.customField2);
      setFileUploadSampleData(sampleData);
      setStepName(3);
    }
  };

  /**
   * Call to Step 3 from FileUploadStep3
   * @param {*} orderId
   * @param {*} orderNumber
   * @param {*} intentData
   * @param {*} clientSecret
   * @param {*} isSuccessFullStep
   */
  const handleStep3Call = (
    orderId,
    orderNumber,
    intentData = null,
    clientSecret = null,
    isSuccessFullStep = false
  ) => {
    if (isSuccessFullStep) {
      setOrderId(orderId);
      setOrderNumber(orderNumber);
      if (intentData) setPaymentIntentData(intentData);
      if (clientSecret) setStripeClientSecret(clientSecret);
      setStepName(4);
    }
  };

  /**
   * Step 4 from FileUploadStep4 component
   * @param {*} isSuccessFullStep
   */
  const handleStep4Call = (isSuccessFullStep) => {
    if (isSuccessFullStep == true) {
      setStepName(5);
    }
  };
  const pageTitle = "File Upload";

  return (
    <DashbaordLayout title={pageTitle}>
      <div className="d-flex" id="wrapper">
        <div id="page-content-wrapper">
          <Row>
            <Col md={6}>
              <h2 className="main-title-mobile">{pageTitle}</h2>
            </Col>
          </Row>

          <Row className=" mt-3">
            <Col md={12}>
              <ul className="step_head">
                <li
                  className={`${
                    stepName == 1 ? "current" : stepName > 1 ? "complete" : ""
                  }`}
                >
                  <div className="step_icon">
                    <BsFillCloudUploadFill />
                  </div>
                  <div className="step_text">Upload File</div>
                </li>
                <li
                  className={`${
                    stepName == 2 ? "current" : stepName > 2 ? "complete" : ""
                  }`}
                >
                  <div className="step_icon">
                    <BsPinFill />
                  </div>
                  <div className="step_text">Select Field</div>
                </li>
                <li
                  className={`${
                    stepName == 3 ? "current" : stepName > 3 ? "complete" : ""
                  }`}
                >
                  <div className="step_icon">
                    <BsClipboardCheckFill />
                  </div>
                  <div className="step_text">Confirm Mappings</div>
                </li>
                <li
                  className={`${
                    stepName == 4 ? "current" : stepName > 4 ? "complete" : ""
                  }`}
                >
                  <div className="step_icon">
                    <BsCheckSquareFill />
                  </div>
                  <div className="step_text">Order Summary</div>
                </li>
              </ul>
            </Col>
          </Row>

          {/* <!-- Step 1 -->*/}
          {stepName == 1 ? (
            <FileUploadStep1
              handleStep1Call={handleStep1Call}
              profileData={profileData}
              fileError={fileError}
            />
          ) : (
            ""
          )}

          {/* <!-- Step 2 -->*/}
          {stepName == 2 ? (
            <FileUploadStep2
              handleStep2Call={handleStep2Call}
              fileHeaders={fileHeaders}
              fileUploadData={fileUploadData}
              setStepName={setStepName}
            />
          ) : (
            ""
          )}

          {/* -- Step 3 -- */}
          {stepName == 3 ? (
            <FileUploadStep3
              handleStep3Call={handleStep3Call}
              amountDue={amountDue}
              fileUpload={fileUpload}
              fileUploadData={fileUploadData}
              fileUploadSampleData={fileUploadSampleData}
              ccEmail={ccEmail}
              setStepName={setStepName}
              fieldMappings={fieldMappings}
              dncCheck={dncCheck}
              chargableHits={chargableHits}
              ageMin={ageMin}
              ageMax={ageMax}
              relativeContacts={relativeContacts}
              mostLikelyGender={mostLikelyGender}
            />
          ) : (
            ""
          )}

          {/** -- Step 4 -- */}
          {stepName == 4 ? (
            <Elements stripe={stripePromise}>
              <FileUploadStep4New
                profileData={profileData}
                fileUpload={fileUpload}
                fileUploadData={fileUploadData}
                amountDue={amountDue}
                chargableHits={chargableHits}
                paymentIntentData={paymentIntentData}
                orderId={orderId}
                orderNumber={orderNumber}
                handleStep4Call={handleStep4Call}
                setStepName={setStepName}
                dncCheck={dncCheck}
                ageMin={ageMin}
                ageMax={ageMax}
                relativeContacts={relativeContacts}
                mostLikelyGender={mostLikelyGender}
              />
            </Elements>
          ) : (
            ""
          )}

          {/** -- Step 5 -- */}
          {stepName == 5 ? <Success /> : ""}
        </div>
      </div>
    </DashbaordLayout>
  );
};

export default FileUploadMain;
