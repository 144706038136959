import { Col, Row, Spinner, Form, Button, Image } from "react-bootstrap";
import leftBannerImage from "../../assets/img/signup_new.png";
import goodCheckSign from "../../assets/img/good_check_sign.png";
import { useParams } from "react-router-dom";
import { RESEND_VERIFICATION } from "../../Constants/api-routes.constant";
import { useState, useEffect } from "react";
import { MetaPixelTrackingCode } from "../MetaPixelCode";

const ThankYouPage = () => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [resendCounter, setResendCounter] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem('skipifyUserEmail');
    if(email) {
      setUserEmail(email);
    }
    MetaPixelTrackingCode(true);
  }, []);

  const handleResendVerificationBtn = () => {
    setSuccessMsg("");
    setErrorMsg("");
    setShowLoader(true);
    if (resendCounter > 3) {
      setErrorMsg("The email verification link has already sent to your email address.");
      setShowLoader(false);
      return;
    }
    if(!userEmail) {
      setErrorMsg("The email address is missing.");
      setShowLoader(false);
      return;
    }
    return fetch(RESEND_VERIFICATION, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: userEmail,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setShowLoader(false);
        if (response.statusCode == 200) {
          setSuccessMsg(
            "The email verification link has been resent to your email address."
          );
          setResendCounter(resendCounter + 1);
        } else {
          setErrorMsg(response?.message ?? 'Something went wrong. Please try later');
        }
      })
      .catch((err) => {
        setShowLoader(false);
        return err;
      });
  };

  return (
    <>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}

      <div className="register_section">
        <div className="register_section_overlay">
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              md={12}
              lg={{ span: 6, order: 1 }}
              className="p-0 text-center"
            >
              <div className="signup_image_div">
                <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                  <Image src={leftBannerImage} alt="" className="bg_image" />
                </a>
                <div className="text-div">
                  <h4>Lead Generation.</h4>
                  <h4>Made Easy.</h4>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={12}
              lg={{ span: 6, order: 2 }}
              className="mb-5"
            >
              <div className="main-form2">
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={10}
                    className="mx-auto text-center mt-5"
                  >
                    <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                      <Image
                        src={require("./../../assets/img/logo.png")}
                        alt="logo"
                        className="register_logo"
                      />
                    </a>

                    <p className="mt-5 mb-4">
                      <Image src={goodCheckSign} alt="" width={90} />
                    </p>

                    {successMsg ? <p className=" alert-success py-2">{successMsg}</p> : ""}
                    {errorMsg ? <p className=" alert-danger py-2">{errorMsg}</p> : ""}

                    <h2 className="register_title text-center">
                      Please Verify Your Email
                    </h2>

                    <p className="register_des text-center py-3 thankyou_text">
                      You're almost there! We sent an email to: <br />
                      <strong>{userEmail}</strong>
                    </p>
                  </Col>
                </Row>

                <Row className="">
                  <Col>
                    <p className="text-center px-5 thankyou_text">
                      Just click on the link in that email to complete your sign
                      up. If <br /> you don’t see it you may need to check your
                      spam folder.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center thankyou_text">
                    <p className="text-center mt-3">
                      {" "}
                      Still don't see it? No problem.
                    </p>
                    <Button className=" mb-4 mt-3" onClick={handleResendVerificationBtn}>Resend Verification</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-center thankyou_text">
                      {" "}
                      If you have any questions, please email us at <br />
                      <a href="mailto:support@skipify.ai">
                        {" "}
                        support@skipify.ai
                      </a>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <FooterFront /> */}
    </>
  );
};

export default ThankYouPage;
