import React from 'react'
import { Image } from 'react-bootstrap'

const index = () => {
  return (
    <div className='underMantanence'>
      <div className='overlay'>
        <div className='overlay1'>
          <div className='overlay2'></div>
        </div>
      </div>
      <div className='text_section'>
        <Image src={require('./../assets/img/logo.png')} />
        <h3>We Are Under Routine Maintenance</h3>
        <p>Sorry for the inconvenience. We will be live shortly.</p>
      </div>

    </div>
  )
}

export default index