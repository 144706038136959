import { BiPlusMedical } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal, Button, Image, Form } from "react-bootstrap";
import Select from "react-select"; 
import { createActiveAddSkip } from "../../store/slices/features/app";
import moment from "moment";
import { CancelAccount } from "../../Utils/clients.util";

import closeIcon from "./../../assets/img/close.png"
import { CancellationReasonConstant } from "../../Constants/cancellation-reasons.constant";

const DeleteModal = (props) => { 
  const dispatch = useDispatch();
  const [deletePopupStatus, setDeletePopupStatus] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [deleteStep, setDeleteStep] = useState(1);
  const [reasonSelected, setReasonSelected] = useState("");
  const [otherReasonText, setOtherReasonText] = useState("");
  const [showOtherReasonTextbox, setShowOtherReasonTextbox] = useState(false); 
  const [otherTextError, setOtherTextError] = useState(''); 

  const [objectData, setObjectData] = useState({
      Heading:<>We’re sad to see you go!</>,
      SubHeading:<></>,
      Paragraph:<>We’re always striving to improve Skipify.ai and your feedback is incredibly valuable to us.<br></br><br></br>Before you go, could you please let us know why you’re cancelling your account? This helps us improve and better serve our customers in the future.</>
  }); 
 

  const onReasonChange = (reason) => {
    setDisableButton(false);
    if(reason.value == "other") {
      setShowOtherReasonTextbox(true);
    } else {
      setShowOtherReasonTextbox(false);
    }
    setReasonSelected(reason.value);
  };

  function popupContentUpdate(x){
    if(x==1){
      setObjectData({
        Heading:<>We’re sad to see you go!</>,
        SubHeading:<></>,
        Paragraph:<>We’re always striving to improve Skipify.ai and your feedback is incredibly valuable to us.<br></br><br></br>Before you go, could you please let us know why you’re cancelling your account? This helps us improve and better serve our customers in the future.</>
      })
    }
    if(x==2){
      setObjectData({
        Heading:<>Wait!</>,
        SubHeading:<>What if we could help refresh your results?</>,
        Paragraph:<>Instead of canceling, why not try Skipify.ai’s Dynamic Data Boost? <br></br><br></br>For the next 30 days, you can rotate your lists with new, fresh data to optimize your leads without losing access to your account and features. This might be the boost you need to see better results!</>
      })
    }
    if(x==3){
      setObjectData({
        Heading:<>Are you sure?</>,
        SubHeading:<>You may lose your valuable data.</>,
        Paragraph:<>If you cancel your account, you’ll lose access to your stored lists and all the data you’ve worked hard to gather.<br></br><br></br>But, you can keep your lists safely stored by opting for our list storage service for a small monthly fee.<br></br>This way, you can keep all your data intact without maintaining a full subscription.</>
      })
    }
    if(x==4){
      setObjectData({
        Heading:<>Cancel Your Plan Renewal</>,
        SubHeading:<></>,
        Paragraph:<>Your plan will be active until <b>{moment(props.dateExp).format(
          "MMMM DD, YYYY"
        )}.</b><br></br><br></br>After this date, you will no longer have access to your list storage or remaining skips unless you purchase additional storage or upgrade your account.<br></br><br></br>Don’t risk losing your valuable data—consider keeping your storage or upgrading to continue using Skipify.ai’s powerful features.</>
      })
    }
    if(x==5){
      setObjectData({
        Heading:<>Your Plan Renewal Has Been Canceled</>,
        SubHeading:<></>,
        Paragraph:<>Your plan will remain active until <b>{moment(props.dateExp).format(
          "MMMM DD, YYYY"
        )}.</b><br></br><br></br>After that, you will lose access to your lists and remaining skips unless additional storage or an upgrade is purchased.<br></br><br></br>If you have any questions or need help, feel free to contact us at <a href='mailto:support@skipify.ai'>support@skipify.ai</a><br></br><br></br>Thank you for being part of Skipify.ai. We hope to serve you again in the future!</>
      })
    }

  }

  
  // handle Add Skips
  const handleSkipRedirection = (index) => {
    dispatch(createActiveAddSkip(index));
  };

  function processDeletion() {
    if(deleteStep == 1 && reasonSelected == 'other' && !otherReasonText?.trim()) {
      setOtherTextError('Please type the other reason.')
      return;
    }
    let count = deleteStep+1;
    setDeleteStep(count);
    popupContentUpdate(count);
  }

  function retainAccount(){
    setShowLoader(false);
    setErrorMsg("");
    setShowOtherReasonTextbox(false);
    props.modalStatus(false);
    if(deleteStep > 4) {
      window.location.reload();
    }
    setTimeout(function(){
      setDeleteStep(1);
    },500)
    
  }

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  // Cancel Account Request
  const handleCancelAccountButton = async () => {
    
    setErrorMsg('');
    setSuccessMsg('');
       
    setShowLoader(true);
    
    let cancellationReason = reasonSelected;
    if(reasonSelected == 'other') {
      cancellationReason= `Other: ${otherReasonText}`;
    }

      return CancelAccount(cancellationReason)
        .then((response) => {
          if (response?.statusCode == 200 && response?.result) {
            setSuccessMsg(
              `Your request to cancel your account has been received. Your plan will be active until <b>${moment(props.dateExp).format(
                "MMMM DD, YYYY"
              )}   `
            );
            processDeletion();
          } else {
            setErrorMsg('Error submitting the request. Please try later.')
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
          setErrorMsg(error?.message);
        });
    };

  useEffect(()=>{
    popupContentUpdate(deleteStep);
  },[deleteStep])
  

  return <Modal
    show={props.showStatus}
    centered
    className="glassModel billing-modal"
    >
    <Modal.Body> 
        <Row className="d-flex justify-content-end mb-2">
        <Col md={1} className="sm-hide" >
            <Image className="close-icon" src={closeIcon} onClick={(e)=>{retainAccount()}}></Image>
        </Col>
        </Row>
        
        <Row className="d-flex justify-content-start mb-2">
        <Col md={12} >
            <h3 className="mb-4">{objectData.Heading}</h3>
            {
                objectData.SubHeading != "" ? <>
                    <h5 className="mb-4">{objectData.SubHeading}</h5>
                </> : <></>
            }
            <p className="mb-4">{objectData.Paragraph}</p>

            {deleteStep==1 ? <>
              <Row>
                  <Col sm={12}>
                  <Form.Group className="mb-3">
                      <Form.Label>Why are you canceling your account? </Form.Label>
                      <Select
                      options={CancellationReasonConstant}
                      // styles={React_Select_Style}
                      name="cancelReason"
                      onChange={onReasonChange}
                      />
                  </Form.Group>  
                  {showOtherReasonTextbox ? (
                        <Form.Group className="mb-3 mt-3">
                        <Form.Label> Other Reason </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Type here"
                          onChange={(e) => setOtherReasonText(e.target.value)}
                          maxLength={200}
                        />
                         {otherTextError ? <p className="text-danger">{otherTextError}</p> : ""}
                      </Form.Group>
                  ) : ''}
                  </Col>
              </Row>
            </> : <></>}
        </Col>
        
        { errorMsg!="" ? <>
          <Col md={12}>
              <p className="text-danger py-0">{errorMsg}</p>
          </Col>
        </> : <></>}
        
        <Col md={12}>
            <Row className="justify-content-end actions-modal mt-3">
              {deleteStep==1 ? <>
                <Col>
                  <Button 
                      variant={disableButton == false ? " btn text-white bg-danger float-end hover-btn" : "disabled btn text-white bg-danger float-end"}
                      disabled={disableButton == false ? "" : "disabled"}
                      onClick={(e)=>{processDeletion()}}
                  >
                      Cancel Plan
                  </Button>
                  <Button
                      variant=" btn text-black float-end hover-btn"
                      onClick={(e) => {retainAccount()}}

                  >
                      Keep Account
                  </Button>
                </Col> 
              </> : <></>}

              {deleteStep==2 ? <>
                <Col>
                  <Button 
                      variant="btn text-white bg-danger float-end  hover-btn"
                      onClick={(e)=>{processDeletion()}}
                  >
                      Cancel Plan
                  </Button>
                  <Button
                      variant=" btn text-black float-end hover-btn"
                      onClick={(e) => {retainAccount();handleSkipRedirection(4)}}

                  >
                      Rotate My List
                  </Button>
                </Col> 
              </> : <></>}

              {deleteStep==3 ? <>
                <Col>
                  <Button 
                      variant={disableButton == false ? " btn text-white bg-danger float-end hover-btn" : "disabled btn text-white bg-danger float-end"}
                      disabled={disableButton == false ? "" : "disabled"}
                      onClick={(e)=>{processDeletion()}}
                  >
                      Cancel Plan
                  </Button>
                  <Button
                      variant=" btn text-black float-end hover-btn"
                      onClick={(e) => {retainAccount();handleSkipRedirection(5)}}

                  >
                      Add List Storage
                  </Button>
                </Col> 
              </> : <></>}
 

              {deleteStep==4 ? <>
                <Col>
                  {showLoader==true ? <>
                    <div className="loader-side">
                      <div className="loader"></div>
                    </div>
                      </> : <> </>}

                  <Button 
                      variant={showLoader == false ? " btn text-white bg-danger float-end hover-btn" : "disabled btn text-white bg-danger float-end"}
                      disabled={showLoader == false ? "" : "disabled"}
                      onClick={(e)=>{retainAccount()}}
                  >
                    Never Mind
                  </Button>
                  <Button
                      variant=" btn text-black float-end hover-btn bg-prim"
                      onClick={(e) => {handleCancelAccountButton();}}

                  >
                    Cancel Plan Renewal
                  </Button>
                </Col> 
              </> : <></>}

              {deleteStep==5 ? <>
                <Col>
                  <Button
                      variant=" btn text-black float-end hover-btn"
                      onClick={(e) => {retainAccount()}}

                  >
                      Return to Dashboard
                  </Button>
                </Col> 
              </> : <></>}

            </Row>
        </Col>
        </Row>
        
    </Modal.Body>
    </Modal>
};

export default DeleteModal;
