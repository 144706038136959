import React, { useState } from "react";
import DashbaordLayout from "./DashbaordLayout";
import InvoiceCom from "./invoicedetail";

const Invoice = (props) => {


  document.title = "Invoices - Skipify.ai";
  const [showLoader, setShowLoader] = useState(false);
  const pageTitle = "Invoices List";

  return (
    <div>

      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex" id="wrapper">

        <DashbaordLayout title={pageTitle}>
          <div id="page-content-wrapper">

            <InvoiceCom />
          </div>
        </DashbaordLayout>
      </div>
    </div>
  );
};

export default Invoice;
