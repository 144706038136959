import React, { useState, useEffect } from "react";
import DashbaordLayout from "../DashbaordLayout";
import AffiliateGrossCommissionWidgets from "./AffiliateGrossCommissionWidgets";
import AffiliateRevenue from "./AffiliateRevenue";
import AffiliateReferralActivity from "./AffiliateReferralActivity";
import AffiliatePayStatements from "./AffiliatePayStatements";
import AffiliateFaqs from "./AffiliateFaqs";
import AffiliateLinkAndPayEmail from "./AffiliateLinkAndPayEmail";
import { AffiliateGetDetails } from "../../Utils/affiliate.util";
import AffiliateJoinProgram from "./AffiliateJoinProgram";
import { Col, Row, Button, Card, Form, InputGroup } from "react-bootstrap";
import AffiliateDuePayments from "./AffiliateDuePayments";

const AffiliateProgram = () => {
  const pageTitle = "Affiliate Program";
  document.title = "Affiliate Program - Skipify.ai";
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [affiliateDetails, setAffiliateDetails] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    AffiliateGetDetails().then((response) => {
      if (response.statusCode == 200) {
        setAffiliateDetails(response.result);
      } else {
        setErrorMsg(
          "Unable to get the data. Please check your internet connection and reload the page."
        );
      }
      setShowLoader(false);
    });
  }, []);

  return (
    <DashbaordLayout title={pageTitle}>
      <div id="page-content-wrapper" className="affiliate-cst">
        {showLoader ? (
          <div className="loaderBlocker">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            {affiliateDetails ? (
              <>
                {errorMsg ? (
                  <p className="alert alert-danger">{errorMsg}</p>
                ) : (
                  ""
                )}
                {successMsg ? (
                  <p className="alert alert-success">{successMsg}</p>
                ) : (
                  ""
                )}

                {affiliateDetails.status == "active" ? (
                  <>

                    <AffiliateGrossCommissionWidgets />

                    <AffiliateLinkAndPayEmail
                      affiliateInfo={affiliateDetails}
                    />

                    <AffiliateRevenue />

                    <AffiliateReferralActivity />

                    {/* <AffiliatePayStatements /> */}

                    <AffiliateDuePayments />
                  </>
                ) : (
                  ""
                )}

                {affiliateDetails.status == "pending" &&
                  <>
                    <Row>
                      <Col md={12} className="my-4">
                        <Card className="affiliate_card">
                          <Card.Body>
                            <h2 className="mb-4">Affiliate Program</h2>
                            <p>
                              Thank you for submitting a request to join our
                              affiliate program. We have received your request,
                              and your details are now under review. We will
                              notify you once the processing is complete. <br />
                              If you have any questions or would like further
                              clarification, please don't hesitate to reach out
                              to us{" "}
                              <a
                                href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/contact-us`}
                                target="_blank"
                              >
                                Contact Us
                              </a>
                              .
                              <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`} className="mailto2">Skipify.ai</a>
                            </p>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </>
                }
                    <AffiliateFaqs />

                {affiliateDetails.status == "declined" ? (
                  <>
                    <Row>
                      <Col md={12} className="my-4">
                        <Card className="affiliate_card">
                          <Card.Body>
                            <h2 className="mb-4">Affiliate Program</h2>
                            <p>
                              Thank you for submitting a request to join our
                              affiliate program. We regret to inform you that
                              your request to join the affiliate program has
                              been declined. If you have any questions or would
                              like further clarification, please don't hesitate
                              to reach out to us. We appreciate your interest
                              and hope to have the opportunity to work with you
                              in the future{" "}
                              <a
                                href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/contact-us`}
                                target="_blank"
                              >
                                Contact Us
                              </a>
                              .
                            </p>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <AffiliateFaqs />
                  </>
                ) : (
                  ""
                )}
                {affiliateDetails.status == "suspended" ? (
                  <>
                    <Row>
                      <Col md={12} className="my-4">
                        <Card className="affiliate_card">
                          <Card.Body>
                            <h2 className="mb-4">Affiliate Program</h2>
                            <p>
                              Your affiliate program has been suspended by the
                              Skipify Admin. If you have any questions or would
                              like further clarification, please don't hesitate
                              to reach out to us{" "}
                              <a
                                href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/contact-us`}
                                target="_blank"
                              >
                                Contact Us
                              </a>
                              .
                            </p>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <AffiliateFaqs />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <AffiliateJoinProgram />
                <AffiliateFaqs />
              </>
            )}
          </>
        )}
      </div>
    </DashbaordLayout>
  );
};

export default AffiliateProgram;
