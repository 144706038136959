import React, { useEffect, useState } from "react";
import { SKIPTRACE_FILE_UPLOAD_ENDPOINT } from "../../Constants/api-routes.constant";
import { GetAuthToken } from "../../Utils/auth-verification.util";
// import { STRIPE_SK } from "../../Constants/api-credentials.constant";
import { Col, Row } from "react-bootstrap";
import { BsFillExclamationCircleFill } from "react-icons/bs";
const FileUploadStep3 = (props) => {
  const amountDue = props.amountDue;
  const fieldMappings = props.fieldMappings;
  const fileUploadData = props.fileUploadData;
  const fileUploadSampleData = props.fileUploadSampleData;

  // props.ccEmail
  // props.fileUpload
  // props.fileUploadData
  // props.handleStep3Call
  // props.dncStatus
  // props.ageMin
  // props.ageMax
  // props.relativeContacts
  // props.mostLikelyGender

  const [paymentIntentData, setPaymentIntentData] = useState("");
  const [stripClientSecret, setStripeClientSecret] = useState("");
  const [paymentStatusFlag, setPaymentStatusFlag] = useState(false); // true = paid, false = unpaid
  const [orderNumber, setOrderNumber] = useState("");
  const [orderId, setOrderId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // Create
  const handleStep3Submit = async () => {
    if (amountDue > 0) {
      const amountDueCent = (Number(amountDue) * 100).toFixed(2);
      const paymentIntent = {};
      uploadFileToServer(paymentIntent);
    } else {
      uploadFileToServer(null);
    }
  };

  // Upload file to server
  const uploadFileToServer = (intentData) => {
    setShowLoader(true);
    // Upload file to server
    const token = GetAuthToken();
    const formData = new FormData();
    formData.append("firstName", fieldMappings.firstName);
    formData.append("lastName", fieldMappings.lastName);
    formData.append("propertyAddress", fieldMappings.propertyAddress);
    formData.append("propertyCity", fieldMappings.propertyCity);
    formData.append("propertyState", fieldMappings.propertyState);
    formData.append("propertyZip", fieldMappings.propertyZip);
    formData.append("apn", fieldMappings.apn);
    formData.append("mailingAddress", fieldMappings.mailingAddress);
    formData.append("mailingCity", fieldMappings.mailingCity);
    formData.append("mailingState", fieldMappings.mailingState);
    formData.append("mailingZip", fieldMappings.mailingZip);
    formData.append("customField1", fieldMappings.customField1);
    formData.append("customField2", fieldMappings.customField2);
    formData.append("ccEmail", props.ccEmail);
    formData.append("fileupload", props.fileUpload);
    formData.append("fileName", props.fileUpload?.name);
    formData.append("dncCheck", props.dncCheck);
    formData.append("ageMin", props.ageMin);
    formData.append("ageMax", props.ageMax);
    formData.append("relativeContactCheck", props.relativeContacts);
    formData.append("likelyGender", props.mostLikelyGender);
    fetch(SKIPTRACE_FILE_UPLOAD_ENDPOINT, {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`, // notice the Bearer before your token,
        // 'Content-Type': 'multipart/form-data'
      },
      body: formData,
    })
      .then((rawRes) => rawRes.json())
      .then((response) => {
        if (response.statusCode == 200) {
          setOrderId(response.result.orderId);
          setOrderNumber(response.result.orderNumber);
          // Send to Main Component
          props.handleStep3Call(
            response.result.orderId,
            response.result.orderNumber,
            intentData,
            intentData?.client_secret,
            true
          );
          setShowLoader(false);
        } else {
          if (response?.message) {
            setErrorMsg(response.message);
          } else {
            setErrorMsg(
              "Failed to upload the file to server. Please try later."
            );
          }
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error?.message);
        setShowLoader(false);
      });
  };

  return (
    <>
    {showLoader && (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      )}
      <div className="row mt-4">
        <div className="col-md-6">
          <h4>Confirm Mappings</h4>
        </div>
      </div>
      {errorMsg ? (
        <div className="alert alert-danger">
          <h6 style={{ color: "#0f5132 !important", fontSize: "0.8rem" }}> <BsFillExclamationCircleFill /> {errorMsg}</h6>
        </div>
      ) : (
        ""
      )}
      <div className="row">
        <div className="col-md-12">
          <p className="showRecordText">showing <span>{fileUploadData.length > 10 ? "10" : fileUploadData.length - 1}</span> of <span>{fileUploadData.length - 1}</span> Records</p>

          <div className="table-data-main resp-table2">
            <table className="table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Property Address</th>
                  <th>Property City</th>
                  <th>Property State</th>
                  <th>Property Zip</th>
                  <th>Property APN</th>
                  <th>Mailing Address</th>
                  <th>Mailing City</th>
                  <th>Mailing State</th>
                  <th>Mailing Zip</th>
                  <th>Custom Field 1</th>
                  <th>Custom Field 2</th>
                </tr>
              </thead>
              <tbody>
                {fileUploadSampleData.length &&
                  fileUploadSampleData.map((record, index) => (
                    <tr key={index}>
                      <td>{record.firstName}</td>
                      <td>{record.lastName}</td>
                      <td>{record.propertyAddress}</td>
                      <td>{record.propertyCity}</td>
                      <td>{record.propertyState}</td>
                      <td>{record.propertyZip}</td>
                      <td>{record.apn}</td>
                      <td>{record.mailingAddress}</td>
                      <td>{record.mailingCity}</td>
                      <td>{record.mailingState}</td>
                      <td>{record.mailingZip}</td>
                      <td>{record.customField1}</td>
                      <td>{record.customField2}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>

      <Row>
        <Col xs={6} sm={6} md={4}>
          <button
            type="button"
            className="btn btn-primary ml-3 px-5 py-2"
            onClick={() => props.setStepName(2)}
          >
            Back
          </button>
        </Col>
        <Col xs={6} sm={6} md={8} style={{ textAlign: "right" }}>
          <button
            type="button"
            className="btn btn-primary px-5 py-2"
            onClick={handleStep3Submit}
          >
            Next
          </button>
        </Col>
      </Row>

    </>
  );
};

export default FileUploadStep3;
