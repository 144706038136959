import { GetAuthToken } from "./auth-verification.util";
import {
  SKIPTRACE_PLACE_ORDER_API_ENDPOINT,
  PAYMENT_SUCCESS_API_ENDPOINT,
  SKIPTRACE_DELETE_ORDER_ENDPOINT,
} from "../Constants/api-routes.constant";

export const OrderPaymentSuccess = async (
  paymentStatusFlag,
  orderId,
  amountDue,
  paymentStatus,
  paymentIntentData,
  chargableHits
) => {
  if (paymentStatusFlag) {
    const reqBody = {
      orderId: orderId,
      amountPaid: amountDue,
      paymentStatus: paymentStatus,
      paymentIntentId: paymentIntentData?.id,
      dateCreated: paymentIntentData?.created,
      hitsPurchased: chargableHits,
    };
    const token = GetAuthToken();

    // Store the payment in the DB
    return fetch(PAYMENT_SUCCESS_API_ENDPOINT, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqBody),
    })
      .then((rawResponse) => rawResponse.json())
      .catch((error) => {
        return false;
      });
  } else {
    return false;
  }
};

export const PlaceFileSkiptraceOrder = async (
  orderId,
  skiptraceCount,
  paymentStatus
) => {
  // Place order API
  const request = {
    orderId: orderId,
    skiptraceCount: skiptraceCount,
    paymentStatus: paymentStatus,
  };
  const token = GetAuthToken();
  try {
    return fetch(SKIPTRACE_PLACE_ORDER_API_ENDPOINT, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    })
      .then((rawResponse) => rawResponse.json())
      .catch((error) => {
        return false;
      });
  } catch (error) {
    return false;
  }
};

// Delete order by ID
export const DeleteOrderById = async (orderId) => {
  const request = {
    orderId: orderId
  };
  const token = GetAuthToken();
  try {
    return fetch(SKIPTRACE_DELETE_ORDER_ENDPOINT, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    })
      .then((rawResponse) => rawResponse.json())
      .catch((error) => {
        return false;
      });
  } catch (error) {
    return false;
  }
}