import { Col, Row, Card, Dropdown, Image } from "react-bootstrap";
import DatePicker from "react-datepicker";
import React, { useState, useEffect } from "react";
import { CiCalendarDate } from "react-icons/ci";
import AreaChart from "./AreaChart";
import { AffiliateGetRevenueData } from "../../Utils/affiliate.util";
import * as moment from 'moment';
import growthImage from "./../../assets/new_theme/img/growth.png";

const AffiliateRevenue = () => {
  // Date Filter
  const [startDateFilter, setStartDateFilter] = useState(moment().startOf('month').toDate());
  const [endDateFilter, setEndDateFilter] = useState(moment().endOf('month').toDate());
  const [revenueData, setRevenueData] = useState([]);
  const [totalCommissionAmount, setTotalCommissionAmount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDateChangeFilter = (dates) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
    if(end) setOpen(false);
  };

  useEffect(() => {
    setShowLoader(true);

    let startDate = "";
    let endDate = "";

   if (startDateFilter && endDateFilter) {
      startDate = moment(startDateFilter).format("MM/DD/YYYY");
      endDate = moment(endDateFilter).format("MM/DD/YYYY");
    }

    AffiliateGetRevenueData(startDate, endDate).then((response) => {
      if (response.statusCode == 200) {
        setRevenueData(response?.result?.revenueGraphData);
        if (response?.result?.totalCommissionAmount > -1) {
          const commission = Number(response.result.totalCommissionAmount)
            ?.toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
          setTotalCommissionAmount(commission);
        }
      }
      setShowLoader(false);
    });
  }, [endDateFilter]);

  return (
    <Row className="mb-4">
      <div className="bord-affiliate">
        <Col> 
          <Row className="d-flex justify-content-between mt-4">
              <Col xs={12} md={12} lg={5} xl={4}>
                <h2>Revenue</h2>
              </Col>
              <Col xs={12} md={12} lg={6} xl={5} className="range-cst text-center mb-4">
                <Row>
                  <Col lg={4} md={3} className="align-self-center text-end pe-0">
                    <label>Select Range</label>
                  </Col>
                  <Col>
                    <div className="datePic ms-2"  >
                      <DatePicker
                        selected={startDateFilter}
                        onChange={handleDateChangeFilter}
                        startDate={startDateFilter}
                        endDate={endDateFilter}
                        dateFormat="MM/dd/yyyy"
                        selectsRange
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="form-control dateSelet text-start"
                        placeholderText={`${startDateFilter} - ${endDateFilter}`}
                        open={open}
                        onFocus={() => setOpen(true)}
                      />
                      <CiCalendarDate size={35} onClick={() => setOpen(!open)} onBlur={() => setOpen(false)} />
                    </div>
                  </Col>
                </Row> 
              </Col>

              {/* <Col
                sm={12}
                md={12}
                lg={6}
                xl={4}
                className="d-flex justify-content-between sm-column"
              >
                <div className="datePic">
                  <CiCalendarDate size={35} />
                  <DatePicker
                    selected={startDateFilter}
                    onChange={handleDateChangeFilter}
                    startDate={startDateFilter}
                    endDate={endDateFilter}
                    selectsRange
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control dateSelet"
                    placeholderText="05/01/2024 - 05/31/2024"
                  />
                </div>
              </Col> */}
          </Row>

          {showLoader ? (
            <div className="loader-small"></div>
          ) : (
            <> 
              <div className="chart_card_number mb-4">
                <h3 className="d-flex">${totalCommissionAmount}
                  {/* This is commented code for revenue  */}
                  {/* <span className="revenue-cent">
                    <Image src={growthImage}></Image>
                    <span className="digit">2.5%  </span>
                  </span> */}
                </h3>
              </div>
              <AreaChart data={revenueData} />
            </>
          )} 
        </Col>
      </div>
    </Row>
  );
};

export default AffiliateRevenue;
