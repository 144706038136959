import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import React, { useEffect, useState, useRef } from "react";
import {
  LOGIN_API_ENDPOINT,
  REGLOGIN_API_ENDPOINT,
  REGISTER_API_PAYMENT_CLIENT_ENDPOINT,
} from "../../Constants/api-routes.constant";
import "../../assets/css/main.css";
// import { TopNav } from "../../Essentials/Front/TopNav";
// import { FooterFront } from "../../Essentials/Front/FooterFront";
import {
  EMAIL_ADDRESS_REGEX,
  NEW_PASSWORD_REGEX,
} from "../../Constants/regex.constant";

import leftBannerImage from "../../assets/img/signup_new.png";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Google_reCaptcha_Secret_Key,
  Google_reCaptcha_Site_Key,
} from "../../Constants/api-credentials.constant";
import { Col, Row, Spinner, Form, Image } from "react-bootstrap";
import { BsFillExclamationCircleFill, BsCartFill, BsPersonFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import HubspotTracking from "../HubspotTracking";
import RegisterCheckout from "./RegisterCheckout";
import ConfirmYourBilling from "./ConfirmYourBilling";
import { MetaPixelTrackingCode } from "../MetaPixelCode";

const RegisterPayment = () => {
  useEffect(() => {
    document.title = "Create an Account - Skipify.ai";
    window.scrollTo(0, 0)
    MetaPixelTrackingCode();
  }, []);

  // Check affiliate query string '?ref=anyvValue' and place it into the localstorage
  const authResult = new URLSearchParams(window.location.search);
  const referralQS = authResult.get('ref');
  if (referralQS) {
    localStorage.setItem('affiliate_id', referralQS);
  }

  const recaptchaRef = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [terms, setTerms] = useState(false);
  const [phoneNumberWithMaking, setPhoneNumberWithMaking] = useState();


  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [businessTypeErr, setBusinessTypeErr] = useState("");
  const [confirmPassErr, setConfirmPassErr] = useState("");
  const [termsErr, setTermsErr] = useState("");


  const [formError, setFormError] = useState("");
  const [formSuccessMsg, setFormSuccessMsg] = useState("");

  // Show/hide password on click
  const [showPass, setShowPass] = useState(false)
  const [showConPass, setShowConPass] = useState(false)


  // Password Validation
  const [lower, setLower] = useState(false);
  const [upper, setUpper] = useState(false);
  const [number, setNumber] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [char, setChar] = useState(false);
  const [swithScreen, setSwitchScreen] = useState(2)

  // hanle validation
  const handleValidatoin = (value) => {
    // regex
    const lower = new RegExp('(?=.*[a-z])');
    const upper = new RegExp('(?=.*[A-Z])');
    const number = new RegExp('(?=.*[0-9])');
    const symbol = new RegExp('(?=.*[#$^+=!*()@%&])');
    const length = new RegExp('(?=.{8,})');

    lower.test(value) ? setLower(true) : setLower(false)
    upper.test(value) ? setUpper(true) : setUpper(false)
    number.test(value) ? setNumber(true) : setNumber(false)
    symbol.test(value) ? setSymbol(true) : setSymbol(false)
    length.test(value) ? setChar(true) : setChar(false)

  }

  // Form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    window?.grecaptcha?.reset();
    recaptchaRef.current.reset();

    setFirstNameError("");
    setLastNameError("");
    setUserEmailError("");
    setPasswordError("");
    setPhoneNumberError("");
    setFormError("");
    setFormSuccessMsg("");

    let errorFlag = false;
    if (!firstName?.trim()) {
      setFirstNameError("First Name is required.");
      errorFlag = true;
    } else {
      setFirstNameError("");
    }



    if (!lastName?.trim()) {
      setLastNameError("Last Name is required.");
      errorFlag = true;
    } else {
      setLastNameError("");
    }
    if (!userEmail?.match(EMAIL_ADDRESS_REGEX)) {
      setUserEmailError("Email should be a valid email address.");
      errorFlag = true;
    } else {
      setUserEmailError("");
    }

    if (!password?.trim()) {
      setPasswordError("Password is required.");
      errorFlag = true;
    } else {
      setPasswordError("");
    }
    if (!password.match(NEW_PASSWORD_REGEX)) {
      setPasswordError(
        "Password must contain uppercase letters, special characters, numeric digits with at least 8 characters"
      );
      errorFlag = true;
    }
    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required.");
      errorFlag = true;
    } else {
      setPhoneNumberError("");
    }
    if (phoneNumber.toString().length != 10) {
      setPhoneNumberError("Phone Number should be 10 digits long.");
      errorFlag = true;
    }


    if (!businessType?.trim() || businessType == 0) {
      setBusinessTypeErr("Business Type is required.");
      errorFlag = true;
    } else {
      setBusinessTypeErr("");
    }

    if (!companyName?.trim()) {
      setCompanyNameError("Company Name is required.");
      errorFlag = true;
    } else {
      setCompanyNameError("");
    }

    if (confirmPass?.trim() !== password?.trim()) {
      setConfirmPassErr("Password don't Match.");
      errorFlag = true;
    } else {
      setConfirmPassErr("");
    }

    if (!terms) {
      setTermsErr("Please Accept the Terms and Conditions in Order to Proceed");
      errorFlag = true;
    } else {
      setTermsErr("");
    }

    if (errorFlag) {
      return;
    }

    const token = await recaptchaRef.current.executeAsync();

    if (!token) {
      setFormError(`Error: Invalid captcha attempt.`);
      return;
    }



    // Post data to Registration API
    if (errorFlag === false) {
      setShowLoader(true);
      const affiliateId = localStorage.getItem('affiliate_id');

      fetch(REGISTER_API_PAYMENT_CLIENT_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          password,
          firstName,
          lastName,
          businessType,
          phoneNumber: phoneNumber.toString(),
          companyName,
          affiliateId
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response?.statusCode == "200") {
            const msg = <>An activation email has been sent to your registered email address. Just click the link in the email to activate your account. If you have any questions, please email us at <a className="mail" href="mailto:support@skipify.ai">support@skipify.ai</a></>
            setFormSuccessMsg(msg);
            localStorage.removeItem('affiliate_id');


            /***** ***** ***** ***** *****/
            /* Getting Auth Token - Begin
            /***** ***** ***** ***** *****/
            if (userEmail?.trim() && password?.trim()) {
              setShowLoader(true);
              fetch(REGLOGIN_API_ENDPOINT, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  username: userEmail,
                  password: password,
                }),
              })
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  setFormError("Error: Invalid Username or Password provided.");
                })
                .then((response) => {
                  setShowLoader(false);
                  if (response?.access_token && response?.token_expiry) {

                    localStorage.setItem("skipifyAuthToken", response.access_token);
                    //localStorage.setItem("skipifyTokenExpiry", response.token_expiry);
                    localStorage.setItem("skipifyUserFullName", response?.firstName + " " + response?.lastName);
                    localStorage.setItem("skipifyUserEmail", response?.email);

                    setFormError("");

                    setSwitchScreen(1);
                  } else {
                    setFormError("Error: Invalid Username or Password provided.");
                  }
                })
                .catch((e) => {
                  setFormError("Error: Invalid Username or Password provided.");
                  setShowLoader(false);
                });
            }
            /***** ***** ***** ***** *****/
            /* Getting Auth Token - End
            /***** ***** ***** ***** *****/




            setFirstName("");
            setLastName("");
            setUserEmail("");
            setPassword("");
            setCompanyName("");
            setPhoneNumber("");
            setConfirmPass("")
            setPhoneNumberWithMaking("");
            setBusinessType('')
          } else if (response?.statusCode == "400") {
            setFormError(response?.message);
          } else {
            setFormError(response?.message);
          }
          setShowLoader(false);
        })
        .catch((e) => {
          setFormError(e?.message);
          setShowLoader(false);
        });
    }
  };



  // Function to remove the Masking from phone Number
  const NormalizePhoneNumber = (phone) => {
    const numericOnly = phone.replace(/\D/g, '');
    let maskedNumber = '';
    if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
    }
    if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
    }
    if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
    }
    setPhoneNumber(numericOnly.slice(0, 10));
    setPhoneNumberWithMaking(maskedNumber);  // saving value for input to the previous value in input

  }



  useEffect(() => {
    localStorage.setItem('skipifyAuthToken', '');
    window.scrollTo(0, 0);
    //if(localStorage.getItem('skipifyAuthToken')) setSwitchScreen(1)
  }, []);



  return (
    <>
      <HubspotTracking />
      {/* <TopNav /> */}
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}


      <div className="register_section">
        <div className="register_section_overlay">

          <Row>
            <Col sm={6}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={Google_reCaptcha_Site_Key}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={12} lg={{ span: 6, order: 1 }} className="p-0 text-center">
              <div className="signup_image_div">
                <Image src={leftBannerImage} alt="" className="bg_image" />
                <div className="text-div">
                  <h4>Lead Generation.</h4>
                  <h4> Made Easy.</h4>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={12} lg={{ span: 6, order: 2 }} className="p-0 mx-auto">
              <Row>
                <Col md={11} className="mx-auto text-center mt-5">

                  <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                    <Image src={require('./../../assets/img/logo.png')} alt="logo" className="register_logo" />
                  </a>

                </Col>
              </Row>

              {
                swithScreen == 1 ?
                  <RegisterCheckout setSwitchScreen={setSwitchScreen} />
                  :
                  <>
                    <Row>
                      <Col md={12}>
                        <h2 className="register_title">Create Your Account</h2>
                        <p className="register_des">Fill out the information below to create your account.</p>
                      </Col>
                    </Row>

                    <form onSubmit={handleSubmit} method="post" id="skipify_registration_form">
                      <div className="main-form2 mt-4">

                        {formError ? (
                          <div className="alert alert-danger mt-2">
                            <h6 style={{ color: "#0f5132 !important", fontSize: "0.8rem" }}> <BsFillExclamationCircleFill /> {formError}</h6>
                          </div>

                        ) : (
                          ""
                        )}

                        <Row>
                          <Col md={11} className="mx-auto">
                            {formSuccessMsg &&
                              <div className="alert alert-success mt-2">
                                <h6>Thank you for registering with us.</h6>
                                <h6 style={{ color: "#0f5132 !important", fontSize: "0.8rem" }}>{formSuccessMsg}</h6>
                              </div>
                            }
                            <Row>
                              <Col sm={6}>
                                <Form.Group className="mb-3" >
                                  <Form.Label>First Name *</Form.Label>
                                  <Form.Control type="text"
                                    onChange={(e) => { setFirstName(e?.target?.value?.slice(0, 25)); setFirstNameError('') }}
                                    name="firstName"
                                    value={firstName}
                                    className="form-control"
                                    id="first_name"
                                  />
                                  {firstNameError ? <p className="text-danger"><BsFillExclamationCircleFill /> {firstNameError}</p> : ""}
                                </Form.Group>

                              </Col>
                              <Col sm={6}>
                                <Form.Group className="mb-3" >
                                  <Form.Label>Last Name *</Form.Label>
                                  <Form.Control type="text"
                                    onChange={(e) => { setLastName(e?.target?.value?.slice(0, 25)); setLastNameError('') }}
                                    name="lastName"
                                    value={lastName}
                                    id="last_name"
                                  />
                                  {lastNameError ? <p className="text-danger"><BsFillExclamationCircleFill /> {lastNameError}</p> : ""}
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={6}>
                                <Form.Group className="mb-3" >
                                  <Form.Label>Email *</Form.Label>

                                  <Form.Control type="email"
                                    onChange={(e) => { setUserEmail(e.target.value); setUserEmailError("") }}
                                    name="email"
                                    value={userEmail}
                                    autoComplete="new-email"
                                    id="email"
                                  />
                                  {userEmailError ? <p className="text-danger"><BsFillExclamationCircleFill /> {userEmailError}</p> : ""}
                                </Form.Group>

                              </Col>

                              <Col sm={6}>
                                <Form.Group className="mb-3" >
                                  <Form.Label>Company Name *</Form.Label>
                                  <Form.Control type="text"
                                    onChange={(e) => { setCompanyName(e.target.value); setCompanyNameError('') }}
                                    name="company"
                                    value={companyName}
                                    id="company_name"
                                  />
                                  {companyNameError ? <p className="text-danger"><BsFillExclamationCircleFill /> {companyNameError}</p> : ""}
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={6}>
                                <Form.Group className="mb-3" >
                                  <Form.Label>Business Type *</Form.Label>
                                  <Form.Select aria-label="Select Business Type" value={businessType} onChange={(e) => { setBusinessType(e.target.value); setBusinessTypeErr("") }} id="industry">
                                    <option value="0">Select Business Type </option>
                                    <option value="Real Estate Investor">Real Estate Investor</option>
                                    <option value="Real Estate Wholesaling">Real Estate Wholesaling</option>
                                    <option value="Real Estate Agent/Broker">Real Estate Agent/Broker</option>
                                    <option value="Property Lending">Property Lending</option>
                                    <option value="Property Vendor/Contractor">Property Vendor/Contractor</option>
                                    <option value="Property Technology/Service">Property Technology/Service</option>
                                    <option value="Title Company">Title Company</option>
                                  </Form.Select>
                                  {businessTypeErr ? <p className="text-danger"><BsFillExclamationCircleFill /> {businessTypeErr}</p> : ""}
                                </Form.Group>
                              </Col>

                              <Col sm={6}>
                                <Form.Group className="mb-3" >
                                  <Form.Label>Phone Number *</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); setPhoneNumberError("") }}
                                    value={phoneNumberWithMaking}
                                    id="phone_number"
                                  />
                                  {phoneNumberError ? <p className="text-danger"><BsFillExclamationCircleFill /> {phoneNumberError}</p> : ""}
                                </Form.Group>
                              </Col>

                            </Row>

                            <Row>
                              <Col sm={6}>
                                <Form.Group className="mb-3 password">
                                  <Form.Label>Password *</Form.Label>
                                  <Form.Control
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                      setPasswordError("");
                                      handleValidatoin(e.target.value)
                                    }}
                                    type={showPass ? 'text' : 'password'}
                                    name="password"
                                    id="password"
                                    autoComplete="new-password"
                                    value={password}
                                  />
                                  {showPass ? <AiFillEye className="show_password" onClick={() => setShowPass(!showPass)} /> : <AiFillEyeInvisible className="show_password" onClick={() => setShowPass(!showPass)} />}
                                </Form.Group>
                                {passwordError ? <p className="text-danger"><BsFillExclamationCircleFill /> {passwordError}</p> : ""}

                              </Col>
                              <Col sm={6}>

                                <Form.Group className="mb-3 password">
                                  <Form.Label>Confirm Password *</Form.Label>
                                  <Form.Control
                                    onChange={(e) => { setConfirmPass(e.target.value); setConfirmPassErr("") }}
                                    type={showConPass ? 'text' : 'password'}
                                    id="password"
                                    value={confirmPass}
                                  />
                                  {showConPass ? <AiFillEye className="show_password" onClick={() => setShowConPass(!showConPass)} /> : <AiFillEyeInvisible className="show_password" onClick={() => setShowConPass(!showConPass)} />}
                                </Form.Group>
                                {confirmPassErr ? <p className="text-danger"><BsFillExclamationCircleFill /> {confirmPassErr}</p> : ""}

                              </Col>

                            </Row>

                            <Row>
                              <Col md={12}>
                                <h4 className="validation_heading">Your password must include:</h4>
                                <ul className="validation_list">
                                  <li className={upper ? 'active' : ''}><h5>A</h5><span>Uppercase</span></li>
                                  <li className={lower ? 'active' : ''}><h5>a</h5><span>Lowercase</span></li>
                                  <li className={symbol ? 'active' : ''}><h5>%</h5><span>Symbol</span></li>
                                  <li className={number ? 'active' : ''}><h5>123</h5><span>Number</span></li>
                                  <li className={char ? 'active' : ''}><h5>8+</h5><span>Characters</span></li>
                                </ul>
                              </Col>
                            </Row>


                            <Row>
                              <Col xs={12} sm={12} md={12} lg={8} xl={6} className="mt-3 mx-auto">
                                <Form.Check
                                  type={"checkbox"}
                                  checked={terms ? true : false}
                                  onChange={() => { setTerms(!terms); setTermsErr('') }}
                                  label={<p className="terms_p">I accept <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/terms`}>Terms and Conditions</a></p>}
                                />
                                {termsErr ? <span className="text-danger"><BsFillExclamationCircleFill /> {termsErr}</span> : ""}
                                {showLoader ?
                                  <button type="button" className="submit"> <Spinner animation="border" size="sm" /></button>
                                  :
                                  <button type="submit" className="submit">Next</button>
                                }
                                <p className="terms_p mt-3">Already have an account?  <Link to="/login">Login</Link></p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </>
              }
            </Col>


          </Row>
        </div>
      </div>

      {/* <FooterFront /> */}
    </>
  );
};

export default RegisterPayment;