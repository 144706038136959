import React, { useEffect, useState, useRef } from "react";
import { EMAIL_ADDRESS_REGEX } from "../../Constants/regex.constant";
import { Row, Col } from "react-bootstrap";
import CSVReader from 'react-csv-reader';
import { updateCurrentBilling } from '../../store/slices/features/payment-plan';
import { DNC_CHECK_SKIP_PER_HIT } from "../../Constants/common-limit.constant";
import { AgeFilterOptionConstant } from "../../Constants/age_filter_options.constant";
import { Form } from "react-bootstrap";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const FileUploadStep1 = (props) => {

  const UploadFileSizeLimit = 7350000; // 7MB
  const SkiptraceMaxRecordsLimit = Number(process.env.REACT_APP_SKIPTRACE_LIMIT) ?? 25000; 

  //props.handleStep1Call
  const profileData = props.profileData;

  const [ccEmail, setCcEmail] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [ccEmailError, setCcEmailError] = useState("");
  const [fileError, setFileError] = useState();
  const [fileName, setFileName] = useState("");

  const [fileData, setFileData] = useState([]);
  const [fileInfo, setFileInfo] = useState('')
  const [dncCheck, setDncCheck] = useState(false);

  // Demographic
  const [ageMin, setAgeMin] = useState(null)
  const [ageMax, setAgeMax] = useState(null)
  const [relativeContacts, setRelativeContacts] = useState(null)
  const checkbox2Ref = useRef(null)
  const checkbox3Ref = useRef(null)
  const checkbox4Ref = useRef(null)
  const checkbox5Ref = useRef(null)
  const checkbox6Ref = useRef(null)
  const [showAgeFilter, setShowAgeFilter] = useState(false);
  const [likelyGender, setLikelyGender] = useState('');
  const maleCheckboxRef = useRef(null);
  const femaleCheckboxRef = useRef(null);

  useEffect(() => {
    setMainComponentErrorMsg();
  })
  const setMainComponentErrorMsg = () => {
    if (props.fileError && !fileError) {
      setFileError(props.fileError);
    }
  }

  // Step 1 submit
  const handleStep1Submit = () => {
    setCcEmailError("");
    setFileError("");
    let errorFlag = false;
    try {
      if (ccEmail?.length > 0) {
        if (!ccEmail.match(EMAIL_ADDRESS_REGEX)) {
          setCcEmailError("Please enter a valid email address");
          errorFlag = true;
        }
      }
      if (!fileData?.length || !fileInfo) {
        setFileError("Please upload a CSV file.");
        errorFlag = true;
      }

      if (!errorFlag) {
        const allowedExtensions = /(\.csv)$/i;
        setFileName(fileInfo.name);
        if (
          !allowedExtensions.exec(fileInfo.name) ||
          (!fileInfo.type?.includes("csv") && !fileInfo.type?.includes("application/vnd.ms-excel"))
        ) {
          setFileError("Please upload a valid CSV file.");
          errorFlag = true;
        }
      }

      // 7MB file limit
      if (!errorFlag) {
        if (fileInfo.size > UploadFileSizeLimit) {
          setFileError("File size should be less than or equals to 7MB");
          errorFlag = true;
        }
      }

      if (!errorFlag) {
        if (fileData?.length > (SkiptraceMaxRecordsLimit+1)) {
          setFileError(
            `We can not skip trace more than ${(
              SkiptraceMaxRecordsLimit
            ).toLocaleString()} records.`
          );
          return;
        }

        if (fileData?.length && fileData.length - 1 > 0) {
          const demographicFilters = {
            ageMin,
            ageMax,
            relativeContacts,
            likelyGender
          }
          props.handleStep1Call(
            fileInfo.name,
            fileData,
            fileInfo,
            ccEmail,
            true,
            dncCheck, 
            demographicFilters
          ); // File Name, File Data, file, cc Email, isSuccessFullStep
        } else {
          setFileError(
            "The upload file is either empty or contains invalid data."
          );
        }
      }
    } catch (err) {
      setFileError(err);
    }
  };

// Handle Upload CSV data
 const handleFile = (data, fileInfo, iFileInfo, originalFile) => {
  try {
    setFileData(data);
    setFileInfo(iFileInfo);
  } catch(err) {
    setFileError(err);
  }
};

// Handle File upload error 
const handleError = (err, file, inputElem, reason) => {
    setFileError(err);
};

const handleAgeFilterCheckbox = async (event, checkboxNumber) => {
    const selectedCheckBox = AgeFilterOptionConstant[checkboxNumber];
    let minAge = ageMin;
    let maxAge = ageMax;

    if( !event.target.checked ) {

       if(maxAge) {
         
        if(selectedCheckBox.min >= minAge && selectedCheckBox.max < maxAge) {
          minAge = selectedCheckBox.max + 1
          setAgeMin(minAge)
        }

        if(selectedCheckBox.min > minAge && selectedCheckBox.max >= maxAge) {
          maxAge = selectedCheckBox.min - 1
          setAgeMax(maxAge)
        }

        if(selectedCheckBox.min == minAge && selectedCheckBox.max == maxAge) {
          maxAge = null
          minAge = null
          setAgeMax(maxAge); setAgeMin(minAge);
        }
       } 
    }
  
    if (checkboxNumber == 0 || ageMin == null) {
      setAgeMin(selectedCheckBox.min);
      setAgeMax(selectedCheckBox.max);
      minAge = selectedCheckBox.min
      maxAge = selectedCheckBox.max
     // console.log(minAge, maxAge, "1")
    }

    // // Allow to select only consective checkboxes
    if (minAge > 1 && 
      ( Math.abs(ageMax - selectedCheckBox.min) == 1 ||
      Math.abs(ageMin - selectedCheckBox.max) == 1 )
    ) {
      if (ageMin > selectedCheckBox.min) {
        setAgeMin(selectedCheckBox.min);
        minAge = selectedCheckBox.min
      }
      if (ageMax < selectedCheckBox.max) {
        setAgeMax(selectedCheckBox.max);
        maxAge = selectedCheckBox.max
      }
     // console.log(minAge, maxAge, "2")
    }

    handleChecked(minAge, maxAge);   
    
  };

  const handleChecked = (minAge, maxAge) => {
    if(AgeFilterOptionConstant[1].min < minAge || AgeFilterOptionConstant[1].max > maxAge ) {
      if (checkbox2Ref.current)  checkbox2Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[2].min < minAge || AgeFilterOptionConstant[2].max > maxAge ) {
      if (checkbox3Ref.current)  checkbox3Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[3].min < minAge || AgeFilterOptionConstant[3].max > maxAge ) {
      if (checkbox4Ref.current)  checkbox4Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[4].min < minAge || AgeFilterOptionConstant[4].max > maxAge ) {
      if (checkbox5Ref.current)  checkbox5Ref.current.checked = false;
    }
    if(AgeFilterOptionConstant[5].min < minAge || AgeFilterOptionConstant[5].max > maxAge ) {
      if (checkbox6Ref.current)  checkbox6Ref.current.checked = false;
    }
  }

  const handleGenderCheckbox = (e) => {
    setLikelyGender(null);
    if(e.target.checked) {
      setLikelyGender(e.target.value);
      if (maleCheckboxRef.current && e.target.value == 'female')  { maleCheckboxRef.current.checked = false; }
      if (femaleCheckboxRef.current && e.target.value == 'male')  { femaleCheckboxRef.current.checked = false; }
    }
  }

  return (
    <form>
      <Row>
        <Col md={12} xl={11} className="mx-auto stripeForm">
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-1">Upload a CSV file to Skiptrace</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <small>* The maximum file size should be 7MB or less.</small>
              <div className="file_btn_lable">
                <span
                  style={{
                    fontSize: "0.8rem",
                    display: "block",
                    marginTop: "20px",
                  }}
                >
                  {fileData?.length > 0 ? fileInfo?.name : "No File Select"}
                </span>
                <CSVReader
                  onFileLoaded={handleFile}
                  onError={handleError}
                  inputId="CSV"
                  label={
                    <span>
                      <i className="fa-solid fa-cloud-arrow-up"></i>CHOOSE FILE
                    </span>
                  }
                  inputStyle={{ padding: "1px" }}
                  accept=".csv, text/csv"
                />
              </div>

              {fileError ? (
                <span className="error-message">{fileError}</span>
              ) : (
                ""
              )}

              <div className="mt-5">
                <h5>DNC Scrub</h5>
                <small className="px-2">
                  * DNC Scrub requires additional {DNC_CHECK_SKIP_PER_HIT} skip
                  per match.
                </small>
                <div className="form-check ms-2 mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="dncCheckbox"
                    onChange={(e) =>
                      setDncCheck((previousValue) => !previousValue)
                    }
                  />
                  <label
                    className="form-check-label d-block"
                    htmlFor="dncCheckbox"
                  >
                    DNC Scrub
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h5>Sending to</h5>
              <small>
                {" "}
                {profileData?.firstName || ""} {profileData?.lastName || ""}{" "}
                {`<${profileData?.email || ""}>`}
              </small>

              <h5 className="mt-4">CC Skiptrace file to</h5>
              <input
                type="email"
                className="form-control mt-2"
                onChange={(e) => setCcEmail(e.target.value)}
                placeholder="Enter an email"
              />
              {ccEmailError ? (
                <span className="error-message">{ccEmailError}</span>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* Demographic filters */}
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="mt-4">
                <a
                  onClick={() => setShowAgeFilter(!showAgeFilter)}
                  className="py-1"
                  type="button"
                >
                  <h5>
                    {showAgeFilter ? "Hide" : "Show"} Demographic Filters{" "}
                    {showAgeFilter ? (
                      <FaChevronDown className="ms-1" />
                    ) : (
                      <FaChevronUp className="ms-1 small" />
                    )}
                  </h5>
                </a>
              </div>
              {/* <h5>Demographic Filters</h5> */}

              <div className={showAgeFilter ? "px-2 py-1 d-block" : "d-none"}>
                <small>
                  * Each demographic category requires additional 0.2 skip per
                  match.
                </small>
                <label className="d-block mt-3 mb-2">
                  <span className="fw-bolder fs-6">Age Range</span>
                </label>

                <Form.Check
                  inline
                  label={AgeFilterOptionConstant[1].label}
                  type="checkbox"
                  id="ageRange2"
                  onChange={(e) => handleAgeFilterCheckbox(e, 1)}
                  ref={checkbox2Ref}
                />
                <Form.Check
                  inline
                  label={AgeFilterOptionConstant[2].label}
                  type="checkbox"
                  id="ageRange3"
                  onChange={(e) => handleAgeFilterCheckbox(e, 2)}
                  ref={checkbox3Ref}
                />
                <Form.Check
                  inline
                  label={AgeFilterOptionConstant[3].label}
                  type="checkbox"
                  id="ageRange4"
                  onChange={(e) => handleAgeFilterCheckbox(e, 3)}
                  ref={checkbox4Ref}
                />
                <Form.Check
                  inline
                  label={AgeFilterOptionConstant[4].label}
                  type="checkbox"
                  id="ageRange5"
                  onChange={(e) => handleAgeFilterCheckbox(e, 4)}
                  ref={checkbox5Ref}
                />
                <Form.Check
                  inline
                  label={AgeFilterOptionConstant[5].label}
                  type="checkbox"
                  id="ageRange6"
                  onChange={(e) => handleAgeFilterCheckbox(e, 5)}
                  ref={checkbox6Ref}
                />

                <label className="d-block mt-4 mb-2">
                  <span className="fw-bolder fs-6">Most Likely Gender</span> (90% Accuracy. Non-Individuals Will Be Excluded From Search)
                </label>
                <Form.Check
                  name="most_likely_gender_1"
                  inline
                  label={`Male`}
                  value={"male"}
                  type="checkbox"
                  id="most_likely_gender_male"
                  onChange={handleGenderCheckbox}
                  ref={maleCheckboxRef}
                />
                <Form.Check
                  name="most_likely_gender_2"
                  inline
                  label={`Female`}
                  value={"female"}
                  type="checkbox"
                  id="most_likely_gender_female"
                  onChange={handleGenderCheckbox}
                  ref={femaleCheckboxRef}
                />

                <label className="d-block mt-4 mb-2">
                  <span className="fw-bolder fs-6">Relatives' Contact Information</span>
                </label>
                <Form.Check
                  name="relative_contact_data"
                  inline
                  label={`Include relatives' contacts`}
                  type="checkbox"
                  id="relatives_data"
                  onChange={(e) => {
                    setRelativeContacts((previousValue) => !previousValue);
                  }}
                  checked={relativeContacts}
                />

             
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-end">
            <div className="col-md-3">
              <button
                type="button"
                onClick={handleStep1Submit}
                className="btn btn-primary mt-3 float-end px-5 py-2"
              >
                Next
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default FileUploadStep1;
