export const IndustrialOptionsConstant = [
  { value: "5000", label: "General" , category: "Industrial"},
  { value: "5001", label: "Manufacturing (Light)" , category: "Industrial"},
  { value: "5002", label: "Light Industrial" , category: "Industrial"},
  { value: "5003", label: "Warehouse" , category: "Industrial"},
  {
    value: "5004",
    label: "Storage yard, Open Storage (Light Equipment, Material)",
   category: "Industrial"},
  { value: "5005", label: "Food Packing, Packing Plant" , category: "Industrial"},
  { value: "5006", label: "Assembly (Light Industrial)" , category: "Industrial"},
  { value: "5007", label: "Food Processing" , category: "Industrial"},
  { value: "5008", label: "Recycling Plant" , category: "Industrial"},
  { value: "5009", label: "Communications" , category: "Industrial"},
  { value: "5010", label: "Condominium" , category: "Industrial"},
  {
    value: "5011",
    label:
      "Laboratory, Research and Development Facility, Cosmetics, Parmaceutical"
  , category: "Industrial"},
  { value: "5012", label: "Industrial Park" , category: "Industrial"},
  { value: "5013", label: "Multi-Tenant Industrial Bldg" , category: "Industrial"},
  {
    value: "5014",
    label: "Marine Facility/Boat Repairs (Small Carft or Sailboat)"
  , category: "Industrial"},
  {
    value: "5015",
    label: "Lumber & Wood Product Manufacturing (Including Furniture)",
   category: "Industrial"},
  { value: "5016", label: "Paper Product Manufacturing & Related Products" , category: "Industrial"},
  { value: "5017", label: "Printing & Publishing (Light Industrial)" , category: "Industrial"},
  { value: "5018", label: "Industrial Loft Building, Loft Building" , category: "Industrial"},
  { value: "5019", label: "Construction/Contracting Services" , category: "Industrial"},
  { value: "5020", label: "Common Area" , category: "Industrial"},
  { value: "6000", label: "Heavy Industrial (General)" , category: "Industrial"},
  { value: "6001", label: "Transportation" , category: "Industrial"},
  { value: "6002", label: "Distribution Warehouse (Regional)" , category: "Industrial"},
  {
    value: "6003",
    label: "Mining Facility (Oil, Gas, Mineral, Precious Metals)",
   category: "Industrial"},
  { value: "6004", label: "Storage Yard (Junk, Auto Wrecking, Salvage)" , category: "Industrial"},
  { value: "6005", label: "Distillery, Brewery, Bottling" , category: "Industrial"},
  { value: "6006", label: "Refinary, Petroleum Products" , category: "Industrial"},
  { value: "6007", label: "Mill" , category: "Industrial"},
  { value: "6008", label: "Factory (Medium)" , category: "Industrial"},
  { value: "6009", label: "Processing Plant" , category: "Industrial"},
  { value: "6010", label: "Lumberyard, Building Materials" , category: "Industrial"},
  {
    value: "6011",
    label: "Shipyard/Storage - Built or Repaired (Seagoing Bessels)",
   category: "Industrial"},
  { value: "6012", label: "Slaughter House, Stockyard" , category: "Industrial"},
  { value: "6013", label: "Waste Disposal, Sewage, Treatment Facility" , category: "Industrial"},
  { value: "6014", label: "Quarries" , category: "Industrial"},
  { value: "6015", label: "Heavy Manufacturing" , category: "Industrial"},
  { value: "6016", label: "Labor Camps" , category: "Industrial"},
  { value: "6017", label: "Winery" , category: "Industrial"},
  { value: "6018", label: "Chemical" , category: "Industrial"},
  { value: "6019", label: "Foundry, Industrial Plant" , category: "Industrial"},
  { value: "6020", label: "Cannery" , category: "Industrial"},
  { value: "6021", label: "Gasonline, Fuel Bulk Storage" , category: "Industrial"},
  { value: "6022", label: "Grain Elevator" , category: "Industrial"},
  { value: "6024", label: "Cold Storage" , category: "Industrial"},
  { value: "6025", label: "Sugar Refinary" , category: "Industrial"},
];
