import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TopNav } from "../../Essentials/Front/TopNav";
import { FooterFront } from "../../Essentials/Front/FooterFront";
import { ACCOUNT_ACTIVATION_API_ENDPOINT } from "../../Constants/api-routes.constant";
import { Row, Col } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { BiSolidUserCheck, BiSolidUserX } from "react-icons/bi";

const AccountActivation = (props) => {
  const history = useNavigate();
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      setErrorMsg("Account ID is invalid.");
    } else {
      // Prepare the request
      const request = {
        clientId: id,
      };

      // Call the API with request
      fetch(ACCOUNT_ACTIVATION_API_ENDPOINT, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      })
        .then((rawResponse) => {
          return rawResponse.json();
        })
        .then((response) => {
          if (response?.statusCode == "200") {
            setSuccessMsg(
              <p>
                Your account has been activated successfully. You can login to
                your account from <a href="/login">here</a>
              </p>
            );

            setTimeout(() => {
              history('/login')
            }, 3000);

          } else {
            setErrorMsg(response?.message);
          }
        });
    }
    document.title = "Account Activation - Skipify.ai";
  }, []);

  return (
    <>
      <TopNav />



      <section className="main-form-register">
        <div className="main-form-register-sub">
          {errorMsg &&
            <Row className="w-100">
              <Col md={7} className="text-center mx-auto success_failPage">
                <BiSolidUserX />
                <h4>Account Activation Failed.</h4>
                <p>{errorMsg}</p>
              </Col>
            </Row>
          }

          {successMsg &&
            <Row className="w-100">
              <Col md={7} className="text-center mx-auto success_activePage">
                <BiSolidUserCheck />
                <h4>Account Successfully Activated</h4>
                <p>{successMsg}</p>
              </Col>
            </Row>
          }
        </div>
      </section>
      <FooterFront />
    </>
  );
};

export default AccountActivation;
