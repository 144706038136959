// function DropdownFunction(){
//     const dropVal = document.querySelectorAll('.dropdown-cst');
//     const ul_ = document.querySelectorAll('.dropdown-menu li a');

//     window.addEventListener('click', function(e){   
//         if (document.getElementsByClassName('dropdown').contains(e.target)){
//             console.log("Error")
//         } else{
//             console.log("No Error!")
//         }
//     });
    
    
//     ul_.forEach(function(a){
//         a.addEventListener('click',function(b){
//             let textVal = a.innerHTML;
//             let parent_ = a.parentElement.parentElement.parentElement;
//             let textBtn_ = parent_.querySelector('.dropdown-cst');
//             textBtn_.innerHTML=textVal;
//         })
//     })
    
    
//     dropVal.forEach(function(e){
//         e.addEventListener('click', function(f){
//             let x = e.parentElement;
//             let y = x.querySelector('ul');
//             y.classList.add('show');
//             console.log(y);
//         })
//     })
// }
// setTimeout(function(){
//     DropdownFunction();
// },100)




// const dropdownCst = document.querySelectorAll('.dropdown-cst');
// console.log(dropdownCst);
// dropdownCst.forEach(function(e){
//     e.addEventListener('click',function(){
//         const a = this.parentNode;
//         const b = a.querySelector('.dropdown-menu');
//         b.style.display="block";
//         if(e.target.parentElement.id == 'yourID'){
//             console.log("Clicked Inside");
//         }
//     })
// })


setTimeout(function(){
    const btnCstFilter = document.querySelectorAll('.btn-cstFilter');
    const quickFilterRow = document.querySelector('.quickFilter-row');
    btnCstFilter.forEach(function(e){
        e.addEventListener('click',function(){
            console.log("F")
            if(this.classList.contains("active")){
                this.classList.remove('active');
                quickFilterRow.style.display="none";
                this.childNodes[1].classList.remove('rotateIcon');
            }
            else {
                this.classList.add('active');
                quickFilterRow.style.display="flex";
                this.childNodes[1].classList.add('rotateIcon');
            }
        })
    })
    function slideOffForMenu(){
        let x = document.querySelector('.slideContainer');
        x.style.right="-35%";
    }
    function slideOnForMenu(){
        let x = document.querySelector('.slideContainer');
        x.style.right=0;
    }
},1000)

