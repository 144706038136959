import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../../Constants/redux-slices';

const INITIAL_STATE = {
  data: []
};

export const invoiceEntity = createSlice({
  name: SLICE_NAME.INVOICE,
  initialState: INITIAL_STATE,
  reducers: {}
});

export const invoiceEntityReducer = invoiceEntity.reducer;