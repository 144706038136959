import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { BiCreditCard, BiSolidMinusCircle } from "react-icons/bi";
import { IoIosCheckmarkCircle, IoMdTrash } from "react-icons/io";
import { OverlayTrigger, Tooltip, Form, Button, Row, Col, Image, Dropdown } from "react-bootstrap";
import {
  BILLING_DELETE_ENDPOINT,
  BILLING_DISABLE_ENDPOINT,
  BILLING_ENABLE_ENDPOINT,
  BILLING_GET_LIST_ENDPOINT,
} from "../../Constants/api-routes.constant";
import { GetAuthToken } from "../../Utils/auth-verification.util";
import { formatCardEndFourDigits } from "../../Utils/formating.util";
import { AddBillingModal } from "./add-billing-modal";
import visaIcon from "./../../assets/new_theme/img/payment/visa.png";
import discoverIcon from "./../../assets/new_theme/img/payment/discover.png";
import linkIcon from "./../../assets/new_theme/img/payment/link.png";
import mastercardIcon from "./../../assets/new_theme/img/payment/card.png";
import amexIcon from "./../../assets/new_theme/img/payment/amex.png";
import chinaUIcon from "./../../assets/new_theme/img/payment/chinaUnion.png";
import dinersIcon from "./../../assets/new_theme/img/payment/diners.png"; 
import jcbIcon from "./../../assets/new_theme/img/payment/jcb.png";
import setMainIcon from "./../../assets/new_theme/img/payment/main-card.png";
import deleteIcon from "./../../assets/new_theme/img/delete.png";
import moreIcon from "./../../assets/new_theme/img/more.png";
import addIcon from "./../../assets/new_theme/img/add.png";


const BillingList = (props) => {
  document.title = "Payment Methods - Skipify.ai";
  const [billingList, setBillingList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showMessage, setShowMessage] = useState(false)
  const [searchParams] = useSearchParams()

  const [showAddCard, setShowAddCard] = useState(false);


  useEffect(() => {
    GetBillingList();
    if(Boolean(searchParams.get('last-payment-declined')) === true){
      setShowAddCard(true)
    }
  }, [showAddCard, searchParams]);

  const GetBillingList = () => {
    const token = GetAuthToken();
    if (!token) return [];
    setShowLoader(true);
    return fetch(BILLING_GET_LIST_ENDPOINT, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.result) {
          const active = response?.result?.filter(item => item.isActive) || [];
          const nonActiveArray = response?.result?.filter(item => !item.isActive) || [];
          setBillingList([...active, ...nonActiveArray]);
        }
        if (response?.result?.length === 0) {
          setShowMessage(true)
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        return err;
      });
  };

  const handleEnableDisableLink = (billingId, currentStatus = false) => {
    setErrorMsg("");
    setSuccessMsg("");
    const token = GetAuthToken();
    if (!token) return [];

    let endpoint = BILLING_DISABLE_ENDPOINT;
    if (currentStatus == false) {
      endpoint = BILLING_ENABLE_ENDPOINT;
    }

    return fetch(endpoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ billingId: billingId }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.result) {
          setSuccessMsg("Card updated successfully.");
          GetBillingList();
        } else {
          setErrorMsg(response?.message);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
        setErrorMsg(err?.message);
        return err;
      });
  };

  const handleDeleteBilling = async (billingId) => {
    setErrorMsg("");
    setSuccessMsg("");
    const token = GetAuthToken();
    if (!token) return [];

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({ billingId: billingId });

      const response = await axios.post(BILLING_DELETE_ENDPOINT, body, { headers });

      if (response.data && response.data?.statusCode === 200) {
        setSuccessMsg("Card deleted successfully.");
        GetBillingList();
      }

      setShowLoader(false);

    } catch (err) {
      setShowLoader(false);
      setErrorMsg(err?.response?.data?.message || err?.message);
    }
  };

  const handleCloseAddCardModal = () => setShowAddCard(false);
  const handleShowAddCardModal = () => setShowAddCard(true);

  const renderAddBillingModal = () => {
    return (
      <AddBillingModal
        showModal={showAddCard}
        closeModalHandler={handleCloseAddCardModal}
        shouldActive={false}
      />
    );
  }

  return (
    <>
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {errorMsg ? (
        <div className="alert alert-danger">{errorMsg}</div>
      ) : (
        ""
      )}
      {successMsg ? (
        <div className="alert alert-success">{successMsg}</div>
      ) : (
        ""
      )}

      {renderAddBillingModal()}

      <div className="row mt-3">
        <Col md={6}>
          <h2 className="mb-4 font-weight600 bill-gap">Saved Payment Methods</h2> 
        </Col>
      </div> 

      {!showMessage ?
        <>
          <Row>
            <Col xl={6} lg={8}>
              <hr className="mb-4 mt-2 hr-custom"></hr>
              {billingList.map((billing, index) => (
                <>
                  <Row key={billing._id} className="my-4 payment-skin-struct">
                    <Col xl={2} lg={2} sm={2} md={2} xs={2} className="cst-wdth-skp align-self-center">
                    { billing.cardType == "discover" ? <>
                      <Image className="icon" src={discoverIcon}></Image>
                    </> : <></>}
                    { billing.cardType == "visa" ? <>
                      <Image className="icon" src={visaIcon}></Image>
                    </> : <></>}
                    { billing.cardType == "link" ? <>
                      <Image className="icon" src={linkIcon}></Image>
                    </> : <></>}
                    { billing.cardType == "mastercard" ? <>
                      <Image className="icon" src={mastercardIcon}></Image>
                    </> : <></>}
                    { billing.cardType == "amex" ? <>
                      <Image className="icon" src={amexIcon}></Image>
                    </> : <></>}
                    { billing.cardType == "unionpay" ? <>
                      <Image className="icon" src={chinaUIcon}></Image>
                    </> : <></>}
                    { billing.cardType == "jcb" ? <>
                      <Image className="icon" src={jcbIcon}></Image>
                    </> : <></>}
                    { billing.cardType == "diners" ? <>
                      <Image className="icon" src={dinersIcon}></Image>
                    </> : <></>}
                    {/* { billing.cardType == "discover" ? <>
                      <Image className="icon" src={visaIcon}></Image>
                    </> : <></>} */}
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                      <div className="container-content">
                        <Row>
                          <Col>
                            <span className="name">
                              {billing?.cardOwnerName ? billing.cardOwnerName : ''}
                            </span>
                            {billing.cardType != "link" ?
                            <span className="number">
                              **** {formatCardEndFourDigits(billing.cardLastFourDigits)}
                            </span> : '' }
                            { billing.isActive ? <> 
                              <span className="ms-2 badge">
                                Main
                              </span> 
                            </> : <></>}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="expiry">Expires {billing.cardExpiryMonth}/{billing.cardExpiryYear}</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                      
                      {/* New Design Dropdown items are Starting From here */}
                                    
                      <ul className="list-table-dropdown mylist-data float-end">
                        <li className="avatar">
                          <Dropdown 
                            autoClose="true"
                            align="end"
                            >
                            <Dropdown.Toggle  variant="primary">
                              <Image src={moreIcon} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="list-items-micro">
                              <Dropdown.Item 
                                onClick={(e) =>
                                  handleEnableDisableLink(
                                    billing._id,
                                    billing.isActive
                                  )
                                }>
                                <Image src={setMainIcon}></Image>
                                <span>
                                  { billing.isActive ? <> 
                                    Remove as main card
                                  </> : <>Set as main card</>}
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item 
                                onClick={(e) => handleDeleteBilling(billing._id)}>
                                  <Image src={deleteIcon}></Image><span className="text-danger">Delete Card</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </>
              ))}
              
            </Col>
          </Row>
        </>
        : <p className="message_show mt-5">No Payment Methods are currently on file.  Click Add New above to add your method of payment.</p>}

        <Row>
          <Col xl={6}>
            <Row>
              <Col xl={4}>
                <Button className="download-table px-4 p-2 mt-3" onClick={handleShowAddCardModal}>
                  <Image src={addIcon}></Image>
                  Add new
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>


      {/* Old Code below */}
      {/* {!showMessage ?
        <div className="table-responsive">
          <table className="table resp-table2 ">
            <thead>
              <tr>
                <th>Card Type</th>
                <th>Last 4 Digits</th>
                <th>Exp. Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {billingList.map((billing, index) => (
                <tr key={billing._id}>
                  <td>{billing.cardType?.toUpperCase()}</td>
                  <td>{billing.cardLastFourDigits != '0000' ? formatCardEndFourDigits(billing.cardLastFourDigits) : "-"}</td>
                  <td>
                    {billing.cardExpiryMonth}/{billing.cardExpiryYear}
                  </td>
                  <td>
                    {!billing.isActive ? (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip>Disabled</Tooltip>
                        }
                      >
                        <button
                          className="inactive_Billing"
                          onClick={(e) =>
                            handleEnableDisableLink(
                              billing._id,
                              billing.isActive
                            )
                          }
                        >
                          <BiSolidMinusCircle />
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip>Enable</Tooltip>
                        }
                      >
                        <button
                          className="active_Billing"
                          onClick={(e) =>
                            handleEnableDisableLink(
                              billing._id,
                              billing.isActive
                            )
                          }
                        >
                          <IoIosCheckmarkCircle />
                        </button>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>

                    <button
                      className="delete_Billing"
                      onClick={(e) => handleDeleteBilling(billing._id)}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: 0,
                      }}
                      disabled={billingList?.length > 1 ? false : true}
                    >
                      <IoMdTrash size={20} color="#9d0101" />
                    </button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        : <p className="message_show mt-5">No Payment Methods are currently on file.  Click Add New above to add your method of payment.</p>} */}
    </>
  );
};

export default BillingList;
