import { GetAuthToken } from "./auth-verification.util";
import {
  AFFILIATE_GET_DETAILS_ENDPOINT,
  AFFILIATE_GET_GROSS_STATS_ENDPOINT,
  AFFILIATE_GET_PAYMENTS_HISTORY_ENDPOINT,
  AFFILIATE_GET_REFERRAL_ACTIVITIES_ENDPOINT,
  AFFILIATE_GET_REFERRAL_LIST_ENDPOINT,
  AFFILIATE_NEW_CREATE_REQUEST_ENDPOINT,
  AFFILIATE_GET_REVENUE_DATA_ENDPOINT,
  AFFILIATE_GET_DUE_PAYMENTS_ENDPOINT
} from "../Constants/api-routes.constant";

export const AffiliateGetDetails = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(AFFILIATE_GET_DETAILS_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const AffiliateGetGrossStats = async (durationFilter = 'Daily', startDate = '', endDate = '') => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(`${AFFILIATE_GET_GROSS_STATS_ENDPOINT}?filter=${durationFilter}&startDate=${startDate}&endDate=${endDate}`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const AffiliateGetPaymentsHistory = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(AFFILIATE_GET_PAYMENTS_HISTORY_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};


export const AffiliateGetDuePayments = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(AFFILIATE_GET_DUE_PAYMENTS_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const AffiliateGetReferralActivity = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(AFFILIATE_GET_REFERRAL_ACTIVITIES_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const AffiliateGetRevenueData = async (startDate = '', endDate = '') => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(`${AFFILIATE_GET_REVENUE_DATA_ENDPOINT}?startDate=${startDate}&endDate=${endDate}`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

export const AffiliateReferralList = async () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(AFFILIATE_GET_REFERRAL_LIST_ENDPOINT, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};

// Join Affiliate Proram
export const AffiliateJoinProgramRequest = () => {
  const token = GetAuthToken();
  if (!token) return [];

  return fetch(AFFILIATE_NEW_CREATE_REQUEST_ENDPOINT, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err;
    });
};
