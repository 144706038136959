import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

const BillingCardElement = ({ confirmResult }) => {

  // props.confirmResult  ... defined in main component

  const stripe = useStripe();
  const elements = useElements();


  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element

      elements,
      redirect: "if_required",
    });

    // Return back the result to parent component
    confirmResult(result);

    if(result.error){
      localStorage.setItem("payment-card-process", false);
    }else{
      localStorage.setItem("payment-card-process", true);
    }

    if(result.error){
      localStorage.setItem("payment-card-process", false);
    }else{
      localStorage.setItem("payment-card-process", true);
    }

    // if (result.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log(result.error.message);
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="btn btn-primary mt-3" disabled={!stripe}>Submit</button>
    </form>
  );
};

export default BillingCardElement;
