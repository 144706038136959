import { createSelector } from 'reselect';
import * as moment from 'moment';

const payPlanFeatureSelector = (state) => state.features.paymentPlan;

export const getSelectedPlan = createSelector(
  payPlanFeatureSelector,
  (paymentPlan) => paymentPlan.seletedPlan,
);

export const getSelectedPlanId = createSelector(
  getSelectedPlan,
  (seletedPlan) => seletedPlan._id,
);

export const getSelectedPlanTitle = createSelector(
  getSelectedPlan,
  (seletedPlan) => seletedPlan.title,
);

export const getTotalPackages = createSelector(
  payPlanFeatureSelector,
  (paymentPlan) => paymentPlan.totalPackages,
);

export const getFreePlanData = createSelector(
  getTotalPackages,
  (packages) => {
    if (packages.length > 0) {
      const data = packages.filter(item => item.isFreePackage);
      return data[0];
    }

    return {};
  },
);

export const getCurrentBilling = createSelector(
  payPlanFeatureSelector,
  (paymentPlan) => paymentPlan.currentbilling,
);

export const getIsCardExpired = createSelector(
  getCurrentBilling,
  (currentbilling) => {

    if (!currentbilling === false) {
      return false;
    }

    const { cardExpiryYear = 0, cardExpiryMonth = 0 } = currentbilling;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    if (cardExpiryYear < currentYear) {
      return true;
    } else if (cardExpiryYear === currentYear && cardExpiryMonth < currentMonth) {
      return true;
    } else {
      return false;
    }
  },
);

export const getCurrentPlan = createSelector(
  payPlanFeatureSelector,
  (paymentPlan) => paymentPlan.currentPlan,
);

export const getCurrentPlanId = createSelector(
  getCurrentPlan,
  (currentPlan) => currentPlan?.currentPackageId || '',
);

export const getCurrentPlanData = createSelector(
  getCurrentPlanId, getTotalPackages, getCurrentPlan,
  (currentPlanId, packages, currentPlan) => {
    if (!currentPlanId) {
      return {};
    }
    const result = packages.filter(item => item._id === currentPlanId);
    return result[0] || currentPlan || {};
  },
);

export const getCurrentPlanPrice = createSelector(
  getCurrentPlanId, getTotalPackages,
  (currentPlanId, packages) => {
    const result = packages.filter(item => item._id === currentPlanId);
    return result[0]?.price || 0;
  },
);

export const getSelectedPlanPriceCalculate = createSelector(
  getSelectedPlan, getCurrentPlanPrice, getCurrentPlan,
  (seletedPlan, currentPlanPrice, currentPlan) => {

    // For upgrade, the previous plan should be active
    if (seletedPlan?.price > currentPlanPrice && moment(currentPlan.currentPackageExpiryDate).isAfter(moment())) {
      return seletedPlan?.price - currentPlanPrice;
    }

    return seletedPlan?.price
  }
);

export const getCurrentPlanRemainDownloads = createSelector(
  getCurrentPlan,
  (currentPlan) => currentPlan.remainingDownloads
);

export const getCurrentPlanRemainHits = createSelector(
  getCurrentPlan,
  (currentPlan) => currentPlan.remainingHits,
);
