import React, { useEffect, useState } from "react";
import loginLogo from "../../assets/img/login-logo.png";
import { FORGET_PASSWORD_API_ENDPOINT } from "../../Constants/api-routes.constant";
import { TopNav } from "../../Essentials/Front/TopNav";
import { FooterFront } from "../../Essentials/Front/FooterFront";
import { Col, Row, Spinner, Form, Image } from "react-bootstrap";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import "../../assets/css/main.css";
import leftBannerImage from "../../assets/img/signup.png";
import { Link } from "react-router-dom";

const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const ForgetPassword = () => {
  useEffect(() => {
    document.title = "Forgot Password - Skipify.ai";
  }, [])

  const [inputEmail, setInputEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [showLoginButton, setShowLoginButton] = useState(false);

  const handleSubmit = () => {

    setShowLoader(true)
    if (!inputEmail || !EmailRegex.test(inputEmail)) {
      setEmailError("Enter a valid email address");
      setSuccessMessage("");
      setShowLoader(false)
      return;
    } else {
      setEmailError("");
    }
    fetch(FORGET_PASSWORD_API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: inputEmail }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setShowLoader(false)
        setInputEmail("")
        setShowLoginButton(true)
        if (response?.statusCode == 200) {
          setSuccessMessage(response?.result);
        } else {
          const errMessage = response?.message
            ? response.message
            : "Sorry! Unable to send email at this moment. Please try again.";
          setEmailError(errMessage);
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {/* <TopNav /> */}
      <div className="register_section">
        <div className="register_section_overlay">
          <Row>
            {/*  */}
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={12} lg={{ span: 7, order: 1 }} className="p-0 text-center">
              <div className="signup_image_div">
                <Link to="/">
                  <Image src={leftBannerImage} alt="" className="bg_image" />
                </Link>
                <div className="text-div">
                  <h4>Lead Generation.</h4>
                  <h4>Made Easy.</h4>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={12} lg={{ span: 5, order: 2 }} className="mb-5">
              <div className="main-form2 mb-3">
                <Row>
                  <Col sm={12} md={10} className="mx-auto text-center">

                    <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                      <Image src={require('./../../assets/img/logo.png')} alt="logo" className="register_logo" />
                    </a>

                    <h2 className="register_title">Forgot your password?</h2>
                    <p className="register_des"> No worries! Enter your email address below and we'll send you a link to reset it.</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={10} className="mx-auto">
                    {successMessage ? (
                      <div className="alert alert-success mt-2 alert_success_div">
                        <h6 style={{ color: "#0f5132 !important", fontSize: "0.8rem" }}>{successMessage}</h6>
                      </div>
                    ) : (
                      ""
                    )}
                    {!showLoginButton &&
                      <Form.Group className="my-3" >
                        <Form.Label> Email Address</Form.Label>
                        <Form.Control
                          onChange={(e) => { setInputEmail(e.target.value); setEmailError("") }}
                          type="email"
                          name="email"
                          value={inputEmail}
                          // placeholder="Enter email here..."
                          isInvalid={emailError ? true : false}
                          onKeyDown={handleKeyPress}
                        />
                        {emailError ? <p className="text-danger"><BsFillExclamationCircleFill /> {emailError}</p> : ""}

                      </Form.Group>

                    }

                  </Col>
                  <Col md={10} className="mx-auto">
                    <div className="tab-container">
                      {!showLoginButton ?
                        <>
                          {showLoader ?
                            <button type="button" className="submit"> <Spinner animation="border" size="sm" /></button>
                            : <button type="submit" className="submit" onClick={handleSubmit} >Submit</button>}
                        </>
                        :
                        <div className="d-grid gap-2">
                          <Link to="/login" className="submit_link"> LOGIN</Link>
                        </div>
                      }

                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <FooterFront /> */}
    </>
  );
};

export default ForgetPassword;
