export const RecreationalOptionsConstant = [
  
  { value: "4000", label: "General" , category: "Recreational"},
  { value: "4001", label: "Recreation Center" , category: "Recreational"},
  { value: "4002", label: "Public Swimming Pool" , category: "Recreational"},
  { value: "4003", label: "Boat slips, Marina, Yacht Club, Boat Landing" , category: "Recreational"},
  { value: "4004", label: "Bowling Alley" , category: "Recreational"},
  { value: "4005", label: "Arcades (Amusement)" , category: "Recreational"},
  { value: "4006", label: "Skating rink, Ice Skating, Roller Skating" , category: "Recreational"},
  { value: "4007", label: "Clubs, Lodges, Professional Associations" , category: "Recreational"},
  { value: "4008", label: "Museums, Library, Art Gallery" , category: "Recreational"},
  { value: "4009", label: "Country Club" , category: "Recreational"},
  { value: "4010", label: "Stadiums" , category: "Recreational"},
  { value: "4011", label: "Arenas, Convention Center" , category: "Recreational"},
  { value: "4012", label: "Auditoriums" , category: "Recreational"},
  { value: "4013", label: "Golf Driving Range" , category: "Recreational"},
  { value: "4014", label: "Race track" , category: "Recreational"},
  { value: "4015", label: "Gym, Health Spa" , category: "Recreational"},
  { value: "4016", label: "Dance Hall" , category: "Recreational"},
  { value: "4017", label: "Riding Stable, Trails" , category: "Recreational"},
  { value: "4018", label: "Campground, RV Park" , category: "Recreational"},
  { value: "4019", label: "Fairgrounds" , category: "Recreational"},
  { value: "4020", label: "Theater" , category: "Recreational"},
  { value: "4021", label: "Drive-In Theater" , category: "Recreational"},
  { value: "4022", label: "Amusement Park, Tourist Attraction" , category: "Recreational"},
  { value: "4023", label: "Piers, Wharf" , category: "Recreational"},
  { value: "4024", label: "Fish Camps, Game Club, Target Shooting" , category: "Recreational"},
  { value: "4025", label: "Outdoor Recreation: Beach, Mountain, Desert" , category: "Recreational"},
  { value: "4026", label: "Pool Hall, Billiard Parlor" , category: "Recreational"},
  { value: "4027", label: "Park, Playground, Picnic Area" , category: "Recreational"},
  { value: "4028", label: "Golf Course" , category: "Recreational"},
  { value: "4029", label: "Racquet or Tennis Court" , category: "Recreational"},
  { value: "4031", label: "Go-carts, Miniature Golf, Water slides" , category: "Recreational"},
  { value: "4032", label: "Sports Complex" , category: "Recreational"},
  { value: "2030", label: "Zoo" , category: "Recreational"},
];
