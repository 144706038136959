import React, { useState } from "react";
import { Col, Row, Modal, Button, Form, Card } from "react-bootstrap";
import { CancelAccount } from "../../Utils/clients.util";
import Select from "react-select";
import { React_Select_Style } from "../../Constants/react-select-style.constants";
import DashbaordLayout from "../DashbaordLayout";

const CancelAccountRequest = () => {
  // const [showCancelAccountModel, setShowCancelAccountModel] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [reasonSelected, setReasonSelected] = useState("");
  const [otherReasonText, setOtherReasonText] = useState("");
  const [showOtherReasonTextbox, setShowOtherReasonTextbox] = useState(false); 

  const pageTitle = "Cancel Account";

  const cancellationReason = [
    {
      value: "Too expensive",
      label: "Too expensive",
    },
    {
      value: "Not getting desired results",
      label: "Not getting desired results",
    },
    {
      value: "Unsatisfactory customer support",
      label: "Unsatisfactory customer support",
    },
    {
      value: "Not using the service enough",
      label: "Not using the service enough",
    },
    {
      value: "Technical problems or bugs",
      label: "Technical problems or bugs",
    },
    {
      value: "Switching to a competitor",
      label: "Switching to a competitor",
    },
    {
      value: "Pausing business activities temporarily",
      label: "Pausing business activities temporarily",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const onReasonChange = (reason) => {
    if(reason.value == "other") {
      setShowOtherReasonTextbox(true);
    } else {
      setShowOtherReasonTextbox(false);
    }
    setReasonSelected(reason.value);
  };

  // Cancel Account Request
  const handleCancelAccountButton = async () => {
  setErrorMsg('');
  setSuccessMsg('');
    if(!reasonSelected) {
      setErrorMsg('Please select a reason to submit the request.');
      return;
    }

    if(reasonSelected == 'other' && !otherReasonText) {
      setErrorMsg('Please type other reason to submit the request.')
      return;
    }

    let reason = reasonSelected;
    if(reasonSelected == 'other') {
      reason = `${reasonSelected}: ${otherReasonText}`
    }
     
    setShowLoader(true);
    return CancelAccount(reason)
      .then((response) => {
        if (response?.statusCode == 200 && response?.result) {
          setSuccessMsg(
            "Your request to cancel your account has been received. A Skipify.ai representative will process your request in the next 2 to 3 business days."
          );
        } else {
          setErrorMsg('Error submitting the request. Please try later.')
        }
       
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        setErrorMsg(error?.message);
      });
  };

  return (
    <div className="d-flex" id="wrapper">
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}

      <DashbaordLayout title={pageTitle}>
        <div id="page-content-wrapper">
          <Card className="setting_form">

            {errorMsg ? <p className="alert alert-danger">{errorMsg}</p> : ""}
            
            <Card.Body className="mb-5">
              <Row className="mt-1">
                <Col>
                {successMsg ? <p className="alert alert-success">{successMsg}</p> : ""}
                </Col>
              </Row>

              {successMsg === '' ? (
                <>
                <Row className="d-flex mt-3">
                <Col>
                  {/* <h5>Why do you want to cancel the account with us?</h5> */}
                  <h5>Please let us know the reason for wanting to cancel your account.</h5>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col lg={5}>
                  <Form.Group className="mb-3 mt-5">
                    <Form.Label> Reason </Form.Label>
                    <Select
                      options={cancellationReason}
                      styles={React_Select_Style}
                      name="cancelReason"
                      onChange={onReasonChange}
                    />
                  </Form.Group>

                  {showOtherReasonTextbox ? (
                        <Form.Group className="mb-3 mt-3">
                        <Form.Label> Other Reason </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Type here"
                          onChange={(e) => setOtherReasonText(e.target.value)}
                          maxLength={300}
                        />
                      </Form.Group>
                  ) : ''}
              

                  <Button
                    className="btn btn-warning  px-5 py-2"
                    onClick={(e) => handleCancelAccountButton(true)}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
                </>
              ) : ''}
              
            </Card.Body>
          </Card>
        </div>
      </DashbaordLayout>
    </div>
  );
};

export default CancelAccountRequest;
