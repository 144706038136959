import React, { useEffect, useState } from "react";
import {
  GetUnreadNotificationList,
  NotificationMarkAsRead,
  allNotificationsMarkAsRead,
} from "../Utils/notifications.util";

import * as moment from "moment";
import { Link } from "react-router-dom";
import Notify from "../Components/Status/Notify";

const HeaderNotification = (props) => {

  // props.setNotificationCount
  const [notificationData, setNotificationData] = useState([]);
  const [notificationUpdated, setNotificationUpdated] = useState(false);

  useEffect(() => {
    GetUnreadNotificationList().then((response) => {
      if (response?.statusCode == "200" && response?.result?.notifications) {
        setNotificationData(response.result.notifications);
        props.setNotificationCount(response.result.notificationCount)
      }
    });
  }, [notificationUpdated]);


  const handleMarkAsReadLink = async (id) => {
    const result = await NotificationMarkAsRead([id]);
    await setNotificationUpdated(!notificationUpdated);
  };

  const handleAllRead = async () => {
    const response = await allNotificationsMarkAsRead();
    setNotificationUpdated(!notificationUpdated);
  }

  return (
    <div className={`notify-dropdown ${props.showNotification ? 'd-block' : 'd-none'}`}>
      <div className="headofNotify">
        <h1>Notifications</h1>
        <Link onClick={handleAllRead}>Clear All</Link>
      </div>
      <ul>
        {notificationData.length
          ? notificationData.map((data) => (
            <li key={data._id} id={data._id}>
              <div className="content">
                {data.notificationType == "payment-completed" ? (
                  <Notify color="gold" />
                ) : (
                  ""
                )}
                {data.notificationType == "skiptrace-completed" ? (
                  // <img src={notifyBlue}></img>
                  <Notify color="blue" />
                ) : (
                  ""
                )}
                {data.notificationType == "profile-updated" ||
                  data.notificationType == "password-reset" ? (
                  <Notify color="gray" />
                ) : (
                  ""
                )}
                <span className="message">{data.title} </span>
              </div>
              <div className="detail">
                <span className="time">
                  {moment(data.dateCreated).startOf("minutes").fromNow()}
                </span>
                <span
                  className="action"
                  onClick={() => handleMarkAsReadLink(data._id)}
                >
                  Mark as read
                </span>
              </div>
            </li>
          ))
          : <li><div className="content">No unread notification</div></li>}
        {/* <li>
          <div className="content">
            <img src={notifyGold}></img>
            <span className="message">
              <b>Jackie Kun</b> mentioned you at <b>Kleon</b>
            </span>
          </div>
          <div className="detail">
            <span className="time">2m ago</span>
            <span className="action"></span>
          </div>
        </li>
        <li>
          <div className="content">
            <img src={notifyClock}></img>
            <span className="message">
              <b>Jackie Kun</b> mentioned you at <b>Kleon</b>
            </span>
          </div>
          <div className="detail">
            <span className="time">2m ago</span>
            <span className="action"></span>
          </div>
        </li> */}
      </ul>
    </div>
  );
};

export default HeaderNotification;
