import React, { useEffect, useState } from "react";
import { Col, Image, Row, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PACKAGES_GET_LIST_ENDPOINT } from "../../Constants/api-routes.constant";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedPlan,
  resetSelectedPlan,
} from "../../store/slices/features/payment-plan";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { createStripePaymentIntent, getActiveBilling, newPlanPurchased, paymentSuccessful } from "../../Utils/billing.util";
import { getFormattedCurrencyInInteger } from "../../Utils/formating.util";


const ConfirmYourBilling = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [plans, setPlans] = useState([]);
  const [stripeErrorMsg, setStripeErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [checked, setChecked] = useState(true)

  const navigate = useNavigate();

  const fetchPlans = () => {
    return fetch(PACKAGES_GET_LIST_ENDPOINT, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((rawRes) => rawRes.json())
      .then((response) => {
        response.result = response.result.filter((plan) => plan.price !== 0)
        setPlans(response.result);
        setSelectedPlan(response.result[0]);
        setShowLoader(false);
      })
      .catch((err) => {
        setErrorMsg(err?.message);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchPlans();
  }, []);
  // const submitPlan = () => {
  //   dispatch(changeSelectedPlan(selectedPlan));

  // }

  const dispatch = useDispatch();

  const newPlanBuy = async (stripeResult) => {
    if (!stripeResult?.paymentIntent) {
      return;
    }

    const requestBody = {
      packageId: selectedPlan._id,
      paymentIntentId: stripeResult.paymentIntent.id,
      paymentAmountInCent: stripeResult.paymentIntent.amount,
      paymentMethodId: stripeResult.paymentIntent.payment_method,
      paymentCreated: stripeResult.paymentIntent.created,
    };

    try {
      const response = await newPlanPurchased(requestBody);
      setShowLoader(false);

      if (response?.statusCode == 200 && response?.result) {
        setSuccessMsg(
          "Thank you for purchasing this Plan. Your information will be updated in a few seconds."
        );
        setTimeout(async () => {
          dispatch(resetSelectedPlan());

          localStorage.setItem('skipifyTokenExpiry', '');
          localStorage.setItem('00ref', '');
          localStorage.setItem('ref', '');

          // redirect to email verification page

          const resPayment = await paymentSuccessful();

          navigate('/register/thank-you')
        }, 3000);
      } else {
        setStripeErrorMsg(
          response?.message ?? "We are unable to verify your payment."
        );
      }
    } catch (error) {
      setShowLoader(false);
      setStripeErrorMsg(
        error?.message ?? "We are unable to verify your payment."
      )
    }
  };

  const stripe = useStripe();
  const elements = useElements();
  const submitPlan = async (e) => {
    e.preventDefault();
    e.target.disabled = true;

    if (selectedPlan.price === 0) {
      window.location.href = "/dashboard";
    }

    setShowLoader(true);
    setStripeErrorMsg("");
    setErrorMsg("");

    try {
      const paymentIntentResult = await createStripePaymentIntent(
        selectedPlan.price,
        'Skipify - Plan Purchase Payment');

      if(!paymentIntentResult?.clientSecret) {
        if (paymentIntentResult?.message?.includes("confirmCardPayment intent secret")) {
          setStripeErrorMsg('Payment Failed due to an unexpected error. Please try later.')
        } else {
          setStripeErrorMsg(paymentIntentResult?.message ?? 'Error while creating a payment request.');
        }
        setShowLoader(false);
        return;
      }
      
      const paymentClientSecret = paymentIntentResult?.clientSecret;
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setShowLoader(false);
        setStripeErrorMsg(
          "Payment Failed. Please refresh the page and try again."
        );
        return;
      }
      let confirmPaymentOptions = {};
      const activeBilling = await getActiveBilling();

      if (!activeBilling.result.stripePaymentMethodId) {
        confirmPaymentOptions = {
          payment_method: {
            card: elements.getElement(CardElement),
            metadata: {
              "customer ID": activeBilling.result.clientId,
              "Plan Purchase": `${selectedPlan.title} $${selectedPlan.price}`,
            },
          },
        };
      }



      const result = await stripe.confirmCardPayment(
        `${paymentClientSecret}`,
        confirmPaymentOptions
      );
      if (result.paymentIntent.status === "succeeded") {
        await newPlanBuy(result);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  return (
    <div className="main-form2">
      {showLoader === true ? (
        <div className="loaderBlocker">
          <div className="fadeOut"></div>
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {stripeErrorMsg ? (
        <div className="alert alert-danger">{stripeErrorMsg}</div>
      ) : (
        ""
      )}
      {errorMsg ? (
        <div className="alert alert-danger">{errorMsg}</div>
      ) : (
        ""
      )}
      {successMsg ? (
        <div className="alert alert-success">{successMsg}</div>
      ) : (
        ""
      )}
      <Row>
        <Col md={11} className="mx-auto">
          <Row>
            <Col md={12}>
              <div className="billing_detail_section">
                <Row>
                  <Col xl={6}>
                    <h4 className="sub_title_h4">SELECTED PLAN</h4>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        setSelectedPlan(
                          plans.filter((plan) => plan._id === e.target.value)[0]
                        )
                      }
                    >
                      {/* <option>Basic</option>
                      <option value="1">Basic - Month to Month</option>
                      <option value="2">Basic Plus - Month to Month</option> */}
                      {plans?.map((plan) => {
                        return (
                          <option value={plan._id} key={plan._id}>
                            {plan.title}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col xl={6}>
                    <h1 className="sub_title_h42">${getFormattedCurrencyInInteger(selectedPlan.price)}/month</h1>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="billing_detail_section_des">
                      <h4>Plan Features: </h4>
                      <ul>
                        {selectedPlan?.features?.split("\n").map((feature) => {
                          return <li>{feature}</li>;
                        })}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={12}>
              <div className="billing_detail_section m-0">
                <Row>
                  <Col md={12}>
                    <h3 className="sub_title_h4 mb-3"> ORDER DETAILS</h3>
                    <div className="order_detail">
                      <h3 className="sub_title_h4"> Order Total</h3>
                      <h3 className="sub_title_h4"> ${getFormattedCurrencyInInteger(selectedPlan.price)}/month</h3>
                    </div>
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      label={
                        <p>
                          I agree to all of Skipify.ai’s{" "}
                          <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/terms`}>
                            Terms of Service
                          </a>{" "}
                          &{" "}
                          <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}/privacy-policy`}>
                            Privacy Policy
                          </a>
                          .
                        </p>
                      }
                      className="term_checkbox"
                      onClick={() => setChecked(!checked)}
                      checked={checked ? true : false}
                    />
                    <Button variant="warning" onClick={(e) => { submitPlan(e); }}>
                      SUBMIT PAYMENT
                    </Button>
                    <div className="billing_detail_section_des">
                      <h4>Subscription Details:</h4>
                      <ul>
                        <li>
                          {" "}
                          The subscription is set to auto-renew by default every
                          month.
                        </li>
                        {/* <li>
                          {" "}
                          Sales tax may be added at the time of checkout.
                        </li> */}
                        <li>
                          {" "}
                          You can upgrade, cancel, or pause your subscription at
                          any time.
                        </li>
                        <li>
                          All payments made once the subscription is active are
                          final.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmYourBilling;
