
import { IoIosCheckmarkCircle } from "react-icons/io";

const Notify = ({ color }) => {
    return (
        <div className="status">
            <IoIosCheckmarkCircle className={`${color}`} />
        </div>
    )
}

export default Notify