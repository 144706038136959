import "../../assets/css/main.css";
import logo from "../../assets/img/logo.png";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";

export const TopNav = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Link to="/" className="navbar-brand" >
          {/* <div className="brand"> */}
          <img src={logo} alt="" />
          {/* <Nav className="right-nav mobile">
              <li><Link className="nav-link " to="/register">Get Started</Link></li>
              <li><Link className="nav-link" to="/login">Log In</Link></li>
            </Nav> */}
          {/* </div> */}
        </Link>
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav className="right-nav menu_nav">

            <li><Link className={` nav-link ${pathname === '/about-us' ? 'active' : ''}`} to="/about-us"> About Us </Link></li>

            <li><Link className={` nav-link ${pathname === '/pricing-plan' ? 'active' : ''}`} to="/pricing-plan">Pricing</Link></li>
            {/* <li><Link className={` nav-link ${pathname === '' ? 'active' : ''}`} to="#">FAQ’s </Link></li> */}
            {/* <li><Link className={` nav-link ${pathname === '' ? 'active' : ''}`} to="#">Blog </Link></li> */}
            <li><Link className={` nav-link ${pathname === '/contact-us' ? 'active' : ''}`} to="/contact-us">Contact Us</Link></li>

            {/* <li ><Link className={` nav-link ${pathname === '' ? 'active' : ''}`} to="#">Our Data </Link></li> */}
            {/* <li ><Link className={` nav-link ${pathname === '' ? 'active' : ''}`} to="#">Why Skipify.ai </Link></li> */}
          </Nav>
          <Nav className="right-nav desktop">
            <li><Link className="nav-link linktext" to="/login">Log In</Link></li>
            <li><Link className="nav-link btnPrimary" to="/register">Create Free Account </Link></li>
            {/* <li><Link className="nav-link " to="/register">Get Started</Link></li> */}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
