import React, { useState, useEffect } from "react";
import { Col, Image, Row, Form, Button } from "react-bootstrap";
import leftBannerImage from "../../assets/img/signup_new.png";
import Icon from "../../assets/img/icon.svg";

const EmailVerification = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const email = localStorage.getItem('skipifyUserEmail');
    if(email != "" && email != null){
      setUserEmail(email);
    }
  }, []);

  return (
    <div className="register_section">
      <div className="register_section_overlay">
        <Row>
          <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={12} lg={{ span: 6, order: 1 }} className="p-0 text-center">
            <div className="signup_image_div">
              <Image src={leftBannerImage} alt="" className="bg_image" />
              <div className="text-div">
                <h4>Lead Generation.</h4>
                <h4> Made Easy.</h4>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={12} lg={{ span: 6, order: 2 }} className="p-0 mx-auto">
            <Row>
              <Col md={11} className="mx-auto text-center mt-5">
                <a href={`${process.env.REACT_APP_SKIPIFY_AI_URL}`}>
                  <Image src={require('./../../assets/img/logo.png')} alt="logo" className="register_logo" />
                </a>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={12} lg={11} xl={10} className="mx-auto">
                <div className="email_verification_page">
                  <Image src={Icon} alt="logo" width={100} />
                  <h1>Please Verify Your Email</h1>

                  <p >You’re almost there! We sent an email to: </p>
                  <h3>{userEmail}</h3>

                  <p className="third_des">Just click on the link in that email to complete your sign up. If you don’t see it you may need to check your spam folder.</p>
                  {/* <p className="third_des">Still don’t see it? No problem.</p> */}
                  <p className="third_des">If you have any questions, please email us at</p>
                  <p><a href="mailto:support@skipify.ai">support@skipify.ai</a></p>
                </div>
              </Col>
            </Row>


          </Col>


        </Row>
      </div>
    </div>
  )
}

export default EmailVerification