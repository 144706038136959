import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = "374774898640796"; // Replace with your Pixel ID
ReactPixel.init(PIXEL_ID);

export const MetaPixelTrackingCode = (trackLead = false) => {
  ReactPixel.pageView();
  if(trackLead) {
    ReactPixel.track('Lead');
  }
};