export const AgriculturalOptionsConstant = [
  { value: "7000", label: "Agricultural / Rural" , category: "Agricultural"},
  { value: "7001", label: "Farm land" , category: "Agricultural"},
  { value: "7002", label: "Ranch" , category: "Agricultural"},
  { value: "7003", label: "Range Land (Grazing)" , category: "Agricultural"},
  { value: "7004", label: "Crop, Field Crops, Row Crops" , category: "Agricultural"},
  { value: "7005", label: "Orchard" , category: "Agricultural"},
  { value: "7006", label: "Vineyard" , category: "Agricultural"},
  { value: "7007", label: "Poultry Farm" , category: "Agricultural"},
  { value: "7008", label: "Dairy Farm" , category: "Agricultural"},
  { value: "7009", label: "Timberland, Forest, Trees" , category: "Agricultural"},
  { value: "7010", label: "Wildlife Refuge" , category: "Agricultural"},
  { value: "7011", label: "Desert or Barren Land" , category: "Agricultural"},
  { value: "7012", label: "Pasture, Meadow" , category: "Agricultural"},
  { value: "7013", label: "Misc. Structures - Ranch, Farm, Fixtures" , category: "Agricultural"},
  { value: "7014", label: "Grove" , category: "Agricultural"},
  { value: "7015", label: "Feedlots" , category: "Agricultural"},
  { value: "7016", label: "Livestock Parcel" , category: "Agricultural"},
  { value: "7017", label: "Horticulture, Growing House" , category: "Agricultural"},
  { value: "7018", label: "Well Site" , category: "Agricultural"},
  { value: "7019", label: "Truck Crops (Tobacco; Cash Crops)" , category: "Agricultural"},
  { value: "7020", label: "Reservoir, Water Supply" , category: "Agricultural"},
  { value: "7021", label: "Irrigation, Flood Control" , category: "Agricultural"},
  { value: "7022", label: "Natural Resources" , category: "Agricultural"},
  { value: "7023", label: "Rural Improved / Non-Residential" , category: "Agricultural"},
  { value: "7024", label: "Cannabis Grow Facility" , category: "Agricultural"},
];
